import React from "react"

import { makeStyles } from '@material-ui/core/styles';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from "@material-ui/core/Typography"

import MoreMenu from "./components/more"

import { useSelector, useDispatch } from "react-redux"

import { selectImage } from "../../../../../../reducers/images"



export default function(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    
    const { hovered_image } = useSelector(state => state.inspector.solar.images)

    const onImageClicked = image => dispatch(selectImage(image))

    return props.images.length === 0 ?
        <Typography className={classes.empty}>No hay imágenes. Prueba añadir algunas con el botón +</Typography>:
        <div className={classes.root}>
            <GridList cellHeight={180} className={classes.gridList} cols={2}>
                {props.images.map(img => (
                    <GridListTile key={img._id}>
                        <img src={img.url} style={{ border: !!hovered_image && (hovered_image._id === img._id) ? "5px solid red" : null }} onClick={() => onImageClicked(img)}/>
                        <GridListTileBar 
                            title={img.filename}
                            actionIcon={
                                <MoreMenu/>
                            }
                        ></GridListTileBar>
                    </GridListTile>
                ))}
            </GridList>
        </div>
}


const useStyles = makeStyles(theme => ({
    root: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflowY: 'scroll',
      height: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: "100%"
    },
    empty: {
        textAlign: "center",
        marginTop: 20
    }
}));