import React from 'react'

import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'

import FAB from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'

import IconAdd from '@material-ui/icons/Add'

import { GET_INCIDENT_GROUPS } from '../../queries/groups'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(5),
    },
}))

const ListGroups = (props) => {
    const classes = useStyles()

    const { isAllowed: isCreateGroupAllowed } = useIsAllowed({
        group: 'incidentGroups',
        actions: 'createIncidentGroup',
    })

    const {
        loading,
        error,
        data: { getIncidentGroups: { groups = [] } = {} } = {},
    } = useQuery(GET_INCIDENT_GROUPS, { variables: { params: {} } })

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2">Grupos de Incidencias</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groups.map((group) => (
                                    <TableRow key={group.id}>
                                        <TableCell>
                                            <Link to={`${group.id}`}>{group.name}</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
            {isCreateGroupAllowed && (
                <Link to="create">
                    <FAB color="primary" aria-label="add" className={classes.fab}>
                        <IconAdd />
                    </FAB>
                </Link>
            )}
        </Container>
    )
}

export default ListGroups
