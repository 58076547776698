import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import * as THREE from 'three'

import pallet from '../assets/pallet.jpg'

const Pallet = ({ groupProps, size, help }) => {
    const meshRef = useRef()
    const palletTexture = new THREE.TextureLoader().load(pallet)

    return (
        /* eslint-disable react/no-unknown-property */
        <group {...groupProps}>
            <mesh ref={meshRef} name="palletName" rotation={[0, 0, 0]} position={[0, 0, 0]}>
                <boxBufferGeometry attach="geometry" args={size} />
                <meshStandardMaterial map={palletTexture} wireframe={help} />
            </mesh>
            {help && <axesHelper size={4} />}
        </group>
    )
}

Pallet.propTypes = {
    groupProps: PropTypes.object,
    size: PropTypes.array.isRequired,
    help: PropTypes.bool,
}

Pallet.defaultProps = {
    groupProps: {},
    help: false,
}

export default Pallet
