import React, { useState } from "react"

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import Fab from "@material-ui/core/Fab"

import AddIcon from '@material-ui/icons/Add';

import { useDispatch } from "react-redux"

import { openNewGroupDialog } from "../reducers/dialogs"

export default function() {
    const [ menuOpen, setMenuOpen ] = useState(false)

    const openMenu = () => setMenuOpen(true)
    const closeMenu = () => setMenuOpen(false)

    const dispatch = useDispatch()

    return (
        <Fab color="primary" onClick={() => dispatch(openNewGroupDialog())}><AddIcon/></Fab>
    )
}