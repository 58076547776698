import { REPORT } from "../queries/local"

export default {
    Query: {
        report(root, args, { cache }) {
                try {
                    const { report } = cache.readQuery({ query: REPORT })   
                    return {
                        ...report,
                        __typename: "Report"
                    }
                } catch(err){
                    return { 
                        summary: "",
                        __typename: "Report"
                    }
                }
        }
    },
    Mutation: {
        setSummary(root, args, { cache }) {
            const { report } = cache.readQuery({ query: REPORT })   
                
            const data =  {
                report: {
                    ...report,
                    summary: args.value.slice(0, 4000)
                }
            }
            
            cache.writeQuery({
                query: REPORT,
                data
            })
    
            return "SUCCEEDED"
        }
    }
}