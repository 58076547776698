import React, { useState } from 'react'

import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'

function Device() {
    const [service, setService] = useState(null)

    const [ssid, setSsid] = useState('')
    const [type, setType] = useState('')
    const [pass, setPass] = useState('')

    const onConnect = async () => {
        const device = await navigator.bluetooth.requestDevice({
            filters: [{ namePrefix: 'FL' }],
            optionalServices: ['c6f2d9e3-49e7-4125-9014-bfc6d669ff00'],
        })

        const server = await device.gatt.connect()

        const service = await server.getPrimaryService('c6f2d9e3-49e7-4125-9014-bfc6d669ff00')

        setService(service)
    }

    const onUpload = async () => {
        let buffer
        let characteristic

        const encoder = new window.TextEncoder()

        characteristic = await service.getCharacteristic('c6f2d9e3-49e7-4125-9014-bfc6d669ff01')
        buffer = encoder.encode(ssid)
        await characteristic.writeValue(buffer.buffer)

        setTimeout(async () => {
            characteristic = await service.getCharacteristic('c6f2d9e3-49e7-4125-9014-bfc6d669ff02')
            buffer = encoder.encode(type)
            await characteristic.writeValue(buffer.buffer)

            setTimeout(async () => {
                characteristic = await service.getCharacteristic(
                    'c6f2d9e3-49e7-4125-9014-bfc6d669ff03'
                )
                buffer = encoder.encode(pass)
                await characteristic.writeValue(buffer.buffer)
            }, 1000)
        }, 1000)
    }

    const onSsidChange = (event) => {
        setSsid(event.target.value)
    }

    const onTypeChange = (event) => {
        setType(event.target.value)
    }

    const onPassChange = (event) => {
        setPass(event.target.value)
    }

    return (
        <div>
            <Button onClick={onConnect}>Connect</Button>
            <Button onClick={onUpload}>Update</Button>
            <TextField label="SSID" onChange={onSsidChange} value={ssid}></TextField>
            <TextField label="Type" onChange={onTypeChange} value={type}></TextField>
            <TextField label="Password" onChange={onPassChange} value={pass}></TextField>
        </div>
    )
}

export default Device
