const EDIT_PARAMETER = 'EDIT_PARAMETER'

export const editParameter = (field, newValue) => ({
    type: EDIT_PARAMETER,
    field,
    newValue,
})

export const initialState = {
    boxes: 'boxes',
    layouts: 'layouts',
    stacks: 'stacks',
    obstacles: 'obstacles',
    settings: 'settings',
    selected_stack: 'selected_stack',
}

/* eslint-disable default-param-last */
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_PARAMETER:
            return { ...state, [action.field]: action.newValue }
        default:
            return state
    }
}
