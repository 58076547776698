import gql from "graphql-tag"

export const SELECTED_IMAGE = gql`
    query {
        labelerSelectedImage @client
    }
`

export const SET_SELECTED_IMAGE = gql`
    mutation SetLabelerSelectedImage($params: SetLabelerSelectedImageRequest) {
        setLabelerSelectedImage(params: $params) @client
    }
`