import React, { useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import { Link, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ErrorIcon from '@mui/icons-material/Error'
import DoneIcon from '@mui/icons-material/Done'

import { groups } from '../../utils/permissions'

import { CREATE_ROLE } from '../../queries/roles'
import ButtonStatus from '../../../../../../../../utils/common-components/buttonStatus'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    paper: {
        padding: theme.spacing(2),
        height: '100%',
    },
}))

const Scene = () => {
    const history = useHistory()

    const [activeStep, setActiveStep] = useState(0)
    const [name, setName] = useState('')
    const [permissions, setPermissions] = useState({})

    const classes = useStyles()

    const [createRole, { error, loading }] = useMutation(CREATE_ROLE)

    const onNext = () => {
        if (activeStep === 0) {
            if (!name) {
                return
            }
        }

        setActiveStep(activeStep + 1)
    }

    const onBack = () => {
        setActiveStep(activeStep - 1)
    }

    const onFinish = async () => {
        try {
            // setLoading(true)
            await createRole({
                variables: {
                    params: {
                        name,
                        permissions: Object.keys(permissions).reduce((acc, group) => {
                            const actions = Object.keys(permissions[group])
                            return [
                                ...acc,
                                ...actions.map((action) => ({
                                    group,
                                    action,
                                    allowed: permissions[group][action] || false,
                                })),
                            ]
                        }, []),
                    },
                },
            })
            history.push('../roles')
        } catch (e) {
            console.log(e)
        } finally {
            // setLoading(false)
        }
    }

    const onNameChange = (e) => {
        setName(e.target.value)
    }

    const onPermissionChange = (group, action) => () => {
        const checked = permissions[group] && permissions[group][action]
        const newPermissions = {
            ...permissions,
            [group]: {
                ...permissions[group],
                [action]: !checked,
            },
        }

        setPermissions(newPermissions)
    }

    const errorIcon = (
        <ErrorIcon
            fontSize="large"
            color="error"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    const successIcon = (
        <DoneIcon
            fontSize="large"
            color="success"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button color="primary" component={Link} to="list" startIcon={<ChevronLeft />}>
                        Volver a roles
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Crear un usuario" />
                        <Divider />
                        <CardContent>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step>
                                    <StepLabel>Nombre</StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Nombre"
                                                    fullWidth
                                                    value={name}
                                                    onChange={onNameChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={onNext}
                                                >
                                                    Siguiente
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                {groups.map((g, i) => {
                                    return (
                                        <Step key={`step-${i}`}>
                                            <StepLabel>{g.label}</StepLabel>
                                            <StepContent>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {g.actions.map((a, j) => {
                                                            const checked =
                                                                (permissions[g.name] &&
                                                                    permissions[g.name][
                                                                        a.action
                                                                    ]) ||
                                                                false

                                                            return (
                                                                <FormControlLabel
                                                                    key={`form-${j}`}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={checked}
                                                                            onChange={onPermissionChange(
                                                                                g.name,
                                                                                a.action
                                                                            )}
                                                                        >
                                                                            {a.label}
                                                                        </Checkbox>
                                                                    }
                                                                    label={a.label}
                                                                />
                                                            )
                                                        })}
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button onClick={onBack} fullWidth>
                                                            Anterior
                                                        </Button>
                                                    </Grid>
                                                    {activeStep < groups.length && (
                                                        <Grid item xs={1}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={onNext}
                                                                fullWidth
                                                            >
                                                                Siguiente
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    {activeStep === groups.length && (
                                                        <Grid item xs={1}>
                                                            <ButtonStatus
                                                                buttonChildren="Finalizar"
                                                                fullWidth
                                                                buttonProps={{
                                                                    disabled: loading,
                                                                    color: 'primary',
                                                                    variant: 'contained',
                                                                    onClick: onFinish,
                                                                }}
                                                                loading={loading}
                                                                error={error}
                                                                errorIcon={errorIcon}
                                                                successIcon={successIcon}
                                                                animationTimeout={3000}
                                                            />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </StepContent>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Scene
