import React, { useState } from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "@material-ui/core/Button"
import Fab from "@material-ui/core/Fab"
import TextField from "@material-ui/core/TextField"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"


import AddIcon from "@material-ui/icons/Add"

import { CREATE_MODEL } from "../../queries/models"
import { GET_TRAINING_SETS_BY_PARENT } from "../../../training-sets/queries/training-sets"

const CreateModelDialog = () => {

    const [ open, setOpen ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ name, setName ] = useState("")
    const [ epochs, setEpochs ] = useState(1)
    const [ type, setType] = useState("")
    const [ resolution, setResolution ] = useState("240")
    const [ preprocessing, setPreprocessing ] = useState(null)
    const [ trainingSet, setTrainingSet ] = useState(null)

    const { data: { horus: { trainingSets: { getTrainingSetsByParent: { trainingSets = []} = {}} = {}} = {}} = {}} = useQuery(GET_TRAINING_SETS_BY_PARENT, { variables: { params: { parent: null }}})
    const [ createModel ] = useMutation(CREATE_MODEL)
    
    const onOpen = () => {
        setOpen(true)
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onAccept = async () => {
        setLoading(true)
        await createModel({
            variables: {
                params: {
                    name,
                    epochs,
                    type,
                    trainingSet,
                    resolution,
                    preprocessing
                }
            }
        })
        setOpen(false)
        setLoading(false)
    }
    
    const onNameChange = e => setName(e.target.value)
    const onTypeChange = (e) => setType(e.target.value)
    const onEpochsChange = e => setEpochs(e.target.value)
    const onTrainingSetChange = e => setTrainingSet(e.target.value)

    return (
        <React.Fragment>
            <Fab onClick={onOpen} style={{ position: "fixed", bottom: 20, right: 20 }}>
                <AddIcon />
            </Fab>
            <Dialog open={open}>
                <DialogTitle>Create Model</DialogTitle>
                <DialogContent>
                    <TextField value={name} onChange={onNameChange} fullWidth/>
                    <FormControl margin="normal" fullWidth>
                        <Select 
                            label={"Model Type"}
                            fullWidth 
                            value={type}
                            onChange={onTypeChange} 
                        >
                            <MenuItem value={null}></MenuItem>
                            <MenuItem value={"segmentation"}>Segmentation</MenuItem>
                        </Select>
                    </FormControl>   
                    <FormControl margin="normal" fullWidth>
                        <Select 
                            label={"Training Set"}
                            fullWidth 
                            value={trainingSet}
                            onChange={onTrainingSetChange} 
                        >
                            {
                                trainingSets.map(t => (<MenuItem value={t._id}>{t.name}</MenuItem>))
                            }
                        </Select>
                    </FormControl>  
                    <TextField type="number" value={epochs} onChange={onEpochsChange} fullWidth/> 
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={onCancel}>Cancel</Button>
                    <Button disabled={loading} onClick={onAccept}>Accept</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default CreateModelDialog