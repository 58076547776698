import React from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import InputLabel from '@mui/material/InputLabel'

const DateWidget = ({ port, portMetadata, updateParameter }) => {
    const onChange = (e) => {
        const date = new Date(e)
        updateParameter(date.toISOString())
    }
    return (
        <React.Fragment>
            <InputLabel>{portMetadata.humanLabel ? portMetadata.humanLabel : port.id}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker onChange={onChange} />
            </LocalizationProvider>
        </React.Fragment>
    )
}
export default DateWidget
