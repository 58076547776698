import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useDispatch, useSelector } from "react-redux"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';

import { GET_DATASET_V2_BY_ID } from "../../../../queries/datasets"
import { setDimensionField } from "../../../../reducer"

import Date from "./containers/date/scene"

const binnings = {
    "date": Date
}

const Dimensions = () => {

    const dispatch = useDispatch()

    const { current_dataset, selected_item, dimensions} = useSelector(state => state.dashboards.bar_chart)

    const { data: { getDatasetV2ById: { dataset: { fields = []} = {}} = {}} = {}, loading } = useQuery(GET_DATASET_V2_BY_ID, { 
        variables: { 
            params: { 
                id: current_dataset 
            }
        }
    })
    
    const onCurrentDatasetChange = (index) => (e) => dispatch(setDimensionField(index, e.target.value))

    const availableFields = [
        { name: "createdAt", type: "date" },
        ...fields.filter(f => f.type !== "rgb" )
    ]
    
    const currentDimension = dimensions[selected_item.index]

    let CurrentBinning = null
    if(currentDimension.field) {
        const selected = availableFields.find(f => f.name === currentDimension.field)

        CurrentBinning = binnings[selected.type]

        console.log(CurrentBinning, selected)
    }

    return (
        <List>
            <ListItem>
                <ListItemText primary="OPCIONES" />
            </ListItem>
            <ListItem>
                <FormControl variant="filled" fullWidth>
                    <InputLabel>Campo</InputLabel>
                    <Select value={currentDimension.field} onChange={onCurrentDatasetChange(selected_item.index)}>
                        {
                            !loading && availableFields.map((d, i) => (
                                <MenuItem value={d.name}>{d.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
               {CurrentBinning && <CurrentBinning/>}
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemText primary="ESTILOS" />
            </ListItem>
        </List>
    )
}

export default Dimensions