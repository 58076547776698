import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize: '1.5em',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
}))


const StepMonitorLevels = (props) => {
    const classes = useStyles()

    const [levels, setLevels] = useState([])
    const [level, setLevel] = useState('')

    const onStepMonitorLevelsAccept = () => {
        if (!levels.length) {
            return
        }

        props.onPropertyChange(props.property, levels)
        props.setActiveStep(props.nextStep)
    }

    const onChipDetete = (index) => {
        const newLevels = levels.filter((_, i) => i !== index)
        setLevels(newLevels)
    }

    const onChipAdd = () => {
        const newLevels = [...levels, level]
        setLevels(newLevels)
    }

    const onLevelChange = (event) => {
        const value = event.target.value
        setLevel(value)
    }

    const onStepMonitorBack = () => {
        props.setActiveStep(props.prevStep)
    }

    return (
        <Grid container spacing={2}>
            <Grid className={classes.root} item xs={12}>
                {
                    levels.map((l, index) => (
                        <Chip
                            key={index}
                            label={l}
                            className={classes.chip}
                            onDelete={() => onChipDetete(index)}
                        />
                    ))
                }
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Nivel"
                    variant="outlined"
                    fullWidth
                    value={level}
                    onChange={onLevelChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={onChipAdd} fullWidth>Añadir</Button>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.actions}>
                    <Button onClick={onStepMonitorBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorLevelsAccept}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorLevels
