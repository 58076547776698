export const OPEN_NEW_INDUSTRIAL_DATASETS_DIALOG = "NEW_INDUSTRIAL_DATASETS_DIALOG/OPEN"
export const CLOSE_NEW_INDUSTRIAL_DATASETS_DIALOG = "NEW_INDUSTRIAL_DATASETS_DIALOG/CLOSE"

export const openNewIndustrialDatasetsDialog = () => ({ type: OPEN_NEW_INDUSTRIAL_DATASETS_DIALOG })
export const closeNewIndustrialDatasetsDialog = () => ({ type: CLOSE_NEW_INDUSTRIAL_DATASETS_DIALOG })

export default (state = { open: false }, action) => {
    switch(action.type) {
        case OPEN_NEW_INDUSTRIAL_DATASETS_DIALOG:
            return {...state, open: true}
        case CLOSE_NEW_INDUSTRIAL_DATASETS_DIALOG:
            return {...state, open: false}
        default:
            return state
    }
}