import React, { useState } from "react"

import { useSelector, useDispatch } from "react-redux"

import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"

import ImageViewer from "./containers/image-viewer"
import Controls from "./containers/controls"
import Annotations from "./containers/annotations"

import { closeDialog } from "../../reducers/dialogs"

const AllTabs = [
    Controls,
    Annotations
]

export default function() {

    const { open } = useSelector(state => state.inspector.solar.side_menu.image_viewer.dialogs)

    const dispatch = useDispatch()

    const onClose = () => dispatch(closeDialog())

    const [ tab, setTab ] = useState(0)

    const CurrentTab = AllTabs[tab]

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
            <Grid container style={{ padding: 0, margin: 0, overflowX: "hidden"}}>
                <Grid item xs>
                    <ImageViewer/>
                </Grid>
                <Grid item xs={4}>
                    <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                        <Tab label="Controles"/>
                    </Tabs>
                    <CurrentTab/>
                </Grid>
            </Grid>
        </Dialog>
    )
}