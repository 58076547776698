import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useParams, useHistory } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'

import AddIcon from '@material-ui/icons/Add'

import { REMOVE_MONITOR, GET_MONITORS } from '../../../../queries/monitors'

import { useIsAllowed } from 'queries/permissions'

const Scene = (props) => {
    const history = useHistory()

    const { monitor } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const { isAllowed: isRemoveAllowed } = useIsAllowed({
        group: 'monitors',
        action: 'removeMonitor',
    })

    const config = props.config || {}

    const name = config.name || ''

    const [loading, setLoading] = useState(false)

    const [removeMonitor] = useMutation(REMOVE_MONITOR)

    const onRemove = async () => {
        setLoading(true)

        try {
            await removeMonitor({
                variables: {
                    params: {
                        id: monitor,
                    },
                },
                refetchQueries: {
                    query: GET_MONITORS,
                },
            })

            history.push('list')
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <List style={{ height: '100%' }}>
            <ListItem>
                <ListItemText primary="Nombre" secondary={name} disabled={loading} />
            </ListItem>
            {isRemoveAllowed && (
                <ListItem button>
                    <ListItemText
                        primary="Eliminar monitor"
                        onClick={onRemove}
                        disabled={loading}
                    />
                </ListItem>
            )}
        </List>
    )
}

export default Scene
