/* eslint-disable import/prefer-default-export */
export const groups = [
    {
        label: 'Grupos',
        name: 'groups',
        actions: [
            {
                label: 'Listar Grupos',
                action: 'getAllGroups',
            },
            {
                label: 'Ver un grupo',
                action: 'getGroupById',
            },
            {
                label: 'Crear un grupo',
                action: 'createGroup',
            },
            {
                label: 'Eliminar un grupo',
                action: 'removeGroupById',
            },
            {
                label: 'Ver estado de un grupo',
                action: 'getGroupStatus',
            },
            {
                label: 'Desplegar un grupo',
                action: 'deploy',
            },
            {
                label: 'Listar blueprints de un grupo',
                action: 'getBlueprintsByGroup',
            },
            {
                label: 'Ver un blueprint',
                action: 'getBlueprintById',
            },
            {
                label: 'Crear un blueprint',
                action: 'createBlueprint',
            },
            {
                label: 'Editar un blueprint',
                action: 'updateBlueprint',
            },
            {
                label: 'Eliminar un blueprint',
                action: 'removeBlueprint',
            },
            {
                label: 'Listar variables de un grupo',
                action: 'getVariablesByGroup',
            },
            {
                label: 'Crear una variable',
                action: 'createVariable',
            },
            {
                label: 'Borrar una variable',
                action: 'removeVariableById',
            },
            {
                label: 'Listar parámetros',
                action: 'getParamsByGroup',
            },
            {
                label: 'Crear un parámetro',
                action: 'createParam',
            },
            {
                label: 'Eliminar un parámetro',
                action: 'removeParamById',
            },
            {
                label: 'Editar un parámetro',
                action: 'updateParamById',
            },
            {
                label: 'Listar recursos de un grupo',
                action: 'getResourcesByGroup',
            },
            {
                label: 'Crear un recurso',
                action: 'createResource',
            },
            {
                label: 'Eliminar un recurso',
                action: 'removeResource',
            },
            {
                label: 'Listar dispositivos de un grupo',
                action: 'getDevicesByGroup',
            },
            {
                label: 'Crear un dispositivo',
                action: 'createDevice',
            },
            {
                label: 'Eliminar un dispositivo',
                action: 'removeDevice',
            },
            {
                label: 'Listar HMIs de un grupo',
                action: 'getHMIsByGroup',
            },
            {
                label: 'Crear un HMI',
                action: 'createHMI',
            },
            {
                label: 'Editar un HMI',
                action: 'updateHMIById',
            },
        ],
    },
    {
        label: 'Datasets',
        name: 'datasets',
        actions: [
            {
                label: 'Listar Datasets',
                action: 'getDatasetsV2ByParent',
            },
            {
                label: 'Ver un dataset',
                action: 'getDatasetV2ById',
            },
            {
                label: 'Crear un dataset',
                action: 'createDatasetV2',
            },
            {
                label: 'Eliminar un dataset',
                action: 'removeDatasetV2',
            },
            {
                label: 'Ver entradas de un dataset',
                action: 'getDatasetV2EntriesByDataset',
            },
            {
                label: 'Exportar un dataset',
                action: 'exportDatasetV2EntriesByDataset',
            },
        ],
    },
    {
        label: 'Sets de entrenamiento',
        name: 'trainingSets',
        actions: [
            {
                label: 'Listar sets de entrenamiento',
                action: 'getTrainingSets',
            },
            {
                label: 'Ver un set de entrenamiento',
                action: 'getTrainingSetById',
            },
            {
                label: 'Editar un set de entrenamiento',
                action: 'updateTrainingSetById',
            },
            {
                label: 'Crear un set de entrenamiento',
                action: 'createTrainingSet',
            },
            {
                label: 'Eliminar un set de entrenamiento',
                action: 'removeTrainingSetById',
            },
            {
                label: 'Listar entradas de un set de entrenamiento',
                action: 'getTrainingSetEntriesByTrainingSet',
            },
            {
                label: 'Crear una entrada de un set de entrenamiento',
                action: 'createTrainingSetEntries',
            },
            {
                label: 'Eliminar una entrada de un set de entrenamiento',
                action: 'removeTrainingSetEntryById',
            },
        ],
    },
    {
        label: 'Modelos',
        name: 'models',
        actions: [
            {
                label: 'Listar modelos',
                action: 'getModels',
            },
            {
                label: 'Crear un modelo',
                action: 'createModel',
            },
            {
                label: 'Listar entregables de un modelo',
                action: 'getDeliverablesByModel',
            },
            {
                label: 'Crear un entregable',
                action: 'createDeliverable',
            },
        ],
    },
    {
        label: 'Monitores',
        name: 'monitors',
        actions: [
            {
                label: 'Listar Monitores',
                action: 'getMonitors',
            },
            {
                label: 'Crear un monitor',
                action: 'createMonitor',
            },
            {
                label: 'Editar un monitor',
                action: 'updateMonitor',
            },
            {
                label: 'Eliminar un monitor',
                action: 'removeMonitor',
            },
        ],
    },
    {
        label: 'Incidencias',
        name: 'incidents',
        actions: [
            {
                label: 'Listar grupos de incidencias',
                action: 'getIncidentGroups',
            },
            {
                label: 'Crear un grupo de incidencias',
                action: 'createIncidentGroup',
            },
            {
                label: 'Editar un grupo de incidencias',
                action: 'updateIncidentGroup',
            },
            {
                label: 'Listar incidencias de un grupo',
                action: 'getIncidentsByGroup',
            },
            {
                label: 'Marcar incidencia en progreso',
                action: 'inProgressIncident',
            },
            {
                label: 'Cerrar Incidencias',
                action: 'closeIncident',
            },
            {
                label: 'Reabrir Incidencias',
                action: 'openIncident',
            },
            {
                label: 'Listar mensajes de una incidencia',
                action: 'getMessagesByOwner',
            },
            {
                label: 'Crear un mensaje de una incidencia',
                action: 'createMessage',
            },
        ],
    },
    {
        label: 'Ajustes',
        name: 'settings',
        actions: [
            {
                label: 'Listar usuarios',
                action: 'getUsers',
            },
            {
                label: 'Crear un usuario',
                action: 'createUser',
            },
            {
                label: 'Editar un usuario',
                action: 'updateUser',
            },
            {
                label: 'Eliminar un usuario',
                action: 'removeUser',
            },
            {
                label: 'Listar roles',
                action: 'getRoles',
            },
            {
                label: 'Crear un rol',
                action: 'createRole',
            },
            {
                label: 'Editar un rol',
                action: 'updateRole',
            },
            {
                label: 'Eliminar un rol',
                action: 'removeRole',
            },
            {
                label: 'Listar servicios',
                action: 'getServices',
            },
        ],
    },
]
