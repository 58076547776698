import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CropSquareOutlinedIcon from '@mui/icons-material/CropSquareOutlined'
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined'
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import theme from '../../../../../../../../../../../../theme'
import { setEditMode } from '../../redux/images'
import {
    EDIT_MODE_SELECT,
    EDIT_MODE_CREATE_POLYGONS,
    EDIT_MODE_REMOVE_POLYGONS,
    EDIT_MODE_REMOVE_POINTS,
    EDIT_MODE_CREATE_POINTS,
} from '../../constants'

/**
 *  Component responsible of PolygonsAndPoints toolbox. Its behavior
 * is like a radio button.
 */
const PolygonsAndPointToolbox = () => {
    const dispatch = useDispatch()

    const editMode = useSelector(
        (state) => state.trainingSetSegmentation.images.editMode || EDIT_MODE_SELECT
    )
    const createPolygonClick = () => {
        dispatch(setEditMode(EDIT_MODE_CREATE_POLYGONS))
    }
    const removePolygonClick = () => {
        dispatch(setEditMode(EDIT_MODE_REMOVE_POLYGONS))
    }
    const createPointClick = () => {
        dispatch(setEditMode(EDIT_MODE_CREATE_POINTS))
    }
    const removePointClick = () => {
        dispatch(setEditMode(EDIT_MODE_REMOVE_POINTS))
    }
    const selectClick = () => {
        dispatch(setEditMode(EDIT_MODE_SELECT))
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    '& > *': {
                        m: 1,
                    },
                }}
                style={{ position: 'absolute' }}
            >
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    color="primary"
                >
                    <Tooltip
                        placement="right"
                        title={<Typography fontSize={11}>Crear polígono</Typography>}
                    >
                        <Button
                            key={EDIT_MODE_CREATE_POLYGONS}
                            variant={
                                editMode === EDIT_MODE_CREATE_POLYGONS ? 'outlined' : 'contained'
                            }
                            onClick={createPolygonClick}
                        >
                            <CropSquareOutlinedIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        placement="right"
                        title={<Typography fontSize={11}>Eliminar polígono (e)</Typography>}
                    >
                        <Button
                            key={EDIT_MODE_REMOVE_POLYGONS}
                            variant={
                                editMode === EDIT_MODE_REMOVE_POLYGONS ? 'outlined' : 'contained'
                            }
                            onClick={removePolygonClick}
                        >
                            <CancelPresentationOutlinedIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        placement="right"
                        title={<Typography fontSize={11}>Añadir punto (q)</Typography>}
                    >
                        <Button
                            key={EDIT_MODE_CREATE_POINTS}
                            variant={
                                editMode === EDIT_MODE_CREATE_POINTS ? 'outlined' : 'contained'
                            }
                            onClick={createPointClick}
                        >
                            <RadioButtonUncheckedOutlinedIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        placement="right"
                        title={<Typography fontSize={11}>Eliminar punto (w)</Typography>}
                    >
                        <Button
                            key={EDIT_MODE_REMOVE_POINTS}
                            variant={
                                editMode === EDIT_MODE_REMOVE_POINTS ? 'outlined' : 'contained'
                            }
                            onClick={removePointClick}
                        >
                            <DoNotDisturbOnOutlinedIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        placement="right"
                        title={<Typography fontSize={11}>Seleccionar (esc)</Typography>}
                    >
                        <Button
                            key={EDIT_MODE_SELECT}
                            variant={editMode === EDIT_MODE_SELECT ? 'outlined' : 'contained'}
                            onClick={selectClick}
                        >
                            <PanToolAltOutlinedIcon />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </Box>
        </ThemeProvider>
    )
}

export default PolygonsAndPointToolbox
