import React from "react"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useHotkeys } from 'react-hotkeys-hook';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';

import { GET_TRAINING_SET_BY_ID } from "../../../../queries/training-sets"
import { SELECTED_IMAGE } from "../../queries/state";
import { GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID, UPDATE_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID } from "../../queries/multi-label-classification-entries";

import lodash from "lodash"

function TagsMenu(props) {

    const { trainingSet } = useParams()

    const { data: { getTrainingSetById: { trainingSet: { classes = []} = {}} = {}} = {}} = useQuery(GET_TRAINING_SET_BY_ID, {
        variables: {
            params: {
                _id: trainingSet
            }
        }
    })
    const { data: { labelerSelectedImage } = {}} = useQuery(SELECTED_IMAGE)
    const { data: { getMultiLabelClassificationEntryById: { multiLabelClassificationEntry: { labels = [] } = {} } = {}} = {}} = useQuery(GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID, {
        variables: {
            params: {
                _id: labelerSelectedImage
            }
        },
        skip: !labelerSelectedImage
    })
    const [ updateClassificationEntryById ] = useMutation(UPDATE_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID)

    const onLabelsChange = label => async event => {
        if(labelerSelectedImage) {
            let lbls;
            if(event.target.checked) {
                lbls = [...labels, label]
            } else {
                const ind = labels.indexOf(label)
                lbls = [
                    ...labels.slice(0, ind),
                    ...labels.slice(ind + 1)
                ]
            }
            await updateClassificationEntryById({
                variables: {
                    params: {
                        _id: labelerSelectedImage,
                        labels: lodash.uniq(lbls)
                    },
                },
                refetchQueries: [
                    {
                        query: GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID,
                        variables: {
                            params: {
                                _id: labelerSelectedImage
                            }
                        }
                    }
                ],
                optimisticResponse: {
                    updateMultiLabelClassificationEntryById: {
                      __typename: "UpdateMultiLabelClassificationEntryByIdResponse",
                      status: "SUCCEEDED"
                    }
                },
                update: (proxy, _) => {
                    // Read the data from our cache for this query.
                    const { getMultiLabelClassificationEntryById: { multiLabelClassificationEntry }} = proxy.readQuery({ 
                        query: GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID, 
                        variables: {
                            params: {
                                _id: labelerSelectedImage
                            }
                        }
                    });
                    
                    // Write our data back to the cache with the new comment in it
                    proxy.writeQuery({ query: GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID,
                        variables: {
                            params: {
                                _id: labelerSelectedImage
                            }
                        },
                        data: {
                            getMultiLabelClassificationEntryById: {
                                multiLabelClassificationEntry: {
                                    ...multiLabelClassificationEntry,
                                    labels: lbls
                                },
                                __typename: "GetMultiLabelClassificationEntryByIdResponse"
                            }
                        }
                    });
                }
            })
        }
    }

    useHotkeys("1,2,3,4,5,6,7,8,9", (event, handler) => {
        if(labelerSelectedImage) {
            onLabelsChange(classes[parseInt(handler.key) - 1])({ target: { checked: !(labels.indexOf(classes[parseInt(handler.key) - 1]) > -1)}})
        }
    }, [labelerSelectedImage, classes, labels])

    return (
        <FormControl component="fieldset" style={{ display: "inline-block", height: "100%", width: "100%", borderLeft: "1px solid #AAA", background: "#FFF", padding: "10px 25px 10px 25px"}}>
            <FormLabel>Clases</FormLabel>
            
                {classes.map((clase, i) => (
                    <FormGroup row>
                        <FormControlLabel
                            value={clase}
                            control={
                                <Checkbox
                                    checked={labels.indexOf(clase) != -1}
                                    onChange={onLabelsChange(clase)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={clase}
                        />
                    </FormGroup>
                ))}
            
        </FormControl>
    )
}

export default TagsMenu