/**
 * Module responsible of renderization of annotations inside image viewer.
 * It is responsible to of its coordinates changes.
 */
import React, { useRef, Children, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import tinycolor from 'tinycolor2'
import _ from 'lodash'
import { polygonBounds } from 'geometric'

import {
    setAnnotationIndex,
    setByIndexInplaceAnnotation,
    clickOnPoint,
    clickOnEditArea,
    setToolbox,
} from '../../redux/images'
import {
    EDIT_MODE_SELECT,
    EDIT_MODE_REMOVE_POLYGONS,
    EDIT_MODE_REMOVE_POINTS,
    TOOLBOX_POLYGONS_AND_POINTS,
} from '../../constants'

// utils
/**
 * Compute the new draggable zone
 * @param {number} xUp - Left Up corner X coordinate
 * @param {number} yUp - Left Up corner Y coordinate
 * @param {number} xBt - Right Bottom corner X coordinate
 * @param {number} yBt - Right Bottom corner Y coordinate
 * @param {number} dragZone - %1 to expand the drag zone
 * @returns - [xUpNew, yUpNew, xBtNew, yBtNew]
 */
const computeDraggableZone = (xUp, yUp, xBt, yBt, dragZone) => {
    const width = xBt - xUp
    const height = yBt - yUp

    const increX = ((dragZone - 1) * width) / 2
    const increY = ((dragZone - 1) * height) / 2

    return [xUp - increX, yUp - increY, xBt + increX, yBt + increY]
}

/**
 * Convert coordinates to svg points string type
 * @param {number} xUp - Left Up corner X coordinate
 * @param {number} yUp - Left Up corner Y coordinate
 * @param {number} xBt - Right Bottom corner X coordinate
 * @param {number} yBt - Right Bottom corner Y coordinate
 * @returns - string
 */
const pointsToString = (xUp, yUp, xBt, yBt) =>
    `${xUp},${yUp} ${xBt},${yUp} ${xBt},${yBt} ${xUp},${yBt}`

/**
 * Invisible component to convert an element into a draggable component
 *
 * Inserts props to its children:
 *      * isDragging: True if the element is dragging
 */
const DraggableObject = ({ transform, moveEffect, children }) => {
    const [dragging, setDragging] = useState(false)
    const originMouse = useRef(false)
    return (
        <g
            onMouseDown={(event) => {
                setDragging(true)
                const transformedPoint = transform.applyInverseToPoint({
                    x: event.clientX,
                    y: event.clientY,
                })
                originMouse.current = [transformedPoint.x, transformedPoint.y]
            }}
            onMouseMoveCapture={(event) => {
                if (dragging) {
                    const transformedPoint = transform.applyInverseToPoint({
                        x: event.clientX,
                        y: event.clientY,
                    })
                    const movX = transformedPoint.x - originMouse.current[0]
                    const movY = transformedPoint.y - originMouse.current[1]
                    moveEffect(movX, movY)
                    originMouse.current = [transformedPoint.x, transformedPoint.y]
                }
                event.stopPropagation()
            }}
            onMouseUpCapture={(event) => {
                setDragging(false)
                event.stopPropagation()
            }}
            onMouseLeaveCapture={(event) => {
                event.stopPropagation()
                setDragging(false)
            }}
        >
            {Children.map(children, (obj) =>
                React.cloneElement(obj, { ...obj.props, isDragging: dragging }, obj.children)
            )}
        </g>
    )
}

DraggableObject.propTypes = {
    /** Transform to change the coordinated between reference systems. vx/zoom transform matrix */
    transform: PropTypes.func,
    /** Function to pass the mov in x and mov in y */
    moveEffect: PropTypes.func.isRequired,
    /** React children */
    children: (PropTypes.object || PropTypes.array).isRequired,
}
DraggableObject.defaultProps = {
    transform: (x, y) => {
        return [x, y]
    },
}
DraggableObject.returnTypes = {
    /** Is true when dragging */
    isDragging: PropTypes.bool.isRequired,
}

/**
 * Component that renders and control the edit point of the annotations
 */
const AnnotationPointEdit = ({
    x,
    y,
    color,
    complementaryColor,
    polygonIndex,
    pointIndex,
    isDragging,
    size,
}) => {
    const dispatch = useDispatch()

    const selectedPolIndex = useSelector(
        (state) => state.trainingSetSegmentation.images.selectedPolygon
    )
    const selectedPointIndex = useSelector(
        (state) => state.trainingSetSegmentation.images.selectedPoint
    )

    const isPolygonSelected = selectedPolIndex === polygonIndex
    const isPointSelected = isPolygonSelected && selectedPointIndex === pointIndex
    const colorModified = isPolygonSelected ? complementaryColor : color

    const xUp = x - size / 2
    const yUp = y - size / 2
    const xBt = x + size / 2
    const yBt = y + size / 2
    const widerDraggableZonePoints = pointsToString(...computeDraggableZone(xUp, yUp, xBt, yBt, 40))

    const onClickHandler = () => {
        dispatch(clickOnPoint(polygonIndex, pointIndex))
    }

    return (
        <g onClick={onClickHandler}>
            {/* Draggable zone to make the movement of element wider */}
            {isDragging && <polygon points={widerDraggableZonePoints} opacity="0" />}
            <circle
                className=""
                // points={`${xUp},${yUp} ${xBt},${yUp} ${xBt},${yBt} ${xUp},${yBt}`}
                cx={x}
                cy={y}
                r={size}
                fill={isPointSelected ? colorModified : '#ffffff'}
                stroke={isPointSelected ? '#ffffff' : colorModified}
                strokeWidth={size * 0.4}
                strokeOpacity="1"
                style={{ cursor: 'grab' }}
            />
        </g>
    )
}

AnnotationPointEdit.propTypes = {
    /** Center x */
    x: PropTypes.number.isRequired,
    /** Center y */
    y: PropTypes.number.isRequired,
    /** stroke color */
    color: PropTypes.string.isRequired,
    /** */
    polygonIndex: PropTypes.number.isRequired,
    pointIndex: PropTypes.number.isRequired,
    /** Size of the corner */
    size: PropTypes.number,
    ...DraggableObject.returnTypes,
}
AnnotationPointEdit.defaultProps = {
    size: 15,
}

/**
 * Component for the central part of the annotation. In this case is
 * used a <path> tag, where the subsegments drawn inside the outer polygon were holes.
 */
const AnnotationCentral = ({
    polygons,
    color,
    strokeWidth,
    strokeColor,
    isSelected,
    isDragging,
    onMouseDown,
    onDoubleClickHandler,
}) => {
    let pathData = ''
    polygons.forEach((pol) => {
        pol.forEach((point, index) => {
            if (index === 0) {
                pathData = `${pathData} M ${point[0]} ${point[1]}`
            } else {
                pathData = `${pathData} L ${point[0]} ${point[1]}`
            }
        })
        pathData = `${pathData} Z`
    })
    const [[xUp, yUp], [xBt, yBt]] = polygonBounds(polygons[0]) || [
        [0, 0],
        [0, 0],
    ]
    const widerDraggableZonePoints = pointsToString(...computeDraggableZone(xUp, yUp, xBt, yBt, 10))
    return (
        <g>
            {/* Draggable zone to make the movement of element wider */}
            {isDragging && <polygon points={widerDraggableZonePoints} opacity="0" />}
            <path
                d={pathData}
                fill={color}
                fillOpacity={0.3 * (1 + isSelected * 0.35)}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                strokeOpacity={0.75 * (1 + isSelected * 0.35)}
                style={{ cursor: isSelected ? 'grab' : 'auto' }}
                onMouseDown={onMouseDown}
                onDoubleClick={onDoubleClickHandler}
            />
        </g>
    )
}

AnnotationCentral.propTypes = {
    /** Polygons to draw.
     * [[[x1, y1], [x2, y2], ...], [[x1, y1], [x2, y2], ...], ...]
     * |________________________| |_____________________________|
     *        Outer polygon                 Holes
     */
    polygons: PropTypes.array.isRequired,
    /** Fill color */
    color: PropTypes.string.isRequired,
    /** Stroke color */
    strokeColor: PropTypes.string.isRequired,
    /** True if the annotation is selected */
    isSelected: PropTypes.string.isRequired,
    /** Execute when selected */
    onMouseDown: PropTypes.func.isRequired,
    onDoubleClickHandler: PropTypes.func,
    ...DraggableObject.returnTypes,
}

AnnotationCentral.defaultProps = {
    onDoubleClickHandler: null,
}

/**
 * Area to first create the annotation, then the annotations is modified
 * panning, adding or removing points. A svg polygon is created above the image to
 * allow the annotation drawing
 */
const EditableAnnotation = ({ transform }) => {
    const dispatch = useDispatch()

    const rectLeftUpCorner = transform.applyInverseToPoint({
        x: 0,
        y: 0,
    })

    return (
        <g
            onClick={(event) => {
                // Svg parent offsets
                const clientRect =
                    event.target.parentNode.parentNode.parentNode.getBoundingClientRect()
                const transformedPoint = transform.applyInverseToPoint({
                    x: event.clientX - clientRect.left,
                    y: event.clientY - clientRect.top,
                })
                dispatch(clickOnEditArea(transformedPoint.x, transformedPoint.y))
            }}
        >
            <rect
                style={{ cursor: 'crosshair' }}
                x={rectLeftUpCorner.x}
                y={rectLeftUpCorner.y}
                width={window.innerWidth / transform.transformMatrix.scaleX}
                height={window.innerHeight / transform.transformMatrix.scaleY}
                opacity="0"
            />
        </g>
    )
}

EditableAnnotation.propTypes = {
    /** Transformation object from vx/zoom */
    transform: PropTypes.object.isRequired,
}

/**
 * Control the whole annotation
 */
const Annotation = ({ index, transform }) => {
    // Get active point to render in order,
    // if not, the event of the corner are overlapped
    const activePoint = useRef(null)

    const dispatch = useDispatch()

    const selectedAnnotation = useSelector(
        (state) => state.trainingSetSegmentation.images.selectedAnnotation
    )
    const inPlaceAnnotation =
        useSelector(
            (state) =>
                _.get(
                    state.trainingSetSegmentation.images.inPlaceAnnotations,
                    state.trainingSetSegmentation.images.selectedEntry,
                    []
                )[index]
        ) || {}

    const classColor = useSelector(
        (state) =>
            _.get(
                state.trainingSetSegmentation.general.classProps,
                _.get(inPlaceAnnotation, 'class', null),
                {
                    color: '',
                }
            ).color
    )
    const editMode = useSelector(
        (state) => state.trainingSetSegmentation.images.editMode || EDIT_MODE_SELECT
    )

    const polygon = _.get(inPlaceAnnotation, 'polygon', [])

    const color = classColor.toLowerCase()

    const strokeColor = tinycolor(color).darken(5).toString()
    const complementaryColor = tinycolor(color).complement().toString()

    const isSelected = selectedAnnotation === index

    // Events
    const annotationMouseDown = () => {
        dispatch(setAnnotationIndex(index))
    }
    const annotationDoubleClick = () => {
        dispatch(setToolbox(TOOLBOX_POLYGONS_AND_POINTS))
    }

    const moveEffect = (movX, movY) => {
        const newPolygon = inPlaceAnnotation.polygon.map((pol) =>
            pol.map((point) => [point[0] + movX, point[1] + movY])
        )
        dispatch(
            setByIndexInplaceAnnotation(
                {
                    ...inPlaceAnnotation,
                    polygon: newPolygon,
                },
                index
            )
        )
    }

    const pointPanEffect = (polygonIndex, pointIndex, totalPointIndex) => (movX, movY) => {
        activePoint.current = totalPointIndex
        dispatch(
            setByIndexInplaceAnnotation(
                {
                    ...inPlaceAnnotation,
                    polygon: [
                        ...inPlaceAnnotation.polygon.slice(0, polygonIndex),
                        [
                            ...inPlaceAnnotation.polygon[polygonIndex].slice(0, pointIndex),
                            [
                                inPlaceAnnotation.polygon[polygonIndex][pointIndex][0] + movX,
                                inPlaceAnnotation.polygon[polygonIndex][pointIndex][1] + movY,
                            ],
                            ...inPlaceAnnotation.polygon[polygonIndex].slice(pointIndex + 1),
                        ],
                        ...inPlaceAnnotation.polygon.slice(polygonIndex + 1),
                    ],
                },
                index
            )
        )
    }

    let points = []
    polygon.forEach((pol, polygonIndex) => {
        pol.forEach((point, pointIndex) => {
            points.push({
                x: point[0],
                y: point[1],
                color: strokeColor,
                complementaryColor,
                moveEffect: pointPanEffect(polygonIndex, pointIndex, points.length),
                totalIndex: points.length,
                polygonIndex,
                pointIndex,
            })
        })
    })

    // Reordering render order
    points.push(...points.splice(activePoint.current, 1))

    points = points.map((point) => {
        return (
            <DraggableObject
                key={point.totalIndex}
                transform={transform}
                moveEffect={point.moveEffect}
            >
                <AnnotationPointEdit
                    {...{
                        ...point,
                        size: 8 / transform.transformMatrix.scaleX,
                    }}
                />
            </DraggableObject>
        )
    })
    let renderEditableZone = null
    if (
        isSelected &&
        editMode !== EDIT_MODE_SELECT &&
        editMode !== EDIT_MODE_REMOVE_POINTS &&
        editMode !== EDIT_MODE_REMOVE_POLYGONS
    ) {
        renderEditableZone = <EditableAnnotation transform={transform} />
    }

    const annotationRender = polygon.length > 0

    return (
        <React.Fragment>
            {annotationRender && (
                <DraggableObject transform={transform} moveEffect={moveEffect}>
                    <AnnotationCentral
                        {...{ polygons: polygon, color, strokeColor, isSelected }}
                        strokeWidth={4 / transform.transformMatrix.scaleX}
                        onMouseDown={annotationMouseDown}
                        onDoubleClickHandler={annotationDoubleClick}
                    />
                </DraggableObject>
            )}
            {annotationRender && isSelected && points}
            {renderEditableZone}
        </React.Fragment>
    )
}

Annotation.propTypes = {
    /** Index of annotation */
    index: PropTypes.number.isRequired,
    /** Transform for change coordinates vx/zoom */
    transform: PropTypes.object.isRequired,
}

export default Annotation
