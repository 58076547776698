import React, { useState } from "react"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Divider from "@material-ui/core/Divider"

import SwipeableViews from 'react-swipeable-views';

import Datasets from "./containers/datasets/scene"
import Jobs from "./containers/jobs/scene"
import Map from "./containers/map/scene"
import Viewer from "./containers/viewer/scene"
import Dataset from "./containers/dataset/scene"

const Inspection = () => {
    const [ leftTab, setLeftTab ] = useState(0)

    const onLeftTabChange = (event, newValue) => {
        setLeftTab(newValue)
    }

    return (
        <Grid container style={{ height: "100%"}}>
            <Grid item xs={2}>
                <Paper style={{ height: "100%", width: "100%"}}>
                    <Tabs value={leftTab} onChange={onLeftTabChange} variant="scrollable" scrollButtons="on">
                        <Tab label="Datasets"/>
                        <Tab label="Jobs"/>
                        <Tab label="Reports"/>
                    </Tabs>
                    <Divider />
                    <SwipeableViews
                        axis={'x'}
                        index={leftTab}
                        style={{ height: "calc(100% - 48px)", width: "100%" }}
                        containerStyle={{ height: "100%", width: "100%" }}
                    >
                        <Datasets/>
                        <Jobs/>
                    </SwipeableViews>
                </Paper>
            </Grid>
            <Grid container item xs direction="column">
                <Viewer/>
                <Map />
            </Grid>
            <Dataset />
        </Grid>
    )
}

export default Inspection