import gql from 'graphql-tag'

import { useQuery } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'

export const IS_ALLOWED = gql`
    query IsAllowed($params: IsAllowedRequest!) {
        isAllowed(params: $params)
    }
`

export const useIsAllowed = (params) => {
    const [allowed, setAllowed] = useState(false)
    const [loading, setLoading] = useState(true)

    const {
        data: { isAllowed } = {},
        loading: loadingResult,
        error,
    } = useQuery(IS_ALLOWED, {
        variables: { params },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    })

    useEffect(() => {
        if (!allowed) {
            setAllowed(isAllowed)
        }
        setLoading(loadingResult)
    }, [isAllowed, loadingResult])

    return { isAllowed: allowed, loading, error }
}
