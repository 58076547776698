import React from "react"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"

const Settings = props => {
    return (
        <List subheader={<ListSubheader component="div">General</ListSubheader>}>
            <ListItem button>
                <ListItemText primary="Name" secondary="name"/>
            </ListItem>
            <Divider />
            <ListItem>
                <Button variant="contained" fullWidth color={"#FF0000"}>Delete</Button>
            </ListItem>
        </List>
    )
}

export default Settings