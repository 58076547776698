import React from "react"

import Container from "@material-ui/core/Container"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from "@material-ui/core/Divider"

import { Switch, Route, Redirect } from "react-router-dom"

import Inspections from "./containers/inspections/scene"
import Settings from "./containers/settings/scene"

function ProjectView(props) {
    const section = props.match.params.section
    
    if(!section) {
        props.history.replace(props.match.url + "/inspections")
    }
    console.log(props.match)
    return (
        <Container>
            <Tabs value={section}>
                <Tab label="Inspections" value={"inspections"} onClick={() => props.history.push("inspections")}/>
                <Tab label="Settings" value={"settings"} onClick={() => props.history.push("settings")}/>
            </Tabs>
            <Divider/>
            <div style={{ padding: "20px 0"}}>
                <Switch>
                    <Route exact path={props.match.path.replace(":section", "inspections")} component={Inspections} />
                    <Route exact path={props.match.path.replace(":section", "settings")} component={Settings} />
                </Switch>
            </div>
        </Container>
    )
}


export default ProjectView