import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { Redirect } from "react-router-dom"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

import Entry from "../../../components/appcard"

import apps from "../../../../../apps"

function Products(props) {

    const { product, ...rest } = props.location.hash.replace("#", "").split("&")
    .reduce((acc, item) => {
        let keyvalue = item.split("=")
        return ({ ...acc, [keyvalue[0]]: keyvalue[1]})
    }, {})

    const params = Object.entries(rest).map(entry => entry.join("=")).join("&")
    return !!product ? 
        <Redirect to={props.match.url + "/" + product} /> : 
        <Container>
            {/* {
                process.env.NODE_ENV === "development" ? 
                    Object.values(apps).map(product => (
                        <Entry title={product.title} image={product.image} path={props.match.url + "/" + product.id}/>
                    )):
                    <Entry title={apps["horus"].title} image={apps["horus"].image} path={props.match.url + "/" + apps["horus"].id}/>
            } */}
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    Si todavía no ha contratado ningún plan puede contactar con ventas <a href="#" onClick={() => window.support.openChat()}>aquí</a>. Si se trata de un problema técnico puede contactar con soporte <a href="#" onClick={() => {window.support.openChat()}}>aquí</a>.
                </Grid>
            </Grid>
        </Container>
        
}

export default Products