import React, { Children, useRef, createRef, useEffect, useLayoutEffect } from 'react'
import PropTypes, { any } from 'prop-types'
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'

const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

/**
 * Clone recursively all the downstream childs of a react component.
 *
 * @param {React.Component} obj
 * @returns {React.Component} Clone child components
 */
const deepChildrenRender = (obj, additionalProps = {}) => {
    let tempChildren = []
    if (obj.props.children !== null && obj.props.children !== undefined) {
        if (obj.props.children[Symbol.iterator] === 'function') {
            tempChildren = [...tempChildren, ...obj.props.children]
        } else {
            tempChildren.push(obj.props.children)
        }
    }

    const clonedChildrens = Children.map(
        [...tempChildren, ...(additionalProps.children || [])] || [],
        (child) => {
            if (React.isValidElement(child)) {
                return deepChildrenRender(child)
            }
            return child
        }
    )
    return React.cloneElement(obj, { ...obj.props, ...additionalProps }, ...clonedChildrens)
}

const BoxCollider = ({ checkActive, onCollisionRef, help, children }) => {
    const scene = useThree((state) => state.scene)

    const raysArray = useRef(
        [...Array(24).keys()].map(() => {
            return createRef()
        })
    )
    const raysHelper = useRef(
        [...Array(24).keys()].map(() => {
            return createRef()
        })
    )
    const verticesRef = useRef(
        [...Array(24).keys()].map(() => {
            return new THREE.Vector3(0, 0, 0)
        })
    )
    const meshCenter = useRef(new THREE.Vector3(0, 0, 0))
    const intersectedColor = useRef(new THREE.Color(0xff0000))
    const notIntersectedColor = useRef(new THREE.Color(0x000000))

    if (onCollisionRef) {
        onCollisionRef.current = new THREE.Vector3(0, 0, 0) // eslint-disable-line no-param-reassign
    }

    const renderGroupRef = useRef()
    const colliderGroupRef = useRef()
    const bboxRef = useRef(
        new THREE.Box3(new THREE.Vector3(-0.5, -0.5, -0.5), new THREE.Vector3(0.5, 0.5, 0.5))
    )
    const meshRef = useRef()
    const boxGeometry = useRef(new THREE.BoxGeometry())
    const boxMaterial = useRef(new THREE.MeshStandardMaterial())
    boxMaterial.current.wireframe = true
    boxMaterial.current.color = new THREE.Color(0xb2beb5)
    boxMaterial.current.side = THREE.DoubleSide
    const boxPosition = useRef(new THREE.Vector3(0, 0, 0))

    useLayoutEffect(() => {
        const tempBox = new THREE.BoxGeometry(
            bboxRef.current.max.x - bboxRef.current.min.x,
            bboxRef.current.max.y - bboxRef.current.min.y,
            bboxRef.current.max.z - bboxRef.current.min.z
        )

        const vertices = tempBox.attributes.position.array
        verticesRef.current.forEach((vertex, index) => {
            vertex.set(vertices[3 * index], vertices[3 * index + 1], vertices[3 * index + 2])
        })
        tempBox.dispose()

        boxGeometry.current.setFromPoints(verticesRef.current)
    })

    useEffect(() => {
        renderGroupRef.current.parent.updateMatrixWorld(true)
        scene.userData = {
            ...scene.userData,
            meshesToGetIntersected: {
                ...(scene.userData.meshesToGetIntersected || {}),
                [meshRef.current.uuid]: meshRef.current,
            },
        }
        const raysArrayCleanup = raysArray.current

        // Change collider ruled by target re-render
        bboxRef.current.makeEmpty()
        const clonedElement = renderGroupRef.current.clone(true)
        clonedElement.rotation.set(0, 0, 0, 0)
        clonedElement.updateMatrixWorld(true)
        bboxRef.current.setFromObject(clonedElement, true)
        bboxRef.current.getCenter(boxPosition.current)
        clonedElement.removeFromParent()
        clonedElement.clear()

        const tempBox = new THREE.BoxGeometry(
            bboxRef.current.max.x - bboxRef.current.min.x,
            bboxRef.current.max.y - bboxRef.current.min.y,
            bboxRef.current.max.z - bboxRef.current.min.z
        )

        const vertices = tempBox.attributes.position.array

        verticesRef.current.forEach((vertex, index) => {
            vertex.set(vertices[3 * index], vertices[3 * index + 1], vertices[3 * index + 2])
        })
        tempBox.dispose()

        // Update collider mesh position and geometry
        boxGeometry.current.setFromPoints(verticesRef.current)

        // Recalculate to recenter
        bboxRef.current.setFromObject(renderGroupRef.current, true)
        bboxRef.current.getCenter(boxPosition.current)
        colliderGroupRef.current.parent.worldToLocal(boxPosition.current)
        colliderGroupRef.current.position.set(
            boxPosition.current.x,
            boxPosition.current.y,
            boxPosition.current.z
        )

        // Changing position of raysHelper if help is activated
        verticesRef.current.forEach((vertex, iVertex) => {
            vertex.applyMatrix4(meshRef.current.matrixWorld)
            meshRef.current.worldToLocal(vertex)
            const originToVertex = vertex.sub(meshRef.current.position)
            if (help) {
                raysHelper.current[iVertex].current.setLength(originToVertex.length())
                raysHelper.current[iVertex].current.setDirection(originToVertex.clone().normalize())
                raysHelper.current[iVertex].current.setColor(notIntersectedColor.current)
            }
            raysArray.current[iVertex].current = new THREE.Raycaster(
                meshRef.current.position.clone(),
                originToVertex.clone().normalize(),
                -0.01,
                originToVertex.length()
            )
        })
        const meshRefUUID = meshRef.current.uuid
        return () => {
            raysArrayCleanup.forEach((ray) => {
                if (ray.current && ray.current.dispose) {
                    ray.current.dispose()
                }
            })
            delete scene.userData.meshesToGetIntersected[meshRefUUID]
        }
    })

    useFrame(() => {
        const isActive = checkActive()
        if (isActive && raysArray.current[0].current) {
            meshCenter.current = meshRef.current.localToWorld(meshRef.current.position.clone())
            const vertices = meshRef.current.geometry.attributes.position.array
            const verticesCount = vertices.length
            let vIndex = 0
            const collidedDirections = []
            range(0, verticesCount - 1, 3).forEach((index) => {
                verticesRef.current[vIndex].set(
                    vertices[index],
                    vertices[index + 1],
                    vertices[index + 2]
                )
                const ray = raysArray.current[vIndex].current
                const vertex = verticesRef.current[vIndex]
                const originToVertex = meshRef.current.localToWorld(vertex).sub(meshCenter.current)
                ray.far = originToVertex.length()
                ray.set(meshCenter.current, originToVertex.normalize())
                const intersects = ray.intersectObjects(
                    Object.values(scene.userData.meshesToGetIntersected).filter(
                        (mesh) => mesh.uuid !== meshRef.current.uuid
                    )
                )
                if (help && raysHelper.current[vIndex].current) {
                    if (intersects.length !== 0) {
                        raysHelper.current[vIndex].current.setColor(intersectedColor.current)
                    } else {
                        raysHelper.current[vIndex].current.setColor(notIntersectedColor.current)
                    }
                }
                if (intersects.length !== 0) {
                    intersects.forEach((inter) => {
                        // Face collided coordinated to world
                        inter.face.normal.transformDirection(inter.object.matrixWorld)

                        // Check parallel directions
                        let parallel = false
                        for (let i = 0; i < collidedDirections.length; i += 1) {
                            if (
                                Math.round(
                                    inter.face.normal.dot(collidedDirections[i].direction)
                                ) === 1
                            ) {
                                parallel = true
                                break
                            }
                        }
                        if (!parallel) {
                            collidedDirections.push({
                                direction: inter.face.normal,
                                point: inter.point,
                            })
                        }
                    })
                }
                vIndex += 1
            })
            onCollisionRef.current = collidedDirections // eslint-disable-line no-param-reassign
        }
    })
    let { props } = children
    props = { ...props, children: [] }
    /* eslint-disable react/no-unknown-property */
    return (
        <group {...props} ref={children.ref}>
            <group ref={renderGroupRef}>
                {Children.map(children.props.children, (obj) => deepChildrenRender(obj))}
            </group>
            <group ref={colliderGroupRef}>
                <mesh
                    visible={help}
                    ref={meshRef}
                    geometry={boxGeometry.current}
                    material={boxMaterial.current}
                />
                {help &&
                    raysHelper.current.map((rayHelper, iRayHelper) => {
                        return <arrowHelper key={iRayHelper} ref={rayHelper} />
                    })}
                {help && <axesHelper args={[5]} />}
            </group>
            {help && <axesHelper args={[5]} />}
        </group>
    )
}

BoxCollider.propTypes = {
    checkActive: PropTypes.func,
    onCollisionRef: PropTypes.shape({ current: any }).isRequired,
    children: PropTypes.array.isRequired,
    help: PropTypes.bool,
}

BoxCollider.defaultProps = {
    checkActive: () => false,
    help: false,
}

export default BoxCollider
