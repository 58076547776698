import gql from "graphql-tag"

export const CREATE_HOTSPOT = gql`
    mutation CreateHotspot($params: CreateHotspotRequest!) {
        createHotspot(params: $params) {
            status
        }
    }
`

export const GET_HOTSPOTS_BY_IMAGE = gql`
    query GetHotspotsByImage($params: GetHotspotsByImageRequest!) {
        getHotspotsByImage(params: $params) {
            status
            hotspots {
                _id
                category
                bbox
            }
        }
    }
`

export const GET_HOTSPOT_BY_ID = gql`
    query GetHotspotById($params: GetHotspotByIdRequest!) {
        getHotspotById(params: $params) {
            status
            hotspot {
                _id
                category
                severity
            }
        }
    }
`

export const REMOVE_HOTSPOT_BY_ID = gql`
    mutation RemoveHotspotById($params: RemoveHotspotByIdRequest!) {
        removeHotspotById(params: $params) {
            status
        }
    }
`

export const UPDATE_HOTSPOT_BY_ID = gql`
    mutation UpdateHotspotById($params: UpdateHotspotByIdRequest!) {
        updateHotspotById(params: $params) {
            status
        }
    }

`

