import { combineReducers } from 'redux'

import general from './general'
import nodes from './nodes'
import parameters from './params'
import variables from './variables'

export default {
    id: 'designer',
    reducerMap: {
        designer: combineReducers({ general, nodes, parameters, variables }),
    },
}
