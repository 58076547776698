import gql from "graphql-tag"

export const GET_WIDGETS_BY_DASHBOARDS = gql`
    query GetWidgetsByDashboard($params: GetWidgetsByDashboardRequest) {
        getWidgetsByDashboard(params: $params) {
            status
            widgets {
                id
                name
                type
                properties {
                    name
                    value
                }
                size {
                    w
                    h
                }
                position {
                    x
                    y
                }
            }
        }
    }
`

export const UPDATE_WIDGET_BY_ID = gql`
    mutation UpdateWidgetById($params: UpdateWidgetByIdRequest) {
        updateWidgetById(params: $params) {
            status
        }
    }  
`