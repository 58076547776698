import React from 'react'

import NewModelDialog from './containers/new-model-dialog'
import ModelsList from './containers/models-list'

const Models = () => {
    return (
        <React.Fragment>
            <NewModelDialog />
            <ModelsList />
        </React.Fragment>
    )
}

export default Models
