import React, { useRef, useState, useCallback } from "react"

import { Zoom } from '@vx/zoom';
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"

import RGB from "./containers/rgb/scene"

const viewers = {
    "rgb": RGB
}

const Viewer = props => {
    
    const { entry: { type } = {}} = useSelector(state => state.inspector.universal)
    
    if(!type)
      return null

    const Current = viewers[type]

    return <Current />
}

export default Viewer