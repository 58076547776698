import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import RouterIcon from '@material-ui/icons/Router'

import Loading from 'components/Loading'
import { useIsAllowed } from 'queries/permissions'
import Menu from './containers/menu'
import NewGroupDialog from './containers/new-group-dialog'
import { GET_GROUPS, REMOVE_GROUP } from '../../queries/groups'

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        margin: `${theme.spacing(5)}px 0`,
    },
    menu: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
}))

const Groups = (props) => {
    const classes = useStyles()

    const { isAllowed: isCreateGroupAllowed } = useIsAllowed({
        action: 'createGroup',
        group: 'groups',
    })

    const { data: { getAllGroups: { groups = [] } = {} } = {}, loading } = useQuery(GET_GROUPS)

    const [removeGroup] = useMutation(REMOVE_GROUP)

    const onRemoveGroup = (_id) => (event) => {
        event.preventDefault()
        removeGroup({
            variables: {
                params: {
                    _id,
                },
            },
            refetchQueries: [
                {
                    query: GET_GROUPS,
                    variables: { params: {} },
                },
            ],
        })
    }

    return (
        <Container className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.title}>
                        Grupos
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Loading loading={loading}>
                        {groups.length ? (
                            <Paper>
                                <List>
                                    {groups.map((group) => (
                                        <Link key={group} to={`${props.match.url}/${group._id}`}>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <RouterIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={group.name}
                                                    secondary={group._id}
                                                    onClick={props.onClick}
                                                />
                                            </ListItem>
                                        </Link>
                                    ))}
                                </List>
                            </Paper>
                        ) : (
                            <div style={{ margin: '20px', textAlign: 'center' }}>
                                Cree un grupo para continuar &#8600;
                            </div>
                        )}
                    </Loading>
                </Grid>
            </Grid>
            <div className={classes.menu}>
                <Menu />
            </div>
            {isCreateGroupAllowed && <NewGroupDialog />}
        </Container>
    )
}

export default Groups
