import React from "react"

import { useSelector } from "react-redux"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

export default function(){

    const { logo, title } = useSelector(state => state.inspector.solar.reports.front_page)

    return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100%' }}
    >
        <Grid item xs={5}>
            <img src={logo} width={"100%"}/>
        </Grid> 
        <Grid item xs={12} style={{ marginTop: 50 }}>
            <Typography style={{ fontSize: "2em", textAlign: "center" }}>{title}</Typography>
        </Grid>   
  </Grid> 
}