import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import List from './containers/list/scene'
import Details from './containers/details/scene'
import ObjectDetection from './containers/object-detection/index'
import OCR from './containers/ocr/index'
import Segmentation from './containers/segmentation/index'
import Classification from './containers/classification/scene'
import MultiLabelClassification from './containers/multi-label-classification/scene'
import MultiViewClassification from './containers/multi-view-classification/scene'
import PointCloudClassification from './containers/point-cloud-classification/scene'

const TrainingSets = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/`} exact={true} component={List} />
            <Route path={`${match.url}/:trainingSet`} exact={true} component={Details} />
            <Route
                path={`${match.url}/:trainingSet/classification/:page?`}
                exact={true}
                component={Classification}
            />
            <Route
                path={`${match.url}/:trainingSet/multi-label-classification/:page?`}
                exact={true}
                component={MultiLabelClassification}
            />
            <Route
                path={`${match.url}/:trainingSet/object-detection/:page?`}
                component={ObjectDetection}
                exact={true}
            />
            <Route path={`${match.url}/:trainingSet/ocr/:page?`} exact={true} component={OCR} />
            <Route
                path={`${match.url}/:trainingSet/segmentation/:page?`}
                exact={true}
                component={Segmentation}
            />
            <Route
                path={`${match.url}/multi-view/classification/:trainingSet/:page?`}
                exact={true}
                component={MultiViewClassification}
            />
            <Route
                path={`${match.url}/point-clouds/classification/:trainingSet/:page?`}
                exact={true}
                component={PointCloudClassification}
            />
        </Switch>
    )
}
TrainingSets.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
}

export default TrainingSets
