import gql from 'graphql-tag'

export const GET_PARAMS_BY_GROUP = gql`
    query GetParamsByGroup($params: GetParamsByGroupRequest!) {
        getParamsByGroup(params: $params) {
            status
            params {
                _id
                name
                type
                local
                remote
            }
        }
    }
`

export const CREATE_PARAM = gql`
    mutation CreateParam($params: CreateParamRequest!) {
        createParam(params: $params) {
            status
        }
    }
`

export const UPDATE_PARAM = gql`
    mutation UpdateParamById($params: UpdateParamByIdRequest!) {
        updateParamById(params: $params) {
            status
        }
    }
`

export const REMOVE_PARAM_BY_ID = gql`
    mutation RemoveParamById($params: RemovedParamByIdRequest!) {
        removeParamById(params: $params) {
            status
        }
    }
`
