import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

const StepMonitorConfiguration = (props) => {
    const classes = useStyles()
    
    const [property, setProperty] = useState(null)

    const [thresholdType, setThresholdType] = useState(null)
    const [thresholdDuration, setThresholdDuration] = useState(null)
    const [thresholdDurationUnit, setThresholdDurationUnit] = useState(null)

    const onStepMonitorConfigurationAccept = () => {
        if (!property || !thresholdType || !thresholdDuration || !thresholdDurationUnit) {
            return
        }

        props.onPropertyChange(props.property, {
            property,
            type: thresholdType,
            duration: {
                unit: thresholdDurationUnit,
                value: thresholdDuration
            },
        })

        props.setActiveStep(props.nextStep)
    }

    const onStepMonitorConfigurationBack = () => {
        props.setActiveStep(props.prevStep)
    }

    const onPropertyChange = (event) => {
        setProperty(event.target.value)
    }

    const onThresholdTypeChange = (event) => {
        setThresholdType(event.target.value)
    }

    const onThresholdDurationChange = (event) => {
        setThresholdDuration(event.target.value)
    }

    const onThresholdDurationUnitChange = (event) => {
        setThresholdDurationUnit(event.target.value)
    }

    return (
        <Grid container>
            <Grid item xs={12} container spacing={2}>
                <Grid item xs={3}>
                    <Typography
                        variant="h4"
                        style={{ textAlign: 'center', margin: '10px 0px' }}
                    >
                        Se dispara cuando
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Propiedad</InputLabel>
                        <Select onChange={onPropertyChange}>
                            <MenuItem value="value">el valor</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <Typography
                        variant="h4"
                        style={{ textAlign: 'center', margin: '10px -10px' }}
                    >
                        está
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Tipo de umbral</InputLabel>
                        <Select onChange={onThresholdTypeChange}>
                            <MenuItem value="more-than">por encima</MenuItem>
                            <MenuItem value="less-than">por debajo</MenuItem>
                            <MenuItem value="range">fuera de un intervalo</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Typography
                        variant="h4"
                        style={{ textAlign: 'center', margin: '10px -20px' }}
                    >
                        durante
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ paddingRight: 5 }}>
                    <TextField 
                        label="Duración" 
                        variant="outlined" 
                        type="number" 
                        fullWidth 
                        onChange={onThresholdDurationChange} 
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="tiempo-label">Tiempo</InputLabel>
                        <Select labelId="tiempo-label" onChange={onThresholdDurationUnitChange}>
                            <MenuItem value="sec">Segundos</MenuItem>
                            <MenuItem value="min">Minutos</MenuItem>
                            <MenuItem value="hour">Horas</MenuItem>
                            <MenuItem value="day">Dias</MenuItem>
                            <MenuItem value="week">Semanas</MenuItem>
                            <MenuItem value="month">Meses</MenuItem>
                            <MenuItem value="year">Años</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.actions}>
                    <Button onClick={onStepMonitorConfigurationBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorConfigurationAccept}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorConfiguration
