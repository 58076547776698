import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import Fab from '@material-ui/core/Fab'
import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'

import { UPDATE_BLUEPRINT } from '../../../../queries/blueprints'

const useStyles = makeStyles((themeMakeStyles) => ({
    root: {
        position: 'fixed',
        bottom: themeMakeStyles.spacing(5),
        right: themeMakeStyles.spacing(5),
    },
}))

const Save = () => {
    const classes = useStyles()

    const [updating, setUpdating] = useState(false)
    const [finishing, setFinishing] = useState(false)

    const { blueprint } = useParams()

    const { nodes, links } = useSelector((state) => state.designer.nodes)
    const [updateBlueprint] = useMutation(UPDATE_BLUEPRINT)

    const timer = useRef(null)
    const onSave = async () => {
        setUpdating(true)
        const params = {
            _id: blueprint,
            update: {
                nodes: Object.keys(nodes).map((node) => ({
                    id: nodes[node].id,
                    name: nodes[node].type,
                    position: {
                        x: Math.round(nodes[node].position.x),
                        y: Math.round(nodes[node].position.y),
                    },
                    ports: nodes[node].ports.map((port, i) => ({
                        id: `${port.id || i}`,
                        name: port.name || '',
                        type: port.type,
                        properties: port.properties
                            ? {
                                  secondType: port.properties.secondType,
                              }
                            : undefined,
                    })),
                    properties: Object.keys(nodes[node].properties).map((property) => ({
                        type: property,
                        value: nodes[node].properties[property],
                    })),
                })),
                links: Object.keys(links).map((link) => ({
                    id: `${links[link].id}`,
                    from: {
                        nodeId: links[link].from.nodeId,
                        portId: links[link].from.portId,
                    },
                    to: {
                        nodeId: links[link].to.nodeId,
                        portId: links[link].to.portId,
                    },
                })),
            },
        }
        await updateBlueprint({
            variables: {
                params,
            },
        })
        setUpdating(false)
        setFinishing(true)
        timer.current = setTimeout(() => {
            setFinishing(false)
            clearTimeout(timer)
            timer.current = null
        }, 3000)
    }

    const showSaveButton = !updating && !finishing
    const showLoadingButton = updating && !finishing
    const showFinishedButton = finishing && !updating
    return (
        <div className={classes.root}>
            <Fab color={finishing ? 'success' : 'primary'} onClick={onSave}>
                {showSaveButton && <SaveIcon size={18} />}
                {showLoadingButton && <CircularProgress size={18} />}
                {showFinishedButton && <DoneIcon size={18} />}
            </Fab>
        </div>
    )
}

export default Save
