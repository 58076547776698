import React from 'react'
import { PropTypes } from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    return (
        <List className={classes.root}>
            <ListItem>
                <TextField
                    label="Ruta de Salida"
                    value={getProperty('output-key', null)}
                    onChange={onPropertyChangeBuilder('output-key')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    onChange={onPropertyChangeBuilder('ip')}
                    label="Dirección IP"
                    value={getProperty('ip', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    onChange={onPropertyChangeBuilder('device_id')}
                    label="Identificador de dispositivo"
                    value={getProperty('device_id', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Tipo de objeto</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder('obj_type')}
                        value={getProperty('obj_type', '')}
                    >
                        {/* Default BacNet objets implemented in BAC0 python 
                       https://bacpypes.readthedocs.io/en/latest/modules/object.html */}
                        <MenuItem value="accumulator">Acumulador</MenuItem>
                        <MenuItem value="analogInput">Entrada analógica</MenuItem>
                        <MenuItem value="analogOutput">Salida analógica</MenuItem>
                        <MenuItem value="analogValue">Valor analógico</MenuItem>
                        <MenuItem value="averaging">Media</MenuItem>
                        <MenuItem value="binaryInput">Entrada binaria</MenuItem>
                        <MenuItem value="binaryOutput">Salida binaria</MenuItem>
                        <MenuItem value="binaryValue">Valor binario</MenuItem>
                        <MenuItem value="calendar">Calendario</MenuItem>
                        <MenuItem value="command">Commando</MenuItem>
                        <MenuItem value="device">Dispositivo</MenuItem>
                        <MenuItem value="eventEnrollment">Suscripción a evento</MenuItem>
                        <MenuItem value="file">Archivo</MenuItem>
                        <MenuItem value="group">Grupo</MenuItem>
                        <MenuItem value="lifeSafetyPoint">Punto salva vidas</MenuItem>
                        <MenuItem value="lifeSafetyZone">Zona saslva vidas</MenuItem>
                        <MenuItem value="loop">Bucle</MenuItem>
                        <MenuItem value="multiStateInput">Entrada múltiple</MenuItem>
                        <MenuItem value="multiStateOutput">Salida múltiple</MenuItem>
                        <MenuItem value="multiStateValue">Valor múltiple</MenuItem>
                        <MenuItem value="notificationClass">Notificación</MenuItem>
                        <MenuItem value="program">Programa</MenuItem>
                        <MenuItem value="pulseConverter">Convertidor pulso</MenuItem>
                        <MenuItem value="schedule">Programar</MenuItem>
                        <MenuItem value="structuredView">Vista estructurada</MenuItem>
                        <MenuItem value="trendLog">Tendencias</MenuItem>
                    </Select>
                    <FormHelperText>Seleccione un tipo de código</FormHelperText>
                </FormControl>
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    onChange={onPropertyChangeBuilder('obj_id')}
                    label="Identificador de objecto"
                    value={getProperty('obj_id', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    onChange={onPropertyChangeBuilder('obj_property')}
                    label="Propiedad"
                    value={getProperty('obj_property', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    onChange={onPropertyChangeBuilder('interval_time')}
                    label="Intervalo"
                    value={getProperty('interval_time', 0)}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
