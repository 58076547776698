import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'

const View = ({ width, height, background, padding, margin, children }) => {
    const {
        connectors: { connect, drag },
    } = useNode()

    const style = {
        display: 'flex',
        flexDirection: 'column',
        width,
        height,
        background,
        padding,
        margin,
    }

    return (
        <div ref={(ref) => connect(drag(ref))} container style={style}>
            {children}
        </div>
    )
}

View.craft = {
    name: 'View',
    custom: {
        component: true,
    },
    props: {
        width: '100%',
        height: '100%',
        background: '#FFF',
        padding: '0px 0px 0px 0px',
        margin: '0px 0px 0px 0px',
    },
}

View.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    background: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

View.defaultProps = {
    width: '100%',
    height: '100%',
    background: '#FFF',
    padding: '0px 0px 0px 0px',
    margin: '0px 0px 0px 0px',
    children: null,
}

export default View
