import React, { useRef, useState, useCallback } from "react"

import { Zoom } from '@vx/zoom';
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"

const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0
}
const Viewer = props => {
    const zoom = useRef(null);
    
    const [ height, setHeight ] = useState(0)
    const [ width, setWidth ] = useState(0)
    const [ en, setEn ] = useState(true)
 
    const onRefChange = useCallback(node => {
        console.log(node)
        if (node === null) { 
          // DOM node referenced by ref has been unmounted
        } else {
            setHeight(node.clientHeight)
            setWidth(node.clientWidth - 1)
        }
    }, []);

    const { entry: { properties } = {}} = useSelector(state => state.inspector.universal)

    const imageHref = properties["url"]
    
    return (
        <Grid ref={onRefChange} item xs onClick={() => setEn(false)}>
            <div ref={zoom} style={{ height: height, width: width, background: "#E1E1E1", overflow: "hidden" }} >
                {
                    (height && width) ? 
                    <Zoom
                        height={height}
                        width={width}
                        scaleXMin={0.1}
                        scaleXMax={10}
                        scaleYMin={0.1}
                        scaleYMax={10}
                        transformMatrix={initialTransform}
                    >
                        {zoom => {
                            return (
                                <svg 
                                    height={height}
                                    width={width}
                                    style={{ cursor: zoom.isDragging ? 'grabbing' : 'grab' }}
                                >
                                    <g transform={zoom.toString()}>
                                        <image xlinkHref={imageHref} />
                                    </g>
                                    <rect
                                        height={height}
                                        width={width}
                                        rx={14}
                                        fill="transparent"
                                        onWheel={zoom.handleWheel}
                                        onMouseDown={zoom.dragStart}
                                        onMouseMove={zoom.dragMove}
                                        onMouseUp={zoom.dragEnd}
                                        onMouseLeave={() => {
                                            if (!zoom.isDragging) return;
                                            zoom.dragEnd();
                                        }}
                                        onDoubleClick={event => {
                                            const point = localPoint(event);
                                            zoom.scale({ scaleX: 1.1, scaleY: 1.1, point });
                                        }}
                                    />
                                </svg>
                            )
                        }}
                    </Zoom> : null
                }
            </div>
        </Grid>
    )
}

export default Viewer