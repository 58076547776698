import React from 'react'

import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'

import Fab from '@material-ui/core/Fab'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'

import Autocomplete from '@material-ui/lab/Autocomplete'

import AddIcon from '@material-ui/icons/Add'
import ChevronLeft from '@material-ui/icons/ChevronLeft'

import LoadingBackdrop from 'components/LoadingBackdrop'

import {
    GET_ALL_USERS,
    REMOVE_USER_BY_ID,
    GET_USER_BY_ID,
    UPDATE_USER_BY_ID,
} from '../../queries/users'

import { GET_ROLES, GET_ROLE_BY_ID } from '../../../roles/queries/roles'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    list: {
        padding: 0,
    },
}))

const Scene = () => {
    const classes = useStyles()
    const match = useRouteMatch()
    const history = useHistory()

    const { isAllowed: isRemoveUserAllowed } = useIsAllowed({
        group: 'settings',
        action: 'removeUser',
    })

    const { user } = match.params

    const [localLoading, setLocalLoading] = React.useState(false)
    const [changeRoleDialogOpen, setChangeRoleDialogOpen] = React.useState(false)
    const [localRole, setLocalRole] = React.useState(null)

    const { data: { getAllRoles: { roles = [] } = {} } = {}, loading: rolesLoading } = useQuery(
        GET_ROLES,
        {
            variables: { params: {} },
        }
    )

    const {
        loading: userLoading,
        error,
        data: { getUserById: { user: { email, role } = {} } = {} } = {},
    } = useQuery(GET_USER_BY_ID, {
        variables: { params: { id: user } },
    })

    const {
        loading: roleLoading,
        error: roleError,
        data: { getRoleById: { role: { name: roleName } = {} } = {} } = {},
    } = useQuery(GET_ROLE_BY_ID, {
        variables: { params: { id: role } },
        skip: !role || role === 'root',
    })

    const [updateUserById] = useMutation(UPDATE_USER_BY_ID)
    const [removeUserById] = useMutation(REMOVE_USER_BY_ID)

    const onRemoveUserClick = async () => {
        setLocalLoading(true)
        await removeUserById({
            variables: { params: { id: user } },
            refetchQueries: [{ query: GET_ALL_USERS, variables: { params: {} } }],
        })
        setLocalLoading(false)
        history.push('list')
    }

    const onChangeRole = () => {
        setChangeRoleDialogOpen(true)
    }

    const onRoleChange = (event, newValue) => {
        setLocalRole(newValue.id)
    }

    const onChangeRoleCancel = () => {
        setChangeRoleDialogOpen(false)
    }

    const onChangeRoleAccept = async () => {
        try {
            setLocalLoading(true)
            setChangeRoleDialogOpen(false)
            await updateUserById({
                variables: { params: { id: user, role: localRole } },
                refetchQueries: [{ query: GET_ALL_USERS, variables: { params: {} } }],
            })
        } catch (err) {
            console.log(err)
        } finally {
            setLocalLoading(false)
        }
    }

    const loading = localLoading || userLoading || rolesLoading || roleLoading

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button color="primary" component={Link} to="list" startIcon={<ChevronLeft />}>
                        Volver a usuarios
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <List className={classes.list}>
                            <ListItem button>
                                <ListItemText primary={'Email'} secondary={email} />
                            </ListItem>
                            <ListItem button disabled={role === 'root'}>
                                <ListItemText
                                    primary={'Role'}
                                    secondary={role === 'root' ? 'Root' : roleName}
                                    onClick={onChangeRole}
                                />
                            </ListItem>
                            <Divider />
                            {isRemoveUserAllowed && (
                                <ListItem
                                    button
                                    onClick={onRemoveUserClick}
                                    disabled={role === 'root'}
                                >
                                    <ListItemText
                                        primary={'Eliminar usuario'}
                                        secondary={
                                            'Eliminar el usuario. Esta acción no se puede deshacer.'
                                        }
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={changeRoleDialogOpen} maxWidth={'md'} fullWidth>
                <DialogTitle>Cambiar Rol</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={roles}
                        getOptionLabel={(option) => {
                            if (option.name) return option.name

                            const rl = roles.find((r) => r.id === option)

                            return rl ? rl.name : ''
                        }}
                        getOptionSelected={(option, value) => {
                            return option.id === value
                        }}
                        value={localRole}
                        onChange={onRoleChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Rol" variant="outlined" />
                        )}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onChangeRoleCancel}>Cancelar</Button>
                    <Button color="primary" onClick={onChangeRoleAccept}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackdrop loading={loading} />
        </Container>
    )
}

export default Scene
