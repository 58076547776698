export const SELECT_IMAGE = "SELECT_IMAGE"
export const HOVER_IMAGE = "HOVER_IMAGE"

export const selectImage = image => ({ type: SELECT_IMAGE, image })
export const hoverImage = image => ({ type: HOVER_IMAGE, image })

export default (state = { selected_image: null, hovered_image: null }, action) => {
    switch(action.type){
        case SELECT_IMAGE:
            return {...state, selected_image: action.image}
        case HOVER_IMAGE:
            return {...state, hovered_image: action.image}
        default:
            return state
    }
}