import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Detector from './nodes/detector/side-bar'
import Pulse from './nodes/pulse/side-bar'
import Camera from './nodes/camera/side-bar'
import EdgeDetector from './nodes/edge-detector/side-bar'
import Dataset from './nodes/dataset/side-bar'
import Transform from './nodes/transform/side-bar'
import clock from './nodes/clock/side-bar'
import javascript from './nodes/javascript/side-bar'
import SendEmail from './nodes/send-email/side-bar'
import http from './nodes/http-request/side-bar'
import classifier from './nodes/classifier/side-bar'
import classifierV2 from './nodes/classifier-v2/side-bar'
import classifierV3 from './nodes/classifier-v3/side-bar'
import rtsp from './nodes/rtsp-camera/side-bar'
import counter from './nodes/counter/side-bar'
import CameraUsb3Camera from './nodes/camera-usb3-vision/side-bar'
import join from './nodes/join/side-bar'
import ocr from './nodes/ocr/side-bar'
import ocr2 from './nodes/ocr2/side-bar'
import barcode from './nodes/barcode/side-bar'
import crop from './nodes/crop/side-bar'
import ObjectDetectionV3 from './nodes/object-detection-v3/side-bar'
import OracleSQL from './nodes/oracle-sql/side-bar'
import TcpReceive from './nodes/tcp-receive/side-bar'
import TcpSend from './nodes/tcp-send/side-bar'
// import udp from './nodes/udp/side-bar'
import HmiTextField from './nodes/hmi-text-field/side-bar'
import hmiButton from './nodes/hmi-button/side-bar'
import hmiImage from './nodes/hmi-image/side-bar'
import hmiText from './nodes/hmi-text/side-bar'
import hmiIndicator from './nodes/hmi-indicator/side-bar'
import hmiSelect from './nodes/hmi-select/side-bar'
import Delay from './nodes/delay/side-bar'
import Upload from './nodes/upload/side-bar'
import LoraServer from './nodes/lora-server/side-bar'
import BacNetDevice from './nodes/bacnet-device/side-bar'
import CameraGenicam from './nodes/camera-genicam/side-bar'
import OcrPower from './nodes/ocrPower/side-bar'
import OnParameterChange from './nodes/on-parameter-change/side-bar'
import Alarm from './nodes/alarm/side-bar'
import Conveyor from './nodes/conveyor/side-bar'
import CobotEdgeDetector from './nodes/cobot-edge-detector/side-bar'
import CobotPulse from './nodes/cobot-pulse/side-bar'
import AnomalyDetection from './nodes/anomaly-detection/side-bar'
import RemoveBackground from './nodes/remove-background/side-bar'
import CobotPalletizer from './nodes/cobot-palletizer/side-bar'
import SqlServer from './nodes/sql-server/side-bar'
import Udp from './nodes/udp/side-bar'
import If from './nodes/if/side-bar'
import Rotate from './nodes/rotate/side-bar'
import InRange from './nodes/in-range/side-bar'
import SwitchColor from './nodes/switch-color/side-bar'

import { setNodeProperties } from '../../redux/nodes'
import GrowableSideBar from '../../../../../../../../../../../../utils/common-components/growableSideBar'

const Functions = {
    Detector,
    Pulse,
    Camera,
    EdgeDetector,
    Dataset,
    Transform,
    clock,
    javascript,
    'send-email': SendEmail,
    'http-request': http,
    classifier,
    'classifier-v2': classifierV2,
    'classifier-v3': classifierV3,
    'rtsp-camera': rtsp,
    counter,
    uploader: Dataset,
    'camera-usb3-vision': CameraUsb3Camera,
    join,
    ocr,
    ocrPower: OcrPower,
    ocr2,
    barcode,
    crop,
    'object-detection-v3': ObjectDetectionV3,
    'oracle-sql': OracleSQL,
    'tcp-receive': TcpReceive,
    'tcp-send': TcpSend,
    'hmi-text-field': HmiTextField,
    'hmi-button': hmiButton,
    'hmi-image': hmiImage,
    'hmi-text': hmiText,
    'hmi-indicator': hmiIndicator,
    'hmi-select': hmiSelect,
    delay: Delay,
    upload: Upload,
    'lora-server': LoraServer,
    bacNetDevice: BacNetDevice,
    'camera-genicam': CameraGenicam,
    'on-parameter-change': OnParameterChange,
    alarm: Alarm,
    conveyor: Conveyor,
    'cobot-edge-detector': CobotEdgeDetector,
    'cobot-pulse': CobotPulse,
    'anomaly-detection': AnomalyDetection,
    'remove-background': RemoveBackground,
    'cobot-palletizer': CobotPalletizer,
    'sql-server': SqlServer,
    udp: Udp,
    if: If,
    rotate: Rotate,
    'in-range': InRange,
    'switch-color': SwitchColor,
}

const SideBar = () => {
    let { nodes = {}, links = {} } = useSelector((state) => state.designer.nodes)
    nodes = Object.keys(nodes).reduce((prev, next) => {
        return [...prev, nodes[next]]
    }, [])
    links = Object.keys(links).reduce((prev, next) => {
        return [...prev, links[next]]
    }, [])
    const selectedNode = useSelector((state) => state.designer.nodes.selected_node)
    const currentNode = useMemo(
        () => nodes.find((item) => item.id === selectedNode),
        [...nodes, selectedNode]
    )
    const nodeDefinition = useSelector(
        (state) =>
            state.designer.nodes.nodesDefinition[
                currentNode !== undefined ? currentNode.type || '' : ''
            ] || null
    )
    const inputLinks = useMemo(
        () => links.filter((link) => link.to.nodeId === selectedNode),
        [...links, selectedNode]
    )
    const inputNodes = useMemo(
        () => inputLinks.map((link) => nodes.find((node) => node.id === link.from.nodeId)),
        [...inputLinks, selectedNode]
    )

    // Common get properties and change properties
    const dispatch = useDispatch()
    const onPropertyChangedHandlerBuilder =
        (property, selector = (e) => e.target.value) =>
        (...args) => {
            dispatch(
                setNodeProperties({
                    nodeId: currentNode.id,
                    properties: {
                        type: property,
                        value: selector(...args),
                    },
                })
            )
        }

    const getProperty = (key, def) => {
        return currentNode.properties[key] || def
    }

    const CurrectSideBar = currentNode && Functions[currentNode.type]
    return CurrectSideBar ? (
        <GrowableSideBar
            paperProps={{
                style: {
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    scrollbarWidth: 0,
                    height: '100%',
                    width: 400,
                },
            }}
            anchor="right"
            size={300}
        >
            <CurrectSideBar
                node={currentNode}
                inputNodes={inputNodes}
                onPropertyChangeBuilder={onPropertyChangedHandlerBuilder}
                getProperty={getProperty}
                nodeDefinition={nodeDefinition}
            />
        </GrowableSideBar>
    ) : null
}

export default SideBar
