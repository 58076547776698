export const SET_HAS_PAYMENT_METHODS = "ONBOARDING/HAS_PAYMENT_METHODS/SET"
export const SET_HAS_DEFAULT_PAYMENT_METHOD = "ONBOARDING/HAS_DEFAULT_PAYMENT_METHOD/SET"
export const SET_HAS_ACTIVE_SUBSCRIPTIONS = "ONBOARDING/HAS_ACTIVE_SUBSCRIPTIONS/SET"

export const setHasPaymentMethods = (has) => ({ type: SET_HAS_PAYMENT_METHODS, has })
export const setHasDefaultPaymentMethod = has => ({ type: SET_HAS_DEFAULT_PAYMENT_METHOD, has })
export const setHasActiveSubscriptions = has => ({ type: SET_HAS_ACTIVE_SUBSCRIPTIONS, has })

export default (state = { has_payment_methods: false, has_default_payment_method: false, has_active_subscriptions: false}, action) => {
    switch(action.type) {
        case SET_HAS_PAYMENT_METHODS:
            return { ...state, has_payment_methods: action.has}
        case SET_HAS_DEFAULT_PAYMENT_METHOD:
            return {...state, has_default_payment_method: action.has}
        case SET_HAS_ACTIVE_SUBSCRIPTIONS:
            return {...state, has_active_subscriptions: action.has}
        default:
            return state
    }
}