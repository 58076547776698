export const SET_ACCESS_TOKEN  = "AUTH/SET/ACCESS_TOKEN"
export const SET_ID_TOKEN      = "AUTH/SET/ID_TOKEN"
export const SET_REFRESH_TOKEN = "AUTH/SET/REFRESH_TOKEN"

export const LOGOUT = "AUTH/LOGOUT"

export const setAccessToken  = (access_token) => ({type: SET_ACCESS_TOKEN, access_token})
export const setIdToken      = (id_token) => ({type: SET_ID_TOKEN, id_token})
export const setRefreshToken = (refresh_token) => ({type: SET_REFRESH_TOKEN, refresh_token})

export const logout = () => ({ type: LOGOUT })

export default (state = { access_token: null, id_token: null, refresh_token: null }, action) => {
    switch(action.type) {
        case SET_ACCESS_TOKEN:
            return {...state, access_token: action.access_token}
        case SET_ID_TOKEN:
            return {...state, id_token: action.id_token}
        case SET_REFRESH_TOKEN:
            return {...state, refresh_token: action.refresh_token}
        case LOGOUT:
            return {...state, access_token: null, id_token: null, refresh_token: null}
            
        default:
            return state;
    }
}