import { combineReducers } from 'redux'

import { reducer as reducerGeneral, initialState as initialStateGeneral } from './general'
import { reducer as reducerImages, initialState as initialStateImages } from './images'

export default {
    id: 'trainingSetOcr',
    initiaState: {
        general: initialStateGeneral,
        images: initialStateImages,
    },
    reducerMap: {
        trainingSetOcr: combineReducers({ general: reducerGeneral, images: reducerImages }),
    },
}
