import { OPEN_UPLOAD_DIALOG } from "../../../../../reducers/general"

export const SELECT_FILES = "SELECT_FILES"
export const NEXT_STEP = "NEXT_STEP"

export const selectFiles = files => ({ type: SELECT_FILES, files })

export const nextStep = () => ({ type: NEXT_STEP })

export default (state = { files: [], step: 0 }, action) => {
    switch(action.type) {
        case OPEN_UPLOAD_DIALOG:
            return { files: [], step: 0 }
        case SELECT_FILES:
            return {...state, files: action.files}
        case NEXT_STEP:
            return {...state, step: state.step + 1}
        default:
            return state
    }
}