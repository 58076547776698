import gql from 'graphql-tag'

import { useQuery, useMutation } from '@apollo/react-hooks'

export const GET_MODELS = gql`
    query getModels {
        getModels {
            models {
                _id
                name
                createdAt
                state
            }
        }
    }
`

export const GET_MODEL = gql`
    query GetModelById($params: GetModelByIdRequest!) {
        getModel(params: $params) {
            _id
            createdAt
            updatedAt
            name
            type
            framework
            epochs
            state
            shape
            artifacts
            classes
            trainingSet
            numTrainingSamples
        }
    }
`

export const GET_DELIVERABLES_BY_MODEL = gql`
    query GetDeliverablesByModel($params: GetDeliverablesByModelRequest!) {
        getDeliverablesByModel(params: $params) {
            status
            deliverables {
                _id
                model
                device
                url
            }
        }
    }
`

export const CREATE_MODEL = gql`
    mutation CreateModel($params: CreateModelRequest) {
        createModel(params: $params) {
            model {
                name
                state
            }
        }
    }
`

export const CREATE_DELIVERABLE = gql`
    mutation CreateDeliverable($params: CreateDeliverableRequest!) {
        createDeliverable(params: $params) {
            status
        }
    }
`

export const useGetModels = () => {
    const { data, error, loading } = useQuery(GET_MODELS)

    if (!data) return { models: [], error, loading }

    const models = data.getModels.models || []
    return { models, error, loading }
}

export const useGetModel = (modelId) => {
    const { data, error, loading } = useQuery(GET_MODEL, {
        variables: { params: { modelId } },
        skip: !modelId,
    })

    if (!data) return { model: {}, error, loading }

    const model = data.getModel || {}
    return { model, error, loading }
}

export const useGetDeliverablesByModel = (modelId) => {
    const { data, error, loading } = useQuery(GET_DELIVERABLES_BY_MODEL, {
        variables: { params: { model: modelId } },
        skip: !modelId,
    })

    if (!data) return { deliverables: [], error, loading }

    const deliverables = data.getDeliverablesByModel.deliverables || []
    return { deliverables, error, loading }
}

export const useCreateModel = () => {
    const [createModel] = useMutation(CREATE_MODEL)

    return async (params) => {
        await createModel({
            variables: { params },
            optimisticResponse: {
                __typename: 'Mutation',
                createModel: {
                    __typename: 'CreateModelResponse',
                    model: {
                        __typename: 'Model',
                        name: params.name,
                        state: 'CREATING',
                    },
                },
            },
            update: (cache, { data: { createModelData } }) => {
                const { getModels } = cache.readQuery({ query: GET_MODELS })
                cache.writeQuery({
                    query: GET_MODELS,
                    data: { getModels: { models: [...getModels.models, createModelData.model] } },
                })
            },
        })
    }
}

export const useCreateDeliverable = () => {
    const [createDeliverable, { data, error, loading }] = useMutation(CREATE_DELIVERABLE)

    const create = (params) => createDeliverable({ variables: { params } })

    return { create, data, error, loading }
}
