import React, { useEffect } from "react"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

import { EXPORT_CSV_DIALOG, CLOSE_EXPORT_CSV_DIALOG } from "../../queries/local"
import { GET_HOTSPOTS_BY_DATASET } from "../../queries/hotspots"

const download = require("downloadjs")

function ExportCsvDialog(props) {

    const { dataset } = useParams()

    const { data: { exportCsvDialog: { open = false } = {}} = {} } = useQuery(EXPORT_CSV_DIALOG)
    const { data: { getHotspotsByDataset: { hotspots = [] } = {}} = {} } = useQuery(GET_HOTSPOTS_BY_DATASET, { variables: { params: { dataset }}})
    const [ closeExportCsvDialog ] = useMutation(CLOSE_EXPORT_CSV_DIALOG)

    const onAccept = () => {
        if(hotspots.length > 0) {
            const csv = [
                [ "Número", "Tipo", "Latitud", "Longitud" ].join(","),
                ...hotspots.map((hotspot, index) => [ 
                    index + 1, 
                    hotspot.category, 
                    hotspot.position && hotspot.position.coordinates[1], 
                    hotspot.position && hotspot.position.coordinates[0],
                ].join(","))
            ].join("\n")
            download(csv, "hotspots.csv")
            closeExportCsvDialog()
        }
    }

    useEffect(() => {
        if(open) {
            onAccept()
        }
    }, [ open ])

    return (
        <Dialog open={open}>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button>Cancelar</Button>
                <Button onClick={onAccept}>Aceptar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ExportCsvDialog