import {
    OPEN_NEW_PAYMENT_METHOD_DIALOG,
    CLOSE_NEW_PAYMENT_MEHTOD_DIALOG,
    NEXT_STEP_NEW_PAYMENT_METHOD_DIALOG,
    BACK_STEP_NEW_PAYMENT_METHOD_DIALOG
} from "../actions/new-payment-mehtod-dialog"

export default (state = { open: false, step: 0, paymentMethodType: "bank" }, action) => {
    switch(action.type) {
        case OPEN_NEW_PAYMENT_METHOD_DIALOG:
            return {...state, open: true, step: 0 }
        case CLOSE_NEW_PAYMENT_MEHTOD_DIALOG:
            return {...state, open: false }
        case NEXT_STEP_NEW_PAYMENT_METHOD_DIALOG:
            return {...state, step: state.step + 1}
        case BACK_STEP_NEW_PAYMENT_METHOD_DIALOG:
            return {...state, step: state.step === 0 ? state.step - 1 : 0}
        default:
            return state
    }
}