import React, { useState, useRef, useCallback, useEffect } from "react"

import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"
import { useSelector, useDispatch } from "react-redux"

import { StaticMap } from 'react-map-gl'
import DeckGL from '@deck.gl/react'
import { ScatterplotLayer, GeoJsonLayer } from "@deck.gl/layers"

import Config from "config"

import Grid from "@material-ui/core/Grid"

import { GET_ENTRIES } from "../../../../queries/entries"
import { GET_PROJECT_BY_ID } from "../../../../../../queries/projects"

import { selectEntry } from "../../../../reducers/index"

const compilers = {
    "rgb": (entry) => {
        const byId = entry.properties.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

        try {
            const p = JSON.parse(byId["position"])
            
            return {
                _id: entry._id,
                type: "rgb",
                properties: byId,
                position: {
                    ...p,
                    coordinates: p.coordinates
                }
            }
        } catch(err) {
            return {
                _id: entry._id,
                type: "rgb",
                properties: byId,
                position: {
                    coordinates: []
                }
            }
        }
    },
    "geojson": (entry) => {
        try {
            const byId = entry.properties.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

            const g = JSON.parse(byId["geojson"])
            
            return {
                ...g,
                properties: {
                    _id: entry._id
                }
            }
        } catch(err) {
            return {
                type: "FeatureCollection",
                features: [],
                properties: {
                    _id: entry._id
                }
            }
        }
    }
}

const Map = (props) => {
    const root = useRef()
    const dispatch = useDispatch()

    const { survey, project } = useParams()
    const { dataset: { _id, type } = {}, entry = {} } = useSelector(state => state.inspector.universal)

    const { data: { getProjectById: { project: { position: { coordinates = [45,45]} = {}} = {}} = {} } = {}} = useQuery(GET_PROJECT_BY_ID, { variables: { params: { _id: project }}})
    const { data: { getHorusDatasetEntriesByDataset: { entries = [] } = {}} = {}} = useQuery(GET_ENTRIES, { variables: { params: { dataset: _id, type }} })
    
    const [ height, setHeight ] = useState(0)
    const [ width, setWidth ] = useState(0)
 
    const onRefChange = useCallback(node => {
        if (node === null) { 
          // DOM node referenced by ref has been unmounted
        } else {
            setHeight(node.clientHeight)
            setWidth(node.clientWidth - 1)
        }
    }, []);


    const [ viewport, setViewport ] = useState({
        longitude: -3.305275, 
        latitude: 39.10725277777778,
        zoom: 15,
        maxZoom: 24
    })

    useEffect(() => {
        if(root.current) {
            setViewport({
                ...viewport,
                height: root.current.offsetHeight,
                width: root.current.offsetWidth,
            })
        }
    }, [ root.current ])

    const onViewportChange = (newViewport) =>  {
        setViewport({ ...viewport, ...newViewport })
    }

    const getMapStyle = () => {
        const res = {
            "version": 8,
            "sources": {
                "base-map": {
                    "type": "raster",
                    "url": "mapbox://mapbox.satellite"
                }
            },
            "layers": [
                {
                    "id": "satellite",
                    "type": "raster",
                    "source": "base-map",
                    "minzoom": 0,
                    "maxzoom": 24
                }
            ],
            center: [0,0],
            zoom: 1
        }
        return res
    }


    const compiledEntries = (type && entries) ? entries.map(compilers[type]): []    

    const getLayers = () => {
        console.log("LAYERS", compiledEntries)
        let layers = []
        switch(type) {
            case "rgb":
                layers = [
                    ...layers,
                    new ScatterplotLayer({
                        id: "id",
                        data: compiledEntries,
                        pickable: true,
                        opacity: 0.8,
                        stroked: true,
                        filled: true,
                        radiusScale: 2,
                        fp64: true,
                        getPosition: d => d.position && d.position.coordinates,
                        getFillColor: [0, 0, 255],
                        getLineColor: d => d._id === entry._id ? [255, 0, 0] : [0, 0, 255],
                        onClick: info => dispatch(selectEntry(info.object._id, info.object.type, info.object.properties))
                    })
                ]
                break;
            case "geojson":
                layers = [
                    ...layers,
                    ...compiledEntries.map(c => {
                        return new GeoJsonLayer({
                            id: 'geojson-layer',
                            data: c,
                            pickable: true,
                            stroked: false,
                            filled: true,
                            extruded: true,
                            pointType: 'circle',
                            lineWidthScale: 20,
                            lineWidthMinPixels: 2,
                            getFillColor: [160, 160, 180, 200],
                            getLineColor: d => d.properties._id === entry._id ? [255, 0, 0] : [0, 0, 255],
                            getPointRadius: 100,
                            getLineWidth: 1,
                            getElevation: 30
                        });
                    })
                ]
                break;
        }

        return layers
    }

    return (
        <Grid ref={onRefChange} item xs>
            <div ref={root} style={{ height: "100%", width: width }}>
                <DeckGL 
                    initialViewState={{longitude: coordinates[0], latitude: coordinates[1], zoom: 15}}
                    controller={true}
                    style={{ position: "relative" }}
                    layers={getLayers()} 
                >
                    <StaticMap 
                        mapStyle={getMapStyle()}
                        mapboxApiAccessToken={Config.app.mapbox_token}
                    ></StaticMap>
                </DeckGL>
            </div>
        </Grid>
    )
}

export default Map