import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useHistory } from "react-router-dom"

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ImageIcon from '@material-ui/icons/Image';

import Actions from "./containers/actions"
import NewIndustrialDatasetDialog from "./containers/new-industrial-dataset-dialog"

import Loading from "components/Loading"

import { GET_INDUSTRIAL_DATASET_BY_PARENT } from "./queries/industrial-datasets"

function IndustrialDatasets(props) {

    const history = useHistory()

    const { data: { getIndustrialDatasetsByParent: { datasets = [] } = {} } = {}, loading} = useQuery(GET_INDUSTRIAL_DATASET_BY_PARENT, {
        variables: {
            params: {
                parent: null
            }
        }
    })

    return (
        <Container>
            <Typography style={{ padding: "30px 0" }} variant="h2">Datasets</Typography>
            <Loading loading={loading}>
                {
                     datasets.length === 0 ? 
                     <div style={{ margin: "20px", textAlign: "center"}}>
                         Cree un dataset para continuar
                         &#8600;
                     </div> :
                     <Paper style={{ marginTop: 20 }}>
                        <List>
                            {datasets.map(d => (
                                <ListItem button>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={d.name}  secondary={d.createdAt}  onClick={() => history.push("datasets/" + d._id + "/" + d.type)}/>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                }
            </Loading>
            <Actions/>
            <NewIndustrialDatasetDialog />
        </Container>
    )
}



export default IndustrialDatasets