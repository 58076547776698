import ParamTypeInteger from './integer'
import ParamTypeFloat from './float'
import ParamTypeString from './string'
import ParamTypeResource from './resource'
import ParamTypeBoolean from './boolean'

export const INTEGER_TYPE = 'integer'
export const FLOAT_TYPE = 'float'
export const STRING_TYPE = 'string'
export const RESOURCE_TYPE = 'resource'
export const BOOLEAN_TYPE = 'boolean'

const TYPES_OBJ = {}
TYPES_OBJ[INTEGER_TYPE] = { name: 'Integer', component: ParamTypeInteger }
TYPES_OBJ[FLOAT_TYPE] = { name: 'Float', component: ParamTypeFloat }
TYPES_OBJ[STRING_TYPE] = { name: 'String', component: ParamTypeString }
TYPES_OBJ[RESOURCE_TYPE] = { name: 'Resource', component: ParamTypeResource }
TYPES_OBJ[BOOLEAN_TYPE] = { name: 'Boolean', component: ParamTypeBoolean }

export default TYPES_OBJ
