import React, { Children, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { setNodeProperties } from '../redux/nodes'

// Values for each
// parameter types
export const TYPE_FIXED = 'fixed'
export const TYPE_PARAMETER = 'parameter'
export const TYPE_VARIABLE = 'variable'

/**
 * Clone recursively all the downstream childs of a react component.
 *
 * @param {React.Component} obj
 * @returns {React.Component} Clone child components
 */
const deepChildrenRender = (obj) => {
    const clonedChildrens = Children.map(obj.props.children || [], (child) => {
        if (React.isValidElement(child)) {
            return deepChildrenRender(child)
        }
        return child
    })
    return React.cloneElement(obj, { ...obj.props, children: clonedChildrens })
}

/**
 * Parametrize an entry. Add the option to choose
 * from fixed and parameter.
 */
const Parametrize = ({
    name,
    humanName,
    parametersFilter,
    typeSelectorProps,
    children: parameterRender,
}) => {
    // Local state to get the corresponding parameter
    const [selectedParameter, setParameter] = useState('')
    const [selectedVariable, setVariable] = useState('')

    // Set filter by parameter type if
    // parametersFilter is a string
    let filter = parametersFilter
    if (typeof parametersFilter === 'string') {
        filter = ({ type }) => {
            if (type === null) {
                return true
            }
            return type === parametersFilter
        }
    }
    // Redux hooks
    const dispatch = useDispatch()
    const nodeId = useSelector((state) => {
        return state.designer.nodes.selected_node
    })
    // // Filter parameters by the filter built with `filter`
    const parametersFiltered = useSelector((state) =>
        Object.keys(state.designer.parameters)
            .filter((obj) => filter(state.designer.parameters[obj]))
            .map((obj) => {
                return { ...state.designer.parameters[obj], id: obj }
            })
    )

    const variablesFiltered = useSelector((state) =>
        Object.keys(state.designer.variables)
            .filter((obj) => filter(state.designer.variables[obj]))
            .map((obj) => {
                return { ...state.designer.variables[obj], id: obj }
            })
    )

    let property = useSelector((state) => {
        return state.designer.nodes.nodes[state.designer.nodes.selected_node].properties[name] || ''
    })

    // Check if property exists
    if (property !== '') {
        property = JSON.parse(property)
    } else {
        property = { type: TYPE_FIXED }
    }
    // Back-compatibility
    if (typeof property !== 'object') {
        property = { type: TYPE_FIXED, value: property }
    }
    // Executed on type change, update global state
    const onPropertyTypeChange = (event) => {
        dispatch(
            setNodeProperties({
                nodeId,
                properties: {
                    type: name,
                    value: JSON.stringify({ ...property, type: event.target.value }),
                },
            })
        )
    }

    // Executed on value change, update local state and
    // global state
    const onPropertyValueChange = (event) => {
        let v
        switch (property.type) {
            case TYPE_PARAMETER:
                v = parametersFiltered.find((obj) => obj.id === event.target.value)
                setParameter(event.target.value)
                break
            case TYPE_VARIABLE:
                v = variablesFiltered.find((obj) => obj.id === event.target.value)
                setVariable(event.target.value)
                break
            default:
                break
        }

        dispatch(
            setNodeProperties({
                nodeId,
                properties: {
                    type: name,
                    value: JSON.stringify({ type: property.type, id: v.id, name: v.name }),
                },
            })
        )
    }

    useEffect(() => {
        setParameter(property.id || '')
        setVariable(property.id || '')
    }, [property.id])

    // Conditional render, if type==parameter the
    // set of available parameters must be shown.
    // if type==fixed the fixed input must be shown.
    let childrens
    switch (property.type) {
        case TYPE_PARAMETER:
            childrens = (
                <FormControl fullWidth>
                    <InputLabel>{humanName || name}</InputLabel>
                    <Select onChange={onPropertyValueChange} value={selectedParameter}>
                        {parametersFiltered.map((p) => {
                            return (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            )
            break
        case TYPE_VARIABLE:
            childrens = (
                <FormControl fullWidth>
                    <InputLabel>{humanName || name}</InputLabel>
                    <Select onChange={onPropertyValueChange} value={selectedVariable}>
                        {variablesFiltered.map((p) => {
                            return (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            )
            break
        default:
            childrens = parameterRender
    }

    return (
        <Grid container justifyContent="flex-end" display="flex" spacing={0}>
            <Grid
                item
                container
                direction="column"
                justifyContent="flex-end"
                xs={3}
                xl={3}
                sm={3}
                md={3}
                lg={3}
                {...typeSelectorProps}
            >
                <FormControl fullWidth>
                    <Select value={property.type} onChange={onPropertyTypeChange}>
                        <MenuItem value="fixed">FIXED</MenuItem>
                        <MenuItem value="parameter">PARAM</MenuItem>
                        <MenuItem value="variable">VAR</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                item
                container
                direction="column"
                justifyContent="flex-end"
                xs={9}
                xl={9}
                sm={9}
                md={9}
                lg={9}
            >
                {Children.map(childrens, (obj) => deepChildrenRender(obj))}
            </Grid>
        </Grid>
    )
}

Parametrize.propTypes = {
    /** Name of the property.
     * Is the name used to save
     * the property in the database
     */
    name: PropTypes.string.isRequired,
    /** Is used as human readable title */
    humanName: PropTypes.string,
    /** Filter function or string. If it is a string
     * the parameters are filtered by type. If it is a function
     * must accept as argument the object parameter and return false or true
     */
    parametersFilter: PropTypes.oneOf(PropTypes.func, PropTypes.string),
    /**
     * Props of type selector container
     */
    typeSelectorProps: PropTypes.object,
    /** Default props to pass the childrens */
    children: PropTypes.array,
}

Parametrize.defaultProps = {
    humanName: null,
    parametersFilter: () => true,
    children: [],
    typeSelectorProps: {},
}

export default Parametrize
