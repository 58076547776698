import React, { useState } from "react"

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ImageIcon from '@material-ui/icons/Image';
import MoreIcon from '@material-ui/icons/MoreHoriz';

function OrthophotosListViewRow(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const handleAction = action => () => {
        props[action]()
        handleClose()
    }

    return (
        <ListItem button>
            <ListItemIcon><ImageIcon/></ListItemIcon>
            <ListItemText primary={props.id} />
            <ListItemSecondaryAction>
                <IconButton onClick={handleClick}>
                    <MoreIcon/>
                </IconButton>
            </ListItemSecondaryAction>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleAction("download")}>Descargar</MenuItem>
                <MenuItem onClick={handleAction("remove")}>Eliminar</MenuItem>
            </Menu>
        </ListItem>
    )
}

export default OrthophotosListViewRow