import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@mui/material/CircularProgress'
import { InputLabel } from '@mui/material'

import Loading from 'components/Loading'
import { GET_PARAMS_BY_GROUP, REMOVE_PARAM_BY_ID, UPDATE_PARAM } from '../../queries/params'
import ParamsType from '../param-type/index'

import { useIsAllowed } from 'queries/permissions'

// Default values of state
const DEFAULT_PARAM = { type: '', local: '' }
const DEFAULT_VALUE = ''

/**
 * This component renders the list of
 * parameters. And launch the creation of
 * new parameters and its updating. To do that
 * take the parameters type defined in ParamsType
 */
const ParamsList = () => {
    // Hooks
    const { group } = useParams()
    const {
        data: { getParamsByGroup: { params: parametersGQL = [] } = {} } = {},
        loading: loadingGQL,
    } = useQuery(GET_PARAMS_BY_GROUP, { variables: { params: { group } }, pollInterval: 10000 })
    const [updateParameterGQL] = useMutation(UPDATE_PARAM)
    const [removeParamGQL] = useMutation(REMOVE_PARAM_BY_ID)
    const { isAllowed: isRemoveParamAllowed } = useIsAllowed({
        action: 'removeParamById',
        group: 'groups',
    })
    const { isAllowed: isUpdateParamAllowed } = useIsAllowed({
        action: 'updateParamById',
        group: 'groups',
    })
    // Initialization of local state
    const [open, setOpen] = useState(false)
    const [param, setParam] = useState(DEFAULT_PARAM)
    const [value, setValue] = useState(DEFAULT_VALUE)
    const [updateLoading, setUpdateLoading] = useState(false)
    // // State array to check whether element is in state deleting
    const [deletingStates, setDeletingStates] = useState(
        parametersGQL.reduce((prev, next) => {
            return { ...prev, [next._id]: false }
        }, [])
    )

    // Click on parameter element
    const onParamClicked = (paramClicked) => () => {
        setParam(paramClicked)
        setOpen(true)
    }

    // Click on accept change
    const onAcceptClicked = async () => {
        setUpdateLoading(true)
        await updateParameterGQL({
            variables: {
                params: {
                    _id: param._id,
                    value,
                },
            },
        })
        setUpdateLoading(false)
        setOpen(false)
    }

    // Click on cancel change
    const onCancelClicked = () => {
        setOpen(false)
    }

    // Click on delete change
    const onDeleteClicked = (paramId) => async () => {
        setDeletingStates({ ...deletingStates, [paramId]: true })
        await removeParamGQL({
            variables: {
                params: { _id: paramId },
            },
            refetchQueries: [
                {
                    query: GET_PARAMS_BY_GROUP,
                    variables: { params: { group } },
                },
            ],
            awaitRefetchQueries: true,
        })
        setDeletingStates({ ...deletingStates, [paramId]: false })
    }
    return (
        <React.Fragment>
            <Loading loading={loadingGQL}>
                {parametersGQL.length === 0 ? (
                    <div style={{ margin: '20px', textAlign: 'center' }}>
                        Cree un parámetro para continuar &#8600;
                    </div>
                ) : (
                    <Paper>
                        <List>
                            {parametersGQL.map((paramI) => (
                                <ListItem
                                    key={paramI._id}
                                    button
                                    onClick={onParamClicked(paramI)}
                                    disabled={!isUpdateParamAllowed}
                                >
                                    <ListItemText
                                        primary={paramI.name}
                                        secondary={`Desired: ${paramI.local} | Reported: ${paramI.remote}`}
                                    />
                                    {isRemoveParamAllowed && (
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={onDeleteClicked(paramI._id)}
                                            >
                                                {deletingStates[paramI._id] ? (
                                                    <CircularProgress size={20} />
                                                ) : (
                                                    <DeleteIcon />
                                                )}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                )}
            </Loading>
            <Dialog open={open}>
                <DialogTitle>Editar Parámetro</DialogTitle>
                {param.type === null ? (
                    <DialogContent>
                        <InputLabel>
                            No tenemos información sobre el tipo de parámetro, ¿de qué tipo es?
                        </InputLabel>
                        <FormControl margin="normal" fullWidth>
                            <Select
                                label="Tipo"
                                fullWidth
                                value={param.type}
                                onChange={(event) => {
                                    setParam({ ...param, type: event.target.value })
                                }}
                            >
                                {Object.keys(ParamsType).map((paramType) => {
                                    return (
                                        <MenuItem key={paramType} value={paramType}>
                                            {ParamsType[paramType].name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        {React.createElement(
                            param.type === '' ? () => null : ParamsType[param.type].component,
                            { value: param.local, setValue },
                            null
                        )}
                    </DialogContent>
                )}

                <DialogActions>
                    <Button color="primary" onClick={onCancelClicked} disabled={updateLoading}>
                        Cancelar
                    </Button>
                    {updateLoading ? (
                        <CircularProgress color="inherit" disableShrink size={25} />
                    ) : (
                        <Button
                            color="primary"
                            onClick={onAcceptClicked}
                            disabled={updateLoading}
                            autoFocus
                        >
                            Aceptar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
export default ParamsList
