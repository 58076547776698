export const OPEN_NEW_GROUP_DIALOG = "NEW_GROUP_DIALOG/OPEN"
export const CLOSE_NEW_GROUP_DIALOG = "NEW_GROUP_DIALOG/CLOSE"

export const openNewGroupDialog = () => ({ type: OPEN_NEW_GROUP_DIALOG })
export const closeNewGroupDialog = () => ({ type: CLOSE_NEW_GROUP_DIALOG })

export default (state={ new_group_dialog_open: false }, action) => {
    switch(action.type) {
        case OPEN_NEW_GROUP_DIALOG:
            return { new_group_dialog_open: true }
        case CLOSE_NEW_GROUP_DIALOG:
            return { new_group_dialog_open: false }
        default:
            return state
    }
}