import React, { useState } from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { makeStyles } from "@material-ui/styles"
import { useTranslation } from 'react-i18next';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Fab from "@material-ui/core/Fab"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Hidden from "@material-ui/core/Hidden"

import AddIcon from '@material-ui/icons/Add';

import Countries from "country-json/src/country-by-abbreviation.json"

import Loading from "components/Loading"

import { GET_PAYMENT_METHODS, CREATE_PAYMENT_METHOD, SET_DEFAULT_PAYMENT_METHOD} from "./queries/payment-methods"

const useStyles = makeStyles(theme => ({
    fab: {
        position: "fixed",
        bottom: 30, 
        right: 30
    },
    dialog: {
        padding: 20
    },
    tableWrapper: {
        overflowX: "auto"
    }
}))

function PaymentMethods(props) {

    const classes = useStyles()

    const { t } = useTranslation("private");

    const { data: { paymentMethods = []} = {}, loading} = useQuery(GET_PAYMENT_METHODS)
    const [ createPaymentMethod ] = useMutation(CREATE_PAYMENT_METHOD)
    const [ setDefaultPaymentMethod ] = useMutation(SET_DEFAULT_PAYMENT_METHOD)

    const [ newPaymentMethodDialogOpen, setNewPaymentMethodDialogOpen ] = useState(false)

    const [ createPaymentMethodLoading, setCreatedPaymentMethodLoading ] = useState(false)
 
    const [ name, setName ] = useState(false)
    const [ line1, setLine1 ] = useState(false)
    const [ nif, setNif ] = useState(false)
    const [ city, setCity ] = useState(false)
    const [ postCode, setPostCode ] = useState(false)
    const [ country, setCountry ] = useState(false)
    const [ cardNumber, setCardNumber ] = useState(false)
    const [ expDate, setExpDate ] = useState(null)
    const [ cvc, setCvc ] = useState(false)


    const newPaymentMethodFabClicked = () => {
        setNewPaymentMethodDialogOpen(true)
        setExpDate(null)
    }

    const newPaymentMethodDialogRequestClose = () => {
        setNewPaymentMethodDialogOpen(false)
    }

    const createPaymentMethodClicked = async () => {
        setCreatedPaymentMethodLoading(true)
        try {
            await createPaymentMethod({
                variables: {
                    params: {
                        number: cardNumber,
                        expMonth: expDate.month() + 1,
                        expYear: expDate.year(),
                        cvc,
                        name,
                        line1,
                        line2: nif,
                        postalCode: postCode,
                        city,
                        country
                    }
                }
            })
        } catch(err) {

        } finally {
            setCreatedPaymentMethodLoading(false)
        }
        
        newPaymentMethodDialogRequestClose()
    }

    return (
        <React.Fragment>
            <Dialog
                open={newPaymentMethodDialogOpen}
                onClose={newPaymentMethodDialogRequestClose}
                className={classes.dialog}
            >
                <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DialogTitle>{t("account.payment_methods.add.title")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField label={t("account.payment_methods.add.name")} margin="normal" variant="outlined" fullWidth onChange={(e) => setName(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t("account.payment_methods.address")} margin="normal" variant="outlined" fullWidth onChange={(e) => setLine1(e.target.value)}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label={t("account.payment_methods.vat")} margin="normal" variant="outlined" fullWidth onChange={(e) => setNif(e.target.value)}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label={t("account.payment_methods.city")} margin="normal" variant="outlined" fullWidth onChange={(e) => setCity(e.target.value)}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label={t("account.payment_methods.postal_code")} margin="normal" variant="outlined" fullWidth onChange={(e) => setPostCode(e.target.value)}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl margin="normal" variant="outlined" fullWidth>
                                        <Select 
                                            label={t("account.payment_methods.add.country.label")}
                                            placeholder={t("account.payment_methods.add.country.placeholder")}
                                            fullWidth 
                                            input={<OutlinedInput name="age" id="outlined-age-simple" />}
                                            value={country}
                                            onChange={e => setCountry(e.target.value)} 
                                        >
                                            {Countries.map(c => <MenuItem value={c.abbreviation}>{c.country}</MenuItem>)}
                                        </Select>
                                    </FormControl>                            
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t("account.payment_methods.add.card_number")} margin="normal" variant="outlined" fullWidth onChange={(e) => setCardNumber(e.target.value)}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        openTo="month"
                                        format="MM/YY"
                                        inputVariant="outlined"
                                        views={["month", "year"]}
                                        label="Expiration Date"
                                        placeholder="MM/YY"
                                        fullWidth 
                                        margin="normal"
                                        value={expDate}
                                        onChange={(date, value) => setExpDate(date)}
                                    />                            
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField label="CVC" margin="normal" variant="outlined" fullWidth onChange={(e) => setCvc(e.target.value)}/>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={newPaymentMethodDialogRequestClose} color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button onClick={createPaymentMethod} color="primary" autoFocus>
                            {t("common.accept")}
                        </Button>
                    </DialogActions>
                </MuiPickersUtilsProvider>
            </Dialog>
            <Fab color="primary" className={classes.fab} onClick={newPaymentMethodFabClicked}>
                <AddIcon/>
            </Fab>
            <Loading loading={loading}>
                <Paper>
                    <div className={classes.tableWrapper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t("account.common.number")}</TableCell>
                                    <TableCell align="center">{t("account.payment_methods.expiration_date")}</TableCell>
                                    <Hidden smDown><TableCell align="center">{t("account.payment_methods.address")}</TableCell></Hidden>
                                    <Hidden smDown><TableCell align="center">{t("account.payment_methods.vat")}</TableCell></Hidden>
                                    <Hidden smDown><TableCell align="center">{t("account.payment_methods.postal_code")}</TableCell></Hidden>
                                    <Hidden smDown><TableCell align="center">{t("account.payment_methods.city")}</TableCell></Hidden>
                                    <Hidden smDown><TableCell align="center">{t("account.payment_methods.country")}</TableCell></Hidden>
                                    <TableCell></TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {paymentMethods.map((pm, i) => (
                                <TableRow key={pm.id}>
                                    <TableCell component="th" scope="row">************{pm.card.last4}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{pm.card.exp_month + "/" + pm.card.exp_year}</TableCell>
                                    <Hidden smDown><TableCell component="th" scope="row" align="center">{pm.billingDetails.address.line1}</TableCell></Hidden>
                                    <Hidden smDown><TableCell component="th" scope="row" align="center">{pm.billingDetails.address.line2}</TableCell></Hidden>
                                    <Hidden smDown><TableCell component="th" scope="row" align="center" >{pm.billingDetails.address.postal_code}</TableCell></Hidden>
                                    <Hidden smDown><TableCell component="th" scope="row" align="center">{pm.billingDetails.address.city}</TableCell></Hidden>
                                    <Hidden smDown><TableCell component="th" scope="row" align="center">{pm.billingDetails.address.country}</TableCell></Hidden>
                                    <TableCell component="th" scope="row" align="right">
                                        { pm.default ? <Button variant="outlined" disabled>Default</Button> : null }
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {!pm.default ?
                                            <Button variant="text" onClick={() => props.setDefaultPaymentMethod({ paymentMethodId: pm.id})}>
                                                Set Default
                                            </Button>
                                        : null }
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </Loading>
        </React.Fragment>
    )
}

export default PaymentMethods