import gql from "graphql-tag";

export const GET_MONITORS = gql`
    query GetMonitors($params: GetMonitorsRequest) {
        getMonitors(params: $params) {
            status
            error
            monitors {
                id
                name
                status
            }
        }
    }
`;

export const CREATE_MONITOR = gql`
    mutation CreateMonitor($params: CreateMonitorRequest) {
        createMonitor(params: $params) {
            status
            error
        }
    }
`;


export const GET_MONITOR_BY_ID = gql`
    query GetMonitor($params: GetMonitorRequest) {
        getMonitor(params: $params) {
            status
            error
            monitor {
                id
                createdAt
                name
                status
                configuration
            }
        }
    }
`;

export const UPDATE_MONITOR = gql`
    mutation UpdateMonitor($params: UpdateMonitorRequest) {
        updateMonitor(params: $params) {
            status
            error
        }
    }
`;

export const REMOVE_MONITOR = gql`
    mutation RemoveMonitor($params: RemoveMonitorRequest) {
        removeMonitor(params: $params) {
            status
            error
        }
    }
`;