import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import List from '@material-ui/core/List';
import Typography from "@material-ui/core/Typography"

import OrthophotosListViewRow from "./components/orthophoto-row"

import { GET_ORTHOPHOTOS_BY_DATASET, REMOVE_ORTHOPHOTO_BY_ID } from "../../../../queries/orthophotos"

function OrthophotosListView(props) {

    const classes = useStyles()

    const { inspection } = useParams()

    const { data: { getOrthophotosByDataset: { orthophotos = []} = {}} = {} } = useQuery(GET_ORTHOPHOTOS_BY_DATASET, { variables: { params: { dataset: inspection }}})
    const [ removeOrthophotoById ] = useMutation(REMOVE_ORTHOPHOTO_BY_ID)

    const onRemoveOrthophotoClicked = _id => () => {
        removeOrthophotoById({
            variables: {
                params: {
                    _id
                }
            },
            refetchQueries: [
                { 
                    query: GET_ORTHOPHOTOS_BY_DATASET ,
                    variables: {
                        params: {
                            dataset: inspection
                        }
                    }
                }
            ]
        })
    }

    return orthophotos.length === 0 ?
        <Typography className={classes.empty}>No hay ortofotos. Puedes añadirlas con el botón +</Typography>:
        <List component="nav" aria-label="main mailbox folders">
            {orthophotos.map(orthophoto => (
                <OrthophotosListViewRow id={orthophoto._id} remove={onRemoveOrthophotoClicked(orthophoto._id)} />
            ))}
        </List>
}

const useStyles = makeStyles(theme => ({
    empty: {
        textAlign: "center",
        marginTop: 20
    }
}))

export default OrthophotosListView