import React from "react"

import { makeStyles } from '@material-ui/core/styles';

import List from "@material-ui/core/List"

import CreateJobsDialog from "./containers/create-jobs-dialog/scene";

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
      position: "absolute",
      bottom: 15,
      left: 75
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));

const Jobs = () => {

    const classes = useStyles();

    return (
        <div style={{ height: "100%", position: "relative"}}>
            <List>
                
            </List>
           <CreateJobsDialog/>
        </div>
    )
}

export default Jobs