import gql from 'graphql-tag'

export const GET_ROLES = gql`
    query GetAllRoles($params: GetAllRolesRequest!) {
        getAllRoles(params: $params) {
            roles {
                id
                name
                permissions {
                    group
                    action
                    allowed
                }
            }
        }
    }
`

export const GET_ROLE_BY_ID = gql`
    query GetRoleById($params: GetRoleByIdRequest!) {
        getRoleById(params: $params) {
            role {
                id
                name
                permissions {
                    group
                    action
                    allowed
                }
            }
        }
    }
`

export const CREATE_ROLE = gql`
    mutation CreateRole($params: CreateRoleRequest!) {
        createRole(params: $params) {
            role {
                id
                name
                permissions {
                    group
                    action
                    allowed
                }
            }
        }
    }
`

export const UPDATE_ROLE = gql`
    mutation UpdateRoleById($params: UpdateRoleByIdRequest!) {
        updateRoleById(params: $params) {
            role {
                id
                name
                permissions {
                    group
                    action
                    allowed
                }
            }
        }
    }
`

export const REMOVE_ROLE_BY_ID = gql`
    mutation RemoveRoleById($params: RemoveRoleByIdRequest!) {
        removeRoleById(params: $params) {
            status
        }
    }
`
