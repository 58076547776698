import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@mui/material/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ErrorIcon from '@mui/icons-material/Error'
import DoneIcon from '@mui/icons-material/Done'

import ButtonStatus from '../../../../../../../../../../../../../../utils/common-components/buttonStatus'

import {
    setAutolabelModel,
    setAutolabelDeliverable,
    setAutolabelConfidenceThreshold,
    setAutolabelClassThreshold,
    setAutolabelNmsThreshold,
} from '../../../../redux/settings'

import { createModelCpu } from '../../../../utils/onnx'

import {
    GET_DELIVERABLES_BY_MODEL,
    GET_MODELS,
    GET_MODEL,
} from '../../../../../../../models/queries/models'

const Scene = () => {
    const dispatch = useDispatch()

    const {
        autolabelModel,
        autolabelDeliverable,
        autolabelConfidenceThreshold,
        autolabelClassThreshold,
        autolabelNmsThreshold,
    } = useSelector((state) => state.trainingSetObjectDetection.settings)

    const [selectedModel, setSelectedModel] = useState(
        Object.hasOwn(autolabelModel || {}, '_id') ? autolabelModel._id : null
    )
    const [selectedDeliverable, setSelectedDeliverable] = useState(autolabelDeliverable)
    const [confidenceThreshold, setConfidenceThreshold] = useState(autolabelConfidenceThreshold)
    const [classThreshold, setClassThreshold] = useState(autolabelClassThreshold)
    const [nmsThreshold, setNmsThreshold] = useState(autolabelNmsThreshold)
    const [modelLoading, setModelLoading] = useState(false)
    const [localError, setLocalError] = useState(false)

    const { data: { getModels: { models = [] } = {} } = {}, error: errorModels } =
        useQuery(GET_MODELS)

    const { data: { getModel: modelInfo = {} } = {}, error: errorModel } = useQuery(GET_MODEL, {
        variables: { params: { modelId: selectedModel } },
    })

    const {
        data: { getDeliverablesByModel: { deliverables = [] } = {} } = {},
        error: errorDeliverables,
    } = useQuery(GET_DELIVERABLES_BY_MODEL, { variables: { params: { model: selectedModel } } })

    const onAccept = async () => {
        const deliverable = deliverables.find((d) => d._id === selectedDeliverable)

        setModelLoading(true)

        if (!deliverable) {
            setLocalError(true)
            setModelLoading(false)
            return
        }
        setLocalError(false)

        createModelCpu(deliverable._id, deliverable.url, modelInfo.shape)
            .then(() => {
                dispatch(setAutolabelModel(modelInfo))
                dispatch(setAutolabelDeliverable(selectedDeliverable))
                dispatch(setAutolabelConfidenceThreshold(confidenceThreshold))
                dispatch(setAutolabelClassThreshold(classThreshold))
                dispatch(setAutolabelNmsThreshold(nmsThreshold))

                setModelLoading(false)
                setLocalError(false)
            })
            .catch(() => {
                setLocalError(true)
                setModelLoading(false)
            })
    }

    const onSelectedModelChange = (event) => {
        setSelectedModel(event.target.value)
    }

    const onSelectedDeliverableChange = (event) => {
        const deliverable = deliverables.find((d) => d._id === event.target.value)

        setSelectedDeliverable(deliverable._id)
    }

    const onConfidenceThresholdChange = (event) => {
        setConfidenceThreshold(event.target.value)
    }

    const onClassThresholdChange = (event) => {
        setClassThreshold(event.target.value)
    }

    const onNmsThresholdChange = (event) => {
        setNmsThreshold(event.target.value)
    }

    const succededModels = useMemo(() => models.filter((m) => m.state === 'SUCCEEDED'), [models])

    const error = errorModels || errorModel || errorDeliverables || localError

    const errorIcon = (
        <ErrorIcon
            fontSize="large"
            color="error"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    const successIcon = (
        <DoneIcon
            fontSize="large"
            color="success"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    return (
        <List>
            <ListItem>
                <FormControl fullWidth disabled={modelLoading} variant="outlined">
                    <InputLabel id="model-select-label">Modelo</InputLabel>
                    <Select
                        labelId="model-select-label"
                        value={selectedModel}
                        onChange={onSelectedModelChange}
                        label="Modelo"
                    >
                        {succededModels.map((m) => {
                            return (
                                <MenuItem key={m._id} value={m._id}>
                                    {m.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <FormControl fullWidth disabled={modelLoading} variant="outlined">
                    <InputLabel>Entregable</InputLabel>
                    <Select
                        value={selectedDeliverable}
                        onChange={onSelectedDeliverableChange}
                        label="Entregable"
                    >
                        {deliverables.map((d) => {
                            return (
                                <MenuItem key={d._id} value={d._id}>
                                    {d._id}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <TextField
                    fullWidth
                    disabled={modelLoading}
                    label="Umbral de confianza"
                    value={confidenceThreshold}
                    onChange={onConfidenceThresholdChange}
                />
            </ListItem>
            <ListItem>
                <TextField
                    fullWidth
                    disabled={modelLoading}
                    label="Umbral de clase"
                    value={classThreshold}
                    onChange={onClassThresholdChange}
                />
            </ListItem>
            <ListItem>
                <TextField
                    fullWidth
                    disabled={modelLoading}
                    label="Umbral de NMS"
                    value={nmsThreshold}
                    onChange={onNmsThresholdChange}
                />
            </ListItem>
            <ListItem>
                <ButtonStatus
                    buttonChildren="Guardar"
                    buttonProps={{
                        disabled: modelLoading,
                        color: 'primary',
                        variant: 'contained',
                        onClick: onAccept,
                    }}
                    loading={modelLoading}
                    error={error}
                    errorIcon={errorIcon}
                    successIcon={successIcon}
                    animationTimeout={3000}
                />
            </ListItem>
        </List>
    )
}

export default Scene
