import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"

import Grid from "@material-ui/core/Grid"

import { GET_TRAINING_SETS_ENTRIES_BY_TRAINING_SET } from "../../../../../../queries/training-sets"

import { setSelectedEntry } from "../../../../reducers"

const Data = () => {
    const dispatch = useDispatch()

    const { trainingSet } = useParams()

    const { data: { horus: { trainingSets: { getTrainingSetEntriesByTrainingSet: { entries = [] } = {}} = {}} = {}} = {}} = useQuery(GET_TRAINING_SETS_ENTRIES_BY_TRAINING_SET, { 
        variables: { params: { trainingSet, labelType: "polygon", dataType: "rgb", limit: 100, offset: 0, filters: [] }}
    })

    const onEntryClicked = _id => () => {
        dispatch(setSelectedEntry(_id))
    }
    
    return (
        <Grid container>
            {
                entries.map((e, i) => {
                    const byId = e.properties.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})
                    return (
                        <Grid item xs={6}>
                            <img src={byId["url"]} width={"100%"} height={"auto"} onClick={onEntryClicked(e._id)} />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}   

export default Data