import React from "react"

import { Switch, Route, Redirect } from "react-router-dom"

import Surveys from "./containers/surveys/scene"
import Viewer from "./containers/viewer/scene"

const Universal = (props) => {
    return (
        <Switch>
            <Redirect from={props.match.url + "/:project"} to={props.match.url + "/:project/surveys"} exact />
            <Route path={props.match.url + "/:project/:section"} exact component={Surveys} />
            <Route path={props.match.url + "/:project/surveys/:survey"} component={Viewer} />
        </Switch>
    )
}

export default Universal