import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import {
    BOOLEAN_TYPE,
    RESOURCE_TYPE,
    STRING_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'
import { GET_RESOURCES_BY_TYPE } from '../../../../../../queries/resources'
import { GET_MODELS, GET_DELIVERABLES_BY_MODEL } from '../../../../../../../models/queries/models'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const ModelAndDeliverable = ({ initValue, setValue }) => {
    const [model, setModel] = useState(null)
    const [deliverable, setDeliverable] = useState(null)

    const { data: { getModels: { models = [] } = {} } = {} } = useQuery(GET_MODELS)
    const { data: { getDeliverablesByModel: { deliverables = [] } = {} } = {} } = useQuery(
        GET_DELIVERABLES_BY_MODEL,
        { variables: { params: { model } }, skip: !model }
    )

    const onModelChanged = (event) => {
        setModel(event.target.value)
    }

    const onDeliverableChanged = (event) => {
        setDeliverable(event.target.value)
        setValue(`${model}.${event.target.value}`)
    }

    useEffect(() => {
        const splitted = initValue.split('.')
        setModel(splitted[0])
        setDeliverable(splitted[1])
    }, [initValue])

    return (
        <Grid container columnSpacing={0}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel shrink={model}>Modelo</InputLabel>
                    <Select onChange={onModelChanged} value={model} displayEmpty>
                        {models
                            .filter((m) => m.state === 'SUCCEEDED')
                            .map((m) => (
                                <MenuItem key={m._id} value={m._id}>
                                    {m.name || m._id}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel shrink={deliverable}>Entregable</InputLabel>
                    <Tooltip title={<Typography fontSize={12}>{deliverable}</Typography>}>
                        <Select onChange={onDeliverableChanged} value={deliverable} displayEmpty>
                            {deliverables.map((d) => {
                                return (
                                    <MenuItem key={d._id} value={d._id}>
                                        {d.device}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </Tooltip>
                </FormControl>
            </Grid>
        </Grid>
    )
}

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const { group } = useParams()

    const { data: { getResourcesByType: { resources = [] } = {} } = {} } = useQuery(
        GET_RESOURCES_BY_TYPE,
        {
            variables: {
                params: {
                    group,
                    type: 'machine-learning-v2',
                },
            },
        }
    )

    return (
        <Grid container columnSpacing={100} className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Entrada"
                    value={
                        JSON.parse(getProperty('input-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('input-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={1}>
                <Tooltip
                    title={
                        <Typography fontSize={14}>
                            Obsoleto desde versión 28. Utilizar modelo y entregable en su lugar.
                        </Typography>
                    }
                >
                    <WarningAmberIcon fontSize="large" style={{ color: '#ff9800' }} />
                </Tooltip>
            </Grid>
            <Grid item xs={11}>
                <Parametrize name="model" humanName="Modelo" parametersFilter={RESOURCE_TYPE}>
                    <FormControl fullWidth>
                        <InputLabel>Modelo</InputLabel>
                        <Select
                            value={
                                JSON.parse(getProperty('model', JSON.stringify({ value: '' })))
                                    .value
                            }
                            onChange={onPropertyChangeBuilder('model', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            {resources.map((resource) => (
                                <MenuItem key={resource._id} value={resource._id}>
                                    {resource.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="model-uri"
                    humanName="Modelo y entregable"
                    parametersFilter={STRING_TYPE}
                >
                    <ModelAndDeliverable
                        initValue={
                            JSON.parse(getProperty('model-uri', JSON.stringify({ value: '' })))
                                .value
                        }
                        setValue={onPropertyChangeBuilder('model-uri', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e })
                        )}
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="optimized" humanName="Optimizar" parametersFilter={BOOLEAN_TYPE}>
                    <FormControl fullWidth>
                        <InputLabel>Optimizar</InputLabel>
                        <Select
                            value={
                                JSON.parse(getProperty('optimized', JSON.stringify({ value: '' })))
                                    .value
                            }
                            onChange={onPropertyChangeBuilder('optimized', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            <MenuItem key="true" value="true">
                                Activado
                            </MenuItem>
                            <MenuItem key="false" value="false">
                                Desactivado
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
