import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles(theme => ({
    page: {
        position: "relative",
        height: 1200,
        width: 1200/Math.sqrt(2),
        margin: "15px auto",
        padding: 20,
        borderRadius: 1,
        "@media print": {
            margin: 0,
            padding: 0,
            height: 1000,
            width: 1000/Math.sqrt(2)
        }
    }
}))

export default function(props) {

    const classes = useStyles()

    return (
        <Paper className={classes.page}>
            {
                props.children
            }
        </Paper>
    )
}