import React from "react"

import { useSelector } from "react-redux"
import { useQuery } from "@apollo/react-hooks"

import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

import UploadRgbImagesDialog from "./containers/upload-rgb-images-dialog/scene";
import { GET_ENTRIES } from "../../../../../../../../queries/entries";

const RGB = props => {
    const { dataset: { _id, type } = {} } = useSelector(state => state.inspector.universal)

    const { data: { getHorusDatasetEntriesByDataset: { entries = [] } = {}} = {}} = useQuery(GET_ENTRIES, { variables: { params: { dataset: _id, type }} })

    if(!entries) {
        return null
    }

    const images = entries.map(e => {
        const byId = e.properties.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

        return {
            url: byId["url"]
        }
    })

    return (
        <div style={{ position: "relative", height: "100%", width: "100%", padding: "auto 0" }}>
            <Grid container style={{ maxHeight: "calc(100vh - 140px)", overflow: "auto" }}>
                {
                    images.map(im => {
                        return (
                            <Grid item xs={6}>
                                <img src={im.url} width="100%" height={"auto"}/>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Paper style={{ position: "absolute", bottom: 0, width: "100%", height: 50 }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ height: "100%", padding: "0 5px"}}>
                    <Grid item xs={4}>
                        <UploadRgbImagesDialog/>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}

export default RGB