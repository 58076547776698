import React, { useState } from "react"

import { useMutation } from "@apollo/react-hooks"

import Grid from "@material-ui/core/Grid"
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import PlanCard from "../../../plan-card"

import { SELECT_PLAN } from "../../../../../../queries/local"

function Plan(props) {
    
    const { plans = [], subscriptions = []} = props

    const [ interval, setInterval ] = useState("month")

    const [ selectPlan ] = useMutation(SELECT_PLAN)

    const onIntervalChange = e => {
        e.target.checked ? setInterval("year"): setInterval("month")
    }

    const onSelected = i => () => {
        const item = subscriptions.find(it => plans.findIndex(p => p._id === it.plan._id) >= 0)
        selectPlan({
            variables: {
                params: {
                    ...item ? { item: item._id } : {},
                    plan: i,
                    quantity: 1,
                    action: item ? "replace" : "add"
                }
            }
        })
    }
    
    return (
        <React.Fragment>
        <Grid container spacing={3}>
            {plans
                .sort((item1, item2) => item1.order - item2.order)
                .map(plan => (
                    plan.interval === interval && 
                        <Grid item xs={4}>
                            <PlanCard onClick={onSelected(plan._id)} selected={subscriptions.filter(item => item.plan._id === plan._id).length > 0} title={plan.title} price={plan.price} features={plan.features} />
                        </Grid>
                ))
            }
        </Grid>
        <Grid container justify="center" style={{ margin: "20px 0" }}>
            <Grid item xs={2}>
                <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Mensual</Grid>
                        <Grid item>
                            <Switch color={"primary"} checked={interval === "year"} onChange={onIntervalChange} />
                        </Grid>
                        <Grid item>Anual</Grid>
                    </Grid>
                </Typography>
            </Grid>
        </Grid>
    </React.Fragment>
    )
}

export default Plan