import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'

const Scroll = ({ height, width, children }) => {
    const {
        connectors: { connect, drag },
    } = useNode((node) => {
        return {
            data: node.data,
        }
    })

    const style = {
        height,
        width,
        overflowY: 'auto',
    }

    return (
        <div ref={(ref) => connect(drag(ref))} style={style} canvas>
            {children}
        </div>
    )
}

Scroll.craft = {
    displayName: 'Scroll',
    custom: {
        component: true,
    },
    rules: {},
    props: {
        height: '100%',
        width: '100%',
        padding: '5px 5px 5px 5px',
        margin: '0px 0px 0px 0px',
        background: '#FFF',
    },
}

Scroll.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    children: PropTypes.array,
}

Scroll.defaultProps = {
    height: '100%',
    width: '100%',
    children: [],
}

export default Scroll
