export const OPEN_UPLOAD_DIALOG = "UPLOAD_DIALOG/OPEN"
export const CLOSE_UPLOAD_DIALOG = "UPLOAD_DIALOG/CLOSE"

export const OPEN_ORTHOPHOTOS_UPLOAD_DIALOG = "DIALOGS/UPLOAD_ORTHOPHOTOS/OPEN"
export const CLOSE_ORTHOPHOTOS_UPLOAD_DIALOG = "DIALOGS/UPLOAD_ORTHOPHOTOS/CLOSE"

export const OPEN_ANALYSE_DIALOG = "DIALOGS/ANALYSE_DIALOG/OPEN"
export const CLOSE_ANALYSE_DIALOG = "DIALOGS/ANALYSE_DIALOG/CLOSe"

export const openUploadDialog = () => ({ type: OPEN_UPLOAD_DIALOG })
export const closeUploadDialog = () => ({ type: CLOSE_UPLOAD_DIALOG })

export const openOrthophotosUploadDialog = () => ({ type: OPEN_ORTHOPHOTOS_UPLOAD_DIALOG })
export const closeOrthophotosUploadDialog = () => ({ type: CLOSE_ORTHOPHOTOS_UPLOAD_DIALOG })

export const openAnalyseDialog = () => ({ type: OPEN_ANALYSE_DIALOG })
export const closeAnalyseDialog = () => ({ type: CLOSE_ANALYSE_DIALOG })

export default (state = { upload_dialog_open: false, analyse_dialog_open: false }, action) => {
    switch (action.type) {
        case OPEN_UPLOAD_DIALOG:
            return {...state, upload_dialog_open: true}
        case CLOSE_UPLOAD_DIALOG:
            return {...state, upload_dialog_open: false}
        case OPEN_ORTHOPHOTOS_UPLOAD_DIALOG:
            return {...state, orthophotos_upload_dialog_open: true }
        case CLOSE_ORTHOPHOTOS_UPLOAD_DIALOG:
            return {...state, orthophotos_upload_dialog_open: false }
        case OPEN_ANALYSE_DIALOG:
            return {...state, analyse_dialog_open: true}
        case CLOSE_ANALYSE_DIALOG:
            return {...state, analyse_dialog_open: false}
        default:
            return state;
    }
}



