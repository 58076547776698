const SET_VARIABLES_BULK = 'SET_VARIABLES_BULK'

export const setBulkVariables = (variables) => ({
    type: SET_VARIABLES_BULK,
    variables,
})

export default (
    state = {
        variables: {},
    },
    action
) => {
    switch (action.type) {
        case SET_VARIABLES_BULK:
            return {
                ...state.variables,
                ...action.variables.reduce((prev, next) => {
                    return {
                        ...prev,
                        [next._id]: {
                            name: next.name || '',
                            value: next.local || null,
                            type: next.type || null,
                        },
                    }
                }, {}),
            }
        default:
            return state
    }
}
