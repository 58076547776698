import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'

import theme from '../../../../../../../../../../../../theme'
import { removeByIndexInPlaceAnnotation } from '../../redux/images'

/**
 *  Component responsible of Annotations toolbox
 */
const AnnToolbox = () => {
    const dispatch = useDispatch()
    const selectedAnnotation = useSelector(
        (state) => state.trainingSetSegmentation.images.selectedAnnotation
    )
    const deleteClick = () => {
        dispatch(removeByIndexInPlaceAnnotation(selectedAnnotation))
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    '& > *': {
                        m: 1,
                    },
                }}
                style={{ position: 'absolute' }}
            >
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="contained"
                    color="primary"
                >
                    {[
                        <Button key="delete" onClick={deleteClick}>
                            <DeleteSweepIcon />
                        </Button>,
                    ]}
                </ButtonGroup>
            </Box>
        </ThemeProvider>
    )
}

export default AnnToolbox
