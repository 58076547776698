import React from 'react'
import { Layers } from '@craftjs/layers'
import MaterialCard from '@material-ui/core/Card'

import Settings from './containers/settings/scene'

const SideBar = () => (
    <div style={{ height: 'calc(100vh - 120px)' }}>
        <MaterialCard style={{ height: 'calc(40% - 10px)', marginBottom: 10 }}>
            <Settings />
        </MaterialCard>
        <MaterialCard style={{ height: '60%', overflowY: 'auto' }}>
            <Layers />
        </MaterialCard>
    </div>
)

export default SideBar
