import React from 'react'
import { PropTypes } from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const code = getProperty('code', '')
    const scan = getProperty('scan', '') === 'true'

    return (
        <List className={classes.root}>
            <ListItem>
                <TextField
                    label="Ruta de Entrada"
                    value={getProperty('input-key', null)}
                    onChange={onPropertyChangeBuilder(
                        'input-key',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Ruta de Salida"
                    value={getProperty('output-key', null)}
                    onChange={onPropertyChangeBuilder(
                        'output-key',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    step="1"
                    onChange={onPropertyChangeBuilder(
                        'crop-x',
                        (event) => event.target.value || ''
                    )}
                    label="Crop Offset X"
                    value={getProperty('crop-x', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    step="1"
                    onChange={onPropertyChangeBuilder(
                        'crop-y',
                        (event) => event.target.value || ''
                    )}
                    label="Crop Offset Y"
                    value={getProperty('crop-y', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    step="1"
                    onChange={onPropertyChangeBuilder(
                        'crop-width',
                        (event) => event.target.value || ''
                    )}
                    label="Crop Width"
                    value={getProperty('crop-width', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    step="1"
                    onChange={onPropertyChangeBuilder(
                        'crop-height',
                        (event) => event.target.value || ''
                    )}
                    label="Crop Height"
                    value={getProperty('crop-height', '')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Tipo de Código</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder(
                            'code',
                            (event) => event.target.value || ''
                        )}
                        value={code}
                    >
                        <MenuItem value="datamatrix">Data Matrix</MenuItem>
                        <MenuItem value="ean-13">EAN13</MenuItem>
                    </Select>
                    <FormHelperText>Seleccione un tipo de código</FormHelperText>
                </FormControl>
            </ListItem>
            <ListItem>
                <ListItemText primary="Detectar" />
                <ListItemSecondaryAction>
                    <Checkbox
                        checked={getProperty('detect', '') === 'true'}
                        onChange={onPropertyChangeBuilder('detect', (event) =>
                            event.target.checked ? 'true' : 'false'
                        )}
                        disabled={!(code === 'datamatrix' || code === 'ean-13')}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Optimizar modelo de detección</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder(
                            'optimized',
                            (event) => event.target.value || ''
                        )}
                        value={getProperty('optimized', 'false')}
                    >
                        <MenuItem value="true">Activado</MenuItem>
                        <MenuItem value="false">Desactivado</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <TextField
                    type="number"
                    min="0"
                    onChange={onPropertyChangeBuilder(
                        'threshold',
                        (event) => event.target.value || ''
                    )}
                    label="Umbral"
                    value={getProperty('threshold', '')}
                    fullWidth
                    disabled={!(code === 'datamatrix' || code === 'ean-13')}
                />
            </ListItem>
            <ListItem>
                <ListItemText primary="Escanear" />
                <ListItemSecondaryAction>
                    <Checkbox
                        checked={scan}
                        onChange={onPropertyChangeBuilder('scan', (event) =>
                            event.target.checked ? 'true' : 'false'
                        )}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <TextField
                    label="Escala"
                    type="number"
                    value={getProperty('scale', '')}
                    onChange={onPropertyChangeBuilder('scale', (event) => event.target.value || '')}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Forma"
                    type="number"
                    min="0"
                    step="1"
                    value={getProperty('shape', '')}
                    onChange={onPropertyChangeBuilder('shape', (event) => event.target.value || '')}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Difuminado"
                    min="0"
                    step="1"
                    type="number"
                    value={getProperty('blur', '')}
                    onChange={onPropertyChangeBuilder('blur', (event) => event.target.value || '')}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Contraste"
                    type="number"
                    value={getProperty('contrast', '')}
                    onChange={onPropertyChangeBuilder(
                        'contrast',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Intentos"
                    min="0"
                    step="1"
                    type="number"
                    value={getProperty('max_tries', '')}
                    onChange={onPropertyChangeBuilder(
                        'max_tries',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Timeout (ms)"
                    type="number"
                    value={getProperty('timeout', '')}
                    onChange={onPropertyChangeBuilder(
                        'timeout',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Gamma (desde)"
                    type="number"
                    min={0}
                    value={getProperty('gamma_start', 0)}
                    onChange={onPropertyChangeBuilder(
                        'gamma_start',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Gamma (hasta)"
                    type="number"
                    value={getProperty('gamma_end', 0)}
                    onChange={onPropertyChangeBuilder(
                        'gamma_end',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Delta giro"
                    type="number"
                    value={getProperty('turn_step', 0)}
                    onChange={onPropertyChangeBuilder(
                        'turn_step',
                        (event) => event.target.value || ''
                    )}
                    fullWidth
                    disabled={!scan}
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
