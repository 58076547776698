import React from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'

import { GET_DEVICES_BY_GROUP } from '../../../../../../queries/devices'
import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import { INTEGER_TYPE } from '../../../../../group/containers/params/containers/param-type/index'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()
    const { group } = useParams()

    const { data: { getDevicesByGroup: { devices = [] } = {} } = {} } = useQuery(
        GET_DEVICES_BY_GROUP,
        { variables: { params: { group } } }
    )

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Dispositivo</InputLabel>
                    <Select
                        value={getProperty('device', '')}
                        onChange={onPropertyChangeBuilder('device')}
                    >
                        {devices.map((deviceI) => (
                            <MenuItem key={deviceI._id} value={deviceI._id}>
                                {deviceI.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        value={JSON.parse(getProperty('pin', JSON.stringify({ value: '' }))).value}
                        onChange={onPropertyChangeBuilder('pin', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        label="Pin de salida"
                    />
                </FormControl>
            </Grid>
            <Grid item sm={12}>
                <Parametrize name="duration" humanName="Duración" parametersFilter={INTEGER_TYPE}>
                    <FormControl fullWidth>
                        <TextField
                            type="number"
                            min="0"
                            step="1"
                            value={
                                JSON.parse(getProperty('duration', JSON.stringify({ value: '' })))
                                    .value
                            }
                            onChange={onPropertyChangeBuilder('duration', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            label="Duración (ms)"
                        />
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
