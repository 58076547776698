import React from "react"

import { useSnackbar } from 'notistack';

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import DurationUnit from './containers/duration-unit/scene'
import DurationValue from './containers/duration-value/scene'

const Scene = (props) => {

    const config = props.config || {}
    const trigger = config.trigger || {}

    const property = trigger.property || ""
    const type = trigger.type || ""

    return (
        <List style={{ height: "100%" }}>
            <ListItem>
                <ListItemText primary="Propiedad" secondary={property} />
            </ListItem>
            <ListItem>
                <ListItemText primary="Tipo de disparador" secondary={type} />
            </ListItem>
            <DurationValue config={props.config} />
            <DurationUnit config={props.config} />
        </List>
    )
}

export default Scene
