import { INFER_IMAGE } from '../sagas/predict'

const OPEN_IMAGE_VIEWER = 'FALCON/DATASETS/VIEWER/OPEN_IMAGE_VIEWER'
const CLOSE_IMAGE_VIEWER = 'FALCON/DATASETS/VIEWER/CLOSE_IMAGE_VIEWER'
const SET_SELECTED_IMAGE = 'FALCON/DATASETS/VIEWER/SET_SELECTED_IMAGE'
const INFER_IMAGE_SUCCESS = 'INFER_IMAGE_SUCCESS'
const SET_ANNOTATIONS = 'FALCON/DATASETS/VIEWER/SET_ANNOTATIONS'
const SET_INFERENCE_RUNNING = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_RUNNING'

export const openImageViewer = (image) => ({ type: OPEN_IMAGE_VIEWER, ...image })
export const closeImageViewer = () => ({ type: CLOSE_IMAGE_VIEWER })
export const setSelectedImage = (image) => ({ type: SET_SELECTED_IMAGE, ...image })
export const setAnnotations = (annotations) => ({ type: SET_ANNOTATIONS, annotations })

export default (
    state = {
        open: false,
        url: '',
        annotations: [],
        inferenceRunning: false,
    },
    action
) => {
    switch (action.type) {
        case OPEN_IMAGE_VIEWER:
            return {
                ...state,
                open: true,
                ...action,
            }
        case CLOSE_IMAGE_VIEWER:
            return {
                ...state,
                open: false,
            }
        case SET_SELECTED_IMAGE:
            return {
                ...state,
                url: action.url,
                annotations: [],
            }
        case SET_ANNOTATIONS:
            return {
                ...state,
                annotations: action.annotations,
            }
        case INFER_IMAGE:
            return {
                ...state,
                inferenceRunning: true,
            }
        case INFER_IMAGE_SUCCESS:
            return {
                ...state,
                inferenceRunning: false,
                annotations: action.annotations.map((a) => ({
                    classLabel: a.classLabel,
                    x: a.bbox[0],
                    y: a.bbox[1],
                    width: a.bbox[2],
                    height: a.bbox[3],
                })),
            }
        default:
            return state
    }
}
