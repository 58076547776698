import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/react-hooks"

import TextField from "@material-ui/core/TextField"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

import { CREATE_RESOURCE, GET_RESOURCES } from "../../../../../../../../queries/resources"

function Camera(props) {

    const { group } = useParams()

    const [ name, setName ] = useState("")
    const [ number, setNumber ] = useState("")

    const [ createResource ] = useMutation(CREATE_RESOURCE)

    const onAccept = async () => {
        await createResource({
            variables: { 
                params: {
                    group,
                    type: "camera",
                    name,
                    config: number
                }
            },
            refetchQueries: [
                {
                    query: GET_RESOURCES,
                    variables: {
                        params: {
                            group
                        }
                    }
                }
            ]
        })
        props.onCancel()
    }

    const onNameChanged = event => {
        setName(event.target.value)
    }

    const onNumberChanged = event => {
        setNumber(event.target.value)
    }

    return (
        <React.Fragment>
            <DialogContent>
                <TextField label="Nombre" fullWidth value={name} onChange={onNameChanged}/>
                <TextField label="Número" fullWidth type="number" value={number} step="1" onChange={onNumberChanged}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>Cancelar</Button>
                <Button onClick={onAccept}>Aceptar</Button>
            </DialogActions>
        </React.Fragment>
    )
}

export default Camera