import gql from "graphql-tag"

export const CREATE_TRAINING_SET = gql`
    mutation CreateTrainingSet($params: HorusCreateTrainingSetRequest) {
        horus {
            trainingSets {
                createTrainingSet(params: $params) {
                    status
                    trainingSet {
                        _id
                    }
                }
            }
        }
    }
`

export const GET_TRAINING_SETS_BY_PARENT = gql`
    query GetTrainingSetsByParent($params: HorusGetTrainingSetsByParentRequest) {
        horus {
            trainingSets {
                getTrainingSetsByParent(params: $params) {
                    status
                    trainingSets {
                        _id
                        createdAt
                        dataType
                        labelType
                        name
                        classes
                    }
                }
            }
        }
    }
`

export const GET_TRAINING_SET_BY_ID = gql`
    query GetTrainingSetById($params: HorusGetTrainingSetByIdRequest) {
        horus {
            trainingSets {
                getTrainingSetById(params: $params) {
                    status
                    trainingSet {
                        _id
                        classes
                    }
                }
            }
        }
    }

`

export const GET_TRAINING_SETS_ENTRIES_BY_TRAINING_SET = gql`
    query GetTrainingSetEntriesByTrainingSet($params: HorusGetTrainingSetEntriesByTrainingSetRequest!) {
        horus {
            trainingSets {
                getTrainingSetEntriesByTrainingSet(params: $params) {
                    status
                    entries {
                        _id
                        properties {
                            name
                            value
                        }
                    }
                    count
                }
            }
        }
    }
`

export const CREATE_TRAINING_SET_ENTRIES = gql`
    mutation CreateTrainingSetEntries($params: HorusCreateTrainingSetEntriesRequest!) {
        horus {
            trainingSets {
                createTrainingSetEntries(params: $params) {
                    status
                    entries {
                        responses {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`

export const UPDATE_TRAINING_SET_ENTRY_BY_ID = gql`
    mutation UpdateTrainingSetEntryById($params: HorusUpdateTrainingSetEntryByIdRequest!) {
        horus {
            trainingSets {
                updateTrainingSetEntryById(params: $params) {
                    status
                    entry {
                        properties {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`

export const GET_TRAINING_SET_ENTRY_BY_ID = gql`
    query GetTrainingSetEntryById($params: HorusGetTrainingSetEntryByIdRequest!) {
        horus {
            trainingSets {
                getTrainingSetEntryById(params: $params) {
                    status
                    entry {
                        properties {
                            name
                            value
                        }
                    }
                }
            }
        }
    }

`