import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import { ThemeProvider } from '@mui/material/styles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { ListItemIcon } from '@mui/material'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ReplayIcon from '@mui/icons-material/Replay'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import {
    GET_TRAINING_SETS,
    RECOVER_TRAINING_SET_BY_ID,
    REMOVE_TRAINING_SET_BY_ID,
} from '../../../queries/training-sets'
import IconButtonStatus from '../../../../../../../../../../../utils/common-components/iconButtonStatus'
import ButtonStatus from '../../../../../../../../../../../utils/common-components/buttonStatus'
import theme from '../../../../../../../../../../../theme'

const SECURE_ELIMINATION_STRING = 'eliminar permanentemente'

/**
 * Component that manages the behavior of
 * the button to delete permanently a training set
 */
const PermanentlyDeleteButton = ({ trainingSetName, trainingSetId }) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [disabledAccept, setDisabledAccept] = useState(true)

    const [removeTrainingSetById, { data, error, loading }] = useMutation(REMOVE_TRAINING_SET_BY_ID)

    const mutationError =
        error !== undefined ||
        (data !== undefined &&
            data.expireTrainingSetById !== undefined &&
            data.expireTrainingSetById.status === 'ERROR')

    const handleOnChangeSecureElimination = (event) => {
        if (event.target.value === SECURE_ELIMINATION_STRING) {
            setDisabledAccept(false)
        } else if (!disabledAccept) {
            setDisabledAccept(true)
        }
    }

    const permanentlyDelete = async () => {
        await removeTrainingSetById({
            variables: {
                params: {
                    _id: trainingSetId,
                },
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SETS,
                    variables: {
                        params: {
                            parent: null,
                        },
                    },
                },
            ],
            awaitRefetchQueries: true,
        })
        setOpenDialog(false)
    }

    return (
        <React.Fragment>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation()
                    setOpenDialog(true)
                }}
            >
                <DeleteForeverIcon />
            </IconButton>
            <Dialog open={openDialog}>
                <DialogContent onClick={() => {}}>
                    <p>
                        <b>{trainingSetName}</b> ({trainingSetId}) se eliminará completamente. Este
                        cambio es <b>irreversible</b>. Si realmente quiere eliminarlo{' '}
                        <b>permanentemente</b> escriba{' '}
                        <b>&quot;{SECURE_ELIMINATION_STRING}&quot;</b>
                    </p>
                    <TextField
                        fullWidth
                        label="Escriba aquí"
                        onChange={handleOnChangeSecureElimination}
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonStatus
                        buttonChildren="Aceptar"
                        buttonProps={{
                            disabled: disabledAccept,
                            color: 'primary',
                            variant: 'contained',
                            onClick: permanentlyDelete,
                        }}
                        loading={loading}
                        error={mutationError}
                        animationTimeout={3000}
                    />

                    <Button
                        color="primary"
                        variant="contained"
                        key="no"
                        onClick={() => {
                            setOpenDialog(false)
                        }}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

PermanentlyDeleteButton.propTypes = {
    /** Name f the training set to be deleted */
    trainingSetName: PropTypes.string.isRequired,
    /** Id of the training set to be delete */
    trainingSetId: PropTypes.string.isRequired,
}

/**
 * Controls the behavior of each row
 * in remove training set list
 * @param {*} param0
 * @returns
 */
const RemovedTrainingSetsListRow = ({ _id, name, expiredAt }) => {
    const [recoverTrainingSetById, { data, error, loading }] = useMutation(
        RECOVER_TRAINING_SET_BY_ID
    )

    const mutationError =
        error !== undefined ||
        (data !== undefined &&
            data.expireTrainingSetById !== undefined &&
            data.expireTrainingSetById.status === 'ERROR')

    const recoverTrainingSet = async (event) => {
        event.stopPropagation()
        await recoverTrainingSetById({
            variables: {
                params: {
                    trainingSet: _id,
                },
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SETS,
                    variables: {
                        params: {
                            parent: null,
                        },
                    },
                },
            ],
            awaitRefetchQueries: true,
        })
    }
    const remainMiliseconds = new Date(expiredAt) - new Date()

    const remainDays = Math.floor(remainMiliseconds / (1000 * 60 * 60 * 24))
    const remainHours = Math.floor(
        (remainMiliseconds - remainDays * 24 * 60 * 60 * 1000) / (60 * 60 * 1000)
    )
    const remainMinutes = Math.floor(
        (remainMiliseconds - remainDays * 24 * 60 * 60 * 1000 - remainHours * 60 * 60 * 1000) /
            (60 * 1000)
    )
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <ImageIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={name}
                secondary={`Time to expire ${remainDays}:${remainHours}:${remainMinutes}`}
            />
            <ListItemIcon>
                <Tooltip title={<Typography fontSize={11}>Eliminar permanentemente</Typography>}>
                    <div>
                        <PermanentlyDeleteButton trainingSetId={_id} trainingSetName={name} />
                    </div>
                </Tooltip>
                <Tooltip title={<Typography fontSize={11}>Recuperar</Typography>}>
                    <div>
                        <IconButtonStatus
                            loading={loading}
                            error={mutationError}
                            iconButtonProps={{ edge: 'end', 'aria-label': 'delete' }}
                            icon={<ReplayIcon onClick={recoverTrainingSet} />}
                        />
                    </div>
                </Tooltip>
            </ListItemIcon>
        </ListItem>
    )
}

RemovedTrainingSetsListRow.propTypes = {
    /** Id string of training set */
    _id: PropTypes.string.isRequired,
    /** Name fo training set */
    name: PropTypes.string.isRequired,
    /** Expiration date of training set */
    expiredAt: PropTypes.object.isRequired,
}

/**
 * Component to render the removed training sets
 */
const RemovedList = () => {
    const [openDialog, setOpenDialog] = useState(false)

    const { data: { getTrainingSets: { trainingSets = [] } = {} } = {} } = useQuery(
        GET_TRAINING_SETS,
        { variables: { params: { parent: null } } }
    )

    const removedTrainingSets = trainingSets.filter((trainSet) => trainSet.state === 'REMOVED')

    const onOpenClicked = () => {
        if (openDialog) {
            setOpenDialog(false)
        } else {
            setOpenDialog(true)
        }
    }

    const onClickOutside = () => {
        setOpenDialog(false)
    }

    let dialogContent = null
    if (removedTrainingSets.length === 0) {
        dialogContent = (
            <div style={{ margin: '20px', textAlign: 'center' }}>
                No existe ningun training set eliminado &#8600;
            </div>
        )
    } else {
        dialogContent = (
            <Paper>
                <List>
                    {removedTrainingSets.map((obj) => (
                        <RemovedTrainingSetsListRow
                            key={obj._id}
                            name={obj.name}
                            _id={obj._id}
                            expiredAt={obj.expiredAt}
                        />
                    ))}
                </List>
            </Paper>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <Fab
                    color="error"
                    onClick={onOpenClicked}
                    style={{ position: 'fixed', bottom: 30, right: 100 }}
                >
                    <DeleteIcon />
                </Fab>
                <Dialog open={openDialog} onClick={onClickOutside}>
                    <DialogContent
                        onClick={(event) => {
                            event.stopPropagation()
                        }}
                    >
                        {dialogContent}
                    </DialogContent>
                    <DialogActions />
                </Dialog>
            </React.Fragment>
        </ThemeProvider>
    )
}

export default RemovedList
