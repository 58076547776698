import gql from "graphql-tag"

export const GET_HOTSPOTS_BY_DATASET = gql`
    query GetHotspotsByDataset($params: GetHotspotsByDatasetRequest!) {
        getHotspotsByDataset(params: $params) {
            status
            hotspots {
                _id
                bbox
                category
                score
                image {
                    url
                }
                position {
                    coordinates
                }
            }
        }
    }

`