import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import Fab from "@material-ui/core/Fab"
import CircularProgress from "@material-ui/core/CircularProgress"

function AccentButton(props) {
    const classes = useStyles()

    return (
        <div className={classes.buttonContainer} style={{ width: props.fullWidth && "100%" }}>
           <Fab 
                variant="extended" 
                color="primary" 
                aria-label="add" 
                {...props}
                className={classes.button}
                style={props.style}
                disabled={props.loading}
            >
                {props.children}
            </Fab>
            {props.loading && <CircularProgress size={24} className={classes.loading}/>}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 10
    },
    button: { 
        fontWeight: 900, 
        fontSize: 13, 
        background: "linear-gradient(60deg,#FF4E50,#F9D423)", 
        margin: "25px 0", 
        width: "100%", 
        boxShadow: "0 0 45px -12px rgba(0,0,0,0.6)" 
    },
    buttonContainer: {
        position: "relative"
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -14,
        marginLeft: -12,
    }
}))

export default AccentButton