import React, { useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import DashboardIcon from '@material-ui/icons/Dashboard'
import AccountIcon from '@material-ui/icons/AccountCircle'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import SettingsIcon from '@material-ui/icons/Settings'

import { useIsAllowed } from 'queries/permissions'

import Logo from 'static/logo-light.png'

const useStyles = makeStyles(() => ({
    appFrame: {
        zIndex: 1,
        overflow: 'hidden',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        zIndex: 10,
        borderRadius: 0,
        '@media print': {
            display: 'none',
        },
    },
    menuButton: {
        color: '#FFF',
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        height: 15,
        marginLeft: 10,
    },
}))

const AppBarFl = () => {
    const classes = useStyles()

    const { isAllowed: isGetUsersAllowed } = useIsAllowed({ group: 'settings', action: 'getUsers' })
    const { isAllowed: isGetRolesAllowed } = useIsAllowed({ group: 'settings', action: 'getRoles' })

    const [accountMenuOpen, setAccountMenuOpen] = useState(false)
    const [accountMenuAnchor, setAccountMenuAnchor] = useState(null)

    const accountIconClicked = (e) => {
        setAccountMenuOpen(true)
        setAccountMenuAnchor(e.currentTarget)
    }

    const overlayClicked = () => {
        setAccountMenuOpen(false)
    }

    const isUserManagementAllowed = isGetUsersAllowed || isGetRolesAllowed

    return (
        <AppBar className={classes.appBar} position="fixed" elevation={2}>
            <Toolbar>
                <img src={Logo} alt="Flight Labs" className={classes.logo} />
                <div className={classes.grow} />
                <Link to="/private/apps">
                    <IconButton className={classes.menuButton}>
                        <DashboardIcon />
                    </IconButton>
                </Link>
                <IconButton id="news" className={classes.menuButton}>
                    <NewReleasesIcon />
                </IconButton>
                <IconButton
                    className={classes.menuButton}
                    onClick={() => {
                        location.href = 'https://desk.zoho.eu/portal/flightlabs/es/home'
                    }}
                >
                    <HelpOutlineIcon />
                </IconButton>
                {isUserManagementAllowed && (
                    <Link to="/private/settings">
                        <IconButton className={classes.menuButton}>
                            <SettingsIcon />
                        </IconButton>
                    </Link>
                )}
                <IconButton className={classes.menuButton} onClick={accountIconClicked}>
                    <AccountIcon />
                </IconButton>
                <Menu
                    open={accountMenuOpen}
                    anchorEl={accountMenuAnchor}
                    onClose={overlayClicked}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    marginThreshold={40}
                    container={accountMenuAnchor ? accountMenuAnchor.parentNode : null}
                >
                    {/* <Link to="/private/account"><MenuItem>Mi cuenta</MenuItem></Link> */}
                    <Link to="/public/auth/logout">
                        <MenuItem>Logout</MenuItem>
                    </Link>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}

export default AppBarFl
