import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'

import ColorPicker from 'material-ui-color-picker'

const Row = ({ query, actions, id }) => {
    const node = query.node(id).get()

    const [color, setColor] = useState(node.data.props.backgroundColor)

    const [padding, setPadding] = useState(
        node.data.props.padding
            .split('px')
            .map((p) => p.trim())
            .filter((p) => p)
    )
    const [margin, setMargin] = useState(
        node.data.props.margin
            .split('px')
            .map((p) => p.trim())
            .filter((p) => p)
    )

    /* eslint-disable no-param-reassign */
    const onIdChange = (e) => {
        actions.setProp(id, (p) => {
            p._id = e.target.value
        })
    }

    const onHeightChange = (e) => {
        actions.setProp(id, (p) => {
            p.height = e.target.value
        })
    }

    const onMarginChange = (i) => (e) => {
        const tmp = margin

        tmp[0] = margin[0] || 0
        tmp[1] = margin[1] || 0
        tmp[2] = margin[2] || 0
        tmp[3] = margin[3] || 0

        tmp[i] = e.target.value
        actions.setProp(id, (p) => {
            p.margin = `${tmp.join('px ')}px`
        })
        setMargin(tmp)
    }

    const onPaddingChange = (i) => (e) => {
        const pad = padding

        pad[0] = padding[0] || 0
        pad[1] = padding[1] || 0
        pad[2] = padding[2] || 0
        pad[3] = padding[3] || 0

        pad[i] = e.target.value
        actions.setProp(id, (p) => {
            p.padding = `${pad.join('px ')}px`
        })
        setPadding(pad)
    }

    const onBackgroundChange = (colorBack) => {
        setColor(colorBack)
        actions.setProp(id, (p) => {
            p.backgroundColor = colorBack
        })
    }

    return (
        <List>
            <ListItem>
                <TextField
                    label="ID"
                    onChange={onIdChange}
                    defaultValue={node.data.props._id || id}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    defaultValue={node.data.props.height}
                    label="Height"
                    onChange={onHeightChange}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <Grid container spacing={1}>
                    {margin.map((p, i) => (
                        <Grid item key={i} xs={3}>
                            <TextField
                                defaultValue={p}
                                label="Margin"
                                type="number"
                                step="1"
                                min="0"
                                onChange={onMarginChange(i)}
                                fullWidth
                            />
                        </Grid>
                    ))}
                </Grid>
            </ListItem>
            <ListItem>
                <Grid container spacing={1}>
                    {padding.map((p, i) => (
                        <Grid item key={i} xs={3}>
                            <TextField
                                defaultValue={p}
                                label="Padding"
                                type="number"
                                step="1"
                                min="0"
                                onChange={onPaddingChange(i)}
                                fullWidth
                            />
                        </Grid>
                    ))}
                </Grid>
            </ListItem>
            <ListItem>
                <div style={{ width: '100%' }}>
                    <ColorPicker
                        floatingLabelText="Fondo"
                        name="color"
                        value={color}
                        InputProps={{ value: color }}
                        onChange={onBackgroundChange}
                        fullWidth
                    />
                </div>
            </ListItem>
        </List>
    )
}

Row.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default Row
