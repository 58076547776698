import React, { useState } from "react"

import { useMutation } from "@apollo/react-hooks"


import Fab from "@material-ui/core/Fab"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';


import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from '@material-ui/icons/Delete';

import { CREATE_TRAINING_SET } from "../../../../queries/training-sets"

const CreateTrainingSetDialog = (props) => {

    const [ open, setOpen ] = useState(false)
    const [ name, setName ] = useState("")
    const [ labelType, setLabelType ] = useState("")
    const [ classes, setClasses ] = useState([""])
    const [ loading, setLoading ] = useState(false)

    const [ createTrainingSet ] = useMutation(CREATE_TRAINING_SET)

    const onFabClicked = () => setOpen(true)

    const onNameChanged = e => {
        setName(e.target.value)
    }

    const onLabelTypeChanged = e => {
        setLabelType(e.target.value)
    }

    const onAddClassClicked = e => {
        setClasses([
            ...classes,
            e.target.value
        ])
    }

    const onClassNameChanged = i => e => {
        setClasses([
            ...classes.slice(0, i),
            e.target.value,
            ...classes.slice(i + 1)
        ])
    }

    const onCancelClicked = () => {
        setOpen(false)
    }

    const onAcceptClicked = async () => {
        setLoading(true)

        await createTrainingSet({
            variables: {
                params: {
                    name: name,
                    dataType: "rgb",
                    labelType: labelType,
                    classes: classes
                }
            }
        })

        setLoading(false)
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Dialog open={open}>
                <DialogContent>
                    <TextField onChange={onNameChanged} value={name} fullWidth disabled={loading} />
                    <FormControl fullWidth disabled={loading}>
                        <InputLabel>Type</InputLabel>
                        <Select value={labelType} onChange={onLabelTypeChanged}>
                            <MenuItem value={"polygon"}>Polygon</MenuItem>
                        </Select>
                    </FormControl>
                    <List>
                        {
                            classes.map((c, i) => {
                                return (
                                    <ListItem>
                                        <TextField onChange={onClassNameChanged(i)} value={c} fullWidth disabled={loading} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end">
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    <Button onClick={onAddClassClicked} fullWidth>Add Class</Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disabled={loading}>Cancel</Button>
                    <Button onClick={onAcceptClicked} disabled={loading}>Accept</Button>
                </DialogActions>
            </Dialog>
            <Fab onClick={onFabClicked} style={{ position: "fixed", bottom: 20, right: 20 }}>
                <AddIcon />
            </Fab>
        </React.Fragment>
    )
}

export default CreateTrainingSetDialog