import React, { useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

import AdjustIcon from '@material-ui/icons/Adjust'
import DeleteIcon from '@material-ui/icons/Delete'

import {
    GET_INCIDENTS_BY_GROUP,
    OPEN_INCIDENT,
    CLOSE_INCIDENT,
    IN_PROGRESS_INCIDENT,
} from '../../../../queries/incidents'

import { useIsAllowed } from 'queries/permissions'

import { useQueryParam, withDefault, BooleanParam, NumberParam } from 'use-query-params'

import { PageParam, ShowClosedParam, RowsPerPageParam } from '../../params'

const Scene = (props) => {
    const { group } = useParams()

    const { selected, onClose } = props

    const [page] = useQueryParam('page', PageParam)
    const [showClosed] = useQueryParam('showClosed', ShowClosedParam)
    const [rowsPerPage] = useQueryParam('rowsPerPage', RowsPerPageParam)

    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [popover, setPopover] = useState('')
    const [loading, setLoading] = useState(false)

    const { isAllowed: isCloseAllowed = false } = useIsAllowed({
        group: 'monitoring',
        action: 'closeIncident',
    })
    const { isAllowed: isOpenAllowed = false } = useIsAllowed({
        group: 'monitoring',
        action: 'openIncident',
    })
    const { isAllowed: isInProgressAllowed = false } = useIsAllowed({
        group: 'monitoring',
        action: 'inProgressIncident',
    })

    const [openIncident] = useMutation(OPEN_INCIDENT)
    const [closeIncident] = useMutation(CLOSE_INCIDENT)
    const [inProgressIncident] = useMutation(IN_PROGRESS_INCIDENT)

    const onStateChangeClick = (e) => {
        setAnchorEl(e.currentTarget)
        setOpen(true)
        setPopover('state')
    }

    const onDeleteClick = (e) => {
        setAnchorEl(e.currentTarget)
        setOpen(true)
        setPopover('delete')
    }

    const onStateChange = async (state) => {
        setAnchorEl(null)
        setOpen(false)
        setLoading(true)

        const changeStateFuntionns = {
            OPEN: openIncident,
            CLOSED: closeIncident,
            IN_PROGRESS: inProgressIncident,
        }

        try {
            await Promise.all(
                selected.map(async (incident) => {
                    const changeState = changeStateFuntionns[state]
                    if (!changeState) return

                    await changeState({
                        variables: {
                            params: {
                                id: incident,
                            },
                        },
                        refetchQueries: [
                            {
                                query: GET_INCIDENTS_BY_GROUP,
                                variables: {
                                    params: {
                                        group,
                                        skip: page * rowsPerPage,
                                        limit: rowsPerPage,
                                        showClosed,
                                    },
                                },
                            },
                        ],
                    })
                })
            )
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const stateOptions = useMemo(() => {
        const options = []

        if (isOpenAllowed) {
            options.push({
                label: 'ABRIR',
                value: 'OPEN',
            })
        }

        if (isInProgressAllowed) {
            options.push({
                label: 'EN PROGRESO',
                value: 'IN_PROGRESS',
            })
        }

        if (isCloseAllowed) {
            options.push({
                label: 'CERRAR',
                value: 'CLOSED',
            })
        }

        return options
    }, [isOpenAllowed, isCloseAllowed, isInProgressAllowed])

    return (
        <Paper
            style={{
                width: '100vw',
                height: 50,
                position: 'fixed',
                bottom: !selected.length ? -50 : 0,
                left: 0,
                transition: 'all 0.5s ease-in-out',
            }}
        >
            <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                    <Typography variant="h6">
                        {selected.length} elemento{selected.length > 1 ? 's' : ''} seleccionado
                        {selected.length > 1 ? 's' : ''}{' '}
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    style={{ height: '100%' }}
                    xs
                >
                    <Grid item>
                        <Tooltip title="Cambiar estado">
                            <IconButton onClick={onStateChangeClick}>
                                {loading ? <CircularProgress size={20} /> : <AdjustIcon />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={1}>
                    <Button fullWidth onClick={onClose}>
                        Cerrar
                    </Button>
                </Grid>
            </Grid>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {stateOptions.map((option) => (
                    <MenuItem onClick={() => onStateChange(option.value)}>{option.label}</MenuItem>
                ))}
            </Popover>
        </Paper>
    )
}

export default Scene
