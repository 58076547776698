import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Tooltip from '@mui/material/Tooltip'

import { EXPORT_DATASET_V2_ENTRIES_BY_DATASET } from '../../../../queries/datasets'

const ExportDialog = (props) => {
    const { dataset } = useParams()

    const [open, setOpen] = useState(false)
    const [separator, setSeparator] = useState(',')
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState(null)

    const [exportDatasetV2EntriesByDataset] = useMutation(EXPORT_DATASET_V2_ENTRIES_BY_DATASET)

    const onIconButtonClicked = () => {
        setUrl(null)
        setLoading(false)
        setOpen(true)
    }

    const onSeparatorChange = (e) => {
        setSeparator(e.target.value)
    }

    const onAcceptClicked = async () => {
        setLoading(true)
        const { data: { exportDatasetV2EntriesByDataset: { url } = {} } = {} } =
            await exportDatasetV2EntriesByDataset({
                variables: {
                    params: {
                        dataset,
                        filters: props.filters,
                        sort: props.sort,
                        separators: {
                            field: separator,
                        },
                    },
                },
            })
        setUrl(url)
        setLoading(false)
    }

    const onCancelClicked = () => {
        setOpen(false)
    }

    const onDownloadClicked = () => {
        const extensions = {
            ',': 'csv',
            ';': 'csv',
            '\t': 'tsv',
        }

        saveAs(url, `export.${extensions[separator]}`)
    }

    const renderContent = () => {
        if (loading) {
            return (
                <Dialog open={open}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            )
        }

        if (url != null) {
            return (
                <Dialog open={open}>
                    <DialogContent>
                        <Button onClick={onDownloadClicked}>Descargar</Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCancelClicked}>Cerrar</Button>
                    </DialogActions>
                </Dialog>
            )
        }

        return (
            <Dialog open={open}>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Separador</InputLabel>
                        <Select value={separator} onChange={onSeparatorChange}>
                            <MenuItem value=",">Coma &quot,&quot</MenuItem>
                            <MenuItem value=";">Punto y Coma &quot:&quot</MenuItem>
                            <MenuItem value="\t">Tabulador &quot\t&quot</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked}>Cancelar</Button>
                    <Button onClick={onAcceptClicked}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <React.Fragment>
            <Tooltip title="Exportar">
                <IconButton onClick={onIconButtonClicked}>
                    <ImportExportIcon />
                </IconButton>
            </Tooltip>
            {renderContent()}
        </React.Fragment>
    )
}

export default ExportDialog
