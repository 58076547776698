import gql from "graphql-tag"

export const CREATE_MODEL = gql`
    mutation HorusCreateModel($params: HorusModelsCreateModelRequest) {
        HorusCreateModel(params: $params) {
            model {
                _id
            }
        }
    }
`