import React, { useRef, useState } from 'react'
import { PropTypes } from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error'

/**
 * Component that render an status button.
 * Allowing circular progress render when loading is true,
 * and success or error renderization
 */
const IconButtonStatus = ({
    icon,
    loading,
    error,
    loadingIcon,
    successIcon,
    errorIcon,
    animationTimeout,
    iconButtonProps,
}) => {
    const [resultAnimation, setResultAnimation] = useState(false)

    const timer = useRef(null)
    const fromLoading = useRef(false)
    if (loading && !fromLoading.current) {
        fromLoading.current = true
    }
    if (fromLoading.current && !loading) {
        fromLoading.current = false
        setResultAnimation(true)
        timer.current = setTimeout(() => {
            setResultAnimation(false)
            fromLoading.current = false
            clearTimeout(timer)
            timer.current = null
        }, animationTimeout)
    }

    const showLoading = loading
    const showSuccess = !loading && !error && resultAnimation
    const showError = !loading && error && resultAnimation
    const showNormal = !showLoading && !showSuccess && !showError

    return (
        <IconButton {...iconButtonProps}>
            {showNormal && icon}
            {showLoading && loadingIcon}
            {showSuccess && successIcon}
            {showError && errorIcon}
        </IconButton>
    )
}

IconButtonStatus.propTypes = {
    /** Icon to display as the button */
    icon: PropTypes.elementType.isRequired,
    /** True to show the loading Icon */
    loading: PropTypes.bool,
    /** True to begin an animation with errorIcon that
     * takes animationTimeout seconds longer.
     */
    error: PropTypes.bool,
    /** Icon to show as loading */
    loadingIcon: PropTypes.elementType,
    /** Icon to show on success */
    successIcon: PropTypes.elementType,
    /** Icon to show on error */
    errorIcon: PropTypes.elementType,
    /** Error and success animation seconds */
    animationTimeout: PropTypes.number,
    /** Props to pass to icon */
    iconButtonProps: PropTypes.shape,
}

IconButtonStatus.defaultProps = {
    loading: false,
    error: false,
    loadingIcon: <CircularProgress size={20} color="primary" />,
    successIcon: <DoneIcon fontSize="large" color="success" />,
    errorIcon: <ErrorIcon fontSize="large" color="error" />,
    animationTimeout: 3000,
    iconButtonProps: {},
}

export default IconButtonStatus
