import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { Editor } from '@craftjs/core'
import MaterialGrid from '@material-ui/core/Grid'

import SideBar from './containers/side-bar/scene'
import Viewer from './containers/viewer/scene'
import RightBar from './containers/right-bar/scene'
import resolvers from './resolvers'
import Components from './components/index'
import RenderNode from './containers/viewer/containers/preview/components/render'

const Scene = () => {
    const client = useApolloClient()

    client.addResolvers(resolvers)

    return (
        <Editor
            className="craftjs-renderer"
            resolver={Components}
            oncomponent={RenderNode}
            onNodesChange={console.log}
        >
            <MaterialGrid container spacing={2} style={{ padding: 20 }}>
                <MaterialGrid item xs={2}>
                    <SideBar />
                </MaterialGrid>
                <MaterialGrid item xs={8}>
                    <Viewer />
                </MaterialGrid>
                <MaterialGrid item xs={2}>
                    <RightBar />
                </MaterialGrid>
            </MaterialGrid>
        </Editor>
    )
}

export default Scene
