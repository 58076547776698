import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"

import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DialogContent from "@material-ui/core/DialogContent"

import AccentButton from "../../../../../../../components/accent-button"

function PlanCard(props) {

    const { features = [] } = props

    const classes = useStyles()

    return (
        <Card onClick={props.onClick} className={classes.card} style={{ borderRadius: 10, cursor: "pointer", border: props.selected && "2px solid #000", boxShadow: `0 0 ${props.selected ? 50 : 20}px -15px #333`, transition: "0.4s ease"}}>
            <CardContent>
                <Typography className={classes.title} variant="h3">{props.title}</Typography>
            </CardContent>
            <CardContent>
                <Typography className={classes.price} variant="h3">{props.billing === "metered" ? "From 0 €" : (props.price / 100 + " €")}</Typography>
            </CardContent>
            {!!features.length && <CardContent className={classes.features}>
                {features.map(feature => (
                    <Typography className={classes.feature} dangerouslySetInnerHTML={{ __html: feature }}></Typography>
                ))}
            </CardContent>}
        </Card>
    )
}

const useStyles = makeStyles(theme => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center"
    },
    title: {
        fontWeight: 900
    },
    price: {
    },
    features: {
        flex: 1
    },
    feature: {
        fontSize: 18,
        fontWeight: 100,
        margin: "20px 0"
    }
}))

export default PlanCard