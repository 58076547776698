import React from 'react'

import { useQuery } from '@apollo/react-hooks'
import { Link, useParams } from 'react-router-dom'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

import { GET_MONITOR_BY_ID } from '../../../../../monitors/queries/monitors'

const Scene = (props) => {
    const { incident, selected = false } = props

    const { group } = useParams()

    const {
        loading,
        error,
        data: { getMonitor: { monitor = {} } = {} } = {},
    } = useQuery(GET_MONITOR_BY_ID, {
        variables: { params: { id: incident.originId } },
    })

    const getDateString = () => {
        if (!incident || !incident.createdAt) return 'Unknown'

        const date = new Date(incident.createdAt)

        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()

        let month = date.getMonth() + 1
        let day = date.getDate()
        let year = date.getFullYear()

        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day
        if (hours < 10) hours = '0' + hours
        if (minutes < 10) minutes = '0' + minutes
        if (seconds < 10) seconds = '0' + seconds

        return `${hours}:${minutes}:${seconds} - ${day}/${month}/${year}`
    }

    const statusMap = {
        OPEN: 'ABIERTO',
        CLOSED: 'CERRADO',
        IN_PROGRESS: 'EN PROGRESO',
    }

    const status = statusMap[incident.status] || 'N/A'

    return (
        <TableRow key={incident.id}>
            <TableCell padding="checkbox">
                <Checkbox checked={selected} onChange={props.onCheck} />
            </TableCell>
            <TableCell>
                <Link to={group + '/' + incident.id}>{status}</Link>
            </TableCell>
            <TableCell>
                <Link to={group + '/' + incident.id}>{getDateString()}</Link>
            </TableCell>
            <TableCell>
                <Link to={group + '/' + incident.id}>{incident.level || 'N/A'}</Link>
            </TableCell>
            <TableCell>
                <Link to={`/private/apps/industrial/monitors/${monitor.id}`}>{monitor.name}</Link>
            </TableCell>
        </TableRow>
    )
}

export default Scene
