import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}))

const TableWithSearch = ({
    columns,
    data,
    search,
    onSearch,
    onSort,
    onFilter,
    onPaginate,
    onRowClick,
    onDirectionChange,
    onSelectChange,
    loading,
    height,
    fullScreen,
    toolbar,
    button,
    rowsAsLinks,
}) => {
    const classes = useStyles()

    const [localData, setLocalData] = useState(data)
    const [displayData, setDisplayData] = useState(data)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [selected, setSelected] = React.useState([])
    const [currentHeight, setCurrentHeight] = useState(0)
    const [searchValue, setSearchValue] = useState('')

    const tableRef = useRef()

    useEffect(() => {
        if (tableRef.current) setCurrentHeight(tableRef.current.clientHeight)

        setLocalData(data)
        setSelected([])
    }, [data])

    useEffect(() => {
        setDisplayData(localData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }, [localData, page, rowsPerPage])

    useEffect(() => {
        setSearchValue(search)
    }, [search])

    useEffect(() => {
        if (onSelectChange !== null) {
            onSelectChange(selected)
        }
    }, [selected])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        onPaginate(newPage, rowsPerPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        onPaginate(0, parseInt(event.target.value, 10))
    }

    const onSortClick = (column) => {
        if (onDirectionChange !== null) {
            onDirectionChange(column.field, column.sortDirection === 'asc' ? 'desc' : 'asc')
        }

        if (onSort !== null) {
            return onSort(column)
        }

        const sortedData = localData.sort((a, b) => {
            const dir = column.sortDirection === 'asc' ? 1 : -1

            const first = a[column.field]
            const second = b[column.field]

            if (typeof first === 'string' && typeof second === 'string') {
                return dir * first.localeCompare(second)
            }

            if (first < second) {
                return -1 * dir
            }

            if (first > second) {
                return 1 * dir
            }
        })

        setLocalData([...sortedData])
    }

    const onFilterClick = (column) => {
        onFilter(column)
    }

    const onRowClickHandler = (row) => () => {
        onRowClick(row)
    }

    const onSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id)
            setSelected(newSelecteds)
            return
        }

        setSelected([])
    }

    const onRowSelect = (id) => (event) => {
        const selectedIndex = selected.indexOf(id)

        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = [...selected, id]
        } else {
            newSelected = newSelected.filter((item) => item !== id)
        }

        setSelected(newSelected)
    }

    const onSearchChange = (event) => {
        setSearchValue(event.target.value)

        if (onSearch !== null) {
            return onSearch(event.target.value)
        }

        const filteredData = data.filter((row) => {
            return columns.some((column) => {
                if (typeof row[column.field] === 'string') {
                    return row[column.field].includes(event.target.value)
                }

                return false
            })
        })

        setLocalData(filteredData)
    }

    if (loading) {
        return (
            <Grid container style={{ minHeight: 300, height: currentHeight }} alignItems="center">
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </Grid>
            </Grid>
        )
    }

    const renderRows = () => {
        if (displayData.length === 0) {
            return (
                <TableRow>
                    <TableCell
                        colSpan={columns.length + 1}
                        style={{ textAlign: 'center', height: 200 }}
                    >
                        No hay datos disponibles
                    </TableCell>
                </TableRow>
            )
        }

        return displayData.map((row) => (
            <TableRow key={row.id}>
                <TableCell padding="checkbox" style={{ paddingLeft: 20 }}>
                    <Checkbox
                        checked={selected.indexOf(row.id) !== -1}
                        onChange={onRowSelect(row.id)}
                    />
                </TableCell>
                {columns.map((column) => {
                    if (rowsAsLinks) {
                        return (
                            <TableCell
                                key={column.field}
                                onClick={onRowClickHandler(row)}
                                style={{ cursor: button ? 'pointer' : 'auto' }}
                            >
                                <Link to={row.link} key={column.field}>
                                    {row[column.field]}
                                </Link>
                            </TableCell>
                        )
                    }

                    return (
                        <TableCell
                            key={column.field}
                            onClick={onRowClickHandler(row)}
                            style={{ cursor: button ? 'pointer' : 'auto' }}
                        >
                            {row[column.field]}
                        </TableCell>
                    )
                })}
            </TableRow>
        ))
    }

    const renderFooter = () => {
        const footer = (
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                count={localData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                component="div"
                colSpan={columns.length + 1}
            />
        )

        if (!fullScreen) return footer

        return (
            <Paper style={{ position: 'absolute', bottom: 0, width: '100%', left: 0 }}>
                {toolbar}
                {footer}
            </Paper>
        )
    }

    const renderContent = () => {
        const content = (
            <Table ref={tableRef} className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow style={{ padding: 10 }}>
                        <TableCell colSpan={columns.length + 1}>
                            <TextField
                                id="standard-basic"
                                label="Buscar"
                                variant="outlined"
                                fullWidth
                                value={searchValue}
                                onChange={onSearchChange}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow style={{ background: '#FEFEFE' }}>
                        <TableCell padding="checkbox" style={{ paddingLeft: 20 }}>
                            <Checkbox
                                indeterminate={selected.length > 0 && selected.length < data.length}
                                checked={selected.length > 0 && selected.length === data.length}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                            />
                        </TableCell>
                        {columns.map((column) => (
                            <TableCell key={column.field}>
                                <TableSortLabel
                                    active={column.sort}
                                    direction={column.sortDirection}
                                    onClick={() => onSortClick(column)}
                                >
                                    {column.title}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{renderRows()}</TableBody>
            </Table>
        )

        if (!height) {
            return content
        }

        return <TableContainer style={{ height }}>{content}</TableContainer>
    }

    return (
        <React.Fragment>
            {renderContent()}
            {renderFooter()}
        </React.Fragment>
    )
}

TableWithSearch.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            sort: PropTypes.bool,
            sortDirection: PropTypes.oneOf(['asc', 'desc']),
            filter: PropTypes.bool,
        })
    ).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
        })
    ).isRequired,
    search: PropTypes.string,
    onSearch: PropTypes.func,
    onSort: PropTypes.func,
    onFilter: PropTypes.func,
    onPaginate: PropTypes.func,
    onSelectChange: PropTypes.func,
    onDirectionChange: PropTypes.func,
    fullScreen: PropTypes.bool,
    height: PropTypes.number,
    toolbar: PropTypes.node,
    button: PropTypes.bool,
    rowsAsLinks: PropTypes.bool,
}

TableWithSearch.defaultProps = {
    search: '',
    onSearch: null,
    onSort: null,
    onFilter: () => {},
    onPaginate: () => {},
    onSelectChange: null,
    onDirectionChange: null,
    fullScreen: false,
    height: null,
    toolbar: null,
    button: false,
    rowsAsLinks: false,
}

export default TableWithSearch
