import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import TemplateMatching from './containers/template-matching/scene'
import Autolabel from './containers/autolabel/scene'
import AutoSave from './containers/autosave'
import Style from './containers/style'

import { closeSettingsDialog } from '../../redux/general'

const tabs = [
    {
        label: 'Etiquetado automático',
        component: Autolabel,
    },
    {
        label: 'Template Matching',
        component: TemplateMatching,
    },
    {
        label: 'Guardado automático',
        component: AutoSave,
    },
    {
        label: 'Estilo',
        component: Style,
    },
]

const Scene = () => {
    const dispatch = useDispatch()

    const { settingsDialogOpen } = useSelector((state) => state.trainingSetObjectDetection.general)

    const [selectedTab, setSelectedTab] = useState(0)

    const onClose = () => {
        dispatch(closeSettingsDialog())
    }

    const CurrentTabComponent = tabs[selectedTab].component

    return (
        <Dialog open={settingsDialogOpen} maxWidth="md" onClose={onClose}>
            <DialogTitle>Ajustes</DialogTitle>
            <Divider />
            <Grid container style={{ height: 500, width: 800 }}>
                <Grid item xs={3} style={{ height: '100%', borderRight: '1px solid #E1E1E1' }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={selectedTab}
                        onChange={(event, newValue) => setSelectedTab(newValue)}
                    >
                        {tabs.map((tab) => {
                            return <Tab key={tab.label} label={tab.label} />
                        })}
                    </Tabs>
                </Grid>
                <Grid item xs={9}>
                    <CurrentTabComponent />
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default Scene
