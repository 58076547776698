import { combineReducers } from 'redux'

import { reducer as reducerGeneral, initialState as initialStateGeneral } from './general'
import { reducer as reducerImages, initialState as initialStateImages } from './images'
import { reducer as reducerSettings, initialState as initialStateSettings } from './settings'

import templateMatchingSaga from './sagas/template-matching'

export default {
    id: 'trainingSetObjectDetection',
    initialState: {
        general: initialStateGeneral,
        images: initialStateImages,
        settings: initialStateSettings,
    },
    reducerMap: {
        trainingSetObjectDetection: combineReducers({
            general: reducerGeneral,
            images: reducerImages,
            settings: reducerSettings,
        }),
    },
    sagas: [templateMatchingSaga],
}
