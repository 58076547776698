import React, { useState, useEffect } from "react"

import { useParams } from "react-router-dom"
import { Zoom } from '@vx/zoom';
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Tooltip from '@material-ui/core/Tooltip';

import NoteAddIcon from '@material-ui/icons/NoteAdd';
import IconButton from '@material-ui/core/IconButton';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';

import { iron } from "../../../../../../../../../../utils/pallete"

import { setSelectedHotspot } from "../../reducers/controls"

import { CREATE_HOTSPOT, GET_HOTSPOTS_BY_IMAGE } from "../../queries/hotspots"

const width = "100%"
const height = 512*1.35

const initialTransform = {
    scaleX: 1.35,
    scaleY: 1.35,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0
};

const tempToRGB = (t, min, max) => {
    if(t === 0) {
        return null;
    } else {
        return iron[Math.min(Math.max(Math.round((iron.length - 1)/(max - min) * (t - min)), 0), iron.length - 1)] || {r: 0, g:0, b:0}
    }
}

function ImageViewer(props) {
    const classes = useStyles()

    const { inspection } = useParams()

    const { selected_image } = useSelector(state => state.inspector.solar.images)
    const { open } = useSelector(state => state.inspector.solar.side_menu.image_viewer.dialogs)
    const { range, selected_hotspot } = useSelector(state => state.inspector.solar.side_menu.image_viewer.radiometric_viewer.controls)
    
    const [ temp, setTemp ] = useState(null)
    const [ display, setDisplay ] = useState(null)
    const [ tempAnnotation, setTempAnnotation ] = useState(null)
    const [ tool, setTool ] = useState("move")

    const dispatch = useDispatch()

    const selectHotspot = hotspot => dispatch(setSelectedHotspot(hotspot))

    const { data: { getHotspotsByImage: { hotspots = []} = {}} = {} } = useQuery(GET_HOTSPOTS_BY_IMAGE, {
        variables: {
            params: {
                image: selected_image._id
            }
        }
    })
    const [ createHotspot ] = useMutation(CREATE_HOTSPOT)

    useEffect(() => {
        if(selected_image && open) {
            const res = fetch(selected_image.url + "?type=radiometry",{
                method: "GET",
                mode: "cors",
                headers:{
                    'Access-Control-Allow-Origin':'*'
                }
            }).then(res => res.arrayBuffer())
            .then(res => { 
                let u16 = new Uint16Array(res);
                let t = Array.from(u16.map(r => (1428.0/Math.log(366545.0/(r - 342.0) + 1.0) - 273.15)));
                setTemp(t)
            })
        }
    }, [ open, selected_image ])

    useEffect(() => {
        if(temp) {
            const cv = document.getElementById("canvas")
            const ctx = cv.getContext("2d")
            
            let rgb = temp.map(t => tempToRGB(t, range[0], range[1])).reduce((acc, color) => { 
                if(color) {
                    acc.push(color.r);
                    acc.push(color.g);
                    acc.push(color.b);
                    acc.push(0xFF);
                }
                return acc
            }, [])
            
            let im = new Uint8ClampedArray(rgb).slice(16)
            var iData = new ImageData(im, 640, 512);
            ctx.putImageData(iData, 0, 0);
            setDisplay(cv.toDataURL('image/jpeg', 1.0))
        }
    }, [ temp, range ])

    const onMouseDown = (zoom) => (event) => {
        switch(tool) {
            case "move":
                return zoom.dragStart(event)
            case "annotation":
                return setTempAnnotation({
                    startX: event.nativeEvent.offsetX/zoom.transformMatrix.scaleX,
                    startY: event.nativeEvent.offsetY/zoom.transformMatrix.scaleY
                })
        }
    }

    const onMouseMove = (zoom) => (event) => {
        switch(tool) {
            case "move":
                return zoom.dragMove(event)
            case "annotation":
                if(tempAnnotation) {
                    setTempAnnotation({
                        ...tempAnnotation, 
                        endX: event.nativeEvent.offsetX/zoom.transformMatrix.scaleX,
                        endY: event.nativeEvent.offsetY/zoom.transformMatrix.scaleY
                    })
                }
        }
    }

    const onMouseUp = zoom => async event => {
        switch(tool) {
            case "move":
                return zoom.dragEnd(event)
            case "annotation":
                await createHotspot({
                    variables: {
                        params: {
                            dataset: inspection,
                            image: selected_image._id,
                            category: "none",
                            severity: "0",
                            location: {
                                type: "Point",
                                coordinates: [0,0]
                            },
                            bbox: [ tempAnnotation.startX, tempAnnotation.startY, tempAnnotation.endX, tempAnnotation.endY ],
                            temperatures: [0, 100]
                        }
                    },
                    refetchQueries: [
                        {
                            query: GET_HOTSPOTS_BY_IMAGE,
                            variables: {
                                params: {
                                    image: ownProps.selected_image._id
                                }
                            }
                        }
                    ]
                })
                setTempAnnotation(null)
                return setTool("move")
        }
    }

    const onWheel = zoom => event => {
        return zoom.handleWheel(event)
    }
    
    return (
        <React.Fragment>
            <canvas id="canvas" width="640px" height="512px" style={{ display: "none" }}></canvas>
            <Zoom
                width={width}
                height={height}
                scaleXMin={1}
                scaleXMax={10}
                scaleYMin={1}
                scaleYMax={10}
                passive={true}
                transformMatrix={initialTransform}
            >
                {zoom => {
                    return (
                        <div className={classes.root}>
                            <svg
                                width={width}
                                height={height}
                                onWheel={onWheel(zoom)}
                                onMouseDown={onMouseDown(zoom)}
                                onMouseMove={onMouseMove(zoom)}
                                onMouseUp={onMouseUp(zoom)}
                                style={{ cursor: tool === "annotation" ? "cell" : "move" }}
                            >
                                <g transform={zoom.toString()}>
                                    <image xlinkHref={display} />
                                    {tempAnnotation && <rect x={tempAnnotation.startX} y={tempAnnotation.startY} width={(tempAnnotation.endX - tempAnnotation.startX)} height={(tempAnnotation.endY - tempAnnotation.startY)} stroke="black" stroke-width="2" fill="none"/>}
                                    {
                                        hotspots.map(hs => (
                                            <rect 
                                                x={hs.bbox[0]} 
                                                y={hs.bbox[1]} 
                                                width={hs.bbox[2] - hs.bbox[0]} 
                                                height={hs.bbox[3] - hs.bbox[1]} 
                                                stroke={selected_hotspot._id === hs._id ? "red" : "black"} 
                                                stroke-width="2" 
                                                fill={selected_hotspot._id === hs._id ? "#99000033" : "#00000033"} 
                                                onClick={() => selectHotspot(hs)} 
                                                style={{ cursor: "pointer" }}/>
                                        ))
                                    }
                                </g>
                            </svg>
                            <div className={classes.icon}>
                                <Grid container>
                                    <Grid xs={12} style={{ marginTop: 10 }}>
                                        <Tooltip title="Reset" placement="left">
                                            <IconButton size={"small"} style={{ background: "white" }} onClick={zoom.reset}><RotateLeftIcon fontSize={"small"}/></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid xs={12} style={{ marginTop: 10 }}>
                                        <Tooltip title="Mover" placement="left">
                                            <IconButton size={"small"} style={{ background: "white" }} onClick={() => setTool("move")}><ControlCameraIcon fontSize={"small"}/></IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid xs={12} style={{ marginTop: 10 }}>
                                        <Tooltip title="Anotación" placement="left">
                                            <IconButton size={"small"} style={{ background: "white" }} onClick={() => setTool("annotation")}><NoteAddIcon fontSize={"small"}/></IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    )
                }}
            </Zoom>
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
    },
    icon: {
        position: "absolute",
        width: 30,
        right: theme.spacing(2),
        top: theme.spacing(2)
    }
}))


export default ImageViewer