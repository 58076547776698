import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import QrReader from 'react-qr-reader'
import { useDispatch, useSelector } from 'react-redux'

import { closeNewGroupDialog } from '../reducers/dialogs'
import { CREATE_GROUP, GET_GROUPS } from '../../../queries/groups'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const NewGroupDialog = () => {
    const classes = useStyles()

    const open = useSelector((state) => state.groups.dialogs.new_group_dialog_open)

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [scan, setScan] = useState(false)

    const [createGroup] = useMutation(CREATE_GROUP)

    const cancel = () => dispatch(closeNewGroupDialog())
    const accept = async () => {
        setLoading(true)
        await createGroup({
            variables: {
                params: {
                    name,
                    thing: id,
                },
            },
            refetchQueries: [{ query: GET_GROUPS }],
        })
        setLoading(false)

        cancel()
    }

    const reset = () => {
        setName('')
        setId('')
    }

    const onIdChanged = (event) => {
        setId(event.target.value)
    }

    const onCameraClicked = () => {
        setScan(true)
    }

    const onScan = (data) => {
        if (data) {
            const parsed = JSON.parse(data)
            setId(parsed['thing_id'])
            setScan(false)
        }
    }

    const onError = (err) => {
        console.log(err)
    }

    return (
        <Dialog open={open} onExited={reset}>
            <DialogTitle>Nuevo Grupo</DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    label="Nombre"
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                />
                {scan && (
                    <QrReader
                        delay={300}
                        onError={onError}
                        onScan={onScan}
                        style={{ width: '100%', transform: 'rotateY(180deg)' }}
                    />
                )}
                <TextField
                    label="Número de identificación"
                    value={id}
                    onChange={onIdChanged}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={onCameraClicked}>
                                <CameraAltIcon />
                            </IconButton>
                        ),
                    }}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={cancel}>
                    Cancelar
                </Button>
                <div className={classes.wrapper}>
                    <Button disabled={loading} onClick={accept}>
                        Aceptar
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default NewGroupDialog
