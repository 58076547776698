import React, { useCallback,useEffect, useState } from "react"

import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/react-hooks"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import MapView from "../../../viewer/containers/map-view/scene"

import { REPORT } from "../../queries/local"

export default function() {
    const classes = useStyles();

    const [ print, setPrint ] = useState(false)
    
    const { data: { report: { summary = ""} = {}} = {}} = useQuery(REPORT)

    let controls = [
        "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "media", "numberList", "bulletList", "quote", "code", "clear"
    ]

    useEffect(() => {

        const beforePrint = () => setPrint(true)
        window.matchMedia('print').addListener(function(mql) {
            console.log("TEST")
            if (mql.matches) {
                beforePrint()
            } else {
                
            }
        });
        window.onbeforeprint = beforePrint;
    }, [window])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h2">Resumen</Typography> 
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <MapView hotspots={[]} dataset={""} images={[]} height={380}/>
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Typography style={{ margin: 10 }}>{summary}</Typography>
            </Grid>
        </Grid>
    )
}


const useStyles = makeStyles(theme => ({
    section: {
        margin: "35px 0 0 0",
        border: "1px solid #AAA", 
        borderRadius: 10,
        overflow: "hidden"
    }
}));