import gql from "graphql-tag"

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($params: ForgotPasswordInput!){
        forgotPassword(params: $params) {
            state
        }
    }
`

export const CONFIRM_FORGOT_PASSWORD = gql`
    mutation ConfirmForgotPassword($params: ConfirmForgotPasswordInput!){
        confirmForgotPassword(params: $params) {
            state
        }
    }
`

export const NEW_PASSWORD = gql`
    mutation NewPassword($params: NewPasswordInput!){
        newPassword(params: $params) {
            state
            access_token
            id_token
            refresh_token
        }
    }
`
