import React from "react"

import CreateModelDialog from "./containers/create-model-dialog/scene"

const Models = () => {

    return (
        <div>
            <CreateModelDialog/>
        </div>
    )
}

export default Models