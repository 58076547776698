import React from 'react'

import { Switch, Route } from 'react-router-dom'

import BarChart from './containers/bar-chart/scene'
import LineChart from './containers/line-chart/scene'

function Creator(props) {
    return (
        <Switch>
            <Route
                path={props.match.url + '/bar-chart/:function/:widget?'}
                component={BarChart}
                exact
            />
            <Route
                path={props.match.url + '/line-chart/:function/:widget?'}
                component={LineChart}
                exact
            />
        </Switch>
    )
}

export default Creator
