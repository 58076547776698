import gql from 'graphql-tag'

export const GET_VARIABLES_BY_GROUP = gql`
    query GetVariablesByGroup($params: GetVariablesByGroupRequest!) {
        getVariablesByGroup(params: $params) {
            status
            variables {
                _id
                name
                type
                default
            }
        }
    }
`

export const CREATE_VARIABLE = gql`
    mutation CreateVariable($params: CreateVariableRequest!) {
        createVariable(params: $params) {
            status
        }
    }
`

export const REMOVE_VARIABLE_BY_ID = gql`
    mutation RemoveVariableById($params: RemoveVariableByIdRequest!) {
        removeVariableById(params: $params) {
            status
        }
    }
`
