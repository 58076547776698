import React from "react"

import { useSelector, useDispatch } from "react-redux"
import styled from 'styled-components'

import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"

import Classes from "./containers/classes/scene";
import Objects from "./containers/objects/scene";


const Left = (props) => {
    return (
        <Paper style={{ height: "100%" }}>
            <Classes />
            <Divider />
            <Objects />
        </Paper>
    )
}

export default Left