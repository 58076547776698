import gql from 'graphql-tag'

export const GET_INCIDENTS_BY_GROUP = gql`
    query GetIncidentsByGroup($params: GetIncidentsByGroupRequest) {
        getIncidentsByGroup(params: $params) {
            status
            error
            count
            incidents {
                id
                originType
                originId
                createdAt
                status
                level
            }
        }
    }
`

export const GET_INCIDENT_BY_ID = gql`
    query GetIncidentById($params: GetIncidentByIdRequest) {
        getIncidentById(params: $params) {
            status
            error
            incident {
                id
                originType
                originId
                createdAt
                status
                level
            }
        }
    }
`

export const CREATE_INCIDENT = gql`
    mutation CreateIncident($params: CreateIncidentRequest) {
        createIncident(params: $params) {
            status
            error
        }
    }
`

export const UPDATE_INCIDENT = gql`
    mutation UpdateIncident($params: UpdateIncidentRequest) {
        updateIncident(params: $params) {
            status
            error
        }
    }
`

export const IN_PROGRESS_INCIDENT = gql`
    mutation InProgressIncident($params: InProgressIncidentRequest) {
        inProgressIncident(params: $params) {
            status
            error
        }
    }
`

export const CLOSE_INCIDENT = gql`
    mutation CloseIncident($params: CloseIncidentRequest) {
        closeIncident(params: $params) {
            status
            error
        }
    }
`

export const OPEN_INCIDENT = gql`
    mutation OpenIncident($params: OpenIncidentRequest) {
        openIncident(params: $params) {
            status
            error
        }
    }
`
