import gql from "graphql-tag"

export const GET_DASHBOARDS_BY_PARENT = gql`
    query GetDashboardsByParent($params: GetDashboardsByParentRequest!) {
        getDashboardsByParent(params: $params) {
            status
            dashboards {
                id
                createdAt
                updatedAt
                name
                parent
            }
        }
    }
`

export const CREATE_DASHBOARD = gql`
    mutation CreateDashboard($params: CreateDashboardRequest) {
        createDashboard(params: $params) {
            status
            dashboard {
                id
                createdAt
                name
                parent
            }
        }
    }
`