import React from 'react'

import { useEditor, Element } from '@craftjs/core'
import { Paper, Box, Grid as MaterialGrid, Button as MaterialButton } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'

import Button from '../../components/button'
import Toggle from '../../components/toggle'
import Card from '../../components/card'
import Text from '../../components/text'
import View from '../../components/view'
import Row from '../../components/row'
import Image from '../../components/image'
import Checkbox from '../../components/checkbox'
import TextField from '../../components/text-field'
import Select from '../../components/select'
import Scroll from '../../components/scroll'
import Tabs from '../../components/tabs'
import Display from './containers/display/scene'
import Users from './containers/users/scene'
import Permissions from './containers/permissions/scene'
import Datasets from './containers/datasets/scene'
import Palletizer from '../../components/palletizer'

const Scene = () => {
    const { connectors } = useEditor()

    return (
        <div style={{ height: 'calc(100vh - 120px)', width: '100%' }}>
            <Paper style={{ height: '65%', width: '100%' }}>
                <List style={{ maxHeight: '100%', overflowY: 'auto' }}>
                    <ListSubheader>Componentes</ListSubheader>
                    <Box px={2} py={2}>
                        <MaterialGrid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            spacing={1}
                        >
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Row} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Fila
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={View} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Vista
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Card} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Tarjeta
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Button} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Button
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Text} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Texto
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Toggle} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Interruptor
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Image} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Imagen
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Checkbox} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Checkbox
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={TextField} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Text Field
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Select} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Select
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Scroll} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Scroll
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Tabs} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Tabs
                                </MaterialButton>
                            </MaterialGrid>
                            <MaterialGrid container direction="column" item>
                                <MaterialButton
                                    ref={(ref) =>
                                        connectors.create(ref, <Element is={Palletizer} canvas />)
                                    }
                                    variant="contained"
                                >
                                    Palletizer
                                </MaterialButton>
                            </MaterialGrid>
                        </MaterialGrid>
                    </Box>
                </List>
            </Paper>
            <Paper style={{ height: 'calc(38% - 10px)', marginTop: 10, width: '100%' }}>
                <List>
                    <Display />
                    <Users />
                    <Permissions />
                    <Datasets />
                </List>
            </Paper>
        </div>
    )
}

export default Scene
