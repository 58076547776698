import React from "react"

import { Card, CardMedia, CardTitle} from "material-ui/Card"

import { withRouter, Link } from "react-router-dom"

class AppEntry extends React.Component {
    state = {
        z: 2
    }

    elevate() {
        this.setState({ z: 5 })
    }

    delevate() {
        this.setState({ z: 2 })
    }

    render() {

        return (
            <Link to={this.props.path}>
                <div className="col-sm-3" style={{marginBottom: 50}}>
                    <Card 
                        style={{ height: 180, transition: "ease 0.4s", cursor: "pointer" }} 
                        zDepth={this.state.z} 
                        onMouseEnter={() => this.elevate()} 
                        onMouseLeave={() => this.delevate()}
                    >
                        <CardMedia 
                            style={{ height: 180, overflowY: "hidden", background: `url(${this.props.image})`, backgroundSize: "cover"  }}
                            overlay={ <CardTitle title={this.props.title} subtitle={this.props.text}/>}
                            overlayContainerStyle={{ height: "100%", background: "radial-gradient(#21212122 0%, #212121FF 100%"}}
                            overlayContentStyle={{ backgroundColor: "transparent", color: "#FFF" }}
                        >
                        </CardMedia>
                    </Card>        
                </div>
            </Link>
        )
    }
}


AppEntry.defaultProps = {
    image: "",
    title: "",
    text: "",
    path: ""
}

export default withRouter(AppEntry);