import gql from 'graphql-tag'

export const GET_GROUPS = gql`
    query {
        getAllGroups {
            status
            groups {
                _id
                name
            }
        }
    }
`

export const CREATE_GROUP = gql`
    mutation CreateGroup($params: CreateGroupRequest!) {
        createGroup(params: $params) {
            status
        }
    }
`

export const REMOVE_GROUP = gql`
    mutation RemoveGroupById($params: RemoveGroupByIdRequest!) {
        removeGroupById(params: $params) {
            status
        }
    }
`

export const DEPLOY = gql`
    mutation Deploy($params: DeployRequest!) {
        deploy(params: $params) {
            status
        }
    }
`

export const GET_GROUP_STATUS = gql`
    query GetGroupStatus($params: GetGroupStatusRequest!) {
        getGroupStatus(params: $params) {
            status {
                deploymentStatus
                detailedErrorMessage
            }
        }
    }
`

export const GET_CONNECTIVITY_INFO_BY_GROUP = gql`
    query GetGroupConnectivityInfo($params: GetGroupConnectivityInfoRequest!) {
        falcon {
            groups {
                getGroupConnectivityInfo(params: $params) {
                    status
                    address
                    port
                }
            }
        }
    }
`

export const SET_CONNECTIVITY_INFO_BY_GROUP = gql`
    mutation SetGroupConnectivityInfo($params: FalconSetGroupConnectivityInfoRequest!) {
        falcon {
            groups {
                setGroupConnectivityInfo(params: $params) {
                    status
                }
            }
        }
    }
`

export const GET_DEPLOYMENT_STATUS_BY_GROUP = gql`
    query GetDeploymentSatusByGroup($params: FalconGetDeploymentStatusRequest!) {
        falcon {
            groups {
                getDeploymentStatus(params: $params) {
                    status
                    deploymentStatus
                    deploymentUpdatedAt
                }
            }
        }
    }
`

export const GET_GROUP_BY_ID = gql`
    query GetGroupById($params: GetGroupByIdRequest!) {
        getGroupById(params: $params) {
            status
            group {
                _id
                name
            }
        }
    }
`
