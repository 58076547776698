import React, { useRef, useState, useEffect }from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"

import { Zoom, applyMatrixToPoint } from '@vx/zoom';

import { SELECTED_IMAGE } from "../../queries/state"
import { GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID } from "../../queries/multi-label-classification-entries"

const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: window.innerWidth * 0.25,
    translateY: window.innerHeight * 0.05,
    skewX: 0,
    skewY: 0
};

function ImageViewer(props) {
    const zoom = useRef(null);

    const { data: { labelerSelectedImage } = {}} = useQuery(SELECTED_IMAGE)
    const { data: { getMultiLabelClassificationEntryById: { multiLabelClassificationEntry } = {}} = {}} = useQuery(GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID, {
        variables: {
            params: {
                _id: labelerSelectedImage
            }
        }
    })

    return(
        <div ref={zoom} style={{ height: "calc(65vh - 67px)", background: "#333", overflow: "hidden" }} >
            <Zoom
                width={window.innerWidth}
                height={window.innerHeight}
                scaleXMin={0.1}
                scaleXMax={10}
                scaleYMin={0.1}
                scaleYMax={10}
                transformMatrix={initialTransform}
            >
                {zoom => {
                    return (
                        <svg 
                            width={window.innerWidth} 
                            height={window.innerHeight} 
                            style={{ cursor: zoom.isDragging ? 'grabbing' : 'grab' }}
                        >
                            <g transform={zoom.toString()}>
                                <image xlinkHref={multiLabelClassificationEntry && multiLabelClassificationEntry.url} />
                            </g>
                            <rect
                                width={window.innerWidth}
                                height={window.innerHeight}
                                rx={14}
                                fill="transparent"
                                onWheel={zoom.handleWheel}
                                onMouseDown={zoom.dragStart}
                                onMouseMove={zoom.dragMove}
                                onMouseUp={zoom.dragEnd}
                                onMouseLeave={() => {
                                    if (!zoom.isDragging) return;
                                    zoom.dragEnd();
                                }}
                                onDoubleClick={event => {
                                    const point = localPoint(event);
                                    zoom.scale({ scaleX: 1.1, scaleY: 1.1, point });
                                }}
                            />
                        </svg>
                    )
                }}
            </Zoom>
    </div>
    )
}

export default ImageViewer