import gql from "graphql-tag"

export const GET_ALL_IMAGES = gql`
    query AllImages($params: AllImagesInput) {
        allImages(params: $params) {
            _id
            position {
                coordinates
            }
            url
            filename
        }
    }

`