import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'

const Image = ({ src, margin, preserveAspectRatio }) => {
    const {
        connectors: { connect, drag },
    } = useNode()

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                'min-height': '50px',
                flex: '1 1 0%',
                'flex-direction': 'row',
                padding: '0px',
                margin,
                background: 'rgb(255, 255, 255)',
                'box-sizing': 'border-box',
            }}
        >
            <svg width="100%" height="100%">
                <image
                    ref={(ref) => connect(drag(ref))}
                    href={src}
                    width="100%"
                    height="100%"
                    preserveAspectRatio={preserveAspectRatio}
                />
            </svg>
        </div>
    )
}

Image.craft = {
    displayName: 'Imagen',
    custom: {
        component: true,
    },
    props: {
        src: 'https://placeholder.pics/svg/300',
        margin: '0px 0px 0px 0px',
        preserveAspectRatio: 'xMinYMin slice',
    },
}

Image.propTypes = {
    src: PropTypes.string,
    margin: PropTypes.string,
    preserveAspectRatio: PropTypes.string,
}

Image.defaultProps = {
    src: 'https://placeholder.pics/svg/300',
    margin: '0px 0px 0px 0px',
    preserveAspectRatio: 'xMinYMin slice',
}

export default Image
