import React, { useState } from "react"

import { useParams, Link } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Group from "./components/group"
import Loading from "../../../../../../components/Loading";

import { GET_PLANS } from "../../queries/plans"
import { CREATE_SUBSCRIPTION, GET_SUBSCRIPTION_BY_PRODUCT, UPDATE_SUBSCRIPTION, REMOVE_SUBSCRIPTION } from "../../queries/subscriptions"

function Plans(props) {

    const { product } = useParams()

    const [ contractLoading, setContractLoading ] = useState(false)

    const { data: { getGroupsByProduct: { groups = []} = {}} = {}, loading} = useQuery(GET_PLANS, { 
        variables: {
            params: {
                product
            }
        }
    })
    const { data: { getSubscriptionByProduct: { local = false, subscription = {}} = {}} = {}} = useQuery(GET_SUBSCRIPTION_BY_PRODUCT, { variables: { params: { product }}})
    const [ createSubscription ] = useMutation(CREATE_SUBSCRIPTION)
    const [ updateSubscription ] = useMutation(UPDATE_SUBSCRIPTION)
    const [ removeSubscription ] = useMutation(REMOVE_SUBSCRIPTION)

    const onContract = async () => {
        setContractLoading(true)
        await createSubscription({
            variables: {
                params: {
                    product,
                    items: subscription.items.map(item => ({
                        plan: item.plan._id,
                        ...item.quantity ? { quantity: item.quantity } : {}
                    }))
                }
            }
        })
        setContractLoading(false)
        props.history.push("/private")
    }
    const onUpdate = async () => {
        setContractLoading(true)
        await updateSubscription({
            variables: {
                params: {
                    subscription: subscription_id,
                    items: subscription.items.map(item => ({
                        _id: item._id,
                        plan: item.plan._id,
                        ...item.quantity ? { quantity: item.quantity } : {}
                    }))
                }
            },
            refetchQueries: [
                { query: GET_SUBSCRIPTION_BY_PRODUCT, variables: { params: { product }}}
            ]
        })
        setContractLoading(false)
        props.history.push("/private")
    }
    const onRemove = async () => {
        await removeSubscription({
            variables: {
                params: {
                    subscription: subscription._id
                }
            }
        })
        props.history.push("/private")
    }
    console.log(subscription)
    const contracted = subscription && !!subscription._id && !local
    return (
        <Loading loading={loading || contractLoading}>
            <Container>
                <Grid container>
                    <Grid item xs={2}>
                        <Link to="../store"><Button style={{ paddingRight: 15 }}><ChevronLeftIcon/>Atrás</Button></Link>
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={2}>
                        {contracted && <Button onClick={onRemove}>Eliminar Subscripción</Button>}
                    </Grid>
                </Grid>
                <div style={{ paddingBottom: 50 }}>
                    {
                        groups.map((group, i) => <Group title={group.title} type={group.type} plans={group.plans} subscriptions={subscription ? subscription.items : []}/>)
                    }
                </div>
                {
                    contracted ? 
                        <Button fullWidth style={{ padding: "25px 100px" }} onClick={onUpdate} variant="contained" color="primary">Modificar</Button> :
                        <Button fullWidth style={{ padding: "25px 100px" }} onClick={onContract} variant="contained" color="primary">Contratar</Button>
                }
            </Container>
        </Loading>
    )
}

export default Plans