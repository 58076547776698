import * as React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const CheckBoxWidget = ({ port, portMetadata, updateParameter }) => {
    const onChange = (event) => {
        updateParameter(event.target.checked.toString())
    }
    return (
        <FormControlLabel
            control={<Checkbox onChange={onChange} />}
            label={portMetadata.humanLabel ? portMetadata.humanLabel : port.id}
        />
    )
}

export default CheckBoxWidget
