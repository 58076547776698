import { combineReducers } from "redux"

import CreatorBarChartReducer from "./containers/creator/containers/bar-chart/reducer"

export default () => {
    return {
        id: "dashboards",
        reducerMap: {
            dashboards: combineReducers({
                "bar_chart": CreatorBarChartReducer
            })
        }
    }
}