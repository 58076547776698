import React from 'react'
import { PropTypes } from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import HomeIcon from '@material-ui/icons/Home'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import StorageIcon from '@material-ui/icons/Storage'
import ImageIcon from '@material-ui/icons/Image'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import WarningIcon from '@material-ui/icons/Warning'
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Drawer from 'components/Drawer'

import Home from './containers/home/scene'
import Devices from './containers/devices/scene'
import Datasets from './containers/datasets/scene'
import DatasetsV2 from './containers/datasets-v2/scene'
import TrainingSets from './containers/training-sets/scene'
import Models from './containers/models/scene'
import ModelDetails from './containers/models/containers/model-details/scene'
import Monitors from './containers/monitors/scene'
import Incidents from './containers/incidents/scene'
import Dashboards from './containers/dashboards/scene'
import Jobs from './containers/jobs/scene'

import { useQuery } from '@apollo/react-hooks'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles(() => ({
    root: {
        background: '#F2F2F2',
        height: '100%',
    },
    container: {
        marginLeft: 55,
        position: 'relative',
        height: '100%',
    },
    icon: {
        fontSize: 24,
    },
}))

const Industrial = (props) => {
    const classes = useStyles()

    const { isAllowed: isGroupsAllowed = true } = useIsAllowed({
        group: 'groups',
        action: 'getAllGroups',
    })
    const { isAllowed: isDatasetsAllowed = true } = useIsAllowed({
        group: 'datasets',
        action: 'getDatasetsV2ByParent',
    })
    const { isAllowed: isTrainingSetsAllowed = true } = useIsAllowed({
        group: 'trainingSets',
        action: 'getTrainingSets',
    })
    const { isAllowed: isModelsAllowed = true } = useIsAllowed({
        group: 'models',
        action: 'getModels',
    })
    const { isAllowed: isMonitorsAllowed = true } = useIsAllowed({
        group: 'monitors',
        action: 'getMonitors',
    })
    const { isAllowed: isIncidentsAllowed = true } = useIsAllowed({
        group: 'incidents',
        action: 'getIncidentGroups',
    })
    const { isAllowed: isJobsAllowed = true } = useIsAllowed({
        group: 'trainingSets', // TODO: Add permissions for jobs
        action: 'getTrainingSets', // TODO: Add permissions for jobs
    })

    const menu = [
        {
            title: 'Inicio',
            path: '/home',
            icon: <HomeIcon className={classes.icon} />,
            visible: true,
        },
        {
            title: 'Dispositivos',
            path: '/devices',
            icon: <DeviceHubIcon className={classes.icon} />,
            visible: isGroupsAllowed,
        },
        {
            title: 'Datasets',
            path: '/datasets-v2',
            icon: <StorageIcon className={classes.icon} />,
            visible: isDatasetsAllowed,
        },
        {
            title: 'Sets de Entrenamiento',
            path: '/training-sets',
            icon: <ImageIcon className={classes.icon} />,
            visible: isTrainingSetsAllowed,
        },
        {
            title: 'Modelos',
            path: '/models',
            icon: <ShowChartIcon className={classes.icon} />,
            visible: isModelsAllowed,
        },
        {
            title: 'Monitores',
            path: '/monitors',
            icon: <PersonalVideoIcon className={classes.icon} />,
            visible: isMonitorsAllowed,
        },
        {
            title: 'Incidencias',
            path: '/incidents',
            icon: <WarningIcon className={classes.icon} />,
            visible: isIncidentsAllowed,
        },
        {
            title: 'Trabajos',
            path: '/jobs',
            icon: <AccountTreeIcon className={classes.icon} />,
            visible: isJobsAllowed,
        },
        // {
        //     title: "Dashboards",
        //     path: "/dashboards",
        //     icon: <DashboardIcon className={classes.icon}/>
        // }
    ].filter((item) => item.visible)

    return (
        <div className={classes.root}>
            <Drawer {...props} menu={menu} />
            <div className={classes.container}>
                <Switch>
                    <Route path={`${props.match.url}/home`} component={Home} />
                    <Route path={`${props.match.url}/devices`} component={Devices} />
                    <Route path={`${props.match.url}/datasets-v2`} component={DatasetsV2} />
                    <Route path={`${props.match.url}/datasets`} component={Datasets} exact />
                    <Route path={`${props.match.url}/training-sets`} component={TrainingSets} />
                    <Route path={`${props.match.url}/models`} exact component={Models} />
                    <Route path={`${props.match.url}/models/:model`} component={ModelDetails} />
                    <Route path={`${props.match.url}/monitors`} component={Monitors} />
                    <Route path={`${props.match.url}/incidents`} component={Incidents} />
                    <Route path={`${props.match.url}/dashboards`} component={Dashboards} />
                    <Route path={`${props.match.url}/jobs`} component={Jobs} />
                    <Redirect exact from={props.match.url} to={`${props.match.url}/home`} />
                </Switch>
            </div>
        </div>
    )
}

Industrial.propTypes = {
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
}

export default Industrial
