import React from "react"

import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import CreateDatasetDialog from "./containers/create-dataset-dialog/scene"

import { GET_DATASETS_BY_PARENT } from "./queries/datasets"

import { openDataset } from "../../../../reducers"

const Datasets = () => {
    const dispatch = useDispatch()

    const { survey } = useParams()

    const { data: { getHorusDatasetsByParent: { datasets = []} = {}} = {}} = useQuery(GET_DATASETS_BY_PARENT, { variables: { params: { parent: survey }}})

    const onDatasetClickBuilder = (_id, type) => () => {
        dispatch(openDataset(_id, type))
    }

    return (
        <div style={{ height: "100%", position: "relative"}}>
            <List>
                {
                    datasets.map((d, i) => {
                        return (
                            <ListItem button onClick={onDatasetClickBuilder(d._id, d.type)}>
                                <ListItemText primary={d.name} secondary={d.type}></ListItemText>
                            </ListItem>
                        )
                    })
                }
            </List>
            <CreateDatasetDialog />
        </div>
    )
}

export default Datasets