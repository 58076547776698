import React, { useState, useEffect } from "react"

import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { useDispatch, useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

import CircularProgress from '@material-ui/core/CircularProgress';

import uploadDialogStateSelector from "../selectors/upload-dialog-state"

import { closeUploadDialog } from "../../../../../reducers/general"

import { UPLOAD_THERMAL_IMAGE } from "../queries/upload-thermal-image"
import { GET_ALL_IMAGES } from "../../../queries/images"

function UploadFiles(props) {

    const dispatch = useDispatch()

    const { inspection } = useParams()

    const { refetch } = useQuery(GET_ALL_IMAGES, { variables: { params: { dataset: inspection }}})
    const [ getEndpoints ] = useMutation(UPLOAD_THERMAL_IMAGE)
    
    const { files } = useSelector(uploadDialogStateSelector)

    const [ loading, setLoading ] = useState(false)
    const [ indeterminated, setIndeterminate ] = useState(true)
    const [ progress, setProgress ] = useState(0)

    useEffect(() => {
        (async () => {
            setLoading(true)
            const { data: {uploadImages: { endpoints = []} = {}}} = await getEndpoints({
                variables: {
                    params: {
                        type: "RADIOMETRIC",
                        dataset: inspection,
                        images: files.map(f => ({
                            filename: f.name,
                            size: f.size
                        }))
                    }
                }
            })
            setIndeterminate(false)

            const upload = (file, endpoint) => {
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();

                    xhr.open("PUT", endpoint, true);
                    xhr.setRequestHeader('Content-Type', 'binary/octet-stream')
                    xhr.onload  = () => resolve()
                    xhr.onerror = (err) => reject(err)

                    xhr.send(file);
                })
            }
            
            for(let i = 0; i < files.length; i = i + 3) {
                const current = files.slice(i, i + 3)

                await Promise.all(current.map(async (file, j) => {
                    await upload(file, endpoints[i + j])
                }))

                setProgress((i + 3)/files.length * 100)
            }
            setProgress(100)
            setIndeterminate(true)
            setTimeout(() => {
                setIndeterminate(false)
                setLoading(false)
                refetch()
            }, 10000)
        })();
    }, [])

    const onCloseClicked = () => {
        dispatch(closeUploadDialog())
    }

    return (
        <div>
            <Grid container justify="center">
                <CircularProgress variant={indeterminated ? "indeterminate" : "determinate"} value={progress} />
            </Grid>
            <DialogActions>
                <Button disabled={loading} onClick={onCloseClicked}>Cerrar</Button>
            </DialogActions>
        </div>
    )
}

export default UploadFiles