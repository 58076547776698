import React from "react"

import { useSelector } from "react-redux"

import RGB from "./containers/rgb/scene";
import GeoJSON from "./containers/geojson/scene";

const dataTypes = {
    "rgb": RGB,
    "geojson": GeoJSON
}

const Data = props => {

    const { type } = useSelector(state => state.inspector.universal.dataset)

    const DataType = dataTypes[type]

    if(!DataType)
        return null
        
    return (
        <DataType/>
    )
}

export default Data