import React from 'react'
import { PropTypes } from 'prop-types'
import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-markup'
import 'prismjs/themes/prism-coy.css'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <List>
                <ListSubheader>Rutas</ListSubheader>
                <ListItem>
                    <TextField
                        label="Ruta de Entrada"
                        value={getProperty('input-key', null)}
                        onChange={onPropertyChangeBuilder('input-key')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Ruta de Salida"
                        value={getProperty('output-key', null)}
                        onChange={onPropertyChangeBuilder('output-key')}
                        fullWidth
                    />
                </ListItem>
                <ListSubheader>Javascript</ListSubheader>
                <ListItem>
                    <div
                        style={{
                            width: '100%',
                            height: 500,
                            overflowY: 'auto',
                            border: '1px solid black',
                        }}
                    >
                        <Editor
                            fullWidth
                            value={getProperty('code', '')}
                            onValueChange={onPropertyChangeBuilder('code', (txt) => txt)}
                            padding={10}
                            highlight={(cod) => highlight(cod, languages.js)}
                            style={{
                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                                minHeight: '100%',
                                minWidth: '100%',
                            }}
                        />
                    </div>
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Versión</InputLabel>
                        <Select
                            value={getProperty('version', '$LATEST')}
                            onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                        >
                            {nodeDefinition.conf.versions.map((version) => (
                                <MenuItem key={`${node.id}_version${version}`} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
