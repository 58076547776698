import React, { useState, useEffect } from 'react'

import { Switch, Route } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"

import useMediaQuery from '@material-ui/core/useMediaQuery';

import MapGL, { Marker } from 'react-map-gl'

import Grid from "@material-ui/core/Grid"
import Paper from '@material-ui/core/Paper';
import Hidden from "@material-ui/core/Hidden"

import ProjectList from './containers/project-list/scene';
import NewProjectDialog from "./containers/new-project-dialog"

import Config from "config"

import { GET_ALL_PROJECTS } from "./queries/projects"

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SIZE = 20;

function Selector(props) {
    
    const [ viewport, setViewport ] = useState({
        width: 0,
        height: 0,
        longitude: 0,
        latitude: 0,
        zoom: 1,
        maxZoom: 20
    })

    const { data: { getProjectsByParent: { projects = []} = {}} = {}, loading } = useQuery(GET_ALL_PROJECTS, { variables: { params: {}}})

    const onViewportChange = (newViewport) =>  {
        setViewport({...viewport, ...newViewport})
    }

    let matches = 0;
    const md = useMediaQuery(theme => theme.breakpoints.between('xs', "md"))
    const lg = useMediaQuery(theme => theme.breakpoints.between("md", "xl"))
    if(md) {
        matches = 8
    } else if(lg) {
        matches = 10
    }
    
    const init = useEffect(() => {
        onViewportChange({ width: window.innerWidth*matches/12, height: window.innerHeight });
    }, [ matches ])
 
    const data = projects
    .filter(project => !!project.position)
    
    return(
        <Grid container style={{ height: "calc(100vh - 63px)", overflow: "hidden"}}>
            <Hidden smDown>
                <Grid item lg={10} md={8}>
                    <MapGL 
                        {...viewport}     
                        mapStyle={"mapbox://styles/mapbox/light-v9"}      
                        mapboxApiAccessToken={Config.app.mapbox_token}
                        onViewportChange={onViewportChange}
                    >  
                        {
                            data
                            .map((project, index) => (
                                <Marker key={`marker-${index}`} longitude={project.position.coordinates[0]} latitude={project.position.coordinates[1]}>
                                    <svg
                                        height={SIZE}
                                        viewBox="0 0 24 24"
                                        style={{
                                            cursor: 'pointer',
                                            fill: '#d00',
                                            stroke: 'none',
                                            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                                        }}
                                    >
                                        <path d={ICON} />
                                    </svg>
                                </Marker>
                            ))
                        }
                    </MapGL>
                </Grid>
            </Hidden>
            <Grid item lg={2} md={4} xs={12}>
                <Paper elevation={1} style={{ position: "fixed", zIndex: 5, background: "#FFF", height: "100%", width: "100%" }}>
                    <Switch>
                        <Route path={props.match.url} component={ProjectList} />
                    </Switch>
                </Paper>
            </Grid>
            <NewProjectDialog/>
        </Grid>
    )
}

export default Selector