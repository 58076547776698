import React from "react"

import TextField from "@material-ui/core/TextField"
import ListItem from "@material-ui/core/ListItem"

const Scene = (props) => {

    const  { terminator = "", timeout } = JSON.parse(props.options || '{}')

    return (
        <React.Fragment>
            <ListItem>
                <TextField
                    variant="outlined"
                    label="Caracter de terminación"
                    value={terminator}
                    onChange={
                        props.onPropertyChangedHandlerBuilder(
                            'options',
                            (e) => {
                                return JSON.stringify({
                                    terminator: e.target.value,
                                    timeout: timeout
                                })
                            }
                        )
                    }
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    variant="outlined"
                    label="Tiempo de espera"
                    value={timeout}
                    onChange={
                        props.onPropertyChangedHandlerBuilder(
                            'options',
                            (e) => {
                                return JSON.stringify({
                                    terminator: terminator,
                                    timeout: e.target.value
                                })
                            }
                        )
                    }
                    fullWidth
                    type="number"
                />
            </ListItem>
        </React.Fragment>
    )
}

export default Scene