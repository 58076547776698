import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"

import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Divider from "@material-ui/core/Divider"

import ActionButton from "./containers/action-button"
import Settings from "./containers/settings"
import ImageViewer from "./containers/image-viewer";
import FileList from "./containers/file-list"
import OrthophotosListView from "./containers/orthophotos-list-view"
import JobsList from "./containers/jobs-list"

function SideMenu(props) {

    const classes = useStyles()

    const [ tab, setTab ] = useState(0)

    const tabs = [
        {
            name: "Controles",
            component: <Settings />
        },
        {
            name: "Imágenes",
            component: <FileList images={props.images}/>
        }, 
        {
            name: "Análisis",
            component: <JobsList/>
        },
        {
            name: "Ortofotos",
            component: <OrthophotosListView/>
        }
    ]
    return (
        <div className={classes.root}>
            <div style={{ flex: 0 }}>
            <ImageViewer/>
            <Divider/>
            <Tabs 
                value={tab}
                variant="scrollable"
                scrollButtons="auto"
                onChange={(event, newValue) => setTab(newValue)}
            >
                {tabs.map(tab => <Tab label={tab.name}/>)}
            </Tabs>
            </div>
            <Divider/>
            <div className={classes.list}>
                    {tabs[tab].component}
            </div>
            <ActionButton/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        overflow: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        background: "#FFF"
    },
    list: {
        width: "100%", 
        overflowY: "auto",  
        flex: 1 
    }
}))

export default SideMenu