import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { green } from '@material-ui/core/colors'

import { closeNewBlueprintDialog } from '../../reducers'
import { CREATE_BLUEPRINT, GET_BLUEPRINTS_BY_GROUP } from '../../../../../../queries/blueprints'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const NewBlueprintDialog = () => {
    const classes = useStyles()

    const { group } = useParams()

    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)

    const { open } = useSelector((state) => state.group.blueprints)

    const dispatch = useDispatch()

    const [createBlueprint] = useMutation(CREATE_BLUEPRINT)

    const onCancelClicked = () => dispatch(closeNewBlueprintDialog())
    const onAcceptClicked = async () => {
        setLoading(true)
        await createBlueprint({
            variables: {
                params: { group, name },
            },
            refetchQueries: [
                {
                    query: GET_BLUEPRINTS_BY_GROUP,
                    variables: {
                        params: {
                            group,
                        },
                    },
                },
            ],
            awaitRefetchQueries: true,
        })
        setLoading(false)
        dispatch(closeNewBlueprintDialog())
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Nuevo Blueprint</DialogTitle>
            <DialogContent>
                <TextField
                    label="Nombre"
                    value={name}
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClicked}>Cancelar</Button>
                <div className={classes.root}>
                    <div className={classes.wrapper}>
                        <Button disabled={loading} onClick={onAcceptClicked}>
                            Aceptar
                        </Button>
                        {loading && (
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default NewBlueprintDialog
