import React, { useRef, Children, useState } from 'react'
import { PropTypes } from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Box from '@mui/material/Box'

import DraggableObject from './dragabbleObject'

const CURSOR_TYPE_MAP = {
    right: 'w-resize',
    top: 'n-resize',
    left: 'e-resize',
    bottom: 's-resize',
}

/**
 * Clone recursively all the downstream childs of a react component.
 *
 * @param {React.Component} obj
 * @returns {React.Component} Clone child components
 */
const deepChildrenRender = (obj) => {
    const clonedChildrens = Children.map(obj.props.children || [], (child) => {
        if (React.isValidElement(child)) {
            return deepChildrenRender(child)
        }
        return child
    })
    return React.cloneElement(obj, { ...obj.props, children: clonedChildrens })
}

/**
 *
 * React component to catch dragging and change style
 */
const IntermediateComponent = ({
    anchor,
    position,
    isDragging,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseLeave,
}) => {
    const WIDTH = position >= 15 ? 5 : 15
    const isDraggingStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'primary.dark',
        '&:hover': {
            cursor: CURSOR_TYPE_MAP[anchor],
        },
        opacity: 0,
    }
    let notDraggingStyle = {
        position: 'absolute',
        backgroundColor: 'primary.dark',
        '&:hover': {
            cursor: CURSOR_TYPE_MAP[anchor],
            backgroundColor: 'primary.light',
        },
        opacity: 1,
    }
    if (anchor === 'right') {
        notDraggingStyle = {
            ...notDraggingStyle,
            right: position,
            top: 0,
            height: '100%',
            width: WIDTH,
        }
    } else if (anchor === 'top') {
        notDraggingStyle = {
            ...notDraggingStyle,
            left: 0,
            top: position,
            height: WIDTH,
            width: '100%',
        }
    } else if (anchor === 'left') {
        notDraggingStyle = {
            ...notDraggingStyle,
            left: position,
            top: 0,
            height: '100%',
            width: WIDTH,
        }
    } else if (anchor === 'bottom') {
        notDraggingStyle = {
            ...notDraggingStyle,
            left: 0,
            bottom: position,
            height: WIDTH,
            width: '100%',
        }
    }
    return (
        <Box
            sx={isDragging ? isDraggingStyle : notDraggingStyle}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
        />
    )
}

IntermediateComponent.propTypes = {
    /* Anchoring size */
    anchor: PropTypes.string.isRequired,
    /* Position of grabber */
    position: PropTypes.number.isRequired,
    /* True if is dragging */
    isDragging: PropTypes.bool,
    /* On Mouse down action */
    onMouseDown: PropTypes.func,
    /* onMouseMove action */
    onMouseMove: PropTypes.func,
    /* onMouseUp action */
    onMouseUp: PropTypes.func,
    /* onMouseLeave action */
    onMouseLeave: PropTypes.func,
}

IntermediateComponent.defaultProps = {
    isDragging: false,
    onMouseDown: () => {},
    onMouseMove: () => {},
    onMouseUp: () => {},
    onMouseLeave: () => {},
}

/**
 *  Growable sidebar
 */
const GrowableSideBar = ({ anchor, size, paperProps, children }) => {
    const paperRef = useRef(null)

    const [freeEdgePosition, setFreeEdgePosition] = useState(size)

    const transform = {
        applyInverseToPoint: (data) => data,
    }
    let tempPaperProps = paperProps || {}
    tempPaperProps = {
        ...tempPaperProps,
        style: {
            ...tempPaperProps.style,
            position: 'absolute',
        },
    }
    if (anchor === 'right') {
        tempPaperProps = {
            ...tempPaperProps,
            style: {
                ...tempPaperProps.style,
                right: 0,
                top: 0,
                width: freeEdgePosition,
                height: '100%',
            },
        }
    } else if (anchor === 'top') {
        tempPaperProps = {
            ...tempPaperProps,
            style: {
                ...tempPaperProps.style,
                left: 0,
                top: 0,
                height: freeEdgePosition,
                width: '100%',
            },
        }
    } else if (anchor === 'left') {
        tempPaperProps = {
            ...tempPaperProps,
            style: {
                ...tempPaperProps.style,
                left: 0,
                top: 0,
                width: freeEdgePosition,
                height: '100%',
            },
        }
    } else if (anchor === 'bottom') {
        tempPaperProps = {
            ...tempPaperProps,
            style: {
                ...tempPaperProps.style,
                left: 0,
                bottom: 0,
                height: freeEdgePosition,
                width: '100%',
            },
        }
    }
    const moveEffect = (movX, movY) => {
        if (anchor === 'right') {
            setFreeEdgePosition(Math.max(freeEdgePosition - movX, 0))
        } else if (anchor === 'top') {
            setFreeEdgePosition(Math.max(freeEdgePosition + movY, 0))
        } else if (anchor === 'left') {
            setFreeEdgePosition(Math.max(freeEdgePosition + movX, 0))
        } else if (anchor === 'bottom') {
            setFreeEdgePosition(Math.max(freeEdgePosition - movY, 0))
        }
    }
    return (
        <React.Fragment>
            <Paper ref={paperRef} {...tempPaperProps}>
                {Children.map(children, (obj) => deepChildrenRender(obj))}
            </Paper>
            <DraggableObject transform={transform} moveEffect={moveEffect}>
                <IntermediateComponent anchor={anchor} position={freeEdgePosition} />
            </DraggableObject>
        </React.Fragment>
    )
}

GrowableSideBar.propTypes = {
    /* Anchoring size: left, right, top, bottom */
    anchor: PropTypes.string.isRequired,
    /* Initial size  */
    size: PropTypes.number,
    /* Children inside paper */
    children: PropTypes.elementType,
    /* Paper props */
    paperProps: Paper.propTypes,
}

GrowableSideBar.defaultProps = {
    paperProps: Paper.defaultProps,
    size: 400,
    children: null,
}

export default GrowableSideBar
