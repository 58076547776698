import React, { useRef, useState } from 'react'
import { PropTypes } from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error'
import Box from '@mui/material/Box'

/**
 * Component that render an status button.
 * Allowing circular progress render when loading is true,
 * and success or error renderization
 */
const ButtonStatus = ({
    buttonChildren,
    buttonProps,
    loading,
    error,
    animationTimeout,
    loadingIcon,
    successIcon,
    errorIcon,
}) => {
    const [resultAnimation, setResultAnimation] = useState(false)

    const timer = useRef(null)
    const fromLoading = useRef(false)
    if (loading && !fromLoading.current) {
        fromLoading.current = true
    }
    if (fromLoading.current && !loading) {
        fromLoading.current = false
        setResultAnimation(true)
        timer.current = setTimeout(() => {
            setResultAnimation(false)
            fromLoading.current = false
            clearTimeout(timer)
            timer.current = null
        }, animationTimeout)
    }

    const showLoading = loading
    const showSuccess = !loading && !error && resultAnimation
    const showError = !loading && error && resultAnimation
    const showNormal = !showLoading && !showSuccess && !showError

    if (showNormal) {
        return <Button {...buttonProps}>{buttonChildren}</Button>
    }
    if (showLoading) {
        return (
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button {...{ ...buttonProps, disabled: true }}>{buttonChildren}</Button>
                {loadingIcon}
            </Box>
        )
    }
    if (showSuccess) {
        return (
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button {...{ ...buttonProps, disabled: true }}>{buttonChildren}</Button>
                {successIcon}
            </Box>
        )
    }
    if (showError) {
        return (
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button {...{ ...buttonProps, disabled: true }}>{buttonChildren}</Button>
                {errorIcon}
            </Box>
        )
    }
    return <Button {...buttonProps}>{buttonChildren}</Button>
}

ButtonStatus.propTypes = {
    /** Children of the button */
    buttonChildren: PropTypes.elementType.isRequired,
    /** Button props */
    buttonProps: PropTypes.object,
    /** True to show the loading Icon */
    loading: PropTypes.bool,
    /** True to begin an animation with errorIcon that
     * takes animationTimeout seconds longer.
     */
    error: PropTypes.bool,
    /** Icon to show as loading */
    loadingIcon: PropTypes.elementType,
    /** Icon to show on success */
    successIcon: PropTypes.elementType,
    /** Icon to show on error */
    errorIcon: PropTypes.elementType,
    /** Error and success animation seconds */
    animationTimeout: PropTypes.number,
}

ButtonStatus.defaultProps = {
    buttonProps: {},
    loading: false,
    error: false,
    loadingIcon: (
        <CircularProgress
            size={20}
            color="primary"
            style={{
                position: 'absolute',
                top: 'calc(50% - 10px)',
                left: 'calc(50% - 10px)',
            }}
        />
    ),
    successIcon: (
        <DoneIcon
            fontSize="large"
            color="success"
            style={{
                position: 'absolute',
                top: 'calc(50% - 10px)',
                left: 'calc(50% - 10px)',
            }}
        />
    ),
    errorIcon: (
        <ErrorIcon
            fontSize="large"
            color="error"
            style={{
                position: 'absolute',
                top: 'calc(50% - 10px)',
                left: 'calc(50% - 10px)',
            }}
        />
    ),
    animationTimeout: 3000,
}

export default ButtonStatus
