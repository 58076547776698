import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Logo from 'static/logo-light.png'

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#025DA8',
    },
}))

const LeftSide = () => {
    const classes = useStyles()

    return (
        <Hidden smDown>
            <Grid
                item
                md={5}
                className={classes.root}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={5}>
                    <img src={Logo} alt="logo" style={{ width: '100%' }} />
                </Grid>
            </Grid>
        </Hidden>
    )
}

export default LeftSide
