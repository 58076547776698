import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ErrorIcon from '@mui/icons-material/Error'
import DoneIcon from '@mui/icons-material/Done'

import ButtonStatus from '../../../../../../../../../../../../../utils/common-components/buttonStatus'

import { setAutoSaveConf } from '../../../redux/settings'

const Scene = () => {
    const dispatch = useDispatch()

    const autosaveConf = useSelector((state) => state.trainingSetObjectDetection.settings.autosave)

    const [autosaveTime, setAutosaveTime] = useState(autosaveConf.time)

    const onAccept = async () => {
        dispatch(setAutoSaveConf({ time: autosaveTime }))
    }

    const onTimeChange = (event) => {
        setAutosaveTime(parseInt(event.target.value, 10))
    }

    const errorIcon = (
        <ErrorIcon
            fontSize="large"
            color="error"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    const successIcon = (
        <DoneIcon
            fontSize="large"
            color="success"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    return (
        <List>
            <ListItem>
                <TextField
                    fullWidth
                    type="number"
                    label="Segundos"
                    value={autosaveTime}
                    onChange={onTimeChange}
                />
            </ListItem>
            <ListItem>
                <ButtonStatus
                    buttonChildren="Guardar"
                    buttonProps={{
                        color: 'primary',
                        variant: 'contained',
                        onClick: onAccept,
                    }}
                    errorIcon={errorIcon}
                    successIcon={successIcon}
                    animationTimeout={3000}
                />
            </ListItem>
        </List>
    )
}

export default Scene
