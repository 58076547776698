import gql from "graphql-tag"

export const CREATE_ORTHOPHOTO = gql`
    mutation CreateOrthophoto($params: CreateOrthophotoRequest!) {
        createOrthophoto(params: $params) {
            status
            url
        }
    }
`

export const GET_ORTHOPHOTOS_BY_DATASET = gql`
    query GetOrthophotosByDataset($params: GetOrthophotosByDatasetRequest!) {
        getOrthophotosByDataset(params: $params) {
            status
            orthophotos {
                _id
                url
            }
        }
    }
`

export const REMOVE_ORTHOPHOTO_BY_ID = gql`
    mutation RemoveOrthophotoById($params: RemoveOrthophotoByIdRequest!) {
        removeOrthophotoById(params: $params) {
            status
        }
    }
`