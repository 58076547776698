import React from "react"

import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { useMutation  } from "@apollo/react-hooks"

import Tooltip from '@material-ui/core/Tooltip';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AddIcon from "@material-ui/icons/Publish"
import MapIcon from '@material-ui/icons/Map';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { openUploadDialog, openOrthophotosUploadDialog, openAnalyseDialog } from "../../../../../../reducers/general"
import { OPEN_EXPORT_CSV_DIALOG } from "../../../../queries/local"

const useStyles = makeStyles(theme => ({
    fab: {
        position: "fixed", 
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}))

export default function() {
    const classes = useStyles()
    const history = useHistory()
    
    const dispatch = useDispatch()

    const [open, setOpen] = React.useState(false);  
  
    const [ openExportCsv ] = useMutation(OPEN_EXPORT_CSV_DIALOG)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    const actions = [
        {
            name: "Añadir imágenes",
            icon: <AddIcon/>,
            onClick: () => dispatch(openUploadDialog())
        },
        {
            name: "Añadir ortofotos",
            icon: <MapIcon/>,
            onClick: () => dispatch(openOrthophotosUploadDialog())
        },
        {
            name: "Procesar",
            icon: <AssessmentIcon/>,
            onClick: () => dispatch(openAnalyseDialog())
        },
        ...(process.env.NODE_ENV === "development" ? [{
            name: "Nuevo informe",
            icon: <PictureAsPdfIcon/>,
            onClick: () => history.push(history.location.pathname + "/report")
        }]:[]),
        {
            name: "Exportar CSV",
            icon: <ListAltIcon/>,
            onClick: () => openExportCsv()
        }
    ]

    return (
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.fab}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        >
            {actions.map(action => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={action.onClick}
                />
            ))}
        </SpeedDial>
    )
}