import React, { useEffect, useRef, useState, useContext } from 'react'
import { useDispatch, useSelector, ReactReduxContext } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@mui/material/styles'
import Grid from '@material-ui/core/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Button from '@mui/material/Button'

import Boxes from './containers/boxes/scene'
import Layouts from './containers/layouts/scene'
import Stacks from './containers/stacks/scene'
import Settings from './containers/settings/scene'
import Obstacles from './containers/obstacles-scene/scene'

import { GET_PARAMS_BY_GROUP } from '../../params/queries/params'
import { editParameter } from './reducers/settings'
import { updateBox } from './reducers/boxes'
import { updateLayout } from './reducers/layout'
import { updateObstacle } from './reducers/obstacles'
import { updateStack } from './reducers/stack'

const Sections = {
    0: Boxes,
    1: Layouts,
    2: Stacks,
    3: Obstacles,
    4: Settings,
}

const Palletizer = () => {
    const { group } = useParams()
    const theme = useTheme()
    const settings = useSelector((state) => state.group.palletizer.settings)
    const [section, setSection] = useState(0)
    const [open, setOpen] = useState(true)
    const dialogRef = useRef()

    const history = useHistory()
    const dispatch = useDispatch()
    const { store } = useContext(ReactReduxContext)

    const CurrentSection = Sections[section]

    const onTabChange = (e, t) => setSection(t)

    const onExitClick = () => {
        setOpen(false)
        history.push(window.location)
    }

    const { data: { getParamsByGroup: { params: parametersGQL = [] } = {} } = {}, loading } =
        useQuery(GET_PARAMS_BY_GROUP, { variables: { params: { group } }, pollInterval: 10000 })

    useEffect(() => {
        // Update redux state
        // Get settings
        const settingsParameter = parametersGQL.filter((param) => param.name === 'settings')[0]
        // // Setting settings
        let settingsFromParameter = {}
        if (settingsParameter) {
            settingsFromParameter = JSON.parse(settingsParameter.local)
            Object.keys(settingsFromParameter).forEach((set) => {
                dispatch(editParameter(set, settingsFromParameter[set]))
            })
        }
        // // Setting boxes
        const boxesParameter = parametersGQL.filter(
            (param) => param.name === (settingsFromParameter.boxes || settings.boxes)
        )[0]
        if (boxesParameter) {
            const boxesFromParam = JSON.parse(boxesParameter.local)
            Object.keys(boxesFromParam.byId).forEach((box) => {
                dispatch(
                    updateBox(box, {
                        name: boxesFromParam.byId[box].name,
                        id: box,
                        measurements: boxesFromParam.byId[box].size,
                        weight: boxesFromParam.byId[box].weight,
                        unsaved: false,
                        labelSide: boxesFromParam.byId[box].labelSide,
                    })
                )
            })
        }
        // // Setting layouts
        const layoutsParameter = parametersGQL.filter(
            (param) => param.name === (settingsFromParameter.layouts || settings.layouts)
        )
        const boxRef = store.getState().group.palletizer.boxes.boxes
        if (layoutsParameter.length > 0) {
            const layoutsFromParam = JSON.parse(layoutsParameter[0].local)
            Object.keys(layoutsFromParam.byId).forEach((lay) => {
                const layObj = layoutsFromParam.byId[lay]
                const boxes = []
                layObj.boxes.forEach((box, i) => {
                    if (boxRef[box.id]) {
                        boxes.push({
                            id: i,
                            boxId: box.id,
                            position: [
                                box.position[0],
                                box.position[1],
                                boxRef[box.id].measurements[2] / 2,
                            ],
                            rotation: box.orientation,
                        })
                    }
                })
                dispatch(
                    updateLayout(lay, {
                        id: lay,
                        name: layObj.name,
                        size: layObj.size,
                        layout: boxes,
                        unsaved: false,
                    })
                )
            })
        }
        // // Setting obstacles
        const obstaclesParameter = parametersGQL.filter(
            (param) => param.name === (settingsFromParameter.obstacles || settings.obstacles)
        )

        if (obstaclesParameter.length > 0) {
            const obstaclesFromParam = JSON.parse(obstaclesParameter[0].local)

            obstaclesFromParam.forEach((obstacle, index) => {
                dispatch(
                    updateObstacle(index, {
                        id: obstacle.id,
                        name: obstacle.name,
                        pose: {
                            position: [
                                obstacle.pose.position.x,
                                obstacle.pose.position.y,
                                obstacle.pose.position.z,
                            ],
                            rotation: [0, 0, 0],
                            orientation: [
                                obstacle.pose.orientation.x,
                                obstacle.pose.orientation.y,
                                obstacle.pose.orientation.z,
                                obstacle.pose.orientation.w,
                            ],
                            positionNeedsUpdate: true,
                            rotationNeedsSync: true,
                        },
                        properties: {
                            size: [
                                obstacle.properties.size.x,
                                obstacle.properties.size.y,
                                obstacle.properties.size.z,
                            ],
                        },
                        unsaved: false,
                    })
                )
            })
        }
        // // Setting stacks
        const stacksParameter = parametersGQL.filter((param) => param.name === settings.stacks)
        const layoutRef = store.getState().group.palletizer.layouts.layouts
        if (stacksParameter.length > 0) {
            const stacksFromParam = JSON.parse(stacksParameter[0].local)
            Object.keys(stacksFromParam.byId).forEach((stack) => {
                const stackObj = stacksFromParam.byId[stack]
                const layouts = []
                stackObj.layouts.forEach((lay, index) => {
                    if (layoutRef[lay.id]) {
                        layouts.push({
                            id: index,
                            layoutId: lay.id,
                            position: lay.position,
                            rotation: lay.orientation,
                            cardboard: {
                                exists: lay.cardboard.exists,
                                thickness: lay.cardboard.thickness,
                                size: lay.cardboard.size,
                            },
                        })
                    }
                })
                dispatch(
                    updateStack(stack, {
                        name: stackObj.name,
                        id: stackObj.id,
                        layouts,
                        unsaved: false,
                    })
                )
            })
        }
    }, [parametersGQL, loading])

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                ref={dialogRef}
                stack={true}
                fullWidth
                maxWidth="xl"
                style={{ overflow: 'hidden' }}
            >
                <DialogTitle>Paletizador</DialogTitle>
                <Divider />
                <Grid container style={{ height: '80vh', width: '100%', overflow: 'hidden' }}>
                    <Grid item xs={1} style={{ height: '100%' }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={section}
                            onChange={onTabChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            style={{ height: '95%' }}
                        >
                            <Tab label="Boxes" />
                            <Tab label="Layouts" />
                            <Tab label="Stacks" />
                            <Tab label="Obstacles" />
                            <Tab label="Settings" />
                        </Tabs>
                        <Grid container style={{ width: '100%' }}>
                            <Grid item xs={2} />
                            <Grid item xs={8} style={{ height: '100%' }}>
                                <Button variant="contained" onClick={onExitClick} color="primary">
                                    Salir
                                </Button>
                            </Grid>
                            <Grid item xs={2} />
                        </Grid>
                    </Grid>
                    <Grid item xs={11} style={{ height: '100%' }}>
                        <CurrentSection />
                    </Grid>
                </Grid>
            </Dialog>
        </ThemeProvider>
    )
}

export default Palletizer
