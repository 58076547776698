import React, { useState } from 'react'

import { useParams, useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

import EditIcon from '@material-ui/icons/Edit'

import { UPDATE_MONITOR, GET_MONITOR_BY_ID } from '../../../../../../queries/monitors'

import { useIsAllowed } from 'queries/permissions'

const Scene = (props) => {
    const history = useHistory()

    const { monitor } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = props.config || {}
    const trigger = config.trigger || {}

    const durationValue = trigger.duration ? trigger.duration.value : ''

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [tempDurationValue, setTempDurationValue] = useState(durationValue)

    const [updateMonitor] = useMutation(UPDATE_MONITOR)

    const { isAllowed: isUpdateAllowed } = useIsAllowed({
        group: 'monitors',
        action: 'updateMonitor',
    })

    const onAccept = async () => {
        setLoading(true)

        try {
            await updateMonitor({
                variables: {
                    params: {
                        id: monitor,
                        configuration: JSON.stringify({
                            ...props.config,
                            trigger: {
                                ...props.config.trigger,
                                duration: {
                                    ...props.config.trigger.duration,
                                    value: tempDurationValue,
                                },
                            },
                        }),
                    },
                },
                refetchQueries: [
                    {
                        query: GET_MONITOR_BY_ID,
                        variables: {
                            params: {
                                id: monitor,
                            },
                        },
                    },
                ],
            })

            enqueueSnackbar(
                'Duración actualizada correctamente. Recuerde que para que los cambios tengan efecto debe desplegar los grupos asignados a este monitor',
                {
                    variant: 'success',
                    action: (snackbarId) => (
                        <React.Fragment>
                            <Button
                                onClick={() => {
                                    history.push('../devices')
                                    closeSnackbar(snackbarId)
                                }}
                            >
                                IR A GRUPOS
                            </Button>
                            <Button
                                onClick={() => {
                                    closeSnackbar(snackbarId)
                                }}
                            >
                                DESCARTAR
                            </Button>
                        </React.Fragment>
                    ),
                    autoHideDuration: 10000,
                }
            )
        } catch (error) {
            enqueueSnackbar('Ha ocurrido un error al guardar los cambios', {
                variant: 'error',
            })
        } finally {
            setLoading(false)
            setOpen(false)
        }
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onOpenRequest = () => {
        setOpen(true)
    }

    const onDurationValueChange = (event) => {
        setTempDurationValue(event.target.value)
    }

    return (
        <React.Fragment>
            <ListItem>
                <ListItemText primary="Duración" secondary={durationValue} />
                {isUpdateAllowed && (
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="edit" onClick={onOpenRequest}>
                            <EditIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Dialog open={open}>
                <DialogTitle>Unidad de duración</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="durationValue"
                        label="Valor"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={tempDurationValue}
                        onChange={onDurationValueChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAccept} disabled={loading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Scene
