import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import Login from './containers/login'
import SignUp from './containers/signup'
import NewPassword from './containers/new-password'
import ForgotPassword from './containers/forgot-password'
import ConfirmForgotPassword from './containers/confirm-forgot-password'
import Logout from './containers/logout'

function Authentication(props) {
    const { match, location } = props

    return (
        <Switch>
            <Redirect exact from={match.url} to={match.url + '/login' + location.hash} />
            <Route exact={true} path={match.url + '/login'} component={Login} />
            <Route exact={true} path={match.url + '/signup'} component={SignUp} />
            <Route
                exact={true}
                path={match.url + '/new-password/:email/:session'}
                component={NewPassword}
            />
            <Route exact={true} path={match.url + '/forgot-password'} component={ForgotPassword} />
            <Route
                exact={true}
                path={match.url + '/confirm-forgot-password'}
                component={ConfirmForgotPassword}
            />
            <Route exact={true} path={match.url + '/logout'} component={Logout} />
        </Switch>
    )
}

export default Authentication
