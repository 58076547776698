const SET_SELECTED_BOX = 'SET_SELECTED_BOX'
const CREATE_BOX = 'CREATE_BOX'
const UPDATE_BOX = 'UPDATE_BOX'
const RELOAD = 'RELOAD'
const DELETE_BOX = 'DELETE'

// Annotations actions
export const setSelectedBox = (index) => ({
    type: SET_SELECTED_BOX,
    index,
})

export const updateBox = (index, data) => ({
    type: UPDATE_BOX,
    index,
    data,
})

export const createBox = (index) => ({
    type: CREATE_BOX,
    index,
})

export const deleteBox = (index) => ({
    type: DELETE_BOX,
    index,
})

export const reload = () => ({
    type: RELOAD,
})

export const initialState = {
    boxes: {},
    selectedBox: null,
}

/* eslint-disable default-param-last */
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_BOX:
            return { ...state, selectedBox: action.index }
        case UPDATE_BOX:
            return {
                ...state,
                boxes: {
                    ...state.boxes,
                    [action.index]: { ...state.boxes[action.index], unsaved: true, ...action.data },
                },
            }
        case CREATE_BOX:
            return {
                ...state,
                boxes: {
                    ...state.boxes,
                    [action.index]: {
                        name: `UNTITLED BOX ${Object.keys(state.boxes).length}`,
                        id: action.index,
                        measurements: [0.4, 0.2, 0.2],
                        weight: 5,
                        unsaved: true,
                        labelSide: 0,
                    },
                },
            }
        case DELETE_BOX:
            return {
                ...state,
                boxes: Object.keys(state.boxes)
                    .filter((box) => box !== action.index)
                    .reduce((prev, next) => {
                        return { ...prev, [next]: state.boxes[next] }
                    }, {}),
            }
        case RELOAD:
            return {
                ...state,
                boxes: {},
                selectedBox: null,
            }
        default:
            return state
    }
}
