import React, { useState } from 'react'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TextField from '@material-ui/core/TextField'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const DEFAULT_ADVANCED = [
    { name: 'model_version', value: 'yolov5m.pt' },
    { name: 'augmentation', value: JSON.stringify([]) },
    { name: 'hsv_h', value: '0.015' },
    { name: 'hsv_s', value: '0.7' },
    { name: 'hsv_v', value: '0.4' },
    { name: 'degrees', value: '0.0' },
    { name: 'translate', value: '0.1' },
    { name: 'scale', value: '0.5' },
    { name: 'shear', value: '0.0' },
    { name: 'perspective', value: '0.0' },
    { name: 'flipud', value: '0.0' },
    { name: 'fliplr', value: '0.5' },
    { name: 'mosaic', value: '1.0' },
    { name: 'mixup', value: '0.0' },
    { name: 'copy_paste', value: '0.0' },
    { name: 'lr0', value: '0.01' },
    { name: 'lrf', value: '0.01' },
    { name: 'momentum', value: '0.937' },
    { name: 'weight_decay', value: '0.0005' },
    { name: 'warmup_epochs', value: '3.0' },
    { name: 'warmup_momentum', value: '0.8' },
    { name: 'warmup_bias_lr', value: '0.1' },
    { name: 'box', value: '0.05' },
    { name: 'cls', value: '0.5' },
    { name: 'cls_pw', value: '1.0' },
    { name: 'obj', value: '1.0' },
    { name: 'obj_pw', value: '1.0' },
    { name: 'iou_t', value: '0.20' },
    { name: 'anchor_t', value: '4.0' },
    { name: 'patience', value: '10' },
]

const ENTRY_WIDTH = '70%'

const ColorJitter = ({ params, onChange }) => {
    const [collapse, setCollapse] = useState(true)
    const [brightness, setBrightness] = useState(params['brightness'] || 0.2)
    const [contrast, setContrast] = useState(params['contrast'] || 0.2)
    const [saturation, setSaturation] = useState(params['saturation'] || 0.2)
    const [hue, setHue] = useState(params['hue'] || 0.2)
    const [p, setP] = useState(params['p'] || 0.0)

    const onChangeP = (e) => {
        setP(e.target.value)
        onChange('p', parseFloat(e.target.value))
    }

    const onChangeBrightness = (e) => {
        setBrightness(e.target.value)
        onChange('brightness', parseFloat(e.target.value))
    }
    const onChangeContrast = (e) => {
        setContrast(e.target.value)
        onChange('contrast', parseFloat(e.target.value))
    }
    const onChangeSaturation = (e) => {
        setSaturation(e.target.value)
        onChange('saturation', parseFloat(e.target.value))
    }
    const onChangeHue = (e) => {
        setHue(e.target.value)
        onChange('hue', parseFloat(e.target.value))
    }

    return (
        <>
            <ListItem>
                <ListItemText primary="Color jitter" />
                <ListItemSecondaryAction onClick={() => setCollapse(!collapse)}>
                    {!collapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={!collapse} timeout="auto" unmountOnExit>
                <ListItem>
                    <ListItemText primary="p" />
                    <TextField
                        type="number"
                        value={p}
                        onChange={onChangeP}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary="brightness" />
                    <TextField
                        type="number"
                        value={brightness}
                        onChange={onChangeBrightness}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary="contrast" />
                    <TextField
                        type="number"
                        value={contrast}
                        onChange={onChangeContrast}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary="saturation" />
                    <TextField
                        type="number"
                        value={saturation}
                        onChange={onChangeSaturation}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText primary="hue" />
                    <TextField
                        type="number"
                        value={hue}
                        onChange={onChangeHue}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                    />
                </ListItem>
            </Collapse>
        </>
    )
}

const InvertImg = ({ params, onChange }) => {
    const [collapse, setCollapse] = useState(true)
    const [p, setP] = useState(params['p'] || 0.0)

    const onChangeP = (e) => {
        setP(e.target.value)
        onChange('p', parseFloat(e.target.value))
    }

    return (
        <>
            <ListItem>
                <ListItemText primary="Invert Img" />
                <ListItemSecondaryAction onClick={() => setCollapse(!collapse)}>
                    {!collapse ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={!collapse} timeout="auto" unmountOnExit>
                <ListItem>
                    <ListItemText primary="p" />
                    <TextField
                        type="number"
                        value={p}
                        onChange={onChangeP}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                    />
                </ListItem>
            </Collapse>
        </>
    )
}

const Scene = ({ value, onChange }) => {
    const [collapseModelSize, setCollapseModelSize] = useState(true)
    const [collapseAugmentation, setCollapseAugmentation] = useState(true)
    const [collapseHyperparameters, setCollapseHyperparameters] = useState(true)

    const options = value

    if (options.length === 0) {
        onChange(DEFAULT_ADVANCED)
    }

    const optionsByKey = options.reduce((acc, option) => {
        acc[option.name] = option.value
        return acc
    }, {})

    const onModelVersionChanged = (event) => {
        optionsByKey['model_version'] = event.target.value
        const optionsList = Object.entries(optionsByKey).map(([name, valueV]) => {
            return {
                name,
                value: valueV,
            }
        })
        onChange(optionsList)
    }

    const onColorJitterChange = (param, paramValue) => {
        optionsByKey['augmentation'] = JSON.stringify([
            {
                name: 'ColorJitter',
                params: {
                    ...(JSON.parse(optionsByKey['augmentation'])[0] || { params: {} }).params,
                    [param]: paramValue,
                },
            },
            ...JSON.parse(optionsByKey['augmentation']).slice(1),
        ])
        console.log(optionsByKey['augmentation'])
        const optionsList = Object.entries(optionsByKey).map(([name, valueV]) => {
            return {
                name,
                value: valueV,
            }
        })
        onChange(optionsList)
    }
    const onInvertImgChange = (param, paramValue) => {
        optionsByKey['augmentation'] = JSON.stringify([
            JSON.parse(optionsByKey['augmentation'])[0],
            {
                name: 'InvertImg',
                params: { ...optionsByKey['augmentation'][0].params, [param]: paramValue },
            },
            ...JSON.parse(optionsByKey['augmentation']).slice(2),
        ])
        const optionsList = Object.entries(optionsByKey).map(([name, valueV]) => {
            return {
                name,
                value: valueV,
            }
        })
        onChange(optionsList)
    }
    const onParamChange = (param) => (e) => {
        optionsByKey[param] = e.target.value
        const optionsList = Object.entries(optionsByKey).map(([name, valueV]) => {
            return {
                name,
                value: valueV,
            }
        })
        onChange(optionsList)
    }

    const modelVersion = optionsByKey['model_version'] || ''
    const augmentation = JSON.parse(optionsByKey['augmentation'] || JSON.stringify([]))

    return (
        <List component="div" disablePadding style={{ marginLeft: 18 }}>
            <ListItem>
                <ListItemText primary="Tamaño del modelo" secondary={modelVersion} />
                <ListItemSecondaryAction onClick={() => setCollapseModelSize(!collapseModelSize)}>
                    {!collapseModelSize ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={!collapseModelSize} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ marginLeft: 18 }}>
                    <ListItem>
                        <FormControl fullWidth>
                            <InputLabel>Tamaño del modelo</InputLabel>
                            <Select value={modelVersion} onChange={onModelVersionChanged}>
                                <MenuItem value="yolov5n.pt">Muy pequeño</MenuItem>
                                <MenuItem value="yolov5s.pt">Pequeño</MenuItem>
                                <MenuItem value="yolov5m.pt">Mediano</MenuItem>
                                <MenuItem value="yolov5l.pt">Grande</MenuItem>
                                <MenuItem value="yolov5x.pt">Muy grande</MenuItem>
                            </Select>
                        </FormControl>
                    </ListItem>
                </List>
            </Collapse>
            <ListItem>
                <ListItemText primary="Transformaciones" />
                <ListItemSecondaryAction
                    onClick={() => setCollapseAugmentation(!collapseAugmentation)}
                >
                    {!collapseAugmentation ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={!collapseAugmentation} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ marginLeft: 18 }}>
                    <ColorJitter
                        params={(augmentation[0] || { params: {} }).params}
                        onChange={onColorJitterChange}
                    />
                    <InvertImg
                        params={(augmentation[1] || { params: {} }).params}
                        onChange={onInvertImgChange}
                    />
                    <ListItem>
                        <ListItemText primary="HSV H:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['hsv_h']}
                            onChange={onParamChange('hsv_h')}
                            InputProps={{ inputProps: { min: 0, max: 1 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="HSV S:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['hsv_s']}
                            onChange={onParamChange('hsv_s')}
                            InputProps={{ inputProps: { min: 0, max: 1 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="HSV V:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['hsv_v']}
                            onChange={onParamChange('hsv_v')}
                            InputProps={{ inputProps: { min: 0, max: 1 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Degrees:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['degrees']}
                            onChange={onParamChange('degrees')}
                            InputProps={{ inputProps: { min: -360, max: +360 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Trasladar:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['translate']}
                            onChange={onParamChange('translate')}
                            InputProps={{ inputProps: { min: -1, max: +1 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Escalar:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['scale']}
                            onChange={onParamChange('scale')}
                            InputProps={{ inputProps: { min: -1, max: +1 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Inclinar:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['shear']}
                            onChange={onParamChange('shear')}
                            InputProps={{ inputProps: { min: -360, max: +360 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Perspectiva:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['perspective']}
                            onChange={onParamChange('perspective')}
                            InputProps={{ inputProps: { min: 0.0, max: 1.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Simetría vertical:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['flipud']}
                            onChange={onParamChange('flipud')}
                            InputProps={{ inputProps: { min: 0.0, max: 1.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Simetría horizontal:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['fliplr']}
                            onChange={onParamChange('fliplr')}
                            InputProps={{ inputProps: { min: 0.0, max: 1.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Mosaico:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['mosaic']}
                            onChange={onParamChange('mosaic')}
                            InputProps={{ inputProps: { min: 0.0, max: 1.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Mezclar:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['mixup']}
                            onChange={onParamChange('mixup')}
                            InputProps={{ inputProps: { min: 0.0, max: 1.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Copiar y pegar:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['copy_paste']}
                            onChange={onParamChange('copy_paste')}
                            InputProps={{ inputProps: { min: 0.0, max: 1.0 } }}
                        />
                    </ListItem>
                </List>
            </Collapse>
            <ListItem>
                <ListItemText primary="Hiperparámetros" />
                <ListItemSecondaryAction
                    onClick={() => setCollapseHyperparameters(!collapseHyperparameters)}
                >
                    {!collapseAugmentation ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={!collapseHyperparameters} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ marginLeft: 18 }}>
                    <ListItem>
                        <ListItemText primary="Early stop:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['patience']}
                            onChange={onParamChange('patience')}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="lr 0:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['lr0']}
                            onChange={onParamChange('lr0')}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="lr f:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['lrf']}
                            onChange={onParamChange('lrf')}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Momento:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['momentum']}
                            onChange={onParamChange('momentum')}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Decaimiento de pesos:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['weight_decay']}
                            onChange={onParamChange('weight_decay')}
                            InputProps={{ inputProps: { min: 0, max: 1 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Epochs de calentamiento:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['warmup_epochs']}
                            onChange={onParamChange('warmup_epochs')}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Momento de calentamiento:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['warmup_momentum']}
                            onChange={onParamChange('warmup_momentum')}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Bias de calentamiento:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['warmup_bias_lr']}
                            onChange={onParamChange('warmup_bias_lr')}
                            InputProps={{ inputProps: { min: 0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Box:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['box']}
                            onChange={onParamChange('box')}
                            InputProps={{ inputProps: { min: 0.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Clase :" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['cls']}
                            onChange={onParamChange('cls')}
                            InputProps={{ inputProps: { min: 0.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Clase positiva:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['cls_pw']}
                            onChange={onParamChange('cls_pw')}
                            InputProps={{ inputProps: { min: 0.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Objeto:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['obj']}
                            onChange={onParamChange('obj')}
                            InputProps={{ inputProps: { min: 0.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Objeto positivo:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['obj_pw']}
                            onChange={onParamChange('obj_pw')}
                            InputProps={{ inputProps: { min: 0.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="IoU:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['iou_t']}
                            onChange={onParamChange('iou_t')}
                            InputProps={{ inputProps: { min: 0.0, max: 1.0 } }}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Anchors:" />
                        <TextField
                            type="number"
                            style={{ width: ENTRY_WIDTH }}
                            value={optionsByKey['anchor_t']}
                            onChange={onParamChange('anchor_t')}
                            InputProps={{ inputProps: { min: 0.0 } }}
                        />
                    </ListItem>
                </List>
            </Collapse>
        </List>
    )
}

export default Scene
