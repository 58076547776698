import React from "react"

import { useQuery } from '@apollo/react-hooks'

import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'

import { GET_INCIDENT_GROUP_BY_ID } from "../../../../../../../../incidents/queries/groups"

const Scene = (props) => {
    
    const {
        loading,
        error,
        data: { getIncidentGroupById: { group: { levels = [] } = {} } = {} } = {}
    } = useQuery(GET_INCIDENT_GROUP_BY_ID, { 
        variables: { params: { id: props.group } },
        skip: !props.group
    })

    if(loading) return null

    if(error) return null

    if(!levels.length) return null

    const thresholds = props.properties.thresholds || []

    const handleLevelsChange = (index) => (event, newValue) => {
        props.onChange({
            ...props.options,
            [thresholds[index].name]: newValue
        })
    }

    return (
        <Grid item xs={12} style={{ paddingTop: 20 }}>
            <Paper elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tipo de umbral</TableCell>
                            <TableCell>Tipo de incidencia</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            thresholds.map((threshold, index) => {
                                return (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">{threshold.name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                id="fixed-tags-demo"
                                                options={levels}
                                                getOptionLabel={(option) => option}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Umbral" variant="outlined" placeholder="Registros" />
                                                )}
                                                onChange={handleLevelsChange(index)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Paper>
        </Grid>
    )
}

export default Scene
