import React, { useState } from 'react'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import InputLabel from '@mui/material/InputLabel'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'

import { useJobsRunWorkflow } from 'queries/jobs'
import Widgets from './widgets/widgets'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(3),
    },
    buttonSuccess: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    buttonProgress: {
        color: red[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const DescriptionIcon = ({ description }) => {
    return (
        <Tooltip title={description}>
            <InfoIcon />
        </Tooltip>
    )
}

const NewJobDialog = () => {
    const classes = useStyles()
    const currentWorkflow = useSelector((state) => state.jobs.jobsReducer.currentWorkflow) || {
        inputPorts: [],
    }

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [parameters, setParameters] = useState({})

    const onOpenDialogClicked = () => setOpen(true)
    const onCancelClicked = () => setOpen(false)
    const runWorkflow = useJobsRunWorkflow(currentWorkflow.engine, currentWorkflow.workflowId)
    const onAcceptClicked = async () => {
        setLoading(true)
        runWorkflow(
            { workflowId: currentWorkflow.workflowId },
            Object.keys(parameters).map((param) => {
                return { name: param, value: parameters[param] }
            })
        )
        setOpen(false)
        setLoading(false)
    }

    const onParamChange =
        (parameter, filterValue = (value) => value) =>
        (value) => {
            setParameters({ ...parameters, [parameter]: filterValue(value) })
        }

    return (
        <React.Fragment>
            <Fab className={classes.fab} color="success" onClick={onOpenDialogClicked}>
                <PlayArrowIcon fontSize="large" />
            </Fab>
            <Dialog open={open}>
                <DialogTitle>Ejecutar un nuevo trabajo</DialogTitle>
                <DialogContent>
                    {currentWorkflow.inputPorts.map((port) => {
                        const metadata = port.metadata.reduce((prev, next) => {
                            return { ...prev, [next.field]: next.value }
                        }, {})
                        if (Widgets[metadata['widget']]) {
                            const CustomWidget = Widgets[metadata['widget']]
                            return (
                                <Box key={port.id}>
                                    <CustomWidget
                                        port={port}
                                        portMetadata={metadata}
                                        updateParameter={onParamChange(port.id)}
                                    />
                                </Box>
                            )
                        }
                        return (
                            <Box key={port.id}>
                                <TextField
                                    label={metadata.humanLabel ? metadata.humanLabel : port.id}
                                    onChange={onParamChange(port.id, (event) => event.target.value)}
                                    value={parameters[port.id] || null}
                                    fullWidth
                                />
                                {port.description && (
                                    <DescriptionIcon description={port.description} />
                                )}
                            </Box>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disabled={loading}>
                        Cancelar
                    </Button>
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={onAcceptClicked}
                            >
                                Aceptar
                            </Button>
                            {loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default NewJobDialog
