import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import {
    STRING_TYPE,
    INTEGER_TYPE,
    FLOAT_TYPE,
    BOOLEAN_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const [rotateMode, setRotateMode] = useState(
        JSON.parse(getProperty('rotate-mode', JSON.stringify({ value: 'auto' }))).value
    )

    const onRotateChangeProp = onPropertyChangeBuilder('rotate-mode', (e) =>
        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
    )
    const onRotateChange = (e) => {
        onRotateChangeProp(e)
        setRotateMode(e.target.value)
    }

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Entrada"
                    value={
                        JSON.parse(getProperty('input-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('input-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="rotate-mode"
                    humanName="Modo de rotación"
                    parametersFilter={STRING_TYPE}
                >
                    <FormControl fullWidth>
                        <InputLabel>Modo de rotación</InputLabel>
                        <Select
                            value={
                                JSON.parse(
                                    getProperty('rotate-mode', JSON.stringify({ value: 'auto' }))
                                ).value
                            }
                            onChange={onRotateChange}
                        >
                            {['auto', 'manual'].map((mode) => (
                                <MenuItem key={mode} value={mode}>
                                    {mode}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            {rotateMode === 'auto' && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <Parametrize
                            name="template-uri"
                            humanName="Template"
                            parametersFilter={STRING_TYPE}
                        >
                            <TextField
                                label="Template"
                                value={
                                    JSON.parse(
                                        getProperty('template-uri', JSON.stringify({ value: null }))
                                    ).value
                                }
                                onChange={onPropertyChangeBuilder('template-uri', (e) =>
                                    JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                                )}
                                fullWidth
                            />
                        </Parametrize>
                    </Grid>
                    <Grid item xs={12}>
                        <Parametrize
                            name="fast-th"
                            humanName="Umbral fast"
                            parametersFilter={INTEGER_TYPE}
                        >
                            <TextField
                                label="Umbral fast"
                                type="number"
                                min="0"
                                value={
                                    JSON.parse(
                                        getProperty('fast-th', JSON.stringify({ value: 20 }))
                                    ).value
                                }
                                onChange={onPropertyChangeBuilder('fast-th', (e) =>
                                    JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                                )}
                                fullWidth
                            />
                        </Parametrize>
                    </Grid>
                    <Grid item xs={12}>
                        <Parametrize
                            name="edge-th"
                            humanName="Umbral edge"
                            parametersFilter={INTEGER_TYPE}
                        >
                            <TextField
                                label="edge-th"
                                type="number"
                                min="0"
                                value={
                                    JSON.parse(
                                        getProperty('edge-th', JSON.stringify({ value: 31 }))
                                    ).value
                                }
                                onChange={onPropertyChangeBuilder('edge-th', (e) =>
                                    JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                                )}
                                fullWidth
                            />
                        </Parametrize>
                    </Grid>
                    <Grid item xs={12}>
                        <Parametrize name="wta-k" humanName="WTA-K" parametersFilter={INTEGER_TYPE}>
                            <TextField
                                label="WTA-K"
                                type="number"
                                min="0"
                                value={
                                    JSON.parse(getProperty('wta-k', JSON.stringify({ value: 2 })))
                                        .value
                                }
                                onChange={onPropertyChangeBuilder('wta-k', (e) =>
                                    JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                                )}
                                fullWidth
                            />
                        </Parametrize>
                    </Grid>
                    <Grid item xs={12}>
                        <Parametrize
                            name="distance-th"
                            humanName="Distancia umbral"
                            parametersFilter={FLOAT_TYPE}
                        >
                            <TextField
                                label="Distancia umbral"
                                type="number"
                                min="0"
                                value={
                                    JSON.parse(
                                        getProperty('distance-th', JSON.stringify({ value: 0.65 }))
                                    ).value
                                }
                                onChange={onPropertyChangeBuilder('distance-th', (e) =>
                                    JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                                )}
                                fullWidth
                            />
                        </Parametrize>
                    </Grid>
                </React.Fragment>
            )}
            {rotateMode === 'manual' && (
                <Grid item xs={12}>
                    <Parametrize name="angle" humanName="Ángulo" parametersFilter={FLOAT_TYPE}>
                        <TextField
                            label="Ángulo"
                            type="number"
                            min="-360"
                            max="360"
                            value={
                                JSON.parse(getProperty('angle', JSON.stringify({ value: 0 }))).value
                            }
                            onChange={onPropertyChangeBuilder('angle', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            fullWidth
                        />
                    </Parametrize>
                </Grid>
            )}
            <Grid item xs={12}>
                <Parametrize
                    name="overwrite"
                    humanName="Sobrescribir"
                    parametersFilter={BOOLEAN_TYPE}
                >
                    <FormControl fullWidth>
                        <InputLabel>Sobrescribir</InputLabel>
                        <Select
                            value={
                                JSON.parse(
                                    getProperty('overwrite', JSON.stringify({ value: 'false' }))
                                ).value
                            }
                            onChange={onPropertyChangeBuilder('overwrite', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            <MenuItem key="true" value="true">
                                Activado
                            </MenuItem>
                            <MenuItem key="false" value="false">
                                Desactivado
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
