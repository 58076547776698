import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useHistory } from "react-router-dom"

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ImageIcon from '@material-ui/icons/Image';

import Loading from "components/Loading"

import NewDashboardDialog from "./containers/new-dashboard-dialog/scene"

import { GET_DASHBOARDS_BY_PARENT } from "./queries/dashboards";

function Dashboards(props) {

    const history = useHistory()

    const { data: { getDashboardsByParent: { dashboards = []} = {}} = {}, loading } = useQuery(GET_DASHBOARDS_BY_PARENT, { variables: { params: {}}})

    return (
        <Container>
            <Typography style={{ padding: "30px 0" }} variant="h2">Dashboards</Typography>
            <Loading loading={loading}>
                {
                     dashboards.length === 0 ? 
                     <div style={{ margin: "20px", textAlign: "center"}}>
                         Cree un dashboard para continuar
                         &#8600;
                     </div> :
                     <Paper style={{ marginTop: 20 }}>
                        <List>
                            {dashboards.map(d => (
                                <ListItem button>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={d.name}  secondary={d.createdAt}  onClick={() => history.push("dashboards/" + d.id)}/>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                }
            </Loading>
            <NewDashboardDialog/>
        </Container>
    )
}

export default Dashboards