import React, { useState } from "react"

import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"

import CloseIcon from '@material-ui/icons/Close';

import SwipeableViews from 'react-swipeable-views';
import Data from "./containers/data/scene"
import Settings from "./containers/settings/scene"

const Dataset = props => {

    const { dataset: { _id } = {}, type } = useSelector(state => state.inspector.universal)
    const [ tab, setTab ] = useState(0)

    const onTabChange = (e, newValue) => {
        setTab(newValue)
    }

    if(!_id) {
        return (
            <Grid container item xs={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    Select a dataset to continue
                </Grid>
            </Grid> 
        )
    }

    return (
        <Grid item xs={2}>
            <Paper style={{ height: "100%", width: "100%", position: "relative" }}>
                <React.Fragment>
                    <IconButton style={{ position: "absolute", left: -60, bottom: 10, background: "#FFF" }}><CloseIcon/></IconButton>
                    <Tabs value={tab} onChange={onTabChange} variant="scrollable" scrollButtons="on">
                        <Tab label="Data"/>
                        <Tab label="Settings"/>
                    </Tabs>
                    <Divider />
                    <SwipeableViews
                        axis={'x'}
                        index={tab}
                        style={{ height: "calc(100% - 48px)", width: "100%" }}
                        containerStyle={{ height: "100%", width: "100%" }}
                    >
                        <Data />
                        <Settings />
                    </SwipeableViews>
                </React.Fragment>
            </Paper>
        </Grid> 
    )
}

export default Dataset