import _ from 'lodash'

const SET_IMAGE_DATA = 'SET_IMAGE_DATA'
const SELECT_NEXT_ENTRY = 'SET_NEXT_ENTRY'
const SELECT_PREVIOUS_ENTRY = 'SELECT_PREVIOUS_ENTRY'
const CHECK_IMAGE_ACTION = 'LABELER/CHECK_IMAGE'

const SET_SELECTED_ANNOTATION_INDEX = 'SET_SELECTED_ANNOTATION_INDEX'
const SET_BULK_INPLACE_ANNOTATION = 'SET_BULK_INPLACE_ANNOTATION'
const SET_BY_INDEX_INPLACE_ANNOTATION = 'SET_BY_INDEX_INPLACE_ANNOTATION'
const SET_ANNOTATION_CLASS_INDEX = 'SET_ANNOTATION_CLASS_INDEX'
const APPEND_INPLACE_ANNOTATION = 'APPEND_INPLACE_ANNOTATION'
const REMOVE_BY_INDEX_INPLACE_ANNOTATION = 'REMOVE_BY_INDEX_INPLACE_ANNOTATION'
const REMOVE_SELECTED_ANNOTATION = 'REMOVE_SELECTED_ANNOTATION'
const SET_INPLACE_ANNOTATION_BY_ENTRY = 'SET_INPLACE_ANNOTATION_BY_ENTRY'
const RESTART_INPLACE_ANNOTATIONS = 'RESTART_INPLACE_ANNOTATIONS'

const SET_SELECTED_ENTRY = 'SET_SELECTED_ENTRY'
const SET_INPLACE_ENTRY = 'SET_INPLACE_ENTRY'

const SET_EDIT_MODE = 'SET_EDIT_MODE'
const DESELECT = 'DESELECT'
const SAVE_IN_PLACE = 'SAVE_IN_PLACE'

// Annotations actions
export const setAnnotationIndex = (index) => ({
    type: SET_SELECTED_ANNOTATION_INDEX,
    index,
})

export const setAnnotationClass = (classIndex) => ({
    type: SET_ANNOTATION_CLASS_INDEX,
    classIndex,
})

export const setBulkInplaceAnnotation = (annotations) => ({
    type: SET_BULK_INPLACE_ANNOTATION,
    annotations,
})

export const setByIndexInplaceAnnotation = (annotation, index) => ({
    type: SET_BY_INDEX_INPLACE_ANNOTATION,
    annotation,
    index,
})

export const appendInPlaceAnnotation = (annotation) => ({
    type: APPEND_INPLACE_ANNOTATION,
    annotation,
})

export const removeByIndexInPlaceAnnotation = (index) => ({
    type: REMOVE_BY_INDEX_INPLACE_ANNOTATION,
    index,
})

export const removeSelectedAnnotation = () => ({
    type: REMOVE_SELECTED_ANNOTATION,
})

export const setInPlaceAnnotationByEntry = (entryId, annotations) => ({
    type: SET_INPLACE_ANNOTATION_BY_ENTRY,
    entryId,
    annotations,
})

export const restartInPlaceAnnotations = () => ({
    type: RESTART_INPLACE_ANNOTATIONS,
})

// Entries actions
export const setSelectedEntry = (entry) => ({
    type: SET_SELECTED_ENTRY,
    entry,
})
export const setInPlaceEntry = (entry) => ({
    type: SET_INPLACE_ENTRY,
    entry,
})
export const selectNextEntry = () => ({
    type: SELECT_NEXT_ENTRY,
})
export const selectPreviousEntry = () => ({
    type: SELECT_PREVIOUS_ENTRY,
})

export const setImageData = (entries) => ({ type: SET_IMAGE_DATA, entries })

// Behavior actions
export const setEditMode = (activated) => ({ type: SET_EDIT_MODE, activated })

export const deselect = () => ({ type: DESELECT })

export const saveInPlace = (isSaving = true) => ({ type: SAVE_IN_PLACE, isSaving })

export const initialState = {
    selectedAnnotation: null,
    inPlaceAnnotations: {},
    selectedEntry: null,
    inPlaceEntry: {},
    allIds: [],
    entries: {},
    editMode: false,
    saveInPlace: false,
}

export const reducer = (state = initialState, action) => {
    const actualAnnotations = _.get(state.inPlaceAnnotations, state.selectedEntry, [])
    switch (action.type) {
        case SET_SELECTED_ANNOTATION_INDEX:
            return { ...state, selectedAnnotation: action.index }
        case SET_ANNOTATION_CLASS_INDEX:
            if (state.selectedEntry === null) {
                return state
            }
            if (state.selectedAnnotation === null) {
                const nextAnnotationIndex = state.inPlaceAnnotations[state.selectedEntry].length
                return {
                    ...state,
                    editMode: true,
                    selectedAnnotation: nextAnnotationIndex,
                    inPlaceAnnotations: {
                        ...state.inPlaceAnnotations,
                        [state.selectedEntry]: [
                            ...state.inPlaceAnnotations[state.selectedEntry],
                            {
                                coords: [null, null, null, null],
                                transcription: 'ChangeTranscription',
                                class: action.classIndex,
                            },
                        ],
                    },
                }
            }
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [
                        ...state.inPlaceAnnotations[state.selectedEntry].slice(
                            0,
                            state.selectedAnnotation
                        ),
                        {
                            ...state.inPlaceAnnotations[state.selectedEntry][
                                state.selectedAnnotation
                            ],
                            class: action.classIndex,
                        },
                        ...state.inPlaceAnnotations[state.selectedEntry].slice(
                            state.selectedAnnotation + 1
                        ),
                    ],
                },
            }
        case SET_BULK_INPLACE_ANNOTATION:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: action.annotations,
                },
            }
        case SET_BY_INDEX_INPLACE_ANNOTATION:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [
                        ...actualAnnotations.slice(0, action.index),
                        action.annotation,
                        ...actualAnnotations.slice(action.index + 1),
                    ],
                },
            }
        case APPEND_INPLACE_ANNOTATION:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [...actualAnnotations, action.annotation],
                },
            }
        case REMOVE_BY_INDEX_INPLACE_ANNOTATION:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [
                        ...actualAnnotations.slice(0, action.index),
                        ...actualAnnotations.slice(action.index + 1),
                    ],
                },
            }
        case REMOVE_SELECTED_ANNOTATION:
            return {
                ...state,
                selectedAnnotation: null,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [
                        ...actualAnnotations.slice(0, state.selectedAnnotation),
                        ...actualAnnotations.slice(state.selectedAnnotation + 1),
                    ],
                },
            }
        case SET_INPLACE_ANNOTATION_BY_ENTRY:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [action.entryId]: action.annotations,
                },
            }
        case RESTART_INPLACE_ANNOTATIONS:
            return {
                ...state,
                inPlaceAnnotations: {},
            }
        case SET_SELECTED_ENTRY:
            return { ...state, selectedEntry: action.entry }
        case SET_INPLACE_ENTRY:
            return { ...state, inPlaceEntry: action.entry }
        case SELECT_NEXT_ENTRY:
            if (state.selectedEntry === null) {
                return {
                    ...state,
                    selectedEntry: state.allIds[0],
                }
            }
            return {
                ...state,
                selectedEntry:
                    state.allIds[state.allIds.indexOf(state.selectedEntry) + 1] ||
                    state.allIds[state.allIds.length - 1],
            }
        case SELECT_PREVIOUS_ENTRY:
            if (state.selectedEntry === null) {
                return {
                    ...state,
                    selectedEntry: state.allIds[state.allIds.length - 1],
                }
            }
            return {
                ...state,
                selectedEntry:
                    state.allIds[state.allIds.indexOf(state.selectedEntry) - 1] || state.allIds[0],
            }
        case SET_IMAGE_DATA:
            return {
                ...state,
                entries: action.entries.reduce((prev, next) => {
                    return { ...prev, [next._id]: { url: next.url } }
                }, {}),
                allIds: action.entries.map((obj) => obj._id),
            }
        case SET_EDIT_MODE:
            return {
                ...state,
                editMode: action.activated,
            }
        case DESELECT:
            if (!(state.selectedAnnotation === null)) {
                return {
                    ...state,
                    selectedAnnotation: null,
                }
            }
            if (!(state.selectedEntry === null)) {
                return {
                    ...state,
                    selectedEntry: null,
                }
            }
            return {
                ...state,
            }
        case SAVE_IN_PLACE:
            return {
                ...state,
                saveInPlace: action.isSaving,
            }
        default:
            return state
    }
}
