import React from "react"


import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import DeleteIcon from '@material-ui/icons/Delete'


const Scene = (props) => {
    const config = props.config || {}
    const action = props.action || {}
    
    return (
        <ListItem>
            <ListItemText 
                primary={`Action: ${action.type}`} 
                secondary={JSON.stringify(action)} 
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default Scene
