import gql from 'graphql-tag'

export const CREATE_DATASET_V2 = gql`
    mutation CreateDatasetV2($params: CreateDatasetV2Request!) {
        createDatasetV2(params: $params) {
            status
            dataset {
                createdAt
                name
            }
        }
    }
`

export const GET_DATASETS_V2_BY_PARENT = gql`
    query GetDatasetsV2ByParent($params: GetDatasetsV2ByParentRequest!) {
        getDatasetsV2ByParent(params: $params) {
            status
            datasets {
                id
                createdAt
                name
            }
        }
    }
`

export const GET_DATASETS_V2_BY_ID = gql`
    query GetDatasetV2ById($params: GetDatasetV2ByIdRequest!) {
        getDatasetV2ById(params: $params) {
            status
            dataset {
                fields {
                    name
                    type
                }
            }
        }
    }
`

export const GET_DATASETS_V2_ENTRIES_BY_DATASET = gql`
    query GetDatasetV2EntriesByDataset($params: GetDatasetV2EntriesByDatasetRequest!) {
        getDatasetV2EntriesByDataset(params: $params) {
            status
            entries {
                id
                createdAt
                fields {
                    name
                    properties {
                        name
                        value
                    }
                }
            }
            count
        }
    }
`

export const EXPORT_DATASET_V2_ENTRIES_BY_DATASET = gql`
    mutation ExportDatasetV2EntriesByDataset($params: ExportDatasetV2EntriesByDatasetRequest!) {
        exportDatasetV2EntriesByDataset(params: $params) {
            status
            url
        }
    }
`
