import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'

import ImageIcon from '@material-ui/icons/Image'

import Loading from 'components/Loading'

import NewDatasetDialog from './containers/new-dataset-dialog/scene'

import { useIsAllowed } from 'queries/permissions'
import { useGetDatasetsV2ByParent, useSetDatasetV2ArchiveById } from 'queries/datasets'

import TableWithSearch from 'components/TableWithSearch'
import IconButtonStatus from 'components/IconButtonStatus'

const ListView = () => {
    const history = useHistory()

    const [tab, setTab] = useState(0)
    const [archiveLoading, setArchiveLoading] = useState(false)
    const [columns, setColumns] = useState([
        {
            title: 'Nombre',
            field: 'name',
            sortDirection: 'asc',
        },
        {
            title: 'Fecha de creación',
            field: 'createdAt',
            sortDirection: 'asc',
        },
    ])
    const [selected, setSelected] = useState([])

    const { isAllowed: isCreateDatasetAllowed } = useIsAllowed({
        action: 'createDatasetV2',
        group: 'datasets',
    })

    const { datasets = [], loading } = useGetDatasetsV2ByParent(undefined, tab === 0 ? false : true)
    const setDatasetV2ArchiveById = useSetDatasetV2ArchiveById({
        updateAllIds: true,
        updateById: false,
    })

    const onTabChange = (event, newValue) => {
        setTab(newValue)
    }

    const onSelectChange = (s) => {
        setSelected(s)
    }

    const toolbarOpen = selected.length > 0

    const onArchive = async () => {
        try {
            setArchiveLoading(true)
            await Promise.all(
                selected.map((id) => {
                    return setDatasetV2ArchiveById(id, tab === 0)
                })
            )
        } catch (e) {
            console.log(e)
        } finally {
            setArchiveLoading(false)
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Paper style={{ width: '100%', height: '100%' }}>
                    <Typography style={{ padding: '15px 20px' }} variant="h2">
                        Datasets
                    </Typography>
                    <Divider />
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={onTabChange}
                    >
                        <Tab label="Activos" />
                        <Tab label="Archivados" />
                    </Tabs>
                    <Divider />
                    <TableWithSearch
                        height="calc(100vh - 246px)"
                        button
                        columns={columns}
                        data={datasets}
                        fullScreen={false}
                        onRowClick={(rowData) => {
                            history.push('datasets-v2/' + rowData.id)
                        }}
                        onDirectionChange={(column, direction) => {
                            const newColumns = columns.map((col) => {
                                if (col.field === column) {
                                    return {
                                        ...col,
                                        sortDirection: direction,
                                    }
                                }
                                return col
                            })

                            setColumns(newColumns)
                        }}
                        loading={loading}
                        onSelectChange={onSelectChange}
                    />
                </Paper>
            </Container>
            <Paper
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    height: toolbarOpen ? 50 : 0,
                    width: '100%',
                    transition: '0.5s ease',
                }}
            >
                <Grid container alignItems="center">
                    <Grid item xs></Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            borderLeft: '1px solid #EFEFEF',
                            borderRight: '1px solid #EFEFEF',
                        }}
                    >
                        <Tooltip title={tab === 0 ? 'Archivar' : 'Desarchivar'}>
                            <IconButtonStatus
                                loading={archiveLoading}
                                iconButtonProps={{
                                    onClick: onArchive,
                                    disabled: selected.length === 0,
                                }}
                                icon={tab === 0 ? <ArchiveIcon /> : <UnarchiveIcon />}
                                error={false}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
            </Paper>
            {isCreateDatasetAllowed && <NewDatasetDialog />}
        </React.Fragment>
    )
}

export default ListView
