import React, { useState } from "react"

import List from '@material-ui/core/List'

import ListThresholds from "./containers/list-thresholds/scene"

import AddRangeThresholdDialog from "./containers/add-range-threshold-dialog/scene"
import AddValueThresholdDialog from "./containers/add-value-threshold-dialog/scene"

const addThresholdDialogs = {
    "more-than": {
        component: AddValueThresholdDialog
    },
    "less-than": {
        component: AddValueThresholdDialog
    },
    "range": {
        component: AddRangeThresholdDialog
    }
}

const Scene = (props) => {
    const config = props.config || {}
    
    const trigger = config.trigger || {}
    
    const type = trigger.type || "more-than"

    const renderDialog = () => {
        const addThresholdDialog = addThresholdDialogs[type]
        if(!addThresholdDialog) {
            return null
        }

        const AddThresholdDialog = addThresholdDialog.component
        if(!AddThresholdDialog) {
            return null
        }

        return (
            <AddThresholdDialog config={config} />
        )
    }
        
    return (
        <List style={{ height: "100%" }}>
            <ListThresholds config={config} />
        </List>
    )
}

export default Scene
