import React from "react"

import { useQuery } from '@apollo/react-hooks'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'

import AddIcon from '@material-ui/icons/Add'

import Incidents from './containers/incidents/scene'

const actionComponents = {
    "incidents": {
        component: Incidents
    }
}

const Scene = (props) => {
    const config = props.config || {}

    const actions = config.actions || []
    

    return (
        <List style={{ height: "100%" }}>
            {
                actions.map((action, index) => {
                    const actionComponent = actionComponents[action.type]
                    if(!actionComponent) {
                        return null
                    }
                    
                    const ActionComponent = actionComponent.component
                    if(!ActionComponent) {
                        return null
                    }

                    return (
                        <ActionComponent config={config} action={action} key={index} />
                    )
                })
            }
        </List>
    )
}

export default Scene
