export const OPEN_NEW_DATASET_DIALOG  = '/NEW_DATASET_DIALOG/OPEN'
export const CLOSE_NEW_DATASET_DIALOG = '/CLOSE/NEW_DATASET_DIALOG'

export const openNewDatasetDialog  = () => ({ type: OPEN_NEW_DATASET_DIALOG })
export const closeNewDatasetDialog = ()      => ({type: CLOSE_NEW_DATASET_DIALOG})

export const CREATE_DATASET_PENDING = "CREATE_DATASET/PENDING"
export const CREATE_DATASET_SUCCESS = "CREATE_DATASET/SUCCESS"

export const createDatasetPending = () => ({ type: CREATE_DATASET_PENDING })
export const createDatasetSuccess = () => ({ type: CREATE_DATASET_SUCCESS })

export default (state = { newDatasetDialogOpen: false, createDatasetLoading: false }, action) => {
    switch(action.type) {
        case OPEN_NEW_DATASET_DIALOG:
            return {...state, newDatasetDialogOpen: true}
        case CLOSE_NEW_DATASET_DIALOG:
            return {...state, newDatasetDialogOpen: false}
        case CREATE_DATASET_PENDING:
            return {...state, createDatasetLoading: true}
        case CREATE_DATASET_SUCCESS:
            return {...state, createDatasetLoading: false}
        default:
            return state;
    }
}