import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ChipInput from 'material-ui-chip-input'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

const StepMonitorNotifications = (props) => {
    const classes = useStyles()

    const [cooldown, setCooldown] = useState(null)
    const [cooldownUnit, setCooldownUnit] = useState(null)
    const [maxNotifications, setMaxNotifications] = useState(null)

    const onStepMonitorActionsAccept = () => {
        if (
            !cooldown.length ||
            !cooldownUnit.length ||
            !maxNotifications.length
        ) {
            return
        }

        const units = {
            'sec': 1,
            'min': 60,
            'hr': 3600,
            'day': 86400,
            'week': 604800,
            'month': 2592000,
            'year': 31536000,
        }
        
        props.onPropertyChange(props.property, { 
            cooldown: Math.round(parseFloat(cooldown) * units[cooldownUnit]), 
            maxNotifications: parseInt(maxNotifications),
        })

        props.setActiveStep(props.nextStep)
    }

    const onStepMonitorActionsBack = () => {
        props.setActiveStep(props.prevStep)
    }

    const options = [{ value: 'alert', label: 'Registrar Incidencia' }]

    const onCooldownChange = (event) => {
        setCooldown(event.target.value)
    }

    const onCooldownUnitChange = (event) => {
        setCooldownUnit(event.target.value)
    }

    const onMaxNotificationsChange = (event) => {
        setMaxNotifications(event.target.value)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} container spacing={1}>
                <Grid item xs={2}>
                    <Typography variant="h5">
                        Cooldown
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <TextField 
                        label="Cooldown" 
                        variant="outlined" 
                        fullWidth 
                        value={cooldown} 
                        onChange={onCooldownChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="tiempo-label">Tiempo</InputLabel>
                        <Select 
                            labelId="tiempo-label"
                            value={cooldownUnit}
                            onChange={onCooldownUnitChange}
                        >
                            <MenuItem value="sec">Segundos</MenuItem>
                            <MenuItem value="min">Minutos</MenuItem>
                            <MenuItem value="hour">Horas</MenuItem>
                            <MenuItem value="day">Dias</MenuItem>
                            <MenuItem value="week">Semanas</MenuItem>
                            <MenuItem value="month">Meses</MenuItem>
                            <MenuItem value="year">Años</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
                <Grid item xs={2}>
                    <Typography variant="h5">Nº máximo de notificationes</Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField 
                        label="Número de notificaciones" 
                        variant="outlined" 
                        fullWidth 
                        value={maxNotifications}
                        onChange={onMaxNotificationsChange}/>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.actions}>
                <Grid item xs={2}>
                    <Button onClick={onStepMonitorActionsBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorActionsAccept}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorNotifications
