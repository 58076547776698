import React, { useState } from "react"

import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import UploadImagesDialog from "./containers/upload-images-dialog/scene"
import Data from "./containers/data/scene"

const Right = (props) => {
    const [ tab, setTab ] = useState(0)

    const onTabChange = (e, newValue) => {
        setTab(newValue)
    }
    
    return (
        <Paper style={{ height: "100%" }}>
            <Tabs value={tab} onChange={onTabChange} variant="scrollable" scrollButtons="on">
                <Tab label="Data"/>
                <Tab label="Settings"/>
            </Tabs>
            <div style={{ height: "calc(100vh - 115px)", overflowY: "auto"}}>
                <Data />
            </div>
            <UploadImagesDialog/>
        </Paper>
    )
}

export default Right