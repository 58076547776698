export const SET_CURRENT_WORKFLOW = 'SET_CURRENT_WORKFLOW'

// Annotations actions
export const setCurrentWorkflow = (workflow) => ({
    type: SET_CURRENT_WORKFLOW,
    workflow,
})

export const initialState = {
    currentWorkflow: undefined,
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_WORKFLOW:
            return { ...state, currentWorkflow: action.workflow }
        default:
            return state
    }
}
