import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { withSnackbar } from 'notistack';
import { useQuery } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"

import AssignmentIcon from '@material-ui/icons/Assignment';

import { GET_JOBS_BY_PARENT } from "../../../../queries/jobs"

function JobsList(props) {

    const classes = useStyles()

    const { inspection } = useParams()

    const { data: { getJobsByParent: { jobs = [] } = {}} = {}} = useQuery(GET_JOBS_BY_PARENT, {
        variables: {
            params: {
                parent: inspection 
            }
        },
        onCompleted: ({ getJobsByParent: { status } = {}}) => {
            if(status !== "SUCCEEDED" && status !== "SUCCESS") {
                props.enqueueSnackbar("Se ha producido un error de conexión", { variant: "error" })
            }      
        }
    })

    return jobs.length === 0 ?
        <Typography className={classes.empty}>No hay ningún análisis. Puedes crear uno con el botón +</Typography> :
        <List style={{ overflowY: "auto", maxHeight: "100%"}}>
            { 
                jobs && jobs.map(job => (
                    <ListItem button>
                        <ListItemIcon><AssignmentIcon/></ListItemIcon>
                        <ListItemText primary={job._id}/>
                    </ListItem>
                ))
            }
        </List>
}

const useStyles = makeStyles(theme => ({
    empty: {
        marginTop: 20,
        textAlign: "center"
    }
}))


export default withSnackbar(JobsList)