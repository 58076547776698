import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'

import StepMonitorName from './containers/step-monitor-name/scene'
import StepMonitorLevels from './containers/step-monitor-levels/scene'
import StepMonitorEmails from './containers/step-monitor-emails/scene'
import StepMonitorNotifications from './containers/step-monitor-notifications/scene'

import { CREATE_INCIDENT_GROUP } from '../../queries/groups'

const CreateGroup = (props) => {
    const history = useHistory()

    const [activeStep, setActiveStep] = useState(0)
    const [properties, setProperties] = useState({})

    const onPropertyChange = (property, value) => {
        setProperties({
            ...properties,
            [property]: value,
        })
    }

    const [createIncidentGroup] = useMutation(CREATE_INCIDENT_GROUP)

    const steps = [
        {
            label: 'Nombre',
            property: 'name',
            component: StepMonitorName,
        },
        {
            label: 'Niveles de incidencia',
            property: 'levels',
            component: StepMonitorLevels
        },
        {
            label: 'Notificaciones',
            property: 'emails',
            component: StepMonitorEmails,
        },
        {
            label: 'Configurar',
            property: 'configuration',
            component: StepMonitorNotifications,
        },
    ]

    useEffect(() => {
        const create = async () => {
            console.log(properties)
            await createIncidentGroup({
               variables: {
                   params: {
                        name: properties.name,
                        emails: properties.emails,
                        cooldown: properties.configuration.cooldown,
                        maxNotifications: properties.configuration.maxNotifications,
                        levels: properties.levels,
                   },
               },
            })

            history.push('../incidents')

        }

        if (activeStep === steps.length) {
            create()
        }

        return () => {}

    }, [activeStep])

            

    return (
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <h1>Crear grupo</h1>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => {
                                const Component = step.component

                                return (
                                    <Step key={index}>
                                        <StepLabel>{step.label}</StepLabel>
                                        <StepContent>
                                            <Component
                                                property={step.property}
                                                onPropertyChange={onPropertyChange}
                                                setActiveStep={setActiveStep}
                                                value={properties[step.property]}
                                                nextStep={index + 1}
                                                prevStep={index - 1}
                                            />
                                        </StepContent>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CreateGroup
