import React, { useEffect } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'

import {
    useGetDatasetV2ById,
    useSetDatasetV2ArchiveById,
    useSetDatasetV2TTLById,
} from 'queries/datasets'

import ListItemEditDialogDuration from 'components/ListItemEditDialogDuration'

const Scene = () => {
    const { dataset } = useParams()

    const setDatasetV2ArchiveById = useSetDatasetV2ArchiveById()
    const setDatasetV2TTLById = useSetDatasetV2TTLById()

    const { datasetData = {}, loading } = useGetDatasetV2ById(dataset)

    const onArchiveChange = (event) => {
        setDatasetV2ArchiveById(dataset, event.target.checked)
    }

    if (datasetData === null) return null

    const onConfirm = (value) => {
        setDatasetV2TTLById(dataset, value)
    }

    return (
        <List>
            <ListItem>
                <ListItemText primary="Archivar" />
                <Switch
                    checked={datasetData.archived || false}
                    onChange={onArchiveChange}
                    color="primary"
                />
            </ListItem>
            <ListItemEditDialogDuration
                label="Tiempo de expiración"
                value={datasetData.ttl}
                onConfirm={onConfirm}
            />
        </List>
    )
}

export default Scene
