import React, { useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useParams, useHistory } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'

import DeleteIcon from '@material-ui/icons/Delete'

import AddScheduleDialog from './containers/add-schedule-dialog/scene'

import { UPDATE_MONITOR, GET_MONITOR_BY_ID } from '../../../../queries/monitors'

import { useIsAllowed } from 'queries/permissions'

const Scene = (props) => {
    const history = useHistory()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { monitor } = useParams()

    const { isAllowed: isUpdateAllowed } = useIsAllowed({
        group: 'monitors',
        action: 'updateMonitor',
    })

    const [loading, setLoading] = useState(false)

    const config = props.config || {}

    const schedule = config.schedule || []

    const [updateMonitor] = useMutation(UPDATE_MONITOR)

    const handleDeleteSchedule = (index) => async () => {
        try {
            const newSchedule = schedule.filter((s, i) => i !== index)

            await updateMonitor({
                variables: {
                    params: {
                        id: monitor,
                        configuration: JSON.stringify({
                            ...config,
                            schedule: newSchedule,
                        }),
                    },
                },
                refetchQueries: [
                    {
                        query: GET_MONITOR_BY_ID,
                        variables: {
                            params: {
                                id: monitor,
                            },
                        },
                    },
                ],
            })

            enqueueSnackbar(
                'Horario eliminado correctamente. Recuerde que para que los cambios tengan efecto debe desplegar los grupos asignados a este monitor',
                {
                    variant: 'success',
                    action: (snackbarId) => (
                        <React.Fragment>
                            <Button
                                onClick={() => {
                                    history.push('../devices')
                                    closeSnackbar(snackbarId)
                                }}
                            >
                                IR A GRUPOS
                            </Button>
                            <Button
                                onClick={() => {
                                    closeSnackbar(snackbarId)
                                }}
                            >
                                DESCARTAR
                            </Button>
                        </React.Fragment>
                    ),
                    autoHideDuration: 15000,
                }
            )
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const renderSchedules = () => {
        if (!schedule.length)
            return (
                <Typography variant="h6" style={{ textAlign: 'center', marginTop: 20 }}>
                    No hay horarios. El monitor estará activo 24h. Para añadir un horario, presione
                    el botón +.
                </Typography>
            )

        return schedule.map((s, index) => {
            return (
                <ListItem key={index}>
                    <ListItemText primary={`${s.from} - ${s.to}`} />
                    {isUpdateAllowed && (
                        <ListItemSecondaryAction>
                            <IconButton onClick={handleDeleteSchedule(index)} disabled={loading}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
            )
        })
    }

    return (
        <List style={{ height: '100%' }}>
            {renderSchedules()}
            {isUpdateAllowed && <AddScheduleDialog config={props.config} />}
        </List>
    )
}

export default Scene
