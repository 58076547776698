import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

function AreYouSureButton(props) {

    const { onClick, ...rest} = props

    const classes = useStyles()

    const [ open, setOpen ] = useState(false)

    const onOpen = () => setOpen(true)
    const onCancel = () => setOpen(false)
    const onAccept = () => {
        props.onAccept()
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Button {...rest} onClick={onOpen}></Button>
            <Dialog open={open}>
                <DialogTitle>¿Esta seguro?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        La operación que va realizar no se puede deshacer
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <div className={classes.buttonContainer}>
                        <Button disabled={props.loading} onClick={onAccept}>Aceptar</Button>
                        { props.loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        position: "relative"
    },
    buttonProgress: {
        position: "absolute",
        left: "50%",
        top: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}))

export default AreYouSureButton