import Card from './card'
import Toggle from './toggle'
import Text from './text'
import Button from './button'
import Row from './row'
import View from './view'
import Container from './container'
import Checkbox from './checkbox'
import TextField from './text-field'
import Select from './select'
import Image from './image'
import Scroll from './scroll'
import Tabs from './tabs'
import Palletizer from './palletizer'

export default {
    toggle: Toggle,
    card: Card,
    text: Text,
    button: Button,
    row: Row,
    view: View,
    container: Container,
    checkbox: Checkbox,
    'text-field': TextField,
    select: Select,
    image: Image,
    scroll: Scroll,
    tabs: Tabs,
    palletizer: Palletizer,
}
