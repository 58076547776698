import React from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { GET_RESOURCES_BY_TYPE } from '../../../../../../queries/resources'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const { group } = useParams()

    const { data: { getResourcesByType: { resources = [] } = {} } = {} } = useQuery(
        GET_RESOURCES_BY_TYPE,
        {
            variables: {
                params: {
                    group,
                    type: 'camera',
                },
            },
        }
    )

    return (
        <div className={classes.root}>
            <List>
                <ListItem>
                    <TextField
                        label="Ruta de Entrada"
                        value={getProperty('input-key', null)}
                        onChange={onPropertyChangeBuilder('input-key', (e) => e.target.value || '')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Cámara</InputLabel>
                        <Select
                            onChange={onPropertyChangeBuilder(
                                'camera',
                                (e) => e.target.value || ''
                            )}
                            value={getProperty('camera', '').value}
                        >
                            {resources.map((resource) => (
                                <MenuItem key={resource._id} value={resource._id}>
                                    {resource.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Seleccione una cámara</FormHelperText>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <TextField
                        label="Ruta de Salida"
                        value={getProperty('output-key', '')}
                        onChange={onPropertyChangeBuilder(
                            'output-key',
                            (e) => e.target.value || ''
                        )}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Versión</InputLabel>
                        <Select
                            value={getProperty('version', '$LATEST')}
                            onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                        >
                            {nodeDefinition.conf.versions.map((version) => (
                                <MenuItem key={`${node.id}_version${version}`} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
