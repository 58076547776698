import React, { useCallback, useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/react-hooks"
import { useDispatch } from "react-redux"
import { useDropzone } from 'react-dropzone'

import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography"

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { CREATE_ORTHOPHOTO } from "../../queries/orthophotos"

import { closeOrthophotosUploadDialog } from "../../../../reducers/general"

function UploadOrthophotosDialog(props) {
    const { inspection } = useParams()

    const dispatch = useDispatch()

    const [ files, setFiles ] = useState([])
    const [ loading, setLoading ] = useState(false)

    const onDrop = useCallback(setFiles, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })   
    
    const closeDialog = () => dispatch(closeOrthophotosUploadDialog())

    const [ getEndpoint ] = useMutation(CREATE_ORTHOPHOTO)

    const onUpload = async () => {
        setLoading(true)
        const { data: { createOrthophoto: { url } = {}} = {}} = await getEndpoint({ 
            variables: {
                params: {
                    dataset: inspection
                }
            }
        })

        const upload = (file, endpoint) => {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();

                xhr.open("PUT", endpoint, true);
                xhr.setRequestHeader('Content-Type', 'binary/octet-stream')
                xhr.onload  = () => resolve()
                xhr.onerror = (err) => reject(err)

                xhr.send(file);
            })
        }
        
        await upload(files[0], url)

        setLoading(false)

        closeDialog()        
    }

    return (
        <Dialog open={props.open} onClose={closeDialog}>
            <DialogContent>
                {
                    loading ? 
                    <CircularProgress /> :
                    <React.Fragment>
                        {"Archivos Seleccionados: " + files.length}
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                <Grid container style={{ width: 450, border: isDragActive && "2px solid #AAA", borderRadius: 5, padding: 20, margin: "20px 0", cursor: "pointer" }} justify="center">
                                    <Grid item>
                                        <InsertDriveFileIcon style={{ fontSize: 48, color: "#AAA" }}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ textAlign: "center" }}>{ isDragActive ? "Suelta los archivos aquí" : "Arrastra y suelta los archivos aqui o haz click para seleccionar"}</Typography>
                                    </Grid>
                                </Grid>
                            }
                        </div>
                    </React.Fragment>
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={!files.length} onClick={onUpload}>Subir</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadOrthophotosDialog