import React from 'react'

import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { DynamicModuleLoader } from 'redux-dynamic-modules'

import List from './containers/list/scene'
import Dashboard from './containers/dashboard/scene'
import Creator from './containers/creator/scene'

import getDashboardsModule from './module'

function Dashboards(props) {
    const { pathname } = useLocation()

    return (
        <DynamicModuleLoader modules={[getDashboardsModule()]}>
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                <Route path={props.match.url + '/'} component={List} exact />
                <Route path={props.match.url + '/:dashboard'} component={Dashboard} exact />
                <Route path={props.match.url + '/:dashboard/editor'} component={Creator} />
            </Switch>
        </DynamicModuleLoader>
    )
}

export default Dashboards
