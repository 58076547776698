import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'

import { useIsAllowed } from 'queries/permissions'
import Header from './containers/header/index'
import Blueprints from './containers/blueprints'
import Settings from './containers/settings'
import Resources from './containers/resources'
import Devices from './containers/devices'
import HMI from './containers/hmi/scene'
import Params from './containers/params/scene'
import Sensors from './containers/sensors/index'
import Variables from './containers/variables/scene'
import Palletizer from './containers/apps/palletizer/scene'

const GroupView = () => {
    const [section, setSection] = useState(0)

    const { group } = useParams()

    const { isAllowed: isBlueprintsAllowed } = useIsAllowed({
        action: 'getBlueprintsByGroup',
        group: 'groups',
    })
    const { isAllowed: isVariablesAllowed } = useIsAllowed({
        action: 'getVariablesByGroup',
        group: 'groups',
    })
    const { isAllowed: isParamsAllowed } = useIsAllowed({
        action: 'getParamsByGroup',
        group: 'groups',
    })
    const { isAllowed: isResourcesAllowed } = useIsAllowed({
        action: 'getResourcesByGroup',
        group: 'groups',
    })
    const { isAllowed: isDevicesAllowed } = useIsAllowed({
        action: 'getDevicesByGroup',
        group: 'groups',
    })
    const { isAllowed: isSensorsAllowed } = useIsAllowed({
        action: 'getSensorsByGroup',
        group: 'groups',
    })
    const { isAllowed: isHMIAllowed } = useIsAllowed({
        action: 'getHMIsByGroup',
        group: 'groups',
    })
    const { isAllowed: isPalletizerAllowed } = useIsAllowed({
        action: 'getHMIsByGroup',
        group: 'groups',
    })

    let sections = []

    if (isBlueprintsAllowed) {
        sections = [...sections, { name: 'Blueprints', component: Blueprints }]
    }
    if (isVariablesAllowed) {
        sections = [...sections, { name: 'Variables', component: Variables }]
    }
    if (isParamsAllowed) {
        sections = [...sections, { name: 'Parametros', component: Params }]
    }
    if (isResourcesAllowed) {
        sections = [...sections, { name: 'Recursos', component: Resources }]
    }
    if (isDevicesAllowed) {
        sections = [...sections, { name: 'Dispositivos', component: Devices }]
    }
    if (isSensorsAllowed) {
        sections = [...sections, { name: 'Sensores', component: Sensors }]
    }
    if (isHMIAllowed) {
        sections = [...sections, { name: 'HMI', component: HMI }]
    }
    if (isPalletizerAllowed) {
        sections = [...sections, { name: 'Palletizer', component: Palletizer }]
    }

    sections = [...sections, { name: 'Ajustes', component: Settings }]

    const getSection = (i) => {
        const s = sections[i]
        if (!s) return null
        if (!s.component) return null
        const Section = s.component
        return <Section group={group} />
    }

    return (
        <React.Fragment>
            <Paper elevation={2} style={{ paddingLeft: 20 }}>
                <Header />
                <Tabs value={section}>
                    {sections.map((s, i) => (
                        <Tab key={s.name} label={s.name} onClick={() => setSection(i)} />
                    ))}
                </Tabs>
            </Paper>
            <Container style={{ paddingTop: 50 }}>{getSection(section)}</Container>
        </React.Fragment>
    )
}

export default GroupView
