/**
 * Perform Non Maximum Suppression to filter overlapping boxes
 * @param {Array[Object]} boxes Boxes
 * @param {Number} overlapThresh Overlapping / IoU threshold
 * @returns {Array[Object]} Boxes
 */
/* eslint-disable import/prefer-default-export */
export const NMS = (boxes, overlapThresh) => {
    if (boxes.length === 0) {
        return []
    }

    const pick = []
    let boxesProcessed = boxes
    boxesProcessed.sort((b1, b2) => {
        return b1.confidence - b2.confidence
    })

    while (boxesProcessed.length > 0) {
        const last = boxesProcessed[boxesProcessed.length - 1]
        pick.push(last)
        const suppress = [last]

        for (let i = 0; i < boxesProcessed.length - 1; i += 1) {
            const box = boxesProcessed[i]
            const xx1 = Math.max(box.bounding[0], last.bounding[0])
            const yy1 = Math.max(box.bounding[1], last.bounding[1])
            const xx2 = Math.min(
                box.bounding[0] + box.bounding[2],
                last.bounding[0] + last.bounding[2]
            )
            const yy2 = Math.min(
                box.bounding[1] + box.bounding[3],
                last.bounding[1] + last.bounding[3]
            )
            const w = Math.max(0, xx2 - xx1 + 1)
            const h = Math.max(0, yy2 - yy1 + 1)
            const overlap = (w * h) / ((box.bounding[2] + 1) * (box.bounding[3] + 1))

            if (overlap > overlapThresh) {
                suppress.push(boxesProcessed[i])
            }
        }

        boxesProcessed = boxesProcessed.filter((box) => {
            return !suppress.find((supp) => {
                return supp === box
            })
        })
    }
    return pick
}
