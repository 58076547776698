import gql from 'graphql-tag'

export const CREATE_MODEL = gql`
    mutation CreateModel($params: CreateModelRequest) {
        createModel(params: $params) {
            model {
                name
                state
            }
        }
    }
`

export const GET_MODELS = gql`
    query {
        getModels {
            models {
                _id
                createdAt
                name
                state
            }
        }
    }
`

export const GET_MODEL = gql`
    query GetModelById($params: GetModelByIdRequest!) {
        getModel(params: $params) {
            _id
            createdAt
            updatedAt
            name
            type
            framework
            epochs
            state
            shape
            artifacts
            classes
            trainingSet
            numTrainingSamples
        }
    }
`

export const GET_DELIVERABLES_BY_MODEL = gql`
    query GetDeliverablesByModel($params: GetDeliverablesByModelRequest!) {
        getDeliverablesByModel(params: $params) {
            status
            deliverables {
                _id
                model
                device
                url
            }
        }
    }
`

export const CREATE_DELIVERABLE = gql`
    mutation CreateDeliverable($params: CreateDeliverableRequest!) {
        createDeliverable(params: $params) {
            status
        }
    }
`
