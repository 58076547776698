import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@material-ui/icons/Add'

import { CREATE_TRAINING_SET_ENTRIES, GET_TRAINING_SETS } from '../../../../queries/training-sets'

const CreateTrainingSetEntriesDialog = ({ fields }) => {
    const [open, setOpen] = useState(false)

    /* eslint-disable no-unused-vars */
    const [_, setLoading] = useState(false)
    const [trainingSet, setTrainingSet] = useState(null)
    const [column, setColumn] = useState(null)

    const { selectedEntries = [] } = useSelector((state) => state.datasets.viewer)

    const { data: { getTrainingSets: { trainingSets = [] } = {} } = {} } = useQuery(
        GET_TRAINING_SETS,
        { variables: { params: { parent: null } } }
    )
    const [createEntries] = useMutation(CREATE_TRAINING_SET_ENTRIES)

    const onCancel = () => {
        setOpen(false)
    }

    const onIconButtonClicked = () => {
        setOpen(true)
    }

    const onAccept = async () => {
        setLoading(true)

        const entries = selectedEntries.map((e) => {
            const byId = e[column].reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

            const url = byId['url']

            return {
                inputs: [
                    {
                        name: 'url',
                        value: url,
                    },
                ],
            }
        })

        await createEntries({
            variables: {
                params: {
                    trainingSet,
                    entries,
                },
            },
        })

        setLoading(false)
        setOpen(false)
    }

    const onColumnChange = (e) => {
        setColumn(e.target.value)
    }

    const onTrainingSetChange = (e) => {
        setTrainingSet(e.target.value)
    }

    return (
        <React.Fragment>
            <Tooltip title="Añadir entrada a training set">
                <IconButton onClick={onIconButtonClicked} disabled={!selectedEntries.length}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open}>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Columna</InputLabel>
                        <Select value={column} onChange={onColumnChange}>
                            {fields.map((f, i) => (
                                <MenuItem key={f.name} value={f.name}>
                                    {f.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            Seleccione la columna que contiene la imagen que desea añadir
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Training Set</InputLabel>
                        <Select value={trainingSet} onChange={onTrainingSetChange}>
                            {trainingSets.map((t, i) => (
                                <MenuItem key={t._id} value={t._id}>
                                    {t.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Seleccione un set de entrenamiento</FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

CreateTrainingSetEntriesDialog.propTypes = {
    fields: PropTypes.array.isRequired,
}

export default CreateTrainingSetEntriesDialog
