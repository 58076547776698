import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { LinkDefault } from '@mrblenny/react-flow-chart'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { removeLink } from '../../redux/nodes'

const Link = (props) => {
    const [hover, setHover] = useState(false)
    const dispatch = useDispatch()
    const {
        startPos,
        endPos,
        link: { id: linkId },
    } = props

    const onDeleteClicked = () => {
        dispatch(removeLink(linkId))
    }

    const margin = 10
    const centerX = startPos.x + (endPos.x - startPos.x) / 2 - margin
    const centerY = startPos.y + (endPos.y - startPos.y) / 2 - margin

    const onLinkMouseEnter = () => {
        setHover(true)
    }
    const onLinkMouseLeave = () => {
        setHover(false)
    }

    return (
        <div onMouseEnter={onLinkMouseEnter} onMouseLeave={onLinkMouseLeave}>
            <LinkDefault {...props} />
            <IconButton
                onClick={onDeleteClicked}
                color="primary"
                size="small"
                style={{
                    display: hover ? 'block' : 'none',
                    position: 'fixed',
                    left: centerX,
                    top: centerY,
                    background: '#FF000099',
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </div>
    )
}

Link.propTypes = LinkDefault.propTypes
Link.defaultProp = LinkDefault.defaultProp

export default Link
