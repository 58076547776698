import React from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

import { setNodeProperties } from '../../../../redux/nodes'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const onURIChange = (event) => {
        dispatch(
            setNodeProperties({
                nodeId: node.id,
                properties: {
                    type: 'uri',
                    value: event.target.value || '',
                },
            })
        )
    }

    const uri = node.properties['uri'] || ''

    return (
        <div className={classes.root}>
            <FormControl fullWidth>
                <TextField onChange={onURIChange} label="URI" value={uri} />
            </FormControl>
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
}

export default SideBar
