import React, { useRef, Children, useState } from 'react'
import { PropTypes } from 'prop-types'

/**
 * Invisible component to convert an element in a draggable component with a div
 *
 * Inserts props to its childrens:
 *      * isDragging: True if the element is dragging
 *      * onMouseDown
 *      * onMouseMove
 *      * onMouseUp
 *      * onMouseLeave
 */
const DraggableObject = ({ transform, moveEffect, children }) => {
    const [dragging, setDragging] = useState(false)
    const originMouse = useRef(false)

    const onMouseDown = (event) => {
        event.stopPropagation()
        setDragging(true)
        const transformedPoint = transform.applyInverseToPoint({
            x: event.clientX,
            y: event.clientY,
        })
        originMouse.current = [transformedPoint.x, transformedPoint.y]
    }

    const onMouseMove = (event) => {
        event.stopPropagation()
        if (dragging) {
            const transformedPoint = transform.applyInverseToPoint({
                x: event.clientX,
                y: event.clientY,
            })
            const movX = transformedPoint.x - originMouse.current[0]
            const movY = transformedPoint.y - originMouse.current[1]
            moveEffect(movX, movY)
            originMouse.current = [transformedPoint.x, transformedPoint.y]
        }
    }

    const onMouseUp = (event) => {
        event.stopPropagation()
        setDragging(false)
    }

    const onMouseLeave = (event) => {
        event.stopPropagation()
        setDragging(false)
    }

    return (
        <div>
            {Children.map(children, (obj) =>
                React.cloneElement(
                    obj,
                    {
                        ...obj.props,
                        isDragging: dragging,
                        onMouseDown,
                        onMouseMove,
                        onMouseUp,
                        onMouseLeave,
                    },
                    obj.children
                )
            )}
        </div>
    )
}

DraggableObject.propTypes = {
    /** Transform to change the coordinated between reference systems. vx/zoom transform matrix */
    transform: PropTypes.func,
    /** Function to pass the mov in x and mov in y */
    moveEffect: PropTypes.func.isRequired,
    /** React children */
    children: (PropTypes.object || PropTypes.array).isRequired,
}
DraggableObject.defaultProps = {
    transform: (x, y) => {
        return [x, y]
    },
}
DraggableObject.returnTypes = {
    /** Is true when dragging */
    isDragging: PropTypes.bool,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseLeave: PropTypes.func,
}

export default DraggableObject
