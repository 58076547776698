import React from 'react'

import NewDeliverableDialog from './containers/new-deliverable-dialog/scene'
import DeliverablesList from './containers/deliverables-list/scene'

const ModelsList = () => {
    return (
        <React.Fragment>
            <NewDeliverableDialog />
            <DeliverablesList />
        </React.Fragment>
    )
}

export default ModelsList
