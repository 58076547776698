import React from 'react'
import { PropTypes } from 'prop-types'
import Box from '@material-ui/core/Box'
import { useSelector, useDispatch } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'
import Paper from '@mui/material/Paper'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Stack from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import { ThemeProvider } from '@mui/material/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import ListItem from '@material-ui/core/ListItem'
import _ from 'lodash'

import {
    setAnnotationIndex,
    setAnnotationClass,
    appendInPlaceAnnotation,
    setEditMode,
} from '../../redux/images'
import { setToolbox } from '../../redux/general'
import theme from '../../../../../../../../../../../../theme'

/**
 * Component responsible of render each annotation inside
 * the column on the left
 */
const AnnotationButton = ({ annIndex }) => {
    // Selected annotations index
    let selectedAnnotation = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedAnnotation
    )
    selectedAnnotation = selectedAnnotation === null ? '' : selectedAnnotation

    // In place annotation linked with this button
    const selectedEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedEntry
    )
    const inPlaceAnnotation = useSelector(
        (state) =>
            _.get(state.trainingSetObjectDetection.images.inPlaceAnnotations, selectedEntry, [])[
                annIndex
            ] || {}
    )
    const annotationColor = useSelector(
        (state) =>
            state.trainingSetObjectDetection.general.classProps[
                _.get(inPlaceAnnotation, 'class', 0)
            ].color
    )

    // Redux dispatches
    const dispatch = useDispatch()
    const selectIndex = (newIndex) => dispatch(setAnnotationIndex(newIndex))

    // Events
    // // Event triggered when the accordion is expanded
    const selectAnnotationClick = () => {
        if (annIndex === selectedAnnotation) {
            selectIndex(null)
            dispatch(setToolbox('samples'))
        } else {
            // Activate annotations toolbox
            selectIndex(annIndex)
            dispatch(setToolbox('annotations'))
        }
    }

    // // Event triggered when class is changed
    const setClass = (event) => {
        dispatch(setAnnotationClass(parseInt(event.target.value, 10)))
    }

    // Getting classes
    const trainingSetClassesSelector = useSelector(
        (state) => state.trainingSetObjectDetection.general.classProps
    )
    const trainingSetClasses = Object.keys(trainingSetClassesSelector).reduce((prev, next) => {
        return { ...prev, [next]: trainingSetClassesSelector[next].text }
    }, {})

    return (
        <Accordion onChange={selectAnnotationClick} expanded={annIndex === selectedAnnotation}>
            <AccordionSummary
                expandIcon={
                    <ExpandCircleDownIcon style={{ color: annotationColor, fontSize: 27 }} />
                }
                aria-controls={`panel${annIndex}a-content`}
                id={`panel${annIndex}a-header`}
            >
                <Paper elevation={0}>
                    {trainingSetClasses[inPlaceAnnotation.class] || `Annotation ${annIndex}`}
                </Paper>
            </AccordionSummary>
            <AccordionDetails>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Clase</InputLabel>
                        <Select onChange={setClass} value={inPlaceAnnotation.class || 0}>
                            {Object.keys(trainingSetClasses).map((idx) => (
                                <MenuItem key={idx} value={idx}>
                                    {trainingSetClasses[idx]}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Seleccione un tipo de código</FormHelperText>
                    </FormControl>
                </ListItem>
            </AccordionDetails>
        </Accordion>
    )
}

AnnotationButton.propTypes = {
    /** Annotation index */
    annIndex: PropTypes.number.isRequired,
}

/**
 * Component responsible of render all the annotations inside
 * the column on the left
 */
const Annotations = () => {
    // Selectors
    const selectedEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedEntry
    )
    const inPlaceAnnotations = useSelector((state) =>
        _.get(state.trainingSetObjectDetection.images.inPlaceAnnotations, selectedEntry, [])
    )

    // Redux dispatch
    const dispatch = useDispatch()
    const activeAnnotationIndex = (annIndex) => dispatch(setAnnotationIndex(annIndex))

    // Events
    const addAnnotation = () => {
        if (selectedEntry != null) {
            dispatch(
                appendInPlaceAnnotation({
                    class: 0,
                    coords: [null, null, null, null],
                })
            )
            dispatch(activeAnnotationIndex(inPlaceAnnotations.length))
            dispatch(setEditMode(true))
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2}>
                    {inPlaceAnnotations.map((ann, annIndex) => {
                        return (
                            <AnnotationButton
                                key={`${selectedEntry}_${annIndex}`}
                                annIndex={annIndex}
                            />
                        )
                    })}
                    <Button color="primary" variant="contained" onClick={addAnnotation}>
                        <AddIcon />
                    </Button>
                </Stack>
            </Box>
        </ThemeProvider>
    )
}

export default Annotations
