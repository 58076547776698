import React, { useState } from 'react'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const Display = () => {
    const [open, setOpen] = useState(false)

    const onItemClicked = () => setOpen(true)

    const onCancel = () => {
        setOpen(false)
    }

    const onAccept = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <ListItem button onClick={onItemClicked}>
                <ListItemText
                    primary="Display"
                    secondary="Asigne los usuarios con acceso a cada sección"
                />
            </ListItem>
            <Dialog open={open}>
                <DialogTitle>Display</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>
                            <TextField label="Anchura" type="number" step="1" fullWidth />
                        </ListItem>
                        <ListItem>
                            <TextField label="Altura" type="number" step="1" fullWidth />
                        </ListItem>
                        <ListItem>
                            <Select fullWidth>
                                <MenuItem>Horizontal</MenuItem>
                                <MenuItem>Vertical</MenuItem>
                            </Select>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Display
