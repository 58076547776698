import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ThemeProvider } from '@mui/material/styles'

import { editParameter } from '../../reducers/settings'
import { GET_PARAMS_BY_GROUP, CREATE_PARAM, UPDATE_PARAM } from '../../../../params/queries/params'

const Settings = () => {
    const { group } = useParams()
    const theme = useTheme()

    const [values, setValues] = useState({})
    const [editable, setEditable] = useState({})
    const settings = useSelector((state) => state.group.palletizer.settings)

    const dispatch = useDispatch()

    const { data: { getParamsByGroup: { params: parametersGQL = [] } = {} } = {} } = useQuery(
        GET_PARAMS_BY_GROUP,
        { variables: { params: { group } }, pollInterval: 10000 }
    )
    const [updateParameterGQL] = useMutation(UPDATE_PARAM)
    const [createParam] = useMutation(CREATE_PARAM)

    const onSaveClick = async () => {
        // Check if parameter exists
        const settingsParameter = parametersGQL.filter((param) => param.name === settings.settings)

        if (settingsParameter.length === 0) {
            await createParam({
                variables: {
                    params: {
                        group,
                        name: settings.settings,
                        type: 'STRING',
                        value: JSON.stringify(settings),
                    },
                },
            })
        } else {
            await updateParameterGQL({
                variables: {
                    params: {
                        _id: settingsParameter[0]._id,
                        value: JSON.stringify(settings),
                    },
                },
            })
        }
    }

    useEffect(() => {
        const settingsParameter = parametersGQL.filter((param) => param.name === settings.settings)

        if (settingsParameter.length > 0) {
            const settingsFromParameter = JSON.parse(settingsParameter[0].local)
            Object.keys(settingsFromParameter).forEach((set) => {
                dispatch(editParameter(set, settingsFromParameter[set]))
            })
        }
        setEditable(
            Object.keys(settings).reduce((prev, next) => {
                return { ...prev, [next]: false }
            }, {})
        )

        setValues(
            Object.keys(settings).reduce((prev, next) => {
                return { ...prev, [next]: settings[next] }
            }, {})
        )
    }, [parametersGQL])

    return (
        <ThemeProvider theme={theme}>
            <List>
                {Object.keys(settings).map((set) => {
                    return (
                        <ListItem key={set} disablePadding>
                            {!editable[set] && (
                                <ListItemText primary={`${set}: ${settings[set]}`} />
                            )}
                            {editable[set] && (
                                <React.Fragment>
                                    <ListItemText primary={`${set}: `} />
                                    <TextField
                                        value={values[set]}
                                        onChange={(event) => {
                                            setValues({
                                                ...values,
                                                [set]: event.target.value,
                                            })
                                        }}
                                        fullWidth
                                    />
                                </React.Fragment>
                            )}
                            <ListItemSecondaryAction>
                                <Tooltip
                                    title={
                                        <Typography fontSize={14}>
                                            {!editable[set] ? 'Editar' : 'Cancelar edición'}
                                        </Typography>
                                    }
                                    aria-label="add"
                                >
                                    <IconButton>
                                        {!editable[set] && (
                                            <EditIcon
                                                style={{ marginTop: 5 }}
                                                onClick={() => {
                                                    setEditable({ ...editable, [set]: true })
                                                }}
                                            />
                                        )}
                                        {editable[set] && (
                                            <CloseIcon
                                                style={{ marginTop: 5 }}
                                                onClick={() => {
                                                    setEditable({ ...editable, [set]: false })
                                                    dispatch(editParameter(set, values[set]))
                                                }}
                                                name={set}
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )
                })}
            </List>
            <Divider style={{ marginTop: 7, marginBottom: 7 }} />
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={5} />
                <Grid item xs={2}>
                    <Button variant="contained" onClick={onSaveClick} color="primary">
                        Guardar
                    </Button>
                </Grid>
                <Grid item xs={5} />
            </Grid>
        </ThemeProvider>
    )
}

export default Settings
