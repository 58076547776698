export const OPEN_NEW_PAYMENT_METHOD_DIALOG = "NEW_PAYMENT_METHOD_DIALOG/OPEN"
export const CLOSE_NEW_PAYMENT_MEHTOD_DIALOG = "NEW_PAYMENT_METHOD_DIALOG/CLOSE"

export const NEXT_STEP_NEW_PAYMENT_METHOD_DIALOG = "NEW_PAYMENT_METHOD_DIALOG/NEXT_STEP"
export const BACK_STEP_NEW_PAYMENT_METHOD_DIALOG = "NEW_PAYMENT_METHOD_DIALOG/BACK_STEP"

export const SET_PAYMENT_METHOD_TYPE_NEW_PAYMENT_METHOD_DIALOG = "NEW_PAYMENT_METHOD_DIALOG/SET_PAYMENT_METHOD_TYPE"

export const openNewPaymentMethodDialog = () => ({ type: OPEN_NEW_PAYMENT_METHOD_DIALOG })
export const closeNewPaymentMethodDialog = () => ({ type: CLOSE_NEW_PAYMENT_MEHTOD_DIALOG })

export const nextStepPaymentMethodDialog = () => ({ type: NEXT_STEP_NEW_PAYMENT_METHOD_DIALOG })
export const backStepPaymentMethodDialog = () => ({ type: BACK_STEP_NEW_PAYMENT_METHOD_DIALOG })

export const setPaymentMethodtypePaymentMethodDialog = () => ({ type: SET_PAYMENT_METHOD_TYPE_NEW_PAYMENT_METHOD_DIALOG })