import { combineReducers } from 'redux'

import blueprints from '../containers/blueprints/reducers'
import devices from '../containers/devices/reducers'
import palletizer from '../containers/apps/palletizer/reducers/main'

export default combineReducers({
    blueprints,
    devices,
    palletizer,
})
