const SET_SELECTED_STACK = 'SET_SELECTED_STACK'
const SET_SELECTED_LAYOUT_STACK = 'SET_SELECTED_LAYOUT_STACK'
const SET_ACTIVE_LAYOUT = 'SET_ACTIVE_LAYOUT'
const UPDATE_STACK = 'UPDATE_STACK'
const DELETE_STACK = 'DELETE_STACK'
const ADD_LAYOUT_TO_CURRENT_STACK = 'ADD_LAYOUT_TO_CURRENT_STACK'
// const ADD_LAYOUT_TO_CURRENT_STACK = 'ADD_LAYOUT_TO_CURRENT_STACK'
const DELETE_LAYOUT_FROM_CURRENT_STACK = 'DELETE_LAYOUT_FROM_CURRENT_STACK'
const UPDATE_LAYOUT_FROM_CURRENT_STACK = 'UPDATE_LAYOUT_FROM_CURRENT_STACK'
const RELOAD_STACK = 'RELOAD_STACK'
const SAVING = 'SAVING'
const SAVE_GLOBAL_STACK = 'SAVE_GLOBAL_STACK'

export const setSelectedStack = (index) => ({
    type: SET_SELECTED_STACK,
    index,
})

export const updateStack = (index, data) => ({
    type: UPDATE_STACK,
    index,
    data,
})

export const deleteStack = (stackId) => ({
    type: DELETE_STACK,
    stackId,
})

export const setSelectedLayoutStack = (index) => ({
    type: SET_SELECTED_LAYOUT_STACK,
    index,
})

export const addLayoutToCurrentStack = (layout) => ({
    type: ADD_LAYOUT_TO_CURRENT_STACK,
    layout,
})

export const setActiveLayoutStack = (layoutIndex) => ({
    type: SET_ACTIVE_LAYOUT,
    layoutIndex,
})

export const deleteLayoutFromCurrentStack = (layoutIndex) => ({
    type: DELETE_LAYOUT_FROM_CURRENT_STACK,
    layoutIndex,
})

export const updateLayoutFromCurrentStack = (layoutIndex, layoutData) => ({
    type: UPDATE_LAYOUT_FROM_CURRENT_STACK,
    layoutIndex,
    layoutData,
})

export const reload = () => ({
    type: RELOAD_STACK,
})

export const saving = (isSaving) => ({
    type: SAVING,
    value: isSaving,
})

export const saveGlobalStack = (stack) => ({
    type: SAVE_GLOBAL_STACK,
    value: stack,
})

// export const setActiveBox = (boxId) => ({
//     type: SET_ACTIVE_BOX,
//     boxId,
// })

export const initialState = {
    stacks: {},
    selectedStack: null,
    selectedLayout: null,
    activeLayout: null,
    saving: false,
    globalStack: '',
}

/* eslint-disable default-param-last */
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_STACK:
            return { ...state, selectedStack: action.index }
        case SET_ACTIVE_LAYOUT:
            return { ...state, activeLayout: action.layoutIndex }
        case UPDATE_STACK:
            return {
                ...state,
                stacks: {
                    ...state.stacks,
                    [action.index]: {
                        unsaved: true,
                        ...state.stacks[action.index],
                        ...action.data,
                    },
                },
            }
        case DELETE_STACK:
            return {
                ...state,
                selectedStack: null,
                stacks: Object.keys(state.stacks)
                    .filter((stack) => stack !== action.stackId)
                    .reduce((prev, next) => {
                        return { ...prev, [next]: state.stacks[next] }
                    }, {}),
            }
        case SET_SELECTED_LAYOUT_STACK:
            return {
                ...state,
                selectedLayout: action.index,
            }
        case ADD_LAYOUT_TO_CURRENT_STACK:
            return {
                ...state,
                stacks: {
                    ...state.stacks,
                    [state.selectedStack]: {
                        ...state.stacks[state.selectedStack],
                        layouts: [...state.stacks[state.selectedStack].layouts, action.layout],
                    },
                },
            }

        case DELETE_LAYOUT_FROM_CURRENT_STACK:
            return {
                ...state,
                stacks: {
                    ...state.stacks,
                    [state.selectedStack]: {
                        ...state.stacks[state.selectedStack],
                        layouts: [
                            ...state.stacks[state.selectedStack].layouts.slice(
                                0,
                                action.layoutIndex
                            ),
                            ...state.stacks[state.selectedStack].layouts.slice(
                                action.layoutIndex + 1
                            ),
                        ],
                    },
                },
            }
        case UPDATE_LAYOUT_FROM_CURRENT_STACK:
            return {
                ...state,
                stacks: {
                    ...state.stacks,
                    [state.selectedStack]: {
                        ...state.stacks[state.selectedStack],
                        layouts: [
                            ...state.stacks[state.selectedStack].layouts.slice(
                                0,
                                action.layoutIndex
                            ),
                            {
                                ...state.stacks[state.selectedStack].layouts[action.layoutIndex],
                                ...action.layoutData,
                            },
                            ...state.stacks[state.selectedStack].layouts.slice(
                                action.layoutIndex + 1
                            ),
                        ],
                    },
                },
            }
        case RELOAD_STACK:
            return {
                stacks: {},
                selectedStack: null,
                selectedLayout: null,
                activeLayout: null,
                globalStack: '',
            }
        case SAVING:
            return { ...state, saving: action.value }
        case SAVE_GLOBAL_STACK:
            return { ...state, globalStack: action.value }
        default:
            return state
    }
}
