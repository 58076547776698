import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { useParams, Link, useRouteMatch } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Fab from '@material-ui/core/Fab'
import NavigationIcon from '@material-ui/icons/Navigation'
import Divider from '@material-ui/core/Divider'

import { GET_TRAINING_SET_BY_ID } from '../../queries/training-sets'

import Info from './containers/info/scene'
import Settings from './containers/settings/scene'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(5),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        padding: theme.spacing(3),
    },
    tabs: {
        marginTop: theme.spacing(3),
    },
    paper: {
        marginTop: theme.spacing(3),
    },
}))

const types = {
    'object-detection': 'object-detection',
    'rgb-images': 'object-detection',
    classification: 'classification',
    'multi-label-classification': 'multi-label-classification',
    ocr: 'ocr',
    segmentation: 'segmentation',
}

const Details = () => {
    const classes = useStyles()
    const match = useRouteMatch()

    const { trainingSet: _id = '' } = useParams()

    const [tab, setTab] = useState(0)

    const { loading, data: { getTrainingSetById: { trainingSet = {} } = {} } = {} } = useQuery(
        GET_TRAINING_SET_BY_ID,
        { variables: { params: { _id } } }
    )

    const onTabChange = (event, newValue) => {
        setTab(newValue)
    }

    const panels = [<Info key={1} />, <Settings key={2} />]

    return (
        <Container fixed className={classes.root}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h2">{trainingSet.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Tabs value={tab} onChange={onTabChange} className={classes.tabs}>
                        <Tab label="Información" />
                        <Tab label="Ajustes" />
                    </Tabs>
                    <Divider fullWidth />
                </Grid>
                <Grid item xs={12}>
                    {panels[tab]}
                </Grid>
            </Grid>
            <Link
                to={`${match.url}/${types[trainingSet.labelType]}`}
                style={{ textDecoration: 'none' }}
            >
                <Fab variant="extended" className={classes.fab} color="primary" disabled={loading}>
                    <NavigationIcon className={classes.extendedIcon} />
                    Empezar
                </Fab>
            </Link>
        </Container>
    )
}

export default Details
