import React from "react"

import { useSelector, useDispatch } from "react-redux"
import { useQuery } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"

import styled from 'styled-components'

import Paper from "@material-ui/core/Paper"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { setClassIndex } from "../../../../reducers/index";
import { GET_TRAINING_SET_BY_ID } from "../../../../../../queries/training-sets"

const colors = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#00FFFF",
    "#FF00FF",
    "#EE8572",
    "#35495E",
    "#347474",
    "#63B7AF"
]

const RadioContainer = styled(FormControlLabel)`
    .MuiFormControlLabel-label {
        margin-right: auto;
    }
`

const Classes = (props) => {

    const dispatch = useDispatch()

    const { trainingSet: trainingSetId } = useParams()

    const { classIndex } = useSelector(state => state.horus.trainingSets.polygon)

    const { data: { horus: { trainingSets: { getTrainingSetById: { trainingSet = { classes: [] } } = {}} = {}} = {}} = {}} = useQuery(GET_TRAINING_SET_BY_ID, { variables: { params: { trainingSet: trainingSetId, labelType: "polygon", dataType: "rgb" }}})

    const onClassClicked = i => () => {
        dispatch(setClassIndex(i))
    }

    const classes = trainingSet.classes

    return (
        <React.Fragment>
            <FormLabel
                style={{ padding: "20px 25px 0px 25px" }}    
            >
                Classes
            </FormLabel>
            <List component="nav"
                style={{
                   maxHeight: "35vh",
                   overflow: "auto"
                }}
            >
                <RadioGroup value={classIndex} style={{ overflowX: "hidden" }}>
                    {classes.map((clase, i) => (
                        <ListItem style={{ padding: 0 }} key={clase + i} onClick={onClassClicked(i)}>
                            <RadioContainer
                                value={i}
                                control={<Radio style={{ color: colors[i], marginRight: 12 }} />}
                                label={clase}
                                onClick={onClassClicked(i)}
                                labelPlacement="start" 
                                style={{ width: "100%" }}
                            />
                        </ListItem>
                    ))}
                </RadioGroup>
            </List>
        </React.Fragment>
    )
}

export default Classes