export const OPEN_NEW_DEVICES_DIALOG = "NEW_DEVICES_DIALOG/OPEN"
export const CLOSE_NEW_DEVICES_DIALOG = "NEW_DEVICES_DIALOG/CLOSE"

export const openNewDevicesDialog = () => ({ type: OPEN_NEW_DEVICES_DIALOG })
export const closeNewDevicesDialog = () => ({ type: CLOSE_NEW_DEVICES_DIALOG })

export default (state = { open: false }, action) => {
    switch(action.type) {
        case OPEN_NEW_DEVICES_DIALOG:
            return {...state, open: true}
        case CLOSE_NEW_DEVICES_DIALOG:
            return {...state, open: false}
        default:
            return state
    }
}