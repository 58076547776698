import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'

import TableWithSearch from 'components/TableWithSearch'
import { useJobsDetails, useJobsRunGetDetailed } from 'queries/jobs'
import GrowableSideBar from '../../../../../../../../../../utils/common-components/growableSideBar'

const JobsDetails = () => {
    const { workflowId, workflowRunId } = useParams()
    // const dispatch = useDispatch()

    const [columns, setColumns] = useState([
        {
            title: 'Nombre',
            field: 'nodeId',
            sortDirection: 'asc',
        },
        {
            title: 'Estado',
            field: 'state',
            sortDirection: 'asc',
        },
        {
            title: 'Fecha de inicio',
            field: 'startedAt',
            sortDirection: 'asc',
        },
        {
            title: 'Fecha de finalización',
            field: 'finishedAt',
            sortDirection: 'asc',
        },
    ])
    const [data, setData] = useState([])
    const [nodes, setNodes] = useState({})
    const [selectedNode, setSelectedNode] = useState(undefined)
    const { jobsList = [{ engine: '', inputPorts: [], outputPort: [] }], loading } = useJobsDetails(
        { workflowId }
    )
    const { jobsRun = { nodes: [] } } = useJobsRunGetDetailed(jobsList[0].engine, workflowRunId)

    useEffect(() => {
        if (jobsRun.nodes.length) {
            const newData = jobsRun.nodes.map((node) => ({
                ...node,
                startedAt: node.startedAt ? new Date(node.startedAt).toLocaleString() : '',
                finishedAt: node.finishedAt ? new Date(node.finishedAt).toLocaleString() : '',
            }))

            setData(newData)
            setNodes(
                jobsRun.nodes.reduce((prev, next) => {
                    return { ...prev, [next.id]: next }
                }, {})
            )
        }
    }, [jobsRun.nodes])
    console.log(jobsRun.nodes)
    return (
        <React.Fragment>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Paper style={{ width: '100%', height: '100%' }}>
                    <Typography style={{ padding: '15px 20px' }} variant="h2">
                        Ejecuciones
                    </Typography>
                    <Divider />
                    <TableWithSearch
                        height="calc(100vh - 198px)"
                        columns={columns}
                        data={data}
                        fullScreen={false}
                        loading={loading}
                        onRowClick={(row) => {
                            setSelectedNode(row.id)
                        }}
                        button={true}
                        onDirectionChange={(column, direction) => {
                            const newColumns = columns.map((col) => {
                                if (col.field === column) {
                                    return {
                                        ...col,
                                        sortDirection: direction,
                                    }
                                }
                                return col
                            })
                            setColumns(newColumns)
                        }}
                    />
                </Paper>
            </Container>
            {selectedNode && (
                <GrowableSideBar
                    paperProps={{
                        style: {
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            scrollbarWidth: 0,
                            height: '100%',
                            width: 400,
                        },
                    }}
                    anchor="right"
                    size={300}
                >
                    <Box component="div" sx={{ whiteSpace: 'pre' }}>
                        {nodes[selectedNode].logs}
                    </Box>
                </GrowableSideBar>
            )}
        </React.Fragment>
    )
}

export default JobsDetails
