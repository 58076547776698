import React, { useState } from "react"

import { useQuery } from "@apollo/react-hooks"
import { useDispatch, useSelector } from "react-redux"

import Divider from "@mui/material/Divider"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import ListItemText from '@material-ui/core/ListItemText';

import { GET_DATASET_V2_BY_ID, GET_DATASETS_V2_BY_PARENT, QUERY_DATASETS_V2_ENTRIES_BY_DATASET } from "../../../../queries/datasets"

import { addDimension, addMetric, removeDimension, removeMetric, selectItem } from "../../../../reducer"

const Data = () => {

    const { data: { getDatasetsV2ByParent: { datasets = []} = {}} = {}} = useQuery(GET_DATASETS_V2_BY_PARENT, { variables: { params: {}}})
    const { data: { getDatasetV2ById: { dataset: { fields = []} = {}} = {}} = {}} = useQuery(GET_DATASET_V2_BY_ID, { variables: { params: { id: selectedDataset }}, skip: !selectedDataset })

    const [ title, setTitle ] = useState("")
    const [ widgetHeight, setWidgetHeight ] = useState(0)
    const [ widgetWidth, setWidgetWidth ] = useState(0)
    const [ selectedDataset, setSelectedDataset ] = useState("")
    const [ xField, setXField ] = useState("")
    const [ yField, setYField ] = useState("")
    const [ aggregation, setAggregation ] = useState("none")

    const [ operators, setOperators ] = useState([])
    
    const onTitleChanged = e => setTitle(e.target.value)
    const onSelectedDatasetChanged = e => setSelectedDataset(e.target.value)

    const onXFieldChange = e => setXField(e.target.value)
    const onYFieldChange = e => setYField(e.target.value)
    const onAggregationChange = e => setAggregation(e.target.value)

    const onAddAggregationClick = () => {
        setOperators([
            ...operators,
            {
                name: "y",
                field: yField,
                mode: "aggregate",
                type: aggregation,
                properties: []
            }
        ])
    }

    const dispatch = useDispatch()

    const { current_dataset, dimensions, metrics } = useSelector(state => state.dashboards.bar_chart)

    const onAddDimensionClick = () => dispatch(addDimension())
    const onRemoveDimensionClick = (index) => () => dispatch(removeDimension(index))

    const onAddMetricClick = () => dispatch(addMetric())
    const onRemoveMetricClick = (index) => () => dispatch(removeMetric(index))
    const onMetricClick = index => () => dispatch(selectItem("metric", index))
    const onDimensionClick = index => () => dispatch(selectItem("dimension", index))

    return (
        <List style={{ height: "100%", overflow: "auto" }}>
            <ListItem
                disabled={!current_dataset}
                secondaryAction={
                    <IconButton edge="end" onClick={onAddDimensionClick} disabled={!current_dataset || dimensions.length > 0}>
                        <AddIcon/>
                    </IconButton>
                }>
                <ListItemText primary="DIMENSIONES" ></ListItemText>
            </ListItem>
            <div style={{ minHeight: 100 }}>
                {
                    dimensions.map((d, i) => (
                        <ListItem
                            button
                            onClick={onDimensionClick(i)}
                            secondaryAction={
                                <IconButton edge="end" onClick={onRemoveDimensionClick(i)}>
                                    <HighlightOffIcon/>
                                </IconButton>
                        }>
                            <ListItemText primary={d.name || d.field || `Dimensión ${i}`}/>
                        </ListItem>
                    ))
                }
            </div>
            <Divider/>
            <ListItem 
                disabled={!current_dataset}
                secondaryAction={
                    <IconButton edge="end" onClick={onAddMetricClick} disabled={!current_dataset}>
                        <AddIcon/>
                    </IconButton>
                }>
                <ListItemText primary="MÉTRICAS" ></ListItemText>
            </ListItem>
            <div style={{ minHeight: 200 }}>
                {
                    metrics.map((d, i) => (
                        <ListItem
                            button
                            onClick={onMetricClick(i)}
                            secondaryAction={
                                <IconButton edge="end" onClick={onRemoveMetricClick(i)}>
                                    <HighlightOffIcon/>
                                </IconButton>
                        }>
                            <ListItemText primary={d.name || d.field || `Métrica ${i}`}/>
                        </ListItem>
                    ))
                }
            </div>
        </List>
    )
}

export default Data