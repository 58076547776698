import React from 'react'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import { TYPE_PARAMETER } from '../../../../utils/parametrize'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    // // Filter parameters by the filter built with `filter`
    const parametersFiltered = useSelector((state) =>
        Object.keys(state.designer.parameters).map((obj) => {
            return { ...state.designer.parameters[obj], id: obj }
        })
    )

    return (
        <Grid container columnSpacing={100} className={classes.root} alignItems="flex-end">
            <FormControl fullWidth>
                <InputLabel>Parámetro</InputLabel>
                <Select
                    onChange={onPropertyChangeBuilder('parameter', (event) => {
                        const param = parametersFiltered.find(
                            (item) => item.id === event.target.value
                        )

                        return JSON.stringify({
                            type: TYPE_PARAMETER,
                            id: event.target.value,
                            name: param.name,
                        })
                    })}
                    value={JSON.parse(getProperty('parameter', JSON.stringify({ id: '' }))).id}
                >
                    {parametersFiltered.map((p) => {
                        return (
                            <MenuItem key={p.id} value={p.id}>
                                {p.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={getProperty('output-key', null)}
                    onChange={onPropertyChangeBuilder('output-key')}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
