import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { DynamicModuleLoader } from 'redux-dynamic-modules'

import JobsList from './containers/jobs-list/index'
import JobsDetails from './containers/jobs-details/index'
import JobsRunDetails from './containers/jobs-run-details/index'
import jobsModule from './redux/module'

const Jobs = ({ match }) => {
    return (
        <DynamicModuleLoader modules={[jobsModule]}>
            <Switch>
                <Route exact path={`${match.url}/`} component={JobsList} />
                <Route exact path={`${match.url}/:workflowId`} component={JobsDetails} />
                <Route
                    exact
                    path={`${match.url}/:workflowId/runs/:workflowRunId`}
                    component={JobsRunDetails}
                />
            </Switch>
        </DynamicModuleLoader>
    )
}
Jobs.propTypes = { match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired }

export default Jobs

// import React from 'react'
// import PropTypes from 'prop-types'
// import { DynamicModuleLoader } from 'redux-dynamic-modules'
// import { Switch, Route } from 'react-router-dom'

// import jobsModule from './redux/module'
// import JobsList from './containers/jobs-list/index'

// const Jobs = ({ match }) => {
//     return (
//         <DynamicModuleLoader modules={[jobsModule]}>
//             <Switch>
//                 <Route exact path={`${match.url}/list`} component={JobsList} />
//             </Switch>
//         </DynamicModuleLoader>
//     )
// }

// Jobs.propTypes = { match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired }

// // <Route exact path={`${match.url}/details/:workflowId`} component={Group} />
// //                 <Route exact path={`${match.url}/edit/:workflowId`} component={Designer} />
// //                 <Route
// //                     exact
// //                     path={`${match.url}/details/:workflowId/run/:runId`}
// //                     component={Device}
// //                 />

// export default Jobs
