import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import TableWithSearch from 'components/TableWithSearch'
import { useJobsList } from 'queries/jobs'
import NewJobDialog from '../new-job-dialog/index'

const JobsList = () => {
    const [columns, setColumns] = useState([
        {
            title: 'Nombre',
            field: 'name',
            sortDirection: 'asc',
        },
        {
            title: 'Estado',
            field: 'state',
            sortDirection: 'asc',
        },
        {
            title: 'Última actualización',
            field: 'updatedAt',
            sortDirection: 'asc',
        },
        {
            title: 'Fecha de creación',
            field: 'createdAt',
            sortDirection: 'asc',
        },
        {
            title: 'ID',
            field: 'workflowId',
            sortDirection: 'asc',
        },
    ])
    const [data, setData] = useState([])

    const { jobsList = [], loading } = useJobsList({})

    const history = useHistory()

    useEffect(() => {
        if (jobsList.length) {
            const newData = jobsList.map((job) => ({
                ...job,
                id: job.workflowId,
                createdAt: new Date(job.createdAt).toLocaleString(),
                updatedAt: new Date(job.updatedAt).toLocaleString(),
                link: `./jobs/${job.workflowId}`,
            }))

            setData(newData)
        }
    }, [jobsList])

    return (
        <React.Fragment>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Paper style={{ width: '100%', height: '100%' }}>
                    <Typography style={{ padding: '15px 20px' }} variant="h2">
                        Trabajos
                    </Typography>
                    <Divider />
                    <TableWithSearch
                        height="calc(100vh - 198px)"
                        columns={columns}
                        data={data}
                        fullScreen={false}
                        loading={loading}
                        onRowClick={(row) => {
                            history.push(row)
                        }}
                        rowsAsLinks
                        onDirectionChange={(column, direction) => {
                            const newColumns = columns.map((col) => {
                                if (col.field === column) {
                                    return {
                                        ...col,
                                        sortDirection: direction,
                                    }
                                }
                                return col
                            })
                            setColumns(newColumns)
                        }}
                    />
                </Paper>
            </Container>
            <NewJobDialog />
        </React.Fragment>
    )
}

export default JobsList
