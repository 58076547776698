import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { OpenCvProvider, useOpenCv } from 'opencv-react'

import { detectImage } from '../../utils/detect'
import { getModel } from '../../utils/onnx'
import { appendInPlaceAnnotation, predict } from '../../redux/images'
import IconButtonStatus from '../../../../../../../../../../../../utils/common-components/iconButtonStatus'

const PredictComponent = ({ setLoading, setError, setNotReady }) => {
    // Load opencv
    const { loaded, cv } = useOpenCv()

    const dispatch = useDispatch()

    const isPredict = useSelector((state) => state.trainingSetObjectDetection.images.predict)

    const inPlaceEntry = useSelector(
        (state) =>
            state.trainingSetObjectDetection.images.entries[
                state.trainingSetObjectDetection.images.selectedEntry
            ]
    ) || { url: null }
    const autolabelModel = useSelector(
        (state) => state.trainingSetObjectDetection.settings.autolabelModel
    )
    const autolabelDeliverable = useSelector(
        (state) => state.trainingSetObjectDetection.settings.autolabelDeliverable
    )
    const autolabelConfidenceThreshold = useSelector(
        (state) => state.trainingSetObjectDetection.settings.autolabelConfidenceThreshold
    )
    const autolabelClassThreshold = useSelector(
        (state) => state.trainingSetObjectDetection.settings.autolabelClassThreshold
    )
    const autolabelNmsThreshold = useSelector(
        (state) => state.trainingSetObjectDetection.settings.autolabelNmsThreshold
    )

    useEffect(() => {
        // Check if inPlaceEntry has a url
        if (inPlaceEntry.url === null) {
            setNotReady(true)
            return
        }
        // Check if opencv is loading
        if (!loaded) {
            setNotReady(true)
            return
        }
        // Check if model is configured
        const model = getModel(autolabelDeliverable)
        if (model === null) {
            setNotReady(true)
            return
        }
        setNotReady(false)
    }, [autolabelDeliverable, inPlaceEntry, loaded])

    useEffect(() => {
        if (!isPredict) {
            return
        }
        setLoading(true)
        if (!loaded) {
            setError(true)
            return
        }
        const model = getModel(autolabelDeliverable)
        if (model === null) {
            setError(true)
            return
        }

        const image = new Image()
        image.onload = async () => {
            const result = await detectImage(
                image,
                model,
                autolabelConfidenceThreshold,
                autolabelClassThreshold,
                autolabelNmsThreshold,
                autolabelModel.shape,
                cv,
                autolabelModel.classes.length
            )
            if (result === null) {
                setError(true)
                return
            }
            const wRatio = image.naturalWidth / autolabelModel.shape[3]
            const hRatio = image.naturalHeight / autolabelModel.shape[2]
            result.forEach((obj) => {
                dispatch(
                    appendInPlaceAnnotation({
                        class: parseInt(obj.classId, 10),
                        coords: [
                            obj.bounding[0] * wRatio,
                            obj.bounding[1] * hRatio,
                            (obj.bounding[0] + obj.bounding[2]) * wRatio,
                            (obj.bounding[1] + obj.bounding[3]) * hRatio,
                        ],
                    })
                )
            })
            dispatch(predict(false))
            setLoading(false)
        }
        image.crossOrigin = 'Anonymous'
        image.src = `${inPlaceEntry.url}?${new Date().getTime()}`
    }, [loaded, isPredict])

    return null
}

const PredictAnnotations = () => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [notReady, setNotReady] = useState(false)
    const [error, setError] = useState(false)

    const onPredictClick = () => {
        dispatch(predict())
    }
    return (
        <Tooltip placement="top" title={<Typography fontSize={11}>Predecir</Typography>}>
            <div style={{ display: 'inline' }}>
                <IconButtonStatus
                    loading={loading}
                    error={error}
                    iconButtonProps={{
                        edge: 'end',
                        'aria-label': 'predict',
                        disabled: notReady,
                        onClick: onPredictClick,
                    }}
                    icon={<OnlinePredictionIcon fontSize="large" />}
                />
                <OpenCvProvider>
                    <PredictComponent
                        setLoading={setLoading}
                        setError={setError}
                        setNotReady={setNotReady}
                    />
                </OpenCvProvider>
            </div>
        </Tooltip>
    )
}

export default PredictAnnotations
