export const SET_LOADING = 'SET_LOADING'

export const setLoading = (loading) => ({ type: SET_LOADING, loading })

export default (state = { loading: false }, action) => {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        default:
            return state
    }
}
