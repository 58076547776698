const SET_SELECTED_OBSTACLE = 'SET_SELECTED_OBSTACLE'
const SET_ACTIVE_OBSTACLE = 'SET_ACTIVE_OBSTACLE'
const UPDATE_OBSTACLE = 'UPDATE_OBSTACLE'
const ADD_OBSTACLE = 'ADD_OBSTACLE'
const DELETE_OBSTACLE = 'DELETE_OBSTACLE'
const UPDATE_CURRENT_OBSTACLE = 'UPDATE_CURRENT_OBSTACLE'
const RELOAD_OBSTACLE = 'RELOAD_OBSTACLE'
const PICK_OVER_OBJECT = 'PCIK_OVER_OBJECT'

export const setSelectedObstacle = (index) => ({
    type: SET_SELECTED_OBSTACLE,
    index,
})

export const updateObstacle = (index, data) => ({
    type: UPDATE_OBSTACLE,
    index,
    data,
})

export const addObstacle = (obstacle) => ({
    type: ADD_OBSTACLE,
    obstacle,
})

export const deleteObstacle = (obstacleIndex) => ({
    type: DELETE_OBSTACLE,
    obstacleIndex,
})

export const updateCurrentObstacle = (obstacle) => ({
    type: UPDATE_CURRENT_OBSTACLE,
    obstacle,
})

export const setActiveObstacle = (obstacle) => ({
    type: SET_ACTIVE_OBSTACLE,
    obstacle,
})

export const reload = () => ({
    type: RELOAD_OBSTACLE,
})

export const pickOverObject = () => ({
    type: PICK_OVER_OBJECT,
})

export const initialState = {
    obstacles: [],
    selectedObstacle: null,
    pickOverObjectActivated: false,
}

/* eslint-disable default-param-last */
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_OBSTACLE:
            return { ...state, selectedObstacle: action.index }
        case UPDATE_OBSTACLE:
            return {
                ...state,
                obstacles: [
                    ...state.obstacles.slice(0, action.index),
                    {
                        ...state.obstacles[action.index],
                        unsaved: true,
                        ...action.data,
                    },
                    ...state.obstacles.slice(action.index + 1),
                ],
            }
        case DELETE_OBSTACLE:
            return {
                ...state,
                obstacles: [
                    ...state.obstacles.slice(0, action.obstacleIndex),
                    ...state.obstacles.slice(action.obstacleIndex + 1),
                ],
                selectedObstacle: null,
            }

        case RELOAD_OBSTACLE:
            return {
                ...state,
                obstacles: [],
                selectedObstacle: null,
            }
        case PICK_OVER_OBJECT:
            return {
                ...state,
                pickOverObjectActivated: !state.pickOverObjectActivated,
            }
        default:
            return state
    }
}
