import {
    EDIT_MODE_CREATE_POINTS,
    TOOLBOX_ANNOTATION,
    TOOLBOX_POLYGONS_AND_POINTS,
    TOOLBOX_SAMPLES,
} from '../constants'

/**
 * Remove an annotation by its index from the state
 *
 * @param {object} state - Actual state
 * @param {number} annIndex - Annotation index to remove
 * @returns The new state
 */
export const removeAnnotation = (state, annIndex) => {
    let selectedToolbox = TOOLBOX_ANNOTATION
    let selectedAnnotation = Math.max(0, annIndex - 1)
    if (state.inPlaceAnnotations[state.selectedEntry].length === 1) {
        selectedToolbox = TOOLBOX_SAMPLES
        selectedAnnotation = null
    }
    return {
        ...state,
        selectedToolbox,
        selectedAnnotation,
        inPlaceAnnotations: {
            ...state.inPlaceAnnotations,
            [state.selectedEntry]: [
                ...state.inPlaceAnnotations[state.selectedEntry].slice(0, annIndex),
                ...state.inPlaceAnnotations[state.selectedEntry].slice(annIndex + 1),
            ],
        },
    }
}

/**
 * Remove a polygon from the selected annotation by its index
 *
 * @param {object} state - Actual state
 * @param {number} polygonIndex - Polygon index
 * @returns New state
 */
export const removePolygon = (state, polygonIndex) => {
    const { selectedEntry, selectedAnnotation, inPlaceAnnotations } = state
    return {
        ...state,
        selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
        selectedPolygon: Math.max(state.selectedPolygon - 1, 0),
        inPlaceAnnotations: {
            ...state.inPlaceAnnotations,
            [selectedEntry]: [
                ...inPlaceAnnotations[selectedEntry].slice(0, selectedAnnotation),
                {
                    ...inPlaceAnnotations[selectedEntry][selectedAnnotation],
                    polygon: [
                        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon.slice(
                            0,
                            polygonIndex
                        ),
                        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon.slice(
                            polygonIndex + 1
                        ),
                    ],
                },
                ...inPlaceAnnotations[selectedEntry].slice(selectedAnnotation + 1),
            ],
        },
    }
}

/**
 * Remove a point from the selected polygon of the selected annotation by
 * its index
 *
 * @param {object} state - Actual state
 * @param {number} polIndex - Polygon index
 * @param {number} pointIndex - Point index
 * @returns New state
 */
export const removePoint = (state, polIndex, pointIndex) => {
    const { selectedEntry, selectedAnnotation, inPlaceAnnotations } = state
    let newPolygon = [
        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon[polIndex].slice(
            0,
            pointIndex
        ),
        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon[polIndex].slice(
            pointIndex + 1
        ),
    ]
    if (newPolygon.length !== 0) {
        newPolygon = [newPolygon]
    }
    return {
        ...state,
        selectedPolygon: polIndex,
        selectedPoint: Math.max(pointIndex - 1, 0),
        selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
        inPlaceAnnotations: {
            ...inPlaceAnnotations,
            [selectedEntry]: [
                ...inPlaceAnnotations[selectedEntry].slice(0, selectedAnnotation),
                {
                    ...inPlaceAnnotations[selectedEntry][selectedAnnotation],
                    polygon: [
                        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon.slice(
                            0,
                            polIndex
                        ),
                        ...newPolygon,
                        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon.slice(
                            polIndex + 1
                        ),
                    ],
                },
                ...inPlaceAnnotations[selectedEntry].slice(selectedAnnotation + 1),
            ],
        },
    }
}

/**
 *  Add polygon to selected annotation
 *
 * @param {object} state - Actual state
 * @param {object} x - Initial point x coordinate
 * @param {object} y - Initial point y coordinate
 * @returns New state
 */
export const createPolygon = (state, x, y) => {
    const { selectedEntry, selectedAnnotation, inPlaceAnnotations } = state
    if (selectedAnnotation === null || selectedEntry === null) {
        return state
    }

    return {
        ...state,
        selectedPolygon: inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon.length,
        selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
        selectedPoint: 0,
        editMode: EDIT_MODE_CREATE_POINTS,
        inPlaceAnnotations: {
            ...state.inPlaceAnnotations,
            [selectedEntry]: [
                ...inPlaceAnnotations[selectedEntry].slice(0, selectedAnnotation),
                {
                    ...inPlaceAnnotations[selectedEntry][selectedAnnotation],
                    polygon: [
                        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon,
                        [[Math.ceil(x), Math.ceil(y)]],
                    ],
                },
                ...inPlaceAnnotations[selectedEntry].slice(selectedAnnotation + 1),
            ],
        },
    }
}

/**
 * Add point to the selected polygon of the selected annotation
 *
 * @param {*} state - Actual state
 * @param {*} x - Initial point x coordinate
 * @param {*} y - Initial point y coordinate
 * @returns New state
 */
export const createPoint = (state, x, y) => {
    const { selectedEntry, selectedAnnotation, selectedPolygon, inPlaceAnnotations } = state
    if (selectedEntry === null || selectedAnnotation === null || selectedPolygon === null) {
        return state
    }
    return {
        ...state,
        selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
        selectedPoint:
            inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon[selectedPolygon].length,
        inPlaceAnnotations: {
            ...state.inPlaceAnnotations,
            [selectedEntry]: [
                ...inPlaceAnnotations[selectedEntry].slice(0, selectedAnnotation),
                {
                    ...inPlaceAnnotations[selectedEntry][selectedAnnotation],
                    polygon: [
                        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon.slice(
                            0,
                            selectedPolygon
                        ),
                        [
                            ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon[
                                selectedPolygon
                            ],
                            [Math.ceil(x), Math.ceil(y)],
                        ],
                        ...inPlaceAnnotations[selectedEntry][selectedAnnotation].polygon.slice(
                            selectedPolygon + 1
                        ),
                    ],
                },
                ...inPlaceAnnotations[selectedEntry].slice(selectedAnnotation + 1),
            ],
        },
    }
}
