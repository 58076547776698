import React from "react"

import Grid from "@material-ui/core/Grid"

class RightSide extends React.Component {
    render() {
        return (
           <Grid item md={7} xs={12}>
                <Grid container direction="row" justify="center" alignItems="center" style={{ minHeight: "100%", boxShadow: "0 0 40px -15px rgba(0,0,0,0.6)" }}>
                    <Grid container md={7}>
                        {this.props.children}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default RightSide