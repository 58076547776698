import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/react-hooks"

import Dialog from "@material-ui/core/Dialog"
import Fab from "@material-ui/core/Fab"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import AddIcon from "@material-ui/icons/Add"

import LoadingButton from "components/loading-button"

import { ADD_ENTRIES_TO_TRAINING_SET_FROM_DATASET  } from "../../../../queries/training-sets"
import { GET_INDUSTRIAL_DATASET_BY_PARENT } from "../../../../../datasets/queries/industrial-datasets"

function AddEntriesDialog() {
    const { trainingSet } = useParams()

    const [ open, setOpen ] = useState(false)
    const [ from, setFrom ] = useState(0)
    const [ to, setTo ] = useState(0)
    const [ selectedDataset, setSelectedDataset ] = useState(null)
    const [ loading, setLoading ] = useState(false)

    const [ addEntries ] = useMutation(ADD_ENTRIES_TO_TRAINING_SET_FROM_DATASET)
    const { data: { getIndustrialDatasetsByParent: { datasets = []} = {}} = {}} = useQuery(GET_INDUSTRIAL_DATASET_BY_PARENT, {
        variables: {
            params: {
                parent: null
            }
        }
    })

    const onFabClicked = () => setOpen(true)

    const onCancel = () => setOpen(false)
    const onAccept = async () => {
        setLoading(true)
        await addEntries({
            variables: {
                params: {
                    trainingSet,
                    dataset: selectedDataset,
                    from,
                    to
                }
            }
        })
        setLoading(false)
    }

    const onFromChanged = event => {
        setFrom(event.target.value)
    }

    const onToChanged = event => {
        setTo(event.target.value)
    }

    const onSelectedDatasetChanged = event => {
        setSelectedDataset(event.target.value)
    }

    return (
        <React.Fragment>
            <Fab onClick={onFabClicked}>
                <AddIcon/>
            </Fab>
            <Dialog open={open}>
                <DialogContent>
                    <Select fullWidth value={selectedDataset} onChange={onSelectedDatasetChanged}>
                        {datasets.map(dataset => <MenuItem value={dataset._id}>{dataset.name}</MenuItem>)}
                    </Select>
                    <TextField fullWidth label="Desde" type="number" step="1" onChange={onFromChanged} value={from}></TextField>
                    <TextField fullWidth label="Hasta" type="number" step="1" onChange={onToChanged} value={to}></TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <LoadingButton loading={loading} onClick={onAccept}>Aceptar</LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default AddEntriesDialog