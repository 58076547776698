import { VISIBILITY, EXPORT_CSV_DIALOG } from "../queries/local"

export default {
    Query: {
        visibility(root, args, { cache }) {
            try {
                const { visibility } = cache.readQuery({ query: VISIBILITY })   
                return {
                    ...visibility,
                    __typename: "Visibility"
                }
            } catch(err){
                return { 
                    radiometric: true,
                    hotspots: true,
                    orthophotos: true,
                    __typename: "Visibility"
                }
            }
        },
        exportCsvDialog(root, args, { cache }) {
            try {
                const { exportCsvDialog } = cache.readQuery({ query: EXPORT_CSV_DIALOG })   
                return {
                    ...exportCsvDialog,
                    __typename: "ExportCsvDialog"
                }
            } catch(err){
                return { 
                    open: false,
                    __typename: "ExportCsvDialog"
                }
            }
        }
    },
    Mutation: {
        setVisibility(root, { type, value }, { cache }) {
            const { visibility } = cache.readQuery({ query: VISIBILITY })   
            
            const data =  {
                visibility: {
                    ...visibility,
                    [type]: value,
                }
            }
            
            cache.writeQuery({
                query: VISIBILITY,
                data
            })

            return "SUCCEEDED"
        },
        openExportCsvDialog(root, args, { cache }) {
            const { exportCsvDialog } = cache.readQuery({ query: EXPORT_CSV_DIALOG })   
                
            const data =  {
                exportCsvDialog: {
                    ...exportCsvDialog,
                    open: true
                }
            }
            
            cache.writeQuery({
                query: EXPORT_CSV_DIALOG,
                data
            })
    
            return "SUCCEEDED"
        },
        closeExportCsvDialog(root, args, { cache }) {
            const { exportCsvDialog } = cache.readQuery({ query: EXPORT_CSV_DIALOG })   
                
            const data =  {
                exportCsvDialog: {
                    ...exportCsvDialog,
                    open: false
                }
            }
            
            cache.writeQuery({
                query: EXPORT_CSV_DIALOG,
                data
            })
    
            return "SUCCEEDED"
        }
    }
}