import React from 'react'

import { Link, useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'

import Fab from '@material-ui/core/Fab'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import AddIcon from '@material-ui/icons/Add'

import { GET_ALL_USERS } from '../../queries/users'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const Scene = () => {
    const classes = useStyles()
    const match = useRouteMatch()

    const {
        loading,
        error,
        data: { getAllUsers: { users = [] } = {} } = {},
    } = useQuery(GET_ALL_USERS, {
        variables: { params: {} },
    })

    const { isAllowed: isCreateUserAllowed } = useIsAllowed({
        group: 'users',
        action: 'createUser',
    })

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2">Usuarios</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <List>
                            {users.map((user) => (
                                <Link to={user._id}>
                                    <ListItem button key={user._id}>
                                        <ListItemText primary={user.email} secondary="" />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </Paper>
                </Grid>
            </Grid>

            {isCreateUserAllowed && (
                <Link to={'new'}>
                    <Fab color="primary" aria-label="add" className={classes.fab}>
                        <AddIcon />
                    </Fab>
                </Link>
            )}
        </Container>
    )
}

export default Scene
