import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'

import { useIsAllowed } from 'queries/permissions'

import ConnectivityInfo from './containers/connectivity-info/scene'

import { REMOVE_GROUP, GET_GROUPS } from '../../../../queries/groups'

function Settings(props) {
    const history = useHistory()

    const { isAllowed: isRemoveGroupAllowed } = useIsAllowed({
        action: 'removeGroupById',
        group: 'groups',
    })

    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)

    const [removeGroupById] = useMutation(REMOVE_GROUP)

    const onAccept = async () => {
        setLoading(true)
        setLoading(false)
        setOpen(false)
    }

    const onDelete = async () => {
        setLoading(true)
        await removeGroupById({
            variables: {
                params: { _id: props.group },
            },
            refetchQueries: [
                {
                    query: GET_GROUPS,
                },
            ],
        })
        setLoading(false)
        history.push('../groups')
    }

    return (
        <div>
            <Paper>
                <List>
                    <ListItem button onClick={() => setOpen(true)}>
                        <ListItemText primary="Nombre" secondary="Name" />
                    </ListItem>
                    <ConnectivityInfo />
                </List>
            </Paper>
            <Dialog open={open}>
                <DialogContent>
                    <TextField
                        label="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            {isRemoveGroupAllowed && (
                <Button
                    variant="contained"
                    style={{ background: '#f44336', color: 'white', marginTop: 20 }}
                    fullWidth
                    onClick={onDelete}
                >
                    Eliminar
                </Button>
            )}
        </div>
    )
}

export default Settings
