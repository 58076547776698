import React, { useState } from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"

import Dialog from "@material-ui/core/Dialog"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import LoadingButton from '@mui/lab/LoadingButton';

import { GET_CONNECTIVITY_INFO_BY_GROUP, SET_CONNECTIVITY_INFO_BY_GROUP } from "../../../../../../queries/groups"

const ConnectivityInfo = (props) => {
    const { group } = useParams()

    const { data: { falcon: { groups: { getGroupConnectivityInfo: { address = "", port = 0} = {}} = {}} = {}} = {}} = useQuery(GET_CONNECTIVITY_INFO_BY_GROUP, { variables: { params: { group }}})
    const [ setConnectivityInfo ] = useMutation(SET_CONNECTIVITY_INFO_BY_GROUP)

    const [ open, setOpen ] = useState(false)
    const [ tempAddress, setTempAddress ] = useState(null)
    const [ tempPort, setTempPort ] = useState(null)
    const [ loading, setLoading ] = useState(false)

    const onIpAddressChange = e => setTempAddress(e.target.value)
    const onPortChange = e => setTempPort(e.target.value)

    const onOpen = () => {
        setTempAddress(null)
        setTempPort(null)
        setOpen(true)
    }
    const onCancel = () => setOpen(false)
    const onAccept = async () => {
        setLoading(true)

        await setConnectivityInfo({
            variables: {
                params: {
                    group,
                    address: tempAddress || address,
                    port: tempPort || port
                }
            },
            refetchQueries: [
                {
                    query: GET_CONNECTIVITY_INFO_BY_GROUP,
                    variables: { params: { group }}
                }
            ]
        })

        setLoading(false)
        setOpen(false)
    }

    return (
        <React.Fragment>
            <ListItem button onClick={onOpen}>
                <ListItemText primary="Connectivity Info" secondary={`Address: ${address} | Port: ${port}`} />
            </ListItem>
            <Dialog open={open}>
                <DialogTitle>Connectivity Info</DialogTitle>
                <DialogContent>
                    <TextField label="IP Address" defaultValue={address} onChange={onIpAddressChange} fullWidth/>
                    <TextField label="Port" defaultValue={port} onChange={onPortChange} fullWidth/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} disabled={loading}>Cancel</Button>
                    <LoadingButton onClick={onAccept} loading={loading} variant="contained" style={{ background: "#242f3e", fontSize: 11 }}>Accept</LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default ConnectivityInfo