import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { graphql, compose } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { withSnackbar } from 'notistack'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import PasswordIcon from '@material-ui/icons/Lock'

import Container from '../components/Container'
import LeftSide from '../components/LeftSide'
import RightSide from '../components/RightSide'

import LoadingButton from 'components/loading-button'

import { setAccessToken, setRefreshToken, setIdToken } from '../reducers/auth'

import { NEW_PASSWORD } from '../queries/authentication'

import PasswordValidator from 'password-validator'

var schema = new PasswordValidator()

schema.is().min(8).is().max(100).has().uppercase().has().lowercase().has().digits().has().symbols()

const useStyles = makeStyles((theme) => ({
    root: {
        '&$checked': {
            color: '#64dd17',
        },
    },
    checked: {},
}))

function NewPassword(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const [loading, setLoading] = useState(false)

    const [password, setPassword] = useState('')

    const [isLongEnough, setIsLongEnough] = useState(false)
    const [hasNumbers, setHasNumbers] = useState(false)
    const [hasLower, setHasLower] = useState(false)
    const [hasUpper, setHasUpper] = useState(false)
    const [hasSymbols, setHasSymbols] = useState(false)

    const [passwordError, setPasswordError] = useState('')

    const onPasswordChange = (e) => {
        setPassword(e.target.value)

        const list = schema.validate(e.target.value, { list: true })

        const funcs = {
            min: setIsLongEnough,
            uppercase: setHasUpper,
            lowercase: setHasLower,
            digits: setHasNumbers,
            symbols: setHasSymbols,
        }

        Object.values(funcs).map((f) => f(true))
        list.map((l) => funcs[l](false))
    }

    const onSubmit = async () => {
        if (!schema.validate(password)) {
            setPasswordError('Introduzca una contraseña válida')
            return
        } else {
            setPasswordError('')
        }

        setLoading(true)
        try {
            const res = await props.newPassword({ password })
            if (res.data.newPassword.state === 'SUCCESS') {
                dispatch(setAccessToken(res.data.newPassword.access_token))
                dispatch(setRefreshToken(res.data.newPassword.refresh_token))
                dispatch(setIdToken(res.data.newPassword.id_token))
                history.push('/private')
            }
        } catch (err) {
            props.enqueueSnackbar('Se ha producido un error', { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container>
            <LeftSide />
            <RightSide>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" style={{ marginBottom: 10 }}>
                        Nueva Contraseña
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={isLongEnough}
                            />
                        }
                        label="Longitud mínima 8"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={hasNumbers}
                            />
                        }
                        label="Debe tener números"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={hasUpper}
                            />
                        }
                        label="Debe tener mayúsculas"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={hasLower}
                            />
                        }
                        label="Debe tener minúsculas"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                classes={{ root: classes.root, checked: classes.checked }}
                                checked={hasSymbols}
                            />
                        }
                        label="Debe tener al menos un caracter especial"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Contraseña"
                        type="password"
                        placeholder="Introduzca una contraseña"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PasswordIcon />
                                </InputAdornment>
                            ),
                        }}
                        error={passwordError.length}
                        helperText={passwordError}
                        value={password}
                        onChange={onPasswordChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        loading={loading}
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        Aceptar
                    </LoadingButton>
                </Grid>
            </RightSide>
        </Container>
    )
}

export default compose(
    withSnackbar,
    graphql(NEW_PASSWORD, {
        props: ({ mutate, ownProps }) => {
            return {
                newPassword: (params) =>
                    mutate({
                        variables: {
                            params: {
                                ...params,
                                email: ownProps.match.params.email,
                                session: ownProps.match.params.session,
                            },
                        },
                    }),
            }
        },
    })
)(NewPassword)
