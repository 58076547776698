import gql from "graphql-tag"

export const CREATE_DATASET_V2 = gql`
    mutation CreateDatasetV2($params: CreateDatasetV2Request!) {
        createDatasetV2(params: $params) {
            status
            dataset {
                createdAt
                name
            }
        }
    }
`

export const GET_DATASETS_V2_BY_PARENT = gql`
    query GetDatasetsV2ByParent($params: GetDatasetsV2ByParentRequest!) {
        getDatasetsV2ByParent(params: $params) {
            status
            datasets {
                id
                createdAt
                name
            }
        }
    }
`

export const GET_DATASET_V2_BY_ID = gql`
    query GetDatasetV2ById($params: GetDatasetV2ByIdRequest!) {
        getDatasetV2ById(params: $params) {
            status
            dataset {
                fields {
                    name
                    type
                }
            }
        }
    }
`

export const QUERY_DATASETS_V2_ENTRIES_BY_DATASET = gql`
    query QueryDatasetV2EntriesByDataset($params: QueryDatasetV2EntriesByDatasetRequest!) {
        queryDatasetV2EntriesByDataset(params: $params) {
            status
            entries {
                channel
                value
            }
        }
    }

`