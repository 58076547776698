import React, { useState, useEffect } from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { Redirect, useLocation } from "react-router-dom"
import { withSnackbar } from 'notistack';

import Loading from "components/Loading"

import { GET_PAYMENT_METHODS, SET_DEFAULT_PAYMENT_METHOD } from "../../account/containers/payment-methods/queries/payment-methods"


function SetDefaultPaymentMethod(props) {

    const location = useLocation()

    const [ step, setStep ] = useState(0)
    const [ loadingDefault, setLoadingDefault ] = useState(false)
    const { data: { paymentMethods = [] } = {}, loading} = useQuery(GET_PAYMENT_METHODS, {
        variables: {},
        onCompleted: async (data) => {
            console.log(data)
            switch(paymentMethods.length) {
                case 0:
                    setStep(1)
                    break;
                case 1:
                    setLoadingDefault(true)
                    try {
                        const res = await setDefaultPaymentMethod({
                            variables: {
                                params: {
                                    paymentMethodId: paymentMethods[0].id
                                }
                            }
                        })
                        if(res.data.setDefaultPaymentMethod.state === "SUCCESS") {
                            setStep(2)
                        }
                    } catch (err) {
                        props.enqueueSnackbar("Se ha producido un error", { variant: "error" })
                    } finally {
                        setLoadingDefault(false)
                    }
                    break;
                default:
                    setStep(3)
            }
        }
    })
    const [ setDefaultPaymentMethod ] = useMutation(SET_DEFAULT_PAYMENT_METHOD)
    

    let steps = [
        null,
        <Redirect to={"/private/onboarding/payment-methods" + location.hash} />,
        <Redirect to={"/private" + location.hash} />,
        <div>TEST</div>
    ]

    return (
        <Loading loading={loading}>
            {
                steps[step]
            }
        </Loading>
    )
}

export default withSnackbar(SetDefaultPaymentMethod)

