import React, { useState, useCallback } from "react"

import { useParams } from "react-router-dom"
import { useDropzone } from 'react-dropzone'
import { useMutation } from "@apollo/react-hooks"
import { useSelector, useDispatch } from "react-redux"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

import AddIcon from "@material-ui/icons/Add"

import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LoadingButton from "components/loading-button"

import { CREATE_ENTRIES } from "../../../../../../../../../../queries/entries"

const initialStateProgress = {
    completed: 0,
    total: 0,
    progress: 0,
};


function AddEntriesDialog() {
 
    const { _id, type } = useSelector(state => state.inspector.universal.dataset)

    const [ open, setOpen ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ files, setFiles ] = useState([])
    const [ filesLoad, setFilesLoad ] = useState([])  
    const [ uploadProgress, setUplProgress ] = useState({ ...initialStateProgress });

    const [ createDatasetEntries ] = useMutation(CREATE_ENTRIES);    

    const onDrop = useCallback(acceptedFiles => {
        setFilesLoad( new Array(acceptedFiles.length).fill().map(
            () => ({ loading: false })
        ));
        setFiles(acceptedFiles)
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onAddImagesClick = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onAccept = async () => {
        setLoading(true)
        setUplProgress(prev => ({ ...prev, total: files.length }))
        
        files.map((file, i) => {
            setFilesLoad(prev => ([ ...prev, prev[i].loading = false ]));
        });

        if(files.length > 0) {      
            const entries = await Promise.all(files.map((f,i) => {
                return new Promise((resolve, reject) => {   

                    const reader = new FileReader();

                    reader.readAsText(f);

                    reader.onload = (e) => {
                        resolve([
                            {
                                name: "geojson",
                                value: e.target.result
                            }
                        ])
                    }; 
                })
            }))
 
            const { data: { createHorusDatasetEntries: { status, properties }}} = await createDatasetEntries({
                variables: {
                    params: {
                        type,
                        dataset: _id,
                        properties: entries
                    }
                }
            })

            if(status === "SUCCEEDED") {
                for(let i = 0; i < properties.length; i++){
                    const res = properties[i]

                    const outputs = res.reduce((acc, item) => ({...acc, [item.name]: item.value}), {})

                    const url = outputs["url"]
                    const fields = JSON.parse(outputs["fields"])

                    var data = new FormData()
                    Object.keys(fields).forEach(k => data.append(k, fields[k]))
                    data.append('file', files[i])

                    setFilesLoad(prev => ([ ...prev, prev[i].loading = true ]));

                    await fetch(url, {
                        method: "POST",
                        body: data
                    })
                    
                    setFilesLoad(prev => ([ ...prev, prev[i].loading = false ]));

                    setUplProgress(prev => ({ 
                        ...prev, 
                        completed: prev.completed + 1, 
                        progress: ( prev.completed + 1 ) / prev.total * 100,
                    }))
                }
            }
        }

        setLoading(false)
        setFiles([])
        setFilesLoad([])
        setUplProgress({ ...initialStateProgress })   
    }

    const onRequestDelete = i => () => {
        setFilesLoad([
            ...filesLoad.slice(0, i),
            ...filesLoad.slice(i + 1)
        ])
        setFiles([
            ...files.slice(0, i),
            ...files.slice(i + 1)
        ])
    }

    return (
        <React.Fragment>
            <Dialog open={open} fullWidth maxWidth={"lg"}>
                <DialogContent>
                    <Grid container spacing={2} style={{ height: 600 }}>
                        <Grid item xs={4} style={{ height: "100%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
                            <List subheader={ <ListSubheader disableSticky component="div">Files</ListSubheader> } style={{height: "100%", overflow: 'auto' }}>
                                {
                                    files.map((f, i) => (
                                        <ListItem>
                                            <ListItemText primary={f.path} />
                                            <ListItemSecondaryAction>
                                                { 
                                                    filesLoad[i].loading  &&
                                                        <CircularProgress color="secondary" size={20} />
                                                }
                                                {
                                                    !loading &&
                                                        <IconButton edge="center" disabled={loading} onClick={onRequestDelete(i)}>
                                                            <DeleteIcon />
                                                        </IconButton> 
                                                }
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                }
                            </List>
                            {
                                uploadProgress.progress > 0 && 
                                    <LinearProgress variant="determinate" color="secondary" value={uploadProgress.progress} />
                            }
                        </Grid>
                        <Grid item xs={8} style={{ height: "100%" }}>
                            <div {...getRootProps()} style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <div style={{ marginBottom: 20 }}>
                                    <InsertDriveFileIcon style={{ fontSize: 40 }}  />
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Drop the files here</p> :
                                        <p>Drag files here or click to upload</p>
                                    }
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button disabled={loading} onClick={onClose}>Cancelar</Button>
                    <LoadingButton loading={loading} onClick={onAccept}>Aceptar</LoadingButton>
                </DialogActions>
            </Dialog>
            <Button
                startIcon={<AddIcon />}
                onClick={onAddImagesClick}
            >
                Add Polygons
            </Button>
        </React.Fragment>
    )
}

export default AddEntriesDialog