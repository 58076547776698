import React, { useState, useMemo } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

import EditIcon from '@material-ui/icons/Edit'

import {
    OPEN_INCIDENT,
    IN_PROGRESS_INCIDENT,
    CLOSE_INCIDENT,
    GET_INCIDENT_BY_ID,
} from '../../../../queries/incidents'

import { useIsAllowed } from 'queries/permissions'

const Scene = (props) => {
    const { incident } = useParams()

    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)

    const { isAllowed: isInProgressAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'inProgressIncident',
    })
    const { isAllowed: isCloseAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'closeIncident',
    })
    const { isAllowed: isOpenAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'openIncident',
    })

    const { data } = useQuery(GET_INCIDENT_BY_ID, {
        variables: {
            params: {
                id: incident,
            },
        },
    })

    const statusMap = {
        OPEN: 'ABIERTO',
        IN_PROGRESS: 'EN PROGRESO',
        CLOSED: 'CERRADO',
    }

    const getIncidentById = (data && data.getIncidentById) || {}
    const inc = getIncidentById.incident || {}
    const status = statusMap[inc.status] || ''

    const [openIncident] = useMutation(OPEN_INCIDENT)
    const [inProgressIncident] = useMutation(IN_PROGRESS_INCIDENT)
    const [closeIncident] = useMutation(CLOSE_INCIDENT)

    const onEdit = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const statusOptions = useMemo(() => {
        let availableStatus = []

        if (isOpenAllowed)
            availableStatus.push({
                value: 'OPEN',
                label: 'ABIERTO',
            })
        if (isInProgressAllowed)
            availableStatus.push({
                value: 'IN PROGRESS',
                label: 'EN PROGRESO',
            })
        if (isCloseAllowed)
            availableStatus.push({
                value: 'CLOSED',
                label: 'CERRADO',
            })

        return availableStatus
    }, [isInProgressAllowed, isCloseAllowed, isOpenAllowed])

    const onOptionClick = async (option) => {
        setAnchorEl(null)
        setLoading(true)

        const updateMap = {
            OPEN: openIncident,
            'IN PROGRESS': inProgressIncident,
            CLOSED: closeIncident,
        }

        try {
            const updateIncident = updateMap[option.value]

            await updateIncident({
                variables: {
                    params: {
                        id: incident,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_INCIDENT_BY_ID,
                        variables: {
                            params: {
                                id: incident,
                            },
                        },
                    },
                ],
            })
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const renderIcon = () => {
        if (loading) {
            return <CircularProgress size={20} />
        }

        return <EditIcon />
    }

    const isEditAllowed = isInProgressAllowed || isCloseAllowed || isOpenAllowed

    return (
        <React.Fragment>
            <ListItem button>
                <ListItemText primary="Estado" secondary={status} />
                {isEditAllowed && (
                    <ListItemSecondaryAction>
                        <IconButton onClick={onEdit}>{renderIcon()}</IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {statusOptions.map((option, index) => (
                    <MenuItem key={index} onClick={() => onOptionClick(option)}>
                        {option.label}
                    </MenuItem>
                ))}
            </Popover>
        </React.Fragment>
    )
}

export default Scene
