import { combineReducers } from 'redux'

import { reducer as boxes } from './boxes'
import { reducer as layouts } from './layout'
import { reducer as stacks } from './stack'
import { reducer as obstacles } from './obstacles'
import { reducer as settings } from './settings'

export default combineReducers({
    boxes,
    layouts,
    stacks,
    obstacles,
    settings,
})
