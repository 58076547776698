import React from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import {
    RESOURCE_TYPE,
    STRING_TYPE,
    BOOLEAN_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'
import { GET_RESOURCES_BY_TYPE } from '../../../../../../queries/resources'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const { group } = useParams()

    const { data: { getResourcesByType: { resources = [] } = {} } = {} } = useQuery(
        GET_RESOURCES_BY_TYPE,
        {
            variables: {
                params: {
                    group,
                    type: 'machine-learning-v2',
                },
            },
        }
    )

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Entrada"
                    value={
                        JSON.parse(getProperty('input-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('input-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="model" humanName="Modelo" parametersFilter={RESOURCE_TYPE}>
                    <FormControl fullWidth>
                        <InputLabel>Modelo</InputLabel>
                        <Select
                            value={
                                JSON.parse(getProperty('model', JSON.stringify({ value: '' })))
                                    .value
                            }
                            onChange={onPropertyChangeBuilder('model', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            {resources.map((resource) => (
                                <MenuItem key={resource._id} value={resource._id}>
                                    {resource.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="lang" humanName="Lenguaje" parametersFilter={STRING_TYPE}>
                    <FormControl fullWidth>
                        <InputLabel>Lenguaje</InputLabel>
                        <Select
                            value={
                                JSON.parse(getProperty('lang', JSON.stringify({ value: '' }))).value
                            }
                            onChange={onPropertyChangeBuilder('lang', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            {['es', 'en', 'ch'].map((langId) => (
                                <MenuItem key={langId} value={langId}>
                                    {langId}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="optimized" humanName="Optimizar" parametersFilter={BOOLEAN_TYPE}>
                    <FormControl fullWidth>
                        <InputLabel>Optimizar</InputLabel>
                        <Select
                            value={
                                JSON.parse(getProperty('optimized', JSON.stringify({ value: '' })))
                                    .value
                            }
                            onChange={onPropertyChangeBuilder('optimized', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            <MenuItem key="true" value="true">
                                Activado
                            </MenuItem>
                            <MenuItem key="false" value="false">
                                Desactivado
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
