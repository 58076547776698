import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#F0F7FA',
            main: '#025DA8',
            dark: '#213B7C',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#025DA8',
            dark: '#ba000d',
            contrastText: '#000',
        },
        error: {
            main: '#f44336',
            darker: '#f44336',
            light: '#f9a19b',
        },
        success: {
            main: '#03C988',
            darker: '#01724d',
            light: '#c4feeb',
        },
    },
    typography: {
        fontSize: 16,
    },
    props: {
        MuiSvgIcon: {
            fontSize: 'large',
        },
    },
    overrides: {
        MuiMenuItem: {
            root: {
                textDecoration: 'none',
                color: '#333',
            },
        },
        MuiPaper: {
            root: {
                borderRadius: 4,
            },
        },
        MuiAppBar: {
            root: {
                left: -5,
                width: 'calc(100vw + 10px)',
            },
        },
    },
})

theme.shadows[1] = '0 0 14px 0 rgba(53,64,82,.05)'

export default theme
