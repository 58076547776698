import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

/**
 * Component to render boolean parameter type
 * Is used for creating new parameter and update its value
 * once created
 * Is saved in database as a true or false
 */
const ParamTypeBoolean = ({ value, setValue }) => {
    const [menuValue, setMenuValue] = useState(value || '')

    return (
        <FormControl margin="normal" fullWidth>
            <Select
                label="Boolean"
                fullWidth
                value={menuValue}
                onChange={(event) => {
                    setMenuValue(event.target.value)
                    setValue(event.target.value)
                }}
            >
                <MenuItem key="true" value="true">
                    Activado
                </MenuItem>
                <MenuItem key="false" value="false">
                    Desactivado
                </MenuItem>
            </Select>
        </FormControl>
    )
}

ParamTypeBoolean.propTypes = {
    /** Initial value and value linked with parent states */
    value: PropTypes.string.isRequired,
    /** Function to set the parent state */
    setValue: PropTypes.func.isRequired,
}

export default ParamTypeBoolean
