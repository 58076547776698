import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import { SET_SENSOR_PARAMS } from "../../../../queries/sensors"

import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"

import SettingsIcon from '@material-ui/icons/Settings';

const SetSensorParamsDialog = (props) => {

    const { sensor } = props

    const [ open, setOpen ] = useState(false)
    const [ interval, setInterval ] = useState(0)
    const [ samples, setSamples ] = useState(0)
    const [ loading, setLoading ] = useState(false)

    const [ setSensorParams ] = useMutation(SET_SENSOR_PARAMS)

    const onEditClicked = () => {
        setSamples(0)
        setInterval(0)
        setOpen(true)
    }

    const onIntervalChange = e => {
        setInterval(e.target.value)
    }

    const onSamplesChange = e => {
        setSamples(e.target.value)
    }

    const onAcceptClicked = async () => {
        setLoading(true)

        if(interval < 1) return
        if(samples < 1) return

        try {
            await setSensorParams({
                variables: {
                    params: {
                        nodeId: sensor.sensorId,
                        measureInterval: interval,
                        txCount: samples
                    }
                }
            })

            setOpen(false)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const onClose = () => {
        setOpen(false)
    }


    return (
        <React.Fragment>
            <IconButton onClick={onEditClicked}>
                <SettingsIcon />
            </IconButton>
            <Dialog open={open}>
                <DialogTitle>Editar parámetros de medida</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Interval (ms)"
                                value={interval}
                                onChange={onIntervalChange}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Samples to transmit"
                                value={samples}
                                onChange={onSamplesChange}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onAcceptClicked} color="primary" disabled={loading}>
                        {props.loading ? <CircularProgress size={24} /> : "Accept"}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default SetSensorParamsDialog