import gql from 'graphql-tag'

export const GET_TRAINING_SETS = gql`
    query GetTrainingSets($params: GetTrainingSetsRequest) {
        getTrainingSets(params: $params) {
            status
            trainingSets {
                _id
                createdAt
                name
                labelType
                dataType
                state
                expiredAt
            }
        }
    }
`

export const GET_TRAINING_SET_BY_ID = gql`
    query GetTrainingSetById($params: GetTrainingSetByIdRequest) {
        getTrainingSetById(params: $params) {
            status
            trainingSet {
                _id
                name
                labelType
                classes
            }
        }
    }
`

export const CREATE_TRAINING_SET_FROM_DATASET = gql`
    mutation CreateTrainingSetFromIndustrialDataset(
        $params: CreateTrainingSetFromIndustrialDatasetRequest
    ) {
        createTrainingSetFromIndustrialDataset(params: $params) {
            status
            trainingSet {
                name
            }
        }
    }
`

export const CREATE_TRAINING_SET = gql`
    mutation CreateTrainingSet($params: CreateTrainingSetRequest) {
        createTrainingSet(params: $params) {
            status
            trainingSet {
                name
            }
        }
    }
`

export const REMOVE_TRAINING_SET_BY_ID = gql`
    mutation RemoveTrainingSetById($params: RemoveTrainingSetByIdRequest) {
        removeTrainingSetById(params: $params) {
            status
            trainingSet {
                name
            }
        }
    }
`

export const EXPIRE_TRAINING_SET_BY_ID = gql`
    mutation ExpireTrainingSetById($params: ExpireTrainingSetByIdRequest!) {
        expireTrainingSetById(params: $params) {
            status
            payload
        }
    }
`

export const RECOVER_TRAINING_SET_BY_ID = gql`
    mutation RecoverTrainingSetById($params: RecoverTrainingSetByIdRequest!) {
        recoverTrainingSetById(params: $params) {
            status
            payload
        }
    }
`

export const UPDATE_TRAINING_SET_BY_ID = gql`
    mutation UpdateTrainingSetById($params: UpdateTrainingSetByIdRequest!) {
        updateTrainingSetById(params: $params) {
            status
            trainingSet {
                classes
            }
        }
    }
`

export const ADD_ENTRIES_TO_TRAINING_SET_FROM_DATASET = gql`
    mutation AddEntriesToTrainingSetFromDataset(
        $params: AddEntriesToTrainingSetFromDatasetRequest!
    ) {
        addEntriesToTrainingSetFromDataset(params: $params) {
            status
        }
    }
`

export const GET_ENTRIES_BY_TRAINING_SET = gql`
    query GetTrainingSetEntriesByTrainingSet($params: GetTrainingSetEntriesByTrainingSetRequest!) {
        getTrainingSetEntriesByTrainingSet(params: $params) {
            status
            count
            entries {
                properties {
                    name
                    value
                }
            }
        }
    }
`

export const CREATE_ENTRIES = gql`
    mutation CreateTrainingSetEntries($params: CreateTrainingSetEntriesRequest!) {
        createTrainingSetEntries(params: $params) {
            status
            responses {
                outputs {
                    name
                    value
                }
            }
        }
    }
`

export const GET_ENTRY_BY_ID = gql`
    query GetEntryById($params: GetTrainingSetEntryByIdRequest!) {
        getTrainingSetEntryById(params: $params) {
            status
            entry {
                properties {
                    name
                    value
                }
            }
        }
    }
`

export const UPDATE_ENTRY_BY_ID = gql`
    mutation UpdateEntryById($params: UpdateTrainingSetEntryByIdRequest!) {
        updateTrainingSetEntryById(params: $params) {
            status
            entry {
                properties {
                    name
                    value
                }
            }
        }
    }
`

export const REMOVE_ENTRY_BY_ID = gql`
    mutation RemoveEntryById($params: RemoveTrainingSetEntryByIdRequest!) {
        removeTrainingSetEntryById(params: $params) {
            status
        }
    }
`
