import gql from 'graphql-tag'

import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/react-hooks'

export const GET_DATASETS_V2_BY_PARENT = gql`
    query GetDatasetsV2ByParent($params: GetDatasetsV2ByParentRequest!) {
        getDatasetsV2ByParent(params: $params) {
            datasets {
                id
                name
                createdAt
            }
        }
    }
`

export const GET_DATASET_V2_BY_ID = gql`
    query GetDatasetV2ById($params: GetDatasetV2ByIdRequest!) {
        getDatasetV2ById(params: $params) {
            dataset {
                id
                archived
                name
                ttl
            }
        }
    }
`

export const DATASETS_SET_ARCHIVE = gql`
    mutation SetDatasetV2ArchiveById($params: SetDatasetV2ArchiveByIdRequest!) {
        setDatasetV2ArchiveById(params: $params) {
            status
        }
    }
`

export const DATASET_SET_TTL = gql`
    mutation SetDatasetV2TTLById($params: SetDatasetV2TTLByIdRequest!) {
        setDatasetV2TTLById(params: $params) {
            status
        }
    }
`

export const useGetDatasetsV2ByParent = (parent, archived = false) => {
    const { enqueueSnackbar } = useSnackbar()

    const { data: { getDatasetsV2ByParent: { datasets = [] } = {} } = {}, loading } = useQuery(
        GET_DATASETS_V2_BY_PARENT,
        {
            variables: {
                params: {
                    parent,
                    archived,
                },
            },
        }
    )

    useEffect(() => {
        if (loading) return

        if (datasets === null) {
            enqueueSnackbar('No se pudo obtener los datasets', {
                variant: 'error',
            })
        }
    }, [datasets, loading, enqueueSnackbar])

    return { datasets, loading }
}

export const useGetDatasetV2ById = (dataset) => {
    const { enqueueSnackbar } = useSnackbar()

    const { data: { getDatasetV2ById: { dataset: datasetData = {} } = {} } = {}, loading } =
        useQuery(GET_DATASET_V2_BY_ID, {
            variables: {
                params: {
                    id: dataset,
                },
            },
        })

    useEffect(() => {
        if (loading) return

        if (datasetData === null) {
            enqueueSnackbar('No se pudo obtener el dataset', {
                variant: 'error',
            })
        }
    }, [datasetData, loading, enqueueSnackbar])

    return { datasetData, loading }
}

export const useSetDatasetV2ArchiveById = ({
    parent = undefined,
    updateAllIds = true,
    updateById = true,
} = {}) => {
    const [mutate] = useMutation(DATASETS_SET_ARCHIVE)

    return async (dataset, archived) => {
        let refetchQueries = []
        if (updateAllIds) {
            refetchQueries.push({
                query: GET_DATASETS_V2_BY_PARENT,
                variables: {
                    params: {
                        parent,
                        archived: false,
                    },
                },
            })

            refetchQueries.push({
                query: GET_DATASETS_V2_BY_PARENT,
                variables: {
                    params: {
                        parent,
                        archived: true,
                    },
                },
            })
        }

        if (updateById) {
            refetchQueries.push({
                query: GET_DATASET_V2_BY_ID,
                variables: {
                    params: {
                        id: dataset,
                    },
                },
            })
        }

        await mutate({
            variables: {
                params: {
                    id: dataset,
                    archive: archived,
                },
            },
            refetchQueries,
            optimisticResponse: {
                __typename: 'Mutation',
                setDatasetV2ArchiveById: {
                    __typename: 'SetDatasetV2ArchiveByIdResponse',
                    status: 'SUCCEEDED',
                },
            },
            update: (
                cache,
                {
                    data: {
                        setDatasetV2ArchiveById: { status },
                    },
                }
            ) => {
                if (status !== 'SUCCEEDED') return

                if (updateById) {
                    let data
                    try {
                        data = cache.readQuery({
                            query: GET_DATASET_V2_BY_ID,
                            variables: {
                                params: {
                                    id: dataset,
                                },
                            },
                        })
                    } catch (e) {
                        data = null
                    }

                    if (!data) {
                        cache.writeQuery({
                            query: GET_DATASET_V2_BY_ID,
                            variables: {
                                params: {
                                    id: dataset,
                                },
                            },
                            data: {
                                ...data,
                                getDatasetV2ById: {
                                    ...data.getDatasetV2ById,
                                    dataset: {
                                        ...data.getDatasetV2ById.dataset,
                                        archived: !data.getDatasetV2ById.dataset.archived,
                                    },
                                },
                            },
                        })
                    }
                }

                if (updateAllIds) {
                    let data2
                    try {
                        data2 = cache.readQuery({
                            query: GET_DATASETS_V2_BY_PARENT,
                            variables: {
                                params: {
                                    parent,
                                    archived: false,
                                },
                            },
                        })
                    } catch (e) {
                        data2 = null
                    }

                    if (data2) {
                        const ind = data2.getDatasetsV2ByParent.datasets.findIndex(
                            (d) => d.id === dataset
                        )

                        if (ind !== -1) {
                            cache.writeQuery({
                                query: GET_DATASETS_V2_BY_PARENT,
                                variables: {
                                    params: {
                                        parent,
                                        archived: false,
                                    },
                                },
                                data: {
                                    ...data2,
                                    getDatasetsV2ByParent: {
                                        ...data2.getDatasetsV2ByParent,
                                        datasets: [
                                            ...data2.getDatasetsV2ByParent.datasets.slice(0, ind),
                                            {
                                                ...data2.getDatasetsV2ByParent.datasets[ind],
                                                archived:
                                                    !data2.getDatasetsV2ByParent.datasets[ind]
                                                        .archived,
                                            },
                                            ...data2.getDatasetsV2ByParent.datasets.slice(ind + 1),
                                        ],
                                    },
                                },
                            })
                        }
                    }

                    let data3

                    try {
                        data3 = cache.readQuery({
                            query: GET_DATASETS_V2_BY_PARENT,
                            variables: {
                                params: {
                                    parent,
                                    archived: true,
                                },
                            },
                        })
                    } catch (e) {
                        data3 = null
                    }

                    if (data3) {
                        const ind = data3.getDatasetsV2ByParent.datasets.findIndex(
                            (d) => d.id === dataset
                        )

                        if (ind !== -1) {
                            cache.writeQuery({
                                query: GET_DATASETS_V2_BY_PARENT,
                                variables: {
                                    params: {
                                        parent,
                                        archived: true,
                                    },
                                },
                                data: {
                                    ...data3,
                                    getDatasetsV2ByParent: {
                                        ...data3.getDatasetsV2ByParent,
                                        datasets: [
                                            ...data3.getDatasetsV2ByParent.datasets.slice(0, ind),
                                            {
                                                ...data3.getDatasetsV2ByParent.datasets[ind],
                                                archived:
                                                    !data3.getDatasetsV2ByParent.datasets[ind]
                                                        .archived,
                                            },
                                            ...data3.getDatasetsV2ByParent.datasets.slice(ind + 1),
                                        ],
                                    },
                                },
                            })
                        }
                    }
                }
            },
        })
    }
}

export const useSetDatasetV2TTLById = () => {
    const [mutate] = useMutation(DATASET_SET_TTL)

    return async (dataset, ttl) => {
        await mutate({
            variables: {
                params: {
                    id: dataset,
                    ttl,
                },
            },
            refetchQueries: [
                {
                    query: GET_DATASET_V2_BY_ID,
                    variables: {
                        params: {
                            id: dataset,
                        },
                    },
                },
            ],
            optimisticResponse: {
                __typename: 'Mutation',
                setDatasetV2TTLById: {
                    __typename: 'SetDatasetV2TTLByIdResponse',
                    status: 'SUCCEEDED',
                },
            },
            update: (
                cache,
                {
                    data: {
                        setDatasetV2TTLById: { status },
                    },
                }
            ) => {
                if (status !== 'SUCCEEDED') return

                const data = cache.readQuery({
                    query: GET_DATASET_V2_BY_ID,
                    variables: {
                        params: {
                            id: dataset,
                        },
                    },
                })

                cache.writeQuery({
                    query: GET_DATASET_V2_BY_ID,
                    variables: {
                        params: {
                            id: dataset,
                        },
                    },
                    data: {
                        ...data,
                        getDatasetV2ById: {
                            ...data.getDatasetV2ById,
                            dataset: {
                                ...data.getDatasetV2ById.dataset,
                                ttl,
                            },
                        },
                    },
                })
            },
        })
    }
}
