export const OPEN_DIALOG = "OPEN_DIALOG"
export const CLOSE_DIALOG = "CLOSE_DIALOG"

export const openDialog = () => ({ type: OPEN_DIALOG })
export const closeDialog = () => ({ type: CLOSE_DIALOG })

export default (state = { open: false }, action) => {
    switch(action.type) {
        case OPEN_DIALOG:
            return { ...state, open: true }
        case CLOSE_DIALOG:
            return {...state, open: false}
        default:
            return state
    }
}