import React, { useEffect, useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Paper from '@material-ui/core/Paper'

import StepMonitorName from './step-monitor-name/scene'
import StepMonitorType from './step-monitor-type/scene'
import StepMonitorSchedule from './step-monitor-schedule/scene'
import StepMonitorConfiguration from './step-monitor-configuration/scene'
import StepMonitorThresholds from './step-monitor-thresholds/scene'
import StepMonitorIncidentGroups from './step-monitor-actions/scene'

import { CREATE_MONITOR } from '../../queries/monitors'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

const CreateMonitor = (props) => {
    const classes = useStyles()
    const history = useHistory()

    const [activeStep, setActiveStep] = useState(0)
    const [properties, setProperties] = useState({})
    const [loading, setLoading] = useState(false)

    const [createMonitor] = useMutation(CREATE_MONITOR)

    const steps = [
        {
            label: 'Nombre',
            property: 'name',
            component: StepMonitorName,
        },
        {
            label: 'Seleccionar el tipo de monitor',
            property: 'type',
            component: StepMonitorType,
        },
        {
            label: 'Horario de trabajo',
            property: 'schedule',
            component: StepMonitorSchedule,
        },
        {
            label: 'Configuración del monitor',
            property: 'trigger',
            component: StepMonitorConfiguration,
        },
        {
            label: 'Umbrales',
            property: 'thresholds',
            component: StepMonitorThresholds,
        },
        {
            label: 'Acciones',
            property: 'actions',
            component: StepMonitorIncidentGroups,
        },
    ]

    
    const onPropertyChange = (property, value) => {
        console.log(property, value)
        setProperties({ ...properties, [property]: value })
    }

    const onSetStep = async step => {
        setActiveStep(step)
    }

    useEffect(() => {

        const update = async () => {
            setLoading(true)

            try {
                console.log(properties)
                await createMonitor({
                    variables: {
                        params: {
                            name: properties.name,
                            type: properties.type,
                            configuration: JSON.stringify(properties)
                        }
                    }
                })

                history.push('../monitors')
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        if (activeStep === steps.length) {
            update()
        }
    }, [activeStep])


    return (
        <Container>
            <Grid container>
                <Grid item xs={12} className={classes.header}>
                    <h1>Crear un Monitor</h1>
                </Grid>
                <Grid item xs={12} className={classes.stepper}>
                    <Paper>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => {
                                const Component = step.component
                                return (
                                    <Step key={index}>
                                        <StepLabel>{step.label}</StepLabel>
                                        <StepContent>
                                            <Component
                                                property={step.property}
                                                onPropertyChange={onPropertyChange}
                                                setActiveStep={onSetStep}
                                                value={properties[step.property]}
                                                properties={properties}
                                                nextStep={index + 1}
                                                prevStep={index - 1}
                                            />
                                        </StepContent>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CreateMonitor
