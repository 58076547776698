import React, { useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ListItemIcon } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DialogTitle from '@mui/material/DialogTitle'

import { removeVirtualColumn, addVirtualColumn } from '../../reducers/index'
import { Operations } from '../spreadsheet/operations/index'

const FromTypes = {
    operation: Operations,
}

/**
 * Component responsible of virtual column info row within manage
 * dialog
 */
const VirtualColumnInfo = ({ name, type }) => {
    const dispatch = useDispatch()

    const onDelete = () => {
        dispatch(removeVirtualColumn([name]))
    }
    return (
        <ListItem>
            <ListItemText primary={name} secondary={type} />
            <ListItemIcon>
                <Tooltip title={<Typography fontSize={11}>Eliminar</Typography>}>
                    <IconButton>
                        <DeleteIcon fontSize="large" onClick={onDelete} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={11}>Editar</Typography>}>
                    <IconButton disabled>
                        <EditIcon fontSize="large" />
                    </IconButton>
                </Tooltip>
            </ListItemIcon>
        </ListItem>
    )
}
VirtualColumnInfo.propTypes = {
    /** Name of the column */
    name: PropTypes.string.isRequired,
    /** Type of the column */
    type: PropTypes.string.isRequired,
}

/**
 * Component that manage the add column dialog
 */
const VirtualColumnAdd = ({ open, getBack, columnsList }) => {
    const dispatch = useDispatch()

    const [name, setName] = useState(null)

    const dataRef = useRef({})

    const [newColumnFrom, setNewColumnFrom] = useState('otherColumn')

    const onExitClick = () => {
        dataRef.current = {}
        setName(null)
        getBack()
    }

    const onSaveClick = () => {
        dispatch(
            addVirtualColumn([
                {
                    name,
                    data: {
                        from: newColumnFrom,
                        data: { ...dataRef.current, data: dataRef.current.data.current },
                    },
                },
            ])
        )
        onExitClick()
    }

    const onNameChange = (event) => {
        setName(event.target.value)
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Añadir columna virtual</DialogTitle>
            <DialogContent>
                <TextField
                    label="Nombre"
                    value={name}
                    onChange={onNameChange}
                    fullWidth
                    variant="standard"
                />
                <FormControl fullWidth>
                    <InputLabel>Desde</InputLabel>
                    <Select
                        value={newColumnFrom}
                        onChange={(event) => {
                            setNewColumnFrom(event.target.value)
                        }}
                    >
                        <MenuItem value="otherColumn">Otra columna</MenuItem>
                        <MenuItem value="operation">Operación</MenuItem>
                    </Select>
                </FormControl>
                {React.createElement(
                    newColumnFrom === 'otherColumn' ? () => null : FromTypes[newColumnFrom],
                    { columnsList, dataContainer: dataRef },
                    null
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onExitClick}>Salir</Button>
                <Button onClick={onSaveClick}>Guardar</Button>
            </DialogActions>
        </Dialog>
    )
}

VirtualColumnAdd.propTypes = {
    open: PropTypes.bool.isRequired,
    getBack: PropTypes.func.isRequired,
    columnsList: PropTypes.object.isRequired,
}

/**
 * Component that manage the virtual column dialog
 */
const VirtualColumnManager = ({ columnsList }) => {
    const virtualColumns = useSelector((state) => state.datasets.viewer.virtualColumns || {})

    const columnsByKey = columnsList.reduce((prev, next) => {
        return {
            ...prev,
            [next.name]: next.type,
        }
    }, {})
    const columnsRealAndVirtualByKey = {
        ...columnsByKey,
        ...Object.entries(virtualColumns).reduce((prev, next) => {
            return { ...prev, [next[0]]: next[1].data.type }
        }, {}),
    }

    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)

    const onIconButtonClicked = () => {
        setOpen(true)
    }

    const onAddClick = () => {
        setOpen(false)
        setOpenAdd(true)
    }

    const getBack = () => {
        setOpenAdd(false)
        setOpen(true)
    }

    const onCancelClicked = () => {
        setOpen(false)
    }

    const renderContent = () => {
        return (
            <React.Fragment>
                <Dialog open={open}>
                    <DialogTitle>Columnas Virtuales</DialogTitle>
                    <DialogContent>
                        <Paper>
                            <List>
                                {Object.entries(virtualColumns).map((virColumn) => (
                                    <VirtualColumnInfo
                                        key={virColumn[0]}
                                        name={virColumn[0]}
                                        type={virColumn[1].from}
                                    />
                                ))}
                            </List>
                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCancelClicked}>Cancelar</Button>
                        <Button onClick={onAddClick}>Añadir</Button>
                    </DialogActions>
                </Dialog>
                <VirtualColumnAdd
                    open={openAdd}
                    getBack={getBack}
                    columnsList={columnsRealAndVirtualByKey}
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Tooltip title={<Typography fontSize={11}>Columnas virtuales</Typography>}>
                <IconButton onClick={onIconButtonClicked}>
                    <ViewColumnIcon fontSize="large" />
                </IconButton>
            </Tooltip>
            {renderContent()}
        </React.Fragment>
    )
}

VirtualColumnManager.propTypes = {
    /** Array with the fields of the dataset */
    columnsList: PropTypes.array.isRequired,
}

export default VirtualColumnManager
