import React, { useState } from "react"

import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import AddIcon from '@material-ui/icons/Add'

import { UPDATE_MONITOR, GET_MONITOR_BY_ID } from '../../../../../../queries/monitors'

const Scene = (props) => {

    const { monitor } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = props.config || {}

    const [ open, setOpen ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ name, setName ] = useState("")
    const [ value, setValue ] = useState(0)

    const [ updateMonitor ] = useMutation(UPDATE_MONITOR)

    const onAdd = () => {
        setOpen(true)
    }

    const onAccept = async () => {
        setLoading(true)

        try {
            await updateMonitor({
                variables: {
                    params: {
                        id: monitor,
                        configuration: JSON.stringify({
                            ...config,
                            thresholds: [
                                ...config.thresholds,
                                {
                                    name: name,
                                    value: value
                                }
                            ]
                        })
                    }
                },
                refetchQueries: [
                    {
                        query: GET_MONITOR_BY_ID,
                        variables: {
                            params: {
                                id: monitor
                            }
                        }
                    }
                ]
            })
            enqueueSnackbar("Umbral agregado", { variant: "success" })
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" })
        } finally {
            setLoading(false)
            setOpen(false)
        }
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onChangeName = (event) => {
        setName(event.target.value)
    }

    const onChangeValue = (event) => {
        setValue(event.target.value)
    }

    return (
        <React.Fragment>
            <Fab 
                color="primary" 
                onClick={onAdd}
                style={{ position: "absolute", bottom: 20, right: 20 }}
            >
                <AddIcon />
            </Fab>
            <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Agregar un umbral</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                label="Nombre"
                                fullWidth
                                variant="outlined"
                                value={name}
                                onChange={onChangeName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                label="Umbral"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={value}
                                onChange={onChangeValue}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary" disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAccept} color="primary" disabled={loading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Scene