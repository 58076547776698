import React, { useState, useEffect } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams, Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import EditIcon from '@material-ui/icons/Edit'

import { UPDATE_INCIDENT_GROUP, GET_INCIDENT_GROUP_BY_ID } from '../../../../queries/groups'

import { useIsAllowed } from 'queries/permissions'

const Name = (props) => {
    const { group } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [open, setOpen] = useState(false)
    const [name, setName] = useState(props.name)
    const [loading, setLoading] = useState(false)

    const { isAllowed: isEditAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'updateIncidentGroup',
    })
    const [updateIncidentGroup] = useMutation(UPDATE_INCIDENT_GROUP)

    const onOpen = () => {
        setOpen(true)
    }

    const onAccept = async () => {
        setLoading(true)

        try {
            await updateIncidentGroup({
                variables: {
                    params: {
                        id: group,
                        name,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_INCIDENT_GROUP_BY_ID,
                        variables: {
                            params: {
                                id: group,
                            },
                        },
                    },
                ],
            })
            enqueueSnackbar('Grupo de incidentes actualizado', { variant: 'success' })
            setOpen(false)
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const onCancel = () => {
        setOpen(false)
        setName(props.name)
    }

    const onNameChange = (event) => {
        setName(event.target.value)
    }

    useEffect(() => {
        setName(props.name)
    }, [props.name])

    return (
        <React.Fragment>
            <ListItem>
                <ListItemText primary="Nombre" secondary={props.name} />
                {isEditAllowed && (
                    <ListItemSecondaryAction>
                        <IconButton onClick={onOpen}>
                            <EditIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Dialog open={open} maxWidth="sm" fullWidth>
                <DialogTitle>Editar nombre</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        label="Nombre"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={name}
                        onChange={onNameChange}
                        disabled={loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAccept} disabled={loading}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Name
