import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import TextField from '@material-ui/core/TextField'

/**
 * Component to render float parameter type
 * Is used for creating new parameter and update its value
 * once created.
 */
const ParamTypeFloat = ({ value, setValue }) => {
    const [localValue, setLocalValue] = useState(value.toString())
    const onValueChanged = (event) => {
        setLocalValue(event.target.value)
        setValue(parseFloat(event.target.value))
    }
    return <TextField label="Valor" value={localValue} onChange={onValueChanged} fullWidth />
}

ParamTypeFloat.propTypes = {
    /** Initial value and value linked with parent states */
    value: PropTypes.number.isRequired,
    /** Function to set the parent state */
    setValue: PropTypes.func.isRequired,
}

export default ParamTypeFloat
