import { combineReducers } from 'redux'

import { reducer as reducerGeneral, initialState as initialStateGeneral } from './general'
import { reducer as reducerImages, initialState as initialStateImages } from './images'

export default {
    id: 'trainingSetSegmentation',
    initialState: {
        general: initialStateGeneral,
        images: initialStateImages,
    },
    reducerMap: {
        trainingSetSegmentation: combineReducers({
            general: reducerGeneral,
            images: reducerImages,
        }),
    },
}
