import React, { useState, useEffect } from "react"

import { useHotkeys } from "react-hotkeys-hook"
import { useParams, useHistory } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import Grid from "@material-ui/core/Grid"
import TablePagination from '@material-ui/core/TablePagination';
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import { GET_CLASSIFICATION_ENTRIES } from "../../queries/classification-entries"
import { SELECTED_IMAGE, SET_SELECTED_IMAGE } from "../../queries/state"

const rowsPerPage = 100

function ImagesMenu(props) {

    const history = useHistory()

    const { trainingSet, page = 0 } = useParams()

    const { data: { getClassificationEntries: { classificationEntries = [] } = {}} = {}} = useQuery(GET_CLASSIFICATION_ENTRIES, { 
        variables: { 
            params: {
                trainingSet,
                offset: page * rowsPerPage,
                first: rowsPerPage  
            }
        }
    })
    const { data: { labelerSelectedImage } = {}} = useQuery(SELECTED_IMAGE)
    const [ setLabelerSelectedImage ] = useMutation(SET_SELECTED_IMAGE)
    
    const [ goPage, setGoPage ] = useState()

    const onImageClicked = _id => () => {
        setLabelerSelectedImage({
            variables: {
                params: {
                    _id
                }
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        history.push(`${page ? "." : trainingSet}/${newPage}`)
    }

    const onGoPageChange = event => setGoPage(event.target.value)
    const onGoClicked = () => handleChangePage(null, goPage)

    const onNextImage = () => {
        const index1 = classificationEntries.findIndex((entry) => entry._id === labelerSelectedImage)
        setLabelerSelectedImage({ 
            variables: {
                params: {
                    _id: classificationEntries[index1 + 1]._id
                }
            }
        })
    }
    const onPreviousImage = () => {
        const index1 = classificationEntries.findIndex((entry) => entry._id === labelerSelectedImage)
        setLabelerSelectedImage({ 
            variables: {
                params: {
                    _id: classificationEntries[index1 - 1]._id
                }
            }
        })
    }

    useHotkeys("d", onNextImage, [ labelerSelectedImage,  classificationEntries ])
    useHotkeys("a", onPreviousImage, [ labelerSelectedImage, classificationEntries ])

    return (
        <div style={{ height: "35vh", boxShadow: "0 0 10px -2px #333333AA" }}>
            <div style={{ height: "calc(100% - 57px)", overflowY: "scroll", background: "#EEE", padding: 5 }}>
                <Grid container>
                    {
                        classificationEntries.map((image, i) => (
                            <Grid xs={6} sm={4} md={3} lg={2} xl={1}>
                                <div style={{ display: "inline-block", padding: 1 }}>
                                    <img 
                                        src={image.url} 
                                        width={"100%"}
                                        onClick={onImageClicked(image._id)} 
                                        style={{
                                            border: labelerSelectedImage === image._id ? "5px solid #f00" : "unset", 
                                            borderRadius: 5
                                        }}
                                    />
                                </div>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
            <Grid container alignItems="center">
                <Grid item xs={8}>
                </Grid>
                <Grid container xs={2} alignItems="center" justify="flex-end">
                    <Grid item xs={6}>
                        <TextField label="Página" onChange={onGoPageChange} value={goPage} type="number" step="1"/>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={onGoClicked}>Ir</Button>
                    </Grid>
                </Grid>
                <Grid container xs={2} alignItems="center" justify="flex-end">
                    <TablePagination
                        rowsPerPageOptions={[ rowsPerPage ]}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        page={parseInt(page)}
                        onChangePage={handleChangePage}
                    />
                </Grid>  
            </Grid>
        </div>
    )
}

export default ImagesMenu