import React, { useMemo } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

import SettingsIcon from '@material-ui/icons/Settings'

import Inferencing from './containers/inferencing/scene'
import Dataset from './containers/dataset/scene'

const Scene = () => {
    const [open, setOpen] = React.useState(false)
    const [selectedTab, setSelectedTab] = React.useState('Inferencing')

    const onIconClick = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onTabChange = (newValue) => {
        setSelectedTab(newValue)
    }

    const { tabsById, allTabIds } = useMemo(() => {
        const t = [
            {
                label: 'Inferencing',
                component: Inferencing,
            },
            {
                label: 'Dataset',
                component: Dataset,
            },
        ]

        return {
            allTabIds: t.map((tab) => tab.label),
            tabsById: t.reduce((acc, tab) => {
                acc[tab.label] = tab
                return acc
            }, {}),
        }
    }, [])

    const renderTab = () => {
        const currentTab = tabsById[selectedTab]
        if (!currentTab) {
            return null
        }

        const TabComponent = currentTab.component

        return <TabComponent />
    }

    return (
        <React.Fragment>
            <IconButton onClick={onIconClick}>
                <SettingsIcon />
            </IconButton>
            <Dialog open={open} maxWidth="md" fullWidth>
                <DialogTitle>Settings</DialogTitle>
                <Divider />
                <Grid container style={{ height: 600, width: '100%' }}>
                    <Grid item xs={3} style={{ borderRight: '1px solid #EEE' }}>
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            orientation="vertical"
                        >
                            {allTabIds.map((tabId) => {
                                const t = tabsById[tabId]
                                return (
                                    <Tab
                                        key={tabId}
                                        label={t.label}
                                        value={t.label}
                                        onClick={() => onTabChange(t.label)}
                                    />
                                )
                            })}
                        </Tabs>
                    </Grid>
                    <Grid item xs={9} style={{ height: '100%', overflowY: 'auto' }}>
                        {renderTab()}
                    </Grid>
                </Grid>
                <Divider />
                <DialogActions>
                    <Button onClick={onClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Scene
