import gql from 'graphql-tag'

export const CREATE_DEVICES_DIALOG = gql`
    query {
        createDevicesDialog @client {
            open
        }
    }
`

export const GET_DEVICES_BY_GROUP = gql`
    query GetDevicesByGroup($params: GetDevicesByGroupRequest!) {
        getDevicesByGroup(params: $params) {
            status
            devices {
                _id
                name
            }
        }
    }
`

export const OPEN_CREATE_DEVICES_DIALOG = gql`
    mutation {
        openCreateDevicesDialog @client
    }
`

export const CLOSE_CREATE_DEVICES_DIALOG = gql`
    mutation {
        closeCreateDevicesDialog @client
    }
`

export const CREATE_DEVICE = gql`
    mutation CreateDevice($params: CreateDeviceRequest!) {
        createDevice(params: $params) {
            status
        }
    }
`

export const REMOVE_DEVICE = gql`
    mutation RemoveDevice($params: RemoveDeviceRequest!) {
        removeDevice(params: $params) {
            status
        }
    }
`
