import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import TextField from "@material-ui/core/TextField"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import LoadingDialog from "components/loading-dialog"

import { GET_MODELS } from "../../../../../../../../../models/queries/models"
import { CREATE_RESOURCE, GET_RESOURCES } from "../../../../../../../../queries/resources"

function MachineLearning(props) {
    const { group } = useParams()

    const [ name, setName ] = useState("")
    const [ model, setModel ] = useState(null)
    const [ loading, setLoading ] = useState(false)

    const { data: { getModels: { models = [] } = {}} = {}} = useQuery(GET_MODELS)
    const [ createResource ] = useMutation(CREATE_RESOURCE)

    const onAccept = async () => {
        setLoading(true)
        await createResource({
            variables: { 
                params: {
                    group,
                    name,
                    type: "machine-learning",
                    config: JSON.stringify({ model })
                }
            },
            refetchQueries: [
                {
                    query: GET_RESOURCES,
                    variables: {
                        params: {
                            group
                        }
                    }
                }
            ]
        })
        setLoading(false)
    }

    const onModelChanged = event => {
        setModel(event.target.value)
    }

    const onNameChanged = event => {
        setName(event.target.value)
    }

    return (
        <React.Fragment>
            <DialogContent>
                <TextField label="Nombre" fullWidth value={name} onChange={onNameChanged}/>
                <FormControl fullWidth>
                    <InputLabel>Modelo</InputLabel>
                    <Select onChange={onModelChanged} value={model}>
                        {models.filter(model => model.state === "SUCCEEDED").map(model => <MenuItem value={model._id}>{model._id}</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={props.onCancel}>Cancelar</Button>
                <Button disabled={loading} onClick={onAccept}>Aceptar</Button>
            </DialogActions>
        </React.Fragment>
    )
}

export default MachineLearning
