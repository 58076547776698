import React from 'react'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from 'react-apollo'
import { Switch, Route, Redirect } from 'react-router-dom'

import Authentication from './containers/authentication/scene'

import Config from '../../../config'

console.log(Config.app.backend.url)
function Public(props) {
    const { match, location } = props

    const client = new ApolloClient({
        link: createHttpLink({
            uri: `${Config.app.backend.url}/users/public`,
        }),
        cache: new InMemoryCache({ dataIdFromObject: (o) => o._id }),
    })

    return (
        <ApolloProvider client={client}>
            <Switch>
                <Redirect exact from="/public" to={'/public/auth' + location.hash} />
                <Route path={match.url + '/auth'} component={Authentication} />
            </Switch>
        </ApolloProvider>
    )
}

export default Public
