import React, { useState } from "react"

import { useMutation } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'


import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'

import EditIcon from '@material-ui/icons/Edit'

import { UPDATE_MONITOR, GET_MONITOR_BY_ID } from '../../../../../../queries/monitors'

import EditValueThresholdDialog from './containers/value-threshold-row/scene'
import EditRangeThresholdDialog from './containers/range-threshold-row/scene'

const thresholdRows = {
    "more-than": {
        component: EditValueThresholdDialog
    },
    "less-than": {
        component: EditValueThresholdDialog
    },
    "range": {
        component: EditRangeThresholdDialog
    }
}

const Scene = (props) => {

    const history = useHistory()

    const config = props.config || {}
    const thresholds = config.thresholds || []
    const trigger = config.trigger || {}
    const type = trigger.type || "more-than"

    
    const renderRow = (threshold, index) => {
        const thresholdRow = thresholdRows[type]
        if(!thresholdRow) {
            return null
        }

        const ThresholdRow = thresholdRow.component
        if(!ThresholdRow) {
            return null
        }

        return (
            <ThresholdRow config={config} index={index} threshold={threshold} />
        )
    }

    return  thresholds.map(renderRow)
}

export default Scene
    