import React, { useState } from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { useSelector, useDispatch } from "react-redux"
import { useParams, Link } from "react-router-dom"
import { withSnackbar } from "notistack"

import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContentText from "@material-ui/core/DialogContentText"

import LoadingButton from "components/loading-button"

import { closeAnalyseDialog } from "../../../../reducers/general"

import { GET_JOB_DEFINITIONS, CREATE_JOB } from "../../queries/jobs"

const options = [
    {
        id: "hotspots-medium-accuracy",
        name: {
            es: "Análisis de Puntos Calientes - Precisión Media"
        },
        message: "Hasta 10000 imágenes tiempo estimado <20min"
    },
    {
        id: "pv-high",
        name: {
            es: "Análisis de Puntos Calientes - Precisión Alta"
        },
        message: "Hasta 10000 imágenes tiempo estimado <1h"
    }
]

function AnalyseDialog(props) {

    const dispatch = useDispatch()

    const { inspection } = useParams()

    const [ selected, setSelected ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ selectedError, setSelectedError ] = useState(null)

    const { analyse_dialog_open } = useSelector(state => state.inspector.solar.general)

    const { data: { getJobDefinitions: { jobDefinitions = [] } = {}} = {}} = useQuery(GET_JOB_DEFINITIONS, { variables: { params: {}}})
    const [ createJob ] = useMutation(CREATE_JOB)

/*     const options = jobDefinitions
 */
    const onCancelRequest = () => {
        dispatch(closeAnalyseDialog())
    }

    const onSelectedChanged = (event) => {
        setSelected(event.target.value)
    }

    const onAccept = async () => {
        if(!selected) {
            setSelectedError(true)
            return
        } else {
            setSelectedError(false)
        }
        try {
            setLoading(true)
            await createJob({
                variables: {
                    params: {
                        parent: inspection,
                        jobDefinition: selected
                    }
                }
            })
            dispatch(closeAnalyseDialog())
            props.enqueueSnackbar("El análisis se ha iniciado con éxito", { variant: "success" })
        } catch(error) {
            props.enqueueSnackbar("Se ha producido un error", { variant: "error" })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog open={analyse_dialog_open} PaperProps={{ style: { minWidth: 350 } }}>
            <DialogTitle>Iniciar Análisis</DialogTitle>
            <DialogContent>
                { options.length > 0 ?
                    <FormControl fullWidth disabled={loading} error={selectedError}>
                        <InputLabel>Tarea</InputLabel>
                        <Select value={selected} onChange={onSelectedChanged}>
                            { 
                                options.map((option) => <MenuItem value={option.id}>{option.name.es}</MenuItem>)
                            }
                        </Select>
                        <FormHelperText>
                            Esta operación incurrirá en costes. Puede ver los precios <a href="https://flightlabs.es/productos/horus/solar#analysis-pricing" target="_blank">aquí.</a>
                        </FormHelperText>
                    </FormControl>:
                    <DialogContentText>
                        <Typography>Tu subscripción no incluye ningún análisis. Puedes añadirlo <Link to="/private/store/horus">aquí.</Link></Typography>
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={onCancelRequest}>Cancelar</Button>
                <LoadingButton loading={loading} onClick={onAccept} disabled={!selected}>Aceptar</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default withSnackbar(AnalyseDialog)