import React from "react"


const Default = () => {
    return (
        <div>
            test
        </div>
    )
}

export default Default