import React, { Component } from "react"

import { Link, useParams, useHistory, useLocation } from "react-router-dom"

import Container from "@material-ui/core/Container"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from "@material-ui/core/Divider"

import Subscriptions from "./containers/subscriptions/scene"
import Invoices from "./containers/invoices/scene"
import PaymentMethods from "./containers/payment-methods/scene"

import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/styles"

const Account = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const { section } = useParams()
    
    const { t } = useTranslation("private")
    
    const tabs = [
        { value: "subscriptions", name: t("account.subscriptions.title"), path: "subscriptions", component: Subscriptions},
        { value: "invoices", name: t("account.invoices.title"), path: "invoices", component: Invoices  },
        { value: "payment-methods", name: t("account.payment_methods.title"), path: "payment-methods", component: PaymentMethods }
    ]
    
    const valueMap = tabs.reduce((acc, tab, i) => ({ ...acc, [tab.value]: i }), [])
    
    const getValue = (params) => {
        return valueMap[params]
    }

    if(!section) {
        history.replace(props.match.url + "/subscriptions")
        return null
    }

    const index = getValue(section)
    const Section = tabs[index].component
    return (
        <div className={classes.root}>
            <Container className={classes.content}>
                <Tabs value={index}>
                    {tabs.map((tab, i) => (
                        <Link to={tab.path} >
                            <Tab label={tab.name} />
                        </Link>    
                    ))}
                </Tabs>
                <Divider className={classes.tabs} />
                <Section/>
            </Container>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        background: "#F5F5F5",
        minHeight: "100vh"
    },
    content: {
        flexGrow: 1,
        minWidth: 0, 
        padding: "20px 25px"
    },
    tabs: {
        marginBottom: 20 
    }
}))

export default Account