import React, { useState, useEffect } from "react"

import { Frame, Element, useEditor } from "@craftjs/core";

import Container from "../../../../components/container"

export default (props) => {
    const { actions, query } = useEditor()

    useEffect(() => {
        setTimeout(() => {
            actions.deserialize(query.serialize())
        }, 1000)
    }, [])

    return (
        <Frame style={{ height: "calc(100% - 48px)", width: "100%" }}>
            <Element is={Container} canvas>
            </Element>
        </Frame>
    )
}