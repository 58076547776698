import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/react-hooks"
import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

import Fab from '@material-ui/core/Fab';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField"

import AddIcon from '@material-ui/icons/Add';

import { CREATE_DATASET } from "../../queries/datasets"

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
      position: "absolute",
      bottom: 15,
      left: 75
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));

const CreateDatasetDialog = (props) => {
    const classes = useStyles();

    const { survey } = useParams()

    const [ open, setOpen ] = useState(false)
    const [ name, setName ] = useState(null)
    const [ type, setType ] = useState(null)
    const [ loading, setLoading ] = useState(false)

    const [ createDataset ] = useMutation(CREATE_DATASET)

    const onOpenClick = () => {
        setOpen(true)
    }

    const onSetType = (e) => {
        setType(e.target.value)
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onAccept = async () => {
        try {
            setLoading(true)

            await createDataset({ variables: { params: { name, type, parent: survey }}})    
        } catch(err) {

        } finally {
            setLoading(false)
        }
    }

    const onNameChange = e => {
        setName(e.target.value)
    }

    return (
        <React.Fragment>
            <Dialog open={open}>
                <DialogContent>
                    <TextField fullWidth label="Name" value={name} onChange={onNameChange} disabled={loading}></TextField>
                    <FormControl fullWidth disabled={loading}>
                        <InputLabel>Data Type</InputLabel>
                        <Select value={type} onChange={onSetType}>
                            <MenuItem value={null}></MenuItem>
                            <MenuItem value={"rgb"}>RGB Images</MenuItem>
                            <MenuItem value={"geojson"}>GeoJSON</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} disabled={loading}>Cancel</Button>
                    <Button disabled={!type} onClick={onAccept} disabled={loading}>Accept</Button>
                </DialogActions>
            </Dialog>
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
                onClick={onOpenClick}
            >
                <AddIcon className={classes.extendedIcon} />
                Create Dataset
            </Fab>
        </React.Fragment>
    )
}

export default CreateDatasetDialog