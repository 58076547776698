import gql from "graphql-tag"

export const VISIBILITY = gql`
    query {
        visibility @client {
            radiometric
            hotspots
            orthophotos
        }
    }
`

export const SET_VISIBILITY = gql`
    mutation SetVisibility($type: String, $value: Boolean) {
        setVisibility(type: $type, value: $value) @client
    }

`

export const EXPORT_CSV_DIALOG = gql`
    query {
        exportCsvDialog @client {
            open
        }
    }
`

export const OPEN_EXPORT_CSV_DIALOG = gql`
    mutation {
        openExportCsvDialog @client
    }
`

export const CLOSE_EXPORT_CSV_DIALOG = gql`
    mutation {
        closeExportCsvDialog @client 
    }
`