import gql from "graphql-tag"


export const GET_ACCESS_TOKEN = gql`
    mutation GetAccessToken($params: GetAccessTokenRequest!) {
        getAccessToken(params: $params) {
            status
            idToken
            accessToken
        }
    }
`