import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import ImageSearchIcon from '@material-ui/icons/ImageSearch'
import AndroidIcon from '@material-ui/icons/Android'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import AlarmIcon from '@material-ui/icons/Alarm'
import CodeIcon from '@material-ui/icons/Code'
import MergeTypeIcon from '@material-ui/icons/MergeType'
import Crop169Icon from '@material-ui/icons/Crop169'
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna'
import IosShareIcon from '@mui/icons-material/IosShare'
import InputIcon from '@mui/icons-material/Input'
import SensorsIcon from '@mui/icons-material/Sensors'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import SpellcheckIcon from '@mui/icons-material/Spellcheck'
import CampaignIcon from '@mui/icons-material/Campaign'
import UploadIcon from '@mui/icons-material/Upload'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import AnnouncementIcon from '@mui/icons-material/Announcement'
import RuleIcon from '@mui/icons-material/Rule'

import ObjectDetectionIcon from './nodes/object-detection-v3/objectDetectionNodeSvg.svg'
import OracleSQLIcon from './nodes/oracle-sql/oracleSql.svg'
import SqlServerIcon from './nodes/sql-server/sqlServerIcon.svg'
import CobotEdgeDetectorIcon from './nodes/cobot-edge-detector/cobotEdgeDetectorIcon.svg'
import CobotPulseIcon from './nodes/cobot-pulse/cobotPulseIcon.svg'
import ConveyorIcon from './nodes/conveyor/conveyorIcon.svg'
import RemoveBackgroundIcon from './nodes/remove-background/removeBackgroundIcon.svg'
import ClassifierIcon from './nodes/classifier/classifierIcon.svg'
import RotateIcon from './nodes/rotate/rotateIcon.svg'
import SwitchColorIcon from './nodes/switch-color/switchColorIcon.svg'
import InRangeIcon from './nodes/in-range/inRangeIcon.svg'
import { removeNode } from '../../redux/nodes'

// FIXME: the names must be synchronized with the other lists.
//        Is better to only have one source of truth
const icons = {
    alarm: () => <NotificationsActiveIcon fontSize="large" />,
    'anomaly-detection': () => <CampaignIcon fontSize="large" />,
    bacNetDevice: SensorsIcon,
    barcode: () => <QrCode2Icon fontSize="large" />,
    'camera-usb3-vision': CameraAltIcon,
    classifier: () => <ClassifierIcon height={35} width={35} />,
    'classifier-v2': () => <ClassifierIcon height={35} width={35} />,
    'classifier-v3': () => <ClassifierIcon height={35} width={35} />,
    clock: AlarmIcon,
    'cobot-edge-detector': () => <CobotEdgeDetectorIcon height={35} width={35} />,
    'cobot-pulse': () => <CobotPulseIcon height={35} width={35} />,
    conveyor: () => <ConveyorIcon height={35} width={35} />,
    counter: AlarmIcon,
    crop: () => <PictureInPictureIcon fontSize="large" />,
    Dataset: ImageSearchIcon,
    delay: FastRewindIcon,
    Detector: () => <ObjectDetectionIcon height={35} width={35} />,
    EdgeDetector: SettingsInputComponentIcon,
    'hmi-button': Crop169Icon,
    if: () => <RuleIcon fontSize="large" />,
    javascript: CodeIcon,
    join: MergeTypeIcon,
    'lora-server': SettingsInputAntennaIcon,
    'object-detection-v3': () => <ObjectDetectionIcon height={35} width={35} />,
    ocr: AndroidIcon,
    ocr2: () => <SpellcheckIcon fontSize="large" />,
    ocrPower: () => <SpellcheckIcon fontSize="large" />,
    'on-parameter-change': () => <AnnouncementIcon fontSize="large" />,
    'oracle-sql': () => <OracleSQLIcon height={35} width={35} />,
    Pulse: SettingsInputComponentIcon,
    'remove-background': () => <RemoveBackgroundIcon height={35} width={35} />,
    rotate: () => <RotateIcon height={35} width={35} />,
    'sql-server': () => <SqlServerIcon height={35} width={35} />,
    'tcp-receive': InputIcon,
    'tcp-send': IosShareIcon,
    upload: () => <UploadIcon fontSize="large" />,
    uploader: ImageSearchIcon,
    'switch-color': () => <SwitchColorIcon height={35} width={35} />,
    'in-range': () => <InRangeIcon height={35} width={35} />,
}

// FIXME: This component seems that is renderized four times when added a new node
const NodeInner = ({ node }) => {
    const dispatch = useDispatch()

    const onDeleteClicked = () => {
        dispatch(removeNode(node.id))
    }

    const CurrentIcon = icons[node.type] || CameraAltIcon

    return (
        <div style={{ padding: '10px 2px 10px 20px', maxWidth: 350, fontSize: 12 }}>
            <Grid container direction="row" alignItems="center">
                <Grid item xs={1} style={{ paddingRight: 5 }}>
                    <CurrentIcon />
                </Grid>
                <Grid container item xs={10} style={{ textAlign: 'center' }}>
                    <Grid item xs={12}>
                        {`ID: ${node.id}`}
                    </Grid>
                    <Grid item xs={12}>
                        {`Type: ${node.type}`}
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <IconButton style={{ float: 'right' }} onClick={onDeleteClicked}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}
NodeInner.propTypes = {
    node: PropTypes.shape({ type: PropTypes.string.isRequired, id: PropTypes.string.isRequired }),
}

NodeInner.defaultProps = {
    node: { type: 'Pulse', id: '' },
}

export default NodeInner
