import React, { useState } from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { useSelector, useDispatch } from "react-redux"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListSubheader from '@material-ui/core/ListSubheader';
import Slider from '@material-ui/core/Slider';
import Divider from "@material-ui/core/Divider"
import TextField from "@material-ui/core/TextField"
import Chip from '@material-ui/core/Chip';

import { setRange } from "../../reducers/controls"

import { 
    GET_HOTSPOT_BY_ID,
    GET_HOTSPOTS_BY_IMAGE,
    REMOVE_HOTSPOT_BY_ID,
    UPDATE_HOTSPOT_BY_ID 
} from "../../queries/hotspots"

function Controls(props) {
    
    const dispatch = useDispatch()

    const { range, selected_hotspot = {} } = useSelector(state => state.inspector.solar.side_menu.image_viewer.radiometric_viewer.controls)
    const { selected_image } = useSelector(state => state.inspector.solar.images.selected_image)

    const onRangeChange = (event, range) => dispatch(setRange(range))

    const [ propertyName, setPropertyName ] = useState("")
    const [ propertyValue, setPropertyValue ] = useState("")


    const { data: { getHotspotById: { hotspot: { _id, properties = [], category = "", severity = "" } = {}} = {}} = {}} = useQuery(GET_HOTSPOT_BY_ID, {
        variables: {
            params: {
                _id: selected_hotspot && selected_hotspot._id
            }
        }
    })
    const [ updateHotspot ] = useMutation(UPDATE_HOTSPOT_BY_ID)
    const [ removeHotspotById ] = useMutation(REMOVE_HOTSPOT_BY_ID)

    const [ tempRange, setTempRange ] = useState(range)

    const onUpdateHotspotCategory = event => {
        updateHotspot({ 
            variables: {
                params: {
                    _id, 
                    update: { 
                        category: event.target.value 
                    } 
                }
            },
            refetchQueries: [
                {
                    query: GET_HOTSPOT_BY_ID,
                    variables: {
                        params: {
                            _id: params._id
                        }
                    }
                }
            ]
        })
    }

    const onUpdateHotspotSeverity = event => {
        updateHotspot({ 
            variables: {
                params: {
                    _id: _id, 
                    update: { 
                        severity: event.target.value
                    } 
                }
            },
            refetchQueries: [
                {
                    query: GET_HOTSPOT_BY_ID,
                    variables: {
                        params: {
                            _id: params._id
                        }
                    }
                }
            ]
        })
    }

    const onRemoveHotspot = () => {
        removeHotspotById({
            variables: {
                params: {
                    _id: selected_hotspot._id
                }
            },
            refetchQueries: [
                {
                    query: GET_HOTSPOTS_BY_IMAGE,
                    variables: {
                        params: {
                            image: selected_image._id
                        }
                    }
                }
            ]
        })
    }

    const onPropertyNameChange = event => {
        setPropertyName(event.target.value)
    }

    const onPropertyValueChange = event => {
        setPropertyValue(event.target.value)
    }

    const addProperty = () => {
        updateHotspot({ 
            variables: {
                params: {
                    _id, 
                    update: { 
                        properties: [
                            ...properties,
                            {
                                name: propertyName,
                                value: propertyValue
                            }
                        ]
                    } 
                }
            },
            refetchQueries: [
                {
                    query: GET_HOTSPOT_BY_ID,
                    variables: {
                        params: {
                            _id: params._id
                        }
                    }
                }
            ]
        })
    }

    const removeProperty = index => () => {
        updateHotspot({ 
            variables: {
                params: {
                    _id, 
                    update: { 
                        properties: [
                            ...properties.slice(0, index),
                            ...properties.slice(index++)
                        ]
                    } 
                }
            },
            refetchQueries: [
                {
                    query: GET_HOTSPOT_BY_ID,
                    variables: {
                        params: {
                            _id: params._id
                        }
                    }
                }
            ]
        })
    }

    return (
        <React.Fragment>
           <List>
                <ListSubheader>Paleta</ListSubheader>
                <ListItem>
                    <Select value={"iron"} fullWidth>
                        <MenuItem value={"iron"}>Iron</MenuItem>
                    </Select>
                </ListItem>
                <ListSubheader>Rango de Temperaturas</ListSubheader>
                <ListItem>
                    <Slider
                        value={tempRange}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        onChange={(event, value) => setTempRange(value)}
                        onChangeCommitted={onRangeChange}
                    />
                </ListItem>
            </List>
            <Divider/>
            <List>
                <ListSubheader>Propiedades</ListSubheader>
                <ListItem>
                    {properties.map((property, index) => (
                        <Chip
                            label={`${property.name}: ${property.value}`}
                            onDelete={removeProperty(index)}
                        />
                    ))}
                </ListItem>
                <ListItem>
                    <Grid container spacing={1}>
                        <Grid item md>
                            <TextField label="Nombre" fullWidth onClick={onPropertyNameChange} value={propertyName}/>
                        </Grid>
                        <Grid item md>
                            <TextField label="Valor" fullWidth onClick={onPropertyValueChange} value={propertyValue}/>
                        </Grid>
                    </Grid>
                </ListItem>
                <ListItem>
                    <Button variant="contained" fullWidth onClick={addProperty}>Añadir</Button>
                </ListItem>
                <ListSubheader>Categoría</ListSubheader>
                <ListItem>
                    <Select value={category} fullWidth onChange={onUpdateHotspotCategory}>
                        <MenuItem value={"none"}>Sin categoría</MenuItem>
                        <MenuItem value={"hotspot"}>Punto Caliente</MenuItem>
                        <MenuItem value={"diode"}>Diodo</MenuItem>
                    </Select>
                </ListItem>
                <ListSubheader>Gravedad</ListSubheader>
                <ListItem>
                    <Select value={severity} fullWidth onChange={onUpdateHotspotSeverity}>
                        {[...Array(10)].map((e,i) => ( <MenuItem value={i}>{i}</MenuItem> ))}
                    </Select>
                </ListItem>
                <ListItem>
                    <Button variant="contained" fullWidth onClick={onRemoveHotspot}>Eliminar</Button>
                </ListItem>
            </List>
        </React.Fragment>
    )
}

export default Controls