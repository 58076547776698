import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'

const Container = ({ padding, background, children }) => {
    const {
        connectors: { connect, drag },
    } = useNode((node) => {
        return {
            data: node.data,
        }
    })

    const style = {
        height: '100%',
        width: '100%',
        display: 'inherit',
        flexDirection: 'inherit',
        padding,
        background,
    }

    return (
        <div className="page-container" ref={(ref) => connect(drag(ref))} style={style} draggable>
            {children}
        </div>
    )
}

Container.craft = {
    displayName: 'Contendor',
    props: {
        padding: '0px 0px 0px 0px',
        background: '#FFF',
    },
}

Container.propTypes = {
    padding: PropTypes.string,
    background: PropTypes.string,
    children: PropTypes.array,
}

Container.defaultProps = {
    padding: '0px 0px 0px 0px',
    background: '#FFF',
    children: [],
}

export default Container
