import React, { useState, useRef, useEffect, useContext, Suspense } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, ReactReduxContext, shallowEqual } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import TextField from '@material-ui/core/TextField'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, GizmoHelper, GizmoViewport } from '@react-three/drei'
import * as THREE from 'three'
import { v4 as uuidv4 } from 'uuid'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { useGesture } from 'react-use-gesture'

import {
    deleteObstacle,
    pickOverObject,
    setSelectedObstacle,
    updateObstacle,
} from '../../reducers/obstacles'
import AddUnits from '../../../../../../../../../../../../../../../components/AddUnits'
import { GET_PARAMS_BY_GROUP, CREATE_PARAM, UPDATE_PARAM } from '../../../../params/queries/params'
import GrowableSideBar from '../../../../../../../../../../../../../../../utils/common-components/growableSideBar'
import Cobot from '../../components/cobot'
import BoxCollider from '../../components/boxCollider'

const LENGTH_UNITS = {
    mm: 1 / 1000,
    cm: 1 / 100,
    dm: 1 / 10,
    m: 1,
}

const STATE_SAVE_TIME = 5 // s

extend({ TextGeometry })

const ThreeObstacle = ({ obstacleIndex }) => {
    const obstacleSize = useSelector(
        (state) => state.group.palletizer.obstacles.obstacles[obstacleIndex].properties.size
    )

    const dispatch = useDispatch()

    const { store } = useContext(ReactReduxContext)

    const overObject = useRef()
    const groupRef = useRef()
    const onCollisionRef = useRef(false)
    const ghostBufferRef = useRef()
    const materialRefRender = useRef()
    const materialRefGhost = useRef()
    const worldQuaternion = useRef(new THREE.Quaternion())
    const worldPosition = useRef(new THREE.Vector3())
    const ghostWorldPosition = useRef(new THREE.Vector3())
    const constrainedPosition = useRef(new THREE.Vector3())

    const cleanedPosition = useRef(new THREE.Vector3(0, 0, 0))
    const cleanedRotation = useRef(new THREE.Euler(0, 0, 0, 'XYZ'))

    const { controls, scene, raycaster, size } = useThree()

    const boxMovement = useGesture({
        onClick: ({ event }) => {
            event.stopPropagation()
            const pickActivated =
                store.getState().group.palletizer.obstacles.pickOverObjectActivated
            const isActive =
                store.getState().group.palletizer.obstacles.selectedObstacle === obstacleIndex
            if (!isActive && !pickActivated) {
                dispatch(setSelectedObstacle(obstacleIndex))
            }
            if (!isActive && pickActivated) {
                dispatch(
                    updateObstacle(store.getState().group.palletizer.obstacles.selectedObstacle, {
                        overObjectName: groupRef.current.name,
                    })
                )
            }
        },
        onDragStart: () => {
            const isActive =
                store.getState().group.palletizer.obstacles.selectedObstacle === obstacleIndex
            if (isActive) {
                controls.enabled = false

                // Equal position of ghost box and render box
                ghostBufferRef.current.position.set(
                    groupRef.current.position.x,
                    groupRef.current.position.y,
                    -groupRef.current.position.z
                )

                // Set Over Object
                scene.traverse((obj) => {
                    if (
                        obj.name ===
                        (store.getState().group.palletizer.obstacles.obstacles[obstacleIndex]
                            .overObjectName || 'floor')
                    ) {
                        overObject.current = obj
                    }
                })
            }
        },
        onDrag: (event) => {
            const isActive =
                store.getState().group.palletizer.obstacles.selectedObstacle === obstacleIndex
            if (isActive) {
                const mouse = new THREE.Vector2(
                    ((event.xy[0] - size.left - size.width / 2) * 2) / size.width,
                    -((event.xy[1] - size.top - size.height / 2) * 2) / size.height
                )
                raycaster.setFromCamera(mouse, event.event.camera)
                const intersects = raycaster.intersectObject(overObject.current)
                if (intersects.length === 0) return null

                ghostBufferRef.current.position.set(
                    intersects[0].point.x,
                    intersects[0].point.y,
                    intersects[0].point.z - obstacleSize[2] / 2
                )
            }
            return null
        },
        onDragEnd: () => {
            const isActive =
                store.getState().group.palletizer.obstacles.selectedObstacle === obstacleIndex
            if (isActive) {
                controls.enabled = true
                // Equal position of ghost box and render box
                ghostBufferRef.current.position.set(
                    groupRef.current.position.x,
                    groupRef.current.position.y,
                    groupRef.current.position.z
                )
            }
        },
    })

    useEffect(() => {
        ghostBufferRef.current.position.set(
            cleanedPosition.current.x,
            cleanedPosition.current.y,
            -cleanedPosition.current.z
        )
        ghostBufferRef.current.rotation.set(
            cleanedRotation.current.x,
            cleanedRotation.current.y,
            cleanedRotation.current.z
        )
        groupRef.current.position.set(
            cleanedPosition.current.x,
            cleanedPosition.current.y,
            -cleanedPosition.current.z
        )
        groupRef.current.rotation.set(
            cleanedRotation.current.x,
            cleanedRotation.current.y,
            cleanedRotation.current.z
        )
    }, [])

    useFrame(({ clock }) => {
        const isActive =
            store.getState().group.palletizer.obstacles.selectedObstacle === obstacleIndex
        const obstacle = store.getState().group.palletizer.obstacles.obstacles[obstacleIndex]
        if (obstacle.pose.positionNeedsUpdate) {
            ghostBufferRef.current.position.set(
                obstacle.pose.position[0],
                obstacle.pose.position[1],
                -obstacle.pose.position[2]
            )
            groupRef.current.position.set(
                obstacle.pose.position[0],
                obstacle.pose.position[1],
                -obstacle.pose.position[2]
            )
            dispatch(
                updateObstacle(obstacleIndex, {
                    pose: { ...obstacle.pose, positionNeedsUpdate: false },
                })
            )
        }
        if (obstacle.pose.rotationNeedsUpdate) {
            ghostBufferRef.current.rotation.set(
                obstacle.pose.rotation[0],
                obstacle.pose.rotation[1],
                obstacle.pose.rotation[2]
            )
            groupRef.current.rotation.set(
                obstacle.pose.rotation[0],
                obstacle.pose.rotation[1],
                obstacle.pose.rotation[2]
            )
            groupRef.current.getWorldQuaternion(worldQuaternion.current)
            dispatch(
                updateObstacle(obstacleIndex, {
                    pose: {
                        ...obstacle.pose,
                        orientation: [
                            worldQuaternion.current.x,
                            worldQuaternion.current.y,
                            worldQuaternion.current.z,
                            worldQuaternion.current.w,
                        ],
                        rotationNeedsUpdate: false,
                    },
                })
            )
        }
        if (obstacle.pose.rotationNeedsSync) {
            worldQuaternion.current.set(
                obstacle.pose.orientation[0],
                obstacle.pose.orientation[1],
                obstacle.pose.orientation[2],
                obstacle.pose.orientation[3]
            )

            ghostBufferRef.current.setRotationFromQuaternion(worldQuaternion.current)
            groupRef.current.setRotationFromQuaternion(worldQuaternion.current)

            dispatch(
                updateObstacle(obstacleIndex, {
                    pose: {
                        ...obstacle.pose,
                        rotation: [
                            groupRef.current.rotation.x,
                            groupRef.current.rotation.y,
                            groupRef.current.rotation.z,
                        ],
                        rotationNeedsSync: false,
                    },
                })
            )
        }
        if (isActive && groupRef.current) {
            if (materialRefRender.current) {
                materialRefRender.current.color.set(0x00ff00)
                materialRefGhost.current.color.set(0x00ff00)
            }
            if (onCollisionRef.current.length !== 0) {
                materialRefGhost.current.color.set(0xff0000)
            } else {
                materialRefGhost.current.color.set(0x00ff00)
            }

            if (onCollisionRef.current.length === 0) {
                groupRef.current.position.copy(ghostBufferRef.current.position)
                groupRef.current.rotation.copy(ghostBufferRef.current.rotation)
            } else if (onCollisionRef.current.length === 1) {
                groupRef.current.getWorldPosition(worldPosition.current)
                ghostBufferRef.current.getWorldPosition(ghostWorldPosition.current)

                if (Math.round(onCollisionRef.current[0].direction.y) === 0) {
                    // Moving along y axis
                    constrainedPosition.current.set(
                        worldPosition.current.x,
                        ghostWorldPosition.current.y,
                        -ghostWorldPosition.current.z
                    )
                } else {
                    const newY =
                        worldPosition.current.y -
                        (onCollisionRef.current[0].direction.x /
                            onCollisionRef.current[0].direction.y) *
                            (ghostWorldPosition.current.x - worldPosition.current.x)

                    constrainedPosition.current.set(
                        ghostWorldPosition.current.x,
                        newY,
                        -ghostWorldPosition.current.z
                    )
                }

                groupRef.current.position.set(
                    constrainedPosition.current.x,
                    constrainedPosition.current.y,
                    -constrainedPosition.current.z
                )
                groupRef.current.rotation.set(
                    ghostBufferRef.current.rotation.x,
                    ghostBufferRef.current.rotation.y,
                    ghostBufferRef.current.rotation.z
                )
            }
        } else {
            materialRefRender.current.color.set(0x000000)
            materialRefGhost.current.color.set(0x000000)
        }
        if (Math.ceil(clock.elapsedTime) % STATE_SAVE_TIME === 0) {
            groupRef.current.getWorldQuaternion(worldQuaternion.current)
            groupRef.current.getWorldPosition(worldPosition.current)
            dispatch(
                updateObstacle(obstacleIndex, {
                    pose: {
                        ...obstacle.pose,
                        position: [
                            worldPosition.current.x,
                            worldPosition.current.y,
                            -worldPosition.current.z,
                        ],
                        orientation: [
                            worldQuaternion.current.x,
                            worldQuaternion.current.y,
                            worldQuaternion.current.z,
                            worldQuaternion.current.w,
                        ],
                        positionNeedsUpdate: false,
                        rotationNeedsUpdate: false,
                    },
                })
            )
        }
    })

    /* eslint-disable react/no-unknown-property */
    return (
        <React.Fragment>
            <group
                name={`obstacle-${obstacleIndex}`}
                ref={groupRef}
                position={[0, 0, 0]}
                rotation={[0, 0, 0]}
            >
                <mesh visible={true}>
                    <boxBufferGeometry
                        attach="geometry"
                        args={[obstacleSize[0], obstacleSize[1], obstacleSize[2]]}
                    />
                    <meshStandardMaterial
                        ref={materialRefRender}
                        visible={true}
                        attach="material"
                        wireframe={true}
                        color={0x000000}
                    />
                </mesh>
            </group>
            <BoxCollider
                checkActive={() =>
                    store.getState().group.palletizer.obstacles.selectedObstacle === obstacleIndex
                }
                onCollisionRef={onCollisionRef}
                help={false}
            >
                <group
                    ref={ghostBufferRef}
                    name={`obstacle-${obstacleIndex}-collider`}
                    rotation={[0, 0, 0, 'XYZ']}
                    {...boxMovement()}
                >
                    <mesh>
                        <boxBufferGeometry
                            attach="geometry"
                            args={[obstacleSize[0], obstacleSize[1], obstacleSize[2]]}
                        />
                        <meshStandardMaterial
                            ref={materialRefGhost}
                            visible={true}
                            attach="material"
                            wireframe={true}
                            color={0x000000}
                        />
                    </mesh>
                </group>
            </BoxCollider>
        </React.Fragment>
    )
}

ThreeObstacle.propTypes = {
    obstacleIndex: PropTypes.number.isRequired,
}

const ThreeObstacles = () => {
    const obstaclesCount = useSelector((state) => state.group.palletizer.obstacles.obstacles.length)
    return [...Array(obstaclesCount).keys()].map((obstacleIndex) => {
        return <ThreeObstacle key={obstacleIndex} obstacleIndex={obstacleIndex} />
    })
}

const ThreeJSScene = () => {
    const controlsRef = useRef()

    const intensity = 0.5
    const main = [1, 2, 1]
    const fill = [-2, -0.5, -2]
    const radius = 4
    const castShadows = false

    const { store } = useContext(ReactReduxContext)

    const dispatch = useDispatch()

    const onFloorClick = (event) => {
        event.stopPropagation()
        if (store.getState().group.palletizer.obstacles.pickOverObjectActivated) {
            dispatch(
                updateObstacle(store.getState().group.palletizer.obstacles.selectedObstacle, {
                    overObjectName: 'floor',
                })
            )
        }
    }
    return (
        <Canvas camera={{ fov: 50, up: [0, 0, -1], position: [-6, -6, 2] }}>
            <ambientLight intensity={intensity / 3} />
            <spotLight
                penumbra={1}
                position={[main[0] * radius, main[1] * radius, main[2] * radius]}
                intensity={intensity * 2}
                castShadow={castShadows}
                shadow-bias={-0.0001}
                shadow-normalBias={0}
                shadow-mapSize={1024}
            />
            <pointLight
                position={[fill[0] * radius, fill[1] * radius, fill[2] * radius]}
                intensity={intensity}
            />
            <Suspense fallback={null}>
                <Cobot rotation={[Math.PI / 2, -Math.PI / 2, Math.PI]} name="cobot" />
            </Suspense>
            <OrbitControls makeDefault ref={controlsRef} />
            <ThreeObstacles />
            <mesh name="floor" onClick={onFloorClick}>
                <planeBufferGeometry args={[10, 10]} attach="geometry" />
                <meshStandardMaterial
                    visible={true}
                    attach="material"
                    color={0xe5e4e2}
                    side={THREE.BackSide}
                />
            </mesh>
            <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                <GizmoViewport opacity={1} />
            </GizmoHelper>
            <axesHelper args={[5]} />
        </Canvas>
    )
}

const ObstacleDataEdit = () => {
    const selectedObstacle = useSelector((state) => {
        return state.group.palletizer.obstacles.selectedObstacle
    })
    const pickScreen = useSelector((state) => {
        return state.group.palletizer.obstacles.pickOverObjectActivated
    })

    const obstacle = useSelector((state) => {
        if (selectedObstacle === null) {
            return null
        }
        return state.group.palletizer.obstacles.obstacles[selectedObstacle]
    })

    const dispatch = useDispatch()

    const [widthUnit, setWidthUnit] = useState(1)
    const [heightUnit, setHeightUnit] = useState(1)
    const [deepUnit, setDeepUnit] = useState(1)
    const [collapseSize, setCollapseSize] = useState(true)

    const [xUnit, setXUnit] = useState(1)
    const [yUnit, setYUnit] = useState(1)
    const [zUnit, setZUnit] = useState(1)
    const [collapsePosition, setCollapsePosition] = useState(true)

    const [collapseRotation, setCollapseRotation] = useState(true)

    const onObstacleNameChange = (e) => {
        dispatch(updateObstacle(selectedObstacle, { name: e.target.value }))
    }

    const onWidthChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                properties: {
                    size: [
                        parseFloat(e.target.value) * widthUnit,
                        obstacle.properties.size[1],
                        obstacle.properties.size[2],
                    ],
                },
            })
        )
    }

    const onHeightChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                properties: {
                    size: [
                        obstacle.properties.size[0],
                        parseFloat(e.target.value) * heightUnit,
                        obstacle.properties.size[2],
                    ],
                },
            })
        )
    }

    const onDeepChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                properties: {
                    size: [
                        obstacle.properties.size[0],
                        obstacle.properties.size[1],
                        parseFloat(e.target.value) * deepUnit,
                    ],
                },
            })
        )
    }

    const onXChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                pose: {
                    ...obstacle.pose,
                    position: [
                        parseFloat(e.target.value) * xUnit,
                        obstacle.pose.position[1],
                        obstacle.pose.position[2],
                    ],
                    positionNeedsUpdate: true,
                },
            })
        )
    }

    const onYChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                pose: {
                    ...obstacle.pose,
                    position: [
                        obstacle.pose.position[0],
                        parseFloat(e.target.value) * yUnit,
                        obstacle.pose.position[2],
                    ],
                    positionNeedsUpdate: true,
                },
            })
        )
    }

    const onZChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                pose: {
                    ...obstacle.pose,
                    position: [
                        obstacle.pose.position[0],
                        obstacle.pose.position[1],
                        parseFloat(e.target.value) * zUnit,
                    ],
                    positionNeedsUpdate: true,
                },
            })
        )
    }

    const onRollChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                pose: {
                    ...obstacle.pose,
                    rotation: [
                        (parseFloat(e.target.value) * Math.PI) / 180,
                        obstacle.pose.rotation[1],
                        obstacle.pose.rotation[2],
                    ],
                    rotationNeedsUpdate: true,
                },
            })
        )
    }

    const onPitchChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                pose: {
                    ...obstacle.pose,
                    rotation: [
                        obstacle.pose.rotation[0],
                        (parseFloat(e.target.value) * Math.PI) / 180,
                        obstacle.pose.rotation[2],
                    ],
                    rotationNeedsUpdate: true,
                },
            })
        )
    }

    const onYawChange = (e) => {
        dispatch(
            updateObstacle(selectedObstacle, {
                pose: {
                    ...obstacle.pose,
                    rotation: [
                        obstacle.pose.rotation[0],
                        obstacle.pose.rotation[1],
                        (parseFloat(e.target.value) * Math.PI) / 180,
                    ],
                    rotationNeedsUpdate: true,
                },
            })
        )
    }
    const setPickInScreen = () => {
        dispatch(pickOverObject())
    }

    if (obstacle === null) {
        return null
    }

    return (
        <GrowableSideBar
            anchor="right"
            size={0}
            paperProps={{
                style: {
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    scrollbarWidth: 0,
                    height: '100%',
                    width: 400,
                },
            }}
        >
            <List>
                <ListItem>
                    <TextField
                        label="Nombre"
                        value={obstacle.name}
                        onChange={onObstacleNameChange}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        sx={{ pl: 4 }}
                        primary={`Size: ${obstacle.properties.size.map((rot) => rot.toFixed(3))}`}
                    />
                    <ListItemSecondaryAction onClick={() => setCollapseSize(!collapseSize)}>
                        {!collapseSize ? <ExpandLess /> : <ExpandMore />}
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={!collapseSize} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="x: " />
                            <AddUnits
                                conversionFactors={LENGTH_UNITS}
                                defaultUnit="m"
                                onChangeUnit={setWidthUnit}
                            >
                                <TextField
                                    type="number"
                                    value={obstacle.properties.size[0] / widthUnit}
                                    onChange={onWidthChange}
                                />
                            </AddUnits>
                        </ListItem>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="y: " />
                            <AddUnits
                                conversionFactors={LENGTH_UNITS}
                                defaultUnit="m"
                                onChangeUnit={setHeightUnit}
                            >
                                <TextField
                                    type="number"
                                    value={obstacle.properties.size[1] / heightUnit}
                                    onChange={onHeightChange}
                                />
                            </AddUnits>
                        </ListItem>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="z: " />
                            <AddUnits
                                conversionFactors={LENGTH_UNITS}
                                defaultUnit="m"
                                onChangeUnit={setDeepUnit}
                            >
                                <TextField
                                    type="number"
                                    value={obstacle.properties.size[2] / deepUnit}
                                    onChange={onDeepChange}
                                />
                            </AddUnits>
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem>
                    <ListItemText
                        sx={{ pl: 4 }}
                        primary={`Position: ${obstacle.pose.position.map((rot) => rot.toFixed(3))}`}
                    />
                    <ListItemSecondaryAction onClick={() => setCollapsePosition(!collapsePosition)}>
                        {!setCollapsePosition ? <ExpandLess /> : <ExpandMore />}
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={!collapsePosition} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="x: " />
                            <AddUnits
                                conversionFactors={LENGTH_UNITS}
                                defaultUnit="m"
                                onChangeUnit={setXUnit}
                            >
                                <TextField
                                    type="number"
                                    value={obstacle.pose.position[0] / xUnit}
                                    onChange={onXChange}
                                />
                            </AddUnits>
                        </ListItem>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="y: " />
                            <AddUnits
                                conversionFactors={LENGTH_UNITS}
                                defaultUnit="m"
                                onChangeUnit={setYUnit}
                            >
                                <TextField
                                    type="number"
                                    value={obstacle.pose.position[1] / yUnit}
                                    onChange={onYChange}
                                />
                            </AddUnits>
                        </ListItem>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="z: " />
                            <AddUnits
                                conversionFactors={LENGTH_UNITS}
                                defaultUnit="m"
                                onChangeUnit={setZUnit}
                            >
                                <TextField
                                    type="number"
                                    value={obstacle.pose.position[2] / zUnit}
                                    onChange={onZChange}
                                />
                            </AddUnits>
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem>
                    <ListItemText
                        sx={{ pl: 4 }}
                        primary={`Rotation: ${obstacle.pose.rotation.map((rot) => rot.toFixed(3))}`}
                    />
                    <ListItemSecondaryAction onClick={() => setCollapseRotation(!collapseRotation)}>
                        {!collapseRotation ? <ExpandLess /> : <ExpandMore />}
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={!collapseRotation} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="x (Roll): " />
                            <TextField
                                type="number"
                                value={(obstacle.pose.rotation[0] * 180) / Math.PI}
                                onChange={onRollChange}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="y (Pitch): " />

                            <TextField
                                type="number"
                                value={(obstacle.pose.rotation[1] * 180) / Math.PI}
                                onChange={onPitchChange}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText sx={{ pl: 4 }} primary="z (Yaw): " />
                            <TextField
                                type="number"
                                value={(obstacle.pose.rotation[2] * 180) / Math.PI}
                                onChange={onYawChange}
                            />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItem>
                    <TextField
                        label="Soporte"
                        InputLabelProps={{ shrink: true }}
                        value={obstacle.overObjectName}
                        fullWidth
                    />

                    <Button
                        variant={pickScreen ? 'contained' : 'outlined'}
                        onClick={setPickInScreen}
                        style={{
                            maxWidth: '30px',
                            maxHeight: '30px',
                            minWidth: '5px',
                            minHeight: '5px',
                        }}
                    >
                        <TouchAppIcon />
                    </Button>
                </ListItem>
            </List>
        </GrowableSideBar>
    )
}

const ObstacleSelectInfo = ({ obstacleIndex }) => {
    const selectedObstacle = useSelector(
        (state) => state.group.palletizer.obstacles.selectedObstacle
    )
    const obstacleInfo = useSelector((state) => {
        const obstacle = state.group.palletizer.obstacles.obstacles[obstacleIndex]
        return {
            id: obstacle.id,
            size: obstacle.properties.size,
            name: obstacle.name,
            unsaved: obstacle.unsaved,
        }
    }, shallowEqual)

    const dispatch = useDispatch()

    const onSelectClick = () => {
        dispatch(setSelectedObstacle(selectedObstacle === obstacleIndex ? null : obstacleIndex))
    }

    const onDeleteClick = () => {
        dispatch(deleteObstacle(obstacleIndex))
    }

    return (
        <ListItem button selected={selectedObstacle === obstacleIndex}>
            <Tooltip
                title={
                    <Typography fontSize={14}>{`Elemento ${
                        obstacleInfo.unsaved ? 'sin guardar' : 'guardado'
                    }`}</Typography>
                }
                aria-label="add"
            >
                <ListItemIcon>
                    {obstacleInfo.unsaved && <ErrorIcon />}
                    {!obstacleInfo.unsaved && <CheckCircleIcon />}
                </ListItemIcon>
            </Tooltip>
            <ListItemText
                primary={obstacleInfo.name}
                secondary={`Medidas: ${obstacleInfo.size[0]} x ${obstacleInfo.size[1]} x ${obstacleInfo.size[2]} m`}
                onClick={onSelectClick}
            />
            <ListItemSecondaryAction>
                <Tooltip title={<Typography fontSize={14}>Eliminar</Typography>} aria-label="add">
                    <IconButton>
                        <DeleteIcon style={{ marginTop: 5 }} onClick={onDeleteClick} />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

ObstacleSelectInfo.propTypes = {
    obstacleIndex: PropTypes.string.isRequired,
}

const ListObstacles = () => {
    const { group } = useParams()
    const theme = useTheme()

    const obstaclesParameterName = useSelector((state) => state.group.palletizer.settings.obstacles)
    const obstaclesLength = useSelector(
        (state) => Object.keys(state.group.palletizer.obstacles.obstacles).length
    )

    const { store } = useContext(ReactReduxContext)

    const dispatch = useDispatch()

    const { data: { getParamsByGroup: { params: parametersGQL = [] } = {} } = {} } = useQuery(
        GET_PARAMS_BY_GROUP,
        { variables: { params: { group } }, pollInterval: 10000 }
    )
    const [updateParameterGQL] = useMutation(UPDATE_PARAM)
    const [createParam] = useMutation(CREATE_PARAM)

    const addNewObstacleClicked = () => {
        const obstacleId = uuidv4()
        dispatch(
            updateObstacle(obstaclesLength, {
                id: obstacleId,
                name: `UNTITLED OBSTACLE ${obstaclesLength}`,
                pose: {
                    position: [-5, -5, 0.5],
                    rotation: [0, 0, 0],
                    orientation: [0, 0, 0, 0],
                    rotationNeedsSync: false,
                    positionNeedsUpdate: true,
                    rotationNeedsUpdate: false,
                },
                properties: { size: [1, 1, 1] },
                overObjectName: 'floor',
                unsaved: true,
            })
        )
    }

    const onSaveClick = async () => {
        const { obstacles } = store.getState().group.palletizer.obstacles
        // Clean to cobot data
        const cleaned = obstacles.map((obstacle) => {
            return {
                name: obstacle.name,
                id: obstacle.id,
                pose: {
                    position: {
                        x: obstacle.pose.position[0],
                        y: obstacle.pose.position[1],
                        z: obstacle.pose.position[2],
                    },
                    orientation: {
                        x: obstacle.pose.orientation[0],
                        y: obstacle.pose.orientation[1],
                        z: obstacle.pose.orientation[2],
                        w: obstacle.pose.orientation[3],
                    },
                },
                properties: {
                    size: {
                        x: obstacle.properties.size[0],
                        y: obstacle.properties.size[1],
                        z: obstacle.properties.size[2],
                    },
                },
            }
        })

        // Check if parameter exists
        const obstaclesParameter = parametersGQL.filter(
            (param) => param.name === obstaclesParameterName
        )

        if (obstaclesParameter.length === 0) {
            await createParam({
                variables: {
                    params: {
                        group,
                        name: obstaclesParameterName,
                        type: 'STRING',
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        } else {
            await updateParameterGQL({
                variables: {
                    params: {
                        _id: obstaclesParameter[0]._id,
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <List>
                <ListItem>
                    <ListItemText primary="AÑADIR OBSTÁCULO" />
                    <ListItemSecondaryAction>
                        <IconButton onClick={addNewObstacleClicked}>
                            <AddIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider style={{ marginTop: 7 }} />
                {store.getState().group.palletizer.obstacles.obstacles.map((obs, iObs) => (
                    <ObstacleSelectInfo key={obs.id} obstacleIndex={iObs} />
                ))}
            </List>
            <Divider style={{ marginTop: 7, marginBottom: 7 }} />
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <Button variant="contained" onClick={onSaveClick} color="primary">
                        Guardar
                    </Button>
                </Grid>
                <Grid item xs={4} />
            </Grid>
        </ThemeProvider>
    )
}

const Obstacles = () => {
    return (
        <Grid container style={{ height: '100%' }}>
            <Grid
                item
                xs={3}
                style={{ height: '100%', borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <ListObstacles />
            </Grid>
            <Grid item xs={9} style={{ height: '100%' }}>
                <ThreeJSScene />
            </Grid>
            <ObstacleDataEdit />
        </Grid>
    )
}

export default Obstacles
