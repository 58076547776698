import React from "react"

import { makeStyles } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid"

import Drawer from "@material-ui/core/Drawer"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from "@material-ui/core/Divider"

import FolderIcon from '@material-ui/icons/Folder';

import ImageViewer from "./containers/image-viewer"


const getSize = views => {
    if(views === 1) {
        return 12
    } else if(views <= 4) {
        return 6
    } else if(views <= 9) {
        return 4
    } else {
        return 3
    }
}

const getHeight = views => {
    if(views <= 2) {
        return window.innerHeight - 70
    } else if(views <= 6) {
        return (window.innerHeight - 70) / 2
    } else if(views <= 9) {
        return (window.innerHeight - 70) / 3
    } else {
        return (window.innerHeight - 70) / 4
    }
}

const MultiViewClassification = (props) => {

    const classes = useStyles()

    const views = 4
    return (
        <Grid container>
            <Grid item xs={10}>
                <Grid container>
                    {
                        [...Array(views).keys()].map((a,b, arr) => {
                            const x = getSize(arr.length)
                            
                            return (
                                <Grid item xs={x} style={{ height: getHeight(views) }}>
                                    <ImageViewer/>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Drawer
                    variant="permanent"
                    anchor="right"
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Single-line item"
                                secondary={'Secondary text'}
                            />
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="Single-line item"
                                secondary={'Secondary text'}
                            />
                        </ListItem>
                    </List>
                </Drawer>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: 400,
        zIndex: 1,
        paddingTop: 75
    }
}));

export default MultiViewClassification