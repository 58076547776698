export const SET_CLASS_INDEX = "HORUS/TRAINING_SETS/POLYGON/SET_CLASS_INDEX"
export const SET_SELECTED_ENTRY = "HORUS/TRAINING_SETS/POLYGON/SET_SELECTED_ENTRY"
export const SET_SELECTED_OBJECT = "HORUS/TRAINING_SETS/POLYGON/SET_SELECTED_OBJECT"

export const setClassIndex = (classIndex) => ({ type: SET_CLASS_INDEX, classIndex })
export const setSelectedEntry = (entry) => ({ type: SET_SELECTED_ENTRY, entry })
export const setSelectedObject = (object) => ({ type: SET_SELECTED_OBJECT, object })

export default (state = { classIndex: -1, selectedObject: -1, selectedEntry: "",  }, action) => {
    switch(action.type) {
        case SET_CLASS_INDEX:
            return {
                ...state,
                classIndex: action.classIndex
            }
        case SET_SELECTED_ENTRY:
            return {
                ...state,
                selectedEntry: action.entry,
                selectedObject: -1,
                classIndex: -1
            }
        case SET_SELECTED_OBJECT:
            return {
                ...state,
                selectedObject: action.object
            }
        default:
            return state
    }
}