import React from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import { INTEGER_TYPE } from '../../../../../group/containers/params/containers/param-type/index'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, onPropertyChangeBuilder, getProperty, nodeDefinition }) => {
    const classes = useStyles()

    const versionGreaterThan6 = parseInt(getProperty('version', '7'), 10) >= 7

    return (
        <Grid container columnSpacing={100} className={classes.root} alignItems="flex-end">
            {!versionGreaterThan6 && (
                <Grid item xs={1}>
                    <Tooltip
                        title={
                            <Typography fontSize={14}>
                                Para versiones menores o iguales a 6 solo está disponible el tipo
                                &quot;fixed&quot;.
                            </Typography>
                        }
                    >
                        <WarningAmberIcon fontSize="large" style={{ color: '#ff9800' }} />
                    </Tooltip>
                </Grid>
            )}
            <Grid item sm={!versionGreaterThan6 ? 11 : 12}>
                <Parametrize
                    name="interval"
                    humanName="Intervalo(us)"
                    parametersFilter={INTEGER_TYPE}
                >
                    <FormControl fullWidth style={{ marginTop: 5 }}>
                        <TextField
                            type="number"
                            min="0"
                            step="1"
                            onChange={onPropertyChangeBuilder('interval', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            label="Intervalo(us)"
                            value={
                                JSON.parse(getProperty('interval', JSON.stringify({ value: '' })))
                                    .value || getProperty('interval', '')
                            }
                        />
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
