exports.app = {
    frontend: {
        url: 'http://localhost:1234',
    },
    backend: {
        //url: 'http://localhost:3000',
        url: 'https://apidev.flightlabs.es',
    },
    mapbox_token:
        'pk.eyJ1IjoibG9yaWVuYmVybmUiLCJhIjoiY2plenJxZXh6MGV1ajJxbzlnNHJ3bzkwdiJ9.3fMtyRl6UG-H_JogmGAQjA',
}

exports.cognito = {
    url: `https://flightlabs-horus-dev.auth.eu-central-1.amazoncognito.com`,
    client_id: '489ts4ld9e24cqjiglia3sfc7c',
    client_secret: 'd8m0eueun0n7q2n0hjnhtuu066dmrlsglp9t7ul8f4nm1ttpl3a',
}
