import { v1 as uuidv1 } from "uuid"

import { GET_HMI_BY_ID } from "../queries/hmi"

export default {
    Query: {
        getHMIById(root, args, { cache }) {
            try {
                const { getHMIById } = cache.readQuery({ query: GET_HMI_BY_ID })

                return {
                    ...getHMIById,
                }
            } catch(err) {
                return {
                    __typename: "GetHMIByIdResponse",
                    root: [
                        
                    ],
                    componentsById: {
                       
                    }
                }
            }
        }
    },
    Mutation: {
        addHMIComponent(root, args, { cache }) {
            console.log("CALLED", args)
            const { getHMIById } = cache.readQuery({ query: GET_HMI_BY_ID, variables: { params: { _id: args.params.hmi }}})

            let data;
            let id = uuidv1()
            if(args.params.parent != null) {
                data = {
                    getHMIById: {
                        ...getHMIById,
                        componentsById: {
                            ...getHMIById.componentsById,
                            [args.params.parent]: {
                                ...getHMIById.componentsById[args.params.parent],
                                children: [
                                    ...getHMIById.componentsById[args.params.parent].children,
                                    id
                                ]
                            },
                            [id]: {
                                id: id,
                                type: args.params.type,
                                properties: args.params.properties,
                                children: args.params.children
                            }
                        }
                    }
                }
            } else {
                data = {
                    getHMIById: {
                        ...getHMIById,
                        root: [
                            ...getHMIById.root,
                            id
                        ],
                        componentsById: {
                            ...getHMIById.componentsById,
                            [id]: {
                                id: id,
                                type: args.params.type,
                                properties: args.params.properties,
                                children: args.params.children
                            }
                        }
                    }
                }
            }
            
            cache.writeQuery({
                query: GET_HMI_BY_ID, 
                variables: { params: { _id: args.params.hmi }},
                data
            })

            return "SUCCEEDED"
        }
    }
}