import React from "react"

import CircularProgress from 'material-ui/CircularProgress';

export default function(props) {
    return props.loading ? 
        <div style={{ position: "fixed", top: 0, zIndex: 1000, height: "100vh", width: "100vw", backgroundColor: props.loading ? "#21212199" : null, pointerEvents: props.loading ? null : "none", transition: "ease 1s" }}>
            <div style={{ display: "table", height: "100%", width: "100%"}}>
                <div style={{ display: "table-cell", height: "100%", width: "100%", textAlign: "center", verticalAlign: "middle"}}>
                    <CircularProgress size={80} thickness={5} />
                </div>
            </div>
        </div> : props.children || null
}