import gql from "graphql-tag"

export const CREATE_WIDGET = gql`
    mutation CreateWidget($params: CreateWidgetRequest) {
        createWidget(params: $params) {
            status
        }
    }
`

