import React, { Suspense, lazy, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, Redirect, useLocation, useRouteMatch } from 'react-router-dom'

import Public from './public/scene'
import Private from './private/scene'

import { setAccessToken } from './public/containers/authentication/reducers/auth'

import Error404 from '../errors/404'
/* 
const Private = lazy(() => import("./private/scene"))
const Public = lazy(() => import("./public/scene"))
 */
function waitingComponent(Component) {
    return (props) => (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    )
}

function Scene() {
    const location = useLocation()

    const access_token = localStorage.getItem('access_token')

    const isLoggedIn = () => !!access_token

    const redirect = !location.hash.length
        ? `#redirect=${location.pathname}`
        : `${location.hash}&redirect=${location.pathname}`

    return (
        <Switch>
            {!isLoggedIn()
                ? [
                      <Redirect from="/private" to={'/' + redirect} />,
                      <Redirect exact from="/" to={'/public' + location.hash} />,
                  ]
                : [<Redirect exact from="/" to={'/private' + location.hash} />]}
            <Route path="/public" component={waitingComponent(Public)} />
            <Route path="/private" component={Private} />
            <Route component={Error404} />
        </Switch>
    )
}

export default Scene
