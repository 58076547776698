export default {
    private: {
        common: {
            accept: "Accept",
            cancel: "Cancel"
        },
        account: {
            common: {
                number: "Number",
                created_at: "Created At",
                period: "Period",
                amount: "Amount",
                status: "Status",
                actions: "Actions",
                plan: "Plan"
            },
            subscriptions: {
                title: "Subscriptions",
                plans: "Plans"
            },
            invoices: {
                title: "Invoices",
                paid: "paid",
                no_invoices_available: "There are no invoces available"
            },
            payment_methods: {
                title: "Payment Methods",
                address: "Address",
                vat: "VAT Number",
                city: "City",
                postal_code: "Postal Code",
                country: "Country",
                expiration_date: "Expiration Date",
                add: {
                    title: "Add Payment Method",
                    name: "Name",
                    card_number: "Card Number",
                    cvc: "CVC"
                }
            }
        }
    }
}