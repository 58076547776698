import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { PropTypes } from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'

import IconButtonStatus from '../../../../../../../../../../../../../utils/common-components/iconButtonStatus'
import { EXPIRE_TRAINING_SET_BY_ID, GET_TRAINING_SETS } from '../../../../../queries/training-sets'

/**
 * Training sets list Row
 */
const TrainingSetsListRow = ({ _id, name, createdAt }) => {
    const match = useRouteMatch()

    const [removeTrainingSet, { data, loading, error }] = useMutation(EXPIRE_TRAINING_SET_BY_ID)

    const mutationError =
        error !== undefined ||
        (data !== undefined &&
            data.expireTrainingSetById !== undefined &&
            data.expireTrainingSetById.status === 'ERROR')

    const expireTrainingSet = async (event) => {
        event.stopPropagation()
        await removeTrainingSet({
            variables: {
                params: {
                    trainingSet: _id,
                    term: 'MID',
                },
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SETS,
                    variables: {
                        params: {
                            parent: null,
                        },
                    },
                },
            ],
            awaitRefetchQueries: true,
        })
    }

    return (
        <ListItem button component={Link} to={`${match.url}/${_id}`}>
            <ListItemAvatar>
                <Avatar>
                    <ImageIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} secondary={createdAt} />
            <ListItemSecondaryAction>
                <IconButtonStatus
                    icon={<DeleteIcon onClick={expireTrainingSet} />}
                    loading={loading}
                    error={mutationError}
                    iconButtonProps={{ edge: 'end', 'aria-label': 'delete' }}
                />
            </ListItemSecondaryAction>
        </ListItem>
    )
}

TrainingSetsListRow.propTypes = {
    /** training set id */
    _id: PropTypes.string.isRequired,
    /** Name of training set */
    name: PropTypes.string.isRequired,
    /** CreatedAt time */
    createdAt: PropTypes.string.isRequired,
}

export default TrainingSetsListRow
