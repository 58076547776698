const OPEN_CREATE_ENTRIES_DIALOG = 'CREATE_ENTRY_DIALOG/OPEN'
const CLOSE_CREATE_ENTRIES_DIALOG = 'CREATE_ENTRY_DIALOG/CLOSE'

const OPEN_UPDATE_TRAINING_SET_CLASSES_DIALOG = 'UPDATE_TRAINING_SET_CLASSES_DIALOG/OPEN'
const CLOSE_UPDATE_TRAINING_SET_CLASSES_DIALOG = 'UPDATE_TRAINING_SET_CLASSES_DIALOG/CLOSE'

const ADD_ENTRIES_DIALOG_CLOSE = 'ADD_ENTRIES_DIALOG/CLOSE'

const SET_CLASS_PROPS = 'SET_CLASS_PROPS'

const SET_FILTER_CHECK_UNLABELED = 'SET_FILTER_CHECK_UNLABELED'
const SET_SELECTED_CLASSES = 'SET_SELECTED_CLASSES'

export const openCreateEntriesDialog = () => ({
    type: OPEN_CREATE_ENTRIES_DIALOG,
})
export const closeCreateEntriesDialog = () => ({
    type: CLOSE_CREATE_ENTRIES_DIALOG,
})
export const openUpdateTrainingSetClassesDialog = () => ({
    type: OPEN_UPDATE_TRAINING_SET_CLASSES_DIALOG,
})
export const closeUpdateTrainingSetClassesDialog = () => ({
    type: CLOSE_UPDATE_TRAINING_SET_CLASSES_DIALOG,
})

export const setCheckFilterLabel = () => ({ type: SET_FILTER_CHECK_UNLABELED })

export const setClasses = (selectedClasses) => ({ type: SET_SELECTED_CLASSES, selectedClasses })
export const setClassProp = (classProps, classIndex) => ({
    type: SET_CLASS_PROPS,
    classProps,
    classIndex,
})

export const initialState = {
    createEntriesDialogOpen: false,
    updateTrainingSetClassesDialogOpen: false,
    classProps: [],
    labels: {
        byId: {},
        allIds: [],
        showUnlabelled: true,
        selectedClasses: null,
    },
}

export const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_CREATE_ENTRIES_DIALOG:
            return { ...state, createEntriesDialogOpen: true }
        case CLOSE_CREATE_ENTRIES_DIALOG:
            return { ...state, createEntriesDialogOpen: false }
        case OPEN_UPDATE_TRAINING_SET_CLASSES_DIALOG:
            return { ...state, updateTrainingSetClassesDialogOpen: true }
        case CLOSE_UPDATE_TRAINING_SET_CLASSES_DIALOG:
            return { ...state, updateTrainingSetClassesDialogOpen: false }
        case ADD_ENTRIES_DIALOG_CLOSE:
            return { ...state, addEntryDialog: false }
        case SET_CLASS_PROPS:
            if (action.classIndex >= state.classProps.length) {
                return {
                    ...state,
                    classProps: [...state.classProps, action.classProps],
                }
            }
            return {
                ...state,
                classProps: [
                    ...state.classProps.slice(0, action.classIndex),
                    { ...state.classProps[action.classIndex], ...action.classProps },
                    ...state.classProps.slice(action.classIndex + 1),
                ],
            }
        case SET_FILTER_CHECK_UNLABELED:
            return {
                ...state,
                labels: { ...state.labels, showUnlabelled: !state.labels.showUnlabelled },
            }
        case SET_SELECTED_CLASSES:
            return {
                ...state,
                labels: { ...state.labels, selectedClasses: action.selectedClasses },
            }
        default:
            return state
    }
}
