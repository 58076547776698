import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import ListGroups from './containers/list-groups/scene'
import CreateGroup from './containers/create-group/scene'
import ListIncidents from './containers/list-incidents/scene'
import ViewIncident from './containers/view-incident/scene'

const Monitors = (props) => {
    return (
        <Switch>
            <Redirect exact from={props.match.url} to={props.match.url + '/list'} />
            <Route path={props.match.url + '/list'} component={ListGroups} />
            <Route path={props.match.url + '/create'} component={CreateGroup} />
            <Route path={props.match.url + '/:group/:incident'} component={ViewIncident} />
            <Route path={props.match.url + '/:group'} component={ListIncidents} />
        </Switch>
    )
}

export default Monitors
