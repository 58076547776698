import React from "react"

import { useParams } from "react-router-dom"
import { useQuery, useApolloClient } from "@apollo/react-hooks"
import { useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

import MapView from "./containers/map-view/scene"
import SideMenu from "./containers/side-menu/scene"
import UploadDialog from "./containers/upload-dialog"
import OrthophotosUploadDialog from "./containers/orthophotos-upload-dialog"
import AnalyseDialog from "./containers/analyse-dialog"
import ExportCsvDialog from "./containers/export-csv-dialog/scene"

import { GET_ALL_IMAGES } from "./queries/images"
import { GET_ORTHOPHOTOS_BY_DATASET } from "./queries/orthophotos"
import { GET_HOTSPOTS_BY_DATASET } from "./queries/hotspots"

import resolvers from "./resolvers"

function Viewer(props) {
    const client = useApolloClient()

    client.addResolvers(resolvers)

    const { inspection } = useParams()

    const { upload_dialog_open, orthophotos_upload_dialog_open } = useSelector(state => state.inspector.solar.general)
    
    const { data: { allImages = [] } = {}} = useQuery(GET_ALL_IMAGES, { variables: { params: { dataset: inspection }}})
    const { data: { getOrthophotosByDataset: { orthophotos = [] } = {} } = {}} = useQuery(GET_ORTHOPHOTOS_BY_DATASET, { variables: { params: { dataset: inspection }}})
    const { data: { getHotspotsByDataset: { hotspots = [] } = {}} = {}} = useQuery(GET_HOTSPOTS_BY_DATASET, { variables: { params: { dataset: inspection }}})

    const images = allImages.filter(img => !!img.position)

    return (
        <React.Fragment>
            <Grid container style={{ height: "calc(100vh - 63px)", overflow: "hidden"}}>
                <Grid item lg md={8} xs={0} >
                    <Grid item lg={12}>
                     <MapView 
                            dataset={inspection} 
                            hotspots={hotspots} 
                            images={images} 
                            orthophotos={orthophotos} 
                            height={"100vh"}
                        />
                    </Grid>
                </Grid>
                <Grid item lg={3} md={4} xs={12} style={{ height: "100%" }}>
                    <Paper style={{ height: "100%", width: "100%", position: "relative" }}>
                        <SideMenu images={images}/>
                    </Paper>
                </Grid>
            </Grid>
            <UploadDialog open={upload_dialog_open} />
            <OrthophotosUploadDialog open={orthophotos_upload_dialog_open}/>
            <AnalyseDialog/>
            <ExportCsvDialog/>
        </React.Fragment>
    )
}

export default Viewer