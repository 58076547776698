import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid"
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from "@material-ui/core/Paper"

import { GET_TRAINING_SET_BY_ID } from "../../../../queries/training-sets"

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3)
    }
}));

export default (props) => {
    const classes = useStyles();

    const { trainingSet: _id = ""} = useParams();

    const { data: { getTrainingSetById: { trainingSet = {}} = {} } = {}} = useQuery(GET_TRAINING_SET_BY_ID, { variables: { params: { _id } }})

    return (
        <Paper className={classes.paper}>
            <Grid container xs={12}>
                <Grid item xs={12}>
                    <List>
                        <ListSubheader>Clases</ListSubheader>
                        {
                            trainingSet.classes && trainingSet.classes.map(c => (
                                <ListItem button>
                                    <ListItemText primary={c} />
                                </ListItem>
                            ))
                        }
                    </List>
                </Grid>
            </Grid>
        </Paper>
    )
}