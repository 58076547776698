import { GET_SUBSCRIPTION_BY_PRODUCT } from "../queries/subscriptions"

import { v1 as uuidv1 } from "uuid"

export default {
    Mutation: {
        selectPlan(root, { params }, { cache }) {
            let data
            let getSubscriptionByProduct
            try {
                const res = cache.readQuery({ query: GET_SUBSCRIPTION_BY_PRODUCT, variables: { params: { product: "horus" }} })   
                getSubscriptionByProduct = res.getSubscriptionByProduct
            } catch(err) {
                getSubscriptionByProduct = {
                    status: "SUCCEEDED",
                    local: true,
                    subscription: {
                        _id: uuidv1(),
                        items: [],
                        __typename: "Subscription"
                    },
                    __typename: "GetSubscriptionByProductResponse"
                }
            }
          
            if(!getSubscriptionByProduct.subscription) {
                getSubscriptionByProduct = {
                    status: "SUCCEEDED",
                    local: true,
                    subscription: {
                        _id: uuidv1(),
                        items: [],
                        __typename: "Subscription"
                    },
                    __typename: "GetSubscriptionByProductResponse"
                }
            }

            const { subscription: { items = [] } = {}} = getSubscriptionByProduct

            switch(params.action) {
                case "add":
                    data = {
                        ...getSubscriptionByProduct,
                        subscription: {
                            ...getSubscriptionByProduct.subscription,
                            items: [
                                ...items,
                                {
                                    _id: uuidv1(),
                                    plan: {
                                        _id: params.plan,
                                        __typename: "Plan"
                                    },
                                    ...params.quantity ? { quantity: params.quantity } : {},
                                    __typename: "Item"
                                }
                            ]
                        }
                    }
                    break;
                case "remove":
                    const removeIndex = items.findIndex(item => item._id === params.item)
                    data =  {
                        ...getSubscriptionByProduct,
                        subscription: {
                            ...getSubscriptionByProduct.subscription,
                            items: [
                                ...items.slice(0, removeIndex),
                                ...items.slice(removeIndex + 1)
                            ]
                        }
                    }
                    break;
                case "replace":
                    const index = items.findIndex(item => item._id === params.item)
                    data =  {
                        ...getSubscriptionByProduct,
                        subscription: {
                            ...getSubscriptionByProduct.subscription,
                            items: [
                                ...items.slice(0, index),
                                {
                                    ...items[index],
                                    _id: params.item,
                                    plan: {
                                        ...items[index].plan,
                                        _id: params.plan
                                    }
                                },
                                ...items.slice(index + 1)
                            ]
                        }
                    }
                    break;
            }
            
            cache.writeQuery({
                query: GET_SUBSCRIPTION_BY_PRODUCT,
                variables: {
                    params: {
                        product: "horus"
                    }
                },
                data: {
                    getSubscriptionByProduct: data
                }
            })
    
            return "SUCCEEDED"
        }
    },
    GetSubscriptionByProductResponse: {
        local: (root) => {
            return !!root.local
        }
    }
}
