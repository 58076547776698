import gql from "graphql-tag"

export const GET_SENSORS_BY_GROUP = gql`
    query GetSensorsByGroup($params: GetSensorsByGroupRequest) {
        getSensorsByGroup(params: $params) {
            status
            sensors {
                _id
                group
                sensorId
                gatewayId
                name
                battery
                nodeRssi
                gatewayRssi
            }
        }
    }
`

export const SET_SENSOR_NAME = gql`
    mutation SetSensorName($params: SetSensorNameRequest) {
        setSensorName(params: $params) {
            status
        }
    }
`

export const SET_SENSOR_PARAMS = gql`
    mutation SetSensorParams($params: SetSensorParamsRequest) {
        setSensorParams(params: $params) {
            status
        }
    }
`