import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { useMutation, useQuery } from "@apollo/react-hooks"

import Slider from '@material-ui/core/Slider';
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from "@material-ui/core/Divider"

import { setMapPointSize } from "../../../../../../reducers/settings"

import { VISIBILITY, SET_VISIBILITY } from "../../../../queries/local"

export default function() {
    const dispatch = useDispatch()

    const { point_size } = useSelector(state => state.inspector.solar.settings)

    const { data: { visibility: { radiometric = false, hotspots = false, orthophotos = false } = {} } = {}} = useQuery(VISIBILITY)
    const [ setVisibility ] = useMutation(SET_VISIBILITY)
    const d = useQuery(VISIBILITY)

    const onPointSizeChanged = (event, value) => {
        dispatch(setMapPointSize(value))
    }

    const onImagesToggled = e => {
        setVisibility({ variables: { type: "radiometric", value: e.target.checked }, refetchQueries: [VISIBILITY]})
    }
    const onHotspotsToggled = e => {
        setVisibility({ variables: { type: "hotspots", value: e.target.checked }, refetchQueries: [VISIBILITY]})
    }
    const onOrthophotosToggled = e => {
        setVisibility({ variables: { type: "orthophotos", value: e.target.checked }, refetchQueries: [VISIBILITY]})
    }
    return (
        <List>
            <ListSubheader>Visibilidad</ListSubheader>
            <ListItem button>
                <ListItemIcon>
                    <Checkbox edge="start" checked={radiometric} onChange={onImagesToggled}/>
                    <ListItemText primary={"Imágenes"} secondary={"Controla la visibilidad de las imágenes en el mapa"} />    
                </ListItemIcon>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Checkbox edge="start" checked={hotspots} onChange={onHotspotsToggled}/>
                    <ListItemText primary={"Puntos calientes"} secondary={"Controla la visibilidad de los puntos calientes en el mapa"}/>    
                </ListItemIcon>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Checkbox edge="start" checked={orthophotos} onChange={onOrthophotosToggled}/>
                    <ListItemText primary={"Ortofotos"} secondary={"Controla la visibilidad de las ortofotos en el mapa"}/>    
                </ListItemIcon>
            </ListItem>
            <Divider/>
            <ListSubheader>Tamaño del marcador</ListSubheader>
            <ListItem>
                <Slider
                    defaultValue={30}
                    getAriaValueText={""}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    value={point_size}
                    min={0}
                    max={10}
                    onChange={onPointSizeChanged}
                />
            </ListItem>
        </List>
    )
} 