import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import CameraAltIcon from '@material-ui/icons/CameraAlt'

import QrReader from 'react-qr-reader'

import { CREATE_HMI } from '../../queries/hmis'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const NewHmi = () => {
    const classes = useStyles()

    const { group } = useParams()

    const [open, setOpen] = useState(false)
    const [scan, setScan] = useState(false)
    const [loading, setLoading] = useState(false)
    const [thing, setThing] = useState('')
    const [name, setName] = useState('')

    const [createHMI] = useMutation(CREATE_HMI)

    const onScan = (data) => {
        if (data) {
            const parsed = JSON.parse(data)
            setThing(parsed['thing_id']) // eslint-disable-line dot-notation
            setScan(false)
        }
    }

    const onError = (err) => {
        console.log(err)
    }

    const onCameraClicked = () => {
        setScan(true)
    }

    const onThingChange = (e) => {
        setThing(e.target.value)
    }

    const onNameChange = (e) => {
        setName(e.target.value)
    }

    const onFabClicked = () => setOpen(true)
    const onCancelClicked = () => setOpen(false)
    const onAcceptClicked = async () => {
        setLoading(true)
        await createHMI({ variables: { params: { group, name, thing } } })
        setLoading(false)
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Tooltip title="Nuevo HMI" placement="left">
                <Fab color="primary" className={classes.fab} onClick={onFabClicked}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open}>
                <DialogTitle>Nuevo HMI</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Nombre"
                        value={name}
                        onChange={onNameChange}
                        disabled={loading}
                        fullWidth
                    />
                    {scan && (
                        <QrReader
                            delay={300}
                            onError={onError}
                            onScan={onScan}
                            style={{ width: '100%', transform: 'rotateY(180deg)' }}
                        />
                    )}
                    <TextField
                        label="Identificador"
                        value={thing}
                        onChange={onThingChange}
                        disabled={loading}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={onCameraClicked}>
                                    <CameraAltIcon />
                                </IconButton>
                            ),
                        }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} color="primary" disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAcceptClicked} color="primary" disabled={loading} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default NewHmi
