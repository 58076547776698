import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@mui/material/Tooltip'

import {
    GET_MODELS,
    GET_DELIVERABLES_BY_MODEL,
} from '../../../../../../../../../models/queries/models'
import { CREATE_RESOURCE, GET_RESOURCES } from '../../../../../../../../queries/resources'

const MachineLearning = ({ onCancel }) => {
    const { group } = useParams()
    const [name, setName] = useState('')
    const [model, setModel] = useState(null)
    const [deliverable, setDeliverable] = useState(null)
    const [loading, setLoading] = useState(false)

    const { data: { getModels: { models = [] } = {} } = {} } = useQuery(GET_MODELS)
    const { data: { getDeliverablesByModel: { deliverables = [] } = {} } = {} } = useQuery(
        GET_DELIVERABLES_BY_MODEL,
        { variables: { params: { model } }, skip: !model }
    )

    const [createResource] = useMutation(CREATE_RESOURCE)

    const onAccept = async () => {
        setLoading(true)
        await createResource({
            variables: {
                params: {
                    group,
                    name,
                    type: 'machine-learning-v2',
                    config: JSON.stringify({ deliverable }),
                },
            },
            refetchQueries: [
                {
                    query: GET_RESOURCES,
                    variables: {
                        params: {
                            group,
                        },
                    },
                },
            ],
            awaitRefetchQueries: true,
        })
        setLoading(false)
        onCancel()
    }

    const onModelChanged = (event) => {
        setModel(event.target.value)
    }

    const onDeliverableChanged = (event) => {
        setDeliverable(event.target.value)
    }

    const onNameChanged = (event) => {
        setName(event.target.value)
    }

    return (
        <React.Fragment>
            <DialogContent>
                <TextField label="Nombre" fullWidth value={name} onChange={onNameChanged} />
                <FormControl fullWidth>
                    <InputLabel>Modelo</InputLabel>
                    <Select onChange={onModelChanged} value={model}>
                        {models
                            .filter((m) => m.state === 'SUCCEEDED')
                            .map((m) => (
                                <MenuItem key={m._id} value={m._id}>
                                    {m.name || m._id}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Entregable</InputLabel>
                    <Select onChange={onDeliverableChanged} value={deliverable}>
                        {deliverables.map((d) => {
                            return (
                                <Tooltip
                                    key={d._id}
                                    title={<p style={{ fontSize: 12 }}>{d._id}</p>}
                                    value={d._id}
                                >
                                    <MenuItem>{d.device}</MenuItem>
                                </Tooltip>
                            )
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={onCancel}>
                    Cancelar
                </Button>
                <Button disabled={loading} onClick={onAccept}>
                    Aceptar
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}

MachineLearning.propTypes = {
    onCancel: PropTypes.func.isRequired,
}

export default MachineLearning
