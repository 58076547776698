let app = exports.app = {
    frontend: {
        url: "https://app.flightlabs.es"
    },
    backend: {
        url: "https://api.flightlabs.es"
    },
    mapbox_token: 'pk.eyJ1IjoibG9yaWVuYmVybmUiLCJhIjoiY2plenJxZXh6MGV1ajJxbzlnNHJ3bzkwdiJ9.3fMtyRl6UG-H_JogmGAQjA'
}

let cognito = exports.cognito = {
    url: `https://flightlabs.auth.eu-central-1.amazoncognito.com`,
    client_id: "57h21qkklq4uo364fj3qrule67",
    client_secret: "1rei1v5s4diprv95vbg43ap08488rsgg1p7fkqa3ccr0krlq6v29"
}

