import gql from "graphql-tag";

export const GET_MESSAGES_BY_OWNER = gql`
    query GetMessagesByOwner($params: GetMessagesByOwnerRequest) {
        getMessagesByOwner(params: $params) {
            status
            error
            messages {
                id
                text
                owner
                createdAt
            }
        }
    }
`;

export const CREATE_MESSAGE = gql`
    mutation CreateMessage($params: CreateMessageRequest) {
        createMessage(params: $params) {
            status
            error
        }
    }
`;