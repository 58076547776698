import React, { useState } from "react"

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import Dialog from "@material-ui/core/Dialog"
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button"
import CircularProgress from '@material-ui/core/CircularProgress';

function LoadingDialog(props) {
    const classes = useStyles();

    return (
        <Dialog {...props}>
            <DialogActions>
                {props.children}
                <Button disabled={loading} onClick={props.onCancel}>Cancelar</Button>
                <div className={classes.wrapper}>
                    <Button disabled={props.loading} onClick={props.onAccept}>Aceptar</Button>
                    {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default LoadingDialog