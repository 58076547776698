import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import { GET_SENSORS_BY_GROUP, SET_SENSOR_NAME } from "../../queries/sensors"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SetSensorParamsDialog from "./containers/set-sensor-params-dialog/scene"

const SensorList = (props) => {

    const { group } = useParams()

    const [ open, setOpen ] = useState(false)
    const [ selected, setSelected ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ name, setName ] = useState("")

    const { data: { getSensorsByGroup: { sensors = [] } = {}, loading: getSensorsByGroupLoading } = {}} = useQuery(GET_SENSORS_BY_GROUP, { variables: { params: { group }}})
    const [ setSensorName ] = useMutation(SET_SENSOR_NAME)

    const onEditClicked = i => () => {
        setSelected(i)
        setOpen(true)
    }

    const onNameChange = e => {
        setName(e.target.value)
    }

    const onAcceptClicked = async () => {
        setLoading(true)

        await setSensorName({ 
            variables: { params: { sensor: sensors[selected]._id, name }},
            refetchQueries: [
                {
                    query: GET_SENSORS_BY_GROUP,
                    variables: {
                        params: {
                            group
                        }
                    }
                }
            ]
        })

        setLoading(false)

        setOpen(false)
    }

    const onCancelClicked = () => {
        setOpen(false)
    }

    if(getSensorsByGroupLoading) {
        return (
            <Grid container justify="center" style={{ padding: 50 }}>
                <CircularProgress size={48} />
            </Grid>
        )
    }

    if(!sensors.length) {
        return (
            <p style={{ textAlign: "center" }}>No hay sensores. Añada un gateway en "DISPOSITIVOS" y los sensores conectados iran apareciendo aquí</p>
        )
    }

    
    return (
        <React.Fragment>
            <Dialog open={open}>
                <DialogContent>
                    <TextField label="Nombre" onChange={onNameChange}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disabled={loading}>Cancel</Button>
                    <Button onClick={onAcceptClicked} disabled={loading}>Accept</Button>
                </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Gateway ID</TableCell>
                            <TableCell>Sensor ID</TableCell>
                            <TableCell>Battery</TableCell>
                            <TableCell>Gateway RSSI</TableCell>
                            <TableCell>Sensor RSSI</TableCell>
                            <TableCell>Last Message</TableCell>
                            <TableCell style={{ width: 250 }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            sensors.map((s, i) => {
                                let batteryLevelColor = "#000000"
                                if(s.battery > 312) {
                                    batteryLevelColor = "#00FF00"
                                } else if(s.battery > 288) {
                                    batteryLevelColor = "#FFFF00"
                                } else if(s.battery > 280) {
                                    batteryLevelColor = "#FF0000"
                                }

                                return (
                                    <TableRow>
                                        <TableCell>{s.name}</TableCell>
                                        <TableCell>{s.gatewayId}</TableCell>
                                        <TableCell>{s.sensorId}</TableCell>
                                        <TableCell><FiberManualRecordIcon style={{ color: batteryLevelColor }} /></TableCell>
                                        <TableCell>{s.gatewayRssi}</TableCell>
                                        <TableCell>{s.nodeRssi}</TableCell>
                                        <TableCell>{s.lastMessageTimestamp}</TableCell>
                                        <TableCell style={{ width: 150 }}>
                                            <SetSensorParamsDialog sensor={s}/>
                                            <IconButton onClick={onEditClicked(i)}><EditIcon/></IconButton>
                                            <IconButton><DeleteIcon/></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer> 
        </React.Fragment>
    )
}

export default SensorList