import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import * as EmailValidator from 'email-validator'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import EmailIcon from '@material-ui/icons/Email'

import LeftSide from '../components/LeftSide'
import RightSide from '../components/RightSide'
import Container from '../components/Container'

import { graphql, compose } from 'react-apollo'
import { FORGOT_PASSWORD } from '../queries/authentication'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        fontSize: 15,
    },
}))

const ForgotPassword = (props) => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [email, setEmail] = useState('')

    const [emailError, setEmailError] = useState('')

    const onEmailChange = (e) => setEmail(e.target.value)

    const onSubmit = async () => {
        if (!EmailValidator.validate(email)) {
            setEmailError('Introduzca un email válido')
            return
        } else {
            setEmailError('')
        }

        setLoading(true)
        try {
            await props.forgotPassword({ email })
            props.history.push('confirm-forgot-password')
        } catch (err) {
            console.log('ERROR')
            setOpen(true)
        } finally {
            setLoading(false)
        }
    }
    const onAccept = () => setOpen(false)

    return (
        <Container>
            <LeftSide />
            <RightSide>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" style={{ marginBottom: 10 }}>
                        Restore Password
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={email}
                        label="Email"
                        placeholder="Enter you email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        error={emailError.length}
                        helperText={emailError}
                        onChange={onEmailChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        fullWidth
                        loading={loading}
                        onClick={onSubmit}
                    >
                        Restore
                    </Button>
                </Grid>
            </RightSide>
            <Dialog open={open}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        La información introducida no corresponde a ningún cliente
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default compose(
    graphql(FORGOT_PASSWORD, {
        props: ({ mutate }) => {
            return {
                forgotPassword: (params) => mutate({ variables: { params } }),
            }
        },
    })
)(ForgotPassword)
