import React, { useState } from 'react'
// import { PropTypes } from 'prop-types'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useQuery } from '@apollo/react-hooks'
import Tooltip from '@mui/material/Tooltip'

import { useGetDatasetsV2ByParent } from 'queries/datasets'
import { GET_MODELS, GET_DELIVERABLES_BY_MODEL } from '../../../../../models/queries/models'

const DeliverableSelect = ({ port, portMetadata, updateParameter }) => {
    const [model, setModel] = useState(null)
    const [deliverable, setDeliverable] = useState(null)

    const { data: { getModels: { models = [] } = {} } = {} } = useQuery(GET_MODELS)
    const { data: { getDeliverablesByModel: { deliverables = [] } = {} } = {} } = useQuery(
        GET_DELIVERABLES_BY_MODEL,
        { variables: { params: { model } }, skip: !model }
    )

    const onModelChanged = (event) => {
        setModel(event.target.value)
    }

    const onDeliverableChanged = (event) => {
        setDeliverable(event.target.value)
        updateParameter(event.target.value)
    }

    return (
        <React.Fragment>
            <InputLabel>{portMetadata.humanLabel ? portMetadata.humanLabel : port.id}</InputLabel>
            <FormControl fullWidth>
                <InputLabel>Modelo</InputLabel>
                <Select onChange={onModelChanged} value={model}>
                    {models
                        .filter((m) => m.state === 'SUCCEEDED')
                        .map((m) => (
                            <MenuItem key={m._id} value={m._id}>
                                {m.name || m._id}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Entregable</InputLabel>
                <Select onChange={onDeliverableChanged} value={deliverable}>
                    {deliverables.map((d) => {
                        return (
                            <Tooltip
                                key={d._id}
                                title={<p style={{ fontSize: 12 }}>{d._id}</p>}
                                value={d._id}
                            >
                                <MenuItem>{d.device}</MenuItem>
                            </Tooltip>
                        )
                    })}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}

const DatasetSelect = ({ port, portMetadata, updateParameter }) => {
    const [dataset, setDataset] = useState(null)

    const { datasets = [], loading } = useGetDatasetsV2ByParent(undefined, false)

    const onDatasetChange = (event) => {
        setDataset(event.target.value)
        updateParameter(event.target.value)
    }

    return (
        <FormControl fullWidth>
            <InputLabel>{portMetadata.humanLabel ? portMetadata.humanLabel : port.id}</InputLabel>
            <Select onChange={onDatasetChange} value={dataset}>
                {datasets.map((d) => (
                    <MenuItem key={d.id} value={d.id}>
                        {d.name || d.id}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const SelectWidget = ({ port, portMetadata, updateParameter }) => {
    const [value, setValue] = useState(null)
    if (portMetadata['options'] === '$fl.models.deliverables') {
        return (
            <DeliverableSelect
                port={port}
                portMetadata={portMetadata}
                updateParameter={updateParameter}
            />
        )
    }
    if (portMetadata['options'] === '$fl.dataset') {
        return (
            <DatasetSelect
                port={port}
                portMetadata={portMetadata}
                updateParameter={updateParameter}
            />
        )
    }

    const options = portMetadata['options'] || []

    const onChange = (e) => {
        updateParameter(e.target.value)
        setValue(e.target.value)
    }
    return (
        <FormControl fullWidth>
            <InputLabel>{portMetadata.humanLabel ? portMetadata.humanLabel : port.id}</InputLabel>
            <Select onChange={onChange} value={value}>
                {options.map((m) => (
                    <MenuItem key={m} value={m}>
                        {m}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default SelectWidget

// <FormControl fullWidth>
// <InputLabel id="demo-simple-select-label">Age</InputLabel>
// <Select
//     labelId="demo-simple-select-label"
//     id="demo-simple-select"
//     value={age}
//     label="Age"
//     onChange={handleChange}
// >
//     <MenuItem value={10}>Ten</MenuItem>
//     <MenuItem value={20}>Twenty</MenuItem>
//     <MenuItem value={30}>Thirty</MenuItem>
// </Select>
// </FormControl>
