import React, { useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import ListSubheader from '@material-ui/core/ListSubheader'

import AutoComplete from '@material-ui/lab/Autocomplete'

import { useGetModels, useGetDeliverablesByModel, useGetModel } from 'queries/models'

import {
    setInferenceModel,
    setInferenceModelShape,
    setInferenceDeliverable,
    setInferenceEnabled,
    setInferenceClassThreshold,
    setInferenceConfidenceThreshold,
    setInferenceNmsThreshold,
    setInferenceModelClasses,
    setInferenceFontSize,
    setInferenceDevice,
} from '../../../../../viewer/reducers/settings'

const Scene = () => {
    const dispatch = useDispatch()

    const {
        inferenceEnabled,
        inferenceModel,
        inferenceDeliverable,
        inferenceConfidenceThreshold,
        inferenceClassThreshold,
        inferenceNmsThreshold,
        inferenceFontSize,
        inferenceDevice,
    } = useSelector((state) => state.datasets.settings)

    const { models = [] } = useGetModels()
    const { deliverables = [] } = useGetDeliverablesByModel(inferenceModel)
    const { model: modelInfo = {} } = useGetModel(inferenceModel)

    const { allModels, modelsById } = useMemo(() => {
        return {
            allModels: models.map((model) => model._id),
            modelsById: models.reduce((acc, model) => {
                acc[model._id] = model
                return acc
            }, {}),
        }
    }, [models])

    const { allDeliverables, deliverablesById } = useMemo(() => {
        return {
            allDeliverables: deliverables.map((deliverable) => deliverable._id),
            deliverablesById: deliverables.reduce((acc, deliverable) => {
                acc[deliverable._id] = deliverable
                return acc
            }, {}),
        }
    }, [deliverables])

    const onModelChange = (event, newValue) => {
        dispatch(setInferenceModel(newValue))
    }

    const onDeliverableChange = (event, newValue) => {
        dispatch(setInferenceModelShape(modelInfo.shape))
        dispatch(setInferenceModelClasses(modelInfo.classes))
        const del = deliverablesById[newValue]
        dispatch(
            setInferenceDeliverable({
                inferenceDeliverable: newValue,
                inferenceDeliverableUrl: del.url,
            })
        )
    }

    const onSave = () => {
        const del = deliverablesById[inferenceDeliverable]
        dispatch(
            setInferenceDeliverable({
                inferenceDeliverable,
                inferenceDeliverableUrl: del.url,
            })
        )
    }

    const onEnableChange = (event) => {
        dispatch(setInferenceEnabled(event.target.checked))
    }

    const onConfidenceThresholdChange = (event) => {
        dispatch(setInferenceConfidenceThreshold(event.target.value))
    }

    const onClassThresholdChange = (event) => {
        dispatch(setInferenceClassThreshold(event.target.value))
    }

    const onNmsThresholdChange = (event) => {
        dispatch(setInferenceNmsThreshold(event.target.value))
    }

    const onFontSizeChange = (event) => {
        dispatch(setInferenceFontSize(event.target.value))
    }

    const onDeviceChange = (event) => {
        dispatch(setInferenceDevice(event.target.value))
    }

    const renderModels = () => {
        if (inferenceEnabled === false) {
            return null
        }

        return (
            <React.Fragment>
                <ListSubheader>Modelo</ListSubheader>
                <ListItem>
                    <AutoComplete
                        id="model"
                        options={allModels}
                        getOptionLabel={(option) => {
                            const model = modelsById[option]
                            return model.name || ''
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Model" variant="outlined" fullWidth />
                        )}
                        onChange={onModelChange}
                        fullWidth
                        value={inferenceModel}
                    />
                </ListItem>
            </React.Fragment>
        )
    }

    const renderDeliverables = () => {
        if (inferenceModel === null) {
            return null
        }

        return (
            <ListItem>
                <AutoComplete
                    id="deliverable"
                    options={allDeliverables}
                    renderInput={(params) => (
                        <TextField {...params} label="Deliverable" variant="outlined" fullWidth />
                    )}
                    onChange={onDeliverableChange}
                    fullWidth
                    value={inferenceDeliverable}
                />
            </ListItem>
        )
    }

    const renderOptions = () => {
        if (inferenceDeliverable === null) {
            return null
        }

        return (
            <React.Fragment>
                <ListItem>
                    <TextField
                        label="Umbral de confianza"
                        variant="outlined"
                        fullWidth
                        type="number"
                        onChange={onConfidenceThresholdChange}
                        value={inferenceConfidenceThreshold}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Umbral de clase"
                        variant="outlined"
                        fullWidth
                        type="number"
                        onChange={onClassThresholdChange}
                        value={inferenceClassThreshold}
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Umbral NMS"
                        variant="outlined"
                        fullWidth
                        type="number"
                        onChange={onNmsThresholdChange}
                        value={inferenceNmsThreshold}
                    />
                </ListItem>
                <ListItem>
                    <AutoComplete
                        id="device"
                        options={['cpu', 'webgl']}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Dispositivo"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                        onChange={onDeviceChange}
                        fullWidth
                        value={inferenceDevice}
                    />
                </ListItem>
                <Divider />
                <ListSubheader>Estilo</ListSubheader>
                <ListItem>
                    <TextField
                        label="Tamaño Fuente"
                        variant="outlined"
                        fullWidth
                        type="number"
                        onChange={onFontSizeChange}
                        value={inferenceFontSize}
                    />
                </ListItem>
            </React.Fragment>
        )
    }

    const renderSave = () => {
        if (inferenceDeliverable === null) {
            return null
        }

        return (
            <ListItem>
                <Button variant="contained" color="primary" onClick={onSave}>
                    Save
                </Button>
            </ListItem>
        )
    }

    console.log('models', allModels)
    console.log('deliverables', allDeliverables)

    return (
        <Grid item xs={12}>
            <List>
                <ListItem>
                    <ListItemText primary="Inference" />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={onEnableChange}
                            checked={inferenceEnabled}
                            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                {renderModels()}
                {renderDeliverables()}
                {renderOptions()}
                {renderSave()}
            </List>
        </Grid>
    )
}

export default Scene
