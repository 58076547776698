import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DynamicModuleLoader } from 'redux-dynamic-modules'

import Groups from './containers/groups/index'
import Group from './containers/group/index'
import Designer from './containers/designer/index'
import Device from './containers/device/scene'
import HMI from './containers/hmi-designer/scene'
import getThingsModule from './index'

const Devices = ({ match }) => {
    return (
        <DynamicModuleLoader modules={[getThingsModule()]}>
            <DndProvider backend={HTML5Backend}>
                <Switch>
                    <Route exact path={`${match.url}/`} component={Groups} />
                    <Route exact path={`${match.url}/:group`} component={Group} />
                    <Route
                        exact
                        path={`${match.url}/:group/blueprints/:blueprint`}
                        component={Designer}
                    />
                    <Route exact path={`${match.url}/:group/devices/:device`} component={Device} />
                    <Route exact path={`${match.url}/:group/hmi/:hmi`} component={HMI} />
                </Switch>
            </DndProvider>
        </DynamicModuleLoader>
    )
}

Devices.propTypes = { match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired }

export default Devices
