import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParam, JsonParam, StringParam } from 'use-query-params'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Chip from '@material-ui/core/Chip'
import TableChartIcon from '@material-ui/icons/TableChart'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@material-ui/core/IconButton'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { useIsAllowed } from 'queries/permissions'
import Spreadsheet from './containers/spreadsheet/scene'
import Gallery from './containers/gallery/scene'
import ExportDialog from './containers/export-dialog/scene'
import VirtualColumnManager from './containers/virtualColumn/scene'
import CreateTrainingSetEntriesDialog from './containers/create-training-set-entries-dialog/scene'
import ImageViewer from './containers/image-viewer/scene'
import Settings from './containers/settings/scene'
import { contractRightPanel, expandRightPanel } from './reducers/index'
import { GET_DATASETS_V2_ENTRIES_BY_DATASET, GET_DATASETS_V2_BY_ID } from '../../queries/datasets'

const views = {
    spreadsheet: Spreadsheet,
    gallery: Gallery,
}

const filtersMap = {
    contains: 'CONTIENE',
    'not-contains': 'NO CONTIENE',
    equals: 'ES IGUAL A',
    'not-equals': 'NO ES IGUAL A',
    'less-than': 'MENOR QUE',
    'less-than-or-equal': 'MENOR O IGUAL QUE',
    'more-than': 'MAYOR QUE',
    'more-than-or-equal': 'MAYOR O IGUAL QUE',
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}))

const Viewer = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { isAllowed: isExportDatasetAllowed } = useIsAllowed({
        group: 'datasets',
        action: 'exportDatasetV2EntriesByDataset',
    })
    const { isAllowed: isCreateTrainingSetEntries } = useIsAllowed({
        group: 'trainingSets',
        action: 'createTrainingSetEntries',
    })

    const [page = 0, setPage] = useQueryParam('page', JsonParam)
    const [fromDate = 0, setFromDate] = useQueryParam('from-date', StringParam)
    const [toDate = 0, setToDate] = useQueryParam('to-date', StringParam)
    const [filters = [], setFilters] = useQueryParam('filters', JsonParam)
    const [sort = [{ field: 'createdAt', criteria: 'desc' }], setSort] = useQueryParam(
        'sort',
        JsonParam
    )

    const { rightPanelOpen } = useSelector((state) => state.datasets.viewer)

    const { dataset: datasetId } = useParams()
    /* eslint-disable no-unused-vars */
    const [_, setUpdate] = useState([])
    const [view, setView] = useState('spreadsheet')

    const [rowsPerPage, setRowsPerPage] = useState(100)

    const [currentFilterField, setCurrentFilterField] = useState('')
    const [currentFilterType, setCurrentFilterType] = useState('')
    const [currentFilterClause, setCurrentFilterClause] = useState('')
    const [currentFilterConnector, setCurrentFilterConnector] = useState(null)
    const [sortField, setSortField] = useState('')
    const [sortCriteria, setSortCriteria] = useState('')

    useEffect(() => {
        if (!fromDate && !toDate) {
            const today = new Date()
            const priorDate = new Date(new Date().setDate(today.getDate() - 1))

            const fromStringYear = priorDate.getFullYear()
            let fromStringMonth = priorDate.getMonth() + 1
            if (fromStringMonth < 10) {
                fromStringMonth = `0${fromStringMonth}`
            }
            let fromStringDay = priorDate.getDate()
            if (fromStringDay < 10) {
                fromStringDay = `0${fromStringDay}`
            }
            let fromStringHour = priorDate.getHours()
            if (fromStringHour < 10) {
                fromStringHour = `0${fromStringHour}`
            }
            let fromStringMinute = priorDate.getMinutes()
            if (fromStringMinute < 10) {
                fromStringMinute = `0${fromStringMinute}`
            }

            const toStringYear = today.getFullYear()
            let toStringMonth = today.getMonth() + 1
            if (toStringMonth < 10) {
                toStringMonth = `0${toStringMonth}`
            }
            let toStringDay = today.getDate()
            if (toStringDay < 10) {
                toStringDay = `0${toStringDay}`
            }
            let toStringHour = today.getHours()
            if (toStringHour < 10) {
                toStringHour = `0${toStringHour}`
            }
            let toStringMinute = today.getMinutes()
            if (toStringMinute < 10) {
                toStringMinute = `0${toStringMinute}`
            }

            let fromString = ''
            let toString = ''

            fromString += fromStringYear
            fromString += '-'
            fromString += fromStringMonth
            fromString += '-'
            fromString += fromStringDay
            fromString += 'T'
            fromString += fromStringHour
            fromString += ':'
            fromString += fromStringMinute

            toString += toStringYear
            toString += '-'
            toString += toStringMonth
            toString += '-'
            toString += toStringDay
            toString += 'T'
            toString += toStringHour
            toString += ':'
            toString += toStringMinute

            setFromDate(fromString)
            setToDate(toString)
        }
    }, [])

    const toISOString = (e) => {
        try {
            const asDateObject = new Date(e)

            return asDateObject.toISOString()
        } catch (err) {
            return '1970-01-01T00:00:00.000Z'
        }
    }

    const f = [
        {
            type: 'group',
            clause: JSON.stringify([
                { field: 'createdAt', type: 'more-than', clause: toISOString(fromDate) },
                {
                    field: 'createdAt',
                    type: 'less-than',
                    clause: toISOString(toDate),
                    connector: 'and',
                },
            ]),
        },
    ]

    if (filters.length) {
        f.push({
            type: 'group',
            clause: JSON.stringify(filters),
            connector: 'and',
        })
    }

    const {
        data: { getDatasetV2EntriesByDataset: { entries = [], count = 0 } = {} } = {},
        refetch,
        loading,
    } = useQuery(GET_DATASETS_V2_ENTRIES_BY_DATASET, {
        variables: {
            params: {
                dataset: datasetId,
                limit: rowsPerPage,
                offset: rowsPerPage * page,
                filters: f,
                sort,
            },
        },
    })

    const { data: { getDatasetV2ById: { dataset: { fields = [] } = {} } = {} } = {} } = useQuery(
        GET_DATASETS_V2_BY_ID,
        { variables: { params: { id: datasetId } } }
    )

    const currentView = views[view]

    const onViewClicked = (v) => () => setView(v)

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value)
        setPage(0)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const onCurrentFilterFieldChanged = (e) => {
        setCurrentFilterField(e.target.value)
    }

    const onCurrentFilterTypeChanged = (e) => {
        setCurrentFilterType(e.target.value)
    }

    const onCurrentFilterClauseChanged = (e) => {
        setCurrentFilterClause(e.target.value)
    }

    const onCurrentFilterConnectorChanged = (e) => {
        setCurrentFilterConnector(e.target.value)
    }

    const newFilterButtonClicked = () => {
        if (currentFilterType && currentFilterType && currentFilterClause) {
            setFilters([
                ...filters,
                {
                    field: currentFilterField,
                    type: currentFilterType,
                    clause: currentFilterClause,
                    connector: currentFilterConnector,
                },
            ])
            setPage(0)
            setCurrentFilterField('')
            setCurrentFilterType('')
            setCurrentFilterClause('')
            setCurrentFilterConnector(null)
        }
    }

    const handleFilterDelete = (i) => () => {
        const newFilters = [...filters.slice(0, i), ...filters.slice(i + 1)]

        if (newFilters.length) newFilters[0] = { ...newFilters[0], connector: null }

        setFilters(newFilters)
        setPage(0)
    }

    const onSortFieldChange = (e) => {
        setSortField(e.target.value)
    }

    const onSortCriteriaChange = (e) => {
        setSortCriteria(e.target.value)
    }

    const onNewSortButtonClicked = () => {
        if (sortField.length && sortCriteria.length) {
            setSort([...sort, { field: sortField, criteria: sortCriteria }])
            setSortField('')
            setSortCriteria('')
            setPage(0)
        }
    }

    const handleSortDelete = (i) => () => {
        setSort([...sort.slice(1, i), ...sort.slice(i + 1)])
        setPage(0)
    }

    const onFromDateChange = (e) => {
        setFromDate(e.target.value)
        setPage(0)
    }

    const onToDateChange = (e) => {
        setToDate(e.target.value)
        setPage(0)
    }

    return (
        <div style={{ flexGrow: 1, height: '100%' }}>
            <Grid container style={{ height: '100%' }}>
                <Grid item xl md sm style={{ height: 'calc(100vh - 114px)' }}>
                    {React.createElement(
                        currentView,
                        { entries: entries || [], fields: fields || [] },
                        []
                    )}
                </Grid>
                <Grid item style={{ width: rightPanelOpen ? 300 : 50, transition: '0.3s ease' }}>
                    <IconButton
                        onClick={() =>
                            dispatch(rightPanelOpen ? contractRightPanel() : expandRightPanel())
                        }
                    >
                        {rightPanelOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    {rightPanelOpen ? (
                        <List component="nav" aria-label="main mailbox folders">
                            <ListSubheader>Vistas</ListSubheader>
                            <ListItem button onClick={onViewClicked('spreadsheet')}>
                                <ListItemIcon>
                                    <TableChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Tabla" />
                            </ListItem>
                            <ListItem button onClick={onViewClicked('gallery')}>
                                <ListItemIcon>
                                    <PhotoLibraryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Galería" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <TextField
                                    id="datetime-local"
                                    label="Desde"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ marginRight: 10 }}
                                    onChange={onFromDateChange}
                                    value={fromDate}
                                />
                                <TextField
                                    id="datetime-local"
                                    label="Hasta"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={onToDateChange}
                                    value={toDate}
                                />
                            </ListItem>
                            <Divider />
                            <ListSubheader>Filtrar</ListSubheader>
                            <ul className={classes.root}>
                                {filters.map((data, i) => {
                                    return (
                                        <li key={data.connector}>
                                            <Chip
                                                label={`${data.connector || ''} ${data.field} ${
                                                    filtersMap[data.type]
                                                } ${data.clause}`.toLowerCase()}
                                                className={classes.chip}
                                                onDelete={handleFilterDelete(i)}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                            <ListItem>
                                <FormControl fullWidth>
                                    <Select
                                        label="Campo"
                                        fullWidth
                                        onChange={onCurrentFilterFieldChanged}
                                        value={currentFilterField}
                                    >
                                        {fields.map((field) => (
                                            <MenuItem key={field.name} value={field.name}>
                                                {field.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <FormControl fullWidth disabled={!currentFilterField.length}>
                                    <Select
                                        label="Tipo"
                                        fullWidth
                                        onChange={onCurrentFilterTypeChanged}
                                        value={currentFilterType}
                                    >
                                        <MenuItem value="contains">Contiene</MenuItem>
                                        <MenuItem value="not-contains">No contiene</MenuItem>
                                        <MenuItem value="equals">Es igual a</MenuItem>
                                        <MenuItem value="not-equals">No es igual a</MenuItem>
                                        <MenuItem value="less-than">Menor que</MenuItem>
                                        <MenuItem value="less-than-or-equal">
                                            Menor o igual a
                                        </MenuItem>
                                        <MenuItem value="more-than">Mayor que</MenuItem>
                                        <MenuItem value="more-than-or-equal">
                                            Mayor o igual a
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <TextField
                                    fullWidth
                                    label=""
                                    value={currentFilterClause}
                                    onChange={onCurrentFilterClauseChanged}
                                    disabled={!currentFilterType.length}
                                />
                            </ListItem>
                            {filters.length ? (
                                <ListItem>
                                    <FormControl fullWidth disabled={!currentFilterClause.length}>
                                        <Select
                                            label="Conector"
                                            fullWidth
                                            onChange={onCurrentFilterConnectorChanged}
                                            value={currentFilterConnector}
                                        >
                                            <MenuItem value="and">AND</MenuItem>
                                            <MenuItem value="or">OR</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ListItem>
                            ) : null}
                            <ListItem>
                                <Button fullWidth onClick={newFilterButtonClicked}>
                                    Añadir Filtro
                                </Button>
                            </ListItem>
                            <Divider />
                            <ListSubheader>Ordenar</ListSubheader>
                            <ul className={classes.root}>
                                {sort.map((data, i) => {
                                    return (
                                        <li key={data.field}>
                                            <Chip
                                                label={`${data.field} ${data.criteria}`}
                                                className={classes.chip}
                                                onDelete={handleSortDelete(i)}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                            <ListItem>
                                <FormControl margin="normal" fullWidth>
                                    <Select
                                        label="Campo"
                                        fullWidth
                                        value={sortField}
                                        onChange={onSortFieldChange}
                                    >
                                        {[{ name: 'createdAt' }, ...fields].map((field) => (
                                            <MenuItem key={field.name} value={f.name}>
                                                {field.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <FormControl margin="normal" fullWidth disabled={!sortField.length}>
                                    <Select
                                        label="Dirección"
                                        fullWidth
                                        value={sortCriteria}
                                        onChange={onSortCriteriaChange}
                                    >
                                        <MenuItem value="asc">Ascendente</MenuItem>
                                        <MenuItem value="desc">Descendente</MenuItem>
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <Button fullWidth onClick={onNewSortButtonClicked}>
                                    Añadir Orden
                                </Button>
                            </ListItem>
                        </List>
                    ) : null}
                </Grid>
                <Paper
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        background: 'white',
                        padding: '0 70px 0 0',
                        width: '100vw',
                    }}
                >
                    <Grid container>
                        <Grid xs />
                        <Grid xl={2}>
                            <VirtualColumnManager columnsList={fields} />
                            <IconButton>
                                <RefreshIcon onClick={() => refetch()} fontSize="large" />
                            </IconButton>
                            {isCreateTrainingSetEntries && (
                                <CreateTrainingSetEntriesDialog fields={fields || []} />
                            )}
                            {isExportDatasetAllowed && <ExportDialog sort={sort} filters={f} />}
                            <Settings />
                        </Grid>
                        <Grid xl={3}>
                            <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                onChangePage={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Backdrop open={loading} style={{ zIndex: 'auto' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <ImageViewer />
        </div>
    )
}

export default Viewer
