import React from 'react'
import { PropTypes } from 'prop-types'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

const languages = [
    {
        name: 'Español',
        code: 'es',
    },
    {
        name: 'Inglés',
        code: 'en',
    },
]

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <List>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Idioma</InputLabel>
                        <Select
                            value={getProperty('language', 'en')}
                            onChange={onPropertyChangeBuilder('language')}
                        >
                            {languages.map((l) => (
                                <MenuItem key={l.name} value={l.code}>
                                    {l.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Seleccione un tipo de frente</FormHelperText>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <TextField
                        label="Ruta de Entrada"
                        value={getProperty('input-key', '')}
                        onChange={onPropertyChangeBuilder('input-key')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Umbral"
                        value={getProperty('thresh', '')}
                        onChange={onPropertyChangeBuilder('thresh')}
                        fullWidth
                        type="number"
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Umbral de caja"
                        value={getProperty('box_thresh', '')}
                        onChange={onPropertyChangeBuilder('box_thresh')}
                        fullWidth
                        type="number"
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Ratio de margen"
                        value={getProperty('unclip_ratio', '')}
                        onChange={onPropertyChangeBuilder('unclip_ratio')}
                        fullWidth
                        type="number"
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('crop-x')}
                        label="Crop Offset X"
                        value={getProperty('crop-x', '')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('crop-y')}
                        label="Crop Offset Y"
                        value={getProperty('crop-y', '')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('crop-width')}
                        label="Crop Width"
                        value={getProperty('crop-width', '')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('crop-height')}
                        label="Crop Height"
                        value={getProperty('crop-height', '')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Ruta de Salida"
                        value={getProperty('output-key', '')}
                        onChange={onPropertyChangeBuilder('output-key')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Versión</InputLabel>
                        <Select
                            value={getProperty('version', '$LATEST')}
                            onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                        >
                            {nodeDefinition.conf.versions.map((version) => (
                                <MenuItem key={`${node.id}_version${version}`} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
