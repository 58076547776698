import React from 'react'

import CircularProgress from 'material-ui/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

export default React.memo(
    (props) => {
        console.log('LoadingBackdrop.jsx: props.loading =', props.loading)
        return (
            <Backdrop
                open={props.loading}
                style={{
                    zIndex: 1000,
                    backgroundColor: props.loading ? '#21212199' : null,
                    pointerEvents: props.loading ? null : 'none',
                    transition: 'ease 1s',
                }}
            >
                <CircularProgress size={80} thickness={5} />
            </Backdrop>
        )
    },
    (prevProps, nextProps) => {
        return prevProps.loading === nextProps.loading
    }
)
