import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';

import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"

import DeleteIcon from '@material-ui/icons/Delete';

export default ({ render }) => {
  const { id } = useNode();
  const { actions, query, isActive } = useEditor((state) => ({
    isActive: state.nodes[id].events.selected,
  }));

  const {
    isHover,
    dom,
    name,
    moveable,
    deletable,
    connectors: { drag },
    parent,
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
  }));

  const currentRef = useRef();

  useEffect(() => {
    if (dom) {
      if (isActive || isHover) dom.classList.add('component-selected');
      else dom.classList.remove('component-selected');
    }
  }, [dom, isActive, isHover]);

  const getPos = useCallback((dom) => {
      
    const { top, left, bottom, right } = dom ? dom.getBoundingClientRect() : { top: 0, left: 0, bottom: 0 };

    const bias = 2

    return {
      top: `${top > 0 ? top : bottom - bias}px`,
      left: `${left - bias}px`,
      width: `${right - left + bias }px`,
      height: `${bottom - top + bias}px`,
    };
  }, []);

  const primaryColor = "#2680eb"

  const pos = getPos(dom)
  
  return (
    <React.Fragment>
      {isHover || isActive
        ? ReactDOM.createPortal(
            <div
              ref={currentRef}
              style={{
                left: pos.left,
                top: pos.top,
                width: pos.width,
                height: pos.height,
                zIndex: 9999,
                position: "fixed",
                border: `1px dashed ${primaryColor}`,
                pointerEvents: "none"
              }}
            >
              <div style={{ background: primaryColor, width: "fit-content", padding: 5 }}>
                <h2 style={{ fontSize: "1.2em", color: "#FFF", margin: 5, textAlign: "center" }}>{name}</h2>
              </div>
            </div>,
            document.querySelector('.page-container')
          )
        : null}
      {render}
    </React.Fragment>
  );
};