import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createStore as createDynamicStore } from 'redux-dynamic-modules'
import 'regenerator-runtime/runtime'
import { combineReducers } from 'redux'

import { getSagaExtension } from 'redux-dynamic-modules-saga'

import Authentication from './modules/public/containers/authentication'
import Account from './modules/private/containers/account'
import Onboarding from './modules/private/containers/onboarding'

export const store = createDynamicStore(
    {
        initialState: {},
        enhancers: [],
        extensions: [getSagaExtension({})],
        advancedCombineReducers: (reducers) =>
            persistReducer(
                {
                    key: 'root',
                    storage,
                    whitelist: ['Auth', 'onboarding'],
                },
                combineReducers({
                    ...reducers,
                })
            ),
    },
    Authentication(),
    Account(),
    Onboarding()
)

export const persistor = persistStore(store)
