import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { CREATE_TRAINING_SET, GET_TRAINING_SETS } from '../../../../queries/training-sets'

/**
 *  Controls the behaviour of deletable classes
 */
const ClassItemDeletion = ({ cls, clsIndex, label, onClassNameChanged, onDeleteClassClicked }) => {
    return (
        <Grid container>
            <Grid item xs={10}>
                <TextField
                    label={label}
                    value={cls}
                    onChange={onClassNameChanged(clsIndex)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={onDeleteClassClicked(clsIndex)} style={{ float: 'right' }}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}
ClassItemDeletion.propTypes = {
    /**
     * Class name
     * */
    cls: PropTypes.string.isRequired,
    /**
     * Class index
     * */
    clsIndex: PropTypes.number.isRequired,
    /**
     * Displayed class label
     * */
    label: PropTypes.string.isRequired,
    /**
     * Hook to execute when the class name is changed
     * */
    onClassNameChanged: PropTypes.func.isRequired,
    /**
     * Hook to execute when delete the class
     * */
    onDeleteClassClicked: PropTypes.func.isRequired,
}

/**
 * Component responsible of create new training set
 */
const NewTrainingSetDialog = () => {
    const [open, setOpen] = useState(false)
    const [name, setName] = useState(null)
    const [type, setType] = useState(null)
    const [loading, setLoading] = useState(false)
    const [classes, setClasses] = useState([''])

    const [createTrainingSet] = useMutation(CREATE_TRAINING_SET, {
        refetchQueries: [{ query: GET_TRAINING_SETS, variables: { params: { parent: null } } }],
    })

    const onOpenClicked = () => setOpen(true)
    const onNameChanged = (e) => setName(e.target.value)
    const onTypeChanged = (e) => setType(e.target.value)
    const onAddClassClicked = () => setClasses([...classes, ''])
    const onDeleteClassClicked = (index) => () =>
        setClasses([...classes.slice(0, index), ...classes.slice(index + 1)])
    const onClassNameChanged = (index) => (e) =>
        setClasses([...classes.slice(0, index), e.target.value, ...classes.slice(index + 1)])

    const onCancelClicked = () => setOpen(false)
    const onAcceptClicked = async () => {
        setLoading(true)
        await createTrainingSet({
            variables: {
                params: {
                    labelType: type,
                    // TODO: Parametrize this entry
                    dataType: 'rgb',
                    name,
                    classes,
                },
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SETS,
                    variables: { params: { parent: null } },
                },
            ],
        })
        setLoading(false)
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Fab
                onClick={onOpenClicked}
                color="primary"
                style={{ position: 'fixed', bottom: 30, right: 30 }}
            >
                <AddIcon />
            </Fab>
            <Dialog open={open}>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select value={type} onChange={onTypeChanged}>
                            <MenuItem value="classification">Clasificación</MenuItem>
                            <MenuItem value="multi-label-classification">
                                Clasificación Multi Etiqueta
                            </MenuItem>
                            <MenuItem value="object-detection">Detección de Objectos</MenuItem>
                            <MenuItem value="ocr">
                                OCR(Reconocimiento óptico de caracteres)
                            </MenuItem>
                            <MenuItem value="segmentation">Segmentación</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField label="Nombre" value={name} onChange={onNameChanged} fullWidth />
                    {classes
                        .slice(0)
                        .map((cls, clsIndex) =>
                            clsIndex === 0 ? (
                                <TextField
                                    label={`Clase ${clsIndex}`}
                                    value={cls}
                                    onChange={onClassNameChanged(0)}
                                    fullWidth
                                />
                            ) : (
                                <ClassItemDeletion
                                    key={`${name}_${type}_${clsIndex}`}
                                    cls={cls}
                                    clsIndex={clsIndex}
                                    label={`Clase ${clsIndex}`}
                                    onClassNameChanged={onClassNameChanged}
                                    onDeleteClassClicked={onDeleteClassClicked}
                                />
                            )
                        )}
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={onAddClassClicked}
                        style={{ marginTop: 10 }}
                        color="primary"
                    >
                        +
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAcceptClicked} disabled={loading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default NewTrainingSetDialog
