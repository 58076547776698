import groups from './containers/groups/reducers/index'
import group from './containers/group/reducers/index'

export default () => {
    return {
        id: 'things',
        reducerMap: {
            groups,
            group,
        },
    }
}
