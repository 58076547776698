import React, { useState, useRef, useEffect } from "react"

import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"

import { StaticMap } from 'react-map-gl'
import DeckGL from '@deck.gl/react'
import { ScatterplotLayer } from "@deck.gl/layers"

import FullscreenLoading from "components/FullscreenLoading"

import Config from "config"

import { VISIBILITY } from "../../queries/local"
import { GET_PROJECT_BY_ID } from "../../queries/projects"

function MapView(props) {
    const root = useRef()

    const { project } = useParams()

    const { data: { getProjectById: { project: { position: { coordinates = [45,45]} = {}} = {}} = {} } = {}} = useQuery(GET_PROJECT_BY_ID, { variables: { params: { _id: project }}})

    const [ viewport, setViewport ] = useState({
        longitude: -3.305275, 
        latitude: 39.10725277777778,
        zoom: 15,
        maxZoom: 24
    })

    useEffect(() => {
        if(root.current) {
            setViewport({
                ...viewport,
                height: root.current.offsetHeight,
                width: root.current.offsetWidth,
            })
        }
    }, [ root.current ])

    const { data: { visibility = {}} = {}} = useQuery(VISIBILITY)

    const onViewportChange = (newViewport) =>  {
        setViewport({ ...viewport, ...newViewport })
    }

    const hotspotColors = {
        "1": [255, 0, 0],
        "2": [255, 255, 0]
    }

    const getMapStyle = () => {
        const { orthophotos = [] } = props;

        const oLayers = visibility.orthophotos ? 
            orthophotos.map(orthophoto => {
                const id = "ortophoto"
                return {
                    id,
                    source: {
                        type: "raster",
                        scheme: "tms",
                        tiles: [orthophoto.url],
                        tileSize: 256
                    },
                    layer: {
                        "id": "layer" + id,
                        type: "raster",
                        "source": id,
                        "minzoom": 0,
                        "maxzoom": 24
                    }
                }
            }): []

        const res = {
            "version": 8,
            "sources": {
                "base-map": {
                    "type": "raster",
                    "url": "mapbox://mapbox.satellite"
                },
                ...oLayers.reduce((acc, item) => ({ ...acc, [item.id]: item.source }), {})
            },
            "layers": [
                {
                    "id": "satellite",
                    "type": "raster",
                    "source": "base-map",
                    "minzoom": 0,
                    "maxzoom": 24
                },
                ...oLayers.map(l => l.layer)
            ],
            center: [0,0],
            zoom: 1
        }
        return res
    }

    const layers = [
        {
            id: "radiometric",
            visible: visibility.radiometric,
            getFillColor: d => (props.selected_image && (d._id === props.selected_image._id)) ? [255, 0, 0] : [0, 0, 0],
            getLineColor: d => (props.selected_image && (d._id === props.selected_image._id)) ? [255, 0, 0] : [0, 0, 0],
            data: [...props.images]
        },
        {
            id: "hotspots",
            visible: visibility.hotspots,
            getFillColor: d => [255, 0, 0],
            getLineColor: d => [255, 0, 0],
            data: [...props.hotspots]
        }
    ]
    
    return (
        <div ref={root} style={{ height: props.height }}>
            <FullscreenLoading loading={props.loading}>
                <DeckGL 
                    initialViewState={{longitude: coordinates[0], latitude: coordinates[1], zoom: 15}}
                    controller={true}
                    style={{ position: "relative" }}
                    layers={layers.filter(l => l.visible).map(layer => {
                        return new ScatterplotLayer({
                            id: layer.id,
                            data: layer.data,
                            pickable: true,
                            opacity: 0.8,
                            stroked: true,
                            filled: true,
                            radiusScale: props.point_size * 0.1,
                            fp64: true,
                            getPosition: d => d.position.coordinates.map(c => parseFloat(c)),
                            getFillColor: layer.getFillColor,
                            getLineColor: layer.getLineColor,
                            onClick: (info, event) => props.selectImage(info.object),
                            onHover: (info, event) => props.hoverImage(info.object),
                        })
                    })} 
                >
                    <StaticMap 
                        mapStyle={getMapStyle()}
                        mapboxApiAccessToken={Config.app.mapbox_token}
                    ></StaticMap>
                </DeckGL>
            </FullscreenLoading>
        </div>
    )
}


import { connect } from "react-redux"

import { selectImage, hoverImage } from "../../../../reducers/images"


export default connect(
    state => ({
        point_size: state.inspector.solar.settings.point_size,
        selected_image: state.inspector.solar.images.selected_image
    }),
    dispatch => ({
        selectImage: image => dispatch(selectImage(image)),
        hoverImage: image => dispatch(hoverImage(image))
    })
)(MapView)