import React, { useState } from "react"

import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/react-hooks"

import Grid from "@material-ui/core/Grid"
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import Tooltip from "@material-ui/core/Tooltip"
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';

import { green } from '@material-ui/core/colors';

import { CREATE_DATASET, GET_ALL_DATASETS, REMOVE_DATASET } from "../../queries/datasets"

function Inspections(props) {
    const classes = useStyles();

    /**
     * LOCAL STATE
     */
    const [ createDatasetLoading, setCreateDatasetLoading ] = useState(false)

    /**
     * GRAPHQL
     */
    const { data: { getDatasetsByProject: { datasets = [] } = {}} = {}, loading} = useQuery(GET_ALL_DATASETS, {
        variables: {
            params: {
                project: props.match.params.project
            }
        }
    })
    const [ createDataset ] = useMutation(CREATE_DATASET)
    const [ removeDataset ] = useMutation(REMOVE_DATASET)

    /**
     * HANDLERS
     */
    const onCreateDatasetClicked = async () => {
        setCreateDatasetLoading(true)
        try {
            await createDataset({
                variables: {
                    params: {
                        project: props.match.params.project
                    }
                },
                refetchQueries: [
                    { 
                        query: GET_ALL_DATASETS,
                        variables: { 
                            params: {
                                project: props.match.params.project
                            }
                        }
                    }
                ]
            })
        } catch(err) {
            props.enqueueSnackbar("Se ha producido un error de conexión", { variant: "error" })
        } finally {
            setCreateDatasetLoading(false)
        }
    }

    const onDatasetRemove = _id => async () => {
        await removeDataset({
            variables: {
                params: {
                    _id
                }
            },
            refetchQueries: [
                { 
                    query: GET_ALL_DATASETS,
                    variables: { 
                        params: {
                            project: props.match.params.project
                        }
                    }
                }
            ]
        })
    }


    return (
        <Grid container direction="row" justify="center" alignItems="flex-start">
            {loading ? 
                <CircularProgress size={68} /> :
                datasets.length > 0 ?
                    <Grid item xs={12}>
                        <Paper style={{ maxHeight: "80vh", overflowY: "auto", height: "100%" }}>
                            <List>
                                {
                                    datasets.map(dataset => (
                                        <ListItem onClick={() => props.history.push(props.match.url + "/" + dataset._id)} button>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ImageIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={dataset.createdAt} secondary={dataset._id} />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={onDatasetRemove(dataset._id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Paper>
                    </Grid> :
                    "No hay ninguna inspección disponible. Prueba a crear una con el botón +"
            }
            <div className={classes.wrapper}>
                <Tooltip title="Nuevo Dataset" placement="left">
                    <Fab color={"primary"} onClick={onCreateDatasetClicked} disabled={props.createDatasetLoading}>
                        <AddIcon/>
                    </Fab>
                </Tooltip>
                {createDatasetLoading && <CircularProgress size={68} className={classes.fabProgress} />}
            </div>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    }
}))



export default withSnackbar(Inspections);