import React from 'react'
import { useParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import SaveIcon from '@mui/icons-material/Save'
import { withTheme } from '@mui/styles'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'

import theme from '../../../../../../../../../../../../theme'
import { REMOVE_ENTRY_BY_ID } from '../../../../queries/training-sets'
import { setBulkInplaceAnnotation, saveInPlace } from '../../redux/images'

/**
 * Component responsible of annotations toolbox saving button.
 * When clicked all the in place annotations are sent to the db.
 */
const SaveButtonRaw = () => {
    const dispatch = useDispatch()

    const saveAnnotations = async () => {
        dispatch(saveInPlace())
    }

    return (
        <Button color="primary" variant="contained" key="save" onClick={saveAnnotations}>
            <SaveIcon />
        </Button>
    )
}
// Using the established theme
const SaveButton = withTheme(SaveButtonRaw)

const DeleteTool = ({ resetTool }) => {
    const handleClose = () => {
        resetTool()
    }
    const { trainingSet } = useParams()
    const selectedEntry = useSelector((state) => state.trainingSetSegmentation.images.selectedEntry)
    const [removeEntrybyIdMut] = useMutation(REMOVE_ENTRY_BY_ID)
    const removeEntryById = async () => {
        await removeEntrybyIdMut({ variables: { params: { trainingSet, _id: selectedEntry } } })
        window.location.reload()
    }

    const removeEntry = () => {
        removeEntryById()
        resetTool()
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog onClose={handleClose} open={true}>
                <DialogTitle>Se va a elminiar la entrada {selectedEntry}, ¿continuar?</DialogTitle>
                <Box
                    component="span"
                    ml="25%"
                    mr="25%"
                    mb="2%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button color="primary" variant="contained" key="yes" onClick={removeEntry}>
                        Sí
                    </Button>
                    <Button color="primary" variant="contained" key="no" onClick={handleClose}>
                        No
                    </Button>
                </Box>
            </Dialog>
        </ThemeProvider>
    )
}

DeleteTool.propTypes = {
    resetTool: PropTypes.func.isRequired,
}

/**
 * Delete all the annotations of selectedEntry
 *
 */
const DeleteAllAnnotations = () => {
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(setBulkInplaceAnnotation([]))
    }
    return (
        <Button key="DeleteAnnotations" onClick={onClick}>
            <DeleteSweepIcon />
        </Button>
    )
}

const SamplesToolbox = ({ setToolbox }) => {
    const deleteClick = () => {
        setToolbox(<DeleteTool resetTool={() => setToolbox(null)} />)
    }
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    '& > *': {
                        m: 1,
                    },
                }}
                style={{ position: 'absolute' }}
            >
                <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="contained"
                    color="primary"
                >
                    {[
                        <Button key="delete" onClick={deleteClick}>
                            <DeleteIcon />
                        </Button>,
                        <DeleteAllAnnotations key="deleteAll" />,
                        <SaveButton key="save" />,
                    ]}
                </ButtonGroup>
            </Box>
        </ThemeProvider>
    )
}

SamplesToolbox.propTypes = {
    setToolbox: PropTypes.func.isRequired,
}

export default SamplesToolbox
