import React from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import {
    STRING_TYPE,
    BOOLEAN_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const COLOR_CODES = [
    'HSV_FULL',
    'Lab',
    'RGB_UYVY',
    'LRGB',
    'BGRA',
    'BGR555',
    'RGB',
    'BGRA_YUYV',
    'BGR_FULL',
    'YCR_CB',
    'RGB_IYUV',
    'BGR_IYUV',
    'BGR_YVYU',
    'RGBA_UYVY',
    'GRAY_UYNV',
    'RGBA_YUYV',
    'RGB_YUNV',
    'BGR_UYNV',
    'HSV',
    'BGR_YUNV',
    'RGB_YVYU',
    'M_RGBA',
    'COLORCVT_MAX',
    'LBGR',
    'BGRA_IYUV',
    'GRAY_IYUV',
    'BGRA_YVYU',
    'BGRA_YUNV',
    'RGBA',
    'RGBA_UYNV',
    'RGB_EA',
    'BGR_EA',
    'LAB',
    'RGB_YUYV',
    'XYZ',
    'HLS',
    'GRAY_YVYU',
    'GRAY_YUNV',
    'LUV',
    'RGBA_YVYU',
    'YUV_IYUV',
    'RGB_FULL',
    'BGRA_UYVY',
    'Luv',
    'GRAY',
    'GRAY_YUYV',
    'BGR565',
    'BGR',
    'BGR_YUYV',
    'BGR_VNG',
    'HLS_FULL',
    'BGRA_UYNV',
    'RGB_UYNV',
    'GRAY_UYVY',
    'RGBA_IYUV',
    'RGB_VNG',
    'YCrCb',
    'RGBA_YUNV',
    'YUV',
    'mRGBA',
    'BGR_UYVY',
]

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Entrada"
                    value={
                        JSON.parse(getProperty('input-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('input-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="scheme"
                    humanName="Esquema de color"
                    parametersFilter={STRING_TYPE}
                >
                    <FormControl fullWidth>
                        <InputLabel>Esquema de color</InputLabel>
                        <Select
                            value={
                                JSON.parse(getProperty('scheme', JSON.stringify({ value: 'BGR' })))
                                    .value
                            }
                            onChange={onPropertyChangeBuilder('scheme', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            {COLOR_CODES.map((mode) => (
                                <MenuItem key={mode} value={mode}>
                                    {mode}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="overwrite"
                    humanName="Sobrescribir"
                    parametersFilter={BOOLEAN_TYPE}
                >
                    <FormControl fullWidth>
                        <InputLabel>Sobrescribir</InputLabel>
                        <Select
                            value={
                                JSON.parse(
                                    getProperty('overwrite', JSON.stringify({ value: 'false' }))
                                ).value
                            }
                            onChange={onPropertyChangeBuilder('overwrite', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            <MenuItem key="true" value="true">
                                Activado
                            </MenuItem>
                            <MenuItem key="false" value="false">
                                Desactivado
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
