import React, { useState, useEffect } from "react"

import { makeStyles } from '@material-ui/core/styles';
import { useEditor } from "@craftjs/core";
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"

import Paper from "@material-ui/core/Paper"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';

import NavigationIcon from '@material-ui/icons/Navigation';

import Viewer from "./containers/preview/scene"
import Editor from "./containers/editor/scene"

import { UPDATE_HMI_BY_ID, GET_HMI_BY_ID } from "../../queries/hmi"

const tabs = [ 
    Viewer,
    Editor
]

const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));


export default (props) => {
    const classes = useStyles()

    const { hmi } = useParams()
    const { actions, query } = useEditor();

    const [ tab, setTab ] = useState(0)
    const [ loading, setLoading ] = useState(false)

    const [ updateHmiById ] = useMutation(UPDATE_HMI_BY_ID)

    const { data: { getHMIById: { hmi: { components = []} = {}} = {}} = {}} = useQuery(GET_HMI_BY_ID, { variables: { params: { _id: hmi }}})
    
    const onTabChange = (e, newValue) => setTab(newValue)

    useEffect(() => {
        if(components.length) {
            const json = components.reduce((acc, item) => {
                return {
                    ...acc,
                    [item._id]: {
                        ...acc[item._id],
                        props: item.properties,
                        type: {
                            resolvedName: item.type
                        },
                        nodes: item.components,
                        isCanvas: true
                    },
                    ...item.components.reduce((acc, n) => ({...acc, [n]: { parent: item._id }}), {})
                }
            }, {})

            actions.deserialize(json)
        }
    }, [ components.length ])

    const onSaveClicked = async () => {
        setLoading(true)

        const output = JSON.parse(query.serialize())
        console.log("OUTPUT", output)
        const nodes = []
        
        const entries = Object.entries(output)
        
        const search = (n) => {
            
            const current = output[n]

            const { _id, ...properties } = current.props

            const children = entries.filter(([_, value]) => value.parent === n).map(([key]) => key)
            
            nodes.push({
                _id: current.props._id || n,
                root: current.parent === null,
                type: current.type.resolvedName,
                properties: properties,
                components: children.map(c => output[c].props._id || c)
            })

            children.map(node => search(node))
        }

        search("ROOT")
        
        await updateHmiById({ 
            variables: {
                params: {
                    _id: hmi,
                    components: nodes
                }
            }
        })

        setLoading(false)
    }

    return (
        <div style={{ height: "100%" }}>
            <div style={{ width: 1024, height: 768, margin: "auto" }}>
                <Viewer components={components}/>
            </div>
            <div className={classes.fab}>
                <Fab variant="extended" onClick={onSaveClicked}>
                    <NavigationIcon className={classes.extendedIcon} />
                    Guardar
                </Fab>
            </div>
        </div>
    )
}