import gql from "graphql-tag"


export const ONBOARDING_REQUIRED = gql`
    query {
        onboardingRequired
    }
`

export const ONBOARDING = gql`
    mutation Onboarding($params: OnboardingInput!) {
        onboarding(params: $params) {
            state
            error
        }
    }
`