import gql from "graphql-tag"

export const GET_PLANS = gql`
    query GetGroupsByProduct($params: GetGroupsByProductRequest!) {
        getGroupsByProduct(params: $params) {
            status
            groups {
                _id
                title
                type
                plans {
                    _id
                    title
                    order
                    features
                    interval
                    price
                    currency
                    billing
                }
            }
        }
    }
`