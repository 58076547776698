import React from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import {
    STRING_TYPE,
    INTEGER_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const SideBar = ({ node, onPropertyChangeBuilder, getProperty, nodeDefinition }) => {
    const classes = useStyles()

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <Parametrize name="ip" humanName="Dirección IP" parametersFilter={STRING_TYPE}>
                    <TextField
                        label="Dirección IP"
                        value={JSON.parse(getProperty('ip', JSON.stringify({ value: null }))).value}
                        onChange={onPropertyChangeBuilder('ip', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="pin" humanName="Pin" parametersFilter={INTEGER_TYPE}>
                    <TextField
                        label="Pin"
                        value={
                            JSON.parse(getProperty('pin', JSON.stringify({ value: null }))).value
                        }
                        onChange={onPropertyChangeBuilder('pin', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        fullWidth
                        type="number"
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="edge" humanName="Tipo de Frente" parametersFilter={STRING_TYPE}>
                    <FormControl fullWidth>
                        <InputLabel>Tipo de Frente</InputLabel>
                        <Select
                            value={
                                JSON.parse(getProperty('edge', JSON.stringify({ value: null })))
                                    .value
                            }
                            onChange={onPropertyChangeBuilder('edge', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            <MenuItem key="rising" value="rising">
                                Frente de Subida
                            </MenuItem>
                            <MenuItem key="falling" value="falling">
                                Frente de Bajada
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
