import gql from "graphql-tag"

export const GET_DATASETS_BY_PARENT = gql`
    query GetHorusDatasetsByParent($params: GetHorusDatasetsByParentRequest!) {
        getHorusDatasetsByParent(params: $params) {
            status
            datasets {
                _id
                name
                type
            }
        }
    }

`

export const GET_DATSAET_ENTRIES_BY_DATASET = gql`
    query GetHorusDatasetEntriesByDataset($params: GetHorusDatasetEntriesByDatasetRequest!) {
        getHorusDatasetEntriesByDataset(params: $params) {
            status
            entries {
                properties {
                    name
                    value
                }
            }
        }
    }
`

export const CREATE_DATASET = gql`
    mutation CreateHorusDataset($params: CreateHorusDatasetRequest!) {
        createHorusDataset(params: $params) {
            status
        }
    }
`

export const CREATE_DATASET_ENTRIES = gql`
    mutation CreateHorusDatasetEntries($params: CreateHorusDatasetEntriesRequest!) {
        createHorusDatasetEntries(params: $params) {
            status
        }
    }

`