import gql from "graphql-tag"

export const CREATE_SUBSCRIPTION = gql`
    mutation CreateSubscription($params: CreateSubscriptionRequest!){
        createSubscription(params: $params) {
            status
        }
    }
`

export const GET_SUBSCRIPTION_BY_PRODUCT = gql`
    query GetSubscriptionByProduct($params: GetSubscriptionByProductRequest!){
        getSubscriptionByProduct(params: $params){
            status
            local @client
            subscription {
                _id
                items {
                    _id
                    plan {
                        _id
                    }
                }
            }
        }
    }
`

export const UPDATE_SUBSCRIPTION = gql`
    mutation UpdateSubscription($params: UpdateSubscriptionRequest!) {
        updateSubscription(params: $params) {
            status
        }
    }
`

export const REMOVE_SUBSCRIPTION = gql`
    mutation RemoveSubscription($params: RemoveSubscriptionRequest!) {
        removeSubscription(params: $params) {
            status
        }
    }
`