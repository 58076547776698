import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'

import { green } from '@material-ui/core/colors'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import AddIcon from '@material-ui/icons/Add'

import { GET_TRAINING_SETS } from '../../../training-sets/queries/training-sets'
import { CREATE_MODEL } from '../../queries/models'

import ObjectDetectionOptions from './containers/object-detection-options/scene'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(3),
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const advancedOptionsDict = {
    'object-detection': ObjectDetectionOptions,
}

const NewDialog = ({ open, setOpen }) => {
    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [trainingSet, setTrainingSet] = useState(null)
    const [trainingSetFilter, setTrainingSetFilter] = useState(null)
    const [name, setName] = useState(null)
    const [epochs, setEpochs] = useState(null)
    const [modelType, setModelType] = useState(null)
    const [resolution, setResolution] = useState(null)
    const [preprocessing, setPreprocessing] = useState('')
    const [advancedOptions, setAdvancedOptions] = useState([])

    const [collapseAdvancedOptions, setCollapseAdvancedOptions] = useState(true)

    const [createModel] = useMutation(CREATE_MODEL)
    const { data: { getTrainingSets: { trainingSets = [] } = {} } = {} } = useQuery(
        GET_TRAINING_SETS,
        {
            variables: {
                params: {
                    parent: null,
                },
            },
        }
    )

    const onTrainingSetChanged = (e) => setTrainingSet(e.target.value)
    const onNameChanged = (e) => setName(e.target.value)
    const onEpochsChanged = (e) => setEpochs(parseInt(e.target.value, 10))
    const onModelTypeChanged = (e) => setModelType(e.target.value)
    const onResolutionChanged = (e) => setResolution(e.target.value)
    const onPreprocessingChanged = (e) => setPreprocessing(e.target.value)
    const onOpenDialogClicked = () => setOpen(true)
    const onCancelClicked = () => setOpen(false)
    const onAcceptClicked = async () => {
        setLoading(true)
        await createModel({
            variables: {
                params: {
                    name,
                    trainingSet,
                    epochs,
                    type: modelType,
                    resolution,
                    preprocessing,
                    options: advancedOptions,
                },
            },
        })
        setOpen(false)
        setLoading(false)
    }
    const onAdvancedOptionsChanged = (options) => setAdvancedOptions(options)

    const filteredTrainingSets = trainingSets.filter((obj) => {
        if (obj.labelType === null || trainingSetFilter === null) return true
        return obj.labelType === trainingSetFilter
    })

    const AdvancedOptions = advancedOptionsDict[modelType] || null

    return (
        <React.Fragment>
            <Fab className={classes.fab} color="primary" onClick={onOpenDialogClicked}>
                <AddIcon />
            </Fab>
            {open && (
                <Dialog open={open} fullWidth maxWidth="sm">
                    <DialogTitle>Entrenar nuevo modelo</DialogTitle>
                    <DialogContent>
                        <List style={{ width: '100%' }}>
                            <ListItem>
                                <FormControl fullWidth>
                                    <InputLabel>Training Set</InputLabel>
                                    <Select value={trainingSet} onChange={onTrainingSetChanged}>
                                        {filteredTrainingSets.map((trainingSetI) => (
                                            <MenuItem
                                                key={trainingSetI._id}
                                                value={trainingSetI._id}
                                            >
                                                {trainingSetI.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <FormControl fullWidth>
                                    <InputLabel>Preprocesar</InputLabel>
                                    <Select value={preprocessing} onChange={onPreprocessingChanged}>
                                        <MenuItem value="">Desactivado</MenuItem>
                                        <MenuItem value="stretch">Estirar</MenuItem>
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <FormControl fullWidth>
                                    <InputLabel>Tipo de Modelo</InputLabel>
                                    <Select value={modelType} onChange={onModelTypeChanged}>
                                        <MenuItem
                                            value="classification"
                                            onClick={() => setTrainingSetFilter('classification')}
                                        >
                                            Clasificación
                                        </MenuItem>
                                        <MenuItem
                                            value="multi-label-classification"
                                            onClick={() =>
                                                setTrainingSetFilter('multi-label-classification')
                                            }
                                        >
                                            Clasificación Multietiqueta
                                        </MenuItem>
                                        <MenuItem
                                            value="object-detection"
                                            onClick={() => setTrainingSetFilter('object-detection')}
                                        >
                                            Detección de Objectos
                                        </MenuItem>
                                        <MenuItem
                                            value="ocr"
                                            onClick={() => setTrainingSetFilter('ocr')}
                                        >
                                            OCR (Reconocimiento òptico de caracteres)
                                        </MenuItem>
                                        <MenuItem
                                            value="anomaly-detection"
                                            onClick={() => setTrainingSetFilter('segmentation')}
                                        >
                                            Detección de anomalías
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <FormControl fullWidth>
                                    <InputLabel>Resolución</InputLabel>
                                    <Select value={resolution} onChange={onResolutionChanged}>
                                        <MenuItem value="224">Baja</MenuItem>
                                        <MenuItem value="512">Media</MenuItem>
                                        <MenuItem value="640">Alta</MenuItem>
                                    </Select>
                                </FormControl>
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label="Nombre"
                                    onChange={onNameChanged}
                                    value={name}
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    label="Iteraciones"
                                    type="Number"
                                    step="1"
                                    onChange={onEpochsChanged}
                                    value={epochs}
                                    fullWidth
                                />
                            </ListItem>
                            {modelType && (
                                <>
                                    <ListItem>
                                        <ListItemText primary="Opciones avanzadas" />
                                        <ListItemSecondaryAction
                                            onClick={() =>
                                                setCollapseAdvancedOptions(!collapseAdvancedOptions)
                                            }
                                        >
                                            {!collapseAdvancedOptions ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Collapse in={!collapseAdvancedOptions} timeout="auto">
                                        {advancedOptionsDict[modelType] && (
                                            <AdvancedOptions
                                                value={advancedOptions}
                                                onChange={onAdvancedOptionsChanged}
                                            />
                                        )}
                                    </Collapse>
                                </>
                            )}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCancelClicked} disabled={loading}>
                            Cancelar
                        </Button>
                        <div className={classes.root}>
                            <div className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    onClick={onAcceptClicked}
                                >
                                    Aceptar
                                </Button>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    )
}

const NewModelDialog = () => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)

    const onOpenDialogClicked = () => setOpen(!open)

    return (
        <React.Fragment>
            <Fab className={classes.fab} color="primary" onClick={onOpenDialogClicked}>
                <AddIcon />
            </Fab>
            {open && <NewDialog open={open} setOpen={onOpenDialogClicked} />}
        </React.Fragment>
    )
}

export default NewModelDialog
