export const OPEN_DATASET = "UNIVERSAL/OPEN_DATASET"
export const SELECT_ENTRY = "UNIVERSAL/SELECT_ENTRY"

export const openDataset = (dataset, dataType) => ({ type: OPEN_DATASET, dataset, dataType })

export const selectEntry = (_id, type, properties) => ({ type: SELECT_ENTRY, _id, dataType: type, properties }) 

export default (state = { dataset: { _id: null, type: null }, entry: {_id: null, type: null, properties: {}}}, action) => {
    switch(action.type) {
        case OPEN_DATASET:
            return {
                ...state,
                dataset: {
                    _id: action.dataset,
                    type: action.dataType
                }
            }
        case SELECT_ENTRY:
            return {
                ...state,
                entry: {
                    _id: action._id,
                    type: action.dataType,
                    properties: action.properties
                }
            }
        default:
            return state
    }
}