import React from "react"
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading(props)  {
    return (
        <React.Fragment>
            {props.loading ? 
                <div style={{ width: "100%" }}>
                   <CircularProgress style={{ margin: "50px calc(50% - 20px)" }}/> 
                </div>:
            props.children}
        </React.Fragment>
    )
}