import React from "react"

import CircularProgress from 'material-ui/CircularProgress';

import { connect } from "react-redux"

class Logout extends React.Component {
    
    componentDidMount() {
        localStorage.removeItem("access_token")

        this.props.history.push("/")
    }

    render() {
        return (
            <div style={{margin: 'calc(50vh - 100px) calc(50vw - 30px)'}}>
                <CircularProgress size={60} thickness={7} />
            </div>
        )
    }
}

export default Logout
