import React, { useEffect, useMemo } from 'react'

import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import moment from 'moment'

const ListItemEditDialogDuration = ({ onClose, onConfirm, title, label, value }) => {
    const [openDialog, setOpenDialog] = React.useState(false)
    const [unit, setUnit] = React.useState('seconds')
    const [newValue, setNewValue] = React.useState(value)

    const duration = useMemo(() => {
        return moment.duration(newValue)
    }, [newValue])

    const lastDuration = useMemo(() => {
        return moment.duration(value)
    }, [value])

    const onConfirmClick = () => {
        onConfirm(newValue)
        setOpenDialog(false)
        onClose()
    }

    const onOpen = () => {
        setOpenDialog(true)
    }

    const onCancel = () => {
        setOpenDialog(false)
        onClose()
        setNewValue(value)
    }

    const valueAsString = value > 0 ? lastDuration.locale('es').humanize() : 0

    return (
        <React.Fragment>
            <ListItem button onClick={onOpen}>
                <ListItemText primary={label} secondary={valueAsString} />
            </ListItem>
            <Dialog open={openDialog}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                value={duration.as(unit)}
                                onChange={(event) =>
                                    setNewValue(
                                        moment.duration(event.target.value, unit).as('milliseconds')
                                    )
                                }
                                variant="outlined"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    value={unit}
                                    onChange={(event) => setUnit(event.target.value)}
                                >
                                    <MenuItem value="seconds">seconds</MenuItem>´
                                    <MenuItem value="minutes">minutes</MenuItem>
                                    <MenuItem value="hours">hours</MenuItem>
                                    <MenuItem value="days">days</MenuItem>
                                    <MenuItem value="months">months</MenuItem>
                                    <MenuItem value="years">years</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={onConfirmClick}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

ListItemEditDialogDuration.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
}

ListItemEditDialogDuration.defaultProps = {
    onConfirm: () => {},
    onClose: () => {},
    title: '',
    label: '',
    value: 0,
}

export default ListItemEditDialogDuration
