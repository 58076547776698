import React from 'react'
import { PropTypes } from 'prop-types'
import TextField from '@material-ui/core/TextField'
import ListSubheader from '@material-ui/core/ListSubheader'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, inputNodes, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <List>
                <ListItem>
                    <TextField
                        label="Timeout (ms)"
                        value={getProperty('timeout', '')}
                        onChange={onPropertyChangeBuilder('timeout')}
                        fullWidth
                    />
                </ListItem>
                <ListSubheader>Alias</ListSubheader>
                {inputNodes.map((inputNode) => {
                    return (
                        <ListItem key={inputNode.id}>
                            <TextField
                                label={inputNode.id}
                                value={JSON.parse(getProperty('aliases', '{}'))[inputNode.id]}
                                onChange={onPropertyChangeBuilder('aliases', (e) =>
                                    JSON.stringify({
                                        ...JSON.parse(getProperty('aliases', '{}')),
                                        [inputNode.id]: e.target.value,
                                    })
                                )}
                                fullWidth
                            />
                        </ListItem>
                    )
                })}
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Versión</InputLabel>
                        <Select
                            value={getProperty('version', '$LATEST')}
                            onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                        >
                            {nodeDefinition.conf.versions.map((version) => (
                                <MenuItem key={`${node.id}_version${version}`} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    inputNodes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
            position: PropTypes.shape({
                x: PropTypes.number,
                y: PropTypes.number,
            }),
            ports: PropTypes.array,
            properties: PropTypes.object,
            size: PropTypes.shape({
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        })
    ).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
