import gql from "graphql-tag"

export const GET_MULTI_LABEL_CLASSIFICATION_ENTRIES = gql`
    query GetMultiLabelClassificationEntries($params: GetMultiLabelClassificationEntriesRequest) {
        getMultiLabelClassificationEntries(params: $params) {
            multiLabelClassificationEntries {
                _id
                url
            }
        }
    }
`

export const GET_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID = gql`
    query GetMultiLabelClassificationEntryById($params: GetMultiLabelClassificationEntryByIdRequest) {
        getMultiLabelClassificationEntryById(params: $params) {
            multiLabelClassificationEntry {
                _id
                url
                labels
            }
        }
    }
`

export const UPDATE_MULTI_LABEL_CLASSIFICATION_ENTRY_BY_ID = gql`
    mutation UpdateMultiLabelClassificationEntryById($params: UpdateMultiLabelClassificationEntryByIdRequest) {
        updateMultiLabelClassificationEntryById(params: $params) {
            status
        }
    }

`

export const CREATE_MULTI_LABEL_CLASSIFICATION_ENTRIES_FROM_DATASET = gql`
    mutation createMultiLabelClassificationEntriesFromDataset($params: CreateMultiLabelClassificationEntriesFromDatasetRequest!) {
        createMultiLabelClassificationFromDataset(params: $params) {
            status
        }
    }

`

export const MULTI_LABEL_CLASSIFICATION_GET_ENTRIES_COUNT = gql`
    query GetEntriesCountByTrainingSet($params: GetEntriesCountByTrainingSetRequest!) {
        getEntriesCountByTrainingSet(params: $params) {
            count
        }
    }
`

export const DELETE_TRAINING_SET_ENTRY_BY_ID = gql`
    mutation RremoveTrainingSetEntryById($params: RemoveTrainingSetEntryByIdRequest!) {
        removeTrainingSetEntryById(params: $params) {
            status
        }
    }

`