import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'

const Text = ({ textAlign, fontSize, fontWeight, color, content }) => {
    const {
        connectors: { connect, drag },
    } = useNode()

    const style = {
        textAlign,
        fontSize,
        fontWeight,
        color,
    }

    let contentInner = ''

    try {
        const parsed = JSON.parse(content)
        switch (parsed.type) {
            case 'fixed':
                contentInner = parsed.value
                break
            case 'parameter':
                contentInner = 'param'
                break
            case 'variable':
                contentInner = 'var'
                break
            default:
                contentInner = ''
                break
        }
    } catch (err) {
        contentInner = content
    }

    return (
        <p ref={(ref) => connect(drag(ref))} style={style}>
            {contentInner}
        </p>
    )
}

Text.craft = {
    displayName: 'Texto',
    custom: {
        component: true,
    },
    props: {
        content: 'Texto',
        textAlign: 'left',
        fontSize: 12,
        color: '#000',
        fontWeight: 500,
    },
}

Text.propTypes = {
    content: PropTypes.string,
    textAlign: PropTypes.string,
    fontSize: PropTypes.number,
    color: PropTypes.string,
    fontWeight: PropTypes.number,
}

Text.defaultProps = {
    content: 'Texto',
    textAlign: 'left',
    fontSize: 12,
    color: '#000',
    fontWeight: 500,
}

export default Text
