import React, { useState, useCallback } from 'react'

import { useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useMutation } from '@apollo/react-hooks'
import { useSelector, useDispatch } from 'react-redux'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'

import DeleteIcon from '@material-ui/icons/Delete'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import LoadingButton from 'components/loading-button'

import { CREATE_ENTRIES } from '../../../../queries/training-sets'
import { closeCreateEntriesDialog } from '../../redux/general'

const initialStateProgress = {
    completed: 0,
    total: 0,
    progress: 0,
}

const AddEntriesDialog = () => {
    const { trainingSet } = useParams()
    const dispatch = useDispatch()
    const openCreateEntryDialog = useSelector(
        (state) => state.trainingSetObjectDetection.general.createEntriesDialogOpen
    )

    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [filesLoad, setFilesLoad] = useState([])
    const [uploadProgress, setUplProgress] = useState({ ...initialStateProgress })

    const [addEntries] = useMutation(CREATE_ENTRIES)

    const onClose = () => !loading && dispatch(closeCreateEntriesDialog())

    const onAccept = async () => {
        setLoading(true)
        setUplProgress((prev) => ({ ...prev, total: files.length }))

        files.map((file, i) => {
            setFilesLoad((prev) => {
                const actual = prev[i]
                actual.loading = false
                return [...prev, actual]
            })
            return file
        })

        if (files.length > 0) {
            const entries = await Promise.all(
                files.map((f, i) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader()

                        reader.readAsDataURL(f)
                        reader.onload = (e) => {
                            const image = new Image()

                            // Set the Base64 string return from FileReader as source.
                            image.src = e.target.result

                            // Validate the File Height and Width.
                            image.onload = function () {
                                resolve({
                                    inputs: [
                                        {
                                            name: 'filesize',
                                            value: f.size.toString(),
                                        },
                                        {
                                            name: 'height',
                                            value: this.height.toString(),
                                        },
                                        {
                                            name: 'width',
                                            value: this.width.toString(),
                                        },
                                    ],
                                })
                            }
                        }
                    })
                })
            )

            const {
                data: {
                    createTrainingSetEntries: { status, responses },
                },
            } = await addEntries({
                variables: {
                    params: {
                        trainingSet,
                        entries,
                    },
                },
            })

            // FIXME: Alway status is SUCCEEDED
            if (status === 'SUCCEEDED') {
                for (let i = 0; i < responses.length; i++) {
                    const res = responses[i]

                    const outputs = res.outputs.reduce(
                        (acc, item) => ({ ...acc, [item.name]: item.value }),
                        {}
                    )

                    const url = outputs['url']
                    const fields = JSON.parse(outputs['fields'])

                    const data = new FormData()
                    Object.keys(fields).forEach((k) => data.append(k, fields[k]))
                    data.append('file', files[i])

                    setFilesLoad((prev) => [...prev, (prev[i].loading = true)])

                    await fetch(url, {
                        method: 'POST',
                        body: data,
                    })

                    setFilesLoad((prev) => [...prev, (prev[i].loading = false)])

                    setUplProgress((prev) => ({
                        ...prev,
                        completed: prev.completed + 1,
                        progress: ((prev.completed + 1) / prev.total) * 100,
                    }))
                }
            }
        }

        setLoading(false)
        setFiles([])
        setFilesLoad([])
        setUplProgress({ ...initialStateProgress })
        dispatch(closeCreateEntriesDialog())
    }

    const onDrop = useCallback((acceptedFiles) => {
        setFilesLoad(new Array(acceptedFiles.length).fill().map(() => ({ loading: false })))
        setFiles(acceptedFiles)
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onRequestDelete = (i) => () => {
        setFilesLoad([...filesLoad.slice(0, i), ...filesLoad.slice(i + 1)])
        setFiles([...files.slice(0, i), ...files.slice(i + 1)])
    }

    return (
        <Dialog open={openCreateEntryDialog} fullWidth maxWidth="lg">
            <DialogContent>
                <Grid container spacing={2} style={{ height: 600 }}>
                    <Grid item xs={5} style={{ height: '100%' }}>
                        <List
                            subheader={
                                <ListSubheader disableSticky component="div">
                                    Archivos
                                </ListSubheader>
                            }
                            style={{ height: '100%', overflow: 'auto' }}
                        >
                            {files.map((f, i) => (
                                <ListItem key={f.name}>
                                    <ListItemText primary={f.path} />
                                    <ListItemSecondaryAction>
                                        {filesLoad[i].loading && (
                                            <CircularProgress color="secondary" size={20} />
                                        )}
                                        {!loading && (
                                            <IconButton
                                                edge="center"
                                                disabled={loading}
                                                onClick={onRequestDelete(i)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        {uploadProgress.progress > 0 && (
                            <LinearProgress
                                variant="determinate"
                                color="secondary"
                                value={uploadProgress.progress}
                            />
                        )}
                    </Grid>
                    <Grid item xs={7} style={{ height: '100%' }}>
                        <div
                            {...getRootProps()}
                            style={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                borderWidth: '2px',
                                borderStyle: 'dotted',
                            }}
                        >
                            <div style={{ marginBottom: 20 }}>
                                <InsertDriveFileIcon style={{ fontSize: 40 }} />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Suelta los archivos aquí</p>
                                ) : (
                                    <p>Arrastra archivos o haz click aquí</p>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={onClose}>
                    Cancelar
                </Button>
                <LoadingButton loading={loading} onClick={onAccept}>
                    Aceptar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default AddEntriesDialog
