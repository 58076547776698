import { SELECTED_IMAGE } from "../queries/state"

export default {
    Query: {
        labelerSelectedImage(root, args, { cache }) {
            try {
                const { labelerSelectedImage } = cache.readQuery({ query: SELECTED_IMAGE })   
                return labelerSelectedImage
            } catch(err){
                return null
            }
        },
    },
    Mutation: {
        setLabelerSelectedImage(root, args, { cache }) {

            cache.writeQuery({ 
                query: SELECTED_IMAGE, 
                data: {
                    labelerSelectedImage: args.params._id
                }
            })

            return "SUCCEEDED"
        }
    }
}