import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import SamplesToolbox from '../images-menu/samplesToolbox'
import AnnToolbox from '../annotations/annToolbox'

const availableToolbox = {
    samples: SamplesToolbox,
    annotations: AnnToolbox,
}

/** Component to be as a board to insert the
 *  toolboxes.
 */
const Tools = () => {
    const [toolbox, setToolbox] = useState(null)
    const toolboxId = useSelector(
        (state) => state.trainingSetObjectDetection.general.selectedToolbox
    )

    const toolboxButtonsComponent = Object.keys(availableToolbox).includes(toolboxId)
        ? availableToolbox[toolboxId]
        : () => null

    return (
        <React.Fragment>
            <div
                style={{
                    position: 'absolute',
                }}
            >
                {toolbox}
            </div>
            {React.createElement(toolboxButtonsComponent, { setToolbox }, null)}
        </React.Fragment>
    )
}

export default Tools
