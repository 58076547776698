import React, { useEffect } from "react"

import Grid from "@material-ui/core/Grid"


import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useHotkeys } from "react-hotkeys-hook"

import Left from "./containers/left/scene"
import Center from "./containers/center/scene"
import Right from "./containers/right/scene"

import { GET_TRAINING_SET_ENTRY_BY_ID, UPDATE_TRAINING_SET_ENTRY_BY_ID } from "../../queries/training-sets"

const TrainingSetsPolygon = (props) => {

    const { selectedObject, selectedEntry } = useSelector(state => state.horus.trainingSets.polygon)

    const { data: { horus: { trainingSets: { getTrainingSetEntryById: { entry = {}} = {}} = {}} = {}} = {}} = useQuery(GET_TRAINING_SET_ENTRY_BY_ID, { 
        variables: { 
            params: { 
                _id: selectedEntry, 
                dataType: "rgb", 
                labelType: "polygon" 
            }
        }
    })
    const [ updateEntryById ] = useMutation(UPDATE_TRAINING_SET_ENTRY_BY_ID)

    const e = entry || {}
    const properties = e.properties || []
    const byId = properties.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

    const polygons = JSON.parse(byId["annotations"] || "[]")

    const onDeletePressed = () => async () => {
        console.log(selectedObject)
        await updateEntryById({
            variables: {
                params: {
                    _id: selectedEntry,
                    dataType: "rgb",
                    labelType: "polygon",
                    properties: [
                        {
                            name: "annotations",
                            value: JSON.stringify([
                                ...polygons.slice(0, selectedObject),
                                ...polygons.slice(selectedObject + 1)
                            ])
                        }
                    ]
                }
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SET_ENTRY_BY_ID,
                    variables: { params: { _id: selectedEntry, labelType: "polygon", dataType: "rgb" }}
                }
            ]
        })
    }

    useEffect(() => {
        const onKeyPressed = (event) => {
            if(event.keyCode === 46 || event.keyCode === 8) {
                onDeletePressed()
            }
        }

        window.addEventListener("keydown", onKeyPressed, { passive: false })

        return () => {
            window.removeEventListener('keydown', onKeyPressed);
        } 
    }, [selectedEntry, selectedObject, ...polygons])

    useHotkeys("Delete", onDeletePressed(selectedEntry, selectedObject), { }, [  ])

 


    return (
            <Grid container>
                <Grid item xs={2}>
                    <Left/>
                </Grid>
                <Grid item xs={8}>
                    <Center />
                </Grid>
                <Grid item xs={2}>
                    <Right/>
                </Grid>
            </Grid>
    )
}

export default TrainingSetsPolygon