import React, { useState, useRef, useEffect, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@mui/material/styles'
import { useDispatch, useSelector, ReactReduxContext, shallowEqual } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import ErrorIcon from '@material-ui/icons/Error'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import TextField from '@material-ui/core/TextField'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { Canvas, extend, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, GizmoHelper, GizmoViewport } from '@react-three/drei'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as THREE from 'three'
import { v4 as uuidv4 } from 'uuid'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import myFont from 'three/examples/fonts/helvetiker_regular.typeface.json'
import { useGesture } from 'react-use-gesture'
import BoxCollider from '../../components/boxCollider'
import Pallet from '../../components/pallet'

import {
    updateLayout,
    setSelectedLayout,
    setSelectedBox,
    addBoxToCurrentLayout,
    setActiveBox,
    deleteBoxFromCurrentLayout,
    updateBoxFromCurrentLayout,
    deleteLayout,
} from '../../reducers/layout'
import ThreeBox from '../../components/box'
import AddUnits from '../../../../../../../../../../../../../../../components/AddUnits'
import { GET_PARAMS_BY_GROUP, CREATE_PARAM, UPDATE_PARAM } from '../../../../params/queries/params'

const LENGTH_UNITS = {
    mm: 1 / 1000,
    cm: 1 / 100,
    dm: 1 / 10,
    m: 1,
}

const STATE_SAVE_TIME = 5 // s

extend({ TextGeometry })

const Box = ({ layoutId, boxIndex, overObjectName }) => {
    const boxInfo = useSelector((state) => {
        const box = state.group.palletizer.layouts.layouts[layoutId].layout[boxIndex]
        return { id: box.id, boxId: box.boxId }
    }, shallowEqual)

    const dispatch = useDispatch()

    const { store } = useContext(ReactReduxContext)
    const boxRef = store.getState().group.palletizer.boxes.boxes[boxInfo.boxId]

    const materialRef = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]
    const meshRef = useRef()
    const textRef = useRef()
    const overObject = useRef()
    const groupRef = useRef()
    const overObjectBbox = useRef(new THREE.Box3())
    const pivotControlsRef = useRef()
    const onCollisionRef = useRef(false)
    const ghostBufferRef = useRef()
    const worldPosition = useRef(new THREE.Vector3())
    const ghostWorldPosition = useRef(new THREE.Vector3())
    const constrainedPosition = useRef(new THREE.Vector3())
    const lastPoint = useRef(new THREE.Vector2())

    const font = new FontLoader().parse(myFont)

    const { controls, scene, raycaster, size } = useThree()

    const boxMovement = useGesture({
        onClick: () => {
            const isActive = store.getState().group.palletizer.layouts.activeBox === boxInfo.id
            if (!isActive) {
                dispatch(setActiveBox(boxInfo.id))
            }
        },
        onDragStart: () => {
            const isActive = store.getState().group.palletizer.layouts.activeBox === boxInfo.id
            if (isActive) {
                controls.enabled = false

                // Equal position of ghost box and render box
                ghostBufferRef.current.position.copy(groupRef.current.position)

                // Restart last point
                lastPoint.current.set(0, 0, 0)
            }
        },
        onDrag: (event) => {
            const isActive = store.getState().group.palletizer.layouts.activeBox === boxInfo.id
            if (isActive) {
                const mouse = new THREE.Vector2(
                    ((event.xy[0] - size.left - size.width / 2) * 2) / size.width,
                    -((event.xy[1] - size.top - size.height / 2) * 2) / size.height
                )
                raycaster.setFromCamera(mouse, event.event.camera)
                const intersects = raycaster.intersectObjects([overObject.current])
                if (intersects.length === 0) return null
                if (lastPoint.current.length() === 0) {
                    lastPoint.current = intersects[0].point.clone()
                    return null
                }

                const dragDistance = intersects[0].point.clone().sub(lastPoint.current)

                ghostBufferRef.current.position.set(
                    ghostBufferRef.current.position.x + dragDistance.x,
                    ghostBufferRef.current.position.y + dragDistance.y,
                    intersects[0].point.z + boxRef.measurements[2] / 2
                )
                lastPoint.current = intersects[0].point
            }
            return null
        },
        onDragEnd: () => {
            const isActive = store.getState().group.palletizer.layouts.activeBox === boxInfo.id
            if (isActive) {
                controls.enabled = true
                // Equal position of ghost box and render box
                ghostBufferRef.current.position.copy(groupRef.current.position)
            }
        },
    })

    useEffect(() => {
        // Compute over object bounding box
        scene.traverse((obj) => {
            if (obj.name === overObjectName) {
                overObject.current = obj
                overObjectBbox.current.setFromObject(obj)
                overObjectBbox.current.applyMatrix4(obj.matrixWorld)
            }
        })

        // Center text
        if (textRef.current) {
            textRef.current.geometry.computeBoundingBox()

            const textCenterPoint = [
                (textRef.current.geometry.boundingBox.max.x -
                    textRef.current.geometry.boundingBox.min.x) /
                    2,
                (textRef.current.geometry.boundingBox.max.y -
                    textRef.current.geometry.boundingBox.min.y) /
                    2,
            ]

            textRef.current.translateX(-textCenterPoint[0])
            textRef.current.translateY(-textCenterPoint[1])
        }
    }, [overObjectName, scene])

    useFrame(({ clock }) => {
        const isActive = store.getState().group.palletizer.layouts.activeBox === boxInfo.id
        if (materialRef[0].current) {
            if (isActive) {
                materialRef.forEach((mat) => {
                    mat.current.emissive.set(0x87ceeb)
                })
            } else {
                materialRef.forEach((mat) => {
                    mat.current.emissive.set(0x000000)
                })
            }
        }

        if (pivotControlsRef.current) {
            if (pivotControlsRef.current.children[0].visible !== isActive) {
                pivotControlsRef.current.children[0].visible = isActive
            }
        }

        if (isActive && overObjectBbox.current && groupRef.current) {
            const box = store.getState().group.palletizer.layouts.layouts[layoutId].layout[boxIndex]
            if (box.positionNeedsUpdate) {
                ghostBufferRef.current.position.set(
                    box.position[0],
                    box.position[1],
                    box.position[2]
                )
                dispatch(
                    updateBoxFromCurrentLayout(boxIndex, {
                        positionNeedsUpdate: false,
                    })
                )
            }

            if (box.rotationNeedsUpdate) {
                ghostBufferRef.current.rotation.set(
                    box.rotation[0],
                    box.rotation[1],
                    box.rotation[2]
                )
                dispatch(
                    updateBoxFromCurrentLayout(boxIndex, {
                        rotationNeedsUpdate: false,
                    })
                )
            }

            if (onCollisionRef.current.length === 0) {
                groupRef.current.position.copy(ghostBufferRef.current.position)
                groupRef.current.rotation.copy(ghostBufferRef.current.rotation)
            } else if (onCollisionRef.current.length === 1) {
                groupRef.current.getWorldPosition(worldPosition.current)
                ghostBufferRef.current.getWorldPosition(ghostWorldPosition.current)

                if (Math.round(onCollisionRef.current[0].direction.y) === 0) {
                    // Moving along y axis
                    constrainedPosition.current.set(
                        worldPosition.current.x,
                        ghostWorldPosition.current.y,
                        ghostWorldPosition.current.z
                    )
                } else {
                    const newY =
                        worldPosition.current.y -
                        (onCollisionRef.current[0].direction.x /
                            onCollisionRef.current[0].direction.y) *
                            (ghostWorldPosition.current.x - worldPosition.current.x)

                    constrainedPosition.current.set(
                        ghostWorldPosition.current.x,
                        newY,
                        ghostWorldPosition.current.z
                    )
                }

                groupRef.current.position.set(
                    constrainedPosition.current.x,
                    constrainedPosition.current.y,
                    constrainedPosition.current.z
                )
                groupRef.current.rotation.set(
                    ghostBufferRef.current.rotation.x,
                    ghostBufferRef.current.rotation.y,
                    ghostBufferRef.current.rotation.z
                )
            }
        }

        if (Math.ceil(clock.elapsedTime) % STATE_SAVE_TIME === 0) {
            dispatch(
                updateBoxFromCurrentLayout(boxIndex, {
                    position: [
                        parseFloat(groupRef.current.position.x.toFixed(3)),
                        parseFloat(groupRef.current.position.y.toFixed(3)),
                        parseFloat(groupRef.current.position.z.toFixed(3)),
                    ],
                    rotation: [
                        groupRef.current.rotation.x,
                        groupRef.current.rotation.y,
                        groupRef.current.rotation.z,
                    ],
                    positionNeedsUpdate: false,
                    rotationNeedsUpdate: false,
                })
            )
        }
    })

    const meshPropsExtended = { ref: meshRef }
    /* eslint-disable react/no-unknown-property */
    const box = store.getState().group.palletizer.layouts.layouts[layoutId].layout[boxIndex]
    return (
        <React.Fragment>
            <group ref={groupRef} position={box.position} rotation={box.rotation}>
                <ThreeBox
                    box={{ size: boxRef.measurements, labelSide: boxRef.labelSide }}
                    meshProps={meshPropsExtended}
                    materialProps={[
                        { ref: materialRef[0] },
                        { ref: materialRef[1] },
                        { ref: materialRef[2] },
                        { ref: materialRef[3] },
                        { ref: materialRef[4] },
                        { ref: materialRef[5] },
                    ]}
                />
                <mesh ref={textRef} position={[-0.07 / 2, 0, boxRef.measurements[2] / 2]}>
                    <textGeometry
                        args={[
                            `${boxInfo.id}`,
                            { font, size: 0.07, height: 0.01, color: 0x000000 },
                        ]}
                    />
                    <meshStandardMaterial attach="material" color={0x000000} />
                </mesh>
            </group>
            <BoxCollider
                checkActive={() =>
                    store.getState().group.palletizer.layouts.activeBox === boxInfo.id
                }
                onCollisionRef={onCollisionRef}
                help={false}
            >
                <group
                    ref={ghostBufferRef}
                    position={box.position}
                    rotation={box.rotation}
                    {...boxMovement()}
                >
                    <mesh>
                        <boxBufferGeometry
                            attach="geometry"
                            args={[
                                boxRef.measurements[0] + 0.0005,
                                boxRef.measurements[1] + 0.0005,
                                boxRef.measurements[2] + 0.0005,
                            ]}
                        />
                        <meshStandardMaterial
                            visible={true}
                            attach="material"
                            wireframe={true}
                            color={0x36454f}
                        />
                    </mesh>
                </group>
            </BoxCollider>
        </React.Fragment>
    )
}

Box.propTypes = {
    layoutId: PropTypes.string.isRequired,
    boxIndex: PropTypes.number.isRequired,
    overObjectName: PropTypes.string,
}
Box.defaultProps = {
    overObjectName: null,
}

const Layout = ({ layoutId }) => {
    const boxCount = useSelector(
        (state) => state.group.palletizer.layouts.layouts[layoutId].layout.length
    )

    const { store } = useContext(ReactReduxContext)
    const layout = store.getState().group.palletizer.layouts.layouts[layoutId]

    const onCollisionRef = useRef()

    /* eslint-disable react/no-unknown-property */
    return (
        <group>
            <Pallet
                groupProps={{
                    name: 'palletName',
                    rotation: [0, 0, 0],
                    position: [layout.size[0] / 2, layout.size[1] / 2, -0.1],
                }}
                size={[layout.size[0], layout.size[1], 0.2]}
            />

            {[...Array(boxCount).keys()].map((boxIndex) => {
                return (
                    <Box
                        key={boxIndex}
                        layoutId={layoutId}
                        boxIndex={boxIndex}
                        overObjectName="palletName"
                    />
                )
            })}
            <BoxCollider onCollisionRef={onCollisionRef} help={false}>
                <group
                    rotation={[0, 0, 0]}
                    position={[layout.size[0] / 2, layout.size[1] / 2, layout.size[2] / 2]}
                >
                    <mesh name="ghostBox">
                        <boxBufferGeometry
                            attach="geometry"
                            args={[layout.size[0], layout.size[1], layout.size[2] + 0.002]}
                        />
                        <meshStandardMaterial visible={false} wireframe={true} />
                    </mesh>
                </group>
            </BoxCollider>
        </group>
    )
}

Layout.propTypes = {
    layoutId: PropTypes.string.isRequired,
}

const ThreeJSScene = ({ layoutId, zoom }) => {
    const layoutInfo = useSelector((state) => {
        const layout = state.group.palletizer.layouts.layouts[layoutId]
        return { id: layout.id, size: layout.size, name: layout.name }
    }, shallowEqual)

    return (
        <Canvas
            orthographic
            camera={{
                zoom,
                position: [layoutInfo.size[0] / 2, layoutInfo.size[1] / 2, 3],
            }}
            gl={{ powerPreference: 'high-performance' }}
            unmountOnExit
        >
            <OrbitControls
                makeDefault
                target={[layoutInfo.size[0] / 2, layoutInfo.size[1] / 2, -layoutInfo.size[2] / 2]}
                maxAzimuthAngle={Math.PI / 2}
                minAzimuthAngle={-Math.PI / 2}
                maxPolarAngle={Math.PI}
                minPolarAngle={0}
            />
            <ambientLight intensity={0.6} />
            <Layout layoutId={layoutId} />
            <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                <GizmoViewport opacity={1} />
            </GizmoHelper>
            <axesHelper args={[5]} />
        </Canvas>
    )
}

ThreeJSScene.propTypes = {
    layoutId: PropTypes.string.isRequired,
    zoom: PropTypes.number.isRequired,
}

const DraggableListItem = ({ layoutId, boxIndex }) => {
    const box = useSelector(
        (state) => state.group.palletizer.layouts.layouts[layoutId].layout[boxIndex]
    )
    const activeBox = useSelector((state) => state.group.palletizer.layouts.activeBox)

    const { store } = useContext(ReactReduxContext)

    const boxRef = store.getState().group.palletizer.boxes.boxes[box.boxId]

    const [collapsePosition, setCollapsePosition] = useState(true)
    const [collapseRotation, setCollapseRotation] = useState(true)
    const [xUnit, setXUnit] = useState('m')
    const [yUnit, setYUnit] = useState('m')
    const [zUnit, setZUnit] = useState('m')

    const dispatch = useDispatch()

    const onActiveClick = () => {
        if (activeBox === box.id) {
            dispatch(setActiveBox(null))
        } else {
            dispatch(setActiveBox(box.id))
        }
    }

    const onDeleteClick = () => {
        dispatch(deleteBoxFromCurrentLayout(boxIndex))
    }

    const changeX = (event) => {
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                position: [
                    parseFloat((parseFloat(event.target.value) * LENGTH_UNITS[xUnit]).toFixed(3)),
                    box.position[1],
                    box.position[2],
                ],
                positionNeedsUpdate: true,
            })
        )
    }

    const changeY = (event) => {
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                position: [
                    box.position[0],
                    parseFloat((parseFloat(event.target.value) * LENGTH_UNITS[yUnit]).toFixed(3)),
                    box.position[2],
                ],
                positionNeedsUpdate: true,
            })
        )
    }

    const changeZ = (event) => {
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                position: [
                    box.position[0],
                    box.position[1],
                    parseFloat((parseFloat(event.target.value) * LENGTH_UNITS[zUnit]).toFixed(3)),
                ],
                positionNeedsUpdate: true,
            })
        )
    }

    const changeRoll = (event) => {
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                rotation: [
                    (parseFloat(event.target.value) * Math.PI) / 180,
                    box.rotation[1],
                    box.rotation[2],
                ],
            })
        )
    }

    const rotateRoll = () => {
        const roll = Math.ceil(((box.rotation[0] + Math.PI / 2) * 180) / Math.PI) % 360
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                rotation: [(roll * Math.PI) / 180, box.rotation[1], box.rotation[2]],
                rotationNeedsUpdate: true,
            })
        )
    }

    const changePitch = (event) => {
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                rotation: [
                    box.rotation[0],
                    (parseFloat(event.target.value) * Math.PI) / 180,
                    box.rotation[2],
                ],
            })
        )
    }

    const rotatePitch = () => {
        const pitch = Math.ceil(((box.rotation[1] + Math.PI / 2) * 180) / Math.PI) % 360
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                rotation: [box.rotation[0], (pitch * Math.PI) / 180, box.rotation[2]],
                rotationNeedsUpdate: true,
            })
        )
    }

    const changeYaw = (event) => {
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                rotation: [
                    box.rotation[0],
                    box.rotation[1],
                    (parseFloat(event.target.value) * Math.PI) / 180,
                ],
            })
        )
    }

    const rotateYaw = () => {
        const yaw = Math.ceil(((box.rotation[2] + Math.PI / 2) * 180) / Math.PI) % 360
        dispatch(
            updateBoxFromCurrentLayout(boxIndex, {
                rotation: [box.rotation[0], box.rotation[1], (yaw * Math.PI) / 180],
                rotationNeedsUpdate: true,
            })
        )
    }

    return (
        <React.Fragment>
            <ListItem selected={activeBox === box.id}>
                <ListItemIcon style={{ cursor: 'move' }}>
                    <DragHandleIcon />
                </ListItemIcon>
                <ListItemText
                    primary={`Caja: ${box.id}`}
                    secondary={`Tipo: ${boxRef.name}`}
                    onClick={onActiveClick}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={onDeleteClick}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={activeBox === box.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem>
                        <ListItemText
                            sx={{ pl: 4 }}
                            primary={`Position: ${box.position.map((pos) => pos.toFixed(3))}`}
                        />
                        <ListItemSecondaryAction
                            onClick={() => setCollapsePosition(!collapsePosition)}
                        >
                            {!collapsePosition ? <ExpandLess /> : <ExpandMore />}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={!collapsePosition} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="x: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit={xUnit}
                                    onChangeUnit={setXUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={box.position[0] / LENGTH_UNITS[xUnit]}
                                        onChange={changeX}
                                    />
                                </AddUnits>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="y: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit={yUnit}
                                    onChangeUnit={setYUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={box.position[1] / LENGTH_UNITS[yUnit]}
                                        onChange={changeY}
                                    />
                                </AddUnits>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="z: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit={zUnit}
                                    onChangeUnit={setZUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={box.position[2] / LENGTH_UNITS[zUnit]}
                                        onChange={changeZ}
                                    />
                                </AddUnits>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem>
                        <ListItemText
                            sx={{ pl: 4 }}
                            primary={`Rotation: ${box.rotation.map((rot) => rot.toFixed(2))}`}
                        />
                        <ListItemSecondaryAction
                            onClick={() => setCollapseRotation(!collapseRotation)}
                        >
                            {!collapseRotation ? <ExpandLess /> : <ExpandMore />}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={!collapseRotation} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="x (Roll): " />
                                <TextField
                                    type="number"
                                    value={(box.rotation[0] * 180) / Math.PI}
                                    onChange={changeRoll}
                                />
                                <ListItemSecondaryAction onClick={rotateRoll}>
                                    <RotateLeftIcon />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="y (Pitch): " />
                                <TextField
                                    type="number"
                                    value={(box.rotation[1] * 180) / Math.PI}
                                    onChange={changePitch}
                                />
                                <ListItemSecondaryAction onClick={rotatePitch}>
                                    <RotateLeftIcon />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="z (Yaw): " />
                                <TextField
                                    type="number"
                                    value={(box.rotation[2] * 180) / Math.PI}
                                    onChange={changeYaw}
                                />
                                <ListItemSecondaryAction onClick={rotateYaw}>
                                    <RotateLeftIcon />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Collapse>
        </React.Fragment>
    )
}

DraggableListItem.propTypes = {
    layoutId: PropTypes.string.isRequired,
    boxIndex: PropTypes.number.isRequired,
}

const LayoutDataEdit = ({ layoutId }) => {
    const layoutInfo = useSelector((state) => {
        const layout = state.group.palletizer.layouts.layouts[layoutId]
        const boxIds = state.group.palletizer.layouts.layouts[layoutId].layout.reduce(
            (prev, next) => {
                return [...prev, next.id]
            },
            []
        )
        return {
            size: layout.size,
            id: layout.id,
            name: layout.name,
            boxesCount: layout.layout.length,
            boxIds,
        }
    }, shallowEqual)

    const selectedBoxIndex = useSelector((state) => state.group.palletizer.layouts.selectedBox)

    const { store } = useContext(ReactReduxContext)
    const { boxes } = store.getState().group.palletizer.boxes
    const dispatch = useDispatch()

    const [widthUnit, setWidthUnit] = useState('m')
    const [heightUnit, setHeightUnit] = useState('m')
    const [deepUnit, setDeepUnit] = useState('m')

    const boxCount = useRef(0)

    const onLayoutNameChange = (e) => {
        dispatch(updateLayout(layoutId, { name: e.target.value }))
    }

    const onWidthChange = (e) => {
        dispatch(
            updateLayout(layoutId, {
                size: [
                    parseFloat(e.target.value) * LENGTH_UNITS[widthUnit],
                    layoutInfo.size[1],
                    layoutInfo.size[2],
                ],
            })
        )
    }

    const onHeightChange = (e) => {
        dispatch(
            updateLayout(layoutId, {
                size: [
                    layoutInfo.size[0],
                    parseFloat(e.target.value) * LENGTH_UNITS[heightUnit],
                    layoutInfo.size[2],
                ],
            })
        )
    }

    const onDeepChange = (e) => {
        dispatch(
            updateLayout(layoutId, {
                size: [
                    layoutInfo.size[0],
                    layoutInfo.size[1],
                    parseFloat(e.target.value) * LENGTH_UNITS[deepUnit],
                ],
            })
        )
    }

    const onSelectedBoxChange = (e) => {
        dispatch(setSelectedBox(e.target.value))
    }

    const onAddClick = () => {
        let newId = boxCount.current
        while (layoutInfo.boxIds.includes(newId)) {
            newId += 1
        }
        dispatch(
            addBoxToCurrentLayout({
                id: newId,
                boxId: selectedBoxIndex,
                position: [0, 0, 0],
                rotation: [0, 0, 0],
            })
        )
        dispatch(setActiveBox(newId))
        boxCount.current = newId + 1
    }

    return (
        <React.Fragment>
            <List style={{ maxHeight: '50%', overflow: 'auto' }}>
                <ListItem>
                    <TextField
                        label="Nombre"
                        value={layoutInfo.name}
                        onChange={onLayoutNameChange}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <AddUnits
                        conversionFactors={LENGTH_UNITS}
                        defaultUnit="m"
                        onChangeUnit={setWidthUnit}
                    >
                        <TextField
                            label="Ancho"
                            value={(layoutInfo.size[0] / LENGTH_UNITS[widthUnit]).toFixed(
                                Math.round(Math.log(LENGTH_UNITS[widthUnit] * 1000) / Math.log(10))
                            )}
                            onChange={onWidthChange}
                            type="number"
                            fullWidth
                        />
                    </AddUnits>
                </ListItem>
                <ListItem>
                    <AddUnits
                        conversionFactors={LENGTH_UNITS}
                        defaultUnit="m"
                        onChangeUnit={setHeightUnit}
                    >
                        <TextField
                            label="Largo"
                            value={(layoutInfo.size[1] / LENGTH_UNITS[heightUnit]).toFixed(
                                Math.round(Math.log(LENGTH_UNITS[heightUnit] * 1000) / Math.log(10))
                            )}
                            onChange={onHeightChange}
                            type="number"
                            fullWidth
                        />
                    </AddUnits>
                </ListItem>
                <ListItem>
                    <AddUnits
                        conversionFactors={LENGTH_UNITS}
                        defaultUnit="m"
                        onChangeUnit={setDeepUnit}
                    >
                        <TextField
                            label="Profundidad"
                            value={
                                layoutInfo.size[2] /
                                LENGTH_UNITS[deepUnit].toFixed(
                                    Math.round(
                                        Math.log(LENGTH_UNITS[deepUnit] * 1000) / Math.log(10)
                                    )
                                )
                            }
                            onChange={onDeepChange}
                            type="number"
                            fullWidth
                        />
                    </AddUnits>
                </ListItem>
                <ListItem>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Agregar</InputLabel>
                        <Select value={selectedBoxIndex} onChange={onSelectedBoxChange}>
                            {Object.values(boxes).map((b) => {
                                return (
                                    <MenuItem key={b.id} value={b.id}>
                                        {b.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <Button onClick={onAddClick} fullWidth disabled={!selectedBoxIndex}>
                        Añadir
                    </Button>
                </ListItem>
            </List>
            <Divider />
            <DndProvider backend={HTML5Backend}>
                <List style={{ maxHeight: '50%', overflow: 'auto' }}>
                    {[...Array(layoutInfo.boxesCount).keys()].map((boxIndex) => (
                        <DraggableListItem key={boxIndex} layoutId={layoutId} boxIndex={boxIndex} />
                    ))}
                </List>
            </DndProvider>
        </React.Fragment>
    )
}

LayoutDataEdit.propTypes = {
    layoutId: PropTypes.string.isRequired,
}

const Edit = ({ layoutId }) => {
    const dispatch = useDispatch()
    useRef(() => {
        return () => {
            dispatch(setSelectedLayout(null))
            dispatch(setSelectedBox(null))
            dispatch(setActiveBox(null))
        }
    })
    return (
        <Grid container item xs={9} style={{ height: '100%' }}>
            <Grid item xs={9}>
                <ThreeJSScene layoutId={layoutId} zoom={350} />
            </Grid>
            <Grid
                item
                xs={3}
                style={{ height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <LayoutDataEdit layoutId={layoutId} />
            </Grid>
        </Grid>
    )
}

Edit.propTypes = {
    layoutId: PropTypes.string.isRequired,
}

const LayoutSelectInfo = ({ layoutId }) => {
    const selectedLayout = useSelector((state) => state.group.palletizer.layouts.selectedLayout)
    const layoutInfo = useSelector((state) => {
        const layout = state.group.palletizer.layouts.layouts[layoutId]
        return { id: layout.id, size: layout.size, name: layout.name, unsaved: layout.unsaved }
    }, shallowEqual)

    const dispatch = useDispatch()

    const onDeleteClick = () => {
        dispatch(deleteLayout(layoutId))
    }

    return (
        <ListItem button key={layoutInfo.id} selected={selectedLayout === layoutId}>
            <Tooltip
                title={
                    <Typography fontSize={14}>{`Elemento ${
                        layoutInfo.unsaved ? 'sin guardar' : 'guardado'
                    }`}</Typography>
                }
                aria-label="add"
            >
                <ListItemIcon>
                    {layoutInfo.unsaved && <ErrorIcon />}
                    {!layoutInfo.unsaved && <CheckCircleIcon />}
                </ListItemIcon>
            </Tooltip>
            <ListItemText
                primary={layoutInfo.name}
                secondary={`Medidas: ${layoutInfo.size[0].toFixed(
                    3
                )} x ${layoutInfo.size[1].toFixed(3)} x ${layoutInfo.size[2].toFixed(3)} m`}
                onClick={() => {
                    if (selectedLayout === layoutId) {
                        dispatch(setSelectedLayout(null))
                    } else {
                        dispatch(setSelectedLayout(null))
                        dispatch(setSelectedLayout(layoutInfo.id))
                    }
                    dispatch(setSelectedBox(null))
                    dispatch(setActiveBox(null))
                }}
            />
            <ListItemSecondaryAction>
                <Tooltip title={<Typography fontSize={14}>Eliminar</Typography>} aria-label="add">
                    <IconButton>
                        <DeleteIcon style={{ marginTop: 5 }} onClick={onDeleteClick} />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

LayoutSelectInfo.propTypes = {
    layoutId: PropTypes.string.isRequired,
}

const Layouts = () => {
    const { group } = useParams()
    const theme = useTheme()

    const selectedLayout = useSelector((state) => state.group.palletizer.layouts.selectedLayout)
    const layoutsLength = useSelector(
        (state) => Object.keys(state.group.palletizer.layouts.layouts).length
    )
    const layoutsParameterName = useSelector((state) => state.group.palletizer.settings.layouts)

    const { store } = useContext(ReactReduxContext)

    const dispatch = useDispatch()

    const { data: { getParamsByGroup: { params: parametersGQL = [] } = {} } = {} } = useQuery(
        GET_PARAMS_BY_GROUP,
        { variables: { params: { group } }, pollInterval: 10000 }
    )
    const [updateParameterGQL] = useMutation(UPDATE_PARAM)
    const [createParam] = useMutation(CREATE_PARAM)

    const addNewLayoutClicked = () => {
        const layoutId = uuidv4()
        dispatch(
            updateLayout(layoutId, {
                id: layoutId,
                name: `UNTITLED LAYOUT ${layoutsLength}`,
                size: [2, 2, 2],
                layout: [],
                unsaved: true,
            })
        )
    }

    const onSaveClick = async () => {
        const { layouts } = store.getState().group.palletizer.layouts
        // Clean to cobot data format
        const cleaned = {
            allIds: Object.values(layouts).map((lay) => lay.id),
            byId: Object.values(layouts).reduce((prev, next) => {
                return {
                    ...prev,
                    [next.id]: {
                        id: next.id,
                        name: next.name,
                        size: next.size,
                        boxes: next.layout.map((box) => {
                            return {
                                id: box.boxId,
                                position: box.position.slice(0, 2),
                                orientation: box.rotation,
                            }
                        }),
                    },
                }
            }, {}),
        }
        // Check if parameter exists
        const layoutsParameter = parametersGQL.filter(
            (param) => param.name === layoutsParameterName
        )

        if (layoutsParameter.length === 0) {
            await createParam({
                variables: {
                    params: {
                        group,
                        name: layoutsParameterName,
                        type: 'STRING',
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        } else {
            await updateParameterGQL({
                variables: {
                    params: {
                        _id: layoutsParameter[0]._id,
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        }

        Object.values(layouts).forEach((lay) => {
            dispatch(updateLayout(lay.id, { ...lay, unsaved: false }))
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ height: '100%' }}>
                <Grid
                    item
                    container
                    xs={3}
                    style={{ height: '100%' }}
                    direction="column"
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={11}
                        style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            overflow: 'auto',
                        }}
                    >
                        <List>
                            <ListItem>
                                <ListItemText primary="AÑADIR LAYOUT" />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={addNewLayoutClicked}>
                                        <AddIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider style={{ marginTop: 7 }} />
                            {Object.values(store.getState().group.palletizer.layouts.layouts).map(
                                (t) => (
                                    <LayoutSelectInfo key={t.id} layoutId={t.id} />
                                )
                            )}
                        </List>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={onSaveClick}
                            color="primary"
                            style={{
                                width: '50px',
                                marginLeft: 'calc(45% - 25px)',
                                marginTop: '10px',
                            }}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
                {selectedLayout !== null ? <Edit layoutId={selectedLayout} /> : null}
            </Grid>
        </ThemeProvider>
    )
}

export default Layouts
