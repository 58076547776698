import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useTranslation } from 'react-i18next';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"

import DownloadIcon from "@material-ui/icons/CloudDownload"

import Loading from "components/Loading"

import { GET_INVOICES } from "../../queries/invoices"

const formatDate = (d) => {
    const date = new Date(d)
    return date.getDate() + "/" + (date.getMonth() + 1) + "/"  + date.getFullYear()
}

const formatPeriod = (periodStart, periodEnd) => {
    return formatDate(periodStart) + " - " + formatDate(periodEnd)
}

function Invoices(props) {
    const { t } = useTranslation("private");

    const { data: { invoices = [] } = {}, loading} = useQuery(GET_INVOICES) 

    return (
        <React.Fragment>
            <Loading loading={loading}>
                {invoices.length > 0 ? <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("account.common.number")}</TableCell>
                                <TableCell>{t("account.common.created_at")}</TableCell>
                                <TableCell>{t("account.common.period")}</TableCell>
                                <TableCell>{t("account.common.amount")}</TableCell>
                                <TableCell>{t("account.common.status")}</TableCell>
                                <TableCell>{t("account.common.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {invoices.map(inv => (
                            <TableRow key={inv.id}>
                                <TableCell component="th" scope="row">{inv.number}</TableCell>
                                <TableCell component="th" scope="row">{formatDate(parseInt(inv.createdAt) * 1000)}</TableCell>
                                <TableCell component="th" scope="row">{formatPeriod(parseInt(inv.periodStart) * 1000, new Date(parseInt(inv.periodEnd) * 1000))}</TableCell>
                                <TableCell component="th" scope="row">{inv.amountPaid + inv.currency}</TableCell>
                                <TableCell component="th" scope="row">{t("account.invoices.paid")}</TableCell>
                                <TableCell component="th" scope="row"><a href={inv.pdfUrl} target="_blank"><IconButton><DownloadIcon/></IconButton></a></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Paper>
                : <Typography variant="h6" align="center" color="textSecondary">{t("account.invoices.no_invoices_available")}</Typography>}
            </Loading>
        </React.Fragment>
    )
}

export default Invoices