import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { HotKeys } from 'react-hotkeys'
import { range } from 'lodash'
import sr from 'seedrandom'
import { ThemeProvider } from '@mui/material/styles'

import theme from '../../../../../../../../../../theme'
import trainingSetObjectDetectionModule from './redux/module'
import ImagesMenu from './containers/images-menu/index'
import Tools from './containers/tools/tools'
import Pagination from './containers/pagination/index'
import ImageViewer from './containers/image-viewer/index'
import UpdateTrainingSetClassesDialog from './containers/update-training-set-classes-dialog'
import ActionsSpeedDialog from './containers/speed-dial/index'
import AddEntriesDialog from './containers/add-entries-dialog/index'
import { GET_TRAINING_SET_BY_ID } from '../../queries/training-sets'
import Annotations from './containers/annotations/annotations'
import { setClassProp, setToolbox } from './redux/general'
import {
    removeSelectedAnnotation,
    setAnnotationClass,
    selectNextEntry,
    selectPreviousEntry,
    deselect,
    saveSelected,
    saveLast,
    predict,
} from './redux/images'
import Save from './containers/save/index'
import SettingsDialog from './containers/settings-dialog/scene'

const colors = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFFF00',
    '#00FFFF',
    '#FF00FF',
    '#EE8572',
    '#35495E',
    '#347474',
    '#63B7AF',
]

/** Set seeder of the random number generator
 * to always reach the same colors in the trainingSet
 */
const setSeeder = (string) => {
    sr(string, { global: true })
}

/** Get Random color if the classes of the datasets are more
 * than 10
 */
const getColor = (index) => {
    if (index < colors.length) {
        return colors[index]
    }
    let hexColor = '#'

    for (let i = 0; i < 6; i += 1) {
        hexColor = hexColor.concat('', Math.floor(Math.random() * (16 - 0)).toString(16))
    }
    return hexColor
}

/**
 * Parent component of object detection training-set edit app
 */
const Labeler = () => {
    const dispatch = useDispatch()
    const { trainingSet: trainingSetId } = useParams()

    const { data: { getTrainingSetById: { trainingSet = {} } = {} } = {} } = useQuery(
        GET_TRAINING_SET_BY_ID,
        {
            variables: {
                params: {
                    _id: trainingSetId,
                },
            },
        }
    )

    // Setting up hot keys
    const keyMap = {
        DELETE_ANNOTATION: 'del',
        NEXT_SAMPLE: 'd',
        PREVIOUS_SAMPLE: 'a',
        ESCAPE: 'esc',
        SAVE: 'ctrl+s',
        PREDICT: 'p',
        NEXT_SAMPLE_SPACE: 'space',
        PREDICT_T: 't',
    }

    const keyMapHandlers = {}
    if (trainingSet.classes) {
        trainingSet.classes.forEach((classObj, index) => {
            const colorC = getColor(index)
            dispatch(setClassProp({ color: colorC, text: classObj }, index))
        })
        const annotationsClassHotKeys = range(1, trainingSet.classes.length + 1)
        annotationsClassHotKeys.forEach((obj) => {
            keyMap[`ANNOTATION_${obj}`] = obj.toString().split('').join(' ')
            keyMapHandlers[`ANNOTATION_${obj}`] = () => {
                dispatch(setAnnotationClass(obj - 1))
            }
        })
        // Add shortcut to BACKGROUND CLASS
        dispatch(setClassProp({ color: '#000000', text: 'BACKGROUND' }, -1))
        keyMap['ANNOTATION_BACKGROUND'] = '0'
        keyMapHandlers.ANNOTATION_BACKGROUND = () => {
            dispatch(setAnnotationClass(-1))
        }
    }

    keyMapHandlers.DELETE_ANNOTATION = () => {
        dispatch(removeSelectedAnnotation())
        dispatch(setToolbox('samples'))
    }
    keyMapHandlers.NEXT_SAMPLE = () => {
        dispatch(saveLast())
        dispatch(selectNextEntry())
        dispatch(setToolbox('samples'))
    }
    keyMapHandlers.NEXT_SAMPLE_SPACE = (event) => {
        event.preventDefault()
        dispatch(saveLast())
        dispatch(selectNextEntry())
        dispatch(setToolbox('samples'))
    }
    keyMapHandlers.PREVIOUS_SAMPLE = () => {
        dispatch(saveLast())
        dispatch(selectPreviousEntry())
        dispatch(setToolbox('samples'))
    }
    keyMapHandlers.ESCAPE = () => {
        dispatch(deselect())
        dispatch(setToolbox('samples'))
    }
    keyMapHandlers.SAVE = (event) => {
        event.preventDefault()
        dispatch(saveSelected())
    }
    keyMapHandlers.PREDICT = (event) => {
        event.preventDefault()
        dispatch(predict())
    }
    keyMapHandlers.PREDICT_T = (event) => {
        event.preventDefault()
        dispatch(predict())
    }

    useEffect(() => {
        setSeeder(trainingSetId)
    }, [trainingSetId])

    return (
        <DynamicModuleLoader modules={[trainingSetObjectDetectionModule]}>
            <ThemeProvider theme={theme}>
                <HotKeys keyMap={keyMap} handlers={keyMapHandlers}>
                    <Grid container style={{ height: '100%' }}>
                        <Grid item xs={2}>
                            <Paper style={{ height: '100%' }}>
                                <Annotations />
                            </Paper>
                        </Grid>
                        <Grid item xs={8}>
                            <Tools style={{ position: 'absolute' }} />
                            <ImageViewer style={{ position: 'absolute' }} />
                        </Grid>
                        <Grid item xs={2}>
                            <Paper style={{ height: '100%' }}>
                                <ImagesMenu />
                            </Paper>
                        </Grid>
                        <Paper
                            style={{
                                padding: 5,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                position: 'fixed',
                                bottom: 0,
                                width: 'calc(100% - 55px)',
                            }}
                        >
                            <Pagination />
                        </Paper>
                        <UpdateTrainingSetClassesDialog />
                        <AddEntriesDialog />
                        <Save />
                        <ActionsSpeedDialog />
                        <SettingsDialog />
                    </Grid>
                </HotKeys>
            </ThemeProvider>
        </DynamicModuleLoader>
    )
}

export default Labeler
