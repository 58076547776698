import React, { useState } from 'react'

import { useSelector, useDispatch } from "react-redux"
import { useMutation } from "@apollo/react-hooks"

import Validator from "validator"

import Grid from "@material-ui/core/Grid"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import LoadingButton from "components/loading-button"

import { closeNewProjectDialog } from "../actions/general"

import { CREATE_PROJECT, GET_ALL_PROJECTS } from "../queries/projects"

function NewProjectDialog(props) {
    const dispatch = useDispatch()
    
    const [ loading, setLoading ] = useState(false)

    const [ name, setName ] = useState("")
    const [ lat, setLat ] = useState(0)
    const [ lng, setLng ] = useState(0)
    const [ category, setCategory] = useState(null)
    const [ description, setDescription ] = useState("")

    const [ nameError, setNameError ] = useState("")
    const [ latError, setLatError ] = useState("")
    const [ lngError, setLngError ] = useState("")
    const [ categoryError, setCategoryError ] = useState(false)
    
    const { new_project_dialog_open } = useSelector(state => state.inspector.selector)

    const [ createProject ] = useMutation(CREATE_PROJECT)

    const onNameChange = e => setName(e.target.value)
    const onLatChange = e => setLat(e.target.value)
    const onLngChange = e => setLng(e.target.value)
    const onCategoryChange = e => setCategory(e.target.value)
    const onDescriptionChange = e => setDescription(e.target.value)
    const onAccept = async () => {
        if(!name.length) {
            setNameError("Introduzca un nombre válido")
            return
        } else {
            setNameError("")
        }

        if(!lat) {
            setLatError("Introduzca una latitud válida")
            return
        } else {
            setLatError("")
        }

        if(!lng) {
            setLngError("Introduzca una longitud válida")
            return
        } else {
            setLngError("")
        }

        if(!category) {
            setCategoryError(true)
            return
        } else {
            setCategoryError(false)
        }

        setLoading(true)
        await createProject({
            variables: {
                params: {
                    name,
                    position: { type: "Point", coordinates: [ lng, lat ]},
                    category,
                    description
                }
            },
            refetchQueries: [
                { query: GET_ALL_PROJECTS, variables: { params: {}} },
            ]
        })
        setLoading(false)
        onClose()
    }
    const onClose = () => dispatch(closeNewProjectDialog())

    return (
        <Dialog open={new_project_dialog_open}>
            <DialogTitle>{"Nuevo Proyecto"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField 
                            value={name}
                            disabled={loading}
                            label="Nombre"
                            placeholder="Introduzca el nombre del proyecto" 
                            fullWidth 
                            error={nameError.length}
                            helperText={nameError}
                            onChange={onNameChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            value={lat}
                            disabled={loading}
                            label="Latitud"
                            placeholder="Introduzca la latitude de la instalación" 
                            fullWidth 
                            error={latError.length}
                            helperText={latError}
                            onChange={onLatChange}
                            type="number"
                            step="any"/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            value={lng}
                            disabled={loading}
                            label="Longitud"
                            placeholder="Introduzca la longitud de la instalación" 
                            fullWidth 
                            error={lngError.length}
                            helperText={lngError}
                            onChange={onLngChange}
                            type="number"
                            step="any"/>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl disabled={loading} fullWidth margin="normal" error={categoryError}>
                            <InputLabel>Categoría</InputLabel>
                            <Select onChange={onCategoryChange} value={category}>
                                <MenuItem value={"universal"}>Universal</MenuItem>
                                <MenuItem value={"fotovoltaic/panels"}>Fotovoltaica > Paneles</MenuItem>
                            </Select>
                            <FormHelperText>Categoría de la instalación</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField 
                            disabled={loading}
                            label="Descripción" 
                            multiline 
                            rows="2" 
                            rowsMax="6" 
                            fullWidth 
                            onChange={onDescriptionChange}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={onClose}>
                    Cancelar
                </Button>
                <LoadingButton loading={loading} onClick={onAccept}>
                    Aceptar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default NewProjectDialog; 