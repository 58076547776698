import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'
import MaterialCard from '@material-ui/core/Card'

const Card = ({ width, height, margin, padding, background, elevation, children }) => {
    const {
        connectors: { connect, drag },
    } = useNode((node) => {
        return {
            data: node.data,
        }
    })

    const style = {
        width,
        height,
        margin,
        padding,
        background,
    }

    return (
        <MaterialCard ref={(ref) => connect(drag(ref))} style={style} elevation={elevation}>
            {children}
        </MaterialCard>
    )
}

Card.craft = {
    displayName: 'Tarjeta',
    custom: {
        component: true,
    },
    props: {
        width: '100%',
        height: '100%',
        padding: '5px 5px 5px 5px',
        margin: '0px 0px 0px 0px',
        background: '#FFF',
        elevation: 2,
    },
}

Card.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    background: PropTypes.string,
    elevation: PropTypes.number,
    children: PropTypes.array,
}
Card.defaultProps = {
    width: '100%',
    height: '100%',
    padding: '5px 5px 5px 5px',
    margin: '0px 0px 0px 0px',
    background: '#FFF',
    elevation: 2,
    children: [],
}

export default Card
