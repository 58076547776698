import React, { useState, useRef } from 'react'

import { Zoom } from '@vx/zoom'
import { useSelector, useDispatch } from 'react-redux'

import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'

import ZoomInIcon from '@material-ui/icons/ZoomIn'
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction'

import IconButtonStatus from 'components/IconButtonStatus'

import { closeImageViewer } from '../../reducers/image-viewer'
import { INFER_IMAGE } from '../../sagas/predict'

const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: window.innerWidth * 0.25,
    translateY: 0,
    skewX: 0,
    skewY: 0,
}

const ImageViewer = (props) => {
    const dispatch = useDispatch()

    const { open, url, annotations, inferenceRunning } = useSelector(
        (state) => state.datasets.image_viewer
    )
    const { inferenceFontSize, inferenceEnabled } = useSelector((state) => state.datasets.settings)

    const onClose = () => {
        dispatch(closeImageViewer())
    }

    const onPredict = () => {
        dispatch({
            type: INFER_IMAGE,
            src: url,
        })
    }

    const renderInfereceButton = () => {
        if (!inferenceEnabled) {
            return null
        }

        return (
            <ButtonGroup
                orientation="vertical"
                color="primary"
                aria-label="vertical outlined primary button group"
            >
                <IconButtonStatus
                    loading={inferenceRunning}
                    error={false}
                    iconButtonProps={{
                        'aria-label': 'predict',
                        disabled: false,
                        onClick: onPredict,
                    }}
                    icon={<OnlinePredictionIcon fontSize="large" />}
                />
            </ButtonGroup>
        )
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Fade in={open}>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Zoom
                        width={window.innerWidth}
                        height={window.innerHeight}
                        scaleXMin={0.1}
                        scaleXMax={10}
                        scaleYMin={0.1}
                        scaleYMax={10}
                        transformMatrix={initialTransform}
                    >
                        {(zoom) => {
                            return (
                                <svg width={window.innerWidth} height={window.innerHeight}>
                                    <rect
                                        width={window.innerWidth}
                                        height={window.innerHeight}
                                        rx={14}
                                        fill="transparent"
                                        onClick={onClose}
                                    />
                                    <g
                                        transform={zoom.toString()}
                                        onTouchStart={zoom.dragStart}
                                        onTouchMove={(event) => {
                                            zoom.dragMove(event)
                                        }}
                                        onTouchEnd={zoom.dragEnd}
                                        onMouseDown={zoom.dragStart}
                                        onMouseMove={(event) => {
                                            zoom.dragMove(event)
                                        }}
                                        onMouseUp={(event) => {
                                            zoom.dragEnd(event)
                                        }}
                                        onMouseLeave={() => {
                                            if (zoom.isDragging) zoom.dragEnd()
                                        }}
                                    >
                                        <image xlinkHref={url} />
                                        {annotations.map((annotation, index) => {
                                            return (
                                                <g>
                                                    <rect
                                                        x={annotation.x}
                                                        y={annotation.y}
                                                        width={annotation.width}
                                                        height={annotation.height}
                                                        fill="transparent"
                                                        stroke="red"
                                                        strokeWidth="2"
                                                    />
                                                    <text
                                                        x={annotation.x}
                                                        y={annotation.y}
                                                        fill="red"
                                                        fontSize={inferenceFontSize}
                                                    >
                                                        {annotation.classLabel}
                                                    </text>
                                                </g>
                                            )
                                        })}
                                    </g>
                                </svg>
                            )
                        }}
                    </Zoom>
                    <Paper
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '0%',
                            transform: 'translate(0%, -50%)',
                        }}
                    >
                        {renderInfereceButton()}
                    </Paper>
                </div>
            </Fade>
        </Modal>
    )
}

export default ImageViewer
