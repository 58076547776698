import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ChipInput from 'material-ui-chip-input'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

const StepMonitorActions = (props) => {
    const classes = useStyles()

    const [actions, setActions] = useState([])

    const onStepMonitorActionsAccept = () => {
        if (!actions.length) {
            return
        }

        props.onPropertyChange(props.property, actions)
        props.setActiveStep(props.nextStep)
    }

    const onStepMonitorActionsBack = () => {
        props.setActiveStep(props.prevStep)
    }

    const options = [{ value: 'alert', label: 'Registrar Incidencia' }]

    const onChipsChange = (chips) => {
        setActions(chips)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ChipInput defaultValue={actions} variant="outlined" fullWidth onChange={onChipsChange}/>
            </Grid>
            <Grid container item xs={12} className={classes.actions}>
                <Grid item xs={2}>
                    <Button onClick={onStepMonitorActionsBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorActionsAccept}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorActions
