import {
    OPEN_NEW_PROJECT_DIALOG,
    CLOSE_NEW_PROJECT_DIALOG
} from "../actions/general"

export default (state = { new_project_dialog_open: false }, action) => {
    switch(action.type) {
        case OPEN_NEW_PROJECT_DIALOG:
            return {...state, new_project_dialog_open: true}
        case CLOSE_NEW_PROJECT_DIALOG:
            return {...state, new_project_dialog_open: false}
        default:
            return state
    }
}