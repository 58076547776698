import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

const StepMonitorType = (props) => {
    const classes = useStyles()

    const [monitorType, setMonitorType] = useState(null)
    const [disabled, setDisabled] = useState(true)

    const options = [{ value: 'local', label: 'Local' }]

    const onStepTypeAccept = () => {
        if (!monitorType) {
            return
        }

        props.setActiveStep(props.nextStep)
        props.onPropertyChange(props.property, monitorType)
    }

    const onMonitorTypeChange = (event, {value}) => {
        if (value) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

        setMonitorType(value)
    }

    const onStepTypeBack = () => {
        props.setActiveStep(props.prevStep)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Autocomplete
                    onChange={onMonitorTypeChange}
                    options={options}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            label="Tipo de monitor"
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.actions}>
                    <Button onClick={onStepTypeBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepTypeAccept}
                        disabled={disabled}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorType
