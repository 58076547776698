const getPropertyBuilder = (props) => (name) => {
    let value = ''

    try {
        const parsed = JSON.parse(props[name])
        switch (parsed.type) {
            case 'fixed':
                value = parsed.value
                break
            case 'parameter':
                value = 'param'
                break
            case 'variable':
                value = 'var'
                break
            default:
                value = ''
                break
        }
    } catch (err) {
        value = props.value
    }

    return value
}

export default getPropertyBuilder
