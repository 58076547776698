import gql from "graphql-tag"

export const GET_PAYMENT_METHODS = gql`
    query {
        paymentMethods {
            id
            default
            billingDetails {
                name
                address {
                    line1
                    line2
                    postal_code
                    city
                    country
                }
            }
            card {
                brand
                exp_month
                exp_year
                last4
            }
        }
    }
`

export const CREATE_PAYMENT_METHOD = gql`
    mutation CreatePaymentMethod($params: CreatePaymentMethodInput!) {
        createPaymentMethod(params: $params) {
            state
        }
    }
`


export const SET_DEFAULT_PAYMENT_METHOD = gql`
    mutation SetDefaultPaymentMethod($params: SetDefaultPaymentMethodInput!) {
        setDefaultPaymentMethod(params: $params) {
            state
        }
    }

`