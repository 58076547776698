import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'

/**
 * Clone recursively all the downstream children of a react component.
 *
 * @param {React.Component} obj
 * @returns {React.Component} Clone child components
 */
const deepChildrenRender = (obj) => {
    const clonedChildren = Children.map(obj.props.children || [], (child) => {
        if (React.isValidElement(child)) {
            return deepChildrenRender(child)
        }
        return child
    })
    return React.cloneElement(obj, { ...obj.props, children: clonedChildren })
}

const AddUnits = ({ conversionFactors, defaultUnit, onChangeUnit, children }) => {
    const [units, setUnits] = useState(defaultUnit)

    const onChange = (event) => {
        setUnits(event.target.value)
        onChangeUnit(event.target.value)
    }

    return (
        <Grid container justifyContent="flex-end" display="flex" spacing={0}>
            <Grid
                item
                container
                direction="column"
                justifyContent="flex-end"
                xs={9}
                xl={9}
                sm={9}
                md={9}
                lg={9}
            >
                {Children.map(children, (obj) => deepChildrenRender(obj))}
            </Grid>
            <Grid
                item
                container
                direction="column"
                justifyContent="flex-end"
                xs={3}
                xl={3}
                sm={3}
                md={3}
                lg={3}
            >
                <Select variant="standard" id="units" value={units} onChange={onChange}>
                    {Object.keys(conversionFactors).map((unit) => {
                        return (
                            <MenuItem key={unit} value={unit}>
                                {unit}
                            </MenuItem>
                        )
                    })}
                </Select>
            </Grid>
        </Grid>
    )
}

AddUnits.propTypes = {
    conversionFactors: PropTypes.object.isRequired,
    defaultUnit: PropTypes.string.isRequired,
    onChangeUnit: PropTypes.func.isRequired,
    children: PropTypes.array.isRequired,
}

export default AddUnits
