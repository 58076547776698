import React from "react"

import Grid from "@material-ui/core/Grid"

class LeftSide extends React.Component {
    render() {
        return (
            <Grid container style={{ minHeight: "100vh" }}>
                {this.props.children}
            </Grid>
        )
    }
}

export default LeftSide