import { call, put, spawn, select, takeLatest } from 'redux-saga/effects'

import { SET_ANNOTATION_CLASS_INDEX, APPEND_INPLACE_ANNOTATION } from '../images'

function* match(payload) {
    const { templateMatching } = yield select((state) => state.trainingSetObjectDetection.settings)
    console.log('templateMatching', templateMatching)
    if (templateMatching.enabled === false) return
    if (payload.classIndex === null) return
    console.log('match')

    const { url: inputImageUrl = '' } = yield select(
        (state) =>
            state.trainingSetObjectDetection.images.entries[
                state.trainingSetObjectDetection.images.selectedEntry
            ]
    )
    console.log('inputImageUrl', inputImageUrl)

    const templates = templateMatching.templates.filter((t) =>
        t.classes.includes(payload.classIndex)
    )
    console.log('templates', templates)

    if (templates.length === 0) return
    console.log('templates.length', templates.length)
    const inputImage = new Image()
    inputImage.src = inputImageUrl
    inputImage.crossOrigin = 'Anonymous'
    yield new Promise((r) => {
        inputImage.onload = r
    })
    const input = cv.imread(inputImage)
    console.log('read', input)

    const annotations = yield call(() =>
        Promise.all(
            templates.map(async function (t) {
                const templateImage = new Image()
                templateImage.src = t.image
                templateImage.crossOrigin = 'Anonymous'
                await new Promise((r) => {
                    templateImage.onload = r
                })
                let template = cv.imread(templateImage)
                const crop = new cv.Rect(t.crop.x, t.crop.y, t.crop.width, t.crop.height)
                template = template.roi(crop)

                const scale = 10

                console.log('template read')
                const dst_input = new cv.Mat()
                cv.resize(
                    input,
                    dst_input,
                    new cv.Size(Math.trunc(input.cols / scale), Math.trunc(input.rows / scale)),
                    0,
                    0,
                    cv.INTER_NEAREST
                )
                const dst_template = new cv.Mat()
                cv.resize(
                    template,
                    dst_template,
                    new cv.Size(
                        Math.trunc(template.cols / scale),
                        Math.trunc(template.rows / scale)
                    ),
                    0,
                    0,
                    cv.INTER_NEAREST
                )

                let mask = new cv.Mat()
                let dst = new cv.Mat()
                cv.matchTemplate(dst_input, dst_template, dst, cv.TM_CCOEFF, mask)
                let result = cv.minMaxLoc(dst, mask)
                console.log('result', result)
                let maxPoint = result.maxLoc
                console.log('result done', maxPoint, dst_template.cols, dst_template.rows)

                return {
                    class: payload.classIndex,
                    coords: [
                        maxPoint.x * scale,
                        maxPoint.y * scale,
                        (maxPoint.x + dst_template.cols) * scale,
                        (maxPoint.y + dst_template.rows) * scale,
                    ],
                }
            })
        )
    )

    for (let i = 0; i < annotations.length; i++) {
        const ann = annotations[i]
        console.log('ann', ann)
        yield put({
            type: APPEND_INPLACE_ANNOTATION,
            annotation: ann,
        })
    }
}

function* main() {
    yield takeLatest(SET_ANNOTATION_CLASS_INDEX, match)
}

export default main
