import gql from "graphql-tag"

export const REPORT = gql`
    query {
        report @client {
            summary
        }
    }
`

export const SET_SUMMARY = gql`
    mutation SetSummary($value: String!) {
        setSummary(value: $value) @client
    }
`