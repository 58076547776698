import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@mui/material/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemIcon from '@mui/material/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import ListSubheader from '@material-ui/core/ListSubheader'
import Button from '@mui/material/Button'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import Typography from '@mui/material/Typography'
import AddIcon from '@material-ui/icons/Add'
import ErrorIcon from '@material-ui/icons/Error'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, GizmoHelper, GizmoViewport } from '@react-three/drei'
import { v4 as uuidv4 } from 'uuid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import * as THREE from 'three'

import { setSelectedBox, updateBox, createBox, deleteBox } from '../../reducers/boxes'
import ThreeBox from '../../components/box'
import { GET_PARAMS_BY_GROUP, CREATE_PARAM, UPDATE_PARAM } from '../../../../params/queries/params'

const ThreeJsScene = ({ boxRefConfig, setLabelSide }) => {
    const getIntersected = (event) => {
        // Calculate label side
        let calcLabelSide = 0
        Object.values(event.intersections[0].normal).forEach((normalVal, dim) => {
            calcLabelSide += Math.abs(normalVal) * (2 * dim - (normalVal - 1) / 2)
        })

        setLabelSide(calcLabelSide)
    }

    return (
        /* eslint-disable react/no-unknown-property */
        <Canvas camera={{ fov: 45, position: [1, 0, 0], up: [0, 0, 1] }}>
            <ambientLight intensity={0.5} />
            <OrbitControls
                makeDefault
                maxZoom={10}
                rotation={new THREE.Vector3(Math.PI / 2, 0, 0)}
            />
            <ThreeBox
                box={{ size: boxRefConfig.measurements, labelSide: boxRefConfig.labelSide }}
                meshProps={{ onClick: getIntersected }}
            />
            <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                <GizmoViewport opacity={1} />
            </GizmoHelper>
        </Canvas>
    )
}

ThreeJsScene.propTypes = {
    setLabelSide: PropTypes.func.isRequired,
    boxRefConfig: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        measurements: PropTypes.array.isRequired,
        weight: PropTypes.number.isRequired,
        unsaved: PropTypes.bool.isRequired,
        labelSide: PropTypes.bool.isRequired,
    }).isRequired,
}

const LENGTH_UNITS = {
    mm: 1 / 1000,
    cm: 1 / 100,
    dm: 1 / 10,
    m: 1,
}

const Edit = ({ boxId }) => {
    const selectedBox = useSelector((state) =>
        Object.keys(state.group.palletizer.boxes.boxes).includes(boxId)
            ? state.group.palletizer.boxes.boxes[boxId]
            : {}
    )

    const [pickInScreen, setPickInScreen] = useState(false)
    const [widthUnits, setWidthUnits] = useState('cm')
    const [heightUnits, setHeightUnits] = useState('cm')
    const [deepUnits, setDeepUnits] = useState('cm')

    const dispatch = useDispatch()

    const onNameChange = (e) => {
        dispatch(updateBox(boxId, { name: e.target.value }))
    }
    const onWidthChange = (e) => {
        dispatch(
            updateBox(boxId, {
                measurements: [
                    parseFloat(e.target.value) * LENGTH_UNITS[widthUnits],
                    selectedBox.measurements[1],
                    selectedBox.measurements[2],
                ],
            })
        )
    }
    const onWidthUnitChange = (e) => {
        setWidthUnits(e.target.value)
    }
    const onHeightChange = (e) => {
        dispatch(
            updateBox(boxId, {
                measurements: [
                    selectedBox.measurements[0],
                    parseFloat(e.target.value) * LENGTH_UNITS[heightUnits],
                    selectedBox.measurements[2],
                ],
            })
        )
    }
    const onHeightUnitChange = (e) => {
        setHeightUnits(e.target.value)
    }
    const onDepthChange = (e) => {
        dispatch(
            updateBox(boxId, {
                measurements: [
                    selectedBox.measurements[0],
                    selectedBox.measurements[1],
                    parseFloat(e.target.value) * LENGTH_UNITS[deepUnits],
                ],
            })
        )
    }
    const onDeepUnitChange = (e) => {
        setDeepUnits(e.target.value)
    }
    const onWeightChange = (e) => {
        dispatch(
            updateBox(boxId, {
                weight: parseFloat(e.target.value),
            })
        )
    }
    const onLabelSideChange = (e) => {
        dispatch(updateBox(boxId, { labelSide: parseInt(e.target.value, 10) }))
    }

    return (
        <Grid item container xs={9} style={{ height: '100%' }}>
            <Grid item xs={10} style={{ height: '100%' }}>
                <ThreeJsScene
                    layoutId={null}
                    boxRefConfig={selectedBox}
                    setLabelSide={(labelSideUI) => {
                        if (!pickInScreen) {
                            return null
                        }
                        dispatch(updateBox(boxId, { labelSide: labelSideUI }))
                        return null
                    }}
                    measurements={selectedBox.measurements}
                />
            </Grid>
            <Grid
                item
                xs={2}
                style={{ height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <List>
                    <ListSubheader>Propiedades</ListSubheader>
                    <ListItem>
                        <TextField
                            label="Nombre"
                            value={selectedBox.name}
                            onChange={onNameChange}
                            fullWidth
                        />
                    </ListItem>
                    <ListItem>
                        <Grid container justifyContent="flex-end" display="flex" spacing={0}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-end"
                                xs={9}
                                xl={9}
                                sm={9}
                                md={9}
                                lg={9}
                            >
                                <TextField
                                    label="Width"
                                    type="number"
                                    value={(
                                        selectedBox.measurements[0] / LENGTH_UNITS[widthUnits]
                                    ).toFixed(
                                        Math.round(
                                            Math.log(LENGTH_UNITS[widthUnits] * 1000) / Math.log(10)
                                        )
                                    )}
                                    onChange={onWidthChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-end"
                                xs={3}
                                xl={3}
                                sm={3}
                                md={3}
                                lg={3}
                            >
                                <Select
                                    variant="standard"
                                    id="units-width"
                                    value={widthUnits}
                                    onChange={onWidthUnitChange}
                                >
                                    {Object.keys(LENGTH_UNITS).map((unit) => {
                                        return (
                                            <MenuItem key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container justifyContent="flex-end" display="flex" spacing={0}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-end"
                                xs={9}
                                xl={9}
                                sm={9}
                                md={9}
                                lg={9}
                            >
                                <TextField
                                    label="Height"
                                    type="number"
                                    value={(
                                        selectedBox.measurements[1] / LENGTH_UNITS[heightUnits]
                                    ).toFixed(
                                        Math.round(
                                            Math.log(LENGTH_UNITS[heightUnits] * 1000) /
                                                Math.log(10)
                                        )
                                    )}
                                    onChange={onHeightChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-end"
                                xs={3}
                                xl={3}
                                sm={3}
                                md={3}
                                lg={3}
                            >
                                <Select
                                    variant="standard"
                                    id="units-width"
                                    value={heightUnits}
                                    onChange={onHeightUnitChange}
                                >
                                    {Object.keys(LENGTH_UNITS).map((unit) => {
                                        return (
                                            <MenuItem key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <Grid container justifyContent="flex-end" display="flex" spacing={0}>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-end"
                                xs={9}
                                xl={9}
                                sm={9}
                                md={9}
                                lg={9}
                            >
                                <TextField
                                    label="Deep"
                                    type="number"
                                    value={(
                                        selectedBox.measurements[2] / LENGTH_UNITS[deepUnits]
                                    ).toFixed(
                                        Math.round(
                                            Math.log(LENGTH_UNITS[deepUnits] * 1000) / Math.log(10)
                                        )
                                    )}
                                    onChange={onDepthChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                direction="column"
                                justifyContent="flex-end"
                                xs={3}
                                xl={3}
                                sm={3}
                                md={3}
                                lg={3}
                            >
                                <Select
                                    variant="standard"
                                    id="units-width"
                                    value={deepUnits}
                                    onChange={onDeepUnitChange}
                                >
                                    {Object.keys(LENGTH_UNITS).map((unit) => {
                                        return (
                                            <MenuItem key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <ListItem>
                        <TextField
                            label="Peso"
                            type="number"
                            value={selectedBox.weight}
                            onChange={onWeightChange}
                            fullWidth
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                            label="Cara"
                            type="number"
                            InputLabelProps={{ shrink: selectedBox.labelSide !== null }}
                            inputProps={{ min: 0, max: 5 }}
                            value={selectedBox.labelSide}
                            onChange={onLabelSideChange}
                            fullWidth
                        />
                        <Tooltip
                            title={<Typography fontSize={14}>Seleccionar en pantalla</Typography>}
                        >
                            <Button
                                variant={pickInScreen ? 'contained' : 'outlined'}
                                onClick={() => setPickInScreen(!pickInScreen)}
                                style={{
                                    maxWidth: '30px',
                                    maxHeight: '30px',
                                    minWidth: '5px',
                                    minHeight: '5px',
                                }}
                            >
                                <TouchAppIcon />
                            </Button>
                        </Tooltip>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}

Edit.propTypes = {
    boxId: PropTypes.string.isRequired,
}

const Boxes = () => {
    const { group } = useParams()
    const theme = useTheme()

    const selectedBox = useSelector((state) => state.group.palletizer.boxes.selectedBox)
    const boxes = useSelector((state) => state.group.palletizer.boxes.boxes)
    const boxesParameterName = useSelector((state) => state.group.palletizer.settings.boxes)

    const dispatch = useDispatch()

    const addNewBoxClicked = () => {
        const newIndexBox = uuidv4()
        dispatch(createBox(newIndexBox))
    }
    const { data: { getParamsByGroup: { params: parametersGQL = [] } = {} } = {} } = useQuery(
        GET_PARAMS_BY_GROUP,
        { variables: { params: { group } }, pollInterval: 10000 }
    )
    const [updateParameterGQL] = useMutation(UPDATE_PARAM)
    const [createParam] = useMutation(CREATE_PARAM)

    // Click on accept change
    const onSaveClick = async () => {
        // Clean to cobot data
        const cleaned = {
            allIds: Object.values(boxes).map((box) => box.id),
            byId: Object.values(boxes).reduce((prev, next) => {
                return {
                    ...prev,
                    [next.id]: {
                        size: next.measurements,
                        weight: next.weight,
                        name: next.name,
                        labelSide: next.labelSide,
                    },
                }
            }, {}),
        }

        // Check if parameter exists
        const boxesParameter = parametersGQL.filter((param) => param.name === boxesParameterName)

        if (boxesParameter.length === 0) {
            await createParam({
                variables: {
                    params: {
                        group,
                        name: boxesParameterName,
                        type: 'STRING',
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        } else {
            await updateParameterGQL({
                variables: {
                    params: {
                        _id: boxesParameter[0]._id,
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        }
    }

    const onDeleteClick = (boxId) => () => {
        dispatch(deleteBox(boxId))
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ height: '100%' }}>
                <Grid
                    item
                    container
                    xs={3}
                    style={{ height: '100%' }}
                    direction="column"
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={11}
                        style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            overflow: 'auto',
                        }}
                    >
                        <List>
                            <ListItem>
                                <ListItemText primary="AÑADIR CAJA" />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={addNewBoxClicked}>
                                        <AddIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider style={{ marginTop: 7 }} />
                            {Object.keys(boxes).map((boxId) => (
                                <ListItem
                                    button
                                    key={boxId}
                                    onClick={() => {
                                        dispatch(setSelectedBox(boxId))
                                    }}
                                    selected={boxId === selectedBox}
                                >
                                    <Tooltip
                                        title={
                                            <Typography fontSize={14}>{`Elemento ${
                                                boxes[boxId].unsaved ? 'sin guardar' : 'guardado'
                                            }`}</Typography>
                                        }
                                        aria-label="add"
                                    >
                                        <ListItemIcon>
                                            {boxes[boxId].unsaved && <ErrorIcon />}
                                            {!boxes[boxId].unsaved && <CheckCircleIcon />}
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText
                                        primary={boxes[boxId].name}
                                        secondary={`Medidas: ${(
                                            boxes[boxId].measurements[0] * 1000
                                        ).toFixed(0)} x ${(
                                            boxes[boxId].measurements[1] * 1000
                                        ).toFixed(0)} x ${(
                                            boxes[boxId].measurements[2] * 1000
                                        ).toFixed(0)} mm | Peso: ${boxes[boxId].weight} kg`}
                                        style={{ 'margin-left': '-25px' }}
                                    />
                                    <ListItemSecondaryAction>
                                        <Tooltip
                                            title={<Typography fontSize={14}>Eliminar</Typography>}
                                            aria-label="add"
                                        >
                                            <IconButton>
                                                <DeleteIcon
                                                    style={{ marginTop: 5 }}
                                                    onClick={onDeleteClick(boxId)}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSaveClick}
                            style={{
                                width: '50px',
                                marginLeft: 'calc(45% - 25px)',
                                marginTop: '10px',
                            }}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
                {selectedBox && <Edit boxId={selectedBox} />}
            </Grid>
        </ThemeProvider>
    )
}

export default Boxes
