import React from "react"

import { useDispatch, useSelector } from "react-redux"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import SelectFiles from "./containers/select-files"
import UploadFields from "./containers/upload-files"

import { closeUploadDialog } from "../../../../reducers/general"

const steps = [
    { label: "Seleccionar los archivos", component: <SelectFiles/>},
    { label: "Subir archivos", component: <UploadFields/>},
]

export default function(props) {

    const dispatch = useDispatch()

    const closeDialog = () => dispatch(closeUploadDialog())
    
    const { step } = useSelector(state => state.inspector.solar.upload_dialog)

    return (
        <Dialog open={props.open} onClose={closeDialog}>
            <Stepper activeStep={step}>
                {steps.map(s => (
                    <Step>
                        <StepLabel>{s.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <DialogContent>
                {steps[step].component}
            </DialogContent>
        </Dialog>
    )
}