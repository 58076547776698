import React from 'react'
import { PropTypes } from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { DynamicModuleLoader } from 'redux-dynamic-modules'

import ListView from './containers/listview/scene'
import Viewer from './containers/viewer/scene'

import getDatasetsModule from './module'

import { useIsAllowed } from 'queries/permissions'
import { OpenCvProvider } from 'opencv-react'

const DatasetsV2 = ({ match }) => {
    const { isAllowed: isGetDatasetsAllowed } = useIsAllowed({
        action: 'getDatasetsV2ByParent',
        group: 'datasets',
    })

    return (
        <DynamicModuleLoader modules={[getDatasetsModule()]}>
            <OpenCvProvider>
                <Switch>
                    {isGetDatasetsAllowed && (
                        <Route path={`${match.url}/:dataset/:page?`} component={Viewer} />
                    )}
                    {isGetDatasetsAllowed && (
                        <Route exact path={`${match.url}/`} component={ListView} />
                    )}
                </Switch>
            </OpenCvProvider>
        </DynamicModuleLoader>
    )
}

DatasetsV2.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }).isRequired,
}

export default DatasetsV2
