import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { withTheme } from '@mui/styles'
import { Fab } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { cross, norm, divide } from 'mathjs'
import { polygonInPolygon } from 'geometric'

import { UPDATE_ENTRY_BY_ID } from '../../../../queries/training-sets'
import { saveInPlace } from '../../redux/images'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'fixed',
            bottom: theme.spacing(9),
            right: theme.spacing(13),
        },
        '@keyframes blinker': {
            from: { opacity: 1 },
            to: { opacity: 0 },
        },
        blinkingIcon: {
            animationName: '$blinker',
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
            animationDirection: 'alternate',
        },
    }
})

/**
 * Automatic save
 *
 */
const Save = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const savingTime = useSelector((state) => state.trainingSetSegmentation.images.saveInPlace)
    const { trainingSet: trainingSetId } = useParams()
    const [updating, setUpdating] = useState(false)
    const [finishing, setFinished] = useState(false)
    const [error, setError] = useState(false)

    const inPlaceAnnotations = useSelector(
        (state) => state.trainingSetSegmentation.images.inPlaceAnnotations || []
    )

    const [updateAnnotations] = useMutation(UPDATE_ENTRY_BY_ID)
    const timer = useRef(null)
    const saveAnnotations = async (entryId, annotation) => {
        // Checking annotations
        const fixAnnotation = annotation.map((obj) => {
            // BUG: Could be an empty annotation?
            if (obj.polygon.length === 0) {
                return obj
            }

            // To save calculation along renders
            // the holes must be kept in the opposite direction
            // to the outer polygon so that the holes are rendered.
            // Outer-polygon clockwise?
            const outerPolygon = obj.polygon[0]
            const v01 = [
                outerPolygon[0][0] - outerPolygon[1][0],
                outerPolygon[0][1] - outerPolygon[1][1],
                0,
            ]
            const v02 = [
                outerPolygon[0][0] - outerPolygon[2][0],
                outerPolygon[0][1] - outerPolygon[2][1],
                0,
            ]
            const outerPolygonClockwise =
                cross(divide(v01, norm(v01)), divide(v02, norm(v02)))[2] > 0

            const holes = obj.polygon.slice(1).map((hole) => {
                // Checking if hole is contained by outer polygon
                if (!polygonInPolygon(hole, outerPolygon)) {
                    throw new Error('Hole not contained by outer polygon')
                }
                // Hole clockwise?
                const holeV01 = [hole[0][0] - hole[1][0], hole[0][1] - hole[1][1], 0]
                const holeV02 = [hole[0][0] - hole[2][0], hole[0][1] - hole[2][1], 0]
                const holeClockwise =
                    cross(divide(holeV01, norm(holeV01)), divide(holeV02, norm(holeV02)))[2] > 0
                if (holeClockwise === outerPolygonClockwise) {
                    hole.reverse()
                    return hole
                }
                return hole
            })

            return {
                ...obj,
                polygon: [outerPolygon, ...holes],
            }
        })
        const { data, error: errorMut = false } = await updateAnnotations({
            variables: {
                params: {
                    trainingSet: trainingSetId,
                    _id: entryId,
                    inputs: [{ name: 'annotations', value: JSON.stringify(fixAnnotation) }],
                },
            },
        })
        if (data.updateTrainingSetEntryById.status === null || errorMut) {
            throw new Error('Could not upload the annotations')
        }
    }

    const showUpdating = !error && updating && !finishing
    const showFinishedButton = !error && finishing && !updating
    const showErrorButton = error && finishing && !updating

    useEffect(() => {
        if (savingTime) {
            setUpdating(true)
            const updatePromises = []
            Object.keys(inPlaceAnnotations).forEach((obj) => {
                updatePromises.push(saveAnnotations(obj, inPlaceAnnotations[obj]))
            })
            Promise.all(updatePromises)
                .then(() => {
                    setUpdating(false)
                    setFinished(true)
                    timer.current = setTimeout(() => {
                        setFinished(false)
                        clearTimeout(timer)
                        timer.current = null
                        dispatch(saveInPlace(false))
                    }, 3000)
                })
                .catch(() => {
                    setUpdating(false)
                    setFinished(true)
                    setError(true)
                    timer.current = setTimeout(() => {
                        setFinished(false)
                        setError(false)
                        clearTimeout(timer)
                        timer.current = null
                        dispatch(saveInPlace(false))
                    }, 3000)
                })
        }
    }, [savingTime])

    if (savingTime) {
        return (
            <div className={classes.root}>
                {showUpdating && (
                    <Fab variant="contained" className={classes.blinkingIcon} color="primary">
                        <SaveIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
                {showFinishedButton && (
                    <Fab variant="contained" color="success">
                        <DoneIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
                {showErrorButton && (
                    <Fab variant="contained" color="error">
                        <CloseIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
            </div>
        )
    }
    return null
}
// Using current theme
const SaveThemed = withTheme(Save)

export default SaveThemed
