import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import { useSelector, useDispatch } from "react-redux"

import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import RadiometricViewer from "./containers/radiometric-viewer"

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    icon: {
        position: "absolute",
        right: theme.spacing(1),
        bottom: theme.spacing(1)
    }
}))

import { openDialog } from "./reducers/dialogs"

export default function() {

    const classes = useStyles()

    const { selected_image } = useSelector(state => state.inspector.solar.images)
    
    const dispatch = useDispatch()

    const buttonClicked = () => dispatch(openDialog())

    return (
        <React.Fragment>
            {selected_image && 
                <div className={classes.root}>
                    <div className={classes.icon}>
                        <IconButton size={"small"} style={{ background: "white" }} onClick={buttonClicked}><FullscreenIcon fontSize={"small"}/></IconButton>
                    </div>
                    <img src={selected_image.url} width={"100%"} height={"auto"}/>
                </div>
            }
            <RadiometricViewer/>
        </React.Fragment>
    )
}