import React from 'react'
import PropTypes from 'prop-types'
import MaterialCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { useNode } from '@craftjs/core'

const Checkbox = (props) => {
    const {
        connectors: { connect, drag },
    } = useNode()
    /* eslint-disable react/destructuring-assignment */
    return (
        <div ref={(ref) => connect(drag(ref))}>
            <FormControlLabel control={<MaterialCheckbox {...props} />} label={props.content} />
        </div>
    )
}

Checkbox.craft = {
    displayName: 'Checkbox',
    custom: {
        component: true,
    },
}
Checkbox.propTypes = {
    content: PropTypes.bool,
}
Checkbox.defaultProps = {
    content: true,
}

export default Checkbox
