export const OPEN_NEW_BLUEPRINT_DIALOG = "NEW_BLUEPRINT_DIALOG/OPEN"
export const CLOSE_NEW_BLUEPRINT_DIALOG = "NEW_BLUEPRINT_DIALOG/CLOSE"

export const openNewBlueprintDialog = () => ({ type: OPEN_NEW_BLUEPRINT_DIALOG })
export const closeNewBlueprintDialog = () => ({ type: CLOSE_NEW_BLUEPRINT_DIALOG })

export default (state = { open: false }, action) => {
    switch(action.type) {
        case OPEN_NEW_BLUEPRINT_DIALOG:
            return {...state, open: true}
        case CLOSE_NEW_BLUEPRINT_DIALOG:
            return {...state, open: false}
        default:
            return state
    }
}