export const SET_TOOL = "SET_TOOL"
export const SET_RANGE = "SET_RANGE"
export const SET_SELECTED_HOTSPOT = "SELECT_HOTSPOT"

export const setTool = tool => ({ type: SET_TOOL, payload: { tool }})
export const setRange = range => ({ type: SET_RANGE, payload: { range }})
export const setSelectedHotspot = hotspot => ({ type: SET_SELECTED_HOTSPOT, payload: { hotspot }})

export default (state = { selected_hotspot: {}, tool: "move", range: [ 0, 100 ]}, action) => {
    switch(action.type) {
        case SET_TOOL:
            return {...state, tool: action.payload.tool }
        case SET_RANGE:
            return {...state, range: action.payload.range }
        case SET_SELECTED_HOTSPOT:
            return {...state, selected_hotspot: action.payload.hotspot }
        default:
            return state
    }
}