import React, { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import RGL, { WidthProvider } from "react-grid-layout";

const ReactGridLayout = WidthProvider(RGL);

import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

import NewWidgetDialog from "./containers/new-widget-dialog/scene"

import BarChart from "./widgets/bar-chart/scene"

import { GET_WIDGETS_BY_DASHBOARDS, UPDATE_WIDGET_BY_ID} from "./queries/dashboards"

const widgetsDict = {
    "bar-chart": BarChart
}

export default () => {

    const { dashboard } = useParams()

    const { data: { getWidgetsByDashboard: { widgets = []} = {}} = {}, loading } = useQuery(GET_WIDGETS_BY_DASHBOARDS, { variables: { params: { dashboard }}})

    const [ updateWidgetById ] = useMutation(UPDATE_WIDGET_BY_ID)
    
    const [ layout, setLayout ] = useState([])

    useEffect(() => {
        setLayout(
            widgets.map(w => {
                return {
                    id: w.id,
                    type: w.type,
                    size: w.size,
                    position: w.position,
                    properties: w.properties
                }
            })
        )
    }, [ loading ])

    const onLayoutChange = async l => {
        setLayout(l.map((w, i) => {
            return {
                ...layout[i],
                size: {
                    w: w.w,
                    h: w.h
                },
                position: {
                    x: w.x,
                    y: w.y
                }
            }
        }))

        await Promise.all(
            l.map((w, i) => {
                const current = layout[i]
                console.log(current)
                if(current.size.w !== w.w || current.size.h !== w.h || current.position.x !== w.x || current.position.y !== w.y ) {

                    const id = w.i.split(":")[0]
                    if(id) {
                        return updateWidgetById({ variables: { params: { id, size: { w: w.w, h: w.h }, position: { x: w.x, y: w.y } }}})
                    }
                }
            })
        )
    }

    return (
        <React.Fragment>
            <ReactGridLayout className="layout" layout={layout} cols={12} rowHeight={30} width={1200} onLayoutChange={onLayoutChange}>
                {
                    layout.map(w => {
                        const CurrentWidget = widgetsDict[w.type]

                        const properties = w.properties.reduce((acc, item) => ({...acc, [item.name]: item.value}), {})
                        
                        return (
                            <div key={`${w.id}:${w.size.w}-${w.size.h}-${w.position.x}-${w.position.y}`} data-grid={{ x: w.position.x, y: w.position.y, w: w.size.w, h: w.size.h }} resizeHandles={["se"]} style={{ display: "flex" }}>
                                <CurrentWidget  {...properties} />
                            </div>
                        )
                    })
                }
            </ReactGridLayout>
            <NewWidgetDialog/>
        </React.Fragment>
    )
}