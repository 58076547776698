import React from "react"

import Fab from "@material-ui/core/Fab"

import AddIcon from "@material-ui/icons/Add"

import { useDispatch } from "react-redux"

import { openNewIndustrialDatasetsDialog } from "../../reducers/dialogs"

function Actions() {
    const dispatch = useDispatch()

    const onFabClicked = () => dispatch(openNewIndustrialDatasetsDialog())

    return (
        <div style={{ position: "fixed", bottom: 50, right: 50 }}>
            <Fab color={"primary"} onClick={onFabClicked}><AddIcon/></Fab>
        </div>
    )
}

export default Actions