import React from 'react'

import NewResourceDialog from './containers/new-resource-dialog/index'
import ResourcesList from './containers/resources-list'

import { useIsAllowed } from 'queries/permissions'

const Resources = () => {
    const { isAllowed: isCreateResourceAllowed } = useIsAllowed({
        action: 'createResource',
        group: 'groups',
    })

    return (
        <React.Fragment>
            {isCreateResourceAllowed && <NewResourceDialog />}
            <ResourcesList />
        </React.Fragment>
    )
}

export default Resources
