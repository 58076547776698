import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import {
    STRING_TYPE,
    FLOAT_TYPE,
    BOOLEAN_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const ArrayField = ({ name, value, changeValue, listProps, listItemsProps }) => {
    const [collapseArray, setCollapseArray] = useState(true)

    const changeLowerBounds = (index) => (e) => {
        return changeValue([
            ...value.slice(0, index),
            parseFloat(e.target.value),
            ...value.slice(index + 1),
        ])
    }
    return (
        <List {...listProps}>
            <ListItem style={{ padding: 0 }} {...listItemsProps}>
                <TextField label={name} value={`${value.map((val) => val.toFixed(0))}`} fullWidth />
                <ListItemSecondaryAction
                    onClick={() => setCollapseArray(!collapseArray)}
                    style={{ padding: 0 }}
                >
                    {!setCollapseArray ? <ExpandLess /> : <ExpandMore />}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={!collapseArray} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {value.map((val, index) => {
                        return (
                            <ListItem key={index}>
                                <ListItemText sx={{ pl: 1 }} primary={`${index}: `} />
                                <TextField
                                    type="number"
                                    value={val}
                                    onChange={changeLowerBounds(index)}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            </Collapse>
        </List>
    )
}

ArrayField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    changeValue: PropTypes.func.isRequired,
    listProps: PropTypes.object,
    listItemsProps: PropTypes.object,
}

ArrayField.defaultProps = {
    listProps: {},
    listItemsProps: {},
}

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const [channels, setChannels] = useState(
        JSON.parse(getProperty('channels', JSON.stringify({ value: 3 }))).value
    )
    const [lowerBounds, setLowerBounds] = useState(
        JSON.parse(
            JSON.parse(
                getProperty(
                    'lower-bounds',
                    JSON.stringify({ value: JSON.stringify(Array(channels).fill(0)) })
                )
            ).value
        )
    )
    const [upperBounds, setUpperBounds] = useState(
        JSON.parse(
            JSON.parse(
                getProperty(
                    'upper-bounds',
                    JSON.stringify({ value: JSON.stringify(Array(channels).fill(0)) })
                )
            ).value
        )
    )

    const onChannelsChangeProp = onPropertyChangeBuilder('channels', (e) =>
        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
    )
    const onChannelsChange = (e) => {
        const newChannels = parseInt(e.target.value, 10)
        if (newChannels > channels) {
            setLowerBounds([...lowerBounds, ...Array(newChannels - channels).fill(0)])
            setUpperBounds([...upperBounds, ...Array(newChannels - channels).fill(0)])
        } else {
            setLowerBounds([...lowerBounds.slice(0, newChannels)])
            setUpperBounds([...upperBounds.slice(0, newChannels)])
        }
        setChannels(newChannels)
        onChannelsChangeProp(e)
    }

    const lowerBoundsChange = onPropertyChangeBuilder('lower-bounds', (value) => {
        setLowerBounds(value)
        return JSON.stringify({ type: TYPE_FIXED, value: JSON.stringify(value) })
    })

    const upperBoundsChange = onPropertyChangeBuilder('upper-bounds', (value) => {
        setUpperBounds(value)
        return JSON.stringify({ type: TYPE_FIXED, value: JSON.stringify(value) })
    })

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Entrada"
                    value={
                        JSON.parse(getProperty('input-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('input-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="channels" humanName="Canales" parametersFilter={FLOAT_TYPE}>
                    <TextField
                        label="Canales"
                        type="number"
                        InputProps={{
                            inputProps: {
                                min: 0,
                            },
                        }}
                        value={channels}
                        onChange={onChannelsChange}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="lower-bounds"
                    humanName="Desde"
                    parametersFilter={STRING_TYPE}
                    typeSelectorProps={{
                        justifyContent: 'flex-start',
                        style: { paddingTop: '16px' },
                    }}
                >
                    <ArrayField
                        name="Desde"
                        value={lowerBounds}
                        changeValue={lowerBoundsChange}
                        listProps={{ style: { padding: 0 } }}
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="upper-bounds"
                    humanName="Hasta"
                    parametersFilter={STRING_TYPE}
                    typeSelectorProps={{
                        justifyContent: 'flex-start',
                        style: { paddingTop: '16px' },
                    }}
                >
                    <ArrayField
                        name="Hasta"
                        value={upperBounds}
                        changeValue={upperBoundsChange}
                        listProps={{ style: { padding: 0 } }}
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="overwrite"
                    humanName="Sobrescribir"
                    parametersFilter={BOOLEAN_TYPE}
                >
                    <FormControl fullWidth>
                        <InputLabel>Sobrescribir</InputLabel>
                        <Select
                            value={
                                JSON.parse(
                                    getProperty('overwrite', JSON.stringify({ value: 'false' }))
                                ).value
                            }
                            onChange={onPropertyChangeBuilder('overwrite', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            <MenuItem key="true" value="true">
                                Activado
                            </MenuItem>
                            <MenuItem key="false" value="false">
                                Desactivado
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
