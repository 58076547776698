import React, { useState } from "react"

import { makeStyles } from "@material-ui/core/styles"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useParams, useHistory } from "react-router-dom"

import List from "@material-ui/core/List"
import Paper from "@material-ui/core/Paper"

import TextEdit from "components/text-edit"
import AreYouSureButton from "components/are-you-sure-button"

import { GET_ALL_PROJECTS, GET_PROJECT_BY_ID, UPDATE_PROJECT_NAME, UPDATE_PROJECT_DESCRIPTION, REMOVE_PROJECT } from "../../../../../../queries/projects"

function Settings(props) {

    const classes = useStyles()
    const history = useHistory()

    const { project } = useParams()

    const [ loading, setLoading ] = useState(false)

    const [ updateName ] = useMutation(UPDATE_PROJECT_NAME)
    const [ updateDescription ] = useMutation(UPDATE_PROJECT_DESCRIPTION)
    const [ removeProject ] = useMutation(REMOVE_PROJECT)

    const { data: { getProjectById: { project: { name, description } = {}} = {}} = {}} = useQuery(GET_PROJECT_BY_ID, { variables: { params: { _id: project }}})
    const onNameChanged = async name => {
        setLoading(true)
        await updateName({
            variables: {
                params: {
                    _id: project,
                    name
                }
            }
        })
        setLoading(false)
    }

    const onDescriptionChanged = async description => {
        setLoading(true)
        await updateDescription({
            variables: {
                params: {
                    _id: project,
                    description
                }
            }
        })
        setLoading(false)
    }
    const onRemoveProjectAccept = async () => {
        setLoading(true)
        try {
            await removeProject({
                variables: {
                    params: {
                        _id: project
                    }
                },
                refetchQueries: [
                    { query: GET_ALL_PROJECTS, variables: { params: {}} }
                ]
            })
            history.push("../../../inspector")
        } catch(err) {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <Paper>
                <List>
                    <TextEdit title="Nombre" value={name} onChange={onNameChanged}/>
                    <TextEdit title="Description" value={description} onChange={onDescriptionChanged}/>
                </List>
            </Paper>
            <AreYouSureButton loading={loading} onAccept={onRemoveProjectAccept} className={classes.button} variant={"contained"} fullWidth>Eliminar</AreYouSureButton>
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    button: {
        margin: "20px 0",
        background: theme.palette.error.main,
        color: theme.palette.common.white
    }
}))

export default Settings