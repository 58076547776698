import React from "react"

import { useMutation } from "@apollo/react-hooks"
import { useDispatch } from "react-redux"

import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"
import ListItem from "@material-ui/core/ListItem"
import TextField from "@material-ui/core/TextField"


import { setTitle, setLogo } from "../../reducers/front-page"
import { SET_SUMMARY } from "../../queries/local"

export default function() {
    const dispatch = useDispatch()

    const [ setSummary ] = useMutation(SET_SUMMARY)

    const changeTitle = event => {
        dispatch(setTitle(event.target.value))
    }
    const onFileSelect = event => {
        const reader = new FileReader()
        
        reader.onloadend = function () {
            dispatch(setLogo(reader.result))
        }

        reader.readAsDataURL(event.target.files[0])
    }
    const onTextEditorChange = event => {
        setSummary({ variables: { value: event.target.value}})
    }
    return (
        <Paper
            variant="permanent"
            anchor="right"
            style={{ minHeight: "100%", width: "100%", paddingBottom: 50 }}
        >
            <List>
                <ListSubheader>Portada</ListSubheader>
                <ListItem>
                    <TextField label="Título" fullWidth onChange={changeTitle}/>
                </ListItem>
                <ListItem>
                    <input type="file" onChange={onFileSelect}/>
                </ListItem>
                <ListSubheader>Resumen</ListSubheader>
                <ListItem>
                    <TextField multiline rows={20} onChange={onTextEditorChange} fullWidth label=""/>
                </ListItem>
            </List>
        </Paper>
    )
}