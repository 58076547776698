import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { DynamicModuleLoader } from 'redux-dynamic-modules'

import { useIsAllowed } from 'queries/permissions'
import NodesMenu from './containers/nodes-menu/index'
import GraphView from './containers/graph-view/index'
import { SideBar } from './containers/functions/index'
import Save from './containers/save'
import { GET_BLUEPRINT_BY_ID } from '../../queries/blueprints'
import { setLinks, setNodes } from './redux/nodes'
import { setBulkParams } from './redux/params'
import { setBulkVariables } from './redux/variables'
import designerModule from './redux/module'
import { GET_PARAMS_BY_GROUP } from '../group/containers/params/queries/params'
import { GET_VARIABLES_BY_GROUP } from '../hmi-designer/queries/variables'

const Designer = ({ loading }) => {
    const { blueprint: blueprintId, group: groupId } = useParams()
    const dispatch = useDispatch()

    const { isAllowed: isEditBlueprint } = useIsAllowed({
        action: 'updateBlueprint',
        group: 'groups',
    })
    const { data: { getBlueprintById: { blueprint: { nodes, links } = {} } = {} } = {} } = useQuery(
        GET_BLUEPRINT_BY_ID,
        {
            variables: { params: { _id: blueprintId } },
        }
    )
    const { data: { getParamsByGroup: { params = [] } = {} } = {} } = useQuery(
        GET_PARAMS_BY_GROUP,
        { variables: { params: { group: groupId } } }
    )
    const { data: { getVariablesByGroup: { variables = [] } = {} } = {} } = useQuery(
        GET_VARIABLES_BY_GROUP,
        { variables: { params: { group: groupId } } }
    )

    // Read from database and dispatch nodes and links configuration
    useEffect(() => {
        dispatch(setLinks(links || []))
        dispatch(setNodes(nodes || []))
        dispatch(setBulkParams(params || []))
        dispatch(setBulkVariables(variables || []))
    }, [nodes, links, dispatch, blueprintId, params])

    return (
        <DynamicModuleLoader modules={[designerModule]}>
            <Grid
                id="graph"
                container
                style={{ maxHeight: 'calc(100vh - 64px)', overflow: 'hidden' }}
            >
                <Grid item xs={12}>
                    <GraphView />
                </Grid>
                <Paper
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        scrollbarWidth: 0,
                        height: '100%',
                        width: 250,
                    }}
                >
                    <NodesMenu loading={loading} />
                </Paper>
                <SideBar />
                {isEditBlueprint && <Save />}
            </Grid>
        </DynamicModuleLoader>
    )
}

Designer.propTypes = {
    loading: PropTypes.bool,
}

Designer.defaultProps = {
    loading: false,
}

export default Designer
