import gql from "graphql-tag"

export const GET_PROJECT_BY_ID = gql`
    query GetProjectById($params: GetProjectByIdRequest!) {
        getProjectById(params: $params) {
            status
            project {
                _id
                name
                position {
                    coordinates
                }
                createdAt
            }
        }
    }
`
