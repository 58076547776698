import _ from 'lodash'

import { removePoint, removePolygon, removeAnnotation, createPolygon, createPoint } from './utils'
import {
    EDIT_MODE_SELECT,
    EDIT_MODE_CREATE_POLYGONS,
    EDIT_MODE_REMOVE_POLYGONS,
    EDIT_MODE_REMOVE_POINTS,
    EDIT_MODE_CREATE_POINTS,
    TOOLBOX_POLYGONS_AND_POINTS,
    TOOLBOX_SAMPLES,
    TOOLBOX_ANNOTATION,
} from '../constants'

const SET_IMAGE_DATA = 'SET_IMAGE_DATA'
const SELECT_NEXT_ENTRY = 'SET_NEXT_ENTRY'
const SELECT_PREVIOUS_ENTRY = 'SELECT_PREVIOUS_ENTRY'

const SET_SELECTED_ANNOTATION_INDEX = 'SET_SELECTED_ANNOTATION_INDEX'
const SET_BULK_INPLACE_ANNOTATION = 'SET_BULK_INPLACE_ANNOTATION'
const SET_BY_INDEX_INPLACE_ANNOTATION = 'SET_BY_INDEX_INPLACE_ANNOTATION'
const SET_ANNOTATION_CLASS_INDEX = 'SET_ANNOTATION_CLASS_INDEX'
const APPEND_INPLACE_ANNOTATION = 'APPEND_INPLACE_ANNOTATION'
const REMOVE_BY_INDEX_INPLACE_ANNOTATION = 'REMOVE_BY_INDEX_INPLACE_ANNOTATION'
const SET_INPLACE_ANNOTATION_BY_ENTRY = 'SET_INPLACE_ANNOTATION_BY_ENTRY'
const RESTART_INPLACE_ANNOTATIONS = 'RESTART_INPLACE_ANNOTATIONS'

// Polygons points utils
const CLICK_ON_POINT = 'CLICK_ON_POINT'
const CLICK_ON_EDIT_AREA = 'CLICK_ON_EDIT_AREA'

const SET_SELECTED_ENTRY = 'SET_SELECTED_ENTRY'

// Behavior
const SET_EDIT_MODE = 'SET_EDIT_MODE'
const DESELECT = 'DESELECT'
const SAVE_IN_PLACE = 'SAVE_IN_PLACE'
const REMOVE_SELECTED = 'REMOVE_SELECTED'
const SET_TOOLBOX = 'SET_TOOLBOX'

// Annotations actions
export const setAnnotationIndex = (index) => ({
    type: SET_SELECTED_ANNOTATION_INDEX,
    index,
})

export const setAnnotationClass = (classIndex) => ({
    type: SET_ANNOTATION_CLASS_INDEX,
    classIndex,
})

export const setBulkInplaceAnnotation = (annotations) => ({
    type: SET_BULK_INPLACE_ANNOTATION,
    annotations,
})

export const setByIndexInplaceAnnotation = (annotation, index) => ({
    type: SET_BY_INDEX_INPLACE_ANNOTATION,
    annotation,
    index,
})

export const appendInPlaceAnnotation = (annotation) => ({
    type: APPEND_INPLACE_ANNOTATION,
    annotation,
})

export const removeByIndexInPlaceAnnotation = (index) => ({
    type: REMOVE_BY_INDEX_INPLACE_ANNOTATION,
    index,
})

export const setInPlaceAnnotationByEntry = (entryId, annotations) => ({
    type: SET_INPLACE_ANNOTATION_BY_ENTRY,
    entryId,
    annotations,
})

export const restartInPlaceAnnotations = () => ({
    type: RESTART_INPLACE_ANNOTATIONS,
})

// Polygons and points utils
export const clickOnPoint = (polIndex, pointIndex) => ({
    type: CLICK_ON_POINT,
    polIndex,
    pointIndex,
})

export const clickOnEditArea = (x, y) => ({
    type: CLICK_ON_EDIT_AREA,
    x,
    y,
})

// Entries actions
export const setSelectedEntry = (entry) => ({
    type: SET_SELECTED_ENTRY,
    entry,
})

export const selectNextEntry = () => ({
    type: SELECT_NEXT_ENTRY,
})
export const selectPreviousEntry = () => ({
    type: SELECT_PREVIOUS_ENTRY,
})

export const setImageData = (entries) => ({ type: SET_IMAGE_DATA, entries })

// Behavior actions
export const setEditMode = (activated) => ({ type: SET_EDIT_MODE, activated })

export const deselect = () => ({ type: DESELECT })

export const saveInPlace = (isSaving = true) => ({ type: SAVE_IN_PLACE, isSaving })

export const removeSelected = () => ({
    type: REMOVE_SELECTED,
})

export const setToolbox = (toolboxName) => ({ type: SET_TOOLBOX, name: toolboxName })

export const initialState = {
    selectedAnnotation: null,
    selectedPolygon: null,
    selectedPoint: null,
    inPlaceAnnotations: {},
    selectedEntry: null,
    inPlaceEntry: {},
    allIds: [],
    entries: {},
    editMode: EDIT_MODE_SELECT,
    saveInPlace: false,
    selectedToolbox: '',
}

export const reducer = (state = initialState, action = {}) => {
    const actualAnnotations = _.get(state.inPlaceAnnotations, state.selectedEntry, [])
    switch (action.type) {
        case SET_SELECTED_ANNOTATION_INDEX:
            if (action.index === null) {
                return {
                    ...state,
                    selectedPoint: null,
                    selectedPolygon: null,
                    selectedToolbox: TOOLBOX_SAMPLES,
                    editMode: EDIT_MODE_SELECT,
                    selectedAnnotation: action.index,
                }
            }
            return {
                ...state,
                selectedToolbox: TOOLBOX_ANNOTATION,
                selectedAnnotation: action.index,
                editMode: EDIT_MODE_SELECT,
                selectedPolygon: null,
                selectPoint: null,
            }

        case SET_ANNOTATION_CLASS_INDEX:
            if (state.selectedEntry === null) {
                return state
            }
            if (state.selectedAnnotation === null) {
                const nextAnnotationIndex = state.inPlaceAnnotations[state.selectedEntry].length
                return {
                    ...state,
                    editMode: EDIT_MODE_CREATE_POLYGONS,
                    selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
                    selectedAnnotation: nextAnnotationIndex,
                    inPlaceAnnotations: {
                        ...state.inPlaceAnnotations,
                        [state.selectedEntry]: [
                            ...state.inPlaceAnnotations[state.selectedEntry],
                            {
                                polygon: [],
                                class: action.classIndex,
                            },
                        ],
                    },
                }
            }
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [
                        ...state.inPlaceAnnotations[state.selectedEntry].slice(
                            0,
                            state.selectedAnnotation
                        ),
                        {
                            ...state.inPlaceAnnotations[state.selectedEntry][
                                state.selectedAnnotation
                            ],
                            class: action.classIndex,
                        },
                        ...state.inPlaceAnnotations[state.selectedEntry].slice(
                            state.selectedAnnotation + 1
                        ),
                    ],
                },
            }
        case SET_BULK_INPLACE_ANNOTATION:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: action.annotations,
                },
            }
        case SET_BY_INDEX_INPLACE_ANNOTATION:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [
                        ...actualAnnotations.slice(0, action.index),
                        action.annotation,
                        ...actualAnnotations.slice(action.index + 1),
                    ],
                },
            }
        case APPEND_INPLACE_ANNOTATION:
            return {
                ...state,
                selectedAnnotation: state.inPlaceAnnotations[state.selectedEntry].length,
                editMode: EDIT_MODE_CREATE_POLYGONS,
                selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [...actualAnnotations, action.annotation],
                },
            }
        case REMOVE_BY_INDEX_INPLACE_ANNOTATION:
            return {
                ...state,
                selectedToolbox: '',
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [state.selectedEntry]: [
                        ...actualAnnotations.slice(0, action.index),
                        ...actualAnnotations.slice(action.index + 1),
                    ],
                },
            }
        case SET_INPLACE_ANNOTATION_BY_ENTRY:
            return {
                ...state,
                inPlaceAnnotations: {
                    ...state.inPlaceAnnotations,
                    [action.entryId]: action.annotations,
                },
            }
        case RESTART_INPLACE_ANNOTATIONS:
            return {
                ...state,
                inPlaceAnnotations: {},
            }
        case SET_SELECTED_ENTRY:
            return { ...state, selectedToolbox: TOOLBOX_SAMPLES, selectedEntry: action.entry }
        case SELECT_NEXT_ENTRY:
            if (state.selectedEntry === null) {
                return {
                    ...state,
                    selectedToolbox: TOOLBOX_SAMPLES,
                    selectedEntry: state.allIds[0],
                    selectedAnnotation: null,
                    selectedPolygon: null,
                    selectedPoint: null,
                    saveInPlace: true,
                }
            }
            return {
                ...state,
                selectedAnnotation: null,
                selectedPolygon: null,
                selectedPoint: null,
                saveInPlace: true,
                selectedToolbox: TOOLBOX_SAMPLES,
                selectedEntry:
                    state.allIds[state.allIds.indexOf(state.selectedEntry) + 1] ||
                    state.allIds[state.allIds.length - 1],
            }
        case SELECT_PREVIOUS_ENTRY:
            if (state.selectedEntry === null) {
                return {
                    ...state,
                    selectedAnnotation: null,
                    selectedPolygon: null,
                    selectedPoint: null,
                    saveInPlace: true,
                    selectedToolbox: TOOLBOX_SAMPLES,
                    selectedEntry: state.allIds[state.allIds.length - 1],
                }
            }
            return {
                ...state,
                selectedAnnotation: null,
                selectedPolygon: null,
                selectedPoint: null,
                saveInPlace: true,
                selectedToolbox: TOOLBOX_SAMPLES,
                selectedEntry:
                    state.allIds[state.allIds.indexOf(state.selectedEntry) - 1] || state.allIds[0],
            }
        case SET_IMAGE_DATA:
            return {
                ...state,
                entries: action.entries.reduce((prev, next) => {
                    return { ...prev, [next._id]: { url: next.url } }
                }, {}),
                allIds: action.entries.map((obj) => obj._id),
            }
        case SET_EDIT_MODE:
            if (state.selectedEntry === null) {
                return state
            }
            return {
                ...state,
                selectedAnnotation:
                    state.selectedAnnotation === null ? 0 : state.selectedAnnotation,
                selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
                editMode: action.activated,
            }
        case DESELECT:
            if (!(state.selectedPoint === null)) {
                return {
                    ...state,
                    selectedPoint: null,
                    selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
                    editMode: EDIT_MODE_SELECT,
                }
            }
            if (!(state.selectedPolygon === null)) {
                return {
                    ...state,
                    selectedPolygon: null,
                    selectedToolbox: TOOLBOX_ANNOTATION,
                    editMode: EDIT_MODE_SELECT,
                }
            }
            if (!(state.selectedAnnotation === null)) {
                return {
                    ...state,
                    selectedAnnotation: null,
                    selectedToolbox: TOOLBOX_SAMPLES,
                    editMode: EDIT_MODE_SELECT,
                }
            }
            if (!(state.selectedEntry === null)) {
                return {
                    ...state,
                    selectedEntry: null,
                    selectedToolbox: '',
                    editMode: EDIT_MODE_SELECT,
                }
            }
            return {
                ...state,
            }
        case SAVE_IN_PLACE:
            return {
                ...state,
                saveInPlace: action.isSaving,
            }
        case REMOVE_SELECTED: {
            if (!(state.selectedPoint === null)) {
                return removePoint(state, state.selectedPolygon, state.selectedPoint)
            }
            if (!(state.selectedPolygon === null)) {
                return removePolygon(state, state.selectedPolygon)
            }
            if (!(state.selectedAnnotation === null)) {
                return removeAnnotation(state, state.selectedAnnotation)
            }
            return {
                ...state,
            }
        }
        case SET_TOOLBOX:
            return {
                ...state,
                selectedToolbox: action.name,
            }
        // Polygons and point utils
        case CLICK_ON_POINT: {
            const { editMode } = state
            if (editMode === EDIT_MODE_REMOVE_POLYGONS) {
                return removePolygon(state, action.polIndex)
            }
            if (editMode === EDIT_MODE_REMOVE_POINTS) {
                return removePoint(state, action.polIndex, action.pointIndex)
            }
            if (editMode === EDIT_MODE_SELECT) {
                return {
                    ...state,
                    selectedToolbox: TOOLBOX_POLYGONS_AND_POINTS,
                    selectedPolygon: action.polIndex,
                    selectedPoint: action.pointIndex,
                }
            }
            return state
        }
        case CLICK_ON_EDIT_AREA: {
            const { editMode, selectedPolygon } = state

            if (editMode === EDIT_MODE_CREATE_POLYGONS) {
                return createPolygon(state, action.x, action.y)
            }
            if (editMode === EDIT_MODE_CREATE_POINTS) {
                let newState
                if (selectedPolygon === null) {
                    newState = createPolygon(state, action.x, action.y)
                } else {
                    newState = createPoint(state, action.x, action.y)
                }
                return newState
            }
            return state
        }
        default:
            return state
    }
}
