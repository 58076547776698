import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { GET_PARAMS_BY_GROUP } from '../../../../../queries/params'
import { GET_VARIABLES_BY_GROUP } from '../../../../../queries/variables'

const TextSettings = ({ query, actions, id }) => {
    const node = query.node(id).get()

    const { group } = useParams()

    const forceUpdate = useReducer((x) => x + 1, 0)[1]

    const { data: { getParamsByGroup: { params = [] } = {} } = {} } = useQuery(
        GET_PARAMS_BY_GROUP,
        { variables: { params: { group } } }
    )

    const { data: { getVariablesByGroup: { variables = [] } = {} } = {} } = useQuery(
        GET_VARIABLES_BY_GROUP,
        { variables: { params: { group } } }
    )

    /* eslint-disable no-unused-vars,no-param-reassign */
    const onIdChange = (e) => {
        actions.setProp(id, (p) => {
            p._id = e.target.value
        })
    }

    const getProperty = (key, _) => {
        const nodeInner = query.node(id).get()

        try {
            let prop = JSON.parse(nodeInner.data.props[key])

            if (!prop.type) {
                prop = {
                    type: 'none',
                }
            }

            return prop
        } catch (err) {
            return {
                type: 'none',
            }
        }
    }

    const onPropertyTypeChanged = (property) => (event) => {
        const value = {
            type: event.target.value,
        }

        actions.setProp(id, (p) => {
            p[property] = JSON.stringify(value)
        })

        forceUpdate()
    }

    const onPropertyValueChanged = (property) => (event) => {
        let value = getProperty(property, '')

        switch (value.type) {
            case 'parameter': {
                const param = params.find((item) => item._id === event.target.value)
                value = {
                    type: 'parameter',
                    id: event.target.value,
                    name: param.name,
                }
                break
            }
            case 'variable': {
                const variable = variables.find((item) => item._id === event.target.value)
                value = {
                    type: 'variable',
                    id: event.target.value,
                    name: variable.name,
                }
                break
            }
            default:
                value = {
                    type: 'fixed',
                    value: event.target.value,
                }
        }

        actions.setProp(id, (p) => {
            p[property] = JSON.stringify(value)
        })
    }

    const value = getProperty('value', '')
    const title = getProperty('title', '')

    return (
        <List>
            <ListItem>
                <TextField
                    label="ID"
                    onChange={onIdChange}
                    defaultValue={node.data.props._id || id}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    defaultValue={title.value}
                    label="Título"
                    onChange={onPropertyValueChanged('title')}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth={!value.type || value.type === 'fixed'}>
                    <InputLabel>Tipo</InputLabel>
                    <Select value={value.type} onChange={onPropertyTypeChanged('value')}>
                        <MenuItem value="none">Ninguno</MenuItem>
                        <MenuItem value="parameter">Parameter</MenuItem>
                        <MenuItem value="variable">Variable</MenuItem>
                    </Select>
                </FormControl>
                {value.type === 'parameter' && (
                    <FormControl fullWidth>
                        <InputLabel>Parámetro</InputLabel>
                        <Select value={value.id} onChange={onPropertyValueChanged('value')}>
                            <MenuItem value={null} />
                            {params.map((p) => {
                                return (
                                    <MenuItem key={p._id} value={p._id}>
                                        {p.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                )}
                {value.type === 'variable' && (
                    <FormControl fullWidth>
                        <InputLabel>Variable</InputLabel>
                        <Select value={value.id} onChange={onPropertyValueChanged('value')}>
                            <MenuItem value={null} />
                            {variables.map((p) => {
                                return (
                                    <MenuItem key={p._id} value={p._id}>
                                        {p.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                )}
            </ListItem>
        </List>
    )
}

TextSettings.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default TextSettings
