import React from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

import { setNodeProperties } from '../../../../redux/nodes'

const languages = [
    {
        name: 'Español',
        code: 'es',
    },
    {
        name: 'Inglés',
        code: 'en',
    },
]

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const onInputKeyChange = (event) => {
        dispatch(
            setNodeProperties({
                nodeId: node.id,
                properties: {
                    type: 'input-key',
                    value: event.target.value || '',
                },
            })
        )
    }

    const onLanguageChange = (event) => {
        dispatch(
            setNodeProperties({
                nodeId: node.id,
                properties: {
                    type: 'language',
                    value: event.target.value || '',
                },
            })
        )
    }

    const onOutputKeyChange = (event) => {
        dispatch(
            setNodeProperties({
                nodeId: node.id,
                properties: {
                    type: 'output-key',
                    value: event.target.value || '',
                },
            })
        )
    }

    const inputKey = node.properties['input-key'] || ''
    const language = node.properties['language'] || ''
    const outputKey = node.properties['output-key'] || ''

    return (
        <div className={classes.root}>
            <TextField
                label="Ruta de Entrada"
                value={inputKey}
                onChange={onInputKeyChange}
                fullWidth
            />
            <FormControl fullWidth>
                <InputLabel>Idioma</InputLabel>
                <Select value={language} onChange={onLanguageChange}>
                    {languages.map((l) => (
                        <MenuItem key={l.name} value={l.code}>
                            {l.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>Seleccione un tipo de frente</FormHelperText>
            </FormControl>
            <TextField
                label="Ruta de Salida"
                value={outputKey}
                onChange={onOutputKeyChange}
                fullWidth
            />
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
}

export default SideBar
