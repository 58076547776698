import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'

import { GET_RESOURCES_BY_TYPE } from '../../../../../../queries/resources'
import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'
import {
    INTEGER_TYPE,
    BOOLEAN_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '20px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()
    const { group } = useParams()

    const { data: { getResourcesByType: { resources = [] } = {} } = {} } = useQuery(
        GET_RESOURCES_BY_TYPE,
        {
            variables: {
                params: {
                    group,
                    type: 'camera-usb3-vision',
                },
            },
        }
    )

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Entrada"
                    value={
                        JSON.parse(getProperty('input-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('input-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Cámara</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder('camera', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        value={
                            JSON.parse(getProperty('camera', JSON.stringify({ value: '' }))).value
                        }
                    >
                        {resources.map((resource) => (
                            <MenuItem key={resource._id} value={resource._id}>
                                {resource.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>Seleccione una cámara</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Formato</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder('format', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        value={
                            JSON.parse(getProperty('format', JSON.stringify({ value: '' }))).value
                        }
                    >
                        <MenuItem value="jpg">JPG</MenuItem>
                        <MenuItem value="raw">RAW</MenuItem>
                    </Select>
                    <FormHelperText>Seleccione un formato</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Invertir X</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder('reverse-x', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        value={
                            JSON.parse(getProperty('reverse-x', JSON.stringify({ value: '' })))
                                .value
                        }
                    >
                        <MenuItem value="" />
                        <MenuItem value="false">No</MenuItem>
                        <MenuItem value="true">Sí</MenuItem>
                    </Select>
                    <FormHelperText>Seleccione un formato</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Invertir Y</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder('reverse-y', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        value={
                            JSON.parse(getProperty('reverse-y', JSON.stringify({ value: '' })))
                                .value
                        }
                    >
                        <MenuItem value="" />
                        <MenuItem value="false">No</MenuItem>
                        <MenuItem value="true">Sí</MenuItem>
                    </Select>
                    <FormHelperText>Seleccione un formato</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Rotar</InputLabel>
                    <Select
                        onChange={onPropertyChangeBuilder('rotate', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        value={
                            JSON.parse(getProperty('rotate', JSON.stringify({ value: '' }))).value
                        }
                    >
                        <MenuItem value="false">Desactivado</MenuItem>
                        <MenuItem value="clockwise">Horario</MenuItem>
                        <MenuItem value="counter-clockwise">Antihorario</MenuItem>
                    </Select>
                    <FormHelperText>Seleccione un sentido</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item sm={12}>
                <Parametrize
                    name="exposure-time"
                    humanName="Tiempo de exposición(us)"
                    parametersFilter={INTEGER_TYPE}
                >
                    <FormControl fullWidth style={{ marginTop: 5 }}>
                        <TextField
                            type="number"
                            min="0"
                            step="1"
                            onChange={onPropertyChangeBuilder('exposure-time', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            label="Tiempo de exposición (uS)"
                            value={
                                JSON.parse(
                                    getProperty('exposure-time', JSON.stringify({ value: '' }))
                                ).value
                            }
                        />
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('x', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        label="Offset X"
                        value={JSON.parse(getProperty('x', JSON.stringify({ value: '' }))).value}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('y', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        label="Offset Y"
                        value={JSON.parse(getProperty('y', JSON.stringify({ value: '' }))).value}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('width', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        label="Ancho"
                        value={
                            JSON.parse(getProperty('width', JSON.stringify({ value: '' }))).value
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        type="number"
                        min="0"
                        step="1"
                        onChange={onPropertyChangeBuilder('height', (e) =>
                            JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                        )}
                        label="Alto"
                        value={
                            JSON.parse(getProperty('height', JSON.stringify({ value: '' }))).value
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item sm={12}>
                <Parametrize name="gain" humanName="Ganancia" parametersFilter={INTEGER_TYPE}>
                    <FormControl fullWidth style={{ marginTop: 5 }}>
                        <TextField
                            type="number"
                            min="0"
                            step="1"
                            onChange={onPropertyChangeBuilder('gain', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            label="Ganancia"
                            value={
                                JSON.parse(getProperty('gain', JSON.stringify({ value: '' }))).value
                            }
                        />
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item sm={12}>
                <Parametrize name="delay" humanName="Delay (ms)" parametersFilter={INTEGER_TYPE}>
                    <FormControl fullWidth style={{ marginTop: 5 }}>
                        <TextField
                            type="number"
                            min="0"
                            step="1"
                            onChange={onPropertyChangeBuilder('delay', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            label="Delay (ms)"
                            value={
                                JSON.parse(getProperty('delay', JSON.stringify({ value: '' })))
                                    .value
                            }
                        />
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <InputLabel style={{ marginTop: 5 }}>Calidad</InputLabel>
                <FormControl fullWidth>
                    <Slider
                        step={1}
                        marks
                        min={0}
                        max={100}
                        valueLabelDisplay="auto"
                        value={parseInt(
                            JSON.parse(getProperty('quality', JSON.stringify({ value: 0 }))).value,
                            10
                        )}
                        onChange={onPropertyChangeBuilder('quality', (_, value) =>
                            JSON.stringify({ type: TYPE_FIXED, value })
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="output-width"
                    humanName="Ancho de salida (píxeles)"
                    parametersFilter={INTEGER_TYPE}
                >
                    <FormControl fullWidth style={{ marginTop: 5 }}>
                        <TextField
                            type="number"
                            min="0"
                            step="1"
                            onChange={onPropertyChangeBuilder('output-width', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            label="Ancho de salida (píxeles)"
                            value={
                                JSON.parse(
                                    getProperty('output-width', JSON.stringify({ value: '' }))
                                ).value
                            }
                        />
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="output-height"
                    humanName="Alto de salida (píxeles)"
                    parametersFilter={INTEGER_TYPE}
                >
                    <FormControl fullWidth style={{ marginTop: 5 }}>
                        <TextField
                            type="number"
                            min="0"
                            step="1"
                            onChange={onPropertyChangeBuilder('output-height', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                            label="Alto de salida (píxeles)"
                            value={
                                JSON.parse(
                                    getProperty('output-height', JSON.stringify({ value: '' }))
                                ).value
                            }
                        />
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize
                    name="preserve-aspect-ratio"
                    humanName="Preservar proporciones"
                    parametersFilter={BOOLEAN_TYPE}
                >
                    <FormControl fullWidth>
                        <InputLabel>Preservar proporciones</InputLabel>
                        <Select
                            value={
                                JSON.parse(
                                    getProperty(
                                        'preserve-aspect-ratio',
                                        JSON.stringify({ value: '' })
                                    )
                                ).value
                            }
                            onChange={onPropertyChangeBuilder('preserve-aspect-ratio', (e) =>
                                JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                            )}
                        >
                            <MenuItem key="true" value="true">
                                Activado
                            </MenuItem>
                            <MenuItem key="false" value="false">
                                Desactivado
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Ruta de Salida"
                    value={
                        JSON.parse(getProperty('output-key', JSON.stringify({ value: null }))).value
                    }
                    onChange={onPropertyChangeBuilder('output-key', (e) =>
                        JSON.stringify({ type: TYPE_FIXED, value: e.target.value })
                    )}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Versión</InputLabel>
                    <Select
                        value={getProperty('version', '$LATEST')}
                        onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                    >
                        {nodeDefinition.conf.versions.map((version) => (
                            <MenuItem key={`${node.id}_version${version}`} value={version}>
                                {version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
