import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'
import MaterialCard from '@material-ui/core/Card'
import MaterialBox from '@material-ui/core/Box'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'

const Palletizer = ({ height, padding, margin, backgroundColor }) => {
    const {
        connectors: { connect, drag },
    } = useNode()

    return (
        <MaterialCard
            ref={(ref) => connect(drag(ref))}
            style={{ padding, margin, backgroundColor, height }}
        >
            <MaterialBox
                ref={(ref) => connect(drag(ref))}
                style={{
                    height: '100%',
                    width: '100%',
                    borderStyle: 'dashed',
                    border: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <PrecisionManufacturingIcon size="large" style={{ height: '60%', width: '60%' }} />
            </MaterialBox>
        </MaterialCard>
    )
}

Palletizer.craft = {
    displayName: 'Palletizer',
    custom: {
        component: true,
    },
    props: {
        height: '50px',
        padding: '0px 0px 0px 0px',
        margin: '0px 0px 0px 0px',
        backgroundColor: '#e8e8e8',
    },
}

Palletizer.propTypes = {
    height: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    backgroundColor: PropTypes.string,
}

Palletizer.defaultProps = {
    height: '100%',
    padding: '20px',
    margin: '0px',
    backgroundColor: '#e8e8e8',
}

export default Palletizer
