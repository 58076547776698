import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { useQuery, useApolloClient } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"

import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Fab from '@material-ui/core/Fab';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import SideMenu from "./containers/side-menu"
import FrontPage from "./containers/front-page"
import ReportIndex from "./containers/report-index"
import Summary from "./containers/summary"
import Hotspots from "./containers/hotspots"

import { GET_HOTSPOTS_BY_DATASET } from "./containers/hotspots/queries/hotspots"

import resolvers from "./resolvers"

export default function() {
    const classes = useStyles()
    const client = useApolloClient()

    client.addResolvers(resolvers)

    const { inspection } = useParams()

    const { data: { getHotspotsByDataset: { hotspots = [] } = {}} = {}} = useQuery(GET_HOTSPOTS_BY_DATASET, {
        variables: {
            params: {
                dataset: inspection
            }
        }
    })

    const filtered = hotspots.filter(hotspot => hotspot.image && hotspot.position && hotspot.score )

    return <div className={classes.root}>
        <Grid container style={{ height: "100%" }}>
            <Grid item xs={9} style={{ height: "100%", overflowY: "auto" }}>
                <Container className={classes.container}>
                        <Paper className={classes.page}>
                            <FrontPage />
                        </Paper>
                        <Paper className={classes.page}>
                            <div className={classes.inner}>
                                <Summary/>
                            </div>
                            <div className={classes.footer}>
                                1
                            </div>
                        </Paper>
                        {filtered.map(hotspot => <Hotspots className={classes.page} hotspot={hotspot} />)}
                </Container>
            </Grid>
            <Grid item xs={3} className={classes.sideMenu}>
                <SideMenu/>
            </Grid>
        </Grid>
        <Fab variant="extended" color="primary" aria-label="delete" className={classes.fab} onClick={() => window.print() }>
            <CloudDownloadIcon className={classes.extendedIcon} />
            Exportar
        </Fab>
    </div>
}


const useStyles = makeStyles(theme => ({
    root: {
        background: "#444",
        height: "100%",
        "@media print": {
            height: "100vh",
            width: "100vw",
            background: "#FFF !important" ,
            position: "absolute",
            zIndex: 100000000000000
        }
    },
    container: {
        marginTop: 50,
        minHeight: "100vh",
        "@media print": {
            position: "absolute",
            top: 0,
            left: 0,
            padding: 0,
            margin: 0,
            transform: "scale(1)"
        }
    },
    page: {
        position: "relative",
        height: 1180,
        width: 1180/Math.sqrt(2),
        margin: "15px auto",
        borderRadius: 1,
        "page-break-after": "always",
        "@media print": {
            margin: 0
        }
    },
    inner: {
        padding: "60px 30px",
    },
    footer: {
        position: "absolute",
        bottom: "0",
        height: 80, 
        width: "100%", 
        background: theme.palette.primary.main,  
        margin: 0, 
        padding: 0
    },
    fab: {
        position: "fixed",
        bottom: 0,
        right: 0,
        margin: theme.spacing(3),
        "@media print": {
            display: "none"
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    sideMenu: {
        "@media print": {
            display: "none"
        }
    }
}))