export const SET_LOGO = 'REPORTS/SET_LOGO'
export const SET_TITLE = 'REPORTS/SET_TITLE'

export const setLogo = (logo) => ({ type: SET_LOGO, logo })
export const setTitle = (title) => ({ type: SET_TITLE, title })

import Logo from 'static/logo.png'

export default (state = { logo: Logo, title: 'Report' }, action) => {
    switch (action.type) {
        case SET_LOGO:
            return { ...state, logo: action.logo }
        case SET_TITLE:
            return { ...state, title: action.title }
        default:
            return state
    }
}
