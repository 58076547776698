import { combineReducers } from 'redux'

import { initialState as jobsInitialState, reducer as jobsReducer } from './jobs'

export default {
    id: 'jobs',
    initialState: { jobsInitialState },
    reducerMap: {
        jobs: combineReducers({ jobsReducer }),
    },
}
