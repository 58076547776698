import gql from 'graphql-tag'

export const CREATE_USER = gql`
    mutation CreateUser($params: CreateUserRequest!) {
        createUser(params: $params) {
            user {
                _id
                email
                role
            }
        }
    }
`

export const GET_ALL_USERS = gql`
    query GetAllUsers($params: GetAllUsersRequest!) {
        getAllUsers(params: $params) {
            users {
                _id
                email
                role
            }
        }
    }
`

export const REMOVE_USER_BY_ID = gql`
    mutation RemoveUserById($params: RemoveUserByIdRequest!) {
        removeUserById(params: $params) {
            status
        }
    }
`

export const GET_USER_BY_ID = gql`
    query GetUserById($params: GetUserByIdRequest!) {
        getUserById(params: $params) {
            user {
                _id
                email
                role
            }
        }
    }
`

export const UPDATE_USER_BY_ID = gql`
    mutation UpdateUserById($params: UpdateUserByIdRequest!) {
        updateUserById(params: $params) {
            status
        }
    }
`
