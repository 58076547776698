import React from 'react'

import Typography from '@material-ui/core/Typography'

const Scene = () => {
    return (
        <div>
            <Typography
                variant="h5"
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                Selecciona una opción en el menú lateral
            </Typography>
        </div>
    )
}

export default Scene
