import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ErrorIcon from '@mui/icons-material/Error'
import DoneIcon from '@mui/icons-material/Done'
import Checkbox from '@mui/material/Checkbox'

import ListSubheader from '@mui/material/ListSubheader'

import ButtonStatus from '../../../../../../../../../../../../../utils/common-components/buttonStatus'

import { setStyleConf } from '../../../redux/settings'

const Style = () => {
    const dispatch = useDispatch()

    const style = useSelector((state) => state.trainingSetObjectDetection.settings.style)

    const [loading, setLoading] = useState(false)

    const [strokeWidth, setStrokeWidth] = useState(style.annotations.strokeWidth)
    const [strokeOpacity, setStrokeOpacity] = useState(style.annotations.strokeOpacity)
    const [fillOpacity, setFillOpacity] = useState(style.annotations.fillOpacity)

    const [guidesEnabled, setGuidesEnabled] = useState(style.guides.enabled)
    const [guidesWidth, setGuidesWidth] = useState(style.guides.width)

    const onAccept = async () => {
        setLoading(true)
        const annotationsConf = {
            strokeWidth,
            strokeOpacity,
            fillOpacity,
        }
        const guidesConf = {
            enabled: guidesEnabled,
            width: guidesWidth,
        }
        dispatch(
            setStyleConf({
                annotations: { ...style.annotations, ...annotationsConf },
                guides: { ...style.guides, ...guidesConf },
            })
        )
        setLoading(false)
    }

    const onStrokeWidthChange = (event) => {
        setStrokeWidth(parseInt(event.target.value, 10))
    }
    const onStrokeOpacityChange = (event) => {
        setStrokeOpacity(parseInt(event.target.value, 10) / 100)
    }

    const onFillOpacityChange = (event) => {
        setFillOpacity(parseInt(event.target.value, 10) / 100)
    }

    const onGuideEnabledChange = (event) => {
        setGuidesEnabled(event.target.checked)
    }
    const onGuideWidthChange = (event) => {
        setGuidesWidth(parseInt(event.target.value, 10))
    }

    const errorIcon = (
        <ErrorIcon
            fontSize="large"
            color="error"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    const successIcon = (
        <DoneIcon
            fontSize="large"
            color="success"
            style={{
                position: 'absolute',
                top: 'calc(50% - 13px)',
                left: 'calc(50% - 10px)',
            }}
        />
    )

    return (
        <List subheader={<li />}>
            <ListSubheader>Anotaciones</ListSubheader>
            <ListItem>
                <TextField
                    fullWidth
                    type="number"
                    label="Espesor"
                    value={strokeWidth}
                    onChange={onStrokeWidthChange}
                />
            </ListItem>
            <ListItem>
                <TextField
                    fullWidth
                    type="number"
                    label="Opacidad de espesor"
                    InputProps={{
                        inputProps: {
                            max: 100,
                            min: 0,
                        },
                    }}
                    value={Math.floor(strokeOpacity * 100)}
                    onChange={onStrokeOpacityChange}
                />
            </ListItem>
            <ListItem>
                <TextField
                    fullWidth
                    type="number"
                    label="Opacidad"
                    InputProps={{
                        inputProps: {
                            max: 100,
                            min: 0,
                        },
                    }}
                    value={Math.floor(fillOpacity * 100)}
                    onChange={onFillOpacityChange}
                />
            </ListItem>
            <ListSubheader>Guías</ListSubheader>
            <ListItem>
                <Checkbox
                    label="Habilitadas"
                    checked={guidesEnabled}
                    onChange={onGuideEnabledChange}
                />
            </ListItem>
            <ListItem>
                <TextField
                    fullWidth
                    type="number"
                    label="Espesor"
                    value={guidesWidth}
                    onChange={onGuideWidthChange}
                />
            </ListItem>
            <ListItem>
                <ButtonStatus
                    error={false}
                    loading={loading}
                    buttonChildren="Guardar"
                    buttonProps={{
                        color: 'primary',
                        variant: 'contained',
                        onClick: onAccept,
                    }}
                    errorIcon={errorIcon}
                    successIcon={successIcon}
                    animationTimeout={3000}
                />
            </ListItem>
        </List>
    )
}

export default Style
