import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { useParams, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer'
import Checkbox from '@material-ui/core/Checkbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import Subheader from '@material-ui/core/ListSubheader'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import DeleteIcon from '@material-ui/icons/Delete'
import AdjustIcon from '@material-ui/icons/Adjust'

import { GET_INCIDENTS_BY_GROUP } from '../../queries/incidents'
import { GET_INCIDENT_GROUP_BY_ID } from '../../queries/groups'

import IncidentRow from './containers/incident-row/scene'
import Toolbar from './containers/toolbar/scene'
import Emails from './containers/emails/scene'
import Name from './containers/name/scene'

import { useIsAllowed } from 'queries/permissions'

import { useQueryParam } from 'use-query-params'

import { PageParam, ShowClosedParam, RowsPerPageParam } from './params'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
}))

const Incidents = (props) => {
    const classes = useStyles()

    const { group } = useParams()

    const [selected, setSelected] = useState([])

    const [page, setPage] = useQueryParam('page', PageParam)
    const [showClosed, setShowClosed] = useQueryParam('showClosed', ShowClosedParam)
    const [rowsPerPage, setRowsPerPage] = useQueryParam('rowsPerPage', RowsPerPageParam)

    const {
        loading,
        error,
        data: { getIncidentsByGroup: { incidents = [], count = 0 } = {} } = {},
    } = useQuery(GET_INCIDENTS_BY_GROUP, {
        variables: {
            params: {
                group,
                limit: rowsPerPage,
                skip: page * rowsPerPage,
                showClosed,
            },
        },
    })

    const { isAllowed: isUpdateIncidentGroupAllowed = false } = useIsAllowed({
        group: 'incidents',
        action: 'updateIncidentGroup',
    })

    const {
        loading: loadingGroup,
        error: errorGroup,
        data: { getIncidentGroupById: { group: { name = '', emails = [] } = {} } = {} } = {},
    } = useQuery(GET_INCIDENT_GROUP_BY_ID, { variables: { params: { id: group } } })

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelected(incidents.map(({ id }) => id))
        } else {
            setSelected([])
        }
    }

    const handleSelect = (id) => () => {
        if (selected.includes(id)) {
            setSelected(selected.filter((i) => i !== id))
        } else {
            setSelected([...selected, id])
        }
    }

    const onClose = () => {
        setSelected([])
    }

    const onPageChange = (e, p) => {
        setPage(p)
    }

    const onRowsPerPageChange = (e) => {
        setRowsPerPage(e.target.value)
    }

    const renderConfig = () => {
        if (!isUpdateIncidentGroupAllowed) {
            return null
        }

        return (
            <React.Fragment>
                <Divider />
                <Subheader>Configuración</Subheader>
                <Emails emails={emails} />
            </React.Fragment>
        )
    }

    console.log('SHOWCLOSED: ', showClosed)

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button color="primary" component={Link} to="list" startIcon={<ChevronLeft />}>
                        Volver a Grupos de Incidencias
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Paper>
                        <List>
                            <Subheader>Información</Subheader>
                            <Name name={name} />
                            <ListItem>
                                <ListItemText
                                    primary="Fecha de creación"
                                    secondary={new Date().toDateString()}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Identificador" secondary={group} />
                            </ListItem>
                            <Divider />
                            <Subheader>Opciones</Subheader>
                            <ListItem>
                                <ListItemText primary="Mostrar cerradas" />
                                <Switch
                                    checked={showClosed}
                                    onChange={() => setShowClosed(!showClosed)}
                                />
                            </ListItem>
                            {renderConfig()}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Paper>
                        <TableContainer style={{ maxHeight: '70vh' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={
                                                    selected.length > 0 &&
                                                    selected.length < incidents.length
                                                }
                                                checked={
                                                    selected.length > 0 &&
                                                    selected.length === incidents.length
                                                }
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>
                                        <TableCell>Estado</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Nivel</TableCell>
                                        <TableCell>Origen</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {incidents.map((incident) => {
                                        const s = selected.includes(incident.id)

                                        return (
                                            <IncidentRow
                                                key={incident.id}
                                                incident={incident}
                                                selected={s}
                                                onCheck={handleSelect(incident.id)}
                                            />
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            count={count}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={page}
                            onChangePage={onPageChange}
                            onChangeRowsPerPage={onRowsPerPageChange}
                            labelRowsPerPage="Filas por página"
                            labelDisplayedRows={(e) => `${e.from}-${e.to} de ${e.count}`}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Toolbar selected={selected} onClose={onClose} />
        </Container>
    )
}

export default Incidents
