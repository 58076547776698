import React, { useEffect, useMemo, useRef, useState } from 'react'

import Paper from '@material-ui/core/Paper'

import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { useHotkeys } from 'react-hotkeys-hook'

import { setSelectedEntries } from '../../reducers'
import operationTypes from './operations/index'

import { openImageViewer, setSelectedImage } from '../../reducers/image-viewer'

/**
 * rgb type render component
 */
const ImageRenderer = ({ value, node, api, onClick, rowIndex, imgColIndex }) => {
    const dispatch = useDispatch()

    const properties = value.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

    const { thumbnail } = properties

    const height = 120

    setTimeout(() => {
        node.setRowHeight(height)
        api.onRowHeightChanged()
    }, 1000)

    const onClickPress = () => {
        dispatch(openImageViewer({}))
        onClick(rowIndex, imgColIndex)
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div style={{ height }}>
            <img
                src={thumbnail}
                height="100%"
                width="auto"
                onClick={onClickPress}
                alt="Not found"
            />
        </div>
    )
}

ImageRenderer.propTypes = {
    /** Value of the entry in the column */
    value: PropTypes.object.isRequired,
    /** Node value of table api */
    node: PropTypes.any.isRequired,
    /** Api */
    api: PropTypes.any.isRequired,
    /** On click handler */
    onClick: PropTypes.func.isRequired,
    /**  Row index */
    rowIndex: PropTypes.number.isRequired,
    /**  Col index */
    imgColIndex: PropTypes.number.isRequired,
}

/**
 * string type render component
 */
const StringRenderer = ({ value }) => {
    const properties = value.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})
    return properties.value
}

StringRenderer.propTypes = {
    /** Value of the element */
    value: PropTypes.object.isRequired,
}

/**
 * Float type render component
 */
const FloatRenderer = ({ value }) => {
    const properties = value.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})
    return properties.value
}

FloatRenderer.propTypes = {
    /** Value of the element */
    value: PropTypes.object.isRequired,
}

/**
 * Date type render component
 */
const DateRenderer = ({ value }) => {
    const properties = value.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

    const date = new Date(properties.value)

    const hours = `0${date.getHours()}`.slice(-2)
    const minutes = `0${date.getMinutes()}`.slice(-2)
    const seconds = `0${date.getSeconds()}`.slice(-2)

    return `${hours}:${minutes}:${seconds} - ${date.getDate()}/${
        date.getMonth() + 1
    }/${date.getFullYear()}`
}

DateRenderer.propTypes = {
    /** Value of the element */
    value: PropTypes.object.isRequired,
}

const renderMap = {
    string: 'stringRenderer',
    rgb: 'imageRenderer',
    float: 'floatRenderer',
    date: 'dateRenderer',
}
const renders = {
    stringRenderer: StringRenderer,
    imageRenderer: ImageRenderer,
    floatRenderer: FloatRenderer,
    dateRenderer: DateRenderer,
}
Object.keys(operationTypes).forEach((key) => {
    renderMap[key] = key
    renders[key] = operationTypes[key].component
})

let v
let imageViewerShown = false

/**
 * Component responsible for render all the spreadsheet
 */
const Spreadsheet = ({ entries, fields }) => {
    const dispatch = useDispatch()

    const virtualColumnsDefinition = useSelector((state) => state.datasets.viewer.virtualColumns)
    const selectedEntries = useSelector((state) => state.datasets.viewer.selectedEntries)
    const oldVirtualColumnsDefinition = useRef({})

    const [col, setCol] = useState(null)
    const [row, setRow] = useState(null)

    const [ready, setReady] = useState(false)
    const [totalEntries, setTotalEntries] = useState(entries)
    const [totalFields, setTotalFields] = useState(fields)

    const onColumnEverythingChanged = (params) => {
        params.api.sizeColumnsToFit()
    }

    const onSelectionChanged = (params) => {
        const selectedRows = Object.values(params.api.selectionController.selectedNodes)
            .filter((s) => s !== undefined)
            .map((s) => {
                return s.data
            })

        dispatch(setSelectedEntries(selectedRows))
    }

    const cellStyle = { display: 'flex', alignItems: 'center' }

    const gridRef = useRef()

    const cols = useMemo(() => {
        return fields.filter((item) => item.type === 'rgb').length
    }, [fields])

    const firstCol = useMemo(() => {
        return fields.findIndex((item) => item.type === 'rgb')
    }, [fields])

    useHotkeys(
        'd',
        () => {
            if (col !== null) {
                let newCol = col + 1
                if (newCol > cols) {
                    let newRow = row + 1
                    newCol = firstCol
                    if (newRow >= totalEntries.length) {
                        newRow = totalEntries.length - 1
                        newCol = cols - 1
                    }

                    setRow(newRow)
                }

                setCol(newCol)
            }
        },
        [col, cols, row, firstCol, totalEntries.length]
    )

    useHotkeys(
        'a',
        () => {
            if (col !== null) {
                let newCol = col - 1
                if (newCol < firstCol) {
                    let newRow = row - 1
                    newCol = cols

                    if (newRow < 0) {
                        newRow = 0
                        newCol = firstCol
                    }

                    setRow(newRow)
                }

                setCol(newCol)
            }
        },
        [col, cols, row]
    )

    useHotkeys(
        'w',
        () => {
            if (row !== null) {
                let newRow = row - 1
                if (row <= 0) {
                    newRow = 0
                }

                setRow(newRow)
            }
        },
        [row, totalEntries]
    )

    useHotkeys(
        's',
        () => {
            if (row !== null) {
                let newRow = row + 1
                if (newRow >= totalEntries.length - 1) {
                    newRow = totalEntries.length - 1
                }

                setRow(newRow)
            }
        },
        [row, totalEntries]
    )

    useHotkeys(
        'enter',
        () => {
            if (row !== null && col !== null) {
                gridRef.current.api.forEachNode((node) => {
                    if (node.rowIndex === row) {
                        node.setSelected(!node.isSelected())
                    }
                })
            }
        },
        [row, col]
    )

    useEffect(() => {
        if (gridRef.current) {
            const currentCell = gridRef.current.api.getFocusedCell()
            if (currentCell) {
                gridRef.current.api.setFocusedCell(row || 0, currentCell.column)
            }
        }
    }, [row])

    const renderVirtualColumns = (entries) => {
        const operationObjects = {}
        let namedFields = {}
        if (fields.length > 0) {
            namedFields = fields.reduce((prev, next) => {
                return { ...prev, [next.name]: next.type }
            }, {})
        }
        // Check types for operations
        const newFields = []
        Object.entries(virtualColumnsDefinition).forEach((virtualColumn) => {
            // Add virtual column to fields
            const OperationClass = operationTypes[virtualColumn[1].data.type].class
            if (OperationClass.checkData(virtualColumn[1].data.data)) {
                const operationObj = new OperationClass(virtualColumn[0])
                if (!Object.hasOwn(namedFields, virtualColumn[0])) {
                    newFields.push(operationObj.getField())
                }
                operationObjects[virtualColumn[0]] = operationObj
            }
        })

        const newEntries = entries.map((entry) => {
            const entryFields = entry.fields.reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr.name]: curr.properties.reduce((prevProp, currProp) => {
                        return { ...prevProp, [currProp.name]: currProp.value }
                    }, {}),
                }
            }, {})
            Object.entries(virtualColumnsDefinition).forEach((virtualColumn) => {
                // Add virtual column to fields
                const operationObj = operationObjects[virtualColumn[0]]
                const newFieldForEntry = operationObj.call(entryFields, virtualColumn[1].data.data)
                entryFields[virtualColumn[0]] = newFieldForEntry
            })
            const newEntryFields = Object.entries(entryFields).reduce(
                (prev, next) => [
                    ...prev,
                    {
                        name: next[0],
                        properties: Object.entries(next[1]).reduce(
                            (prevProps, nextProps) => [
                                ...prevProps,
                                { name: nextProps[0], value: nextProps[1] },
                            ],
                            []
                        ),
                    },
                ],
                []
            )
            return { ...entry, fields: newEntryFields }
        })
        return [newEntries, newFields]
    }

    useEffect(() => {
        if (v) {
            v.update()
        }
        // Create new entries with the new fields
        const [newEntries, newFields] = renderVirtualColumns(entries)
        setTotalEntries(newEntries)
        setTotalFields([...fields, ...newFields])
        oldVirtualColumnsDefinition.current = virtualColumnsDefinition
    }, [entries, fields, virtualColumnsDefinition])

    const onGridReady = ({ columnApi }) => {
        setReady(true)
        columnApi.autoSizeColumns()
    }

    const renderColumns = () => {
        let imgColIndex = firstCol
        return totalFields.map((f) => {
            if (f.type in renderMap) {
                let cellRendererParams = {
                    onClick: (row, col) => {
                        setCol(col)
                        setRow(row)
                    },
                }

                if (f.type === 'rgb') {
                    cellRendererParams = {
                        ...cellRendererParams,
                        imgColIndex: imgColIndex++,
                    }
                }

                return (
                    <AgGridColumn
                        suppressSizeToFit
                        key={f.name}
                        field={f.name}
                        cellRenderer={renderMap[f.type]}
                        cellStyle={cellStyle}
                        autoHeight
                        wrapText
                        resizable
                        cellRendererParams={cellRendererParams}
                    />
                )
            }
            return (
                <AgGridColumn
                    key={f.name}
                    field={f.name}
                    cellStyle={cellStyle}
                    autoHeight
                    suppressSizeToFit
                    resizable
                />
            )
        })
    }
    const rowData = totalEntries.map((entry) => {
        const createdAt = new Date(entry.createdAt)

        const hours = `0${createdAt.getHours()}`.slice(-2)
        const minutes = `0${createdAt.getMinutes()}`.slice(-2)
        const seconds = `0${createdAt.getSeconds()}`.slice(-2)

        const d = `${hours}:${minutes}:${seconds}  -  ${createdAt.getDate()}/${
            createdAt.getMonth() + 1
        }/${createdAt.getFullYear()}`

        return entry.fields.reduce(
            (acc, item) => ({
                ...acc,
                id: entry.id,
                createdAt: d,
                [item.name]: item.properties,
            }),
            {}
        )
    })

    useEffect(() => {
        const r = entries[row] || { fields: [] }
        const c = r.fields[col] || { properties: [] }
        const p = c.properties.find((p) => p.name === 'url') || {}
        dispatch(
            setSelectedImage({
                url: p.value,
            })
        )
    }, [col, row, entries])

    return (
        <div className="ag-theme-alpine" style={{ width: '100%', height: '100%' }}>
            <AgGridReact
                ref={gridRef}
                rowData={rowData}
                rowHeight={120}
                onColumnEverythingChanged={onColumnEverythingChanged}
                frameworkComponents={renders}
                rowSelection="multiple"
                onSelectionChanged={onSelectionChanged}
                onFirstDataRendered={onGridReady}
            >
                <AgGridColumn
                    checkboxSelection
                    headerCheckboxSelection
                    field="createdAt"
                    maxWidth={200}
                    cellStyle={cellStyle}
                    autoHeight
                    suppressSizeToFit
                    resizable
                />
                {renderColumns()}
            </AgGridReact>
            <Paper style={{ position: 'fixed', width: '100%', height: 70 }}></Paper>
        </div>
    )
}

Spreadsheet.propTypes = {
    /** Entries */
    entries: PropTypes.array.isRequired,
    /** Columns */
    fields: PropTypes.array.isRequired,
}

export default Spreadsheet
