import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const ToggleSettings = ({ query, actions, id }) => {
    const node = query.node(id).get()

    /* eslint-disable no-unused-vars,no-param-reassign */
    const onIdChange = (e) => {
        actions.setProp(id, (p) => {
            p._id = e.target.value
        })
    }

    const onContentChange = (e) => {
        actions.setProp(id, (p) => {
            p.content = e.target.value
        })
    }

    const onFontSizeChange = (e) => {
        actions.setProp(id, (p) => {
            p.fontSize = parseInt(e.target.value, 10)
        })
    }

    const onTextAlignChange = (e) => {
        actions.setProp(id, (p) => {
            p.textAlign = e.target.value
        })
    }

    return (
        <List>
            <ListItem>
                <TextField
                    label="ID"
                    onChange={onIdChange}
                    defaultValue={node.data.props._id || id}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField label="Contenido" onChange={onContentChange} fullWidth />
            </ListItem>
            <ListItem>
                <TextField
                    label="Tamaño"
                    type="number"
                    step="1"
                    min="0"
                    onChange={onFontSizeChange}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Alinear Texto</InputLabel>
                    <Select onChange={onTextAlignChange}>
                        <MenuItem value="left">Izquierda</MenuItem>
                        <MenuItem value="center">Centrado</MenuItem>
                        <MenuItem value="right">Derecha</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    )
}

ToggleSettings.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default ToggleSettings
