const SET_INFERENCE_ENABLED = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_ENABLED'
const SET_INFERENCE_MODEL = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_MODEL'
const SET_INFERENCE_MODEL_SHAPE = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_MODEL_SHAPE'
const SET_INFERENCE_MODEL_CLASSES = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_MODEL_CLASSES'
const SET_INFERENCE_DELIVERABLE = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_DELIVERABLE'
const SET_INFERENCE_CONFIDENCE_THRESHOLD =
    'FALCON/DATASETS/VIEWER/SET_INFERENCE_CONFIDENCE_THRESHOLD'
const SET_INFERENCE_CLASS_THRESHOLD = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_CLASS_THRESHOLD'
const SET_INFERENCE_NMS_THRESHOLD = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_NMS_THRESHOLD'
const SET_INFERENCE_FONT_SIZE = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_FONT_SIZE'
const SET_INFERENCE_DEVICE = 'FALCON/DATASETS/VIEWER/SET_INFERENCE_DEVICE'

export const setInferenceEnabled = (inferenceEnabled) => ({
    type: SET_INFERENCE_ENABLED,
    inferenceEnabled,
})

export const setInferenceModel = (inferenceModel) => ({
    type: SET_INFERENCE_MODEL,
    inferenceModel,
})

export const setInferenceModelShape = (inferenceModelShape) => ({
    type: SET_INFERENCE_MODEL_SHAPE,
    inferenceModelShape,
})

export const setInferenceModelClasses = (inferenceModelClasses) => ({
    type: SET_INFERENCE_MODEL_CLASSES,
    inferenceModelClasses,
})

export const setInferenceDeliverable = (params) => ({
    type: SET_INFERENCE_DELIVERABLE,
    ...params,
})

export const setInferenceConfidenceThreshold = (inferenceConfidenceThreshold) => ({
    type: SET_INFERENCE_CONFIDENCE_THRESHOLD,
    inferenceConfidenceThreshold,
})

export const setInferenceClassThreshold = (inferenceClassThreshold) => ({
    type: SET_INFERENCE_CLASS_THRESHOLD,
    inferenceClassThreshold,
})

export const setInferenceNmsThreshold = (inferenceNmsThreshold) => ({
    type: SET_INFERENCE_NMS_THRESHOLD,
    inferenceNmsThreshold,
})

export const setInferenceFontSize = (inferenceFontSize) => ({
    type: SET_INFERENCE_FONT_SIZE,
    inferenceFontSize,
})

export const setInferenceDevice = (inferenceDevice) => ({
    type: SET_INFERENCE_DEVICE,
    inferenceDevice,
})

export default (
    state = {
        inferenceEnabled: false,
        inferenceModel: null,
        inferenceDeliverable: null,
        inferenceDeliverableUrl: null,
        inferenceModelShape: null,
        inferenceModelClasses: [],
        inferenceConfidenceThreshold: 0.5,
        inferenceClassThreshold: 0.5,
        inferenceNmsThreshold: 0.5,
        inferenceFontSize: 20,
        inferenceDevice: 'cpu',
    },
    action
) => {
    switch (action.type) {
        case SET_INFERENCE_ENABLED:
            return {
                ...state,
                inferenceEnabled: action.inferenceEnabled,
            }
        case SET_INFERENCE_MODEL:
            return {
                ...state,
                inferenceModel: action.inferenceModel,
            }
        case SET_INFERENCE_MODEL_SHAPE:
            return {
                ...state,
                inferenceModelShape: action.inferenceModelShape,
            }
        case SET_INFERENCE_MODEL_CLASSES:
            return {
                ...state,
                inferenceModelClasses: action.inferenceModelClasses,
            }
        case SET_INFERENCE_DELIVERABLE:
            return {
                ...state,
                inferenceDeliverable: action.inferenceDeliverable,
                inferenceDeliverableUrl: action.inferenceDeliverableUrl,
            }
        case SET_INFERENCE_CONFIDENCE_THRESHOLD:
            return {
                ...state,
                inferenceConfidenceThreshold: action.inferenceConfidenceThreshold,
            }
        case SET_INFERENCE_CLASS_THRESHOLD:
            return {
                ...state,
                inferenceClassThreshold: action.inferenceClassThreshold,
            }
        case SET_INFERENCE_NMS_THRESHOLD:
            return {
                ...state,
                inferenceNmsThreshold: action.inferenceNmsThreshold,
            }
        case SET_INFERENCE_FONT_SIZE:
            return {
                ...state,
                inferenceFontSize: action.inferenceFontSize,
            }
        case SET_INFERENCE_DEVICE:
            return {
                ...state,
                inferenceDevice: action.inferenceDevice,
            }
        default:
            return state
    }
}
