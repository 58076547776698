import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useParams, Link } from 'react-router-dom'

import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { GET_HMIS_BY_GROUP } from '../../queries/hmis'

const HMIsList = () => {
    const { group } = useParams()

    const { data: { getHMIsByGroup: { hmis = [] } = {} } = {} } = useQuery(GET_HMIS_BY_GROUP, {
        variables: { params: { group } },
    })

    return (
        <React.Fragment>
            {hmis.length !== 0 && (
                <Paper>
                    <List>
                        {hmis.map((hmi) => (
                            <Link to={`${group}/hmi/${hmi._id}`} key={hmi._id}>
                                <ListItem>
                                    <ListItemText primary={hmi.name} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </Paper>
            )}
            {hmis.length === 0 && (
                <div style={{ margin: '20px', textAlign: 'center' }}>
                    Cree un HMI para continuar &#8600;
                </div>
            )}
        </React.Fragment>
    )
}

export default HMIsList
