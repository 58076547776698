import gql from 'graphql-tag'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { useEffect, useState } from 'react'

const COMPLETE_WORKFLOW_TYPE_FRAGMENTS = gql`
    fragment MetadataComplete on JobsMetadata {
        field
        value
    }

    fragment PortComplete on JobsPort {
        id
        dataType {
            name
            metadata {
                ...MetadataComplete
            }
        }
        metadata {
            ...MetadataComplete
        }
        description
    }

    fragment NodeComplete on JobsNode {
        id
        nodeId
        nodeVersion
        type
        input {
            ...PortComplete
        }
        output {
            ...PortComplete
        }
        metadata {
            ...MetadataComplete
        }
        description
    }
    fragment WorkflowComplete on JobsWorkflow {
        _id
        schemeVersion
        organization
        name
        description
        workflowId
        revision
        engine
        state
        nodes {
            ...NodeComplete
        }
        links {
            nodeFrom {
                nodeId
                port {
                    ...PortComplete
                }
            }
        }
        inputPorts {
            ...PortComplete
        }
        outputPorts {
            ...PortComplete
        }
        tags {
            text
            color
        }
        createdAt
        updatedAt
        metadata {
            ...MetadataComplete
        }
    }
`

const COMPLETE_WORKFLOW_RUN_TYPE_FRAGMENTS = gql`
    fragment PortRunComplete on JobsPortRun {
        id
        value
    }

    fragment NodeRunComplete on JobsNodeRun {
        id
        nodeId
        state
        logs
        startedAt
        finishedAt
    }
    fragment WorkflowRunComplete on JobsWorkflowRun {
        _id
        schemeVersion
        organization
        state
        state
        nodes {
            ...NodeRunComplete
        }
        inputs {
            ...PortRunComplete
        }
        outputs {
            ...PortRunComplete
        }
        createdAt
        updatedAt
        finishedAt
    }
`

const WORKFLOW_TYPE_FRAGMENTS = gql`
    fragment WorkflowInfo on JobsWorkflow {
        name
        workflowId
        state
        engine
        createdAt
        updatedAt
    }
`

const WORKFLOW_RUN_TYPE_FRAGMENTS = gql`
    fragment WorkflowRunInfo on JobsWorkflowRun {
        _id
        state
        createdAt
        updatedAt
    }
`

// Check if is jobs is activated
const IS_JOBS_ACTIVATED = gql`
    query {
        jobsIsActivated
    }
`

export const useJobsIsActivated = () => {
    const [activated, setActivated] = useState(false)

    const {
        data: { jobsIsActivated } = {},
        loading,
        error,
    } = useQuery(IS_JOBS_ACTIVATED, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    })

    useEffect(() => {
        if (!activated) {
            setActivated(jobsIsActivated)
        }
    }, [jobsIsActivated])

    return { isActivated: activated, loading, error }
}

// Activate jobs infrastructure
const ACTIVATE_JOBS = gql`
    mutation {
        jobsActivate
    }
`

export const useJobsActivate = () => {
    const [activateJobs] = useMutation(ACTIVATE_JOBS, {
        refetchQueries: [{ query: IS_JOBS_ACTIVATED }],
        awaitRefetchQueries: true,
    })

    return async () => {
        await activateJobs()
    }
}

// Deactivate jobs infrastructure
const DEACTIVATE_JOBS = gql`
    mutation {
        jobsDeactivate
    }
`
export const useJobsDeactivate = () => {
    const [deactivateJobs] = useMutation(DEACTIVATE_JOBS, {
        refetchQueries: [{ query: IS_JOBS_ACTIVATED }],
        awaitRefetchQueries: true,
    })

    return async () => {
        await deactivateJobs()
    }
}

// List workflows
const LIST_WORKFLOW_JOBS = gql`
    ${WORKFLOW_TYPE_FRAGMENTS}
    query jobsGetByWorkflow($by: JobsInputWorkflow) {
        jobsGetByWorkflow(by: $by) {
            ...WorkflowInfo
        }
    }
`
export const useJobsList = (by) => {
    const [jobsList, setJobsList] = useState(undefined)

    const {
        data: { jobsGetByWorkflow } = {},
        loading,
        error,
    } = useQuery(LIST_WORKFLOW_JOBS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: { by },
    })

    useEffect(() => {
        if (jobsGetByWorkflow !== undefined && jobsGetByWorkflow.length > 0) {
            setJobsList(jobsGetByWorkflow)
        }
    }, [jobsGetByWorkflow])
    return { jobsList, loading, error }
}

// Get detailed workflow
const GET_WORKFLOW_JOBS = gql`
    ${COMPLETE_WORKFLOW_TYPE_FRAGMENTS}
    query jobsGetByWorkflow($by: JobsInputWorkflow) {
        jobsGetByWorkflow(by: $by) {
            ...WorkflowComplete
        }
    }
`
export const useJobsDetails = (by) => {
    const [jobsList, setJobsList] = useState(undefined)

    const {
        data: { jobsGetByWorkflow } = {},
        loading,
        error,
    } = useQuery(GET_WORKFLOW_JOBS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: { by },
    })

    useEffect(() => {
        if (jobsGetByWorkflow !== undefined && jobsGetByWorkflow.length > 0) {
            console.log(jobsGetByWorkflow)
            setJobsList(jobsGetByWorkflow)
        }
    }, [jobsGetByWorkflow])
    return { jobsList, loading, error }
}

// Create workflow
const CREATE_WORKFLOW_JOBS = gql`
    mutation jobsCreateWorkflow($name: String, $description: String) {
        jobsCreateWorkflow(name: $name, description: $description) {
            workflowId
        }
    }
`
export const useJobsCreateWorkflow = () => {
    const [createWorkflow] = useMutation(CREATE_WORKFLOW_JOBS, {
        refetchQueries: [{ query: LIST_WORKFLOW_JOBS, variables: { by: {} } }],
        awaitRefetchQueries: true,
    })

    return async (name, description) => {
        await createWorkflow({ variables: { name, description } })
    }
}

// Update workflow
const UPDATE_WORKFLOW_JOBS = gql`
    mutation jobsUpdateWorkflow($by: JobsInputWorkflow, $update: JobsInputWorkflow) {
        jobsUpdateWorkflow(by: $by, update: $update) {
            workflowId
        }
    }
`
export const useJobsUpdateWorkflow = () => {
    const [updateWorkflow] = useMutation(UPDATE_WORKFLOW_JOBS)

    return async (by, update) => {
        await updateWorkflow({ variables: { by, update } })
    }
}

// List workflow runs
const LIST_WORKFLOW_RUN_JOBS = gql`
    ${WORKFLOW_RUN_TYPE_FRAGMENTS}
    query jobsGetByWorkflowRun($engine: JOBS_ENGINES!, $filters: JobsInputWorkflowRunsFilters) {
        jobsGetByWorkflowRun(engine: $engine, filters: $filters) {
            ...WorkflowRunInfo
        }
    }
`
export const useJobsRunList = (engine, filters) => {
    const [jobsRunList, setJobsRunList] = useState([])

    const {
        data: { jobsGetByWorkflowRun } = [],
        loading,
        error,
    } = useQuery(LIST_WORKFLOW_RUN_JOBS, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: { engine, filters },
    })

    useEffect(() => {
        if (jobsGetByWorkflowRun !== undefined && jobsGetByWorkflowRun.length > 0) {
            setJobsRunList(jobsGetByWorkflowRun)
        }
    }, [jobsGetByWorkflowRun])
    return { jobsRunList, loading, error }
}

// Get detailed workflow runs
const GET_DETAILED_RUN_JOB = gql`
    ${COMPLETE_WORKFLOW_RUN_TYPE_FRAGMENTS}
    query jobsGetDetailedWorkflowRun($engine: JOBS_ENGINES!, $name: String) {
        jobsGetDetailedWorkflowRun(engine: $engine, name: $name) {
            ...WorkflowRunComplete
        }
    }
`
export const useJobsRunGetDetailed = (engine, name) => {
    const [jobsRun, setJobsRun] = useState(undefined)

    const {
        data: { jobsGetDetailedWorkflowRun } = {},
        loading,
        error,
    } = useQuery(GET_DETAILED_RUN_JOB, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        variables: { engine, name },
    })

    useEffect(() => {
        if (jobsGetDetailedWorkflowRun !== undefined || jobsGetDetailedWorkflowRun !== {}) {
            setJobsRun(jobsGetDetailedWorkflowRun)
        }
    }, [jobsGetDetailedWorkflowRun])
    return { jobsRun, loading, error }
}

// Run workflow
const RUN_WORKFLOW_JOBS = gql`
    mutation jobsRunWorkflow($by: JobsInputWorkflow, $parameters: [JobsInputWorkflowParameters]) {
        jobsRunWorkflow(by: $by, parameters: $parameters)
    }
`
export const useJobsRunWorkflow = (engine, workflowId) => {
    const [updateWorkflow] = useMutation(RUN_WORKFLOW_JOBS, {
        refetchQueries: [
            { query: LIST_WORKFLOW_RUN_JOBS, variables: { engine, filters: { workflowId } } },
        ],
        awaitRefetchQueries: true,
    })

    return async (by, parameters) => {
        await updateWorkflow({ variables: { by, parameters } })
    }
}
