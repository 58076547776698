import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FlowChart, PortDefault, IPortDefaultProps } from '@mrblenny/react-flow-chart'
import styled from 'styled-components'
import theme from 'theme'

import NodeInner from '../functions/node'
import Link from '../functions/links'

import {
    moveNode as moveNodeRedux,
    moveCanvas as moveCanvasRedux,
    completeLink as completeLinkRedux,
    moveLink as moveLinkRedux,
    startLink as startLinkRedux,
    changeNodeSize as changeNodeSizeRedux,
    changePortPosition as changePortPositionRedux,
    selectNode as selectNodeRedux,
    removeLink as removeLinkRedux,
} from '../../redux/nodes'

const PortDefaultOuter = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover > div {
        background: cornflowerblue;
    }
`

const PortCustom = ({
    isHovered,
    isLinkHovered,
    isLinkSelected,
    isSelected,
    port,
    config,
    className,
}) => {
    const activePort = isHovered || isLinkHovered || isLinkSelected || isSelected
    if (port.properties) {
        if (port.properties.secondType) {
            return (
                <PortDefaultOuter className={className} config={config}>
                    {port.properties.secondType === 'if-true' && (
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                background: activePort
                                    ? theme.palette.success.light
                                    : theme.palette.success.main,
                            }}
                        />
                    )}
                    {port.properties.secondType === 'if-false' && (
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                background: activePort
                                    ? theme.palette.error.light
                                    : theme.palette.error.main,
                            }}
                        />
                    )}
                </PortDefaultOuter>
            )
        }
    }
    return (
        <PortDefault
            {...{
                isHovered,
                isLinkHovered,
                isLinkSelected,
                isSelected,
                port,
                config,
                className,
            }}
        />
    )
}

PortCustom.propTypes = IPortDefaultProps

// FIXME: each time a selectNode occur a move node occur too.
const GraphView = () => {
    const dispatch = useDispatch()

    const [scale, setScale] = useState(1)

    const canvasOffset = useSelector((state) => state.designer.nodes.offset)
    const selectedNode = useSelector((state) => state.designer.nodes.selected_node)
    const { nodes = {}, links = {} } = useSelector((state) => state.designer.nodes)

    const callbacks = {
        // Canvas callbackd
        onZoomCanvas: (event) => {
            setScale(event.data.scale)

            return event
        },
        onCanvasClick: () => {
            dispatch(selectNodeRedux(null))
        },
        onDragCanvasStop: (event) => {
            dispatch(
                moveCanvasRedux({
                    positionX: event.data.positionX,
                    positionY: event.data.positionY,
                })
            )
        },
        onDragCanvas: (event) => event,

        onDeleteKey: (event) => event,

        // Node callbacks
        onDragNode: (event) => event,
        onDragNodeStop: (event) => {
            dispatch(moveNodeRedux({ id: event.id, data: { x: event.data.x, y: event.data.y } }))
        },
        onNodeSizeChange: (event) => {
            dispatch(changeNodeSizeRedux({ nodeId: event.nodeId, size: event.size }))
        },
        onPortPositionChange: (event) => {
            dispatch(changePortPositionRedux(event))
        },
        onNodeClick: (event) => {
            dispatch(selectNodeRedux(event.nodeId))
        },
        onNodeMouseEnter: (event) => event,
        onNodeMouseLeave: (event) => event,

        // Link callbacks
        onLinkClick: (event) => event,
        onLinkComplete: (event) => {
            dispatch(
                completeLinkRedux({
                    linkId: event.linkId,
                    toNodeId: event.toNodeId,
                    toPortId: event.toPortId,
                })
            )
        },
        onLinkMouseEnter: (event) => event,
        onLinkMouseLeave: (event) => event,
        onLinkMove: (event) => {
            dispatch(moveLinkRedux({ linkId: event.linkId, toPosition: event.toPosition }))
        },
        onLinkStart: (event) => {
            dispatch(
                startLinkRedux({
                    linkId: event.linkId,
                    fromNodeId: event.fromNodeId,
                    fromPortId: event.fromPortId,
                })
            )
        },
        onLinkCancel: (event) => {
            dispatch(removeLinkRedux(event.linkId))
        },
    }

    const chart = {
        scale,
        offset: canvasOffset,
        nodes: useMemo(
            () =>
                Object.keys(nodes).reduce((acc, node) => {
                    return {
                        ...acc,
                        [nodes[node].id]: {
                            id: nodes[node].id,
                            type: nodes[node].type,
                            position: {
                                x: Number(Math.round(nodes[node].position.x)),
                                y: Number(Math.round(nodes[node].position.y)),
                            },
                            size: nodes[node].size,
                            properties: nodes[node].properties,
                            ports: nodes[node].ports.reduce((prev, port, i) => {
                                return {
                                    ...prev,
                                    [i.toString()]: {
                                        ...port,
                                        id: i.toString(),
                                    },
                                }
                            }, {}),
                        },
                    }
                }, {}),
            [nodes]
        ),
        links: useMemo(
            () =>
                Object.keys(links).reduce((acc, link) => {
                    return {
                        ...acc,
                        [links[link].id]: {
                            id: links[link].id,
                            from: {
                                nodeId: links[link].from.nodeId,
                                portId: links[link].from.portId,
                            },
                            to: {
                                nodeId: links[link].to.nodeId,
                                portId: links[link].to.portId,
                                position: links[link].to.position,
                            },
                        },
                    }
                }, {}),
            [links]
        ),
        selected: {
            id: selectedNode,
            type: 'node',
        },
        hovered: {},
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflow: 'hidden',
            }}
        >
            <FlowChart
                chart={chart}
                callbacks={callbacks}
                Components={{
                    Port: PortCustom,
                    NodeInner,
                    Link,
                }}
            />
        </div>
    )
}

export default GraphView
