import React from "react"

import { useApolloClient } from "@apollo/react-hooks"

import Grid from "@material-ui/core/Grid"

import ImageViewer from "./containers/image-viewer"
import ImagesMenu from "./containers/images-menu"
import LabelsMenu from "./containers/labels-menu"
import AddEntriesDialog from "./containers/add-entries-dialog"

import resolvers from "./resolvers"

function Classifier(props){
    const client = useApolloClient()

    client.addResolvers(resolvers)

    return (
        <Grid container>
            <Grid item md={10} xs={12}><ImageViewer /></Grid>
            <Grid item md={2}  xs={12}><LabelsMenu/></Grid>
            <Grid item xs={12}><ImagesMenu /></Grid>
            <div style={{ position: "fixed", bottom: 80, right: 20 }}>
                <AddEntriesDialog/>
            </div>
        </Grid>
    )
}

export default Classifier