import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'

import ColorPicker from 'material-ui-color-picker'

const ContainerSettings = ({ query, actions, id }) => {
    const node = query.node(id).get()

    const [color, setColor] = useState(node.data.props.background)

    const [padding, setPadding] = useState(
        node.data.props.padding
            .split('px')
            .map((p) => p.trim())
            .filter((p) => p)
    )

    /* eslint-disable no-param-reassign */
    const onPaddingChange = (i) => (e) => {
        const pad = padding
        pad[i] = e.target.value
        actions.setProp(id, (p) => {
            p.padding = `${pad.join('px ')}px`
        })
        setPadding(pad)
    }

    const onBackgroundChange = (colorBack) => {
        setColor(colorBack)
        actions.setProp(id, (p) => {
            p.background = colorBack
        })
    }

    return (
        <List>
            <ListItem>
                <Grid container spacing={1}>
                    {padding.map((p, i) => (
                        <Grid item key={i} xs={3}>
                            <TextField
                                defaultValue={p}
                                label="Padding"
                                type="number"
                                step="1"
                                min="0"
                                onChange={onPaddingChange(i)}
                                fullWidth
                            />
                        </Grid>
                    ))}
                </Grid>
            </ListItem>
            <ListItem>
                <div style={{ width: '100%' }}>
                    <ColorPicker
                        floatingLabelText="Fondo"
                        name="color"
                        value={color}
                        InputProps={{ value: color }}
                        onChange={onBackgroundChange}
                        fullWidth
                    />
                </div>
            </ListItem>
        </List>
    )
}

ContainerSettings.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default ContainerSettings
