import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'

const ScrollSettings = ({ query, actions, id }) => {
    const node = query.node(id).get()

    /* eslint-disable no-param-reassign */
    const onIdChange = (e) => {
        actions.setProp(id, (p) => {
            p._id = e.target.value
        })
    }

    const onHeightChange = (e) => {
        actions.setProp(id, (p) => {
            p.height = e.target.value
        })
    }

    const onWidthChange = (e) => {
        actions.setProp(id, (p) => {
            p.width = e.target.value
        })
    }

    return (
        <List>
            <ListItem>
                <TextField
                    label="ID"
                    onChange={onIdChange}
                    defaultValue={node.data.props._id || id}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    defaultValue={node.data.props.height}
                    label="Height"
                    onChange={onHeightChange}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    defaultValue={node.data.props.width}
                    label="Width"
                    onChange={onWidthChange}
                    fullWidth
                />
            </ListItem>
        </List>
    )
}

ScrollSettings.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default ScrollSettings
