export const CHANGE_CURRENT_DATASET = "@DASHBOARDS/BAR_CHART/CHANGE_CURRENT_DATASET"

export const changeCurrentDataset = (dataset) => ({ type: CHANGE_CURRENT_DATASET, dataset })


export const ADD_DIMENSION = "@DASHBOARDS/BAR_CHART/ADD_DIMENSION"
export const REMOVE_DIMENSION = "@DASHBOARDS/BAR_CHAR/REMOVE_DIMENSION"
export const SET_DIMENSION_FIELD = "@DASHBOARDS/BAR_CHAR/SET_DIMENSION_FIELD"
export const SET_DIMENSION_BUCKET = "@DASHBOARDS/BAR_CHAR/SET_DIMENSION_BUCKET"

export const addDimension = () => ({ type: ADD_DIMENSION })
export const removeDimension = (index) => ({ type: REMOVE_DIMENSION, index })
export const setDimensionField = (index, field) => ({ type: SET_DIMENSION_FIELD, index, field })
export const setDimensionBucket = (index, bucket) => ({ type: SET_DIMENSION_BUCKET, index, bucket })


export const ADD_METRIC = "@DASHBOARDS/BAR_CHART/ADD_METRIC"
export const REMOVE_METRIC = "@DASHBOARDS/BAR_CHART/REMOVE_METRIC"
export const SET_METRIC_FIELD = "@DASHBOARDS/BAR_CHAR/SET_METRIC_FIELD"
export const SET_METRIC_AGGREGATION = "@DASHBOARDS/BAR_CHAR/SET_AGGREGATION_FIELD"

export const addMetric = () => ({ type: ADD_METRIC })
export const removeMetric = index => ({ type: REMOVE_METRIC, index })
export const setMetricField = (index, field) => ({ type: SET_METRIC_FIELD, index, field })
export const setMetricAggregation = (index, aggregation) => ({ type: SET_METRIC_AGGREGATION, index, aggregation })

export const SELECT_ITEM = "@DASHBOARDS/BAR_CHART/SELECT_ITEM"

export const selectItem = (itemType, index) => ({ type: SELECT_ITEM, itemType, index })

export default (state = { current_dataset: null, selected_item: { type: "default", index: null }, dimensions: [], metrics: []}, action) => {
    switch (action.type) {
        case CHANGE_CURRENT_DATASET:
            return { ...state, current_dataset: action.dataset }
        case ADD_DIMENSION:
            return {
                ...state,
                dimensions: [ 
                    ...state.dimensions,
                    {
                        field: ""
                    }
                ]
            }
        case REMOVE_DIMENSION:
            return {
                ...state,
                dimensions: [
                    ...state.dimensions.slice(0, action.index),
                    ...state.dimensions.slice(action.index + 1)
                ]
            }
        case SET_DIMENSION_FIELD:
            return {
                ...state,
                dimensions: [
                    ...state.dimensions.slice(0, action.index),
                    {
                        ...state.dimensions[action.index],
                        field: action.field
                    },
                    ...state.dimensions.slice(action.index + 1)
                ]
            }
        case SET_DIMENSION_BUCKET:
            return {
                ...state,
                dimensions: [
                    ...state.dimensions.slice(0, action.index),
                    {
                        ...state.dimensions[action.index],
                        bucket: action.bucket
                    },
                    ...state.dimensions.slice(action.index + 1)
                ]
            }
        case ADD_METRIC:
            return {
                ...state,
                metrics: [
                    ...state.metrics,
                    {
                        field: ""
                    }
                ]
            }
        case REMOVE_METRIC:
            return {
                ...state,
                metrics: [
                    ...state.metrics.slice(0, action.index),
                    ...state.metrics.slice(action.index + 1)
                ]
            }
        case SET_METRIC_FIELD:
            return {
                ...state,
                metrics: [
                    ...state.metrics.slice(0, action.index),
                    {
                        ...state.metrics[action.index],
                        field: action.field
                    },
                    ...state.metrics.slice(action.index + 1)
                ]
            }
        case SET_METRIC_AGGREGATION:
            return {
                ...state,
                metrics: [
                    ...state.metrics.slice(0, action.index),
                    {
                        ...state.metrics[action.index],
                        aggregation: action.aggregation
                    },
                    ...state.metrics.slice(action.index + 1)
                ]
            }
        case SELECT_ITEM:
            return {
                ...state,
                selected_item: {
                    ...state.selected_item,
                    type: action.itemType,
                    index: action.index
                }
            }
        default:
            return state
    }
}