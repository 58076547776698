import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid"

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import placeholder from "static/image-placeholder.png"
import Popover from '@material-ui/core/Popover';

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const useStyles = makeStyles({
    root: {
        maxWidth: 400,
    },
    media: {
        minHeight: 300,
        height: "100%",
        maxHeight: 400
    },
});

export default (props) => {

    const classes = useStyles();

    const { dataset: datasetId } = useParams()

    const { entries, fields } = props

    const [ open, setOpen ] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [ url, setUrl ] = useState("")

    const onClick = u => e => {
        setAnchorEl(e.currentTarget)
        setOpen(true)
        setUrl(u)
    }

    const handleClose = () => {
        setOpen(false)
        setAnchorEl(null)
    }
    
    const rowData = entries.map(entry => {
        return entry.fields.reduce((acc, item) => ({...acc, [item.name]: item.properties}), {})
    })

    return (
        <Grid container spacing={2} style={{ padding: 30 }}>
            {
                rowData.map(entry => {


                    let url = "/" + placeholder
                    
                    const images = fields.filter(f => {
                        return f.type === "rgb"
                    })

                    const filtered = fields.filter(f => {
                        return f.type !== "rgb"
                    })
                    
                    return (
                        <Grid item xs={2}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <Carousel showArrows={true} showThumbs={false}>
                                        {
                                            images.map(img => {
                                                const properties = entry[img.name].reduce((acc, item) => ({...acc,[item.name]:item.value}), {})
                                                
                                                return (
                                                    <div onClick={onClick(properties["url"])}>
                                                        <img src={properties["thumbnail"]} />
                                                        <p className="legend">{img.name}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    <CardContent>
                                        <List>
                                            {filtered.map(item => {
                                                const properties = entry[item.name].reduce((acc, item) => ({...acc,[item.name]:item.value}), {})

                                                return (
                                                    <ListItem>
                                                        <ListItemText primary={item.name} secondary={properties["value"]} />
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )
                })
            }
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <img src={url} height={600} width={"auto"} />
            </Popover>
        </Grid>        
    )
}