import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import ListSubheader from '@material-ui/core/ListSubheader'

import { CREATE_DATASET_V2 } from '../../../../queries/datasets'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(3),
    },
}))

export default (props) => {
    const classes = useStyles()

    const [createDatasetV2] = useMutation(CREATE_DATASET_V2)

    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const [name, setName] = useState(null)
    const [fields, setFields] = useState([])

    const onOpenDialogClicked = () => setOpen(true)
    const onNameTextFieldChanged = (e) => setName(e.target.value)
    const onCancelClicked = () => {
        setOpen(false)
        setName('')
        setLoading(false)
        setFields([])
    }

    const onAcceptClicked = async () => {
        setLoading(true)
        if (!fields.some((f) => !f.name || !f.type)) {
            await createDatasetV2({
                variables: {
                    params: {
                        name,
                        parent: null,
                        type: 'table',
                        fields,
                    },
                },
            })
        }
        setLoading(false)
        setOpen(false)
    }

    const onNewFieldClicked = () => {
        setFields([...fields, { name: '', type: '' }])
    }

    const onFieldNameChange = (i) => (e) => {
        setFields([
            ...fields.slice(0, i),
            {
                ...fields[i],
                name: e.target.value,
            },
            ...fields.slice(i + 1),
        ])
    }

    const onFieldTypeChange = (i) => (e) => {
        setFields([
            ...fields.slice(0, i),
            {
                ...fields[i],
                type: e.target.value,
            },
            ...fields.slice(i + 1),
        ])
    }

    return (
        <React.Fragment>
            <Dialog open={open} maxWidth={'lg'}>
                <DialogTitle>Nuevo Dataset</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Nombre"
                        fullWidth
                        onChange={onNameTextFieldChanged}
                        value={name}
                        disabled={loading}
                    />
                    <ListSubheader disableSticky={true}>Campos</ListSubheader>
                    {fields.map((f, i) => {
                        return (
                            <Card elevation={3} style={{ marginBottom: 5, minWidth: 500 }}>
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        label="Nombre"
                                        value={f.name}
                                        onChange={onFieldNameChange(i)}
                                    ></TextField>
                                    <FormControl fullWidth>
                                        <InputLabel>Tipo</InputLabel>
                                        <Select
                                            value={f.type}
                                            onChange={onFieldTypeChange(i)}
                                            disabled={loading}
                                        >
                                            <MenuItem value={''}></MenuItem>
                                            <MenuItem value={'boolean'}>Booleano</MenuItem>
                                            <MenuItem value={'string'}>Texto</MenuItem>
                                            <MenuItem value={'integer'}>Entero</MenuItem>
                                            <MenuItem value={'float'}>Decimal</MenuItem>
                                            <MenuItem value={'rgb'}>Imagen RGB</MenuItem>
                                            <MenuItem value={'date'}>Fecha</MenuItem>
                                        </Select>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        )
                    })}
                    <Button onClick={onNewFieldClicked} style={{ marginTop: 10 }} fullWidth>
                        Nuevo Campo
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAcceptClicked} disabled={loading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Fab className={classes.fab} color="primary" onClick={onOpenDialogClicked}>
                <AddIcon />
            </Fab>
        </React.Fragment>
    )
}
