import React, { useMemo } from 'react'
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import SecurityIcon from '@material-ui/icons/Security'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'

import { useIsAllowed } from 'queries/permissions'
import Users from './containers/users/scene'
import Roles from './containers/roles/scene'
import Services from './containers/services/scene'
import LoadingBackdrop from '../../../../components/LoadingBackdrop'

const useStyles = makeStyles(() => ({
    leftContainer: {
        height: 'calc(100vh - 64px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        borderRight: '1px solid #e0e0e0',
    },
    leftContainerPaper: {
        height: '100%',
    },
}))

const Settings = () => {
    const classes = useStyles()
    const match = useRouteMatch()
    const history = useHistory()

    const { section } = match.params

    const { isAllowed: isGetUsersAllowed, loading: isGetUsersAllowedLoading } = useIsAllowed({
        group: 'settings',
        action: 'getUsers',
    })
    const { isAllowed: isGetRolesAllowed, loading: isGetRolesAllowedLoading } = useIsAllowed({
        group: 'settings',
        action: 'getRoles',
    })
    const { isAllowed: isGetServicesAllowed, loading: isGetServicesAllowedLoading } = useIsAllowed({
        group: 'settings',
        action: 'getServices',
    })

    const tabs = useMemo(() => {
        let tempTabs = []
        if (isGetUsersAllowed) {
            tempTabs = [
                ...tempTabs,
                {
                    label: 'Usuarios',
                    value: 'users',
                    component: Users,
                    path: '/users/list',
                    icon: <AccountBoxIcon />,
                },
            ]
        }

        if (isGetRolesAllowed) {
            tempTabs = [
                ...tempTabs,
                {
                    label: 'Roles',
                    value: 'roles',
                    component: Roles,
                    path: '/roles/list',
                    icon: <SecurityIcon />,
                },
            ]
        }

        if (isGetServicesAllowed) {
            tempTabs = [
                ...tempTabs,
                {
                    label: 'Servicios',
                    value: 'services',
                    component: Services,
                    path: '/services/list',
                    icon: <MiscellaneousServicesIcon fontSize="large" />,
                },
            ]
        }

        return tempTabs
    }, [isGetUsersAllowed, isGetRolesAllowed, isGetServicesAllowed])

    const onTabChange = (e, value) => {
        const current = tabs.find((tab) => tab.value === value)
        history.push(`../${current.value}`)
    }

    const loading =
        isGetUsersAllowedLoading || isGetRolesAllowedLoading || isGetServicesAllowedLoading

    const renderTab = () => {
        if (loading) return null

        const currentTab = tabs.find((tab) => tab.value === section) || { component: null }
        const CurrentTab = currentTab.component

        return (
            <Grid item xs={10}>
                {CurrentTab && <CurrentTab />}
            </Grid>
        )
    }

    const renderRedirect = () => {
        if (loading) return null

        if (!tabs.length) return <Redirect to="/private" />
        if (!section) return <Redirect to={match.url + tabs[0].path} />
        return null
    }

    return (
        <Grid container>
            <Grid item xs={1.5} className={classes.leftContainer}>
                <Paper className={classes.leftContainerPaper}>
                    <Tabs orientation="vertical" value={section} onChange={onTabChange}>
                        {tabs.map((tab, i) => (
                            <Tab key={i} icon={tab.icon} label={tab.label} value={tab.value} />
                        ))}
                    </Tabs>
                </Paper>
            </Grid>
            <LoadingBackdrop loading={loading} />
            {renderTab()}
            {renderRedirect()}
        </Grid>
    )
}

export default Settings
