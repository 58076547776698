import gql from 'graphql-tag'

export const CREATE_HMI = gql`
    mutation CreateHMI($params: CreateHMIRequest!) {
        createHMI(params: $params) {
            status
        }
    }
`

export const GET_HMIS_BY_GROUP = gql`
    query GetHMIsByGroup($params: GetHMIsByGroupRequest!) {
        getHMIsByGroup(params: $params) {
            status
            hmis {
                _id
                name
                components {
                    _id
                    type
                    components
                }
            }
        }
    }
`

export const GET_HMI_BY_ID = gql`
    query GetHMIById($params: GetHMIByIdRequest!) {
        getHMIById(params: $params) {
            status
            hmi {
                name
            }
        }
    }
`
