import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'

import { green } from '@material-ui/core/colors'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CircularProgress from '@material-ui/core/CircularProgress'

import AddIcon from '@material-ui/icons/Add'

import { CREATE_DELIVERABLE } from '../../../../queries/models'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const NewModelDialog = () => {
    const classes = useStyles()

    const { model } = useParams()

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [device, setDevice] = useState('')

    const [createDeliverable] = useMutation(CREATE_DELIVERABLE)

    const onDeviceChanged = (e) => setDevice(e.target.value)
    const onOpenDialogClicked = () => setOpen(true)
    const onCancelClicked = () => setOpen(false)
    const onAcceptClicked = async () => {
        setLoading(true)
        await createDeliverable({
            variables: {
                params: { model, device },
            },
        })
        setOpen(false)
        setLoading(false)
    }

    return (
        <React.Fragment>
            <Fab className={classes.fab} color="primary" onClick={onOpenDialogClicked}>
                <AddIcon />
            </Fab>
            <Dialog open={open}>
                <DialogTitle>Empaquetar un modelo</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Dispositivo</InputLabel>
                        <Select value={device} onChange={onDeviceChanged}>
                            <MenuItem value="falcon-n">FALCON N</MenuItem>
                            <MenuItem value="falcon-x">FALCON X</MenuItem>
                            <MenuItem value="universal">UNIVERSAL</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disalbed={loading}>
                        Cancelar
                    </Button>
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={onAcceptClicked}
                            >
                                Aceptar
                            </Button>
                            {loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default NewModelDialog
