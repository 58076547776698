import React, { useState } from 'react'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddIcon from '@material-ui/icons/Add'

import { useJobsCreateWorkflow, useJobsUpdateWorkflow } from 'queries/jobs'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(8),
        right: theme.spacing(3),
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const NewJobDialog = () => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState(null)
    const [description, setDescription] = useState(null)

    const onNameChanged = (e) => setName(e.target.value)
    const onDescriptionChanged = (e) => setDescription(e.target.value)
    const onOpenDialogClicked = () => setOpen(true)
    const onCancelClicked = () => setOpen(false)
    const createWorkflow = useJobsCreateWorkflow()
    const updateWorkflow = useJobsUpdateWorkflow()
    const onAcceptClicked = async () => {
        setLoading(true)
        await createWorkflow(name, description)
        updateWorkflow(
            { name, description },
            {
                nodes: [
                    {
                        id: 'cowsay-id',
                        nodeId: 'cowsay',
                        nodeVersion: '1',
                        type: 'TASK',
                        description: '',
                        input: [
                            {
                                id: 'message',
                                dataType: {
                                    name: 'STRING',
                                    metadata: [
                                        {
                                            field: 'max_len',
                                            value: '0',
                                        },
                                    ],
                                },
                                metadata: [],
                                description: '',
                            },
                            {
                                id: 'sub_message',
                                dataType: {
                                    name: 'STRING',
                                    metadata: [
                                        {
                                            field: 'max_len',
                                            value: '0',
                                        },
                                    ],
                                },
                                metadata: [],
                                description: '',
                            },
                        ],
                        output: [
                            {
                                id: 'message',
                                dataType: {
                                    name: 'STRING',
                                    metadata: [],
                                },
                                metadata: [],
                                description: '',
                            },
                        ],
                        metadata: [
                            {
                                value: '192.168.0.14:8087/cowsay:1.0.0',
                                field: 'image',
                            },
                            {
                                field: 'logic',
                                value: 'cowsay',
                            },
                        ],
                    },
                ],
                links: [
                    {
                        nodeFrom: {
                            nodeId: 'workflow',
                            port: {
                                id: 'message',
                                dataType: {
                                    name: 'string',
                                    metadata: [],
                                },
                                description: '',
                                metadata: [
                                    {
                                        field: 'value',
                                        value: 'Default hello world',
                                    },
                                ],
                            },
                        },
                        nodeTo: {
                            nodeId: 'cowsay-id',
                            port: {
                                id: 'message',
                                dataType: {
                                    name: 'string',
                                    metadata: [],
                                },
                                description: '',
                                metadata: [],
                            },
                        },
                    },
                    {
                        nodeFrom: {
                            nodeId: 'workflow',
                            port: {
                                id: 'sub_message',
                                dataType: {
                                    name: 'STRING',
                                    metadata: [],
                                },
                                description: '',
                                metadata: [
                                    {
                                        field: 'value',
                                        value: 'Default hello world again',
                                    },
                                ],
                            },
                        },
                        nodeTo: {
                            nodeId: 'cowsay-id',
                            port: {
                                id: 'sub_message',
                                dataType: {
                                    name: 'STRING',
                                    metadata: [],
                                },
                                description: '',
                                metadata: [],
                            },
                        },
                    },
                ],
                inputPorts: [
                    {
                        id: 'message',
                        dataType: {
                            name: 'string',
                            metadata: [],
                        },
                        description: 'Principal message of the cow',
                        metadata: [
                            {
                                field: 'constantValue',
                                value: 'Default hello world',
                            },
                        ],
                    },
                    {
                        id: 'sub_message',
                        dataType: {
                            name: 'STRING',
                            metadata: [],
                        },
                        description: 'Secondary message of the cow',
                        metadata: [
                            {
                                field: 'constantValue',
                                value: 'Default hello world again',
                            },
                        ],
                    },
                ],
                outputPorts: [
                    {
                        id: 'message',
                        dataType: {
                            name: 'STRING',
                            metadata: [],
                        },
                        description: '',
                        metadata: [],
                    },
                ],
            }
        )
        setOpen(false)
        setLoading(false)
    }

    return (
        <React.Fragment>
            <Fab className={classes.fab} disabled color="primary" onClick={onOpenDialogClicked}>
                <AddIcon />
            </Fab>
            <Dialog open={open}>
                <DialogTitle>Crear un nuevo workflow</DialogTitle>
                <DialogContent>
                    <TextField label="Nombre" onChange={onNameChanged} value={name} fullWidth />
                    <TextField
                        label="Description"
                        onChange={onDescriptionChanged}
                        value={description}
                        fullWidth
                        multiline={true}
                        minRows={2}
                        maxRows={5}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disalbed={loading}>
                        Cancelar
                    </Button>
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={onAcceptClicked}
                            >
                                Aceptar
                            </Button>
                            {loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default NewJobDialog
