import React from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { persistStore } from 'redux-persist'
import { ThemeProvider } from '@material-ui/styles'
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from 'notistack'
import { QueryParamProvider } from 'use-query-params'

import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { store, persistor } from './store'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

import Scene from './modules/scene'

import theme from './theme'
import './i18n'

import icon from 'static/favicon.ico'

import Favicon from 'react-favicon'

export default function () {
    return (
        <BrowserRouter key="1">
            <Favicon url={icon} />
            <QueryParamProvider adapter={ReactRouter5Adapter}>
                <MuiThemeProvider key="2">
                    <ThemeProvider theme={theme} key="3">
                        <SnackbarProvider maxSnack={3} key="4">
                            <Provider store={store} key="5">
                                <PersistGate loading={null} persistor={persistor} key="6">
                                    <IntlProvider key="7">
                                        <Scene key="8" />
                                    </IntlProvider>
                                </PersistGate>
                            </Provider>
                        </SnackbarProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </QueryParamProvider>
        </BrowserRouter>
    )
}
