import React from 'react'
import { PropTypes } from 'prop-types'
import TextField from '@material-ui/core/TextField'

/**
 * Component to render string parameter type
 * Is used for creating new parameter and update its value
 * once created.
 */
const ParamTypeString = ({ value, setValue }) => {
    const onValueChanged = (event) => {
        setValue(event.target.value)
    }
    return <TextField label="Valor" value={value} onChange={onValueChanged} fullWidth />
}

ParamTypeString.propTypes = {
    /** Initial value and value linked with parent states */
    value: PropTypes.number.isRequired,
    /** Function to set the parent state */
    setValue: PropTypes.func.isRequired,
}

export default ParamTypeString
