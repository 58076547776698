import React, { useRef, useEffect, useState } from "react"

import { useQuery } from "@apollo/react-hooks"
import { useSelector } from "react-redux"

import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"

import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    VerticalBarSeries,
    ChartLabel
} from 'react-vis';

import { QUERY_DATASETS_V2_ENTRIES_BY_DATASET } from "../../queries/datasets"

const padding = 20

const Chart = () => {
    
    const card = useRef(null)

    const [ widgetWidth, setWidgetWidth ] = useState(0)
    const [ widgetHeight, setWidgetHeight ] = useState(0)

    const { current_dataset, dimensions, metrics } = useSelector(state => state.dashboards.bar_chart)

    const { data: { queryDatasetV2EntriesByDataset: { entries = []} = {}} = {}} = useQuery(QUERY_DATASETS_V2_ENTRIES_BY_DATASET, { 
        variables: { 
            params: { 
                dataset: current_dataset,
                channels: [
                    ...dimensions.map(d => (
                        {
                            name: "x",
                            field: d.field,
                            mode: "group",
                            type: "date",
                            properties: [
                              {
                                name: "time",
                                value: "1"
                              },
                              {
                                name: "unit",
                                value: d.bucket
                              }
                            ]
                        }
                    )),
                    ...metrics.map((m, i) => (
                        {
                            name: "y",
                            field: m.field,
                            mode: "aggregate",
                            type: m.aggregation,
                            properties: []
                        }
                    ))
                ],
                filters: [],
                limit: 5000,
                offset: 0
            }
        }
    })

    useEffect( () => {
        if(card.current){

            let height = card.current.offsetHeight;
            let width  = card.current.offsetWidth;

            setWidgetHeight(height)
            setWidgetWidth(width)
        }

    }, [card]);

    const margin = 200

    return (
        <Card ref={card} style={{ alignItems: "stretch", width: `calc(100% - ${margin}px)`, height: `calc(100% - ${margin}px)`, padding: padding, margin: `${margin / 2}px auto` }} elevation={4}>
            <CardContent>
                <XYPlot margin={{ bottom: 70 }} xType="ordinal" width={widgetWidth - padding*2} height={widgetHeight - padding * 2}>
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis tickLabelAngle={-45} />
                    <YAxis />
                    <ChartLabel
                        text={""}
                        className="alt-x-label"
                        includeMargin={false}
                        xPercent={0.5}
                        yPercent={0.05}
                    />
                    {
                        entries && entries.length > 1 && 
                        <VerticalBarSeries
                            data={entries.map(e => e.reduce((acc, item) => ({ ...acc, [item.channel]: item.value }), {}))}
                        />
                    }
                </XYPlot>                     
            </CardContent>
        </Card>
    )
}

export default Chart