import React from 'react'

import { useIsAllowed } from 'queries/permissions'
import NewHMIDialog from './containers/new-hmi-dialog/scene'
import HMIsList from './containers/list/scene'

const Hmi = () => {
    const { isAllowed: isCreateHMIAllowed } = useIsAllowed({
        action: 'createHMI',
        group: 'groups',
    })

    return (
        <React.Fragment>
            {isCreateHMIAllowed && <NewHMIDialog />}
            <HMIsList />
        </React.Fragment>
    )
}
export default Hmi
