import React, { useState } from "react"

import { useQuery } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Page from "../../components/page"

import { GET_HOTSPOTS_BY_DATASET } from "./queries/hotspots"

function Hotspots(props) {
    const { hotspot } = props

    const [ width, setWidth ] = useState(0)
    const [ height, setHeight ] = useState(0)

    const onImageLoad = e => {
        setWidth(e.target.offsetWidth)
        setHeight(e.target.offsetHeight)
    }
    console.log(width)
    return (
        <Page>
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ position: "relative" }}>
                        <img onLoad={onImageLoad} src={hotspot.image && hotspot.image.url} width={"100%"} height="auto"/>
                        <div style={{ 
                            position: "absolute", 
                            border: "2px solid #333", 
                            left: hotspot.bbox[1] * width, 
                            top: hotspot.bbox[0] * height, 
                            width: (hotspot.bbox[3] - hotspot.bbox[1]) * width, 
                            height: (hotspot.bbox[2] - hotspot.bbox[0]) * height
                        }}></div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Parámetro</TableCell>
                            <TableCell>Valor</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Categoría
                                </TableCell>
                                <TableCell>
                                    {hotspot.category}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Precisión
                                </TableCell>
                                <TableCell>
                                    {hotspot.score}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Latitud
                                </TableCell>
                                <TableCell>
                                    {hotspot.position && hotspot.position.coordinates[1]}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Longitud
                                </TableCell>
                                <TableCell>
                                    {hotspot.position && hotspot.position.coordinates[0]}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Page>
    )
}

export default Hotspots