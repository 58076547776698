import React from 'react'

import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'

import ChevronLeft from '@material-ui/icons/ChevronLeft'
import EditIcon from '@material-ui/icons/Edit'

import Status from './containers/status/scene'
import Name from './containers/name/scene'
import Chat from './containers/chat/scene'

import { GET_INCIDENT_BY_ID } from '../../queries/incidents'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
}))

const Scene = (props) => {
    const classes = useStyles()

    const { group, incident } = useParams()

    const { isAllowed: isGetMessagesAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'getMessagesByOwner',
    })

    const { data } = useQuery(GET_INCIDENT_BY_ID, { variables: { params: { id: incident } } })

    const getIncidentById = (data && data.getIncidentById) || {}
    const inc = getIncidentById.incident || {}
    const id = inc.id || ''
    const createdAt = inc.createdAt || ''
    const level = inc.level || ''

    const renderDate = () => {
        if (!createdAt) return null

        const d = new Date(createdAt)

        let hour = d.getHours()
        if (hour < 10) hour = `0${hour}`
        let minute = d.getMinutes()
        if (minute < 10) minute = `0${minute}`

        let day = d.getDate()
        if (day < 10) day = `0${day}`
        let month = d.getMonth() + 1
        if (month < 10) month = `0${month}`
        let year = d.getFullYear()

        const secondary = `${hour}:${minute} - ${day}/${month}/${year}`

        return (
            <ListItem>
                <ListItemText primary="Fecha de creación" secondary={secondary} />
            </ListItem>
        )
    }

    const renderLevel = () => {
        if (!level) return null

        return (
            <ListItem>
                <ListItemText primary="Nivel" secondary={level} />
            </ListItem>
        )
    }

    const renderId = () => {
        if (!id) return null

        return (
            <ListItem>
                <ListItemText primary="Identificador" secondary={id} />
            </ListItem>
        )
    }

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        component={Link}
                        to={`../${group}`}
                        startIcon={<ChevronLeft />}
                    >
                        Volver a Incidencias
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Paper>
                        <List>
                            <Status />
                            {renderDate()}
                            {renderLevel()}
                            {renderId()}
                        </List>
                    </Paper>
                </Grid>
                {isGetMessagesAllowed && (
                    <Grid item xs={9}>
                        <Paper style={{ height: 'calc(100vh - 220px)' }}>
                            <Chat />
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}

export default Scene
