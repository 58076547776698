import React, { useState } from 'react'

import { DynamicModuleLoader } from 'redux-dynamic-modules'
import { Switch, Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import MapIcon from '@material-ui/icons/Map'
import ImageIcon from '@material-ui/icons/Image'
import FiberSmartRecordIcon from '@material-ui/icons/FiberSmartRecord'

import Selector from './containers/selector/scene'
import Universal from './containers/universal/scene'
import Solar from './containers/solar/scene'

import TrainingSets from './containers/training-sets/containers/listview/scene'
import TrainingSetsPolygon from './containers/training-sets/containers/polygon/scene'

import getInspectorModules from './module'
import Models from './containers/models/scene'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        width: 60,
    },
    drawerPaperClosed: {
        zIndex: 8,
        width: 60,
        paddingTop: 80,
        transition: 'width 0.3s ease',
        overflowX: 'hidden',
        boxShadow: '0 0 20px -15px #000',
    },
    drawerPaperOpen: {
        zIndex: 8,
        width: drawerWidth,
        paddingTop: 80,
        transition: 'width 0.3s ease',
        overflowX: 'hidden',
        boxShadow: '0 0 20px -15px #000',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}))

function Inspector(props) {
    const classes = useStyles()

    const [open, setOpen] = useState(false)

    return (
        <DynamicModuleLoader modules={[getInspectorModules()]}>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: open ? classes.drawerPaperOpen : classes.drawerPaperClosed,
                }}
                anchor="left"
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                <List style={{ width: drawerWidth }}>
                    <Link to={props.match.url}>
                        <ListItem button>
                            <ListItemIcon>
                                <MapIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Projects'} />
                        </ListItem>
                    </Link>
                    <Link to={props.match.url + '/training-sets/'}>
                        <ListItem button>
                            <ListItemIcon>
                                <ImageIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Training Sets'} />
                        </ListItem>
                    </Link>
                    <Link to={props.match.url + '/models/'}>
                        <ListItem button>
                            <ListItemIcon>
                                <FiberSmartRecordIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Models'} />
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
            <div
                style={{
                    height: 'calc(100vh - 63px)',
                    width: 'calc(100vw - 60px)',
                    marginLeft: 60,
                }}
            >
                <Switch>
                    <Route exact path={props.match.url} component={Selector} />
                    <Route path={props.match.url + '/solar/'} component={Solar} />
                    <Route path={props.match.url + '/universal/'} component={Universal} />
                    <Route
                        path={props.match.url + '/training-sets/'}
                        exact
                        component={TrainingSets}
                    />
                    <Route
                        path={props.match.url + '/training-sets/polygon/:trainingSet'}
                        exact
                        component={TrainingSetsPolygon}
                    />
                    <Route path={props.match.url + '/models'} exact component={Models} />
                </Switch>
            </div>
        </DynamicModuleLoader>
    )
}

export default React.memo(Inspector)
