import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Zoom } from '@vx/zoom'
import { get } from 'lodash'

import Annotation from './annotation'
import { setAnnotationIndex } from '../../redux/images'
import { setToolbox } from '../../redux/general'

const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: window.innerWidth * 0.25,
    translateY: window.innerHeight * 0.05,
    skewX: 0,
    skewY: 0,
}

/**
 * Component responsible of the board where
 * images and annotations are rendered.
 */
const ImageViewer = () => {
    const dispatch = useDispatch()

    const inPlaceAnnotationsLength = useSelector(
        (state) =>
            get(
                state.trainingSetObjectDetection.images.inPlaceAnnotations,
                state.trainingSetObjectDetection.images.selectedEntry,
                []
            ).length
    )
    const selectedEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedEntry
    )
    const inPlaceEntry = useSelector(
        (state) =>
            state.trainingSetObjectDetection.images.entries[
                state.trainingSetObjectDetection.images.selectedEntry
            ]
    ) || { url: null }

    const selectedAnnotation = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedAnnotation
    )

    const showAnnotations = useSelector(
        (state) => state.trainingSetObjectDetection.images.showBBoxes
    )

    const clickOutside = () => {
        if (selectedEntry !== null) {
            dispatch(setAnnotationIndex(null))
            dispatch(setToolbox('samples'))
        }
    }

    const processedAnnotations = [...Array(inPlaceAnnotationsLength).keys()]
    if (inPlaceAnnotationsLength > 0) {
        // Get index of selected annotations
        const indexOfSelected = processedAnnotations.indexOf(selectedAnnotation)
        const selectedAnnValue = processedAnnotations.splice(indexOfSelected, 1)[0]
        processedAnnotations.push(selectedAnnValue)
    }

    const hasMoved = useRef(false)
    return (
        <div style={{ height: '1000px', background: '#EFEFEF', overflow: 'hidden' }}>
            <Zoom
                width={window.innerWidth}
                height={window.innerHeight}
                scaleXMin={0.1}
                scaleXMax={10}
                scaleYMin={0.1}
                scaleYMax={10}
                transformMatrix={initialTransform}
            >
                {(zoom) => {
                    return (
                        <svg width={window.innerWidth} height={window.innerHeight}>
                            {inPlaceEntry.url && (
                                <g transform={zoom.toString()}>
                                    <image xlinkHref={inPlaceEntry.url} />
                                </g>
                            )}
                            <rect
                                width={window.innerWidth}
                                height={window.innerHeight}
                                rx={14}
                                fill="transparent"
                                onTouchStart={zoom.dragStart}
                                onTouchMove={(event) => {
                                    zoom.dragMove(event)
                                }}
                                onTouchEnd={zoom.dragEnd}
                                onMouseDown={zoom.dragStart}
                                onMouseMove={(event) => {
                                    hasMoved.current = true
                                    zoom.dragMove(event)
                                }}
                                onMouseUp={(event) => {
                                    hasMoved.current = false
                                    zoom.dragEnd(event)
                                }}
                                onMouseLeave={() => {
                                    if (zoom.isDragging) zoom.dragEnd()
                                }}
                                onClick={() => {
                                    if (!hasMoved.current) clickOutside()
                                    hasMoved.current = false
                                }}
                            />
                            {showAnnotations && (
                                <g transform={zoom.toString()}>
                                    {processedAnnotations.map((index) => {
                                        return (
                                            <Annotation
                                                key={`${selectedEntry}_${index}`}
                                                index={index}
                                                transform={zoom}
                                            />
                                        )
                                    })}
                                </g>
                            )}
                        </svg>
                    )
                }}
            </Zoom>
        </div>
    )
}

export default ImageViewer
