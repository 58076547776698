import gql from "graphql-tag"

export const GET_ALL_PROJECTS = gql`
    query GetProjectsByParent($params: GetProjectsByParentRequest) {
        getProjectsByParent(params: $params) {
            status
            projects {
                _id
                name
                position
                createdAt
            }
        }
    }
`

export const CREATE_PROJECT = gql`
    mutation createProject($params: CreateProjectRequest!){
        createProject(params: $params) {
            state
        }
    }
`

export const UPDATE_PROJECT_NAME = gql`
    mutation UpdateProjectName($params: UpdateProjectNameRequest!) {
        updateProjectName(params: $params) {
            status
        }
    }
`

export const UPDATE_PROJECT_DESCRIPTION = gql`
    mutation UpdateProjectDescription($params: UpdateProjectDescriptionRequest!) {
        updateProjectDescription(params: $params) {
            status
        }
    }
`

export const GET_PROJECT_BY_ID = gql`
    query GetProjectById($params: GetProjectByIdRequest!) {
        getProjectById(params: $params) {
            status
            project {
                _id
                name
                description
                position {
                    coordinates
                }
            }
        }
    }
`

export const REMOVE_PROJECT = gql`
    mutation RemoveProject($params: RemoveProjectRequest!) {
        removeProject(params: $params) {
            status
        }
    }
`