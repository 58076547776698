import gql from 'graphql-tag'

export const GET_PARAMS_BY_GROUP = gql`
    query GetParamsByGroup($params: GetParamsByGroupRequest!) {
        getParamsByGroup(params: $params) {
            status
            params {
                _id
                name
                type
                local
                remote
            }
        }
    }
`
