import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ChipInput from 'material-ui-chip-input'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

const StepMonitorName = (props) => {
    const classes = useStyles()

    const [name, setName] = useState(null)
    const [disabled, setDisabled] = useState(true)

    const onStepMonitorNameAccept = () => {
        if (!name.length) {
            return
        }

        props.onPropertyChange(props.property, name)
        props.setActiveStep(props.nextStep)
    }

    const onNameChange = (event) => {
        if (event.target.value.length) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

        setName(event.target.value)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    label="Nombre"
                    variant="outlined"
                    fullWidth
                    defaultValue={props.value}
                    value={name}
                    onChange={onNameChange}
                />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.actions}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorNameAccept}
                        disabled={disabled}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorName
