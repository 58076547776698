export const SET_SELECTED_ENTRIES = 'FALCON/DATASETS/VIEWER/SET_SELECTED_ENTRIES'

export const setSelectedEntries = (entries) => ({ type: SET_SELECTED_ENTRIES, entries })

export const EXPAND_RIGHT_PANEL = 'FALCON/DATASETS/VIEWER/EXPAND_RIGHT_PANEL'
export const CONTRACT_RIGHT_PANEL = 'FALCON/DATASETS/VIEWER/CONTRACT_RIGHT_PANEL'

// Virtual columns
const ADD_VIRTUAL_COLUMN = 'FALCON/DATASETS/VIEWER/ADD_VIRTUAL_COLUMN'
const REMOVE_VIRTUAL_COLUMN = 'FALCON/DATASETS/VIEWER/REMOVE_VIRTUAL_COLUMN'
const EDIT_VIRTUAL_COLUMN = 'FALCON/DATASETS/VIEWER/EDIT_VIRTUAL_COLUMN'

export const expandRightPanel = () => ({ type: EXPAND_RIGHT_PANEL })
export const contractRightPanel = () => ({ type: CONTRACT_RIGHT_PANEL })

export const addVirtualColumn = (columns) => ({
    type: ADD_VIRTUAL_COLUMN,
    columns,
})

export const removeVirtualColumn = (columnsNames) => ({
    type: REMOVE_VIRTUAL_COLUMN,
    columnsNames,
})
export const editVirtualColumn = (columns) => ({
    type: REMOVE_VIRTUAL_COLUMN,
    columns,
})



export default (
    state = {
        rightPanelOpen: true,
        selectedEntries: [],
        virtualColumns: {},
    },
    action
) => {
    switch (action.type) {
        case SET_SELECTED_ENTRIES:
            return {
                ...state,
                selectedEntries: action.entries,
            }
        case EXPAND_RIGHT_PANEL:
            return {
                ...state,
                rightPanelOpen: true,
            }
        case CONTRACT_RIGHT_PANEL:
            return {
                ...state,
                rightPanelOpen: false,
            }
        case ADD_VIRTUAL_COLUMN:
            return {
                ...state,
                virtualColumns: {
                    ...state.virtualColumns,
                    ...action.columns.reduce((prev, next) => {
                        return { ...prev, [next.name]: next.data }
                    }, {}),
                },
            }
        case REMOVE_VIRTUAL_COLUMN:
            return {
                ...state,
                virtualColumns: {
                    ...Object.entries(state.virtualColumns)
                        .filter((obj) => !action.columnsNames.includes(obj[0]))
                        .reduce((prev, next) => {
                            console.log(action.columnsNames)
                            console.log(next)
                            return { ...prev, [next[0]]: next[1] }
                        }, {}),
                },
            }
        case EDIT_VIRTUAL_COLUMN:
            return {
                ...state,
                virtualColumns: {
                    ...state.virtualColumns,
                    ...action.columns.map((column) => {
                        return { [state.virtualColumns[column.name]]: column.data }
                    }),
                },
            }
        default:
            return state
    }
}
