import React, { useState } from 'react'

import { makeStyles } from '@material-ui/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import AddIcon from '@material-ui/icons/Add'

import Camera from './containers/camera'
import CameraUSB3 from './containers/camera-usb3/index'
import CameraGenicam from './containers/camera-genicam/index'
import MachineLearning from './containers/machine-learning'
import MachineLearningV2 from './containers/machine-learning-v2'

const resources = {
    camera: {
        name: 'Cámara',
        value: 'camera',
        component: Camera,
    },
    'camera-usb3-vision': {
        name: 'Cámara USB3 Vision',
        value: 'camera-usb3-vision',
        component: CameraUSB3,
    },
    'camera-genicam': {
        name: 'Cámara GeniCam',
        value: 'camera-genicam',
        component: CameraGenicam,
    },
    'machine-learning': {
        name: 'Detector',
        value: 'machine-learning',
        component: MachineLearning,
    },
    'machine-learning-v2': {
        name: 'Detector V2',
        value: 'machine-learning-v2',
        component: MachineLearningV2,
    },
}

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const NewResourceDialog = () => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)

    const Form = resources[selected] && resources[selected].component

    const onRequestClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <div className={classes.fab}>
                <Tooltip title="Nuevo Recurso" placement="left">
                    <Fab onClick={() => setOpen(true)} color="primary">
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
            <Dialog open={open}>
                <DialogTitle>Nuevo recurso</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Tipo</InputLabel>
                        <Select value={selected} onChange={(e) => setSelected(e.target.value)}>
                            {Object.values(resources).map((resource) => (
                                <MenuItem key={resource.name} value={resource.value}>
                                    {resource.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                {!Form ? (
                    <DialogActions>
                        <Button onClick={onRequestClose}>Cancelar</Button>
                        <Button disabled>Aceptar</Button>
                    </DialogActions>
                ) : (
                    <Form onCancel={onRequestClose} />
                )}
            </Dialog>
        </React.Fragment>
    )
}

export default NewResourceDialog
