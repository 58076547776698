import React, { useState } from 'react';

import { Link } from "react-router-dom"

import { makeStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        width: 60,
    },
    drawerPaperClosed: {
        zIndex: 8,
        width: 60,
        paddingTop: 80,
        transition: "width 0.3s ease",
        overflowX: "hidden",
        boxShadow: "0 0 20px -15px #000"
    },
    drawerPaperOpen: {
        zIndex: 8,
        width: drawerWidth,
        paddingTop: 80,
        transition: "width 0.3s ease",
        overflowX: "hidden",
        boxShadow: "0 0 20px -15px #000"
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    }
}));

export default function PermanentDrawerLeft(props) {
    const classes = useStyles();
    
    const [ open, setOpen ] = useState(false)
    
    return (
        props.menu.length ? 
          <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                  paper: open ? classes.drawerPaperOpen : classes.drawerPaperClosed,
              }}
              anchor="left"
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
          >
              <List style={{ width: drawerWidth }}>
                  {props.menu.map(item => (
                      <Link to={props.match.url + item.path}>
                          <ListItem button>
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText primary={item.title} />
                          </ListItem>
                      </Link>
                  ))}
              </List>
          </Drawer> :
          null
    );
}