export const SET_MAP_POINT_SIZE = "SET_MAP_POINT_SIZE"

export const setMapPointSize = point_size => ({ type: SET_MAP_POINT_SIZE, point_size })

export default (state = { point_size: 5 }, action) => {
    switch(action.type) {
        case SET_MAP_POINT_SIZE:
            return {
                ...state,
                point_size: action.point_size
            }
        default:
            return state
    }
}