import React, { useState } from 'react'
import { object, PropTypes } from 'prop-types'
import TextField from '@mui/material/TextField'

export const CONSTANT_ID = 'constant'

/**
 * Functional component that control the configuration of
 * constant operation when creating a virtual column
 */
export const ConstantInputComponent = ({ dataContainer }) => {
    const [text, setText] = useState('')

    const onTextChange = (event) => {
        dataContainer.current.value = event.target.value // eslint-disable-line no-param-reassign
        setText(event.target.value)
    }

    return (
        <TextField
            label="Valor"
            value={text}
            onChange={onTextChange}
            fullWidth
            variant="standard"
        />
    )
}
ConstantInputComponent.propTypes = {
    /** Static container to fill it with the data. Is a reference. */
    dataContainer: PropTypes.shape({ current: object }).isRequired,
}

/**
 * Component to render the constant column in the table.
 * This operation (actually) is only string so this render is not used
 */
export const ConstantComponent = () => {
    return null
}

/**
 * Constant operation. This operation fill all the rows with a
 * constant value.
 */
export class Constant {
    constructor(name) {
        this.name = name
    }

    /**
     * Get the field configuration
     * @returns object
     */
    getField() {
        return { name: this.name, type: 'string' }
    }

    /**
     * Function to check the configuration data integrity
     * @param {object} data
     * @returns bool
     */
    static checkData(data) {
        if (!Object.hasOwn(data, 'value')) {
            return false
        }
        return true
    }

    /**
     *
     * @param {object} _ - Each entry of the dataset, but in constant is not used
     * @param {object} data - Operation configuration
     * @returns New data for the entry
     */
    /* eslint-disable class-methods-use-this */
    call(_, data) {
        // Check data has all the entries
        return { value: data.value }
    }
}
