import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import ListMonitors from './containers/list-monitors/scene'
import CreateMonitor from './containers/create-monitor/scene'
import ViewMonitor from './containers/view-monitor/scene'

const Monitors = (props) => {
    return (
        <Switch>
            <Redirect exact from={props.match.url} to={props.match.url + '/list'} />
            <Route path={props.match.url + '/create'} component={CreateMonitor} />
            <Route path={props.match.url + '/list'} component={ListMonitors} />
            <Route path={props.match.url + '/:monitor'} component={ViewMonitor} />
        </Switch>
    )
}

export default Monitors
