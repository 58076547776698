import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

function LoadingButton(props) {
    const classes = useStyles()

    return (
        <div className={classes.wrapper}>
            <Button disabled={props.loading} {...props}>
                Aceptar
            </Button>
            {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
}))

export default LoadingButton
