import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useNode } from '@craftjs/core'

const Toggle = (props) => {
    const {
        connectors: { connect, drag },
    } = useNode()
    /* eslint-disable react/destructuring-assignment */
    return (
        <div ref={(ref) => connect(drag(ref))}>
            <FormControlLabel control={<Switch {...props} />} label={props.content} />
        </div>
    )
}

Toggle.craft = {
    displayName: 'Interruptor',
    custom: {
        component: true,
    },
}

Toggle.propTypes = {
    content: PropTypes.string,
}

Toggle.defaultProps = {
    content: 'Texto',
}

export default Toggle
