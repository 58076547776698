import { v1 as uuidv1 } from 'uuid'

const MOVE_CANVAS = 'MOVE_CANVAS'

const ADD_NODE = 'ADD_NODE'
const SELECT_NODE = 'SELECT_NODE'
const REMOVE_NODE = 'REMOVE_NODE'
const MOVE_NODE = 'MOVE_NODE'
const CHANGE_NODE_SIZE = 'CHANGE_NODE_SIZE'

const CHANGE_PORT_POSITION = 'CHANGE_PORT_POSITION'

const START_LINK = 'START_LINK'
const MOVE_LINK = 'MOVE_LINK'
const COMPLETE_LINK = 'COMPLETE_LINK'
const REMOVE_LINK = 'REMOVE_LINK'

const SET_NODES = 'SET_NODES'
const SET_LINKS = 'SET_LINKS'

const SET_NODE_PROPERTIES = 'SET_NODE_PROPERTIES'

const SET_NODES_DEFINITION = 'SET_NODES_DEFINITION'

export const moveCanvas = ({ positionX, positionY }) => ({
    type: MOVE_CANVAS,
    offset: { positionX, positionY },
})

export const addNode = (node) => ({ type: ADD_NODE, node })
export const selectNode = (nodeId) => ({ type: SELECT_NODE, nodeId })
export const removeNode = (nodeId) => ({ type: REMOVE_NODE, nodeId })
export const moveNode = (node) => ({ type: MOVE_NODE, node })
export const changeNodeSize = ({ nodeId, size }) => ({
    type: CHANGE_NODE_SIZE,
    node: { nodeId, size },
})
export const setNodes = (nodes) => ({ type: SET_NODES, nodes })
export const setNodeProperties = ({ nodeId, properties }) => ({
    type: SET_NODE_PROPERTIES,
    node: { nodeId, properties },
})
export const changePortPosition = (event) => ({ type: CHANGE_PORT_POSITION, event })

export const setLinks = (links) => ({ type: SET_LINKS, links })
export const startLink = ({ linkId, fromNodeId, fromPortId }) => ({
    type: START_LINK,
    link: { linkId, fromNodeId, fromPortId },
})
export const moveLink = ({ linkId, toPosition }) => ({
    type: MOVE_LINK,
    link: { linkId, toPosition },
})
export const completeLink = ({ linkId, toNodeId, toPortId }) => ({
    type: COMPLETE_LINK,
    link: { linkId, toNodeId, toPortId },
})
export const removeLink = (linkId) => ({ type: REMOVE_LINK, linkId })

const rotate = (center, current, angle) => {
    const radians = (Math.PI / 180) * angle
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)
    const x = cos * (current.x - center.x) + sin * (current.y - center.y) + center.x
    const y = cos * (current.y - center.y) - sin * (current.x - center.x) + center.y
    return { x, y }
}

export const setNodeDefinitions = (definitions) => {
    return { type: SET_NODES_DEFINITION, definitions }
}

export default (
    state = {
        selected_node: 8989898,
        offset: { x: 0, y: 0 },
        nodes: {},
        links: {},
        selected: {},
        hovered: {},
        nodesDefinition: {},
    },
    action
) => {
    switch (action.type) {
        case SET_NODES:
            return {
                ...state,
                nodes: action.nodes.reduce((acc, node) => {
                    return {
                        ...acc,
                        [node.id]: {
                            id: node.id,
                            type: node.name,
                            position: {
                                x: Number(node.position.x),
                                y: Number(node.position.y),
                            },
                            properties: node.properties.reduce(
                                (prev, property) => ({ ...prev, [property.type]: property.value }),
                                {}
                            ),
                            ports: node.ports,
                        },
                    }
                }, {}),
            }
        case SELECT_NODE:
            return {
                ...state,
                selected_node: action.nodeId,
            }
        case SET_LINKS:
            return {
                ...state,
                links: action.links.reduce((acc, link) => {
                    return {
                        ...acc,
                        [link.id]: {
                            id: link.id,
                            from: {
                                nodeId: link.from.nodeId,
                                portId: link.from.portId,
                            },
                            to: {
                                nodeId: link.to.nodeId,
                                portId: link.to.portId,
                            },
                        },
                    }
                }, {}),
            }
        case MOVE_CANVAS:
            return {
                ...state,
                offset: {
                    x: action.offset.positionX,
                    y: action.offset.positionY,
                },
            }
        case ADD_NODE:
            const id = uuidv1()
            return {
                ...state,
                nodes: {
                    ...state.nodes,
                    [id]: {
                        id,
                        type: action.node._id,
                        position: { x: 300, y: 100 },
                        ports: action.node.ports.map((port, i) => {
                            return {
                                ...port,
                                id: i,
                            }
                        }),
                        properties: {},
                    },
                },
            }
        case MOVE_NODE:
            return {
                ...state,
                nodes: {
                    ...state.nodes,
                    [action.node.id]: {
                        ...state.nodes[action.node.id],
                        position: {
                            x: action.node.data.x,
                            y: action.node.data.y,
                        },
                    },
                },
            }
        case CHANGE_NODE_SIZE:
            return {
                ...state,
                nodes: {
                    ...state.nodes,
                    [action.node.nodeId]: {
                        ...state.nodes[action.node.nodeId],
                        size: action.node.size,
                    },
                },
            }
        case SET_NODE_PROPERTIES:
            if (action.node.properties.value === '' || action.node.properties.value === null) {
                const { [action.node.properties.type]: value, ...rest } =
                    state.nodes[action.node.nodeId].properties

                return {
                    ...state,
                    nodes: {
                        ...state.nodes,
                        [action.node.nodeId]: {
                            ...state.nodes[action.node.nodeId],
                            properties: rest,
                        },
                    },
                }
            }

            return {
                ...state,
                nodes: {
                    ...state.nodes,
                    [action.node.nodeId]: {
                        ...state.nodes[action.node.nodeId],
                        properties: {
                            ...state.nodes[action.node.nodeId].properties,
                            [action.node.properties.type]: action.node.properties.value,
                        },
                    },
                },
            }
        case CHANGE_PORT_POSITION:
            const { node: nodeToUpdate, port, el, nodesEl } = action.event
            if (nodeToUpdate.size) {
                // rotate the port's position based on the node's orientation prop (angle)
                const center = { x: nodeToUpdate.size.width / 2, y: nodeToUpdate.size.height / 2 }
                const current = {
                    x: el.offsetLeft + nodesEl.offsetLeft + el.offsetWidth / 2,
                    y: el.offsetTop + nodesEl.offsetTop + el.offsetHeight / 2,
                }
                const angle = nodeToUpdate.orientation || 0
                const position = rotate(center, current, angle)

                const node = state.nodes[nodeToUpdate.id]
                node.ports[port.id].position = {
                    x: position.x,
                    y: position.y,
                }

                return {
                    ...state,
                    nodes: {
                        ...state.nodes,
                        [action.event.node.id]: { ...node },
                    },
                }
            }

            return state
        case START_LINK:
            return {
                ...state,
                links: {
                    ...state.links,
                    [action.link.linkId]: {
                        id: action.link.linkId,
                        from: {
                            nodeId: action.link.fromNodeId,
                            portId: action.link.fromPortId,
                        },
                        to: {},
                    },
                },
            }
        case MOVE_LINK:
            return {
                ...state,
                links: {
                    ...state.links,
                    [action.link.linkId]: {
                        ...state.links[action.link.linkId],
                        to: {
                            position: action.link.toPosition,
                        },
                    },
                },
            }
        case COMPLETE_LINK:
            return {
                ...state,
                links: {
                    ...state.links,
                    [action.link.linkId]: {
                        ...state.links[action.link.linkId],
                        to: {
                            nodeId: action.link.toNodeId,
                            portId: action.link.toPortId,
                        },
                    },
                },
            }
        case REMOVE_NODE:
            const nodes = Object.values(state.nodes).filter((node) => node.id !== action.nodeId)
            const links = Object.values(state.links).filter(
                (link) => !(link.to.nodeId === action.nodeId || link.from.nodeId === action.nodeId)
            )

            return {
                ...state,
                nodes: nodes.reduce((acc, node) => ({ ...acc, [node.id]: node }), {}),
                links: links.reduce((acc, link) => ({ ...acc, [link.id]: link }), {}),
            }
        case REMOVE_LINK:
            return {
                ...state,
                links: Object.values(state.links)
                    .filter((link) => link.id !== action.linkId)
                    .reduce((acc, link) => ({ ...acc, [link.id]: link }), {}),
            }
        case SET_NODES_DEFINITION:
            return { ...state, nodesDefinition: action.definitions }
        default:
            return state
    }
}
