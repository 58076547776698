/**
 * Module responsible of renderization of annotations in the image viewer.
 * It is responsible to of its coordinates changes.
 */
import React, { useRef, Children, useState } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { LightenDarkenColor } from 'lighten-darken-color'
import _ from 'lodash'

import {
    setAnnotationIndex,
    setByIndexInplaceAnnotation,
    setEditMode,
    removeByIndexInPlaceAnnotation,
} from '../../redux/images'
import { setToolbox } from '../../redux/general'

const leftUpString = 'lu'
const leftBtString = 'lb'
const rightUpString = 'ru'
const rightBtString = 'rb'

// utils
/**
 * Compute the new draggable zone
 * @param {number} xUp - Left Up corner X coordinate
 * @param {number} yUp - Left Up corner Y coordinate
 * @param {number} xBt - Right Bottom corner X coordinate
 * @param {number} yBt - Right Bottom corner Y coordinate
 * @param {number} dragZone - %1 to expand the drag zone
 * @returns - [xUpNew, yUpNew, xBtNew, yBtNew]
 */
const computeDraggableZone = (xUp, yUp, xBt, yBt, dragZone) => {
    const width = xBt - xUp
    const height = yBt - yUp

    const increX = ((dragZone - 1) * width) / 2
    const increY = ((dragZone - 1) * height) / 2

    return [xUp - increX, yUp - increY, xBt + increX, yBt + increY]
}

/**
 * Convert coordinates to svg points string type
 * @param {number} xUp - Left Up corner X coordinate
 * @param {number} yUp - Left Up corner Y coordinate
 * @param {number} xBt - Righ Bottom corner X coordinate
 * @param {number} yBt - Right Bottom corner Y coordinate
 * @returns - string
 */
const pointsToString = (xUp, yUp, xBt, yBt) =>
    `${xUp},${yUp} ${xBt},${yUp} ${xBt},${yBt} ${xUp},${yBt}`

/**
 * Invisible component to convert an element in a draggable component
 *
 * Inserts props to its childrens:
 *      * isDragging: True if the element is dragging
 */
const DraggableObject = ({ transform, moveEffect, children }) => {
    const [dragging, setDragging] = useState(false)
    const originMouse = useRef(false)
    return (
        <g
            onMouseDown={(event) => {
                setDragging(true)
                const transformedPoint = transform.applyInverseToPoint({
                    x: event.clientX,
                    y: event.clientY,
                })
                originMouse.current = [transformedPoint.x, transformedPoint.y]
            }}
            onMouseMove={(event) => {
                if (dragging) {
                    const transformedPoint = transform.applyInverseToPoint({
                        x: event.clientX,
                        y: event.clientY,
                    })
                    const movX = transformedPoint.x - originMouse.current[0]
                    const movY = transformedPoint.y - originMouse.current[1]
                    moveEffect(movX, movY)
                    originMouse.current = [transformedPoint.x, transformedPoint.y]
                }
            }}
            onMouseUp={() => {
                setDragging(false)
            }}
            onMouseLeave={() => {
                setDragging(false)
            }}
        >
            {Children.map(children, (obj) =>
                React.cloneElement(obj, { ...obj.props, isDragging: dragging }, obj.children)
            )}
        </g>
    )
}

DraggableObject.propTypes = {
    /** Transform to change the coordinated between reference systems. vx/zoom transform matrix */
    transform: PropTypes.func,
    /** Function to pass the mov in x and mov in y */
    moveEffect: PropTypes.func.isRequired,
    /** React children */
    children: (PropTypes.object || PropTypes.array).isRequired,
}
DraggableObject.defaultProps = {
    transform: (x, y) => {
        return [x, y]
    },
}
DraggableObject.returnTypes = {
    /** Is true when dragging */
    isDragging: PropTypes.bool.isRequired,
}

/**
 * Component that renders and control the edit point of the annotations
 */
const AnnotationCornerEdit = ({ x, y, color, isDragging, size }) => {
    const xUp = x - size / 2
    const yUp = y - size / 2
    const xBt = x + size / 2
    const yBt = y + size / 2
    return (
        <g>
            {/* Draggable zone to make the movement of element wider */}
            {isDragging && (
                <polygon
                    points={pointsToString(...computeDraggableZone(xUp, yUp, xBt, yBt, 10))}
                    opacity="0"
                />
            )}
            <polygon
                className=""
                points={`${xUp},${yUp} ${xBt},${yUp} ${xBt},${yBt} ${xUp},${yBt}`}
                fill="#ffffff"
                stroke={color}
                strokeWidth={size * 0.2}
                strokeOpacity="1"
            />
        </g>
    )
}

AnnotationCornerEdit.propTypes = {
    /** Center x */
    x: PropTypes.number.isRequired,
    /** Center y */
    y: PropTypes.number.isRequired,
    /** stroke color */
    color: PropTypes.string.isRequired,
    /** Size of the corner */
    size: PropTypes.number,
    ...DraggableObject.returnTypes,
}
AnnotationCornerEdit.defaultProps = {
    size: 15,
}

/**
 * Component for the central part of the annotation
 */
const AnnotationCentral = ({
    xUp,
    yUp,
    xBt,
    yBt,
    color,
    strokeWidth,
    strokeColor,
    strokeOpacity,
    fillOpacity,
    isSelected,
    isDragging,
    onMouseDown,
}) => {
    return (
        <g>
            {/* Draggable zone to make the movement of element wider */}
            {isDragging && (
                <polygon
                    points={pointsToString(...computeDraggableZone(xUp, yUp, xBt, yBt, 20))}
                    opacity="0"
                />
            )}
            <polygon
                points={`${xUp},${yUp} ${xBt},${yUp} ${xBt},${yBt} ${xUp},${yBt}`}
                fill={color}
                fillOpacity={fillOpacity * (1 + isSelected * 0.35)}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
                strokeOpacity={strokeOpacity * (1 + isSelected * 0.35)}
                style={{ cursor: isSelected ? 'grab' : 'auto' }}
                onMouseDown={onMouseDown}
            />
        </g>
    )
}

AnnotationCentral.propTypes = {
    /** Left up corner x */
    xUp: PropTypes.number.isRequired,
    /** Left up corner y */
    yUp: PropTypes.number.isRequired,
    /** Right bottom corner x */
    xBt: PropTypes.number.isRequired,
    /** Right bottom corner x */
    yBt: PropTypes.number.isRequired,
    /** Fill color */
    color: PropTypes.string.isRequired,
    /** Stroke color */
    strokeColor: PropTypes.string.isRequired,
    /** Stroke color */
    strokeOpacity: PropTypes.number.isRequired,
    /** Stroke color */
    fillOpacity: PropTypes.number.isRequired,
    /** True if the annotation is selected */
    isSelected: PropTypes.string.isRequired,
    /** Execute when selected */
    onMouseDown: PropTypes.func.isRequired,
    ...DraggableObject.returnTypes,
}

/**
 * Area to first create the annotation, then the annotations is modified
 * panning the corners. A svg polygon is created above the image to
 * allow the annotation drawing
 */
const EditableAnnotation = ({ index, transform, guidesEnabled, guidesWidth, guidesStroke }) => {
    // Local state
    const [dragging, setDragging] = useState(false)
    // Reference to the mouse position
    const originMouse = useRef(false)
    const [positionMouse, setPositionMouse] = useState([0, 0])

    const dispatch = useDispatch()

    // Get actual annotation
    const inPlaceAnnotation =
        useSelector(
            (state) =>
                _.get(
                    state.trainingSetObjectDetection.images.inPlaceAnnotations,
                    state.trainingSetObjectDetection.images.selectedEntry,
                    []
                )[index]
        ) || {}

    const rectLeftUpCorner = transform.applyInverseToPoint({
        x: 0,
        y: 0,
    })

    return (
        <g
            onMouseDown={(event) => {
                setDragging(true)
                // Svg parent offsets
                const clientRect =
                    event.target.parentNode.parentNode.parentNode.getBoundingClientRect()
                const transformedPoint = transform.applyInverseToPoint({
                    x: event.clientX - clientRect.left,
                    y: event.clientY - clientRect.top,
                })
                dispatch(
                    setByIndexInplaceAnnotation(
                        {
                            ...inPlaceAnnotation,
                            coords: [
                                transformedPoint.x,
                                transformedPoint.y,
                                transformedPoint.x,
                                transformedPoint.y,
                            ],
                        },
                        index
                    )
                )
                originMouse.current = [transformedPoint.x, transformedPoint.y]
                positionMouse.current = [transformedPoint.x, transformedPoint.y]
                dispatch(setToolbox('annotations'))
            }}
            onMouseMove={(event) => {
                // Svg parent offsets
                const clientRect =
                    event.target.parentNode.parentNode.parentNode.getBoundingClientRect()
                const transformedPositionPoint = transform.applyInverseToPoint({
                    x: event.clientX - clientRect.left,
                    y: event.clientY - clientRect.top,
                })
                setPositionMouse([transformedPositionPoint.x, transformedPositionPoint.y])
                if (dragging) {
                    const transformedPoint = transform.applyInverseToPoint({
                        x: event.clientX - clientRect.left,
                        y: event.clientY - clientRect.top,
                    })
                    const movX = transformedPoint.x - originMouse.current[0]
                    const movY = transformedPoint.y - originMouse.current[1]
                    dispatch(
                        setByIndexInplaceAnnotation(
                            {
                                ...inPlaceAnnotation,
                                coords: [
                                    inPlaceAnnotation.coords[0],
                                    inPlaceAnnotation.coords[1],
                                    inPlaceAnnotation.coords[2] + movX,
                                    inPlaceAnnotation.coords[3] + movY,
                                ],
                            },
                            index
                        )
                    )
                    originMouse.current = [transformedPoint.x, transformedPoint.y]
                }
            }}
            onMouseUp={() => {
                setDragging(false)
                dispatch(setEditMode(false))
                dispatch(setAnnotationIndex(null))
            }}
            onMouseLeave={() => {
                setDragging(false)
                if (inPlaceAnnotation.coords.every((val) => val === null)) {
                    dispatch(setAnnotationIndex(null))
                    dispatch(removeByIndexInPlaceAnnotation(index))
                }
                dispatch(setEditMode(false))
            }}
            onMouseEnter={(event) => {
                // Svg parent offsets
                const clientRect =
                    event.target.parentNode.parentNode.parentNode.getBoundingClientRect()
                const transformedPositionPoint = transform.applyInverseToPoint({
                    x: event.clientX - clientRect.left,
                    y: event.clientY - clientRect.top,
                })
                setPositionMouse([transformedPositionPoint.x, transformedPositionPoint.y])
            }}
        >
            {guidesEnabled && (
                <React.Fragment>
                    <rect
                        x={rectLeftUpCorner.x}
                        y={positionMouse[1] - guidesWidth / transform.transformMatrix.scaleY / 2}
                        width={window.innerWidth / transform.transformMatrix.scaleX}
                        height={guidesWidth / transform.transformMatrix.scaleY}
                        opacity={1}
                        fill="#ffffff"
                        fillOpacity={1}
                        stroke="#000000"
                        strokeWidth={(0.3 * guidesWidth) / transform.transformMatrix.scaleX}
                        strokeOpacity={1}
                    />
                    <rect
                        x={positionMouse[0] - guidesWidth / transform.transformMatrix.scaleX / 2}
                        y={rectLeftUpCorner.y}
                        width={guidesWidth / transform.transformMatrix.scaleX}
                        height={window.innerHeight / transform.transformMatrix.scaleY}
                        opacity={1}
                        fill="#ffffff"
                        fillOpacity={1}
                        stroke="#000000"
                        strokeWidth={(0.3 * guidesWidth) / transform.transformMatrix.scaleX}
                        strokeOpacity={1}
                    />
                </React.Fragment>
            )}
            <rect
                style={{ cursor: 'crosshair' }}
                x={rectLeftUpCorner.x}
                y={rectLeftUpCorner.y}
                width={window.innerWidth / transform.transformMatrix.scaleX}
                height={window.innerHeight / transform.transformMatrix.scaleY}
                opacity="0"
            />
        </g>
    )
}

EditableAnnotation.propTypes = {
    index: PropTypes.number.isRequired,
    transform: PropTypes.object.isRequired,
    guidesEnabled: PropTypes.bool.isRequired,
    guidesWidth: PropTypes.number.isRequired,
}

/**
 * Control the whole annotation
 */
const Annotations = ({ index, transform }) => {
    // Get active corner to render in order,
    // if not, the event of the corner are overlapped
    const activeCorner = useRef(leftUpString)

    const dispatch = useDispatch()
    const annotation = useSelector(
        (state) =>
            _.get(
                state.trainingSetObjectDetection.images.inPlaceAnnotations,
                state.trainingSetObjectDetection.images.selectedEntry,
                []
            )[index] || {}
    )
    const selectedAnnotation = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedAnnotation
    )
    const selectedEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedEntry
    )
    const inPlaceAnnotation =
        useSelector(
            (state) =>
                _.get(
                    state.trainingSetObjectDetection.images.inPlaceAnnotations,
                    selectedEntry,
                    []
                )[index]
        ) || {}

    const classColor = useSelector(
        (state) =>
            _.get(
                state.trainingSetObjectDetection.general.classProps,
                _.get(annotation, 'class', null),
                {
                    color: '',
                }
            ).color
    )
    const editMode = useSelector((state) => state.trainingSetObjectDetection.images.editMode)

    // Style options
    const annotationStyle = useSelector(
        (state) => state.trainingSetObjectDetection.settings.style.annotations
    )
    const guidesStyle = useSelector(
        (state) => state.trainingSetObjectDetection.settings.style.guides
    )

    const [xUp, yUp, xBt, yBt] = _.get(annotation, 'coords', [null, null, null, null])

    const color = classColor.toLowerCase()
    let strokeColor = LightenDarkenColor(color, 5)
    // Fix for an error in LightenDarkenColor, some resulting colors
    // has 5 characters instead of 6
    strokeColor = `${strokeColor}${'f'.repeat(7 - strokeColor.length)}`

    const isSelected = selectedAnnotation === index

    // Events
    const annotationMouseDown = () => {
        dispatch(setAnnotationIndex(index))
        dispatch(setToolbox('annotations'))
    }

    const moveEffect = (movX, movY) => {
        dispatch(
            setByIndexInplaceAnnotation(
                {
                    ...inPlaceAnnotation,
                    coords: [
                        inPlaceAnnotation.coords[0] + movX,
                        inPlaceAnnotation.coords[1] + movY,
                        inPlaceAnnotation.coords[2] + movX,
                        inPlaceAnnotation.coords[3] + movY,
                    ],
                },
                index
            )
        )
    }

    const leftUpMoveEffect = (movX, movY) => {
        activeCorner.current = leftUpString
        dispatch(
            setByIndexInplaceAnnotation(
                {
                    ...inPlaceAnnotation,
                    coords: [
                        inPlaceAnnotation.coords[0] + movX,
                        inPlaceAnnotation.coords[1] + movY,
                        inPlaceAnnotation.coords[2],
                        inPlaceAnnotation.coords[3],
                    ],
                },
                index
            )
        )
    }

    const rightUpMoveEffect = (movX, movY) => {
        activeCorner.current = rightUpString
        dispatch(
            setByIndexInplaceAnnotation(
                {
                    ...inPlaceAnnotation,
                    coords: [
                        inPlaceAnnotation.coords[0],
                        inPlaceAnnotation.coords[1] + movY,
                        inPlaceAnnotation.coords[2] + movX,
                        inPlaceAnnotation.coords[3],
                    ],
                },
                index
            )
        )
    }

    const rightBtMoveEffect = (movX, movY) => {
        activeCorner.current = rightBtString
        dispatch(
            setByIndexInplaceAnnotation(
                {
                    ...inPlaceAnnotation,
                    coords: [
                        inPlaceAnnotation.coords[0],
                        inPlaceAnnotation.coords[1],
                        inPlaceAnnotation.coords[2] + movX,
                        inPlaceAnnotation.coords[3] + movY,
                    ],
                },
                index
            )
        )
    }

    const leftBtMoveEffect = (movX, movY) => {
        activeCorner.current = leftBtString
        dispatch(
            setByIndexInplaceAnnotation(
                {
                    ...inPlaceAnnotation,
                    coords: [
                        inPlaceAnnotation.coords[0] + movX,
                        inPlaceAnnotation.coords[1],
                        inPlaceAnnotation.coords[2],
                        inPlaceAnnotation.coords[3] + movY,
                    ],
                },
                index
            )
        )
    }

    // The selected corner always must be rendered the last.
    // Otherwise the event will be overlapped by other component
    const corners = {}
    corners[leftUpString] = { x: xUp, y: yUp, color: strokeColor, moveEffect: leftUpMoveEffect }
    corners[rightUpString] = { x: xBt, y: yUp, color: strokeColor, moveEffect: rightUpMoveEffect }
    corners[rightBtString] = { x: xBt, y: yBt, color: strokeColor, moveEffect: rightBtMoveEffect }
    corners[leftBtString] = { x: xUp, y: yBt, color: strokeColor, moveEffect: leftBtMoveEffect }

    let cornersRenderOrder = Object.keys(corners)
    cornersRenderOrder = _.without(cornersRenderOrder, activeCorner.current)
    cornersRenderOrder.push(activeCorner.current)
    cornersRenderOrder = cornersRenderOrder.map((corner) => {
        const cornerObj = corners[corner]
        return (
            <DraggableObject key={corner} transform={transform} moveEffect={cornerObj.moveEffect}>
                <AnnotationCornerEdit
                    {...{
                        x: cornerObj.x,
                        y: cornerObj.y,
                        color: cornerObj.color,
                        size: 15 / transform.transformMatrix.scaleX,
                    }}
                />
            </DraggableObject>
        )
    })
    let renderEditableZone = null
    if (isSelected && editMode) {
        renderEditableZone = (
            <EditableAnnotation
                index={index}
                transform={transform}
                guidesEnabled={guidesStyle.enabled}
                guidesWidth={guidesStyle.width}
            />
        )
    }

    const annotationRender = !_.isEqual([xUp, yUp, xBt, yBt], [null, null, null, null])

    return (
        <React.Fragment>
            {annotationRender && (
                <DraggableObject transform={transform} moveEffect={moveEffect}>
                    <AnnotationCentral
                        {...{ xUp, yUp, xBt, yBt, color, strokeColor, isSelected }}
                        strokeWidth={annotationStyle.strokeWidth / transform.transformMatrix.scaleX}
                        strokeOpacity={annotationStyle.strokeOpacity}
                        fillOpacity={annotationStyle.fillOpacity}
                        onMouseDown={annotationMouseDown}
                    />
                </DraggableObject>
            )}
            {annotationRender && isSelected && cornersRenderOrder}
            {renderEditableZone}
        </React.Fragment>
    )
}

Annotations.propTypes = {
    /** Index of the annotation */
    index: PropTypes.number.isRequired,
    /** Transform for change coordinates vx/zoom */
    transform: PropTypes.object.isRequired,
}

export default Annotations
