export const ROWS_PER_PAGE = 100 // eslint-disable-line import/prefer-default-export

// EDIT MODES
export const EDIT_MODE_SELECT = 'select'
export const EDIT_MODE_CREATE_POLYGONS = 'createPolygons'
export const EDIT_MODE_REMOVE_POLYGONS = 'removePolygons'
export const EDIT_MODE_CREATE_POINTS = 'createPoints'
export const EDIT_MODE_REMOVE_POINTS = 'removePoints'

// TOOLBOXES
export const TOOLBOX_SAMPLES = 'samples'
export const TOOLBOX_ANNOTATION = 'annotation'
export const TOOLBOX_POLYGONS_AND_POINTS = 'polygonsAndPoints'
