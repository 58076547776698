import { combineReducers } from "redux"

import NewDatasetDialog from "./datasets/new-dataset-dialog"
import DatasetsView from "./datasets/datasets-view"
import * as General from "./general"

import upload_dialog from "../containers/viewer/containers/upload-dialog/reducers"
import settings from "./settings"
import images from "./images"

import side_menu from "../containers/viewer/containers/side-menu/reducers"
import reports from "../containers/reports/reducers"

export default combineReducers({
    newDatasetDialog: NewDatasetDialog, 
    datasetsView: DatasetsView,
    general: General.default,
    upload_dialog,
    settings,
    images,
    side_menu,
    reports
})