import React from "react"
import { Switch, Route } from "react-router-dom"

import PaymentMethods from "./containers/payment-methods"
import DefaultPaymentMethods from "./containers/default-payment-method"

export default function({ match }) {
    return (
        <Switch>
            <Route path={match.url + "/payment-methods"} exact component={PaymentMethods} />
            <Route path={match.url + "/default-payment-method"} exact component={DefaultPaymentMethods} />
        </Switch>
    )
}