import 'react-hot-loader'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './app/App'

try {
    require('react-tap-event-plugin')() // eslint-disable-line global-require
} catch (err) {
    // continue regardless of error
}

if (module.hot) module.hot.accept()

const container = document.getElementById('render')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<App />)
// ReactDOM.render(<App />) // eslint-disable-line no-undef
