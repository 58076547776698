import React, { useEffect, useState } from "react"

import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism-coy.css'

import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"
import { useEditor } from "@craftjs/core";

import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';

import { UPDATE_HMI_BY_ID, GET_HMI_BY_ID } from "../../../../queries/hmi"

const useStyles = makeStyles((theme) => ({
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));

export default (props) => {
    const classes = useStyles()

    const { hmi } = useParams()
    const { actions, query } = useEditor();

    const [ loading, setLoading ] = useState(false)
    const [ code, setCode ] = useState("")

    useEffect(() => { 
        const output = JSON.parse(query.serialize())
        
        const entries = Object.entries(output)
        
        if(entries.length > 0) { 
            const search = (n) => {
                
                const current = output[n]
        
                const { _id = n, ...properties } = current.props
        
                const children = entries.filter(([_, value]) => value.parent === n).map(([key]) => key)
                
                return ({
                    _id: _id,
                    root: !current.parent,
                    type: current.type.resolvedName,
                    properties: properties,
                    components: children.map(search)
                })

            }
        
            const nodes = search("ROOT")

            setCode(JSON.stringify(nodes, null, 2))
        }
    }, [])

    const onCodeChanged = value => {
        setCode(value)
        try {
            const flat = {}
            const parsed = JSON.parse(value)

            const flatten = (node, parent) => {
                flat[node._id] = {
                    props: node.properties,
                    type: {
                        resolvedName: node.type
                    },
                    nodes: node.components.map(c => flatten(c, node._id)),
                    isCanvas: true,
                    parent: parent
                }

                return node._id
            }

            flatten(parsed, null)
            
            actions.deserialize(flat)
        } catch(err) {
            console.log(err)
        }
    }
    

    return (
        <div style={{ border: "1px solid #333", height: "100%" }}>
            <Editor
                value={code}
                onValueChange={onCodeChanged}
                padding={10}
                highlight={cod => highlight(cod, languages.js)}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                    minHeight: "100%"
                }}
            />
        </div>
    )
}