import { combineReducers } from "redux"

import selector from "./containers/selector/reducers"
import solar from "./containers/solar/reducers"
import universal from "./containers/universal/reducers"
import polygon from "./containers/training-sets/containers/polygon/reducers"

export default () => {
    return {
        id: "horus",
        reducerMap: {
            inspector: combineReducers({
                selector,
                solar,
                universal
            }),
            horus: combineReducers({
                trainingSets: combineReducers({
                    polygon
                })
            })
        }
    }
}