import React, { useState } from "react"

import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"

import Fab from '@material-ui/core/Fab';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
      position: "absolute",
      bottom: 15,
      left: 75
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));

const CreateJobDialog = (props) => {
    const classes = useStyles();

    const [ open, setOpen ] = useState(false)
    const [ type, setType ] = useState(null)

    const onOpenClick = () => {
        setOpen(true)
    }

    const onSetType = (e, newValue) => {
        setType(newValue)
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onAccept = () => {
    }

    return (
        <React.Fragment>
            <Dialog open={open}>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Analysis Type</InputLabel>
                        <Select value={type} onChange={onSetType}>
                            <MenuItem value={"plants"}>Invassive Plants</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button disabled={!type} onClick={onAccept}>Accept</Button>
                </DialogActions>
            </Dialog>
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.margin}
                onClick={onOpenClick}
            >
                <AddIcon className={classes.extendedIcon} />
                Create Job
            </Fab>
        </React.Fragment>
    )
}

export default CreateJobDialog