import React, { useState } from "react"

import { useQuery } from "@apollo/react-hooks"
import { useDispatch, useSelector } from "react-redux"

import Divider from "@mui/material/Divider"
import Button from "@mui/material/Button"
import Chip from '@mui/material/Chip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { GET_DATASET_V2_BY_ID, GET_DATASETS_V2_BY_PARENT, QUERY_DATASETS_V2_ENTRIES_BY_DATASET } from "../../queries/datasets"
import { changeCurrentDataset } from "../../reducer"

import Data from "./containers/data/scene"
import Filters from "./containers/filters/scene"

const tabs = [
    Data,
    Filters
]

const LeftBar = () => {

    const { data: { getDatasetsV2ByParent: { datasets = []} = {}} = {}} = useQuery(GET_DATASETS_V2_BY_PARENT, { variables: { params: {}}})
    const { data: { getDatasetV2ById: { dataset: { fields = []} = {}} = {}} = {}} = useQuery(GET_DATASET_V2_BY_ID, { variables: { params: { id: selectedDataset }}, skip: !selectedDataset })

    const [ title, setTitle ] = useState("")
    const [ widgetHeight, setWidgetHeight ] = useState(0)
    const [ widgetWidth, setWidgetWidth ] = useState(0)
    const [ selectedDataset, setSelectedDataset ] = useState("")
    const [ xField, setXField ] = useState("")
    const [ yField, setYField ] = useState("")
    const [ aggregation, setAggregation ] = useState("none")

    const [ operators, setOperators ] = useState([])
    
    const onTitleChanged = e => setTitle(e.target.value)
    const onSelectedDatasetChanged = e => setSelectedDataset(e.target.value)

    const onXFieldChange = e => setXField(e.target.value)
    const onYFieldChange = e => setYField(e.target.value)
    const onAggregationChange = e => setAggregation(e.target.value)

    const onAddAggregationClick = () => {
        setOperators([
            ...operators,
            {
                name: "y",
                field: yField,
                mode: "aggregate",
                type: aggregation,
                properties: []
            }
        ])
    }


    const dispatch = useDispatch()

    const [ tab, setTabs ] = useState(0)

    const { current_dataset } = useSelector(state => state.dashboards.bar_chart)

    const onTabChange = (e, value) => setTabs(value)
    const onCurrentDatasetChange = e => dispatch(changeCurrentDataset(e.target.value))

    const CurrenTab = tabs[tab] 

    return (
        <List style={{ height: "100%" }}>
            <ListItem>
                <ListItemText primary="FUENTE DE DATOS"/>
            </ListItem>
            <ListItem>
                <FormControl variant="filled" fullWidth>
                    <InputLabel>Dataset</InputLabel>
                    <Select value={current_dataset} onChange={onCurrentDatasetChange}>
                        {
                            datasets.map(d => {
                                return (
                                    <MenuItem value={d.id}>{d.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </ListItem>
            <Divider/>
            <Tabs variant="fullWidth" value={tab} onChange={onTabChange}>
                <Tab label="Datos" />
                <Tab label="Filtrar" />
            </Tabs>
            <Divider/>
            <CurrenTab />
        </List>
    )
}

export default LeftBar