import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import CreateDeviceDialog from './containers/create-device-dialog'

import { GET_DEVICES_BY_GROUP, REMOVE_DEVICE } from '../../../../queries/devices'
import { openNewDevicesDialog } from './reducers'

import { useIsAllowed } from 'queries/permissions'

export default () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { group } = useParams()

    const { data: { getDevicesByGroup: { devices = [] } = {} } = {} } = useQuery(
        GET_DEVICES_BY_GROUP,
        { variables: { params: { group } } }
    )
    const [removeDevice] = useMutation(REMOVE_DEVICE)
    const { isAllowed: isCreateDeviceAllowed } = useIsAllowed({
        action: 'createDevice',
        group: 'groups',
    })
    const { isAllowed: isRemoveDeviceAllowed } = useIsAllowed({
        action: 'removeDevice',
        group: 'groups',
    })

    const onFabClick = () => {
        dispatch(openNewDevicesDialog())
    }

    const onRemoveDevice = (_id) => () => {
        removeDevice({
            variables: {
                params: {
                    _id,
                },
            },
            refetchQueries: [
                {
                    query: GET_DEVICES_BY_GROUP,
                    variables: {
                        params: {
                            group,
                        },
                    },
                },
            ],
        })
    }

    return (
        <React.Fragment>
            {devices.length > 0 ? (
                <Paper>
                    <List>
                        {devices.map((device) => (
                            <ListItem>
                                <ListItemText
                                    primary={device.name}
                                    secondary={device._id}
                                ></ListItemText>
                                {isRemoveDeviceAllowed && (
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={onRemoveDevice(device._id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            ) : (
                <Typography align="center">
                    No hay ningún dispositivo. Puede añadir uno pulsando "+"
                </Typography>
            )}
            <CreateDeviceDialog />
            {isCreateDeviceAllowed && (
                <div className={classes.fab}>
                    <Tooltip title="Nuevo Dispositivo" placement="left">
                        <Fab onClick={onFabClick} color="primary">
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
            )}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))
