import React from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { Link, useHistory, useParams } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'

import DeleteIcon from '@material-ui/icons/Delete'

import { GET_BLUEPRINTS_BY_GROUP, REMOVE_BLUEPRINT } from '../../../../../../queries/blueprints'

import Loading from 'components/Loading'

import { useIsAllowed } from 'queries/permissions'

function BlueprintsListRow(props) {
    return (
        <ListItem button>
            <ListItemText primary={props.name} secondary={props.id} onClick={props.onClick} />
            {props.delete && (
                <ListItemSecondaryAction>
                    <IconButton onClick={props.onDelete}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    )
}

function BlueprintsList(props) {
    const history = useHistory()
    const { group } = useParams()

    const { isAllowed: isRemoveBlueprint } = useIsAllowed({
        action: 'removeBlueprint',
        group: 'groups',
    })
    const { data: { getBlueprintsByGroup: { blueprints = [] } = {} } = {}, loading } = useQuery(
        GET_BLUEPRINTS_BY_GROUP,
        { variables: { params: { group } } }
    )

    const [removeBlueprint] = useMutation(REMOVE_BLUEPRINT)

    const onRemoveBlueprint = (_id) => () => {
        removeBlueprint({
            variables: {
                params: {
                    _id,
                },
            },
            refetchQueries: [
                {
                    query: GET_BLUEPRINTS_BY_GROUP,
                    variables: {
                        params: {
                            group,
                        },
                    },
                },
            ],
        })
    }

    return (
        <React.Fragment>
            <Loading loading={loading}>
                {blueprints.length === 0 ? (
                    <div style={{ margin: '20px', textAlign: 'center' }}>
                        Cree un blueprint para continuar &#8600;
                    </div>
                ) : (
                    <Paper elevation={2}>
                        <List>
                            {blueprints.map((blueprint) => {
                                return (
                                    <BlueprintsListRow
                                        id={blueprint._id}
                                        name={blueprint.name}
                                        delete={isRemoveBlueprint}
                                        onDelete={onRemoveBlueprint(blueprint._id)}
                                        onClick={() =>
                                            history.push(group + '/blueprints/' + blueprint._id)
                                        }
                                    />
                                )
                            })}
                        </List>
                    </Paper>
                )}
            </Loading>
        </React.Fragment>
    )
}

export default BlueprintsList
