import {
    AUTOSAVE_TIME_DEFAULT,
    STYLE_ANN_STROKE_WIDTH_DEFAULT,
    STYLE_ANN_FILL_OPACITY_DEFAULT,
    STYLE_ANN_STROKE_OPACITY_DEFAULT,
    STYLE_GUIDES_ENABLED_DEFAULT,
    STYLE_GUIDES_WIDTH_DEFAULT,
} from '../constants'

const SET_TEMPLATE_MATCHING_ENABLED = 'TEMPLATE_MATCHING_ENABLED/SET'
const ADD_TEMPLATE_MATCHING_TEMPLATE = 'TEMPLATE_MATCHING_TEMPLATE/ADD'
const SELECT_TEMPLATE_MATCHING_TEMPLATE = 'TEMPLATE_MATCHING_TEMPLATE/SELECT'
const REMOVE_TEMPLATE_MATCHING_TEMPLATE = 'TEMPLATE_MATCHING_TEMPLATE/REMOVE'
const SET_TEMPLATE_MATCHING_IMAGE = 'TEMPLATE_MATCHING_IMAGE/SET'
const SET_TEMPLATE_MATCHING_CROP = 'TEMPLATE_MATCHING_CROP/SET'
const SET_TEMPLATE_MATCHING_CLASSES = 'TEMPLATE_MATCHING_CLASSES/SET'
const SET_AUTOLABEL_MODEL = 'AUTOLABEL_MODEL/SET'
const SET_AUTOLABEL_DELIVERABLE = 'AUTOLABEL_DELIVERABLE/SET'
const SET_AUTOLABEL_CONFIDENCE_THRESHOLD = 'AUTOLABEL_CONFIDENCE_THRESHOLD/SET'
const SET_AUTOLABEL_CLASS_THRESHOLD = 'AUTOLABEL_CLASS_THRESHOLD/SET'
const SET_AUTOLABEL_NMS_THRESHOLD = 'AUTOLABEL_NMS_THRESHOLD/SET'
const SET_AUTOSAVE_CONF = 'AUTOSAVE_CONF'
const SET_STYLE_CONF = 'STYLE_CONF'

export const setTemplateMatchingEnabled = (enabled) => ({
    type: SET_TEMPLATE_MATCHING_ENABLED,
    enabled,
})
export const selectTemplateMatchingTemplate = (index) => ({
    type: SELECT_TEMPLATE_MATCHING_TEMPLATE,
    index,
})
export const addTemplateMatchingTemplate = () => ({
    type: ADD_TEMPLATE_MATCHING_TEMPLATE,
})
export const removeTemplateMatchingTemplate = (index) => ({
    type: REMOVE_TEMPLATE_MATCHING_TEMPLATE,
    index,
})
export const setTemplateMatchingImage = (index, image) => ({
    type: SET_TEMPLATE_MATCHING_IMAGE,
    index,
    image,
})
export const setTemplateMatchingCrop = (index, crop) => ({
    type: SET_TEMPLATE_MATCHING_CROP,
    index,
    crop,
})
export const setTemplateMatchingClasses = (index, classes) => ({
    type: SET_TEMPLATE_MATCHING_CLASSES,
    index,
    classes,
})
export const setAutolabelModel = (model) => ({ type: SET_AUTOLABEL_MODEL, model })
export const setAutolabelDeliverable = (deliverable) => ({
    type: SET_AUTOLABEL_DELIVERABLE,
    deliverable,
})
export const setAutolabelConfidenceThreshold = (autolabelConfidenceThreshold) => ({
    type: SET_AUTOLABEL_CONFIDENCE_THRESHOLD,
    autolabelConfidenceThreshold,
})
export const setAutolabelClassThreshold = (autolabelClassThreshold) => ({
    type: SET_AUTOLABEL_CLASS_THRESHOLD,
    autolabelClassThreshold,
})
export const setAutolabelNmsThreshold = (autolabelNmsThreshold) => ({
    type: SET_AUTOLABEL_NMS_THRESHOLD,
    autolabelNmsThreshold,
})
export const setAutoSaveConf = (autosaveConf) => ({
    type: SET_AUTOSAVE_CONF,
    autosaveConf,
})
export const setStyleConf = (styleConf) => ({
    type: SET_STYLE_CONF,
    styleConf,
})

export const initialState = {
    templateMatching: {
        enabled: false,
        selectedTemplate: null,
        templates: [],
    },
    autolabelModel: null,
    autolabelDeliverable: null,
    autolabelConfidenceThreshold: 0.5,
    autolabelClassThreshold: 0.5,
    autolabelNmsThreshold: 0.5,
    autosave: {
        time: AUTOSAVE_TIME_DEFAULT,
    },
    style: {
        annotations: {
            strokeWidth: STYLE_ANN_STROKE_WIDTH_DEFAULT,
            strokeOpacity: STYLE_ANN_STROKE_OPACITY_DEFAULT,
            fillOpacity: STYLE_ANN_FILL_OPACITY_DEFAULT,
        },
        guides: {
            enabled: STYLE_GUIDES_ENABLED_DEFAULT,
            width: STYLE_GUIDES_WIDTH_DEFAULT,
        },
    },
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEMPLATE_MATCHING_ENABLED:
            return {
                ...state,
                templateMatching: { ...state.templateMatching, enabled: action.enabled },
            }
        case SELECT_TEMPLATE_MATCHING_TEMPLATE:
            return {
                ...state,
                templateMatching: {
                    ...state.templateMatching,
                    selectedTemplate: action.index,
                },
            }
        case ADD_TEMPLATE_MATCHING_TEMPLATE:
            return {
                ...state,
                templateMatching: {
                    ...state.templateMatching,
                    templates: [
                        ...state.templateMatching.templates,
                        { image: null, crop: null, classes: [] },
                    ],
                },
            }
        case REMOVE_TEMPLATE_MATCHING_TEMPLATE:
            return {
                ...state,
                templateMatching: {
                    ...state.templateMatching,
                    templates: state.templateMatching.templates.filter(
                        (template, index) => index !== action.index
                    ),
                },
            }
        case SET_TEMPLATE_MATCHING_IMAGE:
            return {
                ...state,
                templateMatching: {
                    ...state.templateMatching,
                    templates: state.templateMatching.templates.map((template, index) =>
                        index === action.index ? { ...template, image: action.image } : template
                    ),
                },
            }
        case SET_TEMPLATE_MATCHING_CROP:
            return {
                ...state,
                templateMatching: {
                    ...state.templateMatching,
                    templates: state.templateMatching.templates.map((template, index) =>
                        index === action.index ? { ...template, crop: action.crop } : template
                    ),
                },
            }
        case SET_TEMPLATE_MATCHING_CLASSES:
            return {
                ...state,
                templateMatching: {
                    ...state.templateMatching,
                    templates: state.templateMatching.templates.map((template, index) =>
                        index === action.index ? { ...template, classes: action.classes } : template
                    ),
                },
            }
        case SET_AUTOLABEL_MODEL:
            return { ...state, autolabelModel: action.model }
        case SET_AUTOLABEL_DELIVERABLE:
            return { ...state, autolabelDeliverable: action.deliverable }
        case SET_AUTOLABEL_CONFIDENCE_THRESHOLD:
            return {
                ...state,
                autolabelConfidenceThreshold: action.autolabelConfidenceThreshold,
            }
        case SET_AUTOLABEL_CLASS_THRESHOLD:
            return { ...state, autolabelClassThreshold: action.autolabelClassThreshold }
        case SET_AUTOLABEL_NMS_THRESHOLD:
            return { ...state, autolabelNmsThreshold: action.autolabelNmsThreshold }
        case SET_AUTOSAVE_CONF:
            return { ...state, autosave: { ...state.autosave, ...action.autosaveConf } }
        case SET_STYLE_CONF:
            return { ...state, style: { ...state.style, ...action.styleConf } }
        default:
            return state
    }
}
