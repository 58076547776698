import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

import Loading from 'components/Loading'
import TrainingSetsListRow from './components/training-sets-list-row'
import { GET_TRAINING_SETS } from '../../../../queries/training-sets'

const TrainingSetsList = () => {
    const { data: { getTrainingSets: { trainingSets = [] } = {} } = {}, loading } = useQuery(
        GET_TRAINING_SETS,
        { variables: { params: { parent: null } } }
    )

    const notRemovedTrainingSets = trainingSets.filter((trainSet) => trainSet.state !== 'REMOVED')

    return (
        <Container style={{ paddingTop: 30 }}>
            <Typography variant="h2">Training Sets</Typography>
            <div style={{ marginTop: 20 }}>
                <Loading loading={loading}>
                    {notRemovedTrainingSets.length ? (
                        <Paper>
                            <List>
                                {notRemovedTrainingSets.map((trainingSet) => (
                                    <TrainingSetsListRow key={trainingSet._id} {...trainingSet} />
                                ))}
                            </List>
                        </Paper>
                    ) : (
                        <div style={{ margin: '20px', textAlign: 'center' }}>
                            Cree un set de entrenamiento para continuar &#8600;
                        </div>
                    )}
                </Loading>
            </div>
        </Container>
    )
}

export default TrainingSetsList
