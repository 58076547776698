import gql from 'graphql-tag'

export const GET_VARIABLES_BY_GROUP = gql`
    query GetVariablesByGroup($params: GetVariablesByGroupRequest!) {
        getVariablesByGroup(params: $params) {
            status
            variables {
                _id
                name
                type
                default
            }
        }
    }
`