import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import * as EmailValidator from 'email-validator'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import LoadingButton from 'components/loading-button'

import EmailIcon from '@material-ui/icons/Email'
import PasswordIcon from '@material-ui/icons/Lock'

import LeftSide from '../components/LeftSide'
import RightSide from '../components/RightSide'
import Container from '../components/Container'

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(1),
        fontSize: 15,
    },
}))

function Login(props) {
    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const onEmailChanged = (e) => setEmail(e.target.value)
    const onPasswordChanged = (e) => setPassword(e.target.value)
    const submit = async () => {
        console.log('submit')
        if (!EmailValidator.validate(email)) {
            setEmailError('Introduzca un email válido')
            return
        } else {
            setEmailError('')
        }

        if (password.length === 0) {
            setPasswordError('Introduzca una contraseña válida')
            return
        } else {
            setPasswordError('')
        }

        setLoading(true)
        try {
            console.log(props)
            const res = await props.login({
                email,
                password,
            })
            if (res.status === 'FAILED') {
                setOpen(true)
                setLoading(false)
            }
        } catch (err) {
            setOpen(true)
            setLoading(false)
        }
    }
    const onAccept = () => setOpen(false)
    const onKeyPress = (event) => {
        console.log(event.key)
        if (event.key === 'Enter') {
            submit()
        }
    }

    return (
        <Container>
            <LeftSide />
            <RightSide>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" style={{ marginBottom: 10 }}>
                        Log In
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Email"
                        placeholder="Enter you email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={onEmailChanged}
                        onKeyPress={onKeyPress}
                        error={emailError.length}
                        helperText={emailError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Password"
                        type="password"
                        placeholder="Enter password"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PasswordIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Link to={'forgot-password'}>Forgot password?</Link>
                                </InputAdornment>
                            ),
                        }}
                        onKeyPress={onKeyPress}
                        onChange={onPasswordChanged}
                        error={passwordError.length}
                        helperText={passwordError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        loading={loading}
                        variant="contained"
                        color="primary"
                        onClick={submit}
                        loading={loading}
                        fullWidth
                        className={classes.button}
                    >
                        Log in
                    </LoadingButton>
                </Grid>
            </RightSide>
            <Dialog open={open}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        La información introducida no corresponde a ningún cliente
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'

import { setAccessToken, setRefreshToken, setIdToken } from '../reducers/auth'

import { withRouter } from 'react-router-dom'

function getHashValue(hash, key) {
    var matches = hash.match(new RegExp(key + '=([^&]*)'))
    return matches ? matches[1] : null
}

export default compose(
    withRouter,
    connect(
        (state) => ({
            access_token: state.Auth.access_token,
        }),
        (dispatch) => ({
            setAccessToken: (access_token) => dispatch(setAccessToken(access_token)),
            setRefreshToken: (refresh_token) => dispatch(setRefreshToken(refresh_token)),
            setIdToken: (id_token) => dispatch(setIdToken(id_token)),
        })
    ),
    graphql(
        gql`
            mutation Login($params: LogInInput!) {
                login(params: $params) {
                    state
                    session
                    access_token
                    id_token
                    refresh_token
                    error
                }
            }
        `,
        {
            props: ({ mutate, ownProps }) => {
                return {
                    login: ({ email, password }) => {
                        return mutate({ variables: { params: { email, password } } })
                            .then((res) => {
                                if (res.data.login.state === 'SUCCESS') {
                                    localStorage.setItem('id_token', res.data.login.id_token)
                                    localStorage.setItem(
                                        'access_token',
                                        res.data.login.access_token
                                    )
                                    localStorage.setItem(
                                        'refresh_token',
                                        res.data.login.refresh_token
                                    )

                                    const redirect =
                                        getHashValue(ownProps.location.hash, 'redirect') ||
                                        '/private' + ownProps.location.hash
                                    ownProps.history.push(redirect)

                                    return {
                                        status: 'SUCCEEDED',
                                    }
                                } else if (res.data.login.state === 'NEW_PASSWORD_REQUIRED') {
                                    ownProps.history.push(
                                        `new-password/${email}/${res.data.login.session}` +
                                            ownProps.location.hash
                                    )

                                    return {
                                        status: 'SUCCEDED',
                                    }
                                } else {
                                    return {
                                        status: 'FAILED',
                                    }
                                }
                            })
                            .catch((err) => {
                                console.log('*********************************')
                                console.log(err)

                                return {
                                    status: 'FAILED',
                                }
                            })
                    },
                }
            },
        }
    )
)(Login)
