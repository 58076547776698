const SET_PARAMS_BULK = 'SET_PARAMS_BULK'

export const setBulkParams = (parameters) => ({
    type: SET_PARAMS_BULK,
    parameters,
})

export default (
    state = {
        parameters: {},
    },
    action
) => {
    switch (action.type) {
        case SET_PARAMS_BULK:
            return {
                ...state.parameters,
                ...action.parameters.reduce((prev, next) => {
                    return {
                        ...prev,
                        [next._id]: {
                            name: next.name || '',
                            value: next.local || null,
                            type: next.type || null,
                        },
                    }
                }, {}),
            }
        default:
            return state
    }
}
