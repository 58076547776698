import React, { useState } from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import CameraAltIcon from '@material-ui/icons/CameraAlt';

import QrReader from 'react-qr-reader'

import { CREATE_DEVICE } from "../../../../../queries/devices"
import { closeNewDevicesDialog } from "../reducers/index"

function CreateDeviceDialog() {

    const dispatch = useDispatch()

    const { group } = useParams();
    const { open } = useSelector(state => state.group.devices)

    const [ scan, setScan ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ thing, setThing ] = useState("")
    const [ name, setName ] = useState("")

    const [ createDevice ] = useMutation(CREATE_DEVICE)

    const closeDialog = () => {
        dispatch(closeNewDevicesDialog())
    }

    const onScan = data => {
        if(data) {
            const parsed = JSON.parse(data)
            setThing(parsed["thing_id"])
            setScan(false)
        }
    }

    const onError = err => {
        console.log(err)
    }

    const onCameraClicked = () => {
        setScan(true)
    }

    const onThingChange = e => {
        setThing(e.target.value)
    }

    const onNameChange = e => {
        setName(e.target.value)
    }

    const onAccept = async () => {
        setLoading(true)
        await createDevice({
            variables: {
                params: {
                    group,
                    name,
                    thing
                }
            }
        })
        setLoading(false)
        closeDialog()
    }

    const onCancel = async () => {
        closeDialog()
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Añadir dispositivo</DialogTitle>
            <DialogContent>
                <TextField
                    label="Nombre"
                    value={name}
                    onChange={onNameChange}
                    fullWidth
                />
                { 
                    scan && <QrReader
                        delay={300}
                        onError={onError}
                        onScan={onScan}
                        style={{ width: '100%', transform: "rotateY(180deg)" }}
                    />
                }
                <TextField
                    label="Identificador"
                    value={thing}
                    onChange={onThingChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={onCameraClicked}>
                                <CameraAltIcon />
                            </IconButton>
                        )
                    }}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} disabled={loading}>Cancelar</Button>
                <Button onClick={onAccept} disabled={loading}>Aceptar</Button>
            </DialogActions>
        </Dialog>
    )

}

export default CreateDeviceDialog;