import React, { useState } from "react"

import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

function TextEdit(props) {
    const [ open, setOpen ] = useState(false)

    const [ field, setField ] = useState(props.value)

    const onOpen = () => setOpen(true)
    const onFieldChanged = e => setField(e.target.value)
    const onCancel = () => setOpen(false)
    const onAccept = () => {
        props.onChange(field)
        setOpen(false)
    }

    return (
        <React.Fragment>
            <ListItem button onClick={onOpen}>
                <ListItemText primary={props.title} secondary={props.value} />
            </ListItem>
            <Dialog open={open}>
                <DialogTitle>Editar</DialogTitle>
                <DialogContent>
                    <TextField value={field} onChange={onFieldChanged}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default TextEdit