import React from 'react'

import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import ServicesList from './containers/list'

const Scene = () => {
    const match = useRouteMatch()

    return (
        <div style={{ height: 'calc(100vh - 64px)' }}>
            <Switch>
                <Route path={`${match.url}/list`} component={ServicesList} />
                <Redirect to={`${match.url}/list`} />
            </Switch>
        </div>
    )
}

export default Scene
