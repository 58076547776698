import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ shadows }) => ({
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        boxShadow: shadows[5],
    },
    active: {
        backgroundColor: '#ffffff',
        boxShadow: shadows[5],
    },
}))

const Icon = ({ loading }) => {
    const classes = useStyles()
    return (
        <div className={`${classes.circle} ${!loading ? classes.active : ''}`}>
            {loading && <CircularProgress size={14} color="secondary" thickness={6} />}
        </div>
    )
}

Icon.propTypes = {
    loading: PropTypes.bool.isRequired,
}

const ProgressSwitch = ({ value, setValue, loading, switchProps }) => {
    const handleChange = (event) => {
        setValue(event.target.checked)
    }

    return (
        <Switch
            checkedIcon={<Icon loading={loading} />}
            icon={<Icon loading={loading} />}
            // disabled={loading}
            checked={value}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Switch with loading state' }}
            {...switchProps}
        />
    )
}

ProgressSwitch.propTypes = {
    value: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    switchProps: PropTypes.object,
}

ProgressSwitch.defaultProps = {
    switchProps: {},
}

export default ProgressSwitch
