import React, { useState } from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ColorPicker from 'material-ui-color-picker'

const ButtonSettings = ({ query, actions, id }) => {
    const node = query.node(id).get()

    const [backgroundColor, setBackgroundColor] = useState(node.data.props.background)
    const [color, setColor] = useState(node.data.props.color)

    /* eslint-disable no-param-reassign */
    const onIdChange = (e) => {
        actions.setProp(id, (p) => {
            p._id = e.target.value
        })
    }

    const onContentChange = (e) => {
        actions.setProp(id, (p) => {
            p.content = e.target.value
        })
    }

    const onHeightChange = (e) => {
        actions.setProp(id, (p) => {
            p.height = e.target.value
        })
    }

    const onWidthChange = (e) => {
        actions.setProp(id, (p) => {
            p.width = e.target.value
        })
    }

    const onFontSizeChange = (e) => {
        actions.setProp(id, (p) => {
            p.fontSize = parseInt(e.target.value, 10)
        })
    }

    const onVariantChange = (e) => {
        actions.setProp(id, (p) => {
            p.variant = e.target.value
        })
    }

    const onBackgroundColorChange = (c) => {
        actions.setProp(id, (p) => {
            p.background = c
        })
        setBackgroundColor(c)
    }

    const onColorChange = (c) => {
        actions.setProp(id, (p) => {
            p.color = c
        })
        setColor(c)
    }

    return (
        <List>
            <ListItem>
                <TextField
                    label="ID"
                    onChange={onIdChange}
                    defaultValue={node.data.props._id || id}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Contenido"
                    defaultValue={node.data.props.content}
                    onChange={onContentChange}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Altura"
                    defaultValue={node.data.props.height}
                    onChange={onHeightChange}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Anchura"
                    defaultValue={node.data.props.width}
                    onChange={onWidthChange}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <TextField
                    label="Tamaño del texto"
                    type="number"
                    defaultValue={node.data.props.fontSize}
                    onChange={onFontSizeChange}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <div style={{ width: '100%' }}>
                    <ColorPicker
                        floatingLabelText="Color del fondo"
                        value={backgroundColor}
                        InputProps={{ value: backgroundColor }}
                        onChange={onBackgroundColorChange}
                        fullWidth
                    />
                </div>
            </ListItem>
            <ListItem>
                <div style={{ width: '100%' }}>
                    <ColorPicker
                        floatingLabelText="Color del texto"
                        value={color}
                        InputProps={{ value: color }}
                        onChange={onColorChange}
                        fullWidth
                    />
                </div>
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Variante</InputLabel>
                    <Select onChange={onVariantChange}>
                        <MenuItem value="text">Texto</MenuItem>
                        <MenuItem value="contained">Contenido</MenuItem>
                        <MenuItem value="outlined">Lineal</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    )
}

ButtonSettings.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default ButtonSettings
