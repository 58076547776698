import React, { useState, useEffect } from "react"

import { useHotkeys } from "react-hotkeys-hook"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from "notistack"

import { green } from '@material-ui/core/colors';

import Grid from "@material-ui/core/Grid"
import TablePagination from '@material-ui/core/TablePagination';
import Button from "@material-ui/core/Button"
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField"
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Divider from "@material-ui/core/Divider"
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DeleteIcon from '@material-ui/icons/Delete';

import { GET_ENTRIES_BY_TRAINING_SET, GET_TRAINING_SET_BY_ID } from "../../../../queries/training-sets"
import { GET_MULTI_LABEL_CLASSIFICATION_ENTRIES, MULTI_LABEL_CLASSIFICATION_GET_ENTRIES_COUNT, DELETE_TRAINING_SET_ENTRY_BY_ID} from "../../queries/multi-label-classification-entries"
import { SELECTED_IMAGE, SET_SELECTED_IMAGE } from "../../queries/state"

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -1,
      left: -1,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    section: {
        margin: 7
    },
    formControl: {
        minWidth: 120
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const rowsPerPage = 100

function ImagesMenu(props) {
    const classes = useStyles();
    const history = useHistory()

    const { trainingSet, page = 0 } = useParams()
    const { state: { showUnlabelled: showUnlabelledInit = true, selectedClasses: selectedClassesInit = null} = {}} = useLocation()

    const [ goPage, setGoPage ] = useState()
    const [ showUnlabelled, setShowUnlabelled ] = useState(showUnlabelledInit)
    const [ removeLoading, setRemoveLoading ] = useState(false)
    const [ selectedClasses, setSelectedClasses ] = useState(selectedClassesInit || [])

    const { loading, data: { getTrainingSetById: { trainingSet: { classes: trainingSetClasses = [] } = {}} = {}} = {}} = useQuery(GET_TRAINING_SET_BY_ID, { variables: { params: { _id: trainingSet } }})
    
    useEffect(() => {
        setSelectedClasses(!!selectedClassesInit ? selectedClassesInit : trainingSetClasses)
    }, [ loading ])


    const { data: { getTrainingSetEntriesByTrainingSet: { count = 0, entries = [] } = {}} = {}} = useQuery(GET_ENTRIES_BY_TRAINING_SET, { 
        variables: { 
            params: {
                trainingSet,
                offset: page * rowsPerPage,
                limit: rowsPerPage,
                filters: [
                    {
                        name: "unlabelled",
                        type: "",
                        clause: showUnlabelled ? "true" : "false"
                    },
                    {
                        name: "classes",
                        type: "",
                        clause: JSON.stringify(selectedClasses)
                    }
                ]
            }
        }
    })


    const multiLabelClassificationEntries = entries.map((image) => {
        const properties = image.properties.reduce((acc, item) => ({...acc, [item.name]: item.value}), {})

        const _id = properties["_id"]
        const url = properties["url"]

        return {
            _id,
            url
        }
    })
    
    const { data: { labelerSelectedImage } = {}} = useQuery(SELECTED_IMAGE)
    
    const [ setLabelerSelectedImage ] = useMutation(SET_SELECTED_IMAGE)
    const [ removeEntryById ] = useMutation(DELETE_TRAINING_SET_ENTRY_BY_ID)

    const onSelectedClassesChanged = (event) => {
        setSelectedClasses(event.target.value)
    }

    const onImageClicked = _id => () => {
        setLabelerSelectedImage({
            variables: {
                params: {
                    _id
                }
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        history.push({
            pathname: `${page ? "." : "multi-label-classification"}/${newPage ? `${newPage}` : "../multi-label-classification"}`,
            state: { 
                showUnlabelled,
                selectedClasses
            }
        })
    }

    const onGoPageChange = event => setGoPage(event.target.value)
    const onGoClicked = () => handleChangePage(null, goPage)

    const onNextImage = () => {
        const index1 = multiLabelClassificationEntries.findIndex((entry) => entry._id === labelerSelectedImage)
        setLabelerSelectedImage({ 
            variables: {
                params: {
                    _id: multiLabelClassificationEntries[index1 + 1]._id
                }
            }
        })
    }
    const onPreviousImage = () => {
        const index1 = multiLabelClassificationEntries.findIndex((entry) => entry._id === labelerSelectedImage)
        setLabelerSelectedImage({ 
            variables: {
                params: {
                    _id: multiLabelClassificationEntries[index1 - 1]._id
                }
            }
        })
    }

    const onDeleteClicked = async () => {
        try {
            setRemoveLoading(true)
            const res = await removeEntryById({
                variables: {
                    params: {
                        _id: labelerSelectedImage,
                        trainingSet
                    }
                },
                refetchQueries: [
                    { 
                        query: GET_MULTI_LABEL_CLASSIFICATION_ENTRIES,
                        variables: {
                            params: {
                                trainingSet,
                                offset: page * rowsPerPage,
                                first: rowsPerPage  
                            }
                        }
                    }
                ]
            })
            onNextImage()
            if(res.data.removeTrainingSetEntryById.status === "FAILED") {
                props.enqueueSnackbar("Se ha producido un error", { variant: "error" })
            }
        } catch(err) {
            props.enqueueSnackbar("Se ha producido un error", { variant: "error" })
        } finally {
            setRemoveLoading(false)
        }
    }

    const onShowUnlabelledChanged = event => {
        setShowUnlabelled(event.target.checked)

        history.push({
            pathname: `${page ? "." : "multi-label-classification"}/../multi-label-classification`,
            state: { 
                showUnlabelled: !showUnlabelled,
                selectedClasses
            }
        })
    }
    
    useHotkeys("d", onNextImage, [ labelerSelectedImage,  multiLabelClassificationEntries ])
    useHotkeys("a", onPreviousImage, [ labelerSelectedImage, multiLabelClassificationEntries ])

    return (
        <div style={{ height: "calc(35vh - 20px)", boxShadow: "0 0 10px -2px #333333AA" }}>
            <Grid container alignItems="flex-end" style={{ padding: 0 }}>
                <Grid item xs>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid className={classes.section} item md={1}>
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <IconButton disabled={removeLoading} className={classes.buttonClassname} onClick={onDeleteClicked}>
                                <DeleteIcon />
                            </IconButton>
                            {removeLoading && <CircularProgress size={48} className={classes.fabProgress} />}
                        </div>
                    </div>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid className={classes.section} container item md={5} alignItems="flex-end" style={{ padding: "0 10px"}}>
                    <Grid item xs={2}>
                        <FormControlLabel control={<Checkbox name="checkedC" />} label="Sin etiquetar" checked={showUnlabelled} onChange={onShowUnlabelledChanged}/>
                    </Grid>
                    <Grid item xs alignItems="flex-end" justify="flex-end">
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel id="demo-mutiple-chip-label">Filtrar</InputLabel>
                            <Select
                                multiple
                                value={selectedClasses}
                                onChange={onSelectedClassesChanged}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                            >
                            {
                                trainingSetClasses.map((name) => (
                                    <MenuItem key={name} value={name}>
                                    {name}
                                    </MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid className={classes.section} container item md={4} alignItems="flex-end" justify="flex-end">
                    <Grid container item xs={5} alignItems="flex-end" justify="flex-end"  spacing={3}>
                        <Grid item xs={8}>
                            <TextField label="Página" onChange={onGoPageChange} value={goPage} type="number" step="1"/>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={onGoClicked}>Ir</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={7}>
                        <TablePagination
                            rowsPerPageOptions={[ rowsPerPage ]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={parseInt(page)}
                            onChangePage={handleChangePage}
                        />      
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ height: "calc(100% - 57px)", overflowY: "scroll", background: "#EEE", padding: 5 }}>
                <Grid container>
                    {
                        multiLabelClassificationEntries.map((image, i) => {
                            return (
                                <Grid xs={6} sm={4} md={3} lg={2} xl={1}>
                                    <div style={{ display: "inline-block", padding: 1 }}>
                                        <img 
                                            src={image.url} 
                                            width={"100%"}
                                            onClick={onImageClicked(image._id)}     
                                            style={{
                                                border: labelerSelectedImage === image._id ? "5px solid #f00" : "unset", 
                                                borderRadius: 5
                                            }}
                                        />
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        </div>
    )
}

export default withSnackbar(ImagesMenu)