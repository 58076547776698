import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'
import MaterialButton from '@material-ui/core/Button'

const Button = ({ height, width, background, color, fontSize, variant, content }) => {
    const {
        connectors: { connect, drag },
    } = useNode()
    const style = {
        height,
        width,
        background,
        color,
        fontSize,
    }

    return (
        <MaterialButton ref={(ref) => connect(drag(ref))} variant={variant} fullWidth style={style}>
            {content}
        </MaterialButton>
    )
}

Button.craft = {
    displayName: 'Botón',
    custom: {
        component: true,
    },
    props: {
        content: 'HAZ CLICK',
        variant: 'outlined',
        height: '20px',
        width: '100%',
        background: '#000',
        color: '#FFF',
        fontSize: 15,
    },
}

Button.propTypes = {
    content: PropTypes.string,
    variant: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    background: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.number,
}

Button.defaultProps = {
    content: 'HAZ CLICK',
    variant: 'outlined',
    height: '20px',
    width: '100%',
    background: '#000',
    color: '#FFF',
    fontSize: 15,
}

export default Button
