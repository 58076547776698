import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import TableWithSearch from 'components/TableWithSearch'

import { useGetModels } from 'queries/models'

const ModelsList = () => {
    const [columns, setColumns] = useState([
        {
            title: 'Nombre',
            field: 'name',
            sortDirection: 'asc',
        },
        {
            title: 'Estado',
            field: 'state',
            sortDirection: 'asc',
        },
        {
            title: 'Fecha de creación',
            field: 'createdAt',
            sortDirection: 'asc',
        },
        {
            title: 'ID',
            field: 'id',
            sortDirection: 'asc',
        },
    ])
    const [data, setData] = useState([])

    const { models, loading } = useGetModels()
    const history = useHistory()

    useEffect(() => {
        if (models.length) {
            const newData = models.map((model) => ({
                ...model,
                id: model._id,
                createdAt: new Date(model.createdAt).toLocaleString(),
                link: `./${model._id}`,
            }))

            setData(newData)
        }
    }, [models])

    return (
        <Container maxWidth={false} style={{ padding: 0 }}>
            <Paper style={{ width: '100%', height: '100%' }}>
                <Typography style={{ padding: '15px 20px' }} variant="h2">
                    Modelos
                </Typography>
                <Divider />
                <TableWithSearch
                    height="calc(100vh - 198px)"
                    columns={columns}
                    data={data}
                    fullScreen={false}
                    loading={loading}
                    onRowClick={(rowData) => {
                        history.push(`models/${rowData.id}`)
                    }}
                    rowsAsLinks
                    onDirectionChange={(column, direction) => {
                        const newColumns = columns.map((col) => {
                            if (col.field === column) {
                                return {
                                    ...col,
                                    sortDirection: direction,
                                }
                            }
                            return col
                        })

                        setColumns(newColumns)
                    }}
                />
            </Paper>
        </Container>
    )
}

export default ModelsList
