import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Edges } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'

import texture from '../assets/box_2.jpg'
import labelTexture from '../assets/box_labeled.jpg'

const Box = ({ box, meshProps, materialProps, layoutIndex = 0, help = false }) => {
    const [colorMapLabeled, setColorMapLabeled] = useState(null)
    const [colorMap, setColorMap] = useState(null)
    const { scene } = useThree()
    const boxRef = useRef()

    const addToUserData = () => {
        scene.userData = {
            ...scene.userData,
            boxes: [
                ...(scene.userData.boxes || []),
                { id: box.id, layoutIndex, boxId: box.boxId, object: boxRef },
            ],
        }
    }

    useEffect(() => {
        const textureLoader = new THREE.TextureLoader()

        textureLoader.load(labelTexture, (textureRes) => {
            // textureRes.center = new THREE.Vector2(0.5, 0.5)
            // textureRes.rotation = Math.PI / 2
            setColorMapLabeled(textureRes)
        })
        textureLoader.load(texture, (textureRes) => {
            setColorMap(textureRes)
        })
        addToUserData()

        return () => {
            const index = scene.userData.boxes.findIndex((element) => {
                return element.id === box.id && element.layoutIndex === element
            })
            scene.userData.boxes.splice(index, 1)
        }
    }, [])

    /* eslint-disable react/no-unknown-property */
    return (
        <mesh ref={boxRef} {...meshProps}>
            <boxBufferGeometry attach="geometry" args={[box.size[0], box.size[1], box.size[2]]} />
            {[...Array(6).keys()].map((i) => {
                if (!colorMapLabeled || !colorMap) {
                    return null
                }
                if (box.labelSide === i) {
                    return (
                        <meshStandardMaterial
                            {...materialProps[i]}
                            key={i}
                            attach={`material-${i}`}
                            map={colorMapLabeled}
                        />
                    )
                }

                return (
                    <meshStandardMaterial
                        {...materialProps[i]}
                        key={i}
                        attach={`material-${i}`}
                        map={colorMap}
                    />
                )
            })}
            <Edges />
            {help && <axesHelper size={5} />}
        </mesh>
    )
}

Box.propTypes = {
    box: PropTypes.object.isRequired,
    meshProps: PropTypes.object,
    materialProps: PropTypes.array,
    layoutIndex: PropTypes.number,
    help: PropTypes.bool,
}

Box.defaultProps = {
    meshProps: {},
    materialProps: [{}, {}, {}, {}, {}, {}],
    layoutIndex: 0,
    help: false,
}

export default Box
