import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

import cobotFile from '../assets/cobotV1.gltf'
import BoxCollider from './boxCollider'

const Cobot = (props) => {
    const onCollisionRef = useRef()
    const { nodes, materials } = useGLTF(cobotFile)
    /* eslint-disable react/no-unknown-property */
    return (
        <BoxCollider onCollisionRef={onCollisionRef} help={false}>
            <group {...props} dispose={null}>
                <mesh
                    geometry={nodes.Body1.geometry}
                    material={materials['Steel - Satin']}
                    position={[0, 0.07, -0.603]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1001.geometry}
                    material={materials['Steel - Satin']}
                    position={[-0.307, 0.072, -0.532]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1002.geometry}
                    material={materials['Steel - Satin']}
                    position={[0.307, 0.072, -0.532]}
                    rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
                    scale={0.01}
                />
                <group
                    position={[-0.175, 1.143, -0.721]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1002_1.geometry}
                        material={materials['Opaque(211,170,33)']}
                    />
                    <mesh
                        geometry={nodes.Body1002_2.geometry}
                        material={materials['Opaque(79,83,88)']}
                    />
                    <mesh
                        geometry={nodes.Body1002_3.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.088, -0.6]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.083, -0.633]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.079, -0.667]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.075, -0.701]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.071, -0.735]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.067, -0.768]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.063, -0.802]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.225, 1.059, -0.836]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.088, -0.6]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.083, -0.633]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.079, -0.667]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.075, -0.701]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.071, -0.735]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.067, -0.768]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.063, -0.802]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.191, 1.059, -0.836]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.088, -0.6]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.083, -0.633]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.079, -0.667]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.075, -0.701]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.071, -0.735]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.067, -0.768]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.063, -0.802]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.157, 1.059, -0.836]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.088, -0.6]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.083, -0.633]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.079, -0.667]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.075, -0.701]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.071, -0.735]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.067, -0.768]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.063, -0.802]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.123, 1.059, -0.836]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.09, -0.583]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.085, -0.617]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.081, -0.65]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.077, -0.684]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.073, -0.718]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.069, -0.752]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.065, -0.785]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.061, -0.819]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.208, 1.056, -0.853]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.09, -0.583]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.085, -0.617]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.081, -0.65]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.077, -0.684]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.073, -0.718]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.069, -0.752]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.065, -0.785]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.061, -0.819]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.174, 1.056, -0.853]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.09, -0.583]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.085, -0.617]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.081, -0.65]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.077, -0.684]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.073, -0.718]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.069, -0.752]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.065, -0.785]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.061, -0.819]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <group
                    position={[-0.14, 1.056, -0.853]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                >
                    <mesh
                        geometry={nodes.Body1003_1.geometry}
                        material={materials['Opaque(128,255,128)']}
                    />
                    <mesh
                        geometry={nodes.Body1003_2.geometry}
                        material={materials['Opaque(225,225,225)']}
                    />
                </group>
                <mesh
                    geometry={nodes.Body1063.geometry}
                    material={materials['Aluminum - Satin']}
                    position={[0, 0.687, 0]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1064.geometry}
                    material={materials['Aluminum - Satin']}
                    position={[0, 0.438, 0]}
                    rotation={[-Math.PI, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1065.geometry}
                    material={materials['Steel - Satin']}
                    position={[0, 0.164, 0]}
                    rotation={[Math.PI / 2, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1066.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[-0.075, 0.74, 0.601]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1067.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[-0.075, 0.34, 0.601]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1068.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[-0.075, 0.74, -0.601]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1069.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[-0.075, 0.34, -0.601]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1070.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[0.075, 0.74, -0.601]}
                    rotation={[0, 1.436, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1071.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[0.075, 0.34, -0.601]}
                    rotation={[0, 1.571, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1072.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[0.075, 0.74, 0.601]}
                    rotation={[0, 1.571, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1073.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[0.075, 0.34, 0.601]}
                    rotation={[0, 1.571, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1074.geometry}
                    material={materials['Paint - Metallic (Black)']}
                    position={[0, 0.941, 0]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1075.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[-0.075, 0.335, -0.601]}
                    rotation={[-Math.PI, Math.PI / 2, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1076.geometry}
                    material={materials['Stainless Steel - Satin.001']}
                    position={[0.075, 0.335, -0.601]}
                    rotation={[-Math.PI, Math.PI / 2, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1077.geometry}
                    material={materials['Stainless Steel - Satin']}
                    position={[-0.2, 0.54, -0.275]}
                    rotation={[Math.PI, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1078.geometry}
                    material={materials['Stainless Steel - Satin']}
                    position={[-0.201, 0.54, 0.275]}
                    rotation={[Math.PI, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1079.geometry}
                    material={materials['Stainless Steel - Satin']}
                    position={[0.201, 0.54, -0.275]}
                    rotation={[Math.PI, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1080.geometry}
                    material={materials['Stainless Steel - Satin']}
                    position={[0.201, 0.54, 0.275]}
                    rotation={[Math.PI, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1081.geometry}
                    material={materials['Stainless Steel - Satin']}
                    position={[-0.2, 0.54, 0.275]}
                    rotation={[Math.PI, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1082.geometry}
                    material={materials['Opaque(170,178,195)']}
                    position={[0.001, 0.998, 0]}
                    rotation={[1.536, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1083.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[0.001, 1.214, -0.006]}
                    rotation={[1.536, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1084.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.18, 1.676, -0.223]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1085.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.057, 1.647, -0.296]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1086.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.172, 1.295, -0.575]}
                    rotation={[3.019, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1087.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.174, 1.219, -0.736]}
                    rotation={[1.449, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body1088.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.174, 1.198, -0.693]}
                    rotation={[-1.693, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body10.geometry}
                    material={materials['Stainless Steel - Satin']}
                    position={[-0.131, 0.561, 0]}
                    rotation={[0, -Math.PI / 2, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body10001.geometry}
                    material={materials['Stainless Steel - Satin']}
                    position={[0.131, 0.561, 0]}
                    rotation={[0, 1.571, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body10002.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.246, 1.709, -0.236]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body11.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.09, 1.14, -0.006]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body12.geometry}
                    material={materials['Aluminum - Satin']}
                    position={[-0.174, 1.166, -0.731]}
                    rotation={[1.449, 0, -Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body12001.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.175, 1.186, -0.025]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body2.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[0.001, 1.218, -0.009]}
                    rotation={[1.536, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body2001.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.262, 1.138, -0.005]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body2002.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.12, 1.292, -0.627]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body2003.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.173, 1.294, -0.584]}
                    rotation={[3.019, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body2004.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.177, 1.32, -0.747]}
                    rotation={[1.449, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body2005.geometry}
                    material={materials['Opaque(202,209,238)']}
                    position={[-0.174, 1.191, -0.733]}
                    rotation={[-1.693, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body3.geometry}
                    material={materials['Steel - Satin']}
                    position={[0, 0.103, -0.217]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body3001.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[0, 1.051, -0.003]}
                    rotation={[1.536, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body3002.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.12, 1.708, -0.237]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body3003.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.015, 1.295, -0.63]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body3004.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.174, 1.284, -0.689]}
                    rotation={[3.019, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body3005.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.174, 1.331, -0.746]}
                    rotation={[1.449, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body3006.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.174, 1.189, -0.772]}
                    rotation={[-1.693, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body4.geometry}
                    material={materials['Steel - Satin']}
                    position={[0, 0.139, 0]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body4001.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.05, 1.131, -0.006]}
                    rotation={[1.536, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body4002.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.185, 1.235, -0.045]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body4003.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.117, 1.292, -0.627]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body4004.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.174, 1.285, -0.686]}
                    rotation={[3.019, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body4005.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.177, 1.33, -0.747]}
                    rotation={[1.449, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body5.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.006, 1.21, 0.001]}
                    rotation={[1.536, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body5001.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.254, 1.143, -0.018]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body5002.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.078, 1.685, -0.251]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body5003.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.171, 1.299, -0.574]}
                    rotation={[3.019, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body5004.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.174, 1.216, -0.735]}
                    rotation={[1.449, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body6.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[0, 1.046, -0.003]}
                    rotation={[1.536, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body6001.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.095, 1.14, -0.006]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body6002.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.063, 1.313, -0.608]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body6003.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.145, 1.291, -0.633]}
                    rotation={[3.019, 0, 0]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body6004.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.174, 1.275, -0.713]}
                    rotation={[1.449, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body7.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.185, 1.346, -0.09]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body7001.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.004, 1.293, -0.626]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body8.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.259, 1.138, -0.008]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body8001.geometry}
                    material={materials['Opaque(255,255,255)']}
                    position={[-0.057, 1.53, -0.405]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body9.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.251, 1.71, -0.236]}
                    rotation={[1.187, 0, -Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.Body9001.geometry}
                    material={materials['Opaque(127,127,127)']}
                    position={[-0.005, 1.296, -0.629]}
                    rotation={[-0.82, 0, Math.PI]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.PARTE_1.geometry}
                    material={materials['Steel - Satin']}
                    position={[-0.132, 0.002, 0.575]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes['PARTE_1_(1)'].geometry}
                    material={materials['Steel - Satin']}
                    position={[0.132, 0.002, 0.575]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes['PARTE_1_(2)'].geometry}
                    material={materials['Steel - Satin']}
                    position={[-0.132, 0.002, -0.1]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes['PARTE_1_(3)'].geometry}
                    material={materials['Steel - Satin']}
                    position={[0.132, 0.002, -0.1]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.PARTE_2.geometry}
                    material={materials['Steel - Satin']}
                    position={[0.289, 0.07, 0.597]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes['PARTE_2_(1)'].geometry}
                    material={materials['Steel - Satin']}
                    position={[-0.289, 0.07, 0.597]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.PARTE_3.geometry}
                    material={materials['Steel - Satin']}
                    position={[-0.25, 0.079, -0.427]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes['PARTE_3_(1)'].geometry}
                    material={materials['Steel - Satin']}
                    position={[0.25, 0.079, -0.427]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes.PARTE_4.geometry}
                    material={materials['Steel - Satin']}
                    position={[-0.247, 0.092, -0.396]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
                <mesh
                    geometry={nodes['PARTE_4_(1)'].geometry}
                    material={materials['Steel - Satin']}
                    position={[0.248, 0.092, -0.396]}
                    rotation={[Math.PI / 2, 0, Math.PI / 2]}
                    scale={0.01}
                />
            </group>
        </BoxCollider>
    )
}

useGLTF.preload(cobotFile)

export default Cobot
