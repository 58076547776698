import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const ImageSettings = ({ query, actions, id }) => {
    const node = query.node(id).get()

    const [margin, setMargin] = useState(
        node.data.props.margin
            .split('px')
            .map((p) => p.trim())
            .filter((p) => p)
    )

    /* eslint-disable no-param-reassign */
    const onIdChange = (e) => {
        actions.setProp(id, (p) => {
            p._id = e.target.value
        })
    }

    const onMarginChange = (i) => (e) => {
        const tmp = margin
        tmp[i] = e.target.value
        actions.setProp(id, (p) => {
            p.margin = `${tmp.join('px ')}px`
        })
        setMargin(tmp)
    }

    const onSizeChange = (e) => {
        actions.setProp(id, (p) => {
            p.preserveAspectRatio = e.target.value
        })
    }

    return (
        <List>
            <ListItem>
                <TextField
                    label="ID"
                    onChange={onIdChange}
                    defaultValue={node.data.props._id || id}
                    fullWidth
                />
            </ListItem>
            <ListItem>
                <Grid container spacing={1}>
                    {margin.map((p, i) => (
                        <Grid item key={i} xs={3}>
                            <TextField
                                defaultValue={p}
                                label="Margin"
                                type="number"
                                step="1"
                                min="0"
                                onChange={onMarginChange(i)}
                                fullWidth
                            />
                        </Grid>
                    ))}
                </Grid>
            </ListItem>
            <ListItem>
                <FormControl fullWidth>
                    <InputLabel>Tamaño</InputLabel>
                    <Select
                        onChange={onSizeChange}
                        defaultValue={node.data.props.preserveAspectRatio}
                    >
                        <MenuItem value="xMinYMin slice">xMinYMin slice</MenuItem>
                        <MenuItem value="xMidYMin slice">xMidYMin slice</MenuItem>
                        <MenuItem value="xMaxYMin slice">xMaxYMin slice</MenuItem>
                        <MenuItem value="xMinYMid slice">xMinYMid slice</MenuItem>
                        <MenuItem value="xMidYMid slice">xMidYMid slice</MenuItem>
                        <MenuItem value="xMaxYMid slice">xMaxYMid slice</MenuItem>
                        <MenuItem value="xMinYMax slice">xMinYMax slice</MenuItem>
                        <MenuItem value="xMidYMax slice">xMidYMax slice</MenuItem>
                        <MenuItem value="xMaxYMax slice">xMaxYMax slice</MenuItem>
                        <MenuItem value="xMinYMin meet">xMinYMin meet</MenuItem>
                        <MenuItem value="xMidYMin meet">xMidYMin meet</MenuItem>
                        <MenuItem value="xMaxYMin meet">xMaxYMin meet</MenuItem>
                        <MenuItem value="xMinYMid meet">xMinYMid meet</MenuItem>
                        <MenuItem value="xMidYMid meet">xMidYMid meet</MenuItem>
                        <MenuItem value="xMaxYMid meet">xMaxYMid meet</MenuItem>
                        <MenuItem value="xMinYMax meet">xMinYMax meet</MenuItem>
                        <MenuItem value="xMidYMax meet">xMidYMax meet</MenuItem>
                        <MenuItem value="xMaxYMax meet">xMaxYMax meet</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    )
}

ImageSettings.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default ImageSettings
