import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { setContext } from 'apollo-link-context'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from '@apollo/react-hooks'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

import AppBar from './containers/appbar'
import Apps from './containers/apps/scene'
import Account from './containers/account/scene'
import Store from './containers/store/scene'
import Onboarding from './containers/onboarding/routes'
import Settings from './containers/settings/scene'
import Config from '../../../config'
import { GET_ACCESS_TOKEN } from './queries/auth'

const useStyles = makeStyles(() => ({
    frame: {
        paddingTop: 63,
        height: '100vh',
        overflowX: 'hidden',
        background: '#F2F2F2',
        '@media print': {
            padding: 0,
        },
    },
}))

const Private = () => {
    const classes = useStyles()
    const match = useRouteMatch()
    const history = useHistory()

    const httpLink = new BatchHttpLink({
        uri: `${Config.app.backend.url}/users/graphql`,
        batchMax: 50,
        batchInterval: 20,
    })

    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem('access_token')

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        }
    })

    const cache = new InMemoryCache({
        addTypename: true,
    })

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
        queryDeduplication: true,
        notifyOnNetworkStatusChange: true,
        connectToDevTools: true,
    })

    useEffect(() => {
        const checkAuth = () => {
            const accessToken = localStorage.getItem('access_token')

            const meta = JSON.parse(atob(accessToken.split('.')[1]))

            const logout = () => {
                localStorage.removeItem('id_token')
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')

                history.push('/')
            }

            const timeLeft = meta.exp * 1000 - Date.now()
            if (timeLeft < 60000) {
                const refreshToken = localStorage.getItem('refresh_token')
                if (timeLeft < 0) {
                    logout()
                } else {
                    client
                        .mutate({
                            mutation: GET_ACCESS_TOKEN,
                            variables: { params: { refreshToken } },
                        })
                        .then(({ data }) => {
                            const { getAccessToken: { status, idToken, accessToken } = {} } = data
                            if (status === 'SUCCEEDED') {
                                localStorage.setItem('id_token', idToken)
                                localStorage.setItem('access_token', accessToken)
                            } else {
                                logout()
                            }
                        })
                }
            }
        }

        checkAuth()

        const handle = setInterval(checkAuth, 10000)

        return () => {
            if (handle) {
                clearInterval(handle)
            }
        }
    }, [history])

    return (
        <ApolloProvider client={client}>
            <AppBar />
            <div className={classes.frame}>
                <Switch>
                    <Redirect exact from={match.url} to={`${match.url}/apps`} />
                    <Route path={`${match.url}/account/:section?`} component={Account} />
                    <Route path={`${match.url}/store`} component={Store} />
                    <Route path={`${match.url}/onboardin`} component={Onboarding} />
                    <Route path={`${match.url}/settings/:section?`} component={Settings} />
                    <Route
                        path={`${match.url}/apps`}
                        component={(props) => {
                            return (
                                /*      <HasPaymentMethods key="has_payment_methods">
                                <HasDefaultPaymentMethod key="has_default">
                                    <HasPlans key="has_plans"> */
                                <Apps key="APPS" {...props} />
                                /*            </HasPlans>
                                </HasDefaultPaymentMethod>
                            </HasPaymentMethods> */
                            )
                        }}
                    />
                </Switch>
            </div>
        </ApolloProvider>
    )
}

export default Private
