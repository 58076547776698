import React, { useRef, useState, useEffect }from "react"

import { useQuery, useMutation } from "@apollo/react-hooks"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom"

import styled from 'styled-components'
import { Zoom, applyMatrixToPoint } from '@vx/zoom';

import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import { setClassIndex, setSelectedObject } from "../../reducers/index";

import { GET_TRAINING_SETS_ENTRIES_BY_TRAINING_SET, GET_TRAINING_SET_ENTRY_BY_ID, UPDATE_TRAINING_SET_ENTRY_BY_ID } from "../../../../queries/training-sets";

const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: window.innerWidth * 0.25,
    translateY: window.innerHeight * 0.05,
    skewX: 0,
    skewY: 0
};

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
      position: "fixed",
      bottom: 15,
      left: "calc(50vw - 70px)"
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));

const Center = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { trainingSet } = useParams()

    const zoom = useRef(null)
    const img = useRef(null)

    const { classIndex, selectedEntry, selectedObject } = useSelector(state => state.horus.trainingSets.polygon)
    
    const [ cursorPosition, setCursorPosition ] = useState({ x: 100 , y: 240 })
    const [ tempAnnotation, setTempAnnotation ] = useState([])

    const { data: { horus: { trainingSets: { getTrainingSetEntryById: { entry = {}} = {}} = {}} = {}} = {}} = useQuery(GET_TRAINING_SET_ENTRY_BY_ID, { 
        variables: { 
            params: { 
                _id: selectedEntry, 
                dataType: "rgb", 
                labelType: "polygon" 
            }
        }
    })

    const [ updateEntryById ] = useMutation(UPDATE_TRAINING_SET_ENTRY_BY_ID)
    
    const e = entry || {}
    const properties = e.properties || []
    const byId = properties.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

    const polygons = [
        ...JSON.parse(byId["annotations"] || "[]"),
        {
            class: classIndex,
            vertices: tempAnnotation
        }
    ]

    const onMouseDown = (zoom) => (event) => {
        switch(classIndex) {
            case -1:
                return zoom.dragStart(event)
            default:

                const startX = (event.nativeEvent.offsetX - zoom.transformMatrix.translateX) / zoom.transformMatrix.scaleX
                const startY = (event.nativeEvent.offsetY - zoom.transformMatrix.translateY) / zoom.transformMatrix.scaleY
                
                if(startX < 0 || startY < 0) {
                    return
                }

                setTempAnnotation([
                    ...tempAnnotation,
                    [ startX, startY ]
                ])

                return           
        }
    }

    const onMouseMoveCursor = (zoom) => (event) => {
        if(zoom.isDragging)
            setCursorPosition({ 
                x: 0,
                y: 0
            });
        
        if(!zoom.isDragging)
            setCursorPosition({ 
                x: event.nativeEvent.offsetX,
                y: event.nativeEvent.offsetY
            });
    }

    const onMouseMove = (zoom) => (event) => {
        switch(classIndex) {
            case -1:
                return zoom.dragMove(event)
        }
    }

    const onMouseUp = zoom => async event => {
        switch(classIndex) {
            case -1:
                return zoom.dragEnd(event)
        }
    }

    const onFinishPolygonClicked = async () => {
        await updateEntryById({
            variables: {
                params: {
                    _id: selectedEntry,
                    dataType: "rgb",
                    labelType: "polygon",
                    properties: [
                        {
                            name: "annotations",
                            value: JSON.stringify(polygons)
                        }
                    ]
                }
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SET_ENTRY_BY_ID,
                    variables: { params: { _id: selectedEntry, labelType: "polygon", dataType: "rgb" }}
                }
            ]
        })

        setTempAnnotation([])

        dispatch(setClassIndex(-1))
    }

    const onPolygonClicked = i => () => {
        dispatch(setSelectedObject(i))
    }

    const renderPolygons = () => {

        return polygons.map((polygon, index) => {

            const vertices = polygon.vertices || []

            const points = vertices.map(point => {
                return point[0] + "," + point[1]
            })

            return (
                <polygon 
                    points={points.join(" ")} 
                    stroke={index === selectedObject ? "red" : "black"} 
                    stroke-width="2" 
                    fill={"#00000033"} 
                    style={{ cursor: "pointer" }}
                    onMouseDown={onPolygonClicked(index)}
                />
            )
        })
    }
    
    return(
        <div ref={zoom} style={{ height: "calc(100vh - 67px)", background: "#EFEFEF", overflow: "hidden" }} >
            <Zoom
                width={window.innerWidth}
                height={window.innerHeight}
                scaleXMin={0.1}
                scaleXMax={10}
                scaleYMin={0.1}
                scaleYMax={10}
                transformMatrix={initialTransform}
                onMouseMove={onMouseMoveCursor(zoom)}
            >
                {
                    (zoom) => {
                        return (
                            <svg 
                                width={window.innerWidth} 
                                height={window.innerHeight} 
                                style={{ 
                                    cursor: classIndex !== -1 ? 'crosshair' : zoom.isDragging ? 'grabbing' : 'grab', 
                                }}
                            >
                                <g transform={zoom.toString()}>
                                    <image ref={img} xlinkHref={byId["url"]} />
                                    {renderPolygons()}
                                </g>
                                <rect
                                    width={window.innerWidth}
                                    height={window.innerHeight}
                                    rx={14}
                                    fill="transparent"
                                    onWheel={zoom.handleWheel}
                                    onMouseDown={onMouseDown(zoom)}
                                    onMouseMove={onMouseMove(zoom)}
                                    onMouseUp={onMouseUp(zoom)}
                                    onMouseLeave={() => {
                                        if (!zoom.isDragging) return;
                                        zoom.dragEnd();
                                    }}
                                    onDoubleClick={event => {
                                        //const point = localPoint(event);
                                        //zoom.scale({ scaleX: 1.1, scaleY: 1.1, point });
                                    }}
                                />
                            </svg>
                        )
                    }
                }
            </Zoom>
            {
                classIndex !== -1 && 
                    <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="add"
                        className={classes.margin}
                        onClick={onFinishPolygonClicked}
                    >
                        <AddIcon className={classes.extendedIcon} />
                        Finish Polygon
                    </Fab>
            }
        </div>
    )
}

export default Center