import gql from "graphql-tag"

export const CREATE_JOB = gql`
    mutation CreateJob($params: CreateJobRequest!) {
        createJob(params: $params) {
            status
        }
    }
`

export const GET_JOBS_BY_PARENT = gql`
    query GetJobsByParent($params: GetJobsByParentRequest!) {
        getJobsByParent(params: $params) {
            status
            jobs {
                _id
            }
        }
    }

`

export const GET_JOB_DEFINITIONS = gql`
    query GetJobDefinitions($params: GetJobDefinitionsRequest!) {
        getJobDefinitions(params: $params) {
            status
            jobDefinitions {
                id
                type
                name {
                    es
                }
            }
        }
    }

`