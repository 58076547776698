import React from 'react'

import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import ImageIcon from '@material-ui/icons/Image'

import Loading from 'components/Loading'

import { GET_DELIVERABLES_BY_MODEL } from '../../../../queries/models'

const DeliverablesList = () => {
    const { model } = useParams()

    const { data: { getDeliverablesByModel: { deliverables = [] } = {} } = {}, loading } = useQuery(
        GET_DELIVERABLES_BY_MODEL,
        { variables: { params: { model } } }
    )

    return (
        <Container>
            <Typography variant="h2" style={{ padding: '20px 0' }}>
                Entregables
            </Typography>
            <Loading loading={loading}>
                {deliverables.length ? (
                    <Paper style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                        <List>
                            {deliverables.map((deliverable) => (
                                <ListItem key={deliverable._id} button>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ImageIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={deliverable._id}
                                        secondary={deliverable.device}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                ) : (
                    <div style={{ margin: '20px', textAlign: 'center' }} />
                )}
            </Loading>
        </Container>
    )
}

export default DeliverablesList
