switch (process.env.APP_ENV) {
    case 'production':
        module.exports = require('./config.production')
        break
    case 'staging':
        module.exports = require('./config.staging')
        break
    case 'development':
    default:
        module.exports = require('./config.development')
}
