import React from 'react'
import { useNode } from '@craftjs/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import getPropertyBuilder from './utils'

const TextField = (props) => {
    const {
        connectors: { connect, drag },
    } = useNode()

    const style = {}

    const getProperty = getPropertyBuilder(props)

    const title = getProperty('title')
    const value = getProperty('value')
    const divider = getProperty('divider')

    return (
        <React.Fragment>
            <ListItem ref={(ref) => connect(drag(ref))} style={style}>
                <ListItemText primary={title} secondary={value} />
            </ListItem>
            {divider ? <Divider /> : null}
        </React.Fragment>
    )
}

TextField.craft = {
    displayName: 'Campo de Texto',
    custom: {
        component: true,
    },
    props: {
        title: 'Texto',
        value: 'valor',
    },
}

export default TextField
