import React from "react"

import { useSelector, useDispatch } from "react-redux"

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { setDimensionBucket } from "../../../../../../reducer"

const Date = () => {

    const dispatch = useDispatch()

    const { selected_item, dimensions } = useSelector(state => state.dashboards.bar_chart)

    const dim = dimensions[selected_item.index]

    const onBucketChange = e => dispatch(setDimensionBucket(selected_item.index, e.target.value))

    return (
        <FormControl variant="filled" fullWidth>
            <InputLabel>Bucket</InputLabel>
            <Select value={dim.bucket} onChange={onBucketChange}>
                <MenuItem value={null}></MenuItem>
                <MenuItem value={"second"}>Segundo</MenuItem>
                <MenuItem value={"minute"}>Minuto</MenuItem>
                <MenuItem value={"hour"}>Hora</MenuItem>
                <MenuItem value={"day"}>Día</MenuItem>
                <MenuItem value={"week"}>Semana</MenuItem>
                <MenuItem value={"month"}>Mes</MenuItem>
                <MenuItem value={"year"}>Año</MenuItem>
            </Select>
        </FormControl>
    )
}

export default Date