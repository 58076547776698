import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@mui/material/CircularProgress'

import { CREATE_PARAM } from '../../queries/params'
import ParamsType from '../param-type/index'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

// Default values of local state
const DEFAULT_OPEN = false
const DEFAULT_NAME = ''
const DEFAULT_TYPE = ''
const DEFAULT_VALUE = ''

/**
 * New parameter component. Renders the
 * components defined in ParamsType
 */
const NewParam = () => {
    // Hooks
    // // url parameters hook
    const { group } = useParams()
    // // graphql mutation
    const [createParam] = useMutation(CREATE_PARAM)
    // // styles mutation
    const classes = useStyles()

    // Local state
    const [open, setOpen] = useState(DEFAULT_OPEN)
    const [name, setName] = useState(DEFAULT_NAME)
    const [type, setType] = useState(DEFAULT_TYPE)
    const [value, setValue] = useState(DEFAULT_VALUE)
    const [loading, setLoading] = useState(false)

    // Event listeners
    const onFabClicked = () => {
        // Set default values on starting of create new
        // parameter
        setType(DEFAULT_TYPE)
        setValue(DEFAULT_VALUE)
        setName(DEFAULT_NAME)
        setOpen(true)
    }
    const onNameChanged = (e) => setName(e.target.value)
    const onTypeChanged = (e) => setType(e.target.value)
    const onAcceptClicked = async () => {
        setLoading(true)
        await createParam({
            variables: {
                params: {
                    group,
                    name,
                    type,
                    value,
                },
            },
        })
        setLoading(false)
        setOpen(DEFAULT_OPEN)
    }
    const onCancelClicked = () => {
        setOpen(DEFAULT_OPEN)
    }

    return (
        <React.Fragment>
            <Tooltip title="Nuevo Parámetro" placement="left">
                <Fab color="primary" className={classes.fab} onClick={onFabClicked}>
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog open={open}>
                <DialogTitle>Nuevo Parámetro</DialogTitle>
                <DialogContent>
                    <TextField label="Nombre" value={name} onChange={onNameChanged} fullWidth />
                    <FormControl margin="normal" fullWidth>
                        <Select label="Tipo" fullWidth value={type} onChange={onTypeChanged}>
                            {Object.keys(ParamsType).map((paramType) => {
                                return (
                                    <MenuItem key={paramType} value={paramType}>
                                        {ParamsType[paramType].name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {React.createElement(
                        type === DEFAULT_TYPE ? () => null : ParamsType[type].component,
                        { value, setValue },
                        null
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancelClicked} disabled={loading}>
                        Cancelar
                    </Button>
                    {loading ? (
                        <CircularProgress color="inherit" disableShrink size={25} />
                    ) : (
                        <Button
                            color="primary"
                            onClick={onAcceptClicked}
                            disabled={loading}
                            autoFocus
                        >
                            Aceptar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default NewParam
