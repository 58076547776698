// import Select from './select/select'
import DateWidget from './date/date'
import CheckboxWidget from './checkbox/checkbox'
import SelectWidget from './select/select'

export default {
    select: SelectWidget,
    date: DateWidget,
    checkbox: CheckboxWidget,
}
