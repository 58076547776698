import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'
import TablePagination from '@material-ui/core/TablePagination'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'

import { GET_ENTRIES_BY_TRAINING_SET } from '../../../../queries/training-sets'
import { ROWS_PER_PAGE } from '../../constants'

const calculatePagination = (totalItems, maxItemsPerPage) => {
    if (totalItems <= maxItemsPerPage) return 1

    return Math.ceil(totalItems / maxItemsPerPage)
}

const Pagination = () => {
    const history = useHistory()
    const { trainingSet, page = 0 } = useParams()
    const { showUnlabelled, selectedClasses } = useSelector((state) => state.labeler.general.labels)
    const [goPage, setGoPage] = useState()

    const filters = [{ name: 'unlabelled', type: '', clause: showUnlabelled }]
    if (selectedClasses) {
        const toIntClasses = selectedClasses.map(parseInt)
        filters.push({ name: 'classes', type: '', clause: JSON.stringify(toIntClasses) })
    }
    const { data: { getTrainingSetEntriesByTrainingSet: { entries = [] } = {} } = {} } = useQuery(
        GET_ENTRIES_BY_TRAINING_SET,
        {
            variables: {
                params: {
                    trainingSet,
                    limit: null,
                    offset: 0,
                    filters,
                },
            },
            fetchPolicy: 'network-only',
        }
    )

    const onGoPageChange = (event) => setGoPage(event.target.value)

    const handleChangePage = (event, newPage) =>
        history.push({
            pathname: `${page ? '.' : 'ocr'}/${newPage ? `${newPage}` : '../ocr'}`,
        })

    const pages = () => {
        const maxPages = calculatePagination(entries.length || 0, ROWS_PER_PAGE)
        const pagesArray = [...Array(maxPages).keys()]

        return pagesArray
    }

    useEffect(() => {
        if (goPage !== undefined) {
            handleChangePage(null, goPage)
        }
    }, [goPage])

    return (
        <React.Fragment>
            <FormControl style={{ width: 80 }}>
                <InputLabel>Page</InputLabel>
                <Select defaultValue={page} value={goPage} onChange={onGoPageChange} fullWidth>
                    {pages().map((value) => (
                        <MenuItem key={value} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TablePagination
                rowsPerPageOptions={[ROWS_PER_PAGE]}
                component="div"
                rowsPerPage={ROWS_PER_PAGE}
                page={parseInt(page, 10)}
                onChangePage={handleChangePage}
                count={entries.length}
            />
        </React.Fragment>
    )
}

export default Pagination
