const OPEN_CREATE_ENTRIES_DIALOG = 'CREATE_ENTRY_DIALOG/OPEN'
const CLOSE_CREATE_ENTRIES_DIALOG = 'CREATE_ENTRY_DIALOG/CLOSE'

const OPEN_UPDATE_TRAINING_SET_CLASSES_DIALOG = 'UPDATE_TRAINING_SET_CLASSES_DIALOG/OPEN'
const CLOSE_UPDATE_TRAINING_SET_CLASSES_DIALOG = 'UPDATE_TRAINING_SET_CLASSES_DIALOG/CLOSE'

const ADD_ENTRIES_DIALOG_OPEN = 'ADD_ENTRIES_DIALOG/OPEN'
const ADD_ENTRIES_DIALOG_CLOSE = 'ADD_ENTRIES_DIALOG/CLOSE'

const SET_SELECTED_CLASS_INDEX = 'SET_SELECTED_CLASS_INDEX'
const SET_CLASS_PROPS = 'SET_CLASS_PROPS'

const SET_FILTER_CHECK_UNLABELED = 'SET_FILTER_CHECK_UNLABELED'
const SET_SELECTED_CLASSES = 'SET_SELECTED_CLASSES'

const SET_TOOLBOX = 'SET_TOOLBOX'

export const OPEN_SETTINGS_DIALOG = 'SETTINGS_DIALOG/OPEN'
export const CLOSE_SETTINGS_DIALOG = 'SETTINGS_DIALOG/CLOSE'

export const openCreateEntriesDialog = () => ({
    type: OPEN_CREATE_ENTRIES_DIALOG,
})
export const closeCreateEntriesDialog = () => ({
    type: CLOSE_CREATE_ENTRIES_DIALOG,
})
export const openUpdateTrainingSetClassesDialog = () => ({
    type: OPEN_UPDATE_TRAINING_SET_CLASSES_DIALOG,
})
export const closeUpdateTrainingSetClassesDialog = () => ({
    type: CLOSE_UPDATE_TRAINING_SET_CLASSES_DIALOG,
})
export const openAddEntriesDialog = () => ({
    type: ADD_ENTRIES_DIALOG_OPEN,
})
export const setSelectedClassIndex = (index) => ({ type: SET_SELECTED_CLASS_INDEX, index })

export const setCheckFilterLabel = () => ({ type: SET_FILTER_CHECK_UNLABELED })

export const setClasses = (selectedClasses) => ({ type: SET_SELECTED_CLASSES, selectedClasses })
export const setClassProp = (classProps, classIndex) => ({
    type: SET_CLASS_PROPS,
    classProps,
    classIndex,
})

export const setToolbox = (toolboxName) => ({ type: SET_TOOLBOX, name: toolboxName })

export const openSettingsDialog = () => ({ type: OPEN_SETTINGS_DIALOG })
export const closeSettingsDialog = () => ({ type: CLOSE_SETTINGS_DIALOG })

export const initialState = {
    createEntriesDialogOpen: false,
    updateTrainingSetClassesDialogOpen: false,
    addEntryDialog: false,
    classIndex: -1,
    classProps: {},
    selectedLabel: null,
    labels: {
        byId: {},
        allIds: [],
        showUnlabelled: true,
        selectedClasses: null,
    },
    selectedToolbox: '',
    settingsDialogOpen: false,
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_CREATE_ENTRIES_DIALOG:
            return { ...state, createEntriesDialogOpen: true }
        case CLOSE_CREATE_ENTRIES_DIALOG:
            return { ...state, createEntriesDialogOpen: false }
        case OPEN_UPDATE_TRAINING_SET_CLASSES_DIALOG:
            return { ...state, updateTrainingSetClassesDialogOpen: true }
        case CLOSE_UPDATE_TRAINING_SET_CLASSES_DIALOG:
            return { ...state, updateTrainingSetClassesDialogOpen: false }
        case ADD_ENTRIES_DIALOG_OPEN:
            return { ...state, addEntryDialog: true }
        case ADD_ENTRIES_DIALOG_CLOSE:
            return { ...state, addEntryDialog: false }
        case SET_SELECTED_CLASS_INDEX:
            return { ...state, classIndex: action.index }
        case SET_CLASS_PROPS:
            return {
                ...state,
                classProps: {
                    ...state.classProps,
                    [action.classIndex]: action.classProps,
                },
            }
        case SET_FILTER_CHECK_UNLABELED:
            return {
                ...state,
                labels: { ...state.labels, showUnlabelled: !state.labels.showUnlabelled },
            }
        case SET_SELECTED_CLASSES:
            return {
                ...state,
                labels: { ...state.labels, selectedClasses: action.selectedClasses },
            }
        case SET_TOOLBOX:
            return {
                ...state,
                selectedToolbox: action.name,
            }
        case OPEN_SETTINGS_DIALOG:
            return { ...state, settingsDialogOpen: true }
        case CLOSE_SETTINGS_DIALOG:
            return { ...state, settingsDialogOpen: false }
        default:
            return state
    }
}
