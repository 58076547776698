import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import AddIcon from '@material-ui/icons/Add'

import { openCreateEntriesDialog, openUpdateTrainingSetClassesDialog } from '../../redux/general'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'fixed',
            bottom: theme.spacing(9),
            right: theme.spacing(4),
        },
    }
})

const ActionsSpeedDialog = () => {
    const classes = useStyles()

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const onOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onModifyClassesClicked = () => {
        dispatch(openUpdateTrainingSetClassesDialog())
    }

    const onUploadImagesModal = () => {
        dispatch(openCreateEntriesDialog())
    }

    return (
        <div className={classes.root}>
            <SpeedDial
                ariaLabel="SpeedDial example"
                icon={<SpeedDialIcon />}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
            >
                <SpeedDialAction
                    icon={<AddIcon />}
                    tooltipTitle="Añadir imágenes"
                    onClick={onUploadImagesModal}
                />
                <SpeedDialAction
                    icon={<PlaylistAddIcon />}
                    tooltipTitle="Modificar clases"
                    onClick={onModifyClassesClicked}
                />
            </SpeedDial>
        </div>
    )
}

export default ActionsSpeedDialog
