import React from 'react'

import { Link } from "react-router-dom"
import { useQuery } from "@apollo/react-hooks"
import { useDispatch } from "react-redux"

import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from "@material-ui/core/Typography"
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box"
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Loading from "components/Loading"

import { openNewProjectDialog } from "../../reducers/new-project-dialog"

import { GET_ALL_PROJECTS } from "../../queries/projects"

const ProjectList = (props) => {
    const dispatch = useDispatch()
    
    const { data: { getProjectsByParent: { projects = []} = {} } = {}, loading } = useQuery(GET_ALL_PROJECTS, { variables: { params: {}}})

    const onNewProject = () => dispatch(openNewProjectDialog())

    return (
    <Box>
        <Loading loading={loading}>
            <List
                subheader={
                <ListSubheader component="div">
                    Proyectos
                </ListSubheader>
                }
            >
                <Divider />
                {projects.length > 0 ? projects.map((project) => {
                    return (
                        <Link to={`${props.match.url}/universal/${project._id}`}>
                            <ListItem key={project._id} button divider>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={project.name} secondary={project.createdAt} />
                            </ListItem>
                        </Link>
                    )
                }) : <Typography variant="h6" color="textSecondary" style={{ width: "100%", margin: "30px auto", textAlign: "center"}}>Crea un proyecto nuevo pulsando +</Typography>}
            </List>
        </Loading>
        <div style={{ position: 'fixed', bottom: 25, right: 25 }}>
            <div>
                <Tooltip title="Nuevo Projecto" placement="left">
                    <Fab color="primary" aria-label="add" onClick={onNewProject}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        </div>
    </Box>
    )
}

export default ProjectList