export const OPEN_NEW_PROJECT_DIALOG = "NEW_PROJECT_DIALOG/OPEN"
export const CLOSE_NEW_PROJECT_DIALOG = "NEW_PROJECT_DIALOG/CLOSE"

export const openNewProjectDialog = () => ({ type: OPEN_NEW_PROJECT_DIALOG })
export const closeNewProjectDialog = () => ({ type: CLOSE_NEW_PROJECT_DIALOG })

export default (state = { open: false }, action) => {
    switch(action.type) {
        case OPEN_NEW_PROJECT_DIALOG:
            return {...state, open: true}
        case CLOSE_NEW_PROJECT_DIALOG:
            return {...state, open: false}
        default:
            return state
    }
}