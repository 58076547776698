import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import NavigationIcon from '@material-ui/icons/Navigation'
import CircularProgress from '@material-ui/core/CircularProgress'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import { green } from '@material-ui/core/colors'

import { useIsAllowed } from 'queries/permissions'
import { DEPLOY, GET_DEPLOYMENT_STATUS_BY_GROUP, GET_GROUP_BY_ID } from '../../../../queries/groups'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: '#FFF',
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const Header = () => {
    const classes = useStyles()

    const { group } = useParams()

    const { isAllowed: isDeployAllowed } = useIsAllowed({
        action: 'deploy',
        group: 'groups',
    })

    const { isAllowed: isGetGroupStatusAllowed } = useIsAllowed({
        action: 'getGroupStatus',
        group: 'groups',
    })

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false) /* eslint-disable-line */

    const { data: { getGroupById: { group: { name = '' } = {} } = {} } = {} } = useQuery(
        GET_GROUP_BY_ID,
        {
            variables: {
                params: {
                    group,
                },
            },
        }
    )

    const {
        data: {
            falcon: {
                groups: {
                    getDeploymentStatus: { deploymentStatus = '', deploymentUpdatedAt = '' } = {},
                } = {},
            } = {},
        } = {},
    } = useQuery(GET_DEPLOYMENT_STATUS_BY_GROUP, {
        variables: {
            params: {
                group,
            },
        },
        pollInterval: 5000,
    })

    const [deploy] = useMutation(DEPLOY)

    const onDeployClicked = async () => {
        setFailure(false)
        setSuccess(false)
        setLoading(true)
        try {
            await deploy({
                variables: {
                    params: {
                        group,
                    },
                },
            })
            setSuccess(true)
        } catch (error) {
            setFailure(true)
        } finally {
            setLoading(false)
        }
    }

    const renderStatusIndicator = (s) => {
        if (!isGetGroupStatusAllowed) return null

        switch (s) {
            case 'Success':
                return (
                    <React.Fragment>
                        <Grid item xs={1} style={{ height: 35 }}>
                            <Brightness1Icon style={{ color: '#0F0' }} />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="h5">Deployment Succeeded</Typography>
                            <Typography variant="h5">{deploymentUpdatedAt}</Typography>
                        </Grid>
                    </React.Fragment>
                )
            case 'InProgress':
                return (
                    <React.Fragment>
                        <Grid item xs={1} style={{ height: 35 }}>
                            <Brightness1Icon style={{ color: '#ffd700' }} />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="h5">Deployment In Progress </Typography>
                            <Typography variant="h5">{deploymentUpdatedAt}</Typography>
                        </Grid>
                    </React.Fragment>
                )
            case 'Failure':
                return (
                    <React.Fragment>
                        <Grid item xs={1} style={{ height: 35 }}>
                            <Brightness1Icon style={{ color: '#F00' }} />
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="h5">Deployment Failed</Typography>
                            <Typography variant="h5">{deploymentUpdatedAt}</Typography>
                        </Grid>
                    </React.Fragment>
                )
            default:
                return null
        }
    }

    return (
        <Grid container style={{ padding: '20px 0' }} alignItems="center">
            <Grid
                container
                xs={3}
                style={{ padding: '20px 0 10px 50px' }}
                spacing={1}
                alignItems="center"
            >
                <Grid item xs={12}>
                    <Typography variant="h2">{name}</Typography>
                </Grid>
                {renderStatusIndicator(deploymentStatus)}
            </Grid>
            <Grid xs={8} />
            <Grid item xs={1}>
                {isDeployAllowed && (
                    <div className={classes.root}>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                className={success && classes.buttonSuccess}
                                disabled={loading}
                                onClick={onDeployClicked}
                                style={{ float: 'right' }}
                                fullWidth
                                color="primary"
                            >
                                <NavigationIcon />
                                Desplegar
                            </Button>
                            {loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                            )}
                        </div>
                    </div>
                )}
            </Grid>
        </Grid>
    )
}

export default Header
