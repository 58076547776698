import React, { useState } from "react"

import { useHistory } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useParams } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import { GET_TRAINING_SET_BY_ID, REMOVE_TRAINING_SET_BY_ID } from "../../../../queries/training-sets"

const useStyles = makeStyles((theme) => ({
    delete: {
        marginTop: theme.spacing(2),
        background: "red",
        color: "white"
    }
}));

export default (props) => {
    const classes = useStyles()
    const history = useHistory()

    const { trainingSet: _id = ""} = useParams()

    const [ loading, setLoading ] = useState(false)

    const { data: { getTrainingSetById: { trainingSet = {}} = {} } = {}} = useQuery(GET_TRAINING_SET_BY_ID, { variables: { params: { _id } }})
    const [ removeById ] = useMutation(REMOVE_TRAINING_SET_BY_ID)

    const onRemoveClicked = async () => {
        setLoading(true)
        await removeById({ variables: { params: { _id }}})
        setLoading(false)
        history.push("../training-sets")
    }

    return (
        <Grid item xs={12}>
            <Button className={classes.delete} disabled={loading} variant="contained" fullWidth onClick={onRemoveClicked}>Borrar</Button>
        </Grid>
    )
}