import gql from "graphql-tag"


export const GET_PRODUCTS = gql`
    query {
        getProducts {
            status
            products {
                _id
                name
            }
        }
    }
`

export const GET_PLANS = gql`
    query GetPlans($params: GetPlansInput){
        getPlans(params: $params) {
            status
            plans {
                _id
            }
        }
    }
`

export const GET_SUBSCRIPTIONS = gql`
    query {
        getSubscriptionsV2 {
            status
            subscriptions {
                _id
                createdAt
                product
            }
        }
    }
`

