import React, { useCallback, useEffect } from "react"

import { useDropzone } from 'react-dropzone'

import { useDispatch, useSelector } from "react-redux"

import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { selectFiles, nextStep } from "../reducers"

import uploadDialogStateSelector from "../selectors/upload-dialog-state"

export default function() {
    const dispatch = useDispatch()

    const setSelectedFiles = files => dispatch(selectFiles(files))
    const nextClicked = () => dispatch(nextStep())

    const { files } = useSelector(uploadDialogStateSelector)

    const onDrop = useCallback(setSelectedFiles, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})    

    return( 
        <div>
            {"Archivos Seleccionados: " + files.length}
            <div {...getRootProps()}>
                <input {...getInputProps()} accept=".jpg,.rjpg,.jpeg"/>
                {
                    <Grid container style={{ width: 450, border: isDragActive && "2px solid #AAA", borderRadius: 5, padding: 20, margin: "20px 0", cursor: "pointer" }} justify="center">
                        <Grid item>
                            <InsertDriveFileIcon style={{ fontSize: 48, color: "#AAA" }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ textAlign: "center" }}>{ isDragActive ? "Suelta los archivos aquí" : "Arrastra y suelta los archivos aqui o haz click para seleccionar"}</Typography>
                        </Grid>
                    </Grid>
                }
            </div>
            <DialogActions>
                <Button variant={"contained"} onClick={nextClicked} color={"primary"} disabled={!files.length}>Subir</Button>
            </DialogActions>
        </div>
    )
}