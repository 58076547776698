import React, { useRef, useState, useEffect } from "react"

import { useHistory, useParams } from "react-router-dom"
import { useMutation } from "@apollo/react-hooks"
import { useSelector } from "react-redux"


import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Fab from '@mui/material/Fab';

import SaveIcon from '@material-ui/icons/Save';

import LeftBar from "./containers/left-bar/scene"
import RightBar from "./containers/right-bar/scene"
import Chart from "./containers/chart/scene"

import { CREATE_WIDGET } from "./queries/dashboards"

function BarChart(props) {

    const history = useHistory()

    const { widget, dashboard } = useParams()

    const [ createWidget ] = useMutation(CREATE_WIDGET)

    const { current_dataset, dimensions, metrics } = useSelector(state => state.dashboards.bar_chart)


    const onSave = async () => {
        const res = await createWidget({
            variables: {
                params: {
                    "name": "test",
                    "dashboard": dashboard,
                    "type": "bar-chart",
                    "properties": [
                        {
                            name: "dataset",
                            value: current_dataset
                        },
                        {
                            name: "dimensions",
                            value: JSON.stringify(dimensions)
                        },
                        {
                            name: "metrics",
                            value: JSON.stringify(metrics)
                        }
                    ]
                }
            }
        })

        if(res.status === "SUCCEEDED") {
            history.push(widget ? "../../.." : "../..")
        } else {

        }
    }

    return (
        <React.Fragment>
            <Grid container style={{ height: "100%" }}>
                <Grid item xs={1.8} style={{ height: "100%" }}>
                    <Card style={{ alignItems: "stretch", width: "100%", height: "100%" }}>
                        <LeftBar />
                    </Card>
                </Grid>
                <Grid item xs={8.4} style={{ height: "100%" }}>
                    <Chart />
                </Grid>
                <Grid item xs={1.8} style={{ height: "100%" }}>
                    <Card style={{ alignItems: "stretch", width: "100%", height: "100%" }}>
                        <RightBar />
                    </Card>
                </Grid>
            </Grid>
            <Fab variant="extended" color="primary" onClick={onSave} style={{ position: "fixed", bottom: 20, right: 20 }}>
                <SaveIcon style={{ marginRight: 10 }}/>
                Guardar
            </Fab>
        </React.Fragment>
    )
}

export default BarChart