import React from 'react'

import NewParamDialog from './containers/new-variable-dialog/scene'
import List from './containers/list/scene'

import { useIsAllowed } from 'queries/permissions'

const Scene = () => {
    const { isAllowed: isCreateVariableAllowed } = useIsAllowed({
        action: 'createVariable',
        group: 'variables',
    })

    return (
        <React.Fragment>
            {isCreateVariableAllowed && <NewParamDialog />}
            <List />
        </React.Fragment>
    )
}
export default Scene
