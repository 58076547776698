import React from 'react'

import { useQuery } from '@apollo/react-hooks'

import NewTrainingSetDialog from './containers/new-training-set-dialog/index'
import TrainingSetsList from './containers/train-sets-list/index'
import RemovedTrainingSetList from './containers/removed-training-set'

import { useIsAllowed } from 'queries/permissions'

const TrainingSets = () => {
    const { isAllowed: isCreateTrainingSetAllowed } = useIsAllowed({
        group: 'trainingSets',
        action: 'createTrainingSet',
    })

    return (
        <React.Fragment>
            <TrainingSetsList />
            <RemovedTrainingSetList />
            {isCreateTrainingSetAllowed && <NewTrainingSetDialog />}
        </React.Fragment>
    )
}

export default TrainingSets
