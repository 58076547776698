import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import Loading from 'components/Loading'
import { GET_RESOURCES } from '../../../../../../queries/resources'

/**
 * Component to render resource parameter type
 * Is used for creating new parameter and update its value
 * once created
 * Is saved in database as and string pointing to resource id.
 */
const ParamTypeResource = ({ value, setValue }) => {
    const initialValue = value || ''
    const [resourceSelected, selectResource] = useState(initialValue || '')

    const { group } = useParams()

    const { data: { getResourcesByGroup: { resources = [] } = {} } = {}, loading: loadingGQL } =
        useQuery(GET_RESOURCES, {
            variables: {
                params: {
                    group,
                },
            },
        })
    return (
        <Loading loading={loadingGQL}>
            <FormControl margin="normal" fullWidth>
                <Select
                    label="Resource"
                    fullWidth
                    value={resourceSelected}
                    onChange={(event) => {
                        setValue(event.target.value)
                        selectResource(event.target.value)
                    }}
                >
                    {resources.map((res) => {
                        return (
                            <MenuItem key={res._id} value={res._id}>
                                {res.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Loading>
    )
}

ParamTypeResource.propTypes = {
    /** Initial value and value linked with parent states */
    value: PropTypes.string.isRequired,
    /** Function to set the parent state */
    setValue: PropTypes.func.isRequired,
}

export default ParamTypeResource
