import React, { useState } from 'react'

import * as EmailValidator from 'email-validator'

import { makeStyles } from '@material-ui/styles'
import { Link, useHistory } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/react-hooks'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ListItem from '@material-ui/core/ListItem'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ListSubheader from '@material-ui/core/ListSubheader'
import Divider from '@material-ui/core/Divider'
import Autocomplete from '@material-ui/lab/Autocomplete'

import ChevronLeft from '@material-ui/icons/ChevronLeft'

import { CREATE_USER } from '../../queries/users'
import { GET_ROLES } from '../../../roles/queries/roles'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
}))

const Scene = () => {
    const history = useHistory()
    const classes = useStyles()

    const [activeStep, setActiveStep] = useState(0)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('')

    const [errorName, setErrorName] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)

    const [createUser] = useMutation(CREATE_USER)

    const { data: { getAllRoles: { roles = [] } = {} } = {} } = useQuery(GET_ROLES, {
        variables: { params: {} },
    })

    const onNext = () => {
        if (activeStep === 0 && !name) {
            setErrorName(true)
            return
        } else if (activeStep === 1 && !EmailValidator.validate(email)) {
            setErrorEmail(true)
            return
        }

        setErrorName(false)
        setErrorEmail(false)

        setActiveStep(activeStep + 1)
    }

    const onBack = () => {
        setActiveStep(activeStep - 1)
    }

    const onFinish = async () => {
        if (!role) {
            return
        }

        await createUser({
            variables: {
                params: {
                    name,
                    email,
                    role,
                },
            },
        })

        history.push('list')
    }

    const onRoleChange = (event, value) => {
        setRole(value.id)
    }

    const onNameChange = (event) => {
        setName(event.target.value)
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button color="primary" component={Link} to="list" startIcon={<ChevronLeft />}>
                        Volver a usuarios
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Crear un usuario"></CardHeader>
                        <Divider />
                        <CardContent>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step>
                                    <StepLabel>Nombre</StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    label="Nombre"
                                                    fullWidth
                                                    value={name}
                                                    onChange={onNameChange}
                                                    error={errorName}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={onNext}
                                                >
                                                    Siguiente
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel>Email</StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    type="email"
                                                    label="Email"
                                                    fullWidth
                                                    value={email}
                                                    onChange={onEmailChange}
                                                    error={errorEmail}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button onClick={onBack} fullWidth>
                                                    Anterior
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={onNext}
                                                >
                                                    Siguiente
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel>Rol</StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    options={roles}
                                                    getOptionLabel={(option) => {
                                                        if (option.name) return option.name

                                                        const rl = roles.find(
                                                            (r) => r.id === option
                                                        )

                                                        return rl ? rl.name : ''
                                                    }}
                                                    getOptionSelected={(option, value) => {
                                                        return option.id === value
                                                    }}
                                                    value={role}
                                                    onChange={onRoleChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Rol"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button onClick={onBack} fullWidth>
                                                    Anterior
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={onFinish}
                                                >
                                                    Finalizar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Scene
