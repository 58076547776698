import React from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'

import getPropertyBuilder from './utils'

const Select = (props) => {
    const {
        connectors: { connect, drag },
    } = useNode()
    /* eslint-disable react/destructuring-assignment */
    const style = {
        height: props.height,
        background: props.background,
        color: props.color,
    }

    const getProperty = getPropertyBuilder(props)

    const value = getProperty('value')
    const title = getProperty('title')
    const divider = getProperty('divider')

    return (
        <React.Fragment>
            <ListItem ref={(ref) => connect(drag(ref))} style={style}>
                <ListItemText primary={title} secondary={value} />
            </ListItem>
            {divider ? <Divider /> : null}
        </React.Fragment>
    )
}

Select.craft = {
    displayName: 'Selección',
    custom: {
        component: true,
    },
    props: {
        label: 'Select',
        options: [
            {
                value: 10,
                text: 'Ten',
            },
        ],
    },
}

Select.propTypes = {
    height: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
}

export default Select
