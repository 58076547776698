import gql from "graphql-tag"

export const CREATE_TRAINING_SET_ENTRIES = gql`
    mutation CreateTrainingSetEntries($params: CreateTrainingSetEntriesRequest!){
        createTrainingSetEntries(params: $params) {
            status
        }
    }
`

export const GET_TRAINING_SETS = gql`
    query GetTrainingSets($params: GetTrainingSetsRequest) {
        getTrainingSets(params: $params) {
            status
            trainingSets {
                _id
                createdAt
                name
                labelType
                dataType
            }
        }
    }
`