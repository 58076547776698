import React, { useState } from "react"

import { useQuery } from "@apollo/react-hooks"
import { useHistory } from "react-router-dom"

import Paper from "@material-ui/core/Paper"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import CreateTrainingSetDialog from "./containers/create-training-set-dialog/scene"

import { GET_TRAINING_SETS_BY_PARENT } from "../../queries/training-sets"

const ListView = () => {

    const history = useHistory()

    const { data: { horus: { trainingSets: { getTrainingSetsByParent: { trainingSets = []} = {}} = {}} = {} } = {} } = useQuery(GET_TRAINING_SETS_BY_PARENT, { params: { parent: null }})

    const onTrainingSetClicked = i => () => {
        history.push(trainingSets[i].labelType + "/" + trainingSets[i]._id)
    }
    
    return (
        <div style={{ marginTop: -18 }}>
            <Paper style={{ padding: 20 }} elevation={3}>
                <h1>Training Sets</h1>
            </Paper>
            <List style={{ marginTop: 5, background: "#FFF" }}>
                {
                    trainingSets.map((t, i) => {
                        return (
                            <ListItem button onClick={onTrainingSetClicked(i)}>
                                <ListItemText primary={t.name} secondary={t.createdAt}></ListItemText>
                            </ListItem>
                        )
                    })
                }
            </List>
            <CreateTrainingSetDialog />
        </div>
    )
}

export default ListView