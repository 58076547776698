import React from 'react'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { GET_HMIS_BY_GROUP } from '../../../../../group/containers/hmi/queries/hmis'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const { group } = useParams()

    const { data: { getHMIsByGroup: { hmis = [] } = {} } = {} } = useQuery(GET_HMIS_BY_GROUP, {
        variables: { params: { group } },
    })

    const hmi = getProperty('hmi', null)
    const text = getProperty('text', null)

    const getComponents = () => {
        const hm = hmis.find((hI) => hI._id === hmi)

        if (!hm) return []

        return hm['components'].filter((c) => c.type === 'text')
    }

    return (
        <div className={classes.root}>
            <List>
                <ListItem>
                    <TextField
                        label="Ruta de Entrada"
                        value={getProperty('input-key', null)}
                        onChange={onPropertyChangeBuilder('input-key')}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>HMI</InputLabel>
                        <Select value={hmi} onChange={onPropertyChangeBuilder('hmi')}>
                            {hmis.map((hI) => (
                                <MenuItem key={hI._id} value={hI._id}>
                                    {hI.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Seleccione un HMI</FormHelperText>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <FormControl fullWidth disabled={!hmi}>
                        <InputLabel>Texto</InputLabel>
                        <Select value={text} onChange={onPropertyChangeBuilder('text')}>
                            {getComponents().map((hI) => (
                                <MenuItem key={hI._id} value={hI._id}>
                                    {hI._id}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Seleccione un texto</FormHelperText>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Versión</InputLabel>
                        <Select
                            value={getProperty('version', '$LATEST')}
                            onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                        >
                            {nodeDefinition.conf.versions.map((version) => (
                                <MenuItem key={`${node.id}_version${version}`} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
