import gql from 'graphql-tag'

export const GET_HMI_BY_ID = gql`
    query GetHMIById($params: GetHMIByIdRequest!) {
        getHMIById(params: $params) {
            root @client
            componentsById @client
            hmi {
                _id
                group
                name
                users {
                    _id
                    name
                    password
                }
                permissions {
                    hmi
                    parameters
                    datasets
                    settings
                }
                components {
                    _id
                    type
                    root
                    properties
                    components
                }
                datasets {
                    visible
                }
            }
        }
    }
`

export const ADD_HMI_COMPONENT = gql`
    mutation AddHMIComponent($params: AddHMIComponentRequest!) {
        addHMIComponent(params: $params) @client
    }
`

export const UPDATE_HMI_BY_ID = gql`
    mutation UpdateHMIById($params: UpdateHMIByIdRequest!) {
        updateHMIById(params: $params) {
            status
        }
    }
`
