import gql from "graphql-tag"

export const GET_INVOICES = gql`
    query {
        invoices(params: { first: 10 }) {
            id
            number
            createdAt
            amountPaid
            currency
            periodStart
            periodEnd
            pdfUrl
        }
    }
`