import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { useQuery } from '@apollo/react-hooks'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import _ from 'lodash'

import Sample from './sample'
import {
    GET_TRAINING_SET_BY_ID,
    GET_ENTRIES_BY_TRAINING_SET,
} from '../../../../queries/training-sets'
import { setCheckFilterLabel, setClasses } from '../../redux/general'
import { restartInPlaceAnnotations, setImageData } from '../../redux/images'
import { ROWS_PER_PAGE } from '../../constants'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -1,
        left: -1,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    section: {
        margin: 7,
    },
    formControl: {
        minWidth: 120,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    filterButton: {
        width: '100%',
        margin: 0,
        marginTop: 12,
        '& .MuiIconButton-label': {
            marginRight: 5,
        },
        '& .MuiFormControlLabel-label': {
            marginRight: 'auto',
            marginLeft: 17,
        },
    },
    iconButton: {
        color: '#000',
        padding: 0,
        position: 'absolute',
        top: 8,
        left: '80%',
    },
}))

/**
 * Responsible of get the elements from the database and
 * render all the entries in the colum on the right
 */
const ImagesMenu = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { trainingSet, page = 0 } = useParams()

    const { showUnlabelled, selectedClasses } = useSelector(
        (state) => state.trainingSetSegmentation.general.labels
    )

    const {
        data: {
            getTrainingSetById: { trainingSet: { classes: trainingSetClasses = [] } = {} } = {},
        } = {},
    } = useQuery(GET_TRAINING_SET_BY_ID, { variables: { params: { _id: trainingSet } } })

    const filters = [{ name: 'unlabelled', type: '', clause: showUnlabelled }]
    if (selectedClasses) {
        const toIntClasses = selectedClasses.map(parseInt)
        filters.push({ name: 'classes', type: '', clause: JSON.stringify(toIntClasses) })
    }

    let { data: { getTrainingSetEntriesByTrainingSet: { entries = [] } = {} } = {} } = useQuery(
        GET_ENTRIES_BY_TRAINING_SET,
        {
            variables: {
                params: {
                    trainingSet,
                    limit: ROWS_PER_PAGE,
                    offset: page * ROWS_PER_PAGE,
                    filters,
                },
            },
            fetchPolicy: 'network-only',
        }
    )

    entries = entries === null ? {} : entries
    entries = entries.map((obj) => {
        return obj.properties.reduce((prev, next) => {
            return { ...prev, [next.name]: next.value }
        }, {})
    })

    /* eslint-disable no-unused-vars */
    const onShowUnlabelledChanged = (event) => {
        dispatch(setCheckFilterLabel())
    }

    const onSelectedClassesChanged = (event) => {
        let result = event.target.value
        if (event.target.value.length === 0) {
            result = null
        }
        dispatch(setClasses(result))
    }

    const entriesId = useRef(null)
    if (
        !_.isEqual(
            entriesId.current,
            entries.map((obj) => obj._id)
        )
    ) {
        dispatch(restartInPlaceAnnotations())
        entriesId.current = entries.map((obj) => obj._id)
        dispatch(setImageData(entries))
    }

    return (
        <div style={{ height: 'calc(100vh - 200px)' }}>
            <Grid container alignItems="flex-end" style={{ padding: 0 }}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox name="checkedC" />}
                        label="Sin etiquetar"
                        checked={showUnlabelled}
                        onChange={onShowUnlabelledChanged}
                        labelPlacement="start"
                        className={classes.filterButton}
                    />
                    <Divider style={{ marginLeft: 20 }} />
                </Grid>
                <Grid
                    className={classes.section}
                    container
                    item
                    xs={12}
                    alignItems="flex-end"
                    style={{ padding: '0 10px' }}
                >
                    <Grid item xs alignItems="flex-end" justify="flex-end">
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel>Filtrar</InputLabel>
                            <Select
                                multiple
                                value={selectedClasses || []}
                                onChange={onSelectedClassesChanged}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={trainingSetClasses[value]}
                                                className={classes.chip}
                                            />
                                        ))}
                                    </div>
                                )}
                            >
                                {trainingSetClasses.map((name, i) => (
                                    <MenuItem key={name} value={String(i)}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <div
                style={{
                    height: 'calc(100% - 57px)',
                    overflowY: 'scroll',
                    padding: 5,
                    marginTop: '2vh',
                }}
            >
                <Divider />
                <Grid container>
                    {entries.map((entry) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={entry._id}>
                            <Sample
                                sampleId={entry._id}
                                url={entry.url}
                                annotations={JSON.parse(entry.annotations)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

export default ImagesMenu
