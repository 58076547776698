import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import { CREATE_RESOURCE, GET_RESOURCES } from '../../../../../../../../queries/resources'

const Camera = ({ onCancel }) => {
    const { group } = useParams()

    const [name, setName] = useState('')
    const [port, setPort] = useState('')

    const [createResource] = useMutation(CREATE_RESOURCE)

    const onAccept = async () => {
        await createResource({
            variables: {
                params: {
                    group,
                    type: 'camera-usb3-vision',
                    name,
                    config: port,
                },
            },
            refetchQueries: [
                {
                    query: GET_RESOURCES,
                    variables: {
                        params: {
                            group,
                        },
                    },
                },
            ],
        })
        onCancel()
    }

    const onNameChanged = (event) => {
        setName(event.target.value)
    }

    const onPortChanged = (event) => {
        setPort(event.target.value)
    }

    return (
        <React.Fragment>
            <DialogContent>
                <TextField label="Nombre" fullWidth value={name} onChange={onNameChanged} />
                <TextField label="Puerto" fullWidth value={port} onChange={onPortChanged} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button onClick={onAccept}>Aceptar</Button>
            </DialogActions>
        </React.Fragment>
    )
}

Camera.propTypes = {
    onCancel: PropTypes.func.isRequired,
}

export default Camera
