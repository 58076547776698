import React, { useState } from "react"

import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useMutation } from "@apollo/react-hooks"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography"

import { closeNewIndustrialDatasetsDialog } from "../../reducers/dialogs"

import { CREATE_INDUSTRIAL_DATASET, GET_INDUSTRIAL_DATASET_BY_PARENT, CREATE_MULTI_VIEW_DATASET } from "../../queries/industrial-datasets"

function NewIndustrialDatasetDialog(props) {

    const dispatch = useDispatch()

    const { id } = useParams()

    const { open } = useSelector(state => state.datasets.dialogs)

    const [ name, setName ] = useState(null)
    const [ type, setType ] = useState(null)
    const [ schema, setSchema ] = useState([])
    const [ fieldType, setFieldType ] = useState("")
    const [ fieldName, setFieldName ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ views, setViews ] = useState(4)

    const [ createIndustrialDataset ] = useMutation(CREATE_INDUSTRIAL_DATASET)
    const [ createMultiViewDataset ] = useMutation(CREATE_MULTI_VIEW_DATASET)

    const onNameTextFieldChanged = e => setName(e.target.value)
    const onTypeSelectChanged = e => setType(e.target.value)
    const onCancelClicked = () => dispatch(closeNewIndustrialDatasetsDialog())
    const onAcceptClicked = async () => {
        setLoading(true)
        switch(type) {
            case "multi-view":
                await createMultiViewDataset({
                    variables: {
                        params: { name, views }
                    },
                    refetchQueries: [
                        { query: GET_INDUSTRIAL_DATASET_BY_PARENT, variables: { params: { parent: null }}}
                    ]
                })
                break;
            default:
                await createIndustrialDataset({
                    variables: {
                        params: { name, type }
                    },
                    refetchQueries: [
                        { query: GET_INDUSTRIAL_DATASET_BY_PARENT, variables: { params: { parent: null }}}
                    ]
                })
                break;
        }
        setLoading(false)
        dispatch(closeNewIndustrialDatasetsDialog())
    }
    const onFieldTypeChange = e => {
        setFieldType(e.target.value)
    }
    const onFieldNameChange = e => {
        setFieldName(e.target.value)
    }
    const onRemoveField = index => () => {
        setSchema([
            ...schema.slice(0, index),
            ...schema.slice(index + 1),
        ])
    }
    const onAddField = () => {
        setSchema([
            ...schema,
            {
                type: fieldType,
                name: fieldName
            }
        ])
    }
    const onViewsChanged = e => {
        setViews(e.target.value)
    }

    const getFields = () => {
        switch(type) {
            case "multi-view":
                return (
                    <TextField label="Nombre" fullWidth type="number" inputProps={{ min: "2" }} value={views} onChange={onViewsChanged}></TextField>
                )
            case "custom":
                return (
                    <div>
                        <Typography variant={"h5"} style={{ marginTop: 10 }}>Campos</Typography>
                        {
                            schema.map((field, i) => (
                                <Chip
                                    label={`${field.name}: ${field.type}`}
                                    onDelete={onRemoveField(i)}
                                />
                            ))
                        }
                        <TextField label="Nombre" fullWidth value={fieldName} onChange={onFieldNameChange}></TextField>
                        <FormControl style={{ marginTop: 10 }} fullWidth>
                            <InputLabel>Tipo</InputLabel>
                            <Select value={fieldType} onChange={onFieldTypeChange} disabled={loading}>
                                <MenuItem value={"string"}>Texto</MenuItem>
                                <MenuItem value={"integer"}>Entero</MenuItem>
                                <MenuItem value={"double"}>Decimal</MenuItem>
                            </Select>
                        </FormControl>
                        <Button onClick={onAddField} fullWidth>Añadir Campo</Button>
                    </div>
                )
        }
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Nuevo Dataset</DialogTitle>
            <DialogContent>
                <TextField label="Nombre" fullWidth onChange={onNameTextFieldChanged} value={name} disabled={loading}/>
                <FormControl style={{ marginTop: 10 }} fullWidth>
                    <InputLabel>Tipo</InputLabel>
                    <Select value={type} onChange={onTypeSelectChanged} disabled={loading}>
                        <MenuItem value={"rgb-images"}>Imágenes RGB</MenuItem>
                        <MenuItem value={"multi-view"}>Multi Vista RGB</MenuItem>
                    </Select>
                </FormControl>
                {
                   getFields()
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClicked} disabled={loading}>Cancelar</Button>
                <Button onClick={onAcceptClicked} disabled={loading}>Aceptar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewIndustrialDatasetDialog