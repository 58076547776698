import React, { useMemo, useEffect } from 'react'

import { Link, useRouteMatch, useParams, useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/styles'

import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Container from '@material-ui/core/Container'
import ListSubheader from '@material-ui/core/ListSubheader'
import Button from '@material-ui/core/Button'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'

import SaveIcon from '@material-ui/icons/Save'
import ChevronLeft from '@material-ui/icons/ChevronLeft'

import LoadingBackdrop from 'components/LoadingBackdrop'

import { groups } from '../../utils/permissions'

import { GET_ROLE_BY_ID, UPDATE_ROLE, REMOVE_ROLE_BY_ID } from '../../queries/roles'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    list: {
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
        padding: 0,
    },
    subheader: {
        background: theme.palette.background.paper,
    },
}))

const Scene = () => {
    const classes = useStyles()
    const match = useRouteMatch()
    const history = useHistory()

    const { isAllowed: isUpdateRoleAllowed } = useIsAllowed({
        group: 'settings',
        action: 'updateRole',
    })
    const { isAllowed: isRemoveRoleAllowed } = useIsAllowed({
        group: 'settings',
        action: 'removeRole',
    })

    const { role } = useParams()

    const [localLoading, setLocalLoading] = React.useState(false)
    const [editNameDialogOpen, setEditNameDialogOpen] = React.useState(false)
    const [name, setName] = React.useState('')

    const {
        data: { getRoleById: { role: { name: queryName, permissions = [] } = {} } = {} } = {},
        loading: getRoleLoading,
        error,
    } = useQuery(GET_ROLE_BY_ID, { variables: { params: { id: role } } })

    const [updateRole] = useMutation(UPDATE_ROLE)
    const [removeRoleById] = useMutation(REMOVE_ROLE_BY_ID)

    useEffect(() => {
        if (queryName) setName(queryName)
    }, [queryName])

    const permissionsMap = useMemo(() => {
        const map = {}

        permissions.forEach((p) => {
            if (map[p.group] === undefined) map[p.group] = {}
            map[p.group][p.action] = p.allowed
        })

        return map
    }, [permissions])

    const onPermissionsChange = (group, action) => (e) => {
        const map = { ...permissionsMap }
        if (map[group] === undefined) map[group] = {}
        map[group][action] = e.target.checked

        const perm = Object.keys(map).reduce((acc, g) => {
            const actions = Object.keys(map[g]).map((a) => ({
                group: g,
                action: a,
                allowed: map[g][a],
            }))
            return [...acc, ...actions]
        }, [])

        updateRole({
            variables: { params: { id: role, permissions: perm } },
            refetchQueries: [{ query: GET_ROLE_BY_ID, variables: { params: { id: role } } }],
        })
    }

    const onRemove = async () => {
        setLocalLoading(true)
        await removeRoleById({
            variables: { params: { id: role } },
        })
        setLocalLoading(false)

        history.push('list')
    }

    const onEditName = () => {
        setEditNameDialogOpen(true)
    }

    const onNameChange = (e) => {
        setName(e.target.value)
    }

    const onEditNameAccept = async () => {
        setLocalLoading(true)
        setEditNameDialogOpen(false)

        await updateRole({
            variables: { params: { id: role, name } },
            refetchQueries: [{ query: GET_ROLE_BY_ID, variables: { params: { id: role } } }],
        })
        setLocalLoading(false)
    }

    const onEditNameCancel = () => {
        setEditNameDialogOpen(false)
    }

    const loading = getRoleLoading || localLoading

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button color="primary" component={Link} to="list" startIcon={<ChevronLeft />}>
                        Volver a roles
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <List className={classes.list}>
                            <ListItem
                                button
                                key={1}
                                onClick={onEditName}
                                disabled={!isUpdateRoleAllowed}
                            >
                                <ListItemText primary="Name" secondary={queryName} />
                            </ListItem>
                            <Divider />
                            {groups.map((group) => (
                                <React.Fragment key={group.id}>
                                    <ListSubheader className={classes.subheader}>
                                        {group.label}
                                    </ListSubheader>
                                    {group.actions.map((a) => (
                                        <ListItem button key={a.id}>
                                            <ListItemText primary={a.label} secondary="" />
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    edge="end"
                                                    onChange={onPermissionsChange(
                                                        group.name,
                                                        a.action
                                                    )}
                                                    checked={
                                                        (permissionsMap[group.name] &&
                                                            permissionsMap[group.name][a.action]) ||
                                                        false
                                                    }
                                                    disabled={!isUpdateRoleAllowed}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </React.Fragment>
                            ))}
                            <Divider />
                            {isRemoveRoleAllowed && (
                                <ListItem button>
                                    <ListItemText
                                        primary="Eliminar"
                                        secondary="Eliminar el rol. Esta operación no se puede deshacer"
                                        onClick={onRemove}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={editNameDialogOpen} onClose={() => setEditNameDialogOpen(false)}>
                <DialogTitle>Editar nombre</DialogTitle>
                <DialogContent>
                    <TextField label="Nombre" fullWidth value={name} onChange={onNameChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onEditNameCancel}>Cancelar</Button>
                    <Button color="primary" onClick={onEditNameAccept}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingBackdrop loading={loading} />
        </Container>
    )
}

export default Scene
