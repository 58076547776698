import gql from 'graphql-tag'

export const GET_BLUEPRINTS_BY_GROUP = gql`
    query GetBlueprintsByGroup($params: GetBlueprintsByGroupRequest!) {
        getBlueprintsByGroup(params: $params) {
            status
            blueprints {
                _id
                name
            }
        }
    }
`

export const GET_BLUEPRINT_BY_ID = gql`
    query GetBlueprintById($params: GetBlueprintByIdRequest!) {
        getBlueprintById(params: $params) {
            status
            blueprint {
                _id
                nodes {
                    id
                    name
                    position {
                        x
                        y
                    }
                    ports {
                        name
                        type
                        properties {
                            secondType
                        }
                    }
                    properties {
                        type
                        value
                    }
                }
                links {
                    id
                    from {
                        nodeId
                        portId
                    }
                    to {
                        nodeId
                        portId
                    }
                }
            }
        }
    }
`

export const CREATE_BLUEPRINT = gql`
    mutation CreateBlueprint($params: CreateBlueprintRequest!) {
        createBlueprint(params: $params) {
            status
            blueprint {
                _id
            }
        }
    }
`

export const REMOVE_BLUEPRINT = gql`
    mutation RemoveBlueprint($params: RemoveBlueprintRequest!) {
        removeBlueprint(params: $params) {
            status
            blueprint {
                _id
            }
        }
    }
`

export const UPDATE_BLUEPRINT = gql`
    mutation UpdateBlueprint($params: UpdateBlueprintRequest!) {
        updateBlueprint(params: $params) {
            status
            blueprint {
                _id
            }
        }
    }
`

export const DEPLOY_BLUEPRINT = gql`
    mutation DeployBlueprint($params: DeployBlueprintRequest!) {
        deployBlueprint(params: $params) {
            status
        }
    }
`

export const GET_FUNCTIONS = gql`
    query GetFunctions {
        getFunctions {
            status
            payload {
                _id
                name
                group
                disabled
                conf {
                    versions
                }
                ports {
                    name
                    type
                    properties {
                        secondType
                    }
                }
            }
        }
    }
`
