import React, { useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useParams, useHistory } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import AddIcon from '@material-ui/icons/Add'

import { UPDATE_MONITOR, GET_MONITOR_BY_ID } from '../../../../../../queries/monitors'

const Scene = (props) => {
    const history = useHistory()

    const { monitor } = useParams()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = props.config || {}

    const [open, setOpen] = useState(false)
    const [from, setFrom] = useState('00:00')
    const [to, setTo] = useState('00:00')
    const [loading, setLoading] = useState(false)

    const [updateMonitor] = useMutation(UPDATE_MONITOR)

    const onAdd = () => {
        setOpen(true)
    }

    const onAccept = async () => {
        setLoading(true)

        try {
            await updateMonitor({
                variables: {
                    params: {
                        id: monitor,
                        configuration: JSON.stringify({
                            ...config,
                            schedule: [
                                ...config.schedule,
                                {
                                    from: from,
                                    to: to,
                                },
                            ],
                        }),
                    },
                },
                refetchQueries: [
                    {
                        query: GET_MONITOR_BY_ID,
                        variables: {
                            params: {
                                id: monitor,
                            },
                        },
                    },
                ],
            })

            enqueueSnackbar(
                'Horario agregado correctamente. Recuerde que para que los cambios tengan efecto debe desplegar los grupos asignados a este monitor',
                {
                    variant: 'success',
                    action: (snackbarId) => (
                        <React.Fragment>
                            <Button
                                onClick={() => {
                                    history.push('../devices')
                                    closeSnackbar(snackbarId)
                                }}
                            >
                                IR A GRUPOS
                            </Button>
                            <Button
                                onClick={() => {
                                    closeSnackbar(snackbarId)
                                }}
                            >
                                DESCARTAR
                            </Button>
                        </React.Fragment>
                    ),
                    autoHideDuration: 15000,
                }
            )

            setOpen(false)
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onFromChange = (event) => {
        setFrom(event.target.value)
    }

    const onToChange = (event) => {
        setTo(event.target.value)
    }

    return (
        <React.Fragment>
            <Fab
                color="primary"
                onClick={onAdd}
                style={{ position: 'absolute', bottom: 20, right: 20 }}
            >
                <AddIcon />
            </Fab>
            <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Agregar horario</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="date-from"
                                label="Desde"
                                type="time"
                                fullWidth
                                variant="outlined"
                                value={from}
                                onChange={onFromChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="date-to"
                                label="Hasta"
                                type="time"
                                fullWidth
                                variant="outlined"
                                value={to}
                                onChange={onToChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary" disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAccept} color="primary" disabled={loading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Scene
