import React, { useState } from "react"

import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'
import { useParams, useHistory } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import EditIcon from '@material-ui/icons/Edit'

import { UPDATE_MONITOR, GET_MONITOR_BY_ID } from '../../../../../../../../queries/monitors'

const Scene = (props) => {

    const history = useHistory()

    const { monitor } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const config = props.config || {}
    const threshold = props.threshold || {}

    const [ open, setOpen ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ from, setFrom ] = useState(threshold.from)
    const [ to, setTo ] = useState(threshold.to)

    const [ updateMonitor ] = useMutation(UPDATE_MONITOR)

    const onEdit = () => {
        setOpen(true)
    }

    const onAccept = async () => {
        setLoading(true)

        try {
            await updateMonitor({
                variables: {
                    params: {
                        id: monitor,
                        configuration: JSON.stringify({
                            ...config,
                            thresholds: [
                                ...config.thresholds.slice(0, props.index),
                                {
                                    ...config.thresholds[props.index],
                                    from: from,
                                    to: to
                                },
                                ...config.thresholds.slice(props.index + 1)
                            ]
                        })
                    }
                },
                refetchQueries: [
                    {
                        query: GET_MONITOR_BY_ID,
                        variables: {
                            params: {
                                id: monitor
                            }
                        }
                    }
                ]
            })

            enqueueSnackbar('Umbral actualizado correctamente. Recuerde que para que los cambios tengan efecto debe desplegar los grupos asignados a este monitor', {
                variant: 'success',
                action: snackbarId => (
                    <React.Fragment>
                        <Button onClick={() => { 
                            history.push("../devices")
                            closeSnackbar(snackbarId)
                        }}>
                            IR A GRUPOS
                        </Button>
                        <Button onClick={() => { closeSnackbar(snackbarId) }}>
                            DESCARTAR
                        </Button>
                    </React.Fragment>
                ),
                autoHideDuration: 10000
            });
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" })
        } finally {
            setLoading(false)
            setOpen(false)
        }
    }

    const onCancel = () => {
        setOpen(false)
    }

    const onFromChange = (event) => {
        setFrom(event.target.value)
    }

    const onToChange = (event) => {
        setTo(event.target.value)
    }
    
    return (
        <React.Fragment>
            <ListItem key={props.index}>
                <ListItemText primary={threshold.name} secondary={`${threshold.from} - ${threshold.to}`} />
                <ListItemSecondaryAction>
                    <IconButton 
                        edge="end" 
                        aria-label="edit" 
                        onClick={onEdit}
                        disabled={loading}
                    >
                        <EditIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Agregar un umbral</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                label="Desde"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={from}
                                onChange={onFromChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                label="Hasta"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={to}
                                onChange={onToChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary" disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onAccept} color="primary" disabled={loading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Scene