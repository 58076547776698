import React, { useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import {
    DrawBoxes,
    DrawBoxesComponent,
    DrawBoxesInputComponent,
    DRAWBOXES_ID,
} from './drawBoxes.jsx'

import { CONSTANT_ID, ConstantComponent, ConstantInputComponent, Constant } from './constant'

import { OBJECT_DETECTION_ID, ObjectDetectionComponent, ObjectDetectionInputComponent, ObjectDetection } from "./object-detection"
console.log(ObjectDetection)
const operations = {
    [DRAWBOXES_ID]: {
        name: 'Dibujar anotaciones',
        class: DrawBoxes,
        component: DrawBoxesComponent,
        input: DrawBoxesInputComponent,
    },
    [CONSTANT_ID]: {
        name: 'Constante',
        class: Constant,
        component: ConstantComponent,
        input: ConstantInputComponent,
    },
    [OBJECT_DETECTION_ID]: {
        name: "Deteccion de Objetos",
        class: ObjectDetection,
        component: ObjectDetectionComponent,
        input: ObjectDetectionInputComponent,
    }
}

/**
 * Manage the operations in virtual columns
 */
export const Operations = ({ columnsList, dataContainer }) => {
    const [operationType, setOperationType] = useState(null)

    const dataRef = useRef({})

    const onOperationChange = (event) => {
        setOperationType(event.target.value)
        dataContainer.current.type = event.target.value // eslint-disable-line no-param-reassign
        dataContainer.current.data = dataRef // eslint-disable-line no-param-reassign
    }
    return (
        <FormControl fullWidth>
            <InputLabel>Tipo de operación</InputLabel>
            <Select value={operationType} onChange={onOperationChange}>
                {Object.keys(operations).map((obj) => {
                    return (
                        <MenuItem key={obj} value={obj}>
                            {operations[obj].name}
                        </MenuItem>
                    )
                })}
            </Select>
            {React.createElement(
                !Object.hasOwn(operations, operationType)
                    ? () => null
                    : operations[operationType].input,
                { columnsList, dataContainer: dataRef },
                null
            )}
        </FormControl>
    )
}

Operations.propTypes = {
    /** List of all columns */
    columnsList: PropTypes.array.isRequired,
    /** Static container to save the configuration */
    dataContainer: PropTypes.object.isRequired,
}
export default operations
