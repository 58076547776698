import React from 'react'

import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import FAB from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import IconAdd from '@material-ui/icons/Add'

import { GET_MONITORS } from '../../queries/monitors'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(5),
    },
    stateColumn: {
        width: '100px',
    },
    actionsColumn: {
        width: '200px',
    },
}))

const Monitors = (props) => {
    const classes = useStyles()

    const {
        loading,
        error,
        data: { getMonitors: { monitors = [] } = {} } = {},
    } = useQuery(GET_MONITORS, { variables: { params: {} } })

    const { isAllowed: isCreateMonitorAllowed } = useIsAllowed({
        group: 'monitors',
        action: 'createMonitor',
    })

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2">Monitores</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.stateColumn}>Estado</TableCell>
                                    <TableCell>Nombre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {monitors.map((monitor) => (
                                    <TableRow key={monitor.id}>
                                        <TableCell className={classes.stateColumn}>
                                            {monitor.status}
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`${monitor.id}`}>{monitor.name}</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                {isCreateMonitorAllowed && (
                    <div className={classes.fab}>
                        <Link to="create">
                            <FAB color="primary" aria-label="add">
                                <IconAdd />
                            </FAB>
                        </Link>
                    </div>
                )}
            </Grid>
        </Container>
    )
}

export default Monitors
