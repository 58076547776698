import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { withTheme } from '@mui/styles'
import { Fab } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

import { UPDATE_ENTRY_BY_ID } from '../../../../queries/training-sets'
import { saveInPlace } from '../../redux/images'
import { last } from 'lodash'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'fixed',
            bottom: theme.spacing(9),
            right: theme.spacing(13),
        },
        '@keyframes blinker': {
            from: { opacity: 1 },
            to: { opacity: 0 },
        },
        blinkingIcon: {
            animationName: '$blinker',
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
            animationDirection: 'alternate',
        },
    }
})

/**
 * Automatic save
 *
 */
const Save = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const savingTime = useSelector((state) => state.trainingSetObjectDetection.images.saveInPlace)
    const { trainingSet: trainingSetId } = useParams()
    const [updating, setUpdating] = useState(false)
    const [finishing, setFinished] = useState(false)
    const [error, setError] = useState(false)

    const inPlaceAnnotations = useSelector(
        (state) => state.trainingSetObjectDetection.images.inPlaceAnnotations || []
    )
    const lastOrSelected = useSelector(
        (state) => state.trainingSetObjectDetection.images.saveMode || []
    )
    const lastEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.lastEntry || null
    )
    const selectedEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedEntry || null
    )
    const autosaveTime = useSelector(
        (state) => state.trainingSetObjectDetection.settings.autosave.time
    )

    const [updateAnnotations] = useMutation(UPDATE_ENTRY_BY_ID)
    const finishAnimationTimer = useRef(null)
    const autosaveTimer = useRef(null)
    const saveAnnotations = async (entryId, annotation) => {
        // Checking annotations
        const fixAnnotation = annotation.map((obj) => {
            if (obj.coords.some((val) => val === null)) {
                throw new Error('Could not upload the annotations')
            }
            if (!Object.hasOwn(obj, 'class')) {
                throw new Error('Could not upload the annotations')
            }
            // Check coords order
            const xUp = Math.floor(Math.min(obj.coords[0], obj.coords[2]))
            const xBt = Math.floor(Math.max(obj.coords[0], obj.coords[2]))
            const yUp = Math.floor(Math.min(obj.coords[1], obj.coords[3]))
            const yBt = Math.floor(Math.max(obj.coords[1], obj.coords[3]))
            const newObj = {
                class: `${obj.class}`,
                left: xUp,
                top: yUp,
                width: xBt - xUp,
                height: yBt - yUp,
            }
            return newObj
        })

        const { data, error: errorMut = false } = await updateAnnotations({
            variables: {
                params: {
                    trainingSet: trainingSetId,
                    _id: entryId,
                    inputs: [{ name: 'annotations', value: JSON.stringify(fixAnnotation) }],
                },
            },
        })
        if (data.updateTrainingSetEntryById.status === null || errorMut) {
            throw new Error('Could not upload the annotations')
        }
    }

    const showUpdating = !error && updating && !finishing
    const showFinishedButton = !error && finishing && !updating
    const showErrorButton = error && finishing && !updating

    useEffect(() => {
        let entryToSave = null
        if (lastOrSelected === 'LAST') {
            entryToSave = lastEntry
        } else if (lastOrSelected === 'SELECTED') {
            entryToSave = selectedEntry
        } else {
            entryToSave = selectedEntry
        }
        if (entryToSave === null) {
            saveInPlace(false)
            return
        }
        if (savingTime) {
            setUpdating(true)
            const updatePromises = []
            const actualAnnotation = { [entryToSave]: inPlaceAnnotations[entryToSave] }
            Object.keys(actualAnnotation).forEach((obj) => {
                console.log('Promises')
                updatePromises.push(saveAnnotations(obj, actualAnnotation[obj]))
            })
            Promise.all(updatePromises)
                .then(() => {
                    setUpdating(false)
                    setFinished(true)
                    finishAnimationTimer.current = setTimeout(() => {
                        setFinished(false)
                        clearTimeout(finishAnimationTimer)
                        finishAnimationTimer.current = null
                        dispatch(saveInPlace(false))
                    }, 1000)
                })
                .catch(() => {
                    setUpdating(false)
                    setFinished(true)
                    setError(true)
                    finishAnimationTimer.current = setTimeout(() => {
                        setFinished(false)
                        setError(false)
                        clearTimeout(finishAnimationTimer)
                        finishAnimationTimer.current = null
                        dispatch(saveInPlace(false))
                    }, 1000)
                })
        }
    }, [savingTime])

    // BUG: Bug with autosave
    // useEffect(() => {
    //     if (autosaveTimer.current !== null) {
    //         clearInterval(autosaveTimer.current)
    //     }
    //     if (autosaveTime > 0) {
    //         autosaveTimer.current = setInterval(() => {
    //             dispatch(saveInPlace(true))
    //         }, autosaveTime * 1000)
    //     } else {
    //         autosaveTimer.current = null
    //     }
    // }, [autosaveTime])

    if (savingTime) {
        return (
            <div className={classes.root}>
                {showUpdating && (
                    <Fab variant="contained" className={classes.blinkingIcon} color="primary">
                        <SaveIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
                {showFinishedButton && (
                    <Fab variant="contained" color="success">
                        <DoneIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
                {showErrorButton && (
                    <Fab variant="contained" color="error">
                        <CloseIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
            </div>
        )
    }
    return null
}
// Using current theme
const SaveThemed = withTheme(Save)

export default SaveThemed
