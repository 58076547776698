import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import Loading from 'components/Loading'

import { GET_RESOURCES, REMOVE_RESOURCE } from '../../../../../../queries/resources'

import { useIsAllowed } from 'queries/permissions'

const ResourcesList = () => {
    const { group } = useParams()

    const { data: { getResourcesByGroup: { resources = [] } = {} } = {}, loading: loadingGQL } =
        useQuery(GET_RESOURCES, {
            variables: {
                params: {
                    group,
                },
            },
        })
    const { isAllowed: isRemoveResource } = useIsAllowed({
        action: 'removeResource',
        group: 'groups',
    })

    const [removeResource] = useMutation(REMOVE_RESOURCE)

    const onDeleteClicked = (_id) => () => {
        removeResource({
            variables: {
                params: {
                    _id,
                },
            },
        })
    }

    return (
        <Loading loading={loadingGQL}>
            {resources.length ? (
                <Paper>
                    <List>
                        {resources.map((resource) => (
                            <ListItem key={resource._id}>
                                <ListItemText primary={resource.name} secondary={resource.type} />
                                {isRemoveResource && (
                                    <IconButton onClick={onDeleteClicked(resource._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            ) : (
                <div style={{ margin: '20px', textAlign: 'center' }}>
                    Cree un periférico para continuar &#8600;
                </div>
            )}
        </Loading>
    )
}

export default ResourcesList
