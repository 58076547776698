import gql from "graphql-tag"

export const CREATE_INDUSTRIAL_DATASET = gql`
    mutation CreateIndustrialDataset($params: CreateIndustrialDatasetRequest!) {
        createIndustrialDataset(params: $params) {
            status
        }
    }
`

export const CREATE_MULTI_VIEW_DATASET = gql`
    mutation CreateMultiViewDataset($params: CreateMultiViewDatasetRequest!) {
        createMultiViewDataset(params: $params) {
            status
        }
    }
`

export const REMOVE_INDUSTRIAL_DATASET = gql`
    mutation RemoveIndustrialDataset($params: RemoveIndustrialDatasetRequest!) {
        removeIndustrialDataset(params: $params) {
            status
        }
    }
`

export const GET_INDUSTRIAL_DATASET_BY_PARENT = gql`
    query GetIndustrialDatasetsByParent($params: GetIndustrialDatasetsByParentRequest!) {
        getIndustrialDatasetsByParent(params: $params) {
            status
            datasets {
                _id
                createdAt
                name
                type
            }
        }
    }
`

export const GET_INDUSTRIAL_DATASET_BY_ID = gql`
    query GetIndustrialDatasetById($params: GetIndustrialDatasetByIdRequest!) {
        getIndustrialDatasetById(params: $params) {
            status
            dataset {
                _id
                name
            }
        }
    }

`