import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'


const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize: '1.5em',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
}))

const Scene = (props) => {

    const classes = useStyles()

    const { thresholds } = props

    const [thresholdName, setThresholdName] = useState(null)
    const [thresholdValueFrom, setThresholdValueFrom] = useState(null)
    const [thresholdValueTo, setThresholdValueTo] = useState(null)

    const onThresholdNameChange = (event) => {
        setThresholdName(event.target.value)
    }

    const onThresholdValueFromChange = (event) => {
        setThresholdValueFrom(event.target.value)
    }

    const onThresholdValueToChange = (event) => {
        setThresholdValueTo(event.target.value)
    }

    const onAddThreshold = () => {
        if (!thresholdName || !thresholdValueFrom || !thresholdValueTo) {
            return
        }

        props.setThresholds([
            ...props.thresholds,
            {
                name: thresholdName,
                from: thresholdValueFrom,
                to: thresholdValueTo,
            }
        ])
    }

    const onRemoveThreshold = (index) => () => {
        props.setThresholds(props.thresholds.filter((_, i) => i !== index))    
    }

    return (
        <Grid item xs={12} container spacing={2}>
                <Grid className={classes.root} item xs={12}>
                    {
                        thresholds.map((threshold, index) => (
                            <Chip
                                key={index}
                                label={`${threshold.name}: ${threshold.from} - ${threshold.to}`}
                                onDelete={onRemoveThreshold(index)}
                                className={classes.chip}
                            />
                        ))
                    }
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="Nombre del umbral" 
                        variant="outlined" 
                        fullWidth 
                        onChange={onThresholdNameChange}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="Umbral Inferior" 
                        type="number" 
                        variant="outlined" 
                        fullWidth 
                        onChange={onThresholdValueFromChange}
                        value={thresholdValueFrom}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label="Umbral Superior" 
                        type="number" 
                        variant="outlined" 
                        fullWidth 
                        onChange={onThresholdValueToChange}
                        value={thresholdValueTo}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        color="primary"
                        onClick={onAddThreshold}
                        fullWidth
                    >
                        Agregar umbral
                    </Button>
                </Grid>
            </Grid>
    )
}

export default Scene