import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNode } from '@craftjs/core'
import Grid from '@material-ui/core/Grid'
import MaterialTabs from '@material-ui/core/Tabs'
import MaterialTab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'

const Tabs = ({ children, tabs }) => {
    const {
        connectors: { connect, drag },
    } = useNode((node) => {
        return {
            data: node.data,
        }
    })

    const [current, setCurrent] = useState(0)
    const onTabChange = (event, newValue) => setCurrent(newValue)

    return (
        <Grid
            ref={(ref) => connect(drag(ref))}
            container
            verticalAlign="center"
            justifyContent="center"
            style={{ height: '100%' }}
        >
            <Grid item xs={11} style={{ height: '100%' }}>
                {children &&
                    children.props.children.map((c, i) => (
                        <div
                            key={i}
                            style={{ display: current === i ? 'block' : 'none', height: '100%' }}
                        >
                            {c}
                        </div>
                    ))}
            </Grid>
            <Grid item xs={1}>
                <Paper
                    style={{ width: '100%', margin: '0 auto', height: 'fit-content' }}
                    elevation={3}
                >
                    <MaterialTabs
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        value={current}
                        orientation="vertical"
                        onChange={onTabChange}
                        TabIndicatorProps={{
                            style: {
                                left: 0,
                            },
                        }}
                    >
                        {tabs.map((t) => (
                            <MaterialTab
                                key={t.label}
                                label={t.label}
                                style={{ minWidth: 'unset' }}
                            />
                        ))}
                    </MaterialTabs>
                </Paper>
            </Grid>
        </Grid>
    )
}

Tabs.craft = {
    displayName: 'Pestañas',
    custom: {
        component: true,
    },
    rules: {
        canMoveIn: (incomingNode) => incomingNode.data.type === Tabs,
    },
    props: {
        tabs: [],
    },
}

Tabs.propTypes = {
    tabs: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

Tabs.defaultProps = {
    tabs: [],
    children: [],
}

export default Tabs
