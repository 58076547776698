import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import EmailIcon from '@material-ui/icons/Email'
import PasswordIcon from '@material-ui/icons/Lock'
import ConfirmationCodeIcon from '@material-ui/icons/DoneOutline'

import LeftSide from "../components/LeftSide"
import RightSide from "../components/RightSide"
import Container from "../components/Container"

import PasswordValidator  from 'password-validator';
 
var schema = new PasswordValidator();
 
schema
.is().min(8)
.is().max(100)
.has().uppercase()
.has().lowercase() 
.has().digits()
.has().symbols()

const useStyles = makeStyles(theme => ({
    root: {
        '&$checked': {
            color: '#64dd17',
        }
    },
    checked: {}
}))

const ForgotPassword = (props) => {

    const classes = useStyles()

    const { confirmForgotPassword } = props

    const [ isLongEnough, setIsLongEnough ] = useState(false)
    const [ hasNumbers, setHasNumbers ] = useState(false)
    const [ hasLower, setHasLower ] = useState(false)
    const [ hasUpper, setHasUpper ] = useState(false)
    const [ hasSymbols, setHasSymbols ] = useState(false)
    const [ correctRepeatPassword, setCorrectRepeatPassword ] = useState(false)

    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ repeatPassword, setRepeatPassword ] = useState("")
    const [ confirmationCode, setConfirmationCode ] = useState("")
    
    const onEmailChange = e => {
        setEmail(e.target.value)
    }

    const onPasswordChange = e => {
        setPassword(e.target.value)

        const list = schema.validate(e.target.value, { list: true })

        const funcs = {
            "min": setIsLongEnough,
            "uppercase": setHasUpper,
            "lowercase": setHasLower,
            "digits": setHasNumbers,
            "symbols": setHasSymbols
        }

        Object.values(funcs).map(f => f(true))
        list.map(l => funcs[l](false))

        setCorrectRepeatPassword(e.target.value === repeatPassword)
    }

    const onRepeatPasswordChange = e => {
        setRepeatPassword(e.target.value)
        setCorrectRepeatPassword(e.target.value === password)
    }

    const onConfirmationCodeChanged = e => {
        setConfirmationCode(e.target.value)
    }

    const onConfirmForgotPassword = async () => {
        if(isLongEnough && hasNumbers && hasLower && hasSymbols && correctRepeatPassword) {
            await confirmForgotPassword({ email, password, confirmationCode})
        }
    }

    return (
        <Container>
            <LeftSide/>
            <RightSide>
                <Grid item xs={12}>
                    <Typography variant="h2" component="h2" style={{ marginBottom: 10 }}>Restore Password</Typography>
                </Grid>
                <Grid item xs={9} style={{ paddingRight: 20 }}>
                    <Grid item xs={12}>                                
                        <TextField 
                            label="Email" 
                            placeholder="Enter your email" 
                            variant="outlined"
                            margin="normal" 
                            fullWidth 
                            value={email}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><EmailIcon/></InputAdornment>
                            }}
                            onChange={onEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12}>                                
                        <TextField 
                            label="Contraseña"
                            type="password" 
                            placeholder="Enter new password" 
                            variant="outlined"
                            margin="normal" 
                            fullWidth 
                            value={password}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><PasswordIcon/></InputAdornment>
                            }}
                            onChange={onPasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12}>                                
                        <TextField 
                            label="Repetir Contraseña"
                            type="password" 
                            placeholder="Vuelva a introducir la nueva contraseña" 
                            variant="outlined"
                            margin="normal" 
                            fullWidth 
                            value={repeatPassword}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><PasswordIcon/></InputAdornment>
                            }}
                            onChange={onRepeatPasswordChange}
                        />
                    </Grid>
                    <Grid item xs={12}>                                
                        <TextField 
                            label="Confirmation Code" 
                            placeholder="Enter confirmation code" 
                            variant="outlined"
                            margin="normal" 
                            fullWidth 
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><ConfirmationCodeIcon/></InputAdornment>
                            }}
                            value={confirmationCode}
                            onChange={onConfirmationCodeChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Fab 
                            variant="extended" 
                            color="primary" 
                            aria-label="add" 
                            style={{ fontWeight: 900, fontSize: 13, background: "linear-gradient(60deg,#FF4E50,#F9D423)", margin: "25px 0", width: "100%", boxShadow: "0 0 45px -12px rgba(0,0,0,0.6)" }}
                            onClick={onConfirmForgotPassword}
                            disabled={!(isLongEnough && hasNumbers && hasLower && hasSymbols && correctRepeatPassword && email && confirmationCode)}
                        >
                            Restore
                        </Fab>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                        <FormControlLabel
                            control={<Checkbox classes={{ root: classes.root, checked: classes.checked }} checked={isLongEnough} />}
                            label="Longitud mínima 8"
                        />
                        <FormControlLabel
                            control={<Checkbox classes={{ root: classes.root, checked: classes.checked }} checked={hasNumbers} />}
                            label="Debe tener números"
                        />
                        <FormControlLabel
                            control={<Checkbox classes={{ root: classes.root, checked: classes.checked }} checked={hasUpper} />}
                            label="Debe tener mayúsculas"
                        />
                        <FormControlLabel
                            control={<Checkbox classes={{ root: classes.root, checked: classes.checked }} checked={hasLower} />}
                            label="Debe tener minúsculas"
                        />
                        <FormControlLabel
                            control={<Checkbox classes={{ root: classes.root, checked: classes.checked }} checked={hasSymbols} />}
                            label="Debe tener al menos un caracter especial"
                        />
                        <FormControlLabel
                            control={<Checkbox classes={{ root: classes.root, checked: classes.checked }} checked={correctRepeatPassword} />}
                            label="Las contraseñas coinciden"
                        />
                    </Grid>
            </RightSide>
        </Container>
    )
}



import { graphql, compose } from "react-apollo"
import { withRouter } from "react-router-dom"

import { CONFIRM_FORGOT_PASSWORD } from "../queries/authentication"

export default compose(
    withRouter,
    graphql(CONFIRM_FORGOT_PASSWORD, {
        props: ({ mutate, ownProps }) => {
            return {
                confirmForgotPassword: async (params) => {
                    const res = await mutate({ variables: { params }})
                    ownProps.history.push("/")
                }
            }
        }
    })
)(ForgotPassword)