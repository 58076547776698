import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { closeUpdateTrainingSetClassesDialog } from '../../redux/general'
import {
    UPDATE_TRAINING_SET_BY_ID,
    GET_TRAINING_SET_BY_ID,
} from '../../../../queries/training-sets'

const UpdateTrainingSetClassesDialog = () => {
    const dispatch = useDispatch()

    const { trainingSet: trainingSetId } = useParams()
    const [clase, setClase] = useState('')
    const { updateTrainingSetClassesDialogOpen } = useSelector(
        (state) => state.trainingSetSegmentation.general
    )
    const { data: { getTrainingSetById: { trainingSet = {} } = {} } = {} } = useQuery(
        GET_TRAINING_SET_BY_ID,
        {
            variables: {
                params: {
                    _id: trainingSetId,
                },
            },
        }
    )
    const [updateTrainingSetById] = useMutation(UPDATE_TRAINING_SET_BY_ID)

    const onCancel = () => {
        dispatch(closeUpdateTrainingSetClassesDialog())
        setClase('')
    }

    const onAccept = async () => {
        await updateTrainingSetById({
            variables: {
                params: {
                    _id: trainingSetId,
                    update: {
                        classes: [...trainingSet.classes, clase],
                    },
                },
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SET_BY_ID,
                    variables: {
                        params: {
                            _id: trainingSetId,
                        },
                    },
                },
            ],
        })
        onCancel()
    }

    const onClaseChanged = (event) => {
        setClase(event.target.value)
    }

    return (
        <Dialog open={updateTrainingSetClassesDialogOpen}>
            <DialogTitle>Añadir clase</DialogTitle>
            <DialogContent>
                <TextField value={clase} onChange={onClaseChanged} label="Clase" />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button onClick={onAccept}>Aceptar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateTrainingSetClassesDialog
