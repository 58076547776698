import React from "react"

import SensorList from "./containers/sensor-list"

const Sensors = (props) => {
    return (
        <React.Fragment>
           <SensorList />
        </React.Fragment>
    )
}

export default Sensors