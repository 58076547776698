import React from 'react'

import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom'

import UsersList from './containers/users-list/scene'
import NewUserWizard from './containers/new-user-wizard/scene'
import EditUser from './containers/edit-user/scene'

const Scene = () => {
    const match = useRouteMatch()
    const history = useHistory()

    return (
        <Switch>
            <Route path={match.url + '/list'} component={UsersList} />
            <Route path={match.url + '/new'} component={NewUserWizard} />
            <Route path={match.url + '/:user'} component={EditUser} />
            <Redirect to={match.url + '/list'} />
        </Switch>
    )
}

export default Scene
