import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { useDispatch } from 'react-redux'

import TableWithSearch from 'components/TableWithSearch'
import { useJobsDetails, useJobsRunList } from 'queries/jobs'
import { setCurrentWorkflow } from '../../redux/jobs'
import RunJob from './runJob'

const JobsDetails = () => {
    const { workflowId } = useParams()
    const dispatch = useDispatch()

    const [columns, setColumns] = useState([
        {
            title: 'Nombre',
            field: '_id',
            sortDirection: 'asc',
        },
        {
            title: 'Estado',
            field: 'state',
            sortDirection: 'asc',
        },
        {
            title: 'Fecha de creación',
            field: 'createdAt',
            sortDirection: 'asc',
        },
    ])
    const [data, setData] = useState([])
    const { jobsList = [{ engine: '', inputPorts: [], outputPort: [] }], loading } = useJobsDetails(
        { workflowId }
    )
    const { jobsRunList = [] } = useJobsRunList(jobsList[0].engine, { workflowId })

    const history = useHistory()

    useEffect(() => {
        dispatch(setCurrentWorkflow(jobsList[0]))
        if (jobsRunList.length) {
            const newData = jobsRunList.map((jobRun) => ({
                ...jobRun,
                id: jobRun.workflowId,
                createdAt: new Date(jobRun.createdAt).toLocaleString(),
                link: `${workflowId}/runs/${jobRun._id}`,
            }))

            setData(newData)
        }
    }, [jobsRunList, jobsList])

    return (
        <React.Fragment>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Paper style={{ width: '100%', height: '100%' }}>
                    <Typography style={{ padding: '15px 20px' }} variant="h2">
                        {jobsList[0].name}
                    </Typography>
                    <Divider />
                    <TableWithSearch
                        height="calc(100vh - 198px)"
                        columns={columns}
                        data={data}
                        fullScreen={false}
                        loading={loading}
                        onRowClick={(row) => {
                            history.push(row)
                        }}
                        rowsAsLinks
                        onDirectionChange={(column, direction) => {
                            const newColumns = columns.map((col) => {
                                if (col.field === column) {
                                    return {
                                        ...col,
                                        sortDirection: direction,
                                    }
                                }
                                return col
                            })
                            setColumns(newColumns)
                        }}
                    />
                </Paper>
            </Container>
            <RunJob />
        </React.Fragment>
    )
}

export default JobsDetails
