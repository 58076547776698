import { combineReducers } from 'redux'

import viewer from './containers/viewer/reducers/index'
import image_viewer from './containers/viewer/reducers/image-viewer'
import settings from './containers/viewer/reducers/settings'

import predictSagas from './containers/viewer/sagas/predict'

export default () => {
    return {
        id: 'falcon',
        reducerMap: {
            datasets: combineReducers({
                viewer,
                image_viewer,
                settings
            }),
        },
        sagas: [
            predictSagas
        ],
    }
}
