import React, { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { GET_HMI_BY_ID, UPDATE_HMI_BY_ID } from '../../../../queries/hmi'

const Permissions = () => {
    const { hmi } = useParams()

    const [open, setOpen] = useState(false)

    const { data: { getHMIById: { hmi: { permissions = {}, users = [] } = {} } = {} } = {} } =
        useQuery(GET_HMI_BY_ID, {
            variables: { params: { _id: hmi } },
        })

    const [tempPermissions, setTempPermissions] = useState({
        hmi: [],
        parameters: [],
        datasets: [],
        settings: [],
    })

    const [usersAllds, setUsersAllIds] = useState([])
    const [usersById, setUsersById] = useState({})

    const localPermissions = useMemo(
        () =>
            permissions || {
                hmi: [],
                parameters: [],
                datasets: [],
                settings: [],
            },
        [permissions]
    )

    useEffect(() => {
        setTempPermissions({
            hmi: localPermissions.hmi || [],
            parameters: localPermissions.parameters || [],
            datasets: localPermissions.datasets || [],
            settings: localPermissions.settings || [],
        })
    }, [
        localPermissions.hmi,
        localPermissions.parameters,
        localPermissions.datasets,
        localPermissions.settings,
    ])

    const usersPlusGuest = useMemo(() => {
        const localUsers = users || []

        return [
            {
                _id: 'guest',
                name: 'Invitado',
            },
            ...localUsers.map((u) => ({ _id: u._id, name: u.name, password: u.password })),
        ]
    }, [users])

    useEffect(() => {
        setUsersById(usersPlusGuest.reduce((acc, item) => ({ ...acc, [item._id]: item }), {}))
        setUsersAllIds(usersPlusGuest.map((u) => u._id))
    }, [usersPlusGuest])

    const [updateHMIById] = useMutation(UPDATE_HMI_BY_ID)

    const onItemClicked = () => setOpen(true)

    const onCancel = () => {
        setOpen(false)
    }

    const onAccept = async () => {
        await updateHMIById({
            variables: {
                params: {
                    _id: hmi,
                    permissions: {
                        hmi: tempPermissions.hmi,
                        parameters: tempPermissions.parameters,
                        datasets: tempPermissions.datasets,
                        settings: tempPermissions.settings,
                    },
                },
            },
        })
        setOpen(false)
    }

    const onPermissionsChanged = (perm) => (e, newValue) => {
        setTempPermissions({
            ...tempPermissions,
            [perm]: newValue.map((n) => n._id || n),
        })
    }

    return (
        <React.Fragment>
            <ListItem button onClick={onItemClicked}>
                <ListItemText
                    primary="Permisos"
                    secondary="Asigne los usuarios con acceso a cada sección"
                />
            </ListItem>
            <Dialog open={open}>
                <DialogTitle>Permisos</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>
                            <Autocomplete
                                multiple
                                options={usersAllds}
                                getOptionLabel={(option) => usersById[option].name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="HMI" />}
                                value={tempPermissions.hmi}
                                onChange={onPermissionsChanged('hmi')}
                            />
                        </ListItem>
                        <ListItem>
                            <Autocomplete
                                multiple
                                options={usersAllds}
                                getOptionLabel={(option) => usersById[option].name}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Parámetros" />
                                )}
                                value={tempPermissions.parameters}
                                onChange={onPermissionsChanged('parameters')}
                            />
                        </ListItem>
                        <ListItem>
                            <Autocomplete
                                multiple
                                options={usersAllds}
                                getOptionLabel={(option) => usersById[option].name}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Datasets" />}
                                onChange={onPermissionsChanged('datasets')}
                                value={tempPermissions.datasets}
                            />
                        </ListItem>
                        <ListItem>
                            <Autocomplete
                                multiple
                                options={usersAllds}
                                getOptionLabel={(option) => usersById[option].name}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Configuración" />
                                )}
                                value={tempPermissions.settings}
                                onChange={onPermissionsChanged('settings')}
                            />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Permissions
