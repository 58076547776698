import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'

import DeleteIcon from '@mui/icons-material/Delete'

import { v4 } from 'uuid'
import { GET_HMI_BY_ID, UPDATE_HMI_BY_ID } from '../../../../queries/hmi'
import ButtonStatus from '../../../../../../../../../../../../../../utils/common-components/buttonStatus'

const DialogUser = ({ open, close }) => {
    const { hmi } = useParams()

    const [_, setLoading] = useState(false)
    const [temp, setTemp] = useState([])

    const { data: { getHMIById: { hmi: { users = [] } = {} } = {} } = {}, loading } = useQuery(
        GET_HMI_BY_ID,
        {
            variables: { params: { _id: hmi } },
            fetchPolicy: 'network-only',
        }
    )

    const [updateHMIById, { loading: acceptLoading, error: acceptError }] =
        useMutation(UPDATE_HMI_BY_ID)

    const onCancel = () => {
        close()
    }

    const onAccept = async () => {
        setLoading(true)
        console.log(temp)
        await updateHMIById({ variables: { params: { _id: hmi, users: temp } } })
        setLoading(false)
        close()
    }

    const onAddClicked = () => {
        setTemp([...temp, { _id: v4(), name: '', password: '' }])
    }

    const onUserChanged = (i) => (e) => {
        setTemp([
            ...temp.slice(0, i),
            {
                ...temp[i],
                name: e.target.value,
            },
            ...temp.slice(i + 1),
        ])
    }

    const onPasswordChanged = (i) => (e) => {
        setTemp([
            ...temp.slice(0, i),
            {
                ...temp[i],
                password: e.target.value,
            },
            ...temp.slice(i + 1),
        ])
    }

    const onDeleteClicked = (i) => () => {
        setTemp([...temp.slice(0, i), ...temp.slice(i + 1)])
    }

    useEffect(() => {
        setTemp(
            users.reduce((prev, next) => {
                return [...prev, { name: next.name, password: next.password, _id: next._id }]
            }, [])
        )
    }, [users])

    return (
        <Dialog open={open && !loading} fullWidth maxWidth="sm">
            <DialogTitle>Usuarios</DialogTitle>
            <Divider />
            <DialogContent style={{ maxHeight: '50vh' }}>
                <List>
                    {temp.map((t, i) => {
                        return (
                            <ListItem key={t._id}>
                                <TextField
                                    label="Nombre"
                                    style={{ marginRight: 5 }}
                                    fullWidth
                                    onChange={onUserChanged(i)}
                                    value={t.name}
                                />
                                <TextField
                                    label="Contraseña"
                                    fullWidth
                                    onChange={onPasswordChanged(i)}
                                    value={t.password}
                                />
                                <ListItemSecondaryAction onClick={onDeleteClicked(i)}>
                                    <IconButton size="large" edge="end">
                                        <DeleteIcon fontSize="large" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onAddClicked}>
                    Añadir
                </Button>
                <Button onClick={onCancel}>Cancelar</Button>
                <ButtonStatus
                    buttonChildren="Aceptar"
                    buttonProps={{
                        color: 'primary',
                        variant: 'contained',
                        onClick: onAccept,
                    }}
                    loading={acceptLoading}
                    error={acceptError}
                    animationTimeout={3000}
                />
            </DialogActions>
        </Dialog>
    )
}

const Users = () => {
    const [open, setOpen] = useState(false)

    const onItemClicked = () => setOpen(true)

    const close = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <ListItem button onClick={onItemClicked}>
                <ListItemText
                    primary="Usuarios"
                    secondary="Asigne los usuarios con acceso a cada sección"
                />
            </ListItem>
            {open && <DialogUser open={open} close={close} />}
        </React.Fragment>
    )
}

export default Users
