import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { useJobsIsActivated, useJobsActivate, useJobsDeactivate } from 'queries/jobs'
import ProgressSwitch from '../../../../../../../components/ProgressSwitch'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const Scene = () => {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [jobsActivated, setJobsActivated] = useState(false)

    const { isActivated: isJobsActivated, loading: loadingFromGQL } = useJobsIsActivated()

    const activateJobs = useJobsActivate()
    const deactivateJobs = useJobsDeactivate()

    const onChange = async (checked) => {
        setLoading(true)
        if (checked) {
            await activateJobs()
            setJobsActivated(true)
        } else {
            await deactivateJobs(false)
            setJobsActivated(false)
        }
        setLoading(false)
    }

    useEffect(() => {
        setLoading(loadingFromGQL)
        setJobsActivated(isJobsActivated)
    }, [isJobsActivated, loadingFromGQL])

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2">Servicios</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <List>
                            <ListItem
                                key="jobs"
                                secondaryAction={
                                    <ProgressSwitch
                                        value={jobsActivated}
                                        setValue={onChange}
                                        loading={loading}
                                    />
                                }
                            >
                                <ListItemText primary="jobs" secondary="" />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Scene
