import React, { useEffect, useState } from 'react'

import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import ChipInput from 'material-ui-chip-input'

import * as EmailValidator from 'email-validator'

import { UPDATE_INCIDENT_GROUP, GET_INCIDENT_GROUP_BY_ID } from '../../../../queries/groups'

import { useIsAllowed } from 'queries/permissions'

const Scene = (props) => {
    const { group } = useParams()
    const { enqueueSnackbar } = useSnackbar()

    const [open, setOpen] = useState(false)
    const [emails, setEmails] = useState(props.emails)
    const [loading, setLoading] = useState(false)

    const { isAllowed: isEditAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'updateIncidentGroup',
    })
    const [updateIncidentGroup] = useMutation(UPDATE_INCIDENT_GROUP)

    const onEmailsClicked = () => {
        setOpen(true)
    }

    const onChipsChange = (chips) => {
        setEmails(chips)
    }

    const onCancel = () => {
        setEmails(props.emails)
        setOpen(false)
    }

    const onSave = async () => {
        setLoading(true)

        try {
            if (emails.some((email) => !EmailValidator.validate(email))) {
                throw new Error('Emails inválidos')
            }

            await updateIncidentGroup({
                variables: {
                    params: {
                        id: group,
                        emails,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_INCIDENT_GROUP_BY_ID,
                        variables: {
                            id: group,
                        },
                    },
                ],
            })

            setOpen(false)

            enqueueSnackbar('Emails guardados', { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setEmails(props.emails)
    }, [props.emails])

    return (
        <React.Fragment>
            <ListItem button onClick={onEmailsClicked} disabled={!isEditAllowed}>
                <ListItemText
                    primary="Emails"
                    secondary="Edita los emails que reciben notificaciones"
                />
            </ListItem>
            <Dialog open={open} maxWidth="sm" fullWidth>
                <DialogTitle>Editar emails</DialogTitle>
                <DialogContent>
                    <ChipInput
                        defaultValue={emails}
                        variant="outlined"
                        fullWidth
                        onChange={onChipsChange}
                        disabled={loading}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} disabled={loading}>
                        Cancelar
                    </Button>
                    <Button onClick={onSave} disabled={loading}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Scene
