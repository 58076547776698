import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CircularProgress from '@mui/material/CircularProgress'
import { InputLabel } from '@mui/material'

import Loading from 'components/Loading'

import { GET_VARIABLES_BY_GROUP, REMOVE_VARIABLE_BY_ID } from '../../queries/variables'

import ParamsType from '../param-type/index'

import { useIsAllowed } from 'queries/permissions'

// Default values of state
const DEFAULT_PARAM = { type: '', local: '' }
const DEFAULT_VALUE = ''

/**
 * This component renders the list of
 * parameters. And launch the creation of
 * new parameters and its updating. To do that
 * take the parameters type defined in ParamsType
 */
const ParamsList = () => {
    // Hooks
    const { group } = useParams()
    const {
        data: { getVariablesByGroup: { variables: variablesGQL = [] } = {} } = {},
        loading: loadingGQL,
    } = useQuery(GET_VARIABLES_BY_GROUP, { variables: { params: { group } } })
    const { isAllowed: isRemoveVariable } = useIsAllowed({
        action: 'removeVariable',
        group: 'groups',
    })

    const [removeVariableGQL] = useMutation(REMOVE_VARIABLE_BY_ID)

    // Initialization of local state
    const [variable, setVariable] = useState(DEFAULT_PARAM)
    const [value, setValue] = useState(DEFAULT_VALUE)
    // // State array to check whether element is in state deleting
    const [deletingStates, setDeletingStates] = useState(
        variablesGQL.reduce((prev, next) => {
            return { ...prev, [next._id]: false }
        }, [])
    )

    // Click on delete change
    const onDeleteClicked = (variableId) => async () => {
        setDeletingStates({ ...deletingStates, [variableId]: true })
        await removeVariableGQL({
            variables: {
                params: { _id: variableId },
            },
            refetchQueries: [
                {
                    query: GET_VARIABLES_BY_GROUP,
                    variables: { params: { group } },
                },
            ],
            awaitRefetchQueries: true,
        })
        setDeletingStates({ ...deletingStates, [variableId]: false })
    }

    return (
        <Loading loading={loadingGQL}>
            {variablesGQL.length === 0 ? (
                <div style={{ margin: '20px', textAlign: 'center' }}>
                    Cree una variable para continuar &#8600;
                </div>
            ) : (
                <Paper>
                    <List>
                        {variablesGQL.map((variableI) => (
                            <ListItem key={variableI._id} button>
                                <ListItemText
                                    primary={variableI.name}
                                    secondary={`Type: ${variableI.type} | Default: ${variableI.default}`}
                                />
                                {isRemoveVariable && (
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={onDeleteClicked(variableI._id)}
                                        >
                                            {deletingStates[variableI._id] ? (
                                                <CircularProgress size={20} />
                                            ) : (
                                                <DeleteIcon />
                                            )}
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </Loading>
    )
}
export default ParamsList
