import React from "react"

import TextField from "@material-ui/core/TextField"
import ListItem from "@material-ui/core/ListItem"

const Scene = (props) => {

    const { buffsize } = JSON.parse(props.options || '{}')

    return (
        <ListItem>
            <TextField
                variant="outlined"
                label="Tamaño del buffer"
                value={buffsize}
                onChange={
                    props.onPropertyChangedHandlerBuilder(
                        'options',
                        (e) => {
                            return JSON.stringify({
                                buffsize: e.target.value
                            })
                        }
                    )
                }
                fullWidth
            />
        </ListItem>
    )
}

export default Scene