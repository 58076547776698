import React, { useState } from "react"

import { useQuery } from "@apollo/react-hooks"
import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { GET_INCIDENT_GROUPS } from "../../../../../../incidents/queries/groups"

import Options from "./containers/options/scene"

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        fontSize: '1.5em',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
}))

const Scene = (props) => {
    console.log(props)

    const classes = useStyles()

    const [ selectedIncidentGroup, setSelectedIncidentGroup ] = useState(null)
    const [ selectedIncidentGroupOptions, setSelectedIncidentGroupOptions ] = useState(null)
    
    const value = props.value || []
    console.log("value", value)
    const {
        loading,
        error,
        data: { getIncidentGroups: { groups = [] } = {} } = {}
    } = useQuery(GET_INCIDENT_GROUPS, { variables: { params: {} } })

    const onChange = (event, newValue) => {
        setSelectedIncidentGroup(newValue.id)
    };

    const onOptionsChange = (value) => {
        setSelectedIncidentGroupOptions(value)
    }

    const onAddIncidentGroup = () => {
        props.onChange([
            ...value,
            {
                group: selectedIncidentGroup,
                translation: selectedIncidentGroupOptions
            }
        ])
    }

    const onChipDetete = (index) => {
        props.onChange(value.filter((_, i) => i !== index))
    }

    return (
        <React.Fragment>
            <Grid className={classes.root} item xs={12}>
                {
                    value.map((group, index) => {
                        const { name } = groups.find(g => g.id === group.group)
                        console.log("group", group)
                        return (
                            <Chip
                                key={index}
                                label={name}
                                className={classes.chip}
                                onDelete={() => onChipDetete(index)}
                            />
                        )
                    })
                }
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    id="fixed-tags-demo"
                    onChange={onChange}
                    options={groups}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value}
                    fullWidth
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                            console.log(option)
                            const { name } = groups.find(g => g.id === option)
                            return (
                                <Chip
                                    label={name} {...getTagProps({ index })}
                                />
                            )
                        })
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="Registros de incidencias" variant="outlined" placeholder="Registros" />
                    )}
                />
            </Grid>
            <Options 
                group={selectedIncidentGroup}
                value={selectedIncidentGroupOptions}
                onChange={onOptionsChange}
                properties={props.properties}
            />
            <Grid item xs={12} style={{ paddingTop: 20 }}>
                <Button onClick={onAddIncidentGroup} fullWidth>Añadir</Button>
            </Grid>
        </React.Fragment>
    )
}

export default Scene