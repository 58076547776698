export const iron = [
    {r: 0 ,g:  0 ,b:  0 },
    {r: 0 ,g:  0 ,b:  36 },
    {r: 0 ,g:  0 ,b:  51 },
    {r: 0 ,g:  0 ,b:  66 },
    {r: 0 ,g:  0 ,b:  81 },
    {r: 2 ,g:  0 ,b:  90 },
    {r: 4 ,g:  0 ,b:  99 },
    {r: 7 ,g:  0 ,b:  106 },
    {r: 11 ,g:  0 ,b:  115 },
    {r: 14 ,g:  0 ,b:  119 },
    {r: 20 ,g:  0 ,b:  123 },
    {r: 27 ,g:  0 ,b:  128 },
    {r: 33 ,g:  0 ,b:  133 },
    {r: 41 ,g:  0 ,b:  137 },
    {r: 48 ,g:  0 ,b:  140 },
    {r: 55 ,g:  0 ,b:  143 },
    {r: 61 ,g:  0 ,b:  146 },
    {r: 66 ,g:  0 ,b:  149 },
    {r: 72 ,g:  0 ,b:  150 },
    {r: 78 ,g:  0 ,b:  151 },
    {r: 84 ,g:  0 ,b:  152 },
    {r: 91 ,g:  0 ,b:  153 },
    {r: 97 ,g:  0 ,b:  155 },
    {r: 104 ,g:  0 ,b:  155 },
    {r: 110 ,g:  0 ,b:  156 },
    {r: 115 ,g:  0 ,b:  157 },
    {r: 122 ,g:  0 ,b:  157 },
    {r: 128 ,g:  0 ,b:  157 },
    {r: 134 ,g:  0 ,b:  157 },
    {r: 139 ,g:  0 ,b:  157 },
    {r: 146 ,g:  0 ,b:  156 },
    {r: 152 ,g:  0 ,b:  155 },
    {r: 157 ,g:  0 ,b:  155 },
    {r: 162 ,g:  0 ,b:  155 },
    {r: 167 ,g:  0 ,b:  154 },
    {r: 171 ,g:  0 ,b:  153 },
    {r: 175 ,g:  1 ,b:  152 },
    {r: 178 ,g:  1 ,b:  151 },
    {r: 182 ,g:  2 ,b:  149 },
    {r: 185 ,g:  4 ,b:  149 },
    {r: 188 ,g:  5 ,b:  147 },
    {r: 191 ,g:  6 ,b:  146 },
    {r: 193 ,g:  8 ,b:  144 },
    {r: 195 ,g:  11 ,b:  142 },
    {r: 198 ,g:  13 ,b:  139 },
    {r: 201 ,g:  17 ,b:  135 },
    {r: 203 ,g:  20 ,b:  132 },
    {r: 206 ,g:  23 ,b:  127 },
    {r: 208 ,g:  26 ,b:  121 },
    {r: 210 ,g:  29 ,b:  116 },
    {r: 212 ,g:  33 ,b:  111 },
    {r: 214 ,g:  37 ,b:  103 },
    {r: 217 ,g:  41 ,b:  97 },
    {r: 219 ,g:  46 ,b:  89 },
    {r: 221 ,g:  49 ,b:  78 },
    {r: 223 ,g:  53 ,b:  66 },
    {r: 224 ,g:  56 ,b:  54 },
    {r: 226 ,g:  60 ,b:  42 },
    {r: 228 ,g:  64 ,b:  30 },
    {r: 229 ,g:  68 ,b:  25 },
    {r: 231 ,g:  72 ,b:  20 },
    {r: 232 ,g:  76 ,b:  16 },
    {r: 234 ,g:  78 ,b:  12 },
    {r: 235 ,g:  82 ,b:  10 },
    {r: 236 ,g:  86 ,b:  8 },
    {r: 237 ,g:  90 ,b:  7 },
    {r: 238 ,g:  93 ,b:  5 },
    {r: 239 ,g:  96 ,b:  4 },
    {r: 240 ,g:  100 ,b:  3 },
    {r: 241 ,g:  103 ,b:  3 },
    {r: 241 ,g:  106 ,b:  2 },
    {r: 242 ,g:  109 ,b:  1 },
    {r: 243 ,g:  113 ,b:  1 },
    {r: 244 ,g:  116 ,b:  0 },
    {r: 244 ,g:  120 ,b:  0 },
    {r: 245 ,g:  125 ,b:  0 },
    {r: 246 ,g:  129 ,b:  0 },
    {r: 247 ,g:  133 ,b:  0 },
    {r: 248 ,g:  136 ,b:  0 },
    {r: 248 ,g:  139 ,b:  0 },
    {r: 249 ,g:  142 ,b:  0 },
    {r: 249 ,g:  145 ,b:  0 },
    {r: 250 ,g:  149 ,b:  0 },
    {r: 251 ,g:  154 ,b:  0 },
    {r: 252 ,g:  159 ,b:  0 },
    {r: 253 ,g:  163 ,b:  0 },
    {r: 253 ,g:  168 ,b:  0 },
    {r: 253 ,g:  172 ,b:  0 },
    {r: 254 ,g:  176 ,b:  0 },
    {r: 254 ,g:  179 ,b:  0 },
    {r: 254 ,g:  184 ,b:  0 },
    {r: 254 ,g:  187 ,b:  0 },
    {r: 254 ,g:  191 ,b:  0 },
    {r: 254 ,g:  195 ,b:  0 },
    {r: 254 ,g:  199 ,b:  0 },
    {r: 254 ,g:  202 ,b:  1 },
    {r: 254 ,g:  205 ,b:  2 },
    {r: 254 ,g:  208 ,b:  5 },
    {r: 254 ,g:  212 ,b:  9 },
    {r: 254 ,g:  216 ,b:  12 },
    {r: 255 ,g:  219 ,b:  15 },
    {r: 255 ,g:  221 ,b:  23 },
    {r: 255 ,g:  224 ,b:  32 },
    {r: 255 ,g:  227 ,b:  39 },
    {r: 255 ,g:  229 ,b:  50 },
    {r: 255 ,g:  232 ,b:  63 },
    {r: 255 ,g:  235 ,b:  75 },
    {r: 255 ,g:  238 ,b:  88 },
    {r: 255 ,g:  239 ,b:  102 },
    {r: 255 ,g:  241 ,b:  116 },
    {r: 255 ,g:  242 ,b:  134 },
    {r: 255 ,g:  244 ,b:  149 },
    {r: 255 ,g:  245 ,b:  164 },
    {r: 255 ,g:  247 ,b:  179 },
    {r: 255 ,g:  248 ,b:  192 },
    {r: 255 ,g:  249 ,b:  203 },
    {r: 255 ,g:  251 ,b:  216 },
    {r: 255 ,g:  253 ,b:  228 },
    {r: 255 ,g:  254 ,b:  239 }
    ];