import React, { useState } from 'react'

import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'

import { GET_MESSAGES_BY_OWNER, CREATE_MESSAGE } from '../../../../queries/messages'

import { useIsAllowed } from 'queries/permissions'

const Scene = (props) => {
    const { incident } = useParams()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const { isAllowed: isCreateMessageAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'createMessage',
    })
    const { isAllowed: isGetMessagesAllowed } = useIsAllowed({
        group: 'incidents',
        action: 'getMessagesByOwner',
    })

    const { data: { getMessagesByOwner: { messages = [] } = {} } = {} } = useQuery(
        GET_MESSAGES_BY_OWNER,
        {
            variables: {
                params: {
                    owner: incident,
                },
            },
            skip: !isGetMessagesAllowed,
        }
    )

    const [createMessage] = useMutation(CREATE_MESSAGE)

    const onMessageChange = (event) => {
        setMsg(event.target.value)
    }

    const onSend = async () => {
        setLoading(true)

        try {
            await createMessage({
                variables: {
                    params: {
                        owner: incident,
                        text: msg,
                    },
                },
                refetchQueries: [
                    {
                        query: GET_MESSAGES_BY_OWNER,
                        variables: {
                            params: {
                                owner: incident,
                            },
                        },
                    },
                ],
            })

            setMsg('')
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{ height: '100%', position: 'relative', overflow: 'hidden' }}>
            {isGetMessagesAllowed && (
                <List
                    style={{
                        height: isCreateMessageAllowed ? 'calc(100% - 200px)' : '100%',
                        overflow: 'auto',
                    }}
                >
                    {messages.map((message, index) => {
                        const { createdAt, text } = message

                        const date = new Date(createdAt)

                        let hours = date.getHours()
                        if (hours < 10) hours = '0' + hours

                        let minutes = date.getMinutes()
                        if (minutes < 10) minutes = '0' + minutes

                        let day = date.getDate()
                        if (day < 0) day = '0' + day

                        let month = date.getMonth() + 1
                        if (month < 0) month = '0' + month

                        let year = date.getFullYear()

                        return (
                            <React.Fragment key={index}>
                                <ListItem style={{ alignItems: 'flex-start' }}>
                                    <ListItemAvatar style={{ paddingTop: 10 }}>
                                        <Avatar src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={text}
                                        secondary={`${hours}:${minutes} - ${day}/${month}/${year}`}
                                    />
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        )
                    })}
                </List>
            )}
            {isCreateMessageAllowed && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        boxShadow: '0 0 20px -5px rgba(0,0,0,0.1)',
                        padding: 20,
                    }}
                >
                    <TextField
                        id="standard-multiline-static"
                        label="Mensaje"
                        multiline
                        rows={6}
                        fullWidth
                        variant="outlined"
                        value={msg}
                        onChange={onMessageChange}
                        disabled={loading}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        style={{ float: 'right', marginTop: 10 }}
                        onClick={onSend}
                    >
                        Enviar
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Scene
