import gql from "graphql-tag"

export const GET_CLASSIFICATION_ENTRIES = gql`
    query GetClassificationEntries($params: GetClassificationEntriesRequest) {
        getClassificationEntries(params: $params) {
            classificationEntries {
                _id
                url
            }
        }
    }
`

export const GET_CLASSIFICATION_ENTRY_BY_ID = gql`
    query GetClassificationEntryById($params: GetClassificationEntryByIdRequest) {
        getClassificationEntryById(params: $params) {
            classificationEntry {
                _id
                url
                label
            }
        }
    }
`

export const UPDATE_CLASSIFICATION_ENTRY_BY_ID = gql`
    mutation UpdateClassificationEntryById($params: UpdateClassificationEntryByIdRequest) {
        updateClassificationEntryById(params: $params) {
            status
        }
    }

`

export const CREATE_OBJECT_DETECTION_ENTRIES_FROM_DATASET = gql`
    mutation createClassificationEntriesFromDataset($params: CreateClassificationEntriesFromDatasetRequest!) {
        createClassificationFromDataset(params: $params) {
            status
        }
    }

`