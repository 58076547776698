import React, { useState } from 'react';

import Grid from "@material-ui/core/Grid"

import DeckGL from '@deck.gl/react';
import {COORDINATE_SYSTEM, OrbitView, LinearInterpolator} from '@deck.gl/core';
import {PointCloudLayer} from '@deck.gl/layers';

import {LASWorkerLoader} from '@loaders.gl/las';
// import {PLYWorkerLoader} from '@loaders.gl/ply';
import {load, registerLoaders} from '@loaders.gl/core';

registerLoaders(LASWorkerLoader);

const LAZ_SAMPLE = 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/point-cloud-laz/indoor.0.1.laz';

const INITIAL_VIEW_STATE = {
    target: [0, 0, 0],
    rotationX: 0,
    rotationOrbit: 0,
    orbitAxis: 'Y',
    fov: 50,
    minZoom: 0,
    maxZoom: 10,
    zoom: 1
};

const transitionInterpolator = new LinearInterpolator(['rotationOrbit']);

function PointCloud(props) {

    const [ viewState, setViewState ] = useState(INITIAL_VIEW_STATE)

    const onViewStateChange = ({ viewState }) => {
        setViewState(viewState);
    }
    
    const rotateCamera = () => {
        setViewState({
            ...viewState,
            rotationOrbit: viewState.rotationOrbit + 120,
            transitionDuration: 2400,
            transitionInterpolator,
            onTransitionEnd: rotateCamera
        });
    }

    const onLoad = ({ header, loaderData, progress }) => {
        // metadata from LAZ file header
        const {mins, maxs} = loaderData.header;
    
        if (mins && maxs) {
            // File contains bounding box info
            setViewState(
                {
                    ...viewState,
                    target: [(mins[0] + maxs[0]) / 2, (mins[1] + maxs[1]) / 2, (mins[2] + maxs[2]) / 2],
                    /* global window */
                    zoom: Math.log2(window.innerWidth / (maxs[0] - mins[0])) - 1
                }
            );
        }
    
        if (props.onLoad) {
            props.onLoad({ count: header.vertexCount, progress: 1 });
        }
    }
    


    const layers = [
        new PointCloudLayer({
            id: 'laz-point-cloud-layer',
            data: LAZ_SAMPLE,
            onDataLoad: onLoad,
            coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
            getNormal: [0, 1, 0],
            getColor: [255, 255, 255],
            opacity: 0.5,
            pointSize: 0.5
        })
    ];

    return (
        <Grid container style={{ height: "100%" }}>
            <Grid item xs={10}>
                <DeckGL
                    views={new OrbitView()}
                    viewState={viewState}
                    controller={true}
                    onViewStateChange={onViewStateChange}
                    layers={layers}
                    parameters={{
                        clearColor: [0.93, 0.86, 0.81, 1]
                    }}
                />
            </Grid>
            <Grid item xs={2} style={{ height: "100%", background: "white", zIndex: 2 }}>
                
            </Grid>
        </Grid>
    )
}

export default PointCloud