import React, { useRef, useState, useContext, Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useDispatch, useSelector, ReactReduxContext, shallowEqual } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import Collapse from '@mui/material/Collapse'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AddIcon from '@material-ui/icons/Add'
import ErrorIcon from '@material-ui/icons/Error'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, GizmoHelper, GizmoViewport, Edges } from '@react-three/drei'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ThemeProvider } from '@mui/material/styles'
import * as THREE from 'three'

import {
    addLayoutToCurrentStack,
    setActiveLayoutStack,
    setSelectedLayoutStack,
    setSelectedStack,
    updateStack,
    deleteLayoutFromCurrentStack,
    updateLayoutFromCurrentStack,
    deleteStack,
} from '../../reducers/stack'
import AddUnits from '../../../../../../../../../../../../../../../components/AddUnits'
import { GET_PARAMS_BY_GROUP, CREATE_PARAM, UPDATE_PARAM } from '../../../../params/queries/params'
import Pallet from '../../components/pallet'
import Box from '../../components/box'
import cardboard from '../../assets/box_2.jpg'

const LENGTH_UNITS = {
    mm: 1 / 1000,
    cm: 1 / 100,
    dm: 1 / 10,
    m: 1,
}

const INTER_LAYOUT_OFFSET_DISTANCE = 0.0005

const Cardboard = ({ meshProps, size, layoutIndex }) => {
    const cardboardTexture = new THREE.TextureLoader().load(cardboard)
    const meshRef = useRef()
    const { scene } = useThree()
    const addToUserData = () => {
        scene.userData = {
            ...scene.userData,
            cardboard: [...(scene.userData.cardboard || []), { layoutIndex, object: meshRef }],
        }
    }
    useEffect(() => {
        addToUserData()
        return () => {
            const index = scene.userData.cardboard.findIndex((element) => {
                return element.layoutIndex === layoutIndex
            })
            scene.userData.cardboard.splice(index, 1)
        }
    }, [])

    return (
        /* eslint-disable react/no-unknown-property */
        <mesh {...meshProps} ref={meshRef}>
            <boxBufferGeometry args={size} />
            <meshStandardMaterial map={cardboardTexture} />
            <Edges />
        </mesh>
    )
}

Cardboard.propTypes = {
    meshProps: PropTypes.object,
    size: PropTypes.array.isRequired,
    layoutIndex: PropTypes.array.isRequired,
}

Cardboard.defaultProps = {
    meshProps: {},
}

const Layout = ({ stackId, layoutIndex, groupProps, stackedHeight }) => {
    const layout = useSelector(
        (state) => state.group.palletizer.stacks.stacks[stackId].layouts[layoutIndex]
    )
    const lastLayoutId = useSelector(
        (state) =>
            state.group.palletizer.stacks.stacks[stackId].layouts[Math.max(layoutIndex - 1, 0)]
                .layoutId
    )
    const { store } = useContext(ReactReduxContext)
    const layoutsRef = store.getState().group.palletizer.layouts.layouts
    const boxesRef = store.getState().group.palletizer.boxes.boxes

    return (
        <group
            {...groupProps}
            position={[layout.position[0], layout.position[1], stackedHeight]}
            rotation={layout.rotation}
        >
            {layoutsRef[layout.layoutId].layout.map((box) => {
                return (
                    <Box
                        key={box.id}
                        box={{
                            boxId: box.boxId,
                            id: box.id,
                            size: boxesRef[box.boxId].measurements,
                            labelSide: boxesRef[box.boxId].labelSide,
                        }}
                        meshProps={{
                            position: [
                                box.position[0] - layoutsRef[lastLayoutId].size[0] / 2,
                                box.position[1] - layoutsRef[lastLayoutId].size[1] / 2,
                                boxesRef[box.boxId].measurements[2] / 2,
                            ],
                            rotation: box.rotation,
                        }}
                        layoutIndex={layoutIndex}
                    />
                )
            })}
            {layout.cardboard.exists && (
                <Cardboard
                    meshProps={{
                        position: [
                            0,
                            0,
                            layoutsRef[layout.layoutId].size[2] + layout.cardboard.thickness / 2,
                        ],
                    }}
                    size={[
                        layout.cardboard.size[0],
                        layout.cardboard.size[1],
                        layout.cardboard.thickness / 2,
                    ]}
                    layoutIndex={layoutIndex}
                />
            )}
        </group>
    )
}

Layout.propTypes = {
    stackId: PropTypes.string.isRequired,
    layoutIndex: PropTypes.number.isRequired,
    groupProps: PropTypes.object,
    stackedHeight: PropTypes.number.isRequired,
}

Layout.defaultProps = {
    groupProps: {},
}

const Stack = () => {
    // const dispatch = useDispatch()
    const selectedStack = useSelector((state) => state.group.palletizer.stacks.selectedStack)
    const firstLayout = useSelector(
        (state) =>
            state.group.palletizer.stacks.stacks[selectedStack].layouts[0] || { layoutId: '' }
    )
    const countLayout = useSelector(
        (state) =>
            state.group.palletizer.stacks.stacks[state.group.palletizer.stacks.selectedStack]
                .layouts.length
    )
    const { store } = useContext(ReactReduxContext)

    const layoutsRef = store.getState().group.palletizer.layouts.layouts

    let stackedHeight = INTER_LAYOUT_OFFSET_DISTANCE
    return (
        <Suspense fallback={null}>
            {layoutsRef[firstLayout.layoutId] && (
                <Pallet
                    groupProps={{
                        position: [
                            layoutsRef[firstLayout.layoutId].size[0] / 2,
                            layoutsRef[firstLayout.layoutId].size[1] / 2,
                            -0.1,
                        ],
                    }}
                    size={[
                        layoutsRef[firstLayout.layoutId].size[0],
                        layoutsRef[firstLayout.layoutId].size[1],
                        0.2,
                    ]}
                    help={false}
                />
            )}
            {[...Array(countLayout).keys()].map((layIndex) => {
                const reduxLayout =
                    store.getState().group.palletizer.stacks.stacks[selectedStack].layouts[layIndex]
                const layout = (
                    <Layout
                        stackId={selectedStack}
                        layoutIndex={layIndex}
                        stackedHeight={stackedHeight}
                        groupProps={{}}
                    />
                )
                stackedHeight +=
                    layoutsRef[reduxLayout.layoutId].size[2] +
                    reduxLayout.cardboard.exists * reduxLayout.cardboard.thickness +
                    INTER_LAYOUT_OFFSET_DISTANCE
                return layout
            })}
        </Suspense>
    )
}

const ThreeJsScene = () => {
    const controlsRef = useRef()

    const intensity = 0.5
    const main = [1, 2, 1]
    const fill = [-2, -0.5, -2]
    const radius = 4
    const castShadows = false

    /* eslint-disable react/no-unknown-property */
    return (
        <Canvas orthographic camera={{ fov: 50, up: [0, 0, 1], position: [-6, -6, 2], zoom: 250 }}>
            <ambientLight intensity={intensity / 3} />
            <spotLight
                penumbra={1}
                position={[main[0] * radius, main[1] * radius, main[2] * radius]}
                intensity={intensity * 2}
                castShadow={castShadows}
                shadow-bias={-0.0001}
                shadow-normalBias={0}
                shadow-mapSize={1024}
            />
            <pointLight
                position={[fill[0] * radius, fill[1] * radius, fill[2] * radius]}
                intensity={intensity}
            />
            <Stack />
            <OrbitControls makeDefault ref={controlsRef} />
            <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
                <GizmoViewport opacity={1} />
            </GizmoHelper>
            <axesHelper size={5} />
        </Canvas>
    )
}

const DraggableListItem = ({ stackId, layoutIndex }) => {
    const layout = useSelector(
        (state) => state.group.palletizer.stacks.stacks[stackId].layouts[layoutIndex]
    )
    const activeLayout = useSelector((state) => state.group.palletizer.stacks.activeLayout)

    const { store } = useContext(ReactReduxContext)

    const layoutRef = store.getState().group.palletizer.layouts.layouts[layout.layoutId]

    const [collapsePosition, setCollapsePosition] = useState(true)
    const [collapseRotation, setCollapseRotation] = useState(true)
    const [collapseCardboard, setCollapseCardboard] = useState(true)
    const [xUnit, setXUnit] = useState('m')
    const [yUnit, setYUnit] = useState('m')
    const [zUnit, setZUnit] = useState('m')
    const [thickUnit, setThickUnit] = useState('m')
    const [xCardboardUnit, setXCardboardUnit] = useState('m')
    const [yCardboardUnit, setYCardboardUnit] = useState('m')

    const ref = useRef(null)

    const dispatch = useDispatch()

    const onActiveClick = () => {
        if (activeLayout === layout.id) {
            dispatch(setActiveLayoutStack(null))
        } else {
            dispatch(setActiveLayoutStack(layout.id))
        }
    }

    const onDeleteClick = () => {
        dispatch(deleteLayoutFromCurrentStack(layoutIndex))
    }

    const changeX = (event) => {
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                position: [
                    parseFloat(event.target.value) * LENGTH_UNITS[xUnit],
                    layout.position[1],
                    layout.position[2],
                ],
                positionNeedsUpdate: true,
            })
        )
    }

    const changeY = (event) => {
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                position: [
                    layout.position[0],
                    parseFloat(event.target.value) * LENGTH_UNITS[yUnit],
                    layout.position[2],
                ],
                positionNeedsUpdate: true,
            })
        )
    }

    const changeZ = (event) => {
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                position: [
                    layout.position[0],
                    layout.position[1],
                    parseFloat(event.target.value) * LENGTH_UNITS[zUnit],
                ],
                positionNeedsUpdate: true,
            })
        )
    }

    const changeRoll = (event) => {
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                rotation: [
                    (parseFloat(event.target.value) * Math.PI) / 180,
                    layout.rotation[1],
                    layout.rotation[2],
                ],
                rotationNeedsUpdate: true,
            })
        )
    }

    const rotateRoll = () => {
        const roll = Math.ceil(((layout.rotation[0] + Math.PI / 2) * 180) / Math.PI) % 360
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                rotation: [(roll * Math.PI) / 180, layout.rotation[1], layout.rotation[2]],
                rotationNeedsUpdate: true,
            })
        )
    }

    const changePitch = (event) => {
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                rotation: [
                    layout.rotation[0],
                    (parseFloat(event.target.value) * Math.PI) / 180,
                    layout.rotation[2],
                ],
                rotationNeedsUpdate: true,
            })
        )
    }

    const rotatePitch = () => {
        const pitch = Math.ceil(((layout.rotation[1] + Math.PI / 2) * 180) / Math.PI) % 360
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                rotation: [layout.rotation[0], (pitch * Math.PI) / 180, layout.rotation[2]],
                rotationNeedsUpdate: true,
            })
        )
    }

    const changeYaw = (event) => {
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                rotation: [
                    layout.rotation[0],
                    layout.rotation[1],
                    (parseFloat(event.target.value) * Math.PI) / 180,
                ],
                rotationNeedsUpdate: true,
            })
        )
    }

    const rotateYaw = () => {
        const yaw = Math.ceil(((layout.rotation[2] + Math.PI / 2) * 180) / Math.PI) % 360
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                rotation: [layout.rotation[0], layout.rotation[1], (yaw * Math.PI) / 180],
                rotationNeedsUpdate: true,
            })
        )
    }
    const onCardboardChange = () => {
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                cardboard: {
                    ...layout.cardboard,
                    exists: !layout.cardboard.exists,
                },
            })
        )
    }

    const onCardboardThicknessChange = (event) => {
        const newThickness = parseFloat(event.target.value) * LENGTH_UNITS[thickUnit]
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                cardboard: {
                    ...layout.cardboard,
                    thickness: newThickness,
                },
            })
        )
    }

    const onXCardboardChange = (event) => {
        const newXSize = parseFloat(event.target.value) * LENGTH_UNITS[xCardboardUnit]
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                cardboard: {
                    ...layout.cardboard,
                    size: [newXSize, layout.cardboard.size[1]],
                },
            })
        )
    }

    const onYCardboardChange = (event) => {
        const newYSize = parseFloat(event.target.value) * LENGTH_UNITS[yCardboardUnit]
        dispatch(
            updateLayoutFromCurrentStack(layoutIndex, {
                cardboard: {
                    ...layout.cardboard,
                    size: [layout.cardboard.size[0], newYSize],
                },
            })
        )
    }

    return (
        <React.Fragment>
            <ListItem selected={activeLayout === layout.id} ref={ref}>
                <ListItemIcon style={{ cursor: 'move' }}>
                    <DragHandleIcon />
                </ListItemIcon>
                <ListItemText
                    primary={`Layout: ${layout.id}`}
                    secondary={`Tipo: ${layoutRef.name}`}
                    onClick={onActiveClick}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={onDeleteClick}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={activeLayout === layout.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem>
                        <ListItemText
                            sx={{ pl: 4 }}
                            primary={`Position: ${layout.position.map((rot) => rot.toFixed(3))}`}
                        />
                        <ListItemSecondaryAction
                            onClick={() => setCollapsePosition(!collapsePosition)}
                        >
                            {!collapsePosition ? <ExpandLess /> : <ExpandMore />}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={!collapsePosition} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="x: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit={xUnit}
                                    onChangeUnit={setXUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={(layout.position[0] / LENGTH_UNITS[xUnit]).toFixed(
                                            Math.round(
                                                Math.log(LENGTH_UNITS[xUnit] * 1000) / Math.log(10)
                                            )
                                        )}
                                        onChange={changeX}
                                    />
                                </AddUnits>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="y: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit={yUnit}
                                    onChangeUnit={setYUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={(layout.position[1] / LENGTH_UNITS[yUnit]).toFixed(
                                            Math.round(
                                                Math.log(LENGTH_UNITS[yUnit] * 1000) / Math.log(10)
                                            )
                                        )}
                                        onChange={changeY}
                                    />
                                </AddUnits>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="z: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit={zUnit}
                                    onChangeUnit={setZUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={(layout.position[2] / LENGTH_UNITS[zUnit]).toFixed(
                                            Math.round(
                                                Math.log(LENGTH_UNITS[zUnit] * 1000) / Math.log(10)
                                            )
                                        )}
                                        onChange={changeZ}
                                    />
                                </AddUnits>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem>
                        <ListItemText
                            sx={{ pl: 4 }}
                            primary={`Rotation: ${layout.rotation.map((rot) => rot.toFixed(2))}`}
                        />
                        <ListItemSecondaryAction
                            onClick={() => setCollapseRotation(!collapseRotation)}
                        >
                            {!collapseRotation ? <ExpandLess /> : <ExpandMore />}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={!collapseRotation} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="x (Roll): " />
                                <TextField
                                    type="number"
                                    value={((layout.rotation[0] * 180) / Math.PI).toFixed(0)}
                                    onChange={changeRoll}
                                />
                                <ListItemSecondaryAction onClick={rotateRoll}>
                                    <RotateLeftIcon />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="y (Pitch): " />
                                <TextField
                                    type="number"
                                    value={((layout.rotation[1] * 180) / Math.PI).toFixed(0)}
                                    onChange={changePitch}
                                />
                                <ListItemSecondaryAction onClick={rotatePitch}>
                                    <RotateLeftIcon />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="z (Yaw): " />
                                <TextField
                                    type="number"
                                    value={((layout.rotation[2] * 180) / Math.PI).toFixed(0)}
                                    onChange={changeYaw}
                                />
                                <ListItemSecondaryAction onClick={rotateYaw}>
                                    <RotateLeftIcon />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem>
                        <ListItemText sx={{ pl: 4 }} primary="Cartón" />
                        <ListItemSecondaryAction
                            onClick={() => setCollapseCardboard(!collapseCardboard)}
                        >
                            {!collapseCardboard ? <ExpandLess /> : <ExpandMore />}
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={!collapseCardboard} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={layout.cardboard.exists}
                                            onChange={onCardboardChange}
                                        />
                                    }
                                    label="Activado"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="Grosor: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit="mm"
                                    onChangeUnit={setThickUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={(
                                            layout.cardboard.thickness / LENGTH_UNITS[thickUnit]
                                        ).toFixed(
                                            Math.round(
                                                Math.log(LENGTH_UNITS[thickUnit] * 1000) /
                                                    Math.log(10)
                                            )
                                        )}
                                        onChange={onCardboardThicknessChange}
                                    />
                                </AddUnits>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="Tamaño X: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit="m"
                                    onChangeUnit={setXCardboardUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={
                                            layout.cardboard.size[0] /
                                            LENGTH_UNITS[xCardboardUnit].toFixed(
                                                Math.round(
                                                    Math.log(LENGTH_UNITS[xCardboardUnit] * 1000) /
                                                        Math.log(10)
                                                )
                                            )
                                        }
                                        onChange={onXCardboardChange}
                                    />
                                </AddUnits>
                            </ListItem>
                            <ListItem>
                                <ListItemText sx={{ pl: 4 }} primary="Tamaño Y: " />
                                <AddUnits
                                    conversionFactors={LENGTH_UNITS}
                                    defaultUnit="m"
                                    onChangeUnit={setYCardboardUnit}
                                >
                                    <TextField
                                        type="number"
                                        value={(
                                            layout.cardboard.size[1] / LENGTH_UNITS[yCardboardUnit]
                                        ).toFixed(
                                            Math.round(
                                                Math.log(LENGTH_UNITS[yCardboardUnit] * 1000) /
                                                    Math.log(10)
                                            )
                                        )}
                                        onChange={onYCardboardChange}
                                    />
                                </AddUnits>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Collapse>
        </React.Fragment>
    )
}

DraggableListItem.propTypes = {
    stackId: PropTypes.string.isRequired,
    layoutIndex: PropTypes.number.isRequired,
}

const StackDataEdit = ({ stackId }) => {
    const stackInfo = useSelector((state) => {
        const stack = state.group.palletizer.stacks.stacks[stackId]
        const layoutsId = state.group.palletizer.stacks.stacks[stackId].layouts.reduce(
            (prev, next) => {
                return [...prev, next.id]
            },
            []
        )
        return {
            id: stack.id,
            name: stack.name,
            size: stack.size,
            layoutsCount: stack.layouts.length,
            layoutsId,
        }
    }, shallowEqual)

    const selectedLayout = useSelector((state) => state.group.palletizer.stacks.selectedLayout)

    const { store } = useContext(ReactReduxContext)
    const { layouts } = store.getState().group.palletizer.layouts
    const dispatch = useDispatch()

    const layoutCount = useRef(0)

    const onStackNameChange = (e) => {
        dispatch(updateStack(stackId, { name: e.target.value }))
    }

    const onSelectedLayoutChange = (e) => {
        dispatch(setSelectedLayoutStack(e.target.value))
    }

    const onAddClick = () => {
        let newId = layoutCount.current
        while (stackInfo.layoutsId.includes(newId)) {
            newId += 1
        }
        dispatch(
            addLayoutToCurrentStack({
                id: newId,
                layoutId: selectedLayout,
                position: [
                    layouts[selectedLayout].size[0] / 2,
                    layouts[selectedLayout].size[1] / 2,
                    0,
                ],
                rotation: [0, 0, 0],
                cardboard: {
                    exists: false,
                    thickness: 0.005,
                    size: [layouts[selectedLayout].size[0], layouts[selectedLayout].size[1]],
                },
            })
        )
        dispatch(setActiveLayoutStack(newId))
        layoutCount.current = newId + 1
    }

    return (
        <React.Fragment style={{ height: '100%' }}>
            <List style={{ height: '185px', overflow: 'hidden' }}>
                <ListItem>
                    <TextField
                        label="Nombre"
                        value={stackInfo.name}
                        onChange={onStackNameChange}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Agregar</InputLabel>
                        <Select value={selectedLayout} onChange={onSelectedLayoutChange}>
                            {Object.values(layouts).map((b) => {
                                return (
                                    <MenuItem key={b.id} value={b.id}>
                                        {b.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </ListItem>
                <ListItem>
                    <Button onClick={onAddClick} fullWidth disabled={!selectedLayout}>
                        Añadir
                    </Button>
                </ListItem>
            </List>
            <Divider />
            <List style={{ maxHeight: 'calc(100% - 185px)', overflow: 'auto' }}>
                {[...Array(stackInfo.layoutsCount).keys()].map((layoutIndex) => (
                    <DraggableListItem
                        key={layoutIndex}
                        stackId={stackId}
                        layoutIndex={layoutIndex}
                    />
                ))}
            </List>
        </React.Fragment>
    )
}

StackDataEdit.propTypes = {
    stackId: PropTypes.string.isRequired,
}

const Edit = ({ stackId }) => {
    return (
        <Grid container item style={{ height: '100%' }} xs={9} justifyContent="stretch">
            <Grid item xs={9}>
                <ThreeJsScene />
            </Grid>
            <Grid
                item
                xs={3}
                style={{ height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
                <StackDataEdit stackId={stackId} />
            </Grid>
        </Grid>
    )
}

Edit.propTypes = {
    stackId: PropTypes.string.isRequired,
}

const StackSelectInfo = ({ stackId }) => {
    const stackInfo = useSelector((state) => {
        const stack = state.group.palletizer.stacks.stacks[stackId]
        return { id: stack.id, name: stack.name, unsaved: stack.unsaved }
    }, shallowEqual)

    const selectedStack = useSelector((state) => state.group.palletizer.stacks.selectedStack)

    const dispatch = useDispatch()

    const onDeleteClick = () => {
        dispatch(deleteStack(stackId))
    }

    return (
        <ListItem button key={stackId} selected={selectedStack === stackId}>
            <Tooltip
                title={
                    <Typography fontSize={14}>{`Elemento ${
                        stackInfo.unsaved ? 'sin guardar' : 'guardado'
                    }`}</Typography>
                }
                aria-label="add"
            >
                <ListItemIcon>
                    {stackInfo.unsaved && <ErrorIcon />}
                    {!stackInfo.unsaved && <CheckCircleIcon />}
                </ListItemIcon>
            </Tooltip>
            <ListItemText
                primary={stackInfo.name}
                onClick={() => {
                    if (stackId === selectedStack) {
                        dispatch(setSelectedStack(null))
                    } else {
                        dispatch(setSelectedStack(null))
                        dispatch(setSelectedStack(stackId))
                    }
                }}
            />
            <ListItemSecondaryAction>
                <Tooltip title={<Typography fontSize={14}>Eliminar</Typography>} aria-label="add">
                    <IconButton>
                        <DeleteIcon style={{ marginTop: 5 }} onClick={onDeleteClick} />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    )
}

StackSelectInfo.propTypes = {
    stackId: PropTypes.string.isRequired,
}

const Stacks = () => {
    const { group } = useParams()
    const theme = useTheme()

    const selectedStack = useSelector((state) => state.group.palletizer.stacks.selectedStack)
    const stacksLength = useSelector(
        (state) => Object.keys(state.group.palletizer.stacks.stacks).length
    )
    const globalSavedStack = useSelector((state) => state.group.palletizer.stacks.globalStack)

    const dispatch = useDispatch()

    const { store } = useContext(ReactReduxContext)

    const addNewStackClicked = () => {
        const newId = uuidv4()
        dispatch(
            updateStack(newId, {
                name: `UNTITLED STACK ${stacksLength}`,
                id: newId,
                unsaved: true,
                layouts: [],
            })
        )
    }

    const { data: { getParamsByGroup: { params: parametersGQL = [] } = {} } = {} } = useQuery(
        GET_PARAMS_BY_GROUP,
        { variables: { params: { group } }, pollInterval: 10000 }
    )
    const [updateParameterGQL] = useMutation(UPDATE_PARAM)
    const [createParam] = useMutation(CREATE_PARAM)

    const stacksParameterName = useSelector((state) => state.group.palletizer.settings.stacks)
    const selectedStackParameterName = useSelector(
        (state) => state.group.palletizer.settings.selected_stack
    )

    useEffect(() => {
        const save = async () => {
            // Check if parameter exists
            const stacksParameter = parametersGQL.filter(
                (param) => param.name === stacksParameterName
            )

            if (stacksParameter.length === 0) {
                const stacks = {
                    byId: { [selectedStack]: globalSavedStack },
                    allIds: [selectedStack],
                }
                await createParam({
                    variables: {
                        params: {
                            group,
                            name: stacksParameterName,
                            type: 'STRING',
                            value: JSON.stringify(stacks),
                        },
                    },
                })
            } else {
                const stacksFromParameter = JSON.parse(stacksParameter[0].local)
                let newStack = stacksFromParameter
                if (stacksFromParameter.allIds.includes(selectedStack)) {
                    newStack = {
                        ...stacksFromParameter,
                        byId: { ...stacksFromParameter.byId, [selectedStack]: globalSavedStack },
                    }
                } else {
                    newStack = {
                        allIds: [...stacksFromParameter.allIds, selectedStack],
                        byId: { ...stacksFromParameter.byId, [selectedStack]: globalSavedStack },
                    }
                }

                await updateParameterGQL({
                    variables: {
                        params: {
                            _id: stacksParameter[0]._id,
                            value: JSON.stringify(newStack),
                        },
                    },
                })
            }

            // Selected stack
            const selectedStackParameter = parametersGQL.filter(
                (param) => param.name === selectedStackParameterName
            )

            if (selectedStackParameter.length === 0) {
                await createParam({
                    variables: {
                        params: {
                            group,
                            name: selectedStackParameterName,
                            type: 'STRING',
                            value: JSON.stringify(selectedStack),
                        },
                    },
                })
            } else {
                await updateParameterGQL({
                    variables: {
                        params: {
                            _id: selectedStackParameter[0]._id,
                            value: JSON.stringify(selectedStack),
                        },
                    },
                })
            }
        }
        if (globalSavedStack) save()
    }, [globalSavedStack])

    const onSaveClick = async () => {
        // dispatch(saving(true))
        const { stacks } = store.getState().group.palletizer.stacks
        const { layouts } = store.getState().group.palletizer.layouts
        // Clean to cobot data format
        const cleaned = {
            allIds: Object.values(stacks).map((stack) => stack.id),
            byId: Object.values(stacks).reduce((prev, next) => {
                const { size } = layouts[(next.layouts[0] || { layoutId: null }).layoutId] || {
                    size: [0, 0, 0],
                }
                let tempHeight = INTER_LAYOUT_OFFSET_DISTANCE
                return {
                    ...prev,
                    [next.id]: {
                        id: next.id,
                        name: next.name,
                        size: [size[0], size[1]],
                        layouts: next.layouts.map((lay) => {
                            const newLayout = {
                                id: lay.layoutId,
                                height: tempHeight,
                                cardboard: lay.cardboard,
                                position: lay.position,
                                orientation: lay.rotation,
                            }
                            tempHeight += size[2] + INTER_LAYOUT_OFFSET_DISTANCE
                            return newLayout
                        }),
                    },
                }
            }, {}),
        }

        // Check if parameter exists
        const stacksParameter = parametersGQL.filter((param) => param.name === stacksParameterName)

        if (stacksParameter.length === 0) {
            await createParam({
                variables: {
                    params: {
                        group,
                        name: stacksParameterName,
                        type: 'STRING',
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        } else {
            await updateParameterGQL({
                variables: {
                    params: {
                        _id: stacksParameter[0]._id,
                        value: JSON.stringify(cleaned),
                    },
                },
            })
        }

        Object.values(stacks).forEach((stack) => {
            dispatch(updateStack(stack.id, { ...stack, unsaved: false }))
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container style={{ height: '100%' }}>
                <Grid
                    item
                    container
                    xs={3}
                    style={{ height: '100%' }}
                    direction="column"
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={11}
                        style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                            overflow: 'auto',
                        }}
                    >
                        <List>
                            <ListItem>
                                <ListItemText primary="AÑADIR STACK" />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={addNewStackClicked}>
                                        <AddIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider style={{ marginTop: 7 }} />
                            {Object.values(store.getState().group.palletizer.stacks.stacks).map(
                                (s) => (
                                    <StackSelectInfo key={s.id} stackId={s.id} />
                                )
                            )}
                        </List>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={onSaveClick}
                            color="primary"
                            style={{
                                width: '50px',
                                marginLeft: 'calc(45% - 25px)',
                                marginTop: '10px',
                            }}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
                {selectedStack ? <Edit stackId={selectedStack} /> : null}
            </Grid>
        </ThemeProvider>
    )
}

export default Stacks
