import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import SamplesToolbox from '../images-menu/samplesToolbox'
import AnnToolbox from '../annotations/annToolbox'
import PolygonsAndPointsToolbox from '../annotations/polygonsAndPointsToolbox'
import { TOOLBOX_ANNOTATION, TOOLBOX_SAMPLES, TOOLBOX_POLYGONS_AND_POINTS } from '../../constants'

const availableToolbox = {
    [TOOLBOX_SAMPLES]: SamplesToolbox,
    [TOOLBOX_ANNOTATION]: AnnToolbox,
    [TOOLBOX_POLYGONS_AND_POINTS]: PolygonsAndPointsToolbox,
}

/** Component to be as a board to insert the
 *  toolboxes.
 */
const Tools = () => {
    const [toolbox, setToolbox] = useState(null)
    const toolboxId = useSelector((state) => state.trainingSetSegmentation.images.selectedToolbox)

    const toolboxButtonsComponent = Object.keys(availableToolbox).includes(toolboxId)
        ? availableToolbox[toolboxId]
        : () => null

    return (
        <React.Fragment>
            <div
                style={{
                    position: 'absolute',
                }}
            >
                {toolbox}
            </div>
            {React.createElement(toolboxButtonsComponent, { setToolbox }, null)}
        </React.Fragment>
    )
}

export default Tools
