import gql from 'graphql-tag'

export const GET_RESOURCES = gql`
    query GetResourcesByGroup($params: GetResourcesByGroupRequest!) {
        getResourcesByGroup(params: $params) {
            status
            resources {
                _id
                name
                type
                config
            }
        }
    }
`

export const CREATE_RESOURCE = gql`
    mutation CreateResource($params: CreateResourceRequest!) {
        createResource(params: $params) {
            status
        }
    }
`

export const GET_RESOURCES_BY_TYPE = gql`
    query GetResourcesByType($params: GetResourcesByTypeRequest!) {
        getResourcesByType(params: $params) {
            status
            resources {
                _id
                name
            }
        }
    }
`

export const REMOVE_RESOURCE = gql`
    mutation RemoveResource($params: RemoveResourceRequest!) {
        removeResource(params: $params) {
            status
        }
    }
`
