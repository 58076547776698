import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'

import BlueprintsList from './containers/blueprints-list'
import NewBlueprintDialog from './containers/new-blueprint-dialog'

import { openNewBlueprintDialog } from './reducers'

import { useIsAllowed } from 'queries/permissions'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const Blueprints = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { isAllowed: isCreateBlueprintAllowed } = useIsAllowed({
        action: 'createBlueprint',
        group: 'groups',
    })

    const onFabClicked = () => dispatch(openNewBlueprintDialog())

    return (
        <div>
            <BlueprintsList />
            {isCreateBlueprintAllowed && (
                <React.Fragment>
                    <div className={classes.fab}>
                        <Tooltip title="Nuevo Blueprint" placement="left">
                            <Fab color="primary" onClick={onFabClicked}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                    <NewBlueprintDialog />
                </React.Fragment>
            )}
        </div>
    )
}

export default Blueprints
