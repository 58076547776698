import routes from "./routes"
import reducers from "./reducers"

export default () => {
    return {
        id: "onboarding",
        reducerMap: {
            onboarding: reducers
        }
    }
}
