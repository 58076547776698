import React from "react"

import { useApolloClient } from "@apollo/react-hooks"
import { makeStyles } from "@material-ui/core/styles"
import { Switch, Route } from "react-router-dom"

import Products from "./containers/products"
import Plans from "./containers/plans/scene"

import resolvers from "./resolvers"

function Store(props) {
    const classes = useStyles()
    const client = useApolloClient()

    client.addResolvers(resolvers)

    return (
        <div className={classes.root}>
            <Switch>
                <Route path={props.match.url + "/"} component={Products} exact />
                <Route path={props.match.url + "/:product"} component={Plans} exact />
            </Switch>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: { 
        padding: "50px 0", 
        minHeight: "100%",
        flexGrow: 1,
        background: "ghostwhite" 
    }
}))

export default Store