import React from "react"

import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { useHotkeys } from "react-hotkeys-hook"

import styled from 'styled-components'

import Paper from "@material-ui/core/Paper"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { setSelectedObject } from "../../../../reducers/index";

import { GET_TRAINING_SET_ENTRY_BY_ID, UPDATE_TRAINING_SET_ENTRY_BY_ID } from "../../../../../../queries/training-sets";

const colors = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#00FFFF",
    "#FF00FF",
    "#EE8572",
    "#35495E",
    "#347474",
    "#63B7AF"
]

const RadioContainer = styled(FormControlLabel)`
    .MuiFormControlLabel-label {
        margin-right: auto;
    }
`

const Objects = (props) => {

    const dispatch = useDispatch()

    const { selectedObject, selectedEntry } = useSelector(state => state.horus.trainingSets.polygon)

    const { data: { horus: { trainingSets: { getTrainingSetEntryById: { entry = {}} = {}} = {}} = {}} = {}} = useQuery(GET_TRAINING_SET_ENTRY_BY_ID, { 
        variables: { 
            params: { 
                _id: selectedEntry, 
                dataType: "rgb", 
                labelType: "polygon" 
            }
        }
    })
    const [ updateEntryById ] = useMutation(UPDATE_TRAINING_SET_ENTRY_BY_ID)

    const e = entry || {}
    const properties = e.properties || []
    const byId = properties.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

    const polygons = JSON.parse(byId["annotations"] || "[]")

    const onObjectClicked = i => () => {
        dispatch(setSelectedObject(i))
    }


    const onDeleteClicked = (_id, obj) => async () => {
        await updateEntryById({
            variables: {
                params: {
                    _id,
                    dataType: "rgb",
                    labelType: "polygon",
                    properties: [
                        {
                            name: "annotations",
                            value: JSON.stringify([
                                ...polygons.slice(0, obj),
                                ...polygons.slice(obj + 1)
                            ])
                        }
                    ]
                }
            },
            refetchQueries: [
                {
                    query: GET_TRAINING_SET_ENTRY_BY_ID,
                    variables: { params: { _id: selectedEntry, labelType: "polygon", dataType: "rgb" }}
                }
            ]
        })
    }

    useHotkeys("Delete", onDeleteClicked(selectedEntry, selectedObject), { }, [ selectedEntry, selectedObject, ...polygons ])


    return (
        <React.Fragment>
            <FormLabel
                style={{ padding: "20px 25px 0px 25px" }}    
            >
                Objects
            </FormLabel>
            <List component="nav"
                style={{
                   maxHeight: "35vh",
                   overflow: "auto"
                }}
            >
                <RadioGroup value={selectedObject} style={{ overflowX: "hidden" }}>
                    {polygons.map((polygon, i) => (
                        <ListItem style={{ padding: 0 }} key={polygon.class + i} onClick={onObjectClicked(i)}>
                            <RadioContainer
                                value={i}
                                control={<Radio style={{ color: colors[polygon.class], marginRight: 12 }} />}
                                label={"Object " + i}
                                onClick={onObjectClicked(i)}
                                labelPlacement="start" 
                                style={{ width: "100%" }}
                            />
                        </ListItem>
                    ))}
                </RadioGroup>
            </List>
        </React.Fragment>
    )
}

export default Objects