import React, { useRef, useState, useMemo, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import Switch from '@material-ui/core/Switch'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import {
    selectTemplateMatchingTemplate,
    addTemplateMatchingTemplate,
    removeTemplateMatchingTemplate,
    setTemplateMatchingEnabled,
    setTemplateMatchingImage,
    setTemplateMatchingCrop,
    setTemplateMatchingClasses,
} from '../../../../redux/settings'

import {} from '../../../../'

const TemplateEditor = () => {
    const dispatch = useDispatch()
    const [localCrop, setLocalCrop] = useState(null)

    const { selectedTemplate, templates } = useSelector(
        (state) => state.trainingSetObjectDetection.settings.templateMatching
    )

    const trainingSetClasses = useSelector(
        (state) => state.trainingSetObjectDetection.general.classProps
    ).map((obj) => obj.text)

    const onImageChange = (e) => {
        let file = e.target.files[0]

        let reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = function () {
            dispatch(setTemplateMatchingImage(selectedTemplate, reader.result))
        }

        reader.onerror = function () {
            console.log(reader.error)
        }
    }

    const onCropChange = (crop) => {
        setLocalCrop(crop)
    }

    const onClassesChangeBuilder = (index) => (e) => {
        if (e.target.checked) {
            dispatch(
                setTemplateMatchingClasses(selectedTemplate, [
                    ...templates[selectedTemplate].classes,
                    index,
                ])
            )
        } else {
            dispatch(
                setTemplateMatchingClasses(
                    selectedTemplate,
                    templates[selectedTemplate].classes.filter((value) => value !== index)
                )
            )
        }
    }

    const fileInputRef = useRef()

    const onAccept = () => {
        dispatch(
            setTemplateMatchingCrop(selectedTemplate, {
                ...localCrop,
                x: (localCrop.x * imgCropRef.current.naturalWidth) / imgCropRef.current.width,
                y: (localCrop.y * imgCropRef.current.naturalHeight) / imgCropRef.current.height,
                width:
                    (localCrop.width * imgCropRef.current.naturalWidth) / imgCropRef.current.width,
                height:
                    (localCrop.height * imgCropRef.current.naturalHeight) /
                    imgCropRef.current.height,
            })
        )
        dispatch(selectTemplateMatchingTemplate(null))
    }

    const onCancel = () => {
        dispatch(selectTemplateMatchingTemplate(null))
    }

    const image = useMemo(() => {
        if (selectedTemplate !== null) {
            return templates[selectedTemplate].image
        }
    }, [selectedTemplate, templates])

    const crop = useMemo(() => {
        if (selectedTemplate !== null) {
            return templates[selectedTemplate].crop
        }
    }, [selectedTemplate, templates])

    useEffect(() => {
        if (crop !== undefined) {
            setLocalCrop(crop)
        }
    }, [crop])

    const imgCropRef = useRef()

    return (
        <Dialog open={selectedTemplate !== null} fullScreen>
            <DialogContent style={{ padding: 0 }}>
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={10} style={{ height: '100%', textAlign: 'center' }}>
                        <ReactCrop crop={localCrop} onChange={onCropChange}>
                            <img
                                ref={imgCropRef}
                                src={image}
                                style={{ maxHeight: 'calc(100vh - 46px)' }}
                            />
                        </ReactCrop>
                    </Grid>
                    <Grid item xs={2} style={{ height: '100%', borderLeft: '1px solid #CCC' }}>
                        <List>
                            <ListSubheader>Plantilla</ListSubheader>
                            <ListItem>
                                <ListItemText primary="Plantilla 1" secondary="archivo.jpg" />
                                <ListItemSecondaryAction>
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        onChange={onImageChange}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => fileInputRef.current.click()}
                                    >
                                        Seleccionar
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                            {trainingSetClasses.map((className, index) => {
                                const { classes = [] } = templates[selectedTemplate] || {}
                                return (
                                    <ListItem>
                                        <ListItemText primary={className} />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                checked={classes.includes(index)}
                                                onClick={onClassesChangeBuilder(index)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ borderTop: '1px solid #CCC' }}>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button color="primary" variant="contained" onClick={onAccept}>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const TemplateMatching = () => {
    const dispatch = useDispatch()

    const { enabled, templates } = useSelector(
        (state) => state.trainingSetObjectDetection.settings.templateMatching
    )

    const onEnabledChange = (e) => {
        dispatch(setTemplateMatchingEnabled(e.target.checked))
    }

    const onAddTemplateClick = () => {
        dispatch(addTemplateMatchingTemplate())
    }

    const onRemoveTemplateClick = (index) => {
        dispatch(removeTemplateMatchingTemplate(index))
    }

    const onEditTemplateClick = (index) => {
        dispatch(selectTemplateMatchingTemplate(index))
    }

    return (
        <React.Fragment>
            <List>
                <ListItem>
                    <ListItemText primary="Activar" />
                    <ListItemSecondaryAction>
                        <Switch edge="end" checked={enabled} onChange={onEnabledChange} />
                    </ListItemSecondaryAction>
                </ListItem>
                {templates.map((template, index) => (
                    <ListItem>
                        <ListItemText key={index} primary={`Plantilla ${index + 1}`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => onEditTemplateClick(index)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" onClick={() => onRemoveTemplateClick(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <div style={{ padding: 5 }}>
                <Button variant="contained" color="primary" fullWidth onClick={onAddTemplateClick}>
                    Añadir Plantilla
                </Button>
            </div>
            <TemplateEditor />
        </React.Fragment>
    )
}

export default TemplateMatching
