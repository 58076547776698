import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useEditor } from '@craftjs/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import { v1 } from 'uuid'
import Container from '../../../../../components/container'

const TabsSettings = ({ query, actions, id }) => {
    const { actions: actionsEditor, query: queryEditor } = useEditor()

    const node = query.node(id).get()

    const [tabs, setTabs] = useState(node.data.props.tabs)

    /* eslint-disable no-unused-vars,no-param-reassign */
    const onIdChange = (e) => {
        actions.setProp(id, (p) => {
            p._id = e.target.value
        })
    }

    const onAddNewItem = () => {
        const newId = v1()
        const t = [...tabs, { id: newId, position: tabs.length, label: 'NEW TAB' }]
        setTabs(t)
        actions.setProp(newId, (p) => {
            p.tabs = t
        })

        const nodeInner = queryEditor
            .parseFreshNode({
                data: {
                    type: Container,
                    isCanvas: true,
                    props: {
                        _id: newId,
                    },
                },
            })
            .toNode()

        actionsEditor.add(nodeInner, newId)
    }

    const onLabelChange = (i) => (e) => {
        const temp = [
            ...tabs.slice(0, i),
            { ...tabs[i], label: e.target.value },
            ...tabs.slice(i + 1),
        ]
        setTabs(temp)
        actions.setProp(id, (p) => {
            p.tabs = temp
        })
    }

    const onDelete = (i) => (e) => {
        const temp = [...tabs.slice(0, i), ...tabs.slice(i + 1)]

        const childNodes = query.node(id).descendants()
        const sel = childNodes.find((c) => {
            const n = query.node(c).get()

            return n.data.props._id === tabs[i].id || n.id === tabs[i].id
        })

        actionsEditor.delete(sel)

        setTabs(temp)
        actions.setProp(id, (p) => {
            p.tabs = temp
        })
    }

    return (
        <List>
            <ListItem>
                <TextField
                    label="ID"
                    onChange={onIdChange}
                    defaultValue={node.data.props._id || id}
                    fullWidth
                />
            </ListItem>
            {tabs.map((t, i) => (
                <ListItem key={t.id}>
                    <TextField
                        label="Titulo"
                        onChange={onLabelChange(i)}
                        defaultValue={t.label}
                        fullWidth
                    />
                    <ListItemSecondaryAction>
                        <IconButton>
                            <DeleteIcon onClick={onDelete(i)} />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
            <ListItem>
                <Button fullWidth onClick={onAddNewItem}>
                    Añadir
                </Button>
            </ListItem>
        </List>
    )
}

TabsSettings.propTypes = {
    query: PropTypes.any.isRequired,
    actions: PropTypes.any.isRequired,
    id: PropTypes.any.isRequired,
}

export default TabsSettings
