import React from "react"

import { useTranslation } from 'react-i18next';
import { useQuery } from "@apollo/react-hooks"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"

import Paper from "@material-ui/core/Paper"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Fab from '@material-ui/core/Fab';
import Button from "@material-ui/core/Button"

import NavigationIcon from '@material-ui/icons/Store';

import Loading from "components/Loading"

import { GET_SUBSCRIPTIONS } from "../../queries/subscriptions"

function Subscriptions(props) {
    const classes = useStyles()

    const { t } = useTranslation("private");

    const { data: {  getSubscriptionsV2: { subscriptions = [] } = {} } = {}, loading} = useQuery(GET_SUBSCRIPTIONS)

    return (
        <Loading loading={loading}>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}>{t("account.common.number")}</TableCell>
                            <TableCell className={classes.cell}>{t("account.common.created_at")}</TableCell>
                            <TableCell className={classes.cell}>{t("account.common.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptions.map(sub => (
                            <TableRow>
                                <TableCell className={classes.cell} style={{ textTransform: "uppercase" }}>{sub.product}</TableCell>
                                <TableCell className={classes.cell} style={{ textTransform: "uppercase" }}>{new Date(sub.createdAt * 1000).toISOString()}</TableCell>
                                <TableCell className={classes.cell} style={{ textTransform: "uppercase" }}><Link to={"/private/store/" + sub.product}><Button>Editar</Button></Link></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={{ position: "fixed", bottom: 30, right: 30 }}>
                    <Link to={"/private/store"}>
                        <Fab variant="extended" color="primary">
                            <NavigationIcon />
                            {t("account.subscriptions.plans")}
                        </Fab>
                    </Link>
                </div>
            </Paper>
        </Loading>
    )
}

const useStyles = makeStyles(theme => ({
    cell: {
        textAlign: "center"
    }
}))

export default Subscriptions