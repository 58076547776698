import React, { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { GET_HMI_BY_ID, UPDATE_HMI_BY_ID } from '../../../../queries/hmi'
import { GET_DATASETS_V2_BY_PARENT } from '../../../../../../../datasets-v2/queries/datasets'

const Datasets = () => {
    const { hmi } = useParams()

    const [open, setOpen] = useState(false)
    const [selectedDatasets, setSelectedDatasets] = useState([])

    const {
        data: { getHMIById: { hmi: { datasets: hmiDatasets = { visible: [] } } = {} } = {} } = {},
    } = useQuery(GET_HMI_BY_ID, {
        variables: { params: { _id: hmi } },
    })

    useEffect(() => {
        if (hmiDatasets !== null && hmiDatasets.visible) {
            setSelectedDatasets([...hmiDatasets.visible, ...selectedDatasets])
        }
    }, [hmiDatasets])

    const { data: { getDatasetsV2ByParent: { datasets = [] } = {} } = {} } = useQuery(
        GET_DATASETS_V2_BY_PARENT,
        {
            variables: {
                params: {
                    parent: null,
                },
            },
        }
    )

    const [updateHMIById] = useMutation(UPDATE_HMI_BY_ID)

    const onItemClicked = () => setOpen(true)

    const onCancel = () => {
        setOpen(false)
    }

    const onAccept = async () => {
        await updateHMIById({
            variables: {
                params: {
                    _id: hmi,
                    datasets: { visible: selectedDatasets },
                },
            },
        })
        setOpen(false)
    }

    const onChange = (_, newValue) => {
        setSelectedDatasets(newValue)
    }

    return (
        <React.Fragment>
            <ListItem button onClick={onItemClicked}>
                <ListItemText primary="Datasets" secondary="Configuración sobre datasets" />
            </ListItem>
            <Dialog open={open}>
                <DialogTitle>Configuración de datasets</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>
                            <Autocomplete
                                multiple
                                options={datasets.map((obj) => obj.id)}
                                getOptionLabel={(option) => {
                                    if (option === undefined) {
                                        return datasets.map((obj) => obj.name)
                                    }
                                    return datasets.filter((obj) => obj.id === option)[0].name
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Visibles" />}
                                value={selectedDatasets}
                                onChange={onChange}
                            />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancelar</Button>
                    <Button onClick={onAccept}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default Datasets
