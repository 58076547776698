import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedEntry, setInPlaceAnnotationByEntry } from '../../redux/images'
import { setToolbox } from '../../redux/general'

/**
 * Component to render each entry
 */
const Sample = ({ sampleId, url, annotations }) => {
    const dispatch = useDispatch()
    const selectedEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedEntry
    )

    const onImageClicked = () => {
        dispatch(setToolbox('samples'))
        dispatch(setSelectedEntry(sampleId))
    }

    useEffect(() => {
        const formattedAnnotations = annotations.map((obj) => {
            if (Object.hasOwn(obj, 'coords')) {
                return obj
            }
            return {
                class: parseInt(obj.class, 10),
                coords: [obj.left, obj.top, obj.left + obj.width, obj.top + obj.height],
            }
        })
        dispatch(setInPlaceAnnotationByEntry(sampleId, formattedAnnotations))
    }, [sampleId, annotations, dispatch])

    return (
        <div
            style={{
                display: 'inline-block',
                padding: 1,
                position: 'relative',
            }}
        >
            <button
                type="button"
                className="header_btn"
                onClick={onImageClicked}
                style={
                    selectedEntry === sampleId
                        ? {
                              border: '5px solid #f00',
                              borderRadius: '5px',
                          }
                        : {}
                }
            >
                <img alt={sampleId} src={url} width="100%" />
            </button>
        </div>
    )
}

Sample.propTypes = {
    /** ID of the sample entry */
    sampleId: PropTypes.string.isRequired,
    /** Thumbnail url */
    url: PropTypes.string.isRequired,
    /** Annotations of the sample */
    annotations: PropTypes.array.isRequired,
}

export default Sample
