import React, { useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useQuery } from '@apollo/react-hooks'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { GET_MONITORS } from '../../../../../../../monitors/queries/monitors'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node, nodeDefinition, onPropertyChangeBuilder, getProperty }) => {
    const classes = useStyles()

    const { data: { getMonitors: { monitors = [] } = {} } = {} } = useQuery(GET_MONITORS, {
        variables: { params: {} },
    })

    const v = useMemo(() => {
        if (node.properties.monitors) {
            const monitorsIds = JSON.parse(node.properties.monitors)
            return monitorsIds
                .map((mId) => {
                    return monitors.find((m) => m.id === mId)
                })
                .filter((m) => m)
        }
        return []
    }, [node.properties.monitors, monitors])

    return (
        <div className={classes.root}>
            <List>
                <ListItem>
                    <TextField
                        label="Ruta de Entrada"
                        value={getProperty('input-key', '')}
                        onChange={onPropertyChangeBuilder('input-key', (e) => e.target.value)}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Ruta del valor"
                        value={getProperty('value-key', '')}
                        onChange={onPropertyChangeBuilder('value-key', (e) => e.target.value)}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <TextField
                        label="Ruta del timestamp"
                        value={getProperty('timestamp-key', '')}
                        onChange={onPropertyChangeBuilder('timestamp-key', (e) => e.target.value)}
                        fullWidth
                    />
                </ListItem>
                <ListItem>
                    <Autocomplete
                        multiple
                        fullWidth
                        id="tags-outlined"
                        options={monitors}
                        getOptionLabel={(option) => option.name}
                        value={v}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Monitores"
                                placeholder="Seleccione al menos un monitor"
                            />
                        )}
                        onChange={onPropertyChangeBuilder('monitors', (...args) => {
                            return JSON.stringify(args[1].map((monitor) => monitor.id))
                        })}
                    />
                </ListItem>
                <ListItem>
                    <FormControl fullWidth>
                        <InputLabel>Versión</InputLabel>
                        <Select
                            value={getProperty('version', '$LATEST')}
                            onChange={onPropertyChangeBuilder('version', (e) => e.target.value)}
                        >
                            {nodeDefinition.conf.versions.map((version) => (
                                <MenuItem key={`${node.id}_version${version}`} value={version}>
                                    {version}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ListItem>
            </List>
        </div>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        conf: PropTypes.shape({ versions: PropTypes.array }),
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
    nodeDefinition: PropTypes.object.isRequired,
    onPropertyChangeBuilder: PropTypes.func.isRequired,
    getProperty: PropTypes.func.isRequired,
}

export default SideBar
