import React, { useState } from "react"

import { useMutation } from "@apollo/react-hooks"
import { makeStyles } from "@material-ui/core/styles"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withSnackbar } from 'notistack';
import { useHistory } from "react-router-dom"

import isCreditCard from "validator/lib/isCreditCard"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MomentUtils from "@date-io/moment";
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab'
import CircularProgress from "@material-ui/core/CircularProgress"

const { getCodeList } = require('country-list');

import { ONBOARDING } from "../queries/onboarding"

function PaymentMethods(props) {

    const classes = useStyles()
    
    const [ billing_address_name,        set_billing_address_name ] = useState("")
    const [ billing_address_address,     set_billing_address_address ] = useState("")
    const [ billing_address_postal_code, set_billing_address_postal_code ] = useState("")
    const [ billing_address_country,     set_billing_address_country ] = useState("")
    const [ billing_address_city,        set_billing_address_city ] = useState("")
    const [ billing_address_vat,         set_billing_address_vat ] = useState("")

    const [ card_number, set_card_number ] = useState("")
    const [ card_date,   set_card_date ] = useState(null)
    const [ card_cvv,    set_card_cvv ] = useState("")

    const [ billing_address_name_error,        set_billing_address_name_error ] = useState("")
    const [ billing_address_address_error,     set_billing_address_address_error ] = useState("")
    const [ billing_address_postal_code_error, set_billing_address_postal_code_error ] = useState("")
    const [ billing_address_country_error,     set_billing_address_country_error ] = useState(false)
    const [ billing_address_city_error,        set_billing_address_city_error ] = useState("")
    const [ billing_address_vat_error,         set_billing_address_vat_error ] = useState("")

    const [ card_number_error, set_card_number_error ] = useState("")
    const [ card_date_error,   set_card_date_error ] = useState("")
    const [ card_cvv_error,    set_card_cvv_error ] = useState("")


    const [ loading, setLoading ] = useState(false)

    const [ onboarding ] = useMutation(ONBOARDING)

    const onBillingAddressNameChange = e => set_billing_address_name(e.target.value)
    const onBillingAddressAddressChange = e => set_billing_address_address(e.target.value)
    const onBillingAddressPostalCodeChange = e => set_billing_address_postal_code(e.target.value)
    const onBillingAddressCountryChange = e => set_billing_address_country(e.target.value)
    const onBillingAddressCityChange = e => set_billing_address_city(e.target.value)
    const onBillingAddressVatChange = e => set_billing_address_vat(e.target.value)

    const onCardNumberChange = e => set_card_number(e.target.value)
    const onCardDateChange = date => set_card_date(date)
    const onCardCvvChange = e => set_card_cvv(e.target.value)

    const onSubmit = async () => {
        if(!billing_address_name.length) {
            set_billing_address_name_error("Introduzca un nombre válido")
            return
        } else {
            set_billing_address_name_error("")
        }

        if(!billing_address_address.length) {
            set_billing_address_address_error("Introduzca una dirección válida")
            return
        } else {
            set_billing_address_address_error("")
        }

        if(!billing_address_vat.length) {
            set_billing_address_vat_error("Introduzca un NIF válido")
            return
        } else {
            set_billing_address_vat_error("")
        }

        if(!billing_address_city.length) {
            set_billing_address_city_error("Introduzca una ciudad válida")
            return
        } else {
            set_billing_address_city_error("")
        }

        if(!billing_address_postal_code.length) {
            set_billing_address_postal_code_error("Introduzca un código postal válido")
            return
        } else {
            set_billing_address_postal_code_error("")
        }

        if(!billing_address_country.length) {
            set_billing_address_country_error(true)
            return
        } else {
            set_billing_address_country_error(false)
        }

        if(!isCreditCard(card_number)) {
            set_card_number_error("Introduzca una tarjeta bancaria válida")
            return
        } else {
            set_card_number_error("")
        }

        if(!(card_cvv.length >= 3 && card_cvv.length <= 4)) {
            set_card_cvv_error("Introduzca un cvv válido")
            return
        } else {
            set_card_cvv_error("")
        }

        setLoading(true)
        try {
            const response = await onboarding({
                variables: { 
                    params: {
                        billingDetails: {
                            name: billing_address_name,
                            address: {
                                line1: billing_address_address, 
                                line2: billing_address_vat,
                                postalCode: billing_address_postal_code,
                                city: billing_address_city,
                                country: billing_address_country
                            }
                        },
                        card: {
                            number: card_number,
                            expMonth: card_date.month() + 1,
                            expYear: card_date.year(),
                            cvc: card_cvv
                        }
                    }
                }
            })
            props.history.push("/private")
        } catch(err) {
            props.enqueueSnackbar("Se ha producido un error", { variant: "error" })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={MomentUtils} >
                <Grid className={classes.container} container direction="row" justify="center" alignItems="center">
                    <Grid className={classes.card} item lg={4} md={6} sm={8} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h2" style={{ marginBottom: 10 }}>Dirección de Facturación</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Nombre" 
                                    placeholder="Enter name" 
                                    margin="normal" 
                                    variant="outlined"
                                    fullWidth
                                    disabled={loading}
                                    error={billing_address_name_error.length}
                                    helperText={billing_address_name_error}
                                    onChange={onBillingAddressNameChange} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Dirección" 
                                    placeholder="Enter address" 
                                    margin="normal" 
                                    variant="outlined"
                                    fullWidth 
                                    disabled={loading}
                                    error={billing_address_address_error.length}
                                    helperText={billing_address_address_error}
                                    onChange={onBillingAddressAddressChange} 
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    label="NIF" 
                                    placeholder="Enter address" 
                                    margin="normal" 
                                    variant="outlined"
                                    fullWidth 
                                    disabled={loading}
                                    error={billing_address_vat_error.length}
                                    helperText={billing_address_vat_error}
                                    onChange={onBillingAddressVatChange} 
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    label="Ciudad" 
                                    placeholder="Enter address" 
                                    margin="normal" 
                                    variant="outlined"
                                    fullWidth 
                                    disabled={loading}
                                    error={billing_address_city_error.length}
                                    helperText={billing_address_city_error}
                                    onChange={onBillingAddressCityChange} 
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    label="Codigo Postal" 
                                    placeholder="Enter postal code" 
                                    margin="normal" 
                                    variant="outlined"
                                    fullWidth 
                                    disabled={loading}
                                    error={billing_address_postal_code_error.length}
                                    helperText={billing_address_postal_code_error}
                                    onChange={onBillingAddressPostalCodeChange} 
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl margin="normal" variant="outlined" fullWidth disabled={loading} error={billing_address_country_error}>
                                    <Select 
                                        label="País" 
                                        placeholder="Enter country" 
                                        fullWidth 
                                        input={<OutlinedInput name="age" id="outlined-age-simple" />}
                                        value={billing_address_country}
                                        onChange={onBillingAddressCountryChange} 
                                    >
                                            {Object.entries(getCodeList()).map(c => <MenuItem value={c[0]}>{c[1]}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid className={classes.section} container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h2" style={{ marginBottom: 10 }}>Método de pago</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    label="Numero de Tarjeta" 
                                    placeholder="Enter name" 
                                    margin="normal" 
                                    variant="outlined"
                                    fullWidth
                                    disabled={loading}
                                    error={card_number_error.length}
                                    helperText={card_number_error}
                                    onChange={onCardNumberChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    openTo="year"
                                    format="MM/YY"
                                    inputVariant="outlined"
                                    views={["year", "month"]}
                                    label="Expiration Date"
                                    placeholder="MM/YY"
                                    fullWidth 
                                    margin="normal"
                                    value={card_date}
                                    disabled={loading}
                                    onChange={onCardDateChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    label="CVV" 
                                    placeholder="Enter name" 
                                    margin="normal" 
                                    variant="outlined"
                                    fullWidth 
                                    disabled={loading}
                                    error={card_cvv_error.length}
                                    helperText={card_cvv_error}
                                    onChange={onCardCvvChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.buttonContainer}>
                                <Fab
                                    className={classes.action}
                                    variant="extended"
                                    color="primary"
                                    onClick={onSubmit}
                                    disabled={loading}
                                >
                                    Submit
                                </Fab>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        display: "flex",
        height: "100%"
    },
    container: { 
        background: "linear-gradient(60deg,#FF4E5077,#F9D42377), url('https://flightlabs.es/wp-content/uploads/2019/06/header.2c04489a1.jpg')", 
        backgroundSize: "cover", 
        flex: 1,
        flexDirection: "column",
        flexGrow: 1
    },
    card: {
        background: "#FFF", 
        padding: "30px 35px", 
        margin: -10, 
        boxShadow: "0 0 40px -15px rgba(0, 0, 0, 0.6)", 
        borderRadius: 10
    },
    action: { 
        fontWeight: 900,
        fontSize: 13, 
        width: "100%",
        boxShadow: "0 0 45px -12px rgba(0,0,0,0.6)" 
    },
    section: { 
        margin: "15px 0"
    },
    buttonContainer: {
        position: "relative"
    },
    buttonProgress: {
        position: "absolute",
        left: "50%",
        top: "50%",
        marginTop: -12,
        marginLeft: -12
    }
})

export default withSnackbar(PaymentMethods)