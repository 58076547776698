import React from 'react'

import { useQuery } from '@apollo/react-hooks'

import Subheader from 'material-ui/Subheader'

import AppEntry from './components/AppEntry'

import Loading from 'components/Loading'
/* import StorageWidget from "./containers/storage"
 */
import entries from '../../../../../../apps'

function Dashboard(props) {
    return (
        <Loading loading={false}>
            <div className="container">
                <Subheader>Apps</Subheader>
                <div className="row">
                    {['falcon', 'horus'].map((app) => {
                        const entry = entries[app]
                        return (
                            <AppEntry
                                title={entry.title}
                                text={entry.text}
                                image={entry.image}
                                path={props.match.url + '/' + entry.path}
                            />
                        )
                    })}
                </div>
                {/*                  <Subheader>Widgets</Subheader>

                <div className="row">
                        <div className="col-sm-6"><StorageWidget/></div>                    
                </div>     */}
            </div>
        </Loading>
    )
}

export default Dashboard
