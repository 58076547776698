import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useSelector, useDispatch } from "react-redux"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { setSelectedHotspot } from "../../reducers/controls"

import { GET_HOTSPOTS_BY_IMAGE } from "../../queries/hotspots"

function Annotations(props) {
    const dispatch = useDispatch()

    const { selected_image } = useSelector(state => state.inspector.solar.images)

    const selectHotspot = hotspot => dispatch(setSelectedHotspot(hotspot))

    const { data: { getHotspotsByImage: { hotspots = []} = {}} = {} } = useQuery(GET_HOTSPOTS_BY_IMAGE,  {
        variables: {
            params: {
                image: selected_image._id
            }
        }
    })
    
    return (
        <List>
            {
                hotspots.map(hotspot => {
                    const hs = hotspot || {}
                    return (
                        <ListItem button onClick={() => selectHotspot(hs)}>
                            <ListItemText primary={hs.category} secondary={hs.severity}/>
                        </ListItem>
                    )
                })
            }
        </List>
    )
}

export default Annotations