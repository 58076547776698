import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import Incidents from "./containers/incidents/scene"

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

const config = {
    "incidents": {
        component: Incidents,
    }
}

const StepMonitorActions = (props) => {
    const classes = useStyles()

    const [actionType, setActionType] = useState(null)
    const [actionConfig, setActionConfig] = useState(null)
    
    console.log(actionType, actionConfig)
    const onStepMonitorActionsAccept = () => {
        if (!actionType || !actionConfig) {
            return
        }
        
        props.onPropertyChange(props.property, [
            { 
                type: actionType, 
                values: actionConfig 
            }
        ])

        props.setActiveStep(props.nextStep)
    }

    const onStepMonitorActionsBack = () => {
        props.setActiveStep(props.prevStep)
    }

    const options = [{ value: 'incidents', label: 'Registrar Incidencia' }]


    const renderConfiguration = () => {
        const current = config[actionType]
        
        if(!current) {
            return null
        }

        const onChange = (value) => {
            setActionConfig(value)
        }

        
        const Configuration = current.component

        return (
            <Grid item xs={12}>
                <Configuration 
                    value={actionConfig} 
                    onChange={onChange}
                    properties={props.properties}
                />
            </Grid>
        )
    }

    const onActionTypeChange = (event) => {
        setActionType(event.target.value)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="action-label">Acción</InputLabel>
                    <Select labelId="action-label" onChange={onActionTypeChange}>
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid container item xs={12}>
                {
                    renderConfiguration()
                }
            </Grid>
            <Grid container item xs={12} className={classes.actions}>
                <Grid item xs={2}>
                    <Button onClick={onStepMonitorActionsBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorActionsAccept}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorActions
