import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core/styles'
import { withTheme } from '@mui/styles'
import { Fab } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'

import { UPDATE_ENTRY_BY_ID } from '../../../../queries/training-sets'
import { saveInPlace } from '../../redux/images'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            position: 'fixed',
            bottom: theme.spacing(9),
            right: theme.spacing(13),
        },
        '@keyframes blinker': {
            from: { opacity: 1 },
            to: { opacity: 0 },
        },
        blinkingIcon: {
            animationName: '$blinker',
            animationDuration: '1s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
            animationDirection: 'alternate',
        },
    }
})

/**
 * Automatic save
 *
 */
const Save = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const savingTime = useSelector((state) => state.trainingSetOcr.images.saveInPlace)
    const { trainingSet: trainingSetId } = useParams()
    const [updating, setUpdating] = useState(false)
    const [finishing, setFinished] = useState(false)
    const [error, setError] = useState(false)

    const inPlaceAnnotations = useSelector(
        (state) => state.trainingSetOcr.images.inPlaceAnnotations || []
    )

    const [updateAnnotations] = useMutation(UPDATE_ENTRY_BY_ID)
    const timer = useRef(null)
    const saveAnnotations = async (entryId, annotation) => {
        // Checking annotations
        const fixAnnotation = annotation.map((obj) => {
            // Check coords order
            const xUp = Math.min(obj.coords[0], obj.coords[2])
            const xBt = Math.max(obj.coords[0], obj.coords[2])
            const yUp = Math.min(obj.coords[1], obj.coords[3])
            const yBt = Math.max(obj.coords[1], obj.coords[3])
            const newObj = {
                ...obj,
                coords: [
                    parseInt(xUp, 10),
                    parseInt(yUp, 10),
                    parseInt(xBt, 10),
                    parseInt(yBt, 10),
                ],
            }
            return newObj
        })
        const { data, error: errorMut = false } = await updateAnnotations({
            variables: {
                params: {
                    trainingSet: trainingSetId,
                    _id: entryId,
                    inputs: [{ name: 'annotations', value: JSON.stringify(fixAnnotation) }],
                },
            },
        })
        if (data.updateTrainingSetEntryById.status === null || errorMut) {
            throw new Error('Could not upload the annotations')
        }
    }

    const showUpdating = !error && updating && !finishing
    const showFinishedButton = !error && finishing && !updating
    const showErrorButton = error && finishing && !updating

    useEffect(() => {
        if (savingTime) {
            setUpdating(true)
            const updatePromises = []
            Object.keys(inPlaceAnnotations).forEach((obj) => {
                updatePromises.push(saveAnnotations(obj, inPlaceAnnotations[obj]))
            })
            Promise.all(updatePromises)
                .then(() => {
                    setUpdating(false)
                    setFinished(true)
                    timer.current = setTimeout(() => {
                        setFinished(false)
                        clearTimeout(timer)
                        timer.current = null
                        dispatch(saveInPlace(false))
                    }, 3000)
                })
                .catch(() => {
                    setUpdating(false)
                    setFinished(true)
                    setError(true)
                    timer.current = setTimeout(() => {
                        setFinished(false)
                        setError(false)
                        clearTimeout(timer)
                        timer.current = null
                        dispatch(saveInPlace(false))
                    }, 3000)
                })
        }
    }, [savingTime])

    if (savingTime) {
        return (
            <div className={classes.root}>
                {showUpdating && (
                    <Fab variant="contained" className={classes.blinkingIcon} color="primary">
                        <SaveIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
                {showFinishedButton && (
                    <Fab variant="contained" color="success">
                        <DoneIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
                {showErrorButton && (
                    <Fab variant="contained" color="error">
                        <CloseIcon style={{ cursor: 'wait' }} fontSize="large" />
                    </Fab>
                )}
            </div>
        )
    }
    return null
}
// Using current theme
const SaveThemed = withTheme(Save)

export default SaveThemed
