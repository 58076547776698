import React from "react"

import Typography from "@material-ui/core/Typography"

import Plan from "./types/plan"
import Addon from "./types/addon"

function Dummy() {
    return(
        <div>TEST</div>
    )
}

const types = {
    radio: Plan,
    checkbox: Addon
}

function Group(props) {
    const Current = types[props.type] || Dummy
    return (
        <div style={{ margin: "50px 0"}}>
            <Typography variant="h3" style={{ textTransform: "uppercase", marginBottom: 50 }}>{props.title}</Typography>
            <Current onSelect={props.onSelect} plans={props.plans} subscriptions={props.subscriptions}/>
        </div>
    )
}

export default Group