import React from 'react'
import { useEditor } from '@craftjs/core'
import MaterialButton from '@material-ui/core/Button'

import Row from './components/row'
import Text from './components/text'
import Toggle from './components/toggle'
import Button from './components/button'
import Card from './components/card'
import View from './components/view'
import Container from './components/container'
import Checkbox from './components/checkbox'
import TextField from './components/text-field'
import Select from './components/select'
import Image from './components/image'
import Scroll from './components/scroll'
import Tabs from './components/tabs'
import Palletizer from './components/palletizer'

const settings = {
    row: Row,
    text: Text,
    toggle: Toggle,
    button: Button,
    card: Card,
    view: View,
    container: Container,
    checkbox: Checkbox,
    'text-field': TextField,
    select: Select,
    image: Image,
    scroll: Scroll,
    tabs: Tabs,
    palletizer: Palletizer,
}

const Settings = () => {
    const { actions, query, selected } = useEditor((state) => {
        const currentNodeId = state.events.selected
        if (currentNodeId) {
            return {
                selected: {
                    id: currentNodeId,
                    name: state.nodes[currentNodeId].data.name,
                    settings:
                        state.nodes[currentNodeId].related &&
                        state.nodes[currentNodeId].related.settings,
                },
            }
        }
        return {
            selected: undefined,
        }
    })

    const CurrentNode = settings[selected && selected.name]

    const onDeleteClick = () => {
        actions.delete(selected.id)
    }

    return (
        <div style={{ height: '100%', overflowY: 'auto' }}>
            {CurrentNode && (
                <CurrentNode key={selected.id} id={selected.id} query={query} actions={actions} />
            )}
            {selected && selected.id !== 'ROOT' && (
                <div style={{ padding: 10 }}>
                    <MaterialButton variant="contained" fullWidth onClick={onDeleteClick}>
                        Eliminar
                    </MaterialButton>
                </div>
            )}
        </div>
    )
}

export default Settings
