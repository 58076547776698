import React from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import Parametrize, { TYPE_FIXED } from '../../../../utils/parametrize'

import {
    STRING_TYPE,
    INTEGER_TYPE,
} from '../../../../../group/containers/params/containers/param-type/index'

import { setNodeProperties } from '../../../../redux/nodes'

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        padding: '5px',
    },
}))

const SideBar = ({ node }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const onPropertyChangedHandlerBuilder = (property) => (event) => {
        dispatch(
            setNodeProperties({
                nodeId: node.id,
                properties: {
                    type: property,
                    value: JSON.stringify({ type: TYPE_FIXED, value: event.target.value }),
                },
            })
        )
    }

    const getProperty = (key, def) => {
        return JSON.parse(node.properties[key] || JSON.stringify({})).value || def
    }

    return (
        <Grid container className={classes.root} alignItems="flex-end">
            <Grid item xs={12}>
                <Parametrize name="ip" humanName="Dirección IP" parametersFilter={STRING_TYPE}>
                    <TextField
                        label="Dirección IP"
                        value={getProperty('ip', '')}
                        onChange={onPropertyChangedHandlerBuilder('ip')}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="port" humanName="Puerto" parametersFilter={INTEGER_TYPE}>
                    <TextField
                        label="Puerto"
                        value={getProperty('port', '')}
                        onChange={onPropertyChangedHandlerBuilder('port')}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="pallets" humanName="Pallets" parametersFilter={STRING_TYPE}>
                    <TextField
                        label="Pallets"
                        value={getProperty('pallets', '')}
                        onChange={onPropertyChangedHandlerBuilder('pallets')}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="conveyors" humanName="Conveyors" parametersFilter={STRING_TYPE}>
                    <TextField
                        label="Conveyors"
                        value={getProperty('conveyors', '')}
                        onChange={onPropertyChangedHandlerBuilder('conveyors')}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="boxes" humanName="Boxes" parametersFilter={STRING_TYPE}>
                    <TextField
                        label="Boxes"
                        value={getProperty('boxes', '')}
                        onChange={onPropertyChangedHandlerBuilder('boxes')}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="layouts" humanName="Layouts" parametersFilter={STRING_TYPE}>
                    <TextField
                        label="Layouts"
                        value={getProperty('layouts', '')}
                        onChange={onPropertyChangedHandlerBuilder('layouts')}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
            <Grid item xs={12}>
                <Parametrize name="stacks" humanName="Stacks" parametersFilter={STRING_TYPE}>
                    <TextField
                        label="Stacks"
                        value={getProperty('stacks', '')}
                        onChange={onPropertyChangedHandlerBuilder('stacks')}
                        fullWidth
                    />
                </Parametrize>
            </Grid>
        </Grid>
    )
}

SideBar.propTypes = {
    node: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
        }),
        ports: PropTypes.array,
        properties: PropTypes.object,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }).isRequired,
}

export default SideBar
