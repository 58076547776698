import React, { useMemo, useState, useEffect } from 'react'

import { PropTypes } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@mui/material/TextField'

import { detectImage } from '../utils/detect'

import { GET_MODELS, GET_DELIVERABLES_BY_MODEL } from '../../../../../../models/queries/models'

export const OBJECT_DETECTION_ID = 'object-detection'

const ROW_HEIGHT = 120

import { createModelCpu, createModelGpu, runModel } from '../utils/onnx'

export const ObjectDetectionComponent = ({ value, node, api }) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const { url, session } = value.reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {})

    setTimeout(() => {
        node.setRowHeight(ROW_HEIGHT)
        api.onRowHeightChanged()
    }, 1000)

    useEffect(() => {
        const image = new Image()

        image.onload = async function () {
            const canvas = await new Promise((resolve, reject) => {
                let handle = setInterval(() => {
                    let c = document.getElementById(url)
                    if (c) {
                        clearInterval(handle)
                        resolve(c)
                    }
                }, 100)
            })

            const ctx = canvas.getContext('2d', { willReadFrequently: true })
            ctx.drawImage(image, 0, 0, 640, 640)
            const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)

            const res = await detectImage(image, session, 0.001, 0.0001, 0.0001, [1, 3, 640, 640])
        }
        image.crossOrigin = 'Anonymous'

        image.src = url
    }, [url, session])

    return (
        <div style={{ height: ROW_HEIGHT, display: 'inline-block' }}>
            <canvas id={url} width={'640'} height={'640'}></canvas>
        </div>
    )
}

ObjectDetectionComponent.propTypes = {
    /** Value of the entry in the column */
    value: PropTypes.object.isRequired,
    /** Node value of table api */
    node: PropTypes.any.isRequired,
    /** Api */
    api: PropTypes.any.isRequired,
}

export const ObjectDetectionInputComponent = ({ columnsList, dataContainer }) => {
    const [selectedModel, setSelectedModel] = useState(null)
    const [selectedDeliverable, setSelectedDeliverable] = useState(null)
    const [modelLoading, setModelLoading] = useState(false)
    const [imageColumn, setImageColumn] = useState('')

    const { data: { getModels: { models = [] } = {} } = {} } = useQuery(GET_MODELS)
    const { data: { getDeliverablesByModel: { deliverables = [] } = {} } = {} } = useQuery(
        GET_DELIVERABLES_BY_MODEL,
        { variables: { params: { model: selectedModel } } }
    )

    const succededModels = useMemo(() => models.filter((m) => m.state === 'SUCCEEDED'), [models])

    const onSelectedModelChange = (event) => {
        setSelectedModel(event.target.value)
    }

    const onSelectedDeliverableChange = async (event) => {
        setModelLoading(true)
        const deliverable = deliverables.find((d) => d._id === event.target.value)

        if (!deliverable) {
            setModelLoading(false)
            return
        }

        const sess = await createModelCpu(deliverable._id, deliverable.url)

        setSelectedDeliverable(deliverable._id)

        dataContainer.current.session = sess
        setModelLoading(false)
    }

    const onImageColumnChange = (event) => {
        setImageColumn(event.target.value)
        dataContainer.current.imageColumn = event.target.value // eslint-disable-line no-param-reassign
    }

    const loading = modelLoading

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>Modelo</InputLabel>
                <Select value={selectedModel} onChange={onSelectedModelChange}>
                    {succededModels.map((m) => {
                        return <MenuItem value={m._id}>{m.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Entregable</InputLabel>
                <Select value={selectedDeliverable} onChange={onSelectedDeliverableChange}>
                    {deliverables.map((d) => {
                        return <MenuItem value={d._id}>{d._id}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Columna imagen</InputLabel>
                <Select value={imageColumn} onChange={onImageColumnChange}>
                    {Object.entries(columnsList)
                        .filter((obj) => obj[1] === 'rgb')
                        .map((obj) => {
                            return (
                                <MenuItem key={obj[0]} value={obj[0]}>
                                    {obj[0]}
                                </MenuItem>
                            )
                        })}
                </Select>
            </FormControl>
        </div>
    )
}

ObjectDetectionInputComponent.propTypes = {
    /** List of all columns */
    columnsList: PropTypes.array.isRequired,
    /** Static container to save the configuration */
    dataContainer: PropTypes.object.isRequired,
}

export class ObjectDetection {
    constructor(name) {
        this.name = name
    }

    /**
     * Get the field configuration
     * @returns object
     */
    getField() {
        return { name: this.name, type: OBJECT_DETECTION_ID }
    }

    /**
     * Function to check the configuration data integrity
     * @param {object} data
     * @returns bool
     */
    static checkData(data) {
        if (!Object.hasOwn(data, 'imageColumn')) {
            return false
        }

        return true
    }

    /**
     *
     * @param {object} _ - Each entry of the dataset, but in constant is not used
     * @param {object} data - Operation configuration
     * @returns New data for the entry
     */
    /* eslint-disable class-methods-use-this */
    call(entry, data) {
        return { ...entry[data.imageColumn], session: data.session }
    }
}
