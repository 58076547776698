import React, { useState } from "react"

import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useMutation } from "@apollo/react-hooks"
import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog"
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Typography from "@material-ui/core/Typography"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"
import Card from "@material-ui/core/Card"
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ListSubheader from '@material-ui/core/ListSubheader';
import { CREATE_DASHBOARD } from "../../queries/dashboards";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fab: {
        position: "fixed", 
        bottom: theme.spacing(5), 
        right: theme.spacing(5)
    }
}));

export default (props) => {
    
    const classes = useStyles()
    const history = useHistory()

    const { dashboard } = useParams()

    const [ createDashboard ] = useMutation(CREATE_DASHBOARD)

    const [ loading, setLoading ] = useState(false)   
    const [ open, setOpen ] = useState(false)

    const [ name, setName ] = useState("")

    const onOpenDialogClicked = () => setOpen(true)
    
    const onCancelClicked = () => {
        setOpen(false)
        setLoading(false)
    }

    const onAcceptClicked = async () => {
        setLoading(true)
        await createDashboard({
            variables: {
                params: {
                    name
                }
            }
        })
        setLoading(false)
        setOpen(false)
    }

    const onNameChange = e => setName(e.target.value)

    return (
        <React.Fragment>
            <Dialog open={open} maxWidth={"lg"}>
                <DialogTitle>Nuevo Dashboard</DialogTitle>
                <DialogContent>
                    <TextField label="Nombre" value={name} onChange={onNameChange}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancelClicked} disabled={loading}>Cancelar</Button>
                    <Button onClick={onAcceptClicked} disabled={loading}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Fab className={classes.fab} color="primary" onClick={onOpenDialogClicked}>
                <AddIcon/>
            </Fab>
        </React.Fragment>
    )
}