import gql from "graphql-tag"

export const GET_ALL_PROJECTS = gql`
    query GetProjectsByParent($params: GetProjectsByParentRequest) {
        getProjectsByParent(params: $params) {
            status
            projects {
                _id
                name
                position {
                    coordinates
                }
                createdAt
            }
        }
    }
`

export const CREATE_PROJECT = gql`
    mutation createProject($params: CreateProjectRequest!){
        createProject(params: $params) {
            state
        }
    }
`