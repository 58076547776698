import React from "react"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { GET_TRAINING_SET_BY_ID } from "../../../../queries/training-sets"
import { SELECTED_IMAGE } from "../../queries/state";
import { GET_CLASSIFICATION_ENTRY_BY_ID, UPDATE_CLASSIFICATION_ENTRY_BY_ID } from "../../queries/classification-entries";

function TagsMenu(props) {

    const { trainingSet } = useParams()

    const { data: { getTrainingSetById: { trainingSet: { classes = []} = {}} = {}} = {}} = useQuery(GET_TRAINING_SET_BY_ID, {
        variables: {
            params: {
                _id: trainingSet
            }
        }
    })
    const { data: { labelerSelectedImage } = {}} = useQuery(SELECTED_IMAGE)
    const { data: { getClassificationEntryById: { classificationEntry: { label = "" } = {} } = {}} = {}} = useQuery(GET_CLASSIFICATION_ENTRY_BY_ID, {
        variables: {
            params: {
                _id: labelerSelectedImage
            }
        },
        skip: !labelerSelectedImage
    })
    const [ updateClassificationEntryById ] = useMutation(UPDATE_CLASSIFICATION_ENTRY_BY_ID)

    const onRadioChange = async event => {
        if(labelerSelectedImage) {
            await updateClassificationEntryById({
                variables: {
                    params: {
                        _id: labelerSelectedImage,
                        label: event.target.value
                    },
                },
                refetchQueries: [
                    {
                        query: GET_CLASSIFICATION_ENTRY_BY_ID,
                        variables: {
                            params: {
                                _id: labelerSelectedImage
                            }
                        }
                    }
                ]
            })
        }
    }

    return (
        <FormControl component="fieldset" style={{ display: "inline-block", height: "100%", width: "100%", borderLeft: "1px solid #AAA", background: "#FFF", padding: "10px 25px 10px 25px"}}>
            <FormLabel>Clases</FormLabel>
            <RadioGroup value={label} onChange={onRadioChange}>
                {classes.map((clase, i) => (
                    <FormControlLabel
                        value={clase}
                        control={<Radio/>}
                        label={clase}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

export default TagsMenu