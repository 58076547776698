import gql from "graphql-tag";

export const GET_INCIDENT_GROUPS = gql`
    query GetIncidentGroups($params: GetIncidentGroupsRequest) {
        getIncidentGroups(params: $params) {
            status
            error
            groups {
                id
                name
                levels
            }
        }
    }
`;

export const GET_INCIDENT_GROUP_BY_ID = gql`
    query GetIncidentGroupById($params: GetIncidentGroupByIdRequest) {
        getIncidentGroupById(params: $params) {
            status
            error
            group {
                id
                name
                levels
                emails
            }
        }
    }
`;

export const CREATE_INCIDENT_GROUP = gql`
    mutation CreateIncidentGroup($params: CreateIncidentGroupRequest) {
        createIncidentGroup(params: $params) {
            status
            error
        }
    }
`;

export const UPDATE_INCIDENT_GROUP = gql`
    mutation UpdateIncidentGroup($params: UpdateIncidentGroupRequest) {
        updateIncidentGroup(params: $params) {
            status
            error
        }
    }
`;
