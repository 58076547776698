import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useQuery } from '@apollo/react-hooks'
import { Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Loading from 'components/Loading'
import { addNode, setNodeDefinitions } from '../../redux/nodes'
import { GET_FUNCTIONS } from '../../../../queries/blueprints'

const NodesMenu = ({ loading }) => {
    const dispatch = useDispatch()

    const { data: { getFunctions: { payload = [] } = {} } = {} } = useQuery(GET_FUNCTIONS)

    const nodes = payload.reduce((prev, next) => {
        return { ...prev, [next._id]: next }
    }, {})

    const onItemClicked = (id) => () => {
        dispatch(addNode(nodes[id]))
    }

    // Get groups render
    const elementsByGroups = {}
    payload.forEach((node) => {
        if (!('group' in node)) {
            console.error(`Node bad defined ${node._id}`)
        } else {
            if (!(node.group in elementsByGroups)) {
                elementsByGroups[node.group] = []
            }
            elementsByGroups[node.group].push(node)
        }
    })
    const groupsRender = []
    Object.keys(elementsByGroups)
        .sort()
        .forEach((group) => {
            const groupObj = elementsByGroups[group]
            const itemsRender = groupObj.sort().map((obj) => {
                const itemRender = (
                    <ListItem
                        key={obj._id}
                        draggable={true}
                        button
                        onClick={obj.disabled ? () => {} : onItemClicked(obj._id)}
                        disabled={obj.disabled}
                    >
                        <ListItemText>
                            <Typography fontSize={12}>{obj.name}</Typography>
                        </ListItemText>
                    </ListItem>
                )
                return itemRender
            })
            //
            groupsRender.push(
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography fontSize={13}>{group}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>{itemsRender}</List>
                    </AccordionDetails>
                </Accordion>
            )
        })

    // Set nodes definition in state
    useEffect(() => {
        dispatch(setNodeDefinitions(nodes))
    }, [nodes, dispatch])
    return <Loading loading={loading}>{groupsRender}</Loading>
}

NodesMenu.propTypes = {
    loading: PropTypes.bool.isRequired,
}

export default NodesMenu
