export default {
    horus: {
        id: 'horus',
        title: 'Horus',
        text: 'Crea un proyecto y comienza a agilizar la inspeccion de tus instalaciones',
        image: require('../static/horus-card.jpg'),
        path: 'inspector',
    },
    falcon: {
        id: 'falcon',
        title: 'Falcon',
        text: `Automatización y monitorización inteligente para entornos industriales\n`,
        image: 'https://images.pexels.com/photos/159282/technology-servers-server-159282.jpeg?cs=srgb&dl=analogue-blur-business-159282.jpg&fm=jpg',
        path: 'industrial',
    },
}
