import React from 'react'

import { Switch, Route } from 'react-router-dom'

import Inspections from './containers/inspections/scene'
import Viewer from './containers/viewer/scene'
import Reports from './containers/reports/scene'

function Solar(props) {
    return (
        <Switch>
            <Route
                exact
                path={props.match.url + '/:project/:section(inspections|settings)?'}
                component={Inspections}
            />
            <Route
                exact
                path={props.match.url + '/:project/inspections/:inspection'}
                component={Viewer}
            />
            <Route
                exact
                path={props.match.url + '/:project/inspections/:dataset/report'}
                component={Reports}
            />
        </Switch>
    )
}

export default Solar
