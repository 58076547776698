import gql from "graphql-tag"

export const CREATE_ENTRIES = gql`
    mutation CreateHorusDatasetEntries($params: CreateHorusDatasetEntriesRequest!) {
        createHorusDatasetEntries(params: $params) {
            status
            properties {
                name
                value
            }
        }
    }
`
export const GET_ENTRIES = gql`
    query getHorusDatasetEntriesByDataset($params: GetHorusDatasetEntriesByDatasetRequest!) {
        getHorusDatasetEntriesByDataset(params: $params) {
            status
            entries {
                _id
                dataset
                createdAt
                updatedAt
                properties {
                    name
                    value
                }
            }
        }
    }
`