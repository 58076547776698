import React, { useState, useMemo } from 'react'

import { useQuery } from '@apollo/react-hooks'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Subheader from '@material-ui/core/ListSubheader'
import Button from '@material-ui/core/Button'

import ChevronLeft from '@material-ui/icons/ChevronLeft'

import { GET_MONITOR_BY_ID } from '../../queries/monitors'

import Schedule from './containers/schedule/scene'
import Triggers from './containers/triggers/scene'
import Thresholds from './containers/thresholds/scene'
import Actions from './containers/actions/scene'
import Settings from './containers/settings/scene'

const alturaPrimeraMitad = 40

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
}))

const Scene = (props) => {
    const classes = useStyles()

    const { monitor } = useParams()

    const [tab, setTab] = useState(0)

    const {
        loading,
        error,
        data: {
            getMonitor: {
                monitor: { name = '', status = '', createdAt = '', configuration = '' } = {},
            } = {},
        } = {},
    } = useQuery(GET_MONITOR_BY_ID, {
        variables: { params: { id: monitor } },
    })

    const tabs = [
        { label: 'Horarios', component: Schedule },
        { label: 'Disparador', component: Triggers },
        { label: 'Umbrales', component: Thresholds },
        { label: 'Acciones', component: Actions },
        { label: 'Configuración', component: Settings },
    ]

    const config = useMemo(() => {
        try {
            return JSON.parse(configuration)
        } catch (e) {
            return {}
        }
    }, [configuration])

    const renderTabComponent = () => {
        const t = tabs[tab]

        if (!t) return null

        const Component = t.component

        if (!Component) return null

        return <Component config={config} />
    }

    const createdAtDate = new Date(createdAt)

    return (
        <Container className={classes.container}>
            <Grid container spacing={2} style={{ height: 'calc(100% - 50px)' }}>
                <Grid item xs={12}>
                    <Button color="primary" component={Link} to="list" startIcon={<ChevronLeft />}>
                        Volver a Monitores
                    </Button>
                </Grid>
                <Grid item xs={5} style={{ height: `${alturaPrimeraMitad}%` }}>
                    <Paper style={{ padding: 5, height: '100%' }}>
                        <Subheader>Estado</Subheader>
                        <Grid
                            container
                            style={{ height: 'calc(100% - 88px)' }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12}>
                                <Typography variant="h1" style={{ textAlign: 'center' }}>
                                    {status}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={7} style={{ height: `${alturaPrimeraMitad}%` }}>
                    <Paper style={{ padding: 5, height: '100%' }}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Nombre" secondary={name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tipo de monitor" secondary={'local'} />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Fecha de creación"
                                    secondary={createdAtDate.toDateString()}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Identificador" secondary={monitor} />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} style={{ height: `${100 - alturaPrimeraMitad}%` }}>
                    <Paper style={{ height: '100%' }}>
                        <Grid container style={{ height: '100%' }}>
                            <Grid item xs={12}>
                                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                                    {tabs.map((tab, i) => (
                                        <Tab key={i} label={tab.label} />
                                    ))}
                                </Tabs>
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{ height: 'calc(100% - 48px)', overflow: 'auto' }}
                            >
                                {renderTabComponent()}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Scene
