import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize: '1.5em',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
}))

const StepMonitorName = (props) => {
    const classes = useStyles()

    const [from, setFrom] = useState("00:00")
    const [to, setTo] = useState("00:00")
    const [schedules, setSchedules] = useState([])

    const onStepMonitorNameAccept = () => {
        props.onPropertyChange(props.property, schedules)
        props.setActiveStep(props.nextStep)
    }

    const onStepBack = () => {
        props.setActiveStep(props.prevStep)
    }

    const onFromChange = (event) => {
        setFrom(event.target.value)
    }

    const onToChange = (event) => {
        setTo(event.target.value)
    }

    const onAddSchedule = () => {
        if (from && to) {
            setSchedules([...schedules, { from, to }])
        }
    }

    const onChipDetete = (index) => {
        const newSchedules = schedules.filter((schedule, i) => i !== index)
        setSchedules(newSchedules)
    }

    return (
        <Grid container>
            <Grid container item xs={12} spacing={2}>
                <Grid className={classes.root} item xs={12}>
                    {
                        schedules.map((schedule, index) => (
                            <Chip
                                key={index}
                                label={`${schedule.from} - ${schedule.to}`}
                                className={classes.chip}
                                onDelete={() => onChipDetete(index)}
                            />
                        ))
                    }
                </Grid>
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            id="time"
                            label="Desde"
                            type="time"
                            value={from}
                            variant="outlined"
                            fullWidth
                            onChange={onFromChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="time"
                            label="Hasta"
                            type="time"
                            value={to}
                            variant="outlined"
                            fullWidth
                            onChange={onToChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            fullWidth 
                            onClick={onAddSchedule}
                        >Añadir tramo horario</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.actions}>
                    <Button onClick={onStepBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorNameAccept}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorName
