import React from "react"

import { useSelector } from "react-redux"

import Default from "./containers/default/scene"
import Dimensions from "./containers/dimensions/scene"
import Metrics from "./containers/metrics/scene"

const bars = {
    "default": Default,
    "dimension": Dimensions,
    "metric": Metrics
}

const RightBar = () => {

    const { selected_item } = useSelector(state => state.dashboards.bar_chart)

    const CurrentBar = bars[selected_item.type]
    
    return (
        <CurrentBar/>
    )
}

export default RightBar