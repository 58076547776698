import React, { Suspense, lazy } from 'react'

import { Switch, Route } from 'react-router-dom'

import Inspector from './containers/inspector/scene'
import Industrial from './containers/industrial/scene'
import Dashboard from './containers/dashboard/scene'

/* const Inspector = lazy(() => import("./containers/inspector/scene"))
const Industrial = lazy(() => import("./containers/industrial/scene")) */

function waitingComponent(Component) {
    return (props) => (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    )
}

function Apps(props) {
    return (
        <Switch>
            <Route exact path={props.match.url} component={waitingComponent(Dashboard)} />
            <Route path={props.match.url + '/inspector'} component={waitingComponent(Inspector)} />
            <Route
                path={props.match.url + '/industrial'}
                component={waitingComponent(Industrial)}
            />
        </Switch>
    )
}

export default Apps
