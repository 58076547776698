import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { useDispatch, useSelector } from "react-redux"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

import { GET_DATASET_V2_BY_ID } from "../../../../queries/datasets"

import { setMetricField, setMetricAggregation } from "../../../../reducer"

const Metrics = () => {

    const dispatch = useDispatch()

    const { current_dataset, selected_item, metrics } = useSelector(state => state.dashboards.bar_chart)

    const { data: { getDatasetV2ById: { dataset: { fields = []} = {}} = {}} = {}, loading } = useQuery(GET_DATASET_V2_BY_ID, { 
        variables: { 
            params: { 
                id: current_dataset 
            }
        }
    })

    const currentMetric = metrics[selected_item.index] || {}
    
    const onFieldChange = e => dispatch(setMetricField(selected_item.index, e.target.value))

    const onAggregationChange = e => dispatch(setMetricAggregation(selected_item.index, e.target.value))

    return (
        <List>
            <ListItem>
                <ListItemText primary="OPCIONES" />
            </ListItem>
            <ListItem>
                <FormControl variant="filled" fullWidth>
                    <InputLabel>Campo</InputLabel>
                    <Select value={currentMetric.field} onChange={onFieldChange}>
                        {
                            !loading && fields.map((d, i) => (
                                <MenuItem value={d.name}>{d.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </ListItem>
            <ListItem>
                <FormControl variant="filled" fullWidth disabled={!currentMetric.field}>
                    <InputLabel>Agregar</InputLabel>
                    <Select value={currentMetric.aggregation} onChange={onAggregationChange}>
                        <MenuItem value={"sum"}>Suma</MenuItem>
                        <MenuItem value={"count"}>Contar</MenuItem>
                        <MenuItem value={"average"}>Media</MenuItem>
                        <MenuItem value={"stddev"}>Desviación Estándar</MenuItem>
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    )
}

export default Metrics