import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(4),
    },
    stepper: {
        marginTop: theme.spacing(2),
    },
    actions: {
        marginTop: theme.spacing(2),
    },
    entry: {
        marginTop: theme.spacing(2),
    },
}))

import Value from './thresholds/value/scene'
import Range from './thresholds/range/scene' 

const thresholdComponents = {
    "value": {
        component: Value,
    },
    "range": {
        component: Range,
    },
}

const StepMonitorThresholds = (props) => {
    const classes = useStyles()
    
    const [thresholds, setThresholds] = useState([])

    const onStepMonitorConfigurationAccept = () => {
        if (!thresholds.length) {
            return
        }

        props.onPropertyChange(props.property, thresholds)

        props.setActiveStep(props.nextStep)
    }

    const onStepMonitorConfigurationBack = () => {
        props.setActiveStep(props.prevStep)
    }

    const renderThresholds = () => {
        const trigger = props.properties["trigger"]
        if (!trigger) {
            return null
        }

        const triggerType = trigger.type

        let thresholdType = "value"
        switch (triggerType) {
            case "more-than":
            case "less-than":
                thresholdType = "value"
                break
            case "range":
                thresholdType = "range"
                break
            default:
                return null
        }

        if (!thresholdType) {
            return null
        }

        const thresholdComponent = thresholdComponents[thresholdType]

        if (!thresholdComponent) {
            return null
        }

        const Component = thresholdComponent.component

        return (
            <Component
                thresholds={thresholds}
                setThresholds={setThresholds}
            />
        )
    }

    return (
        <Grid container>
            {
                renderThresholds()
            }
            <Grid container item xs={12}>
                <Grid item xs={2} className={classes.actions}>
                    <Button onClick={onStepMonitorConfigurationBack}>Back</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onStepMonitorConfigurationAccept}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default StepMonitorThresholds
