const SET_SELECTED_LAYOUT = 'SET_SELECTED_LAYOUT'
const SET_SELECTED_LAYOUT_BOX = 'SET_SELECTED_LAYOUT_BOX'
const SET_ACTIVE_BOX = 'SET_ACTIVE_BOX'
const UPDATE_LAYOUT = 'UPDATE_LAYOUT'
const ADD_BOX_TO_CURRENT_LAYOUT = 'ADD_BOX_TO_CURRENT_LAYOUT'
const DELETE_BOX_FROM_CURRENT_LAYOUT = 'DELETE_BOX_FROM_CURRENT_LAYOUT'
const UPDATE_BOX_FROM_CURRENT_LAYOUT = 'UPDATE_BOX_FROM_CURRENT_LAYOUT'
const RELOAD_LAYOUT = 'RELOAD_LAYOUT'
const DELETE_LAYOUT = 'DELETE_LAYOUT'

export const setSelectedLayout = (index) => ({
    type: SET_SELECTED_LAYOUT,
    index,
})

export const setSelectedBox = (index) => ({
    type: SET_SELECTED_LAYOUT_BOX,
    index,
})

export const updateLayout = (index, data) => ({
    type: UPDATE_LAYOUT,
    index,
    data,
})

export const addBoxToCurrentLayout = (box) => ({
    type: ADD_BOX_TO_CURRENT_LAYOUT,
    box,
})

export const deleteBoxFromCurrentLayout = (boxIndex) => ({
    type: DELETE_BOX_FROM_CURRENT_LAYOUT,
    boxIndex,
})

export const updateBoxFromCurrentLayout = (boxIndex, boxData) => ({
    type: UPDATE_BOX_FROM_CURRENT_LAYOUT,
    boxIndex,
    boxData,
})

export const setActiveBox = (boxId) => ({
    type: SET_ACTIVE_BOX,
    boxId,
})

export const reload = () => ({
    type: RELOAD_LAYOUT,
})

export const deleteLayout = (layoutId) => ({
    type: DELETE_LAYOUT,
    layoutId,
})

export const initialState = {
    layouts: {},
    selectedLayout: null,
    selectedBox: null,
    activeBox: null,
}

/* eslint-disable default-param-last */
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_LAYOUT:
            return { ...state, selectedLayout: action.index }
        case SET_SELECTED_LAYOUT_BOX:
            return { ...state, selectedBox: action.index }
        case SET_ACTIVE_BOX:
            return { ...state, activeBox: action.boxId }
        case UPDATE_LAYOUT:
            return {
                ...state,
                layouts: {
                    ...state.layouts,
                    [action.index]: {
                        ...state.layouts[action.index],
                        unsaved: true,
                        ...action.data,
                    },
                },
            }
        case DELETE_LAYOUT:
            return {
                ...state,
                layouts: Object.keys(state.layouts)
                    .filter((lay) => lay !== action.layoutId)
                    .reduce((prev, next) => {
                        return { ...prev, [next]: state.layouts[next] }
                    }, {}),
            }
        case ADD_BOX_TO_CURRENT_LAYOUT:
            return {
                ...state,
                layouts: {
                    ...state.layouts,
                    [state.selectedLayout]: {
                        ...state.layouts[state.selectedLayout],
                        layout: [...state.layouts[state.selectedLayout].layout, action.box],
                        unsaved: true,
                    },
                },
            }
        case DELETE_BOX_FROM_CURRENT_LAYOUT:
            return {
                ...state,
                layouts: {
                    ...state.layouts,
                    [state.selectedLayout]: {
                        ...state.layouts[state.selectedLayout],
                        layout: [
                            ...state.layouts[state.selectedLayout].layout.slice(0, action.boxIndex),
                            ...state.layouts[state.selectedLayout].layout.slice(
                                action.boxIndex + 1
                            ),
                        ],
                        unsaved: true,
                    },
                },
            }
        case UPDATE_BOX_FROM_CURRENT_LAYOUT:
            return {
                ...state,
                layouts: {
                    ...state.layouts,
                    [state.selectedLayout]: {
                        ...state.layouts[state.selectedLayout],
                        layout: [
                            ...state.layouts[state.selectedLayout].layout.slice(0, action.boxIndex),
                            {
                                ...state.layouts[state.selectedLayout].layout[action.boxIndex],
                                ...action.boxData,
                            },
                            ...state.layouts[state.selectedLayout].layout.slice(
                                action.boxIndex + 1
                            ),
                        ],
                        unsaved: true,
                    },
                },
            }
        case RELOAD_LAYOUT:
            return {
                ...state,
                layouts: {},
                selectedLayout: null,
                selectedBox: null,
                activeBox: null,
            }
        default:
            return state
    }
}
