import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { useSelector, useDispatch } from 'react-redux'
import TablePagination from '@material-ui/core/TablePagination'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Grid from '@material-ui/core/Grid'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import SettingsIcon from '@material-ui/icons/Settings'
import Box from '@mui/material/Box'

import { setBboxVisibility } from '../../redux/images'
import { openSettingsDialog } from '../../redux/general'
import { GET_ENTRIES_BY_TRAINING_SET } from '../../../../queries/training-sets'
import { ROWS_PER_PAGE } from '../../constants'
import PredictAnnotations from '../predict-annotations/predictAnnotations'

const calculatePagination = (totalItems, maxItemsPerPage) => {
    if (totalItems <= maxItemsPerPage) return 1

    return Math.ceil(totalItems / maxItemsPerPage)
}

const Pagination = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { trainingSet, page = 0 } = useParams()
    const { showUnlabelled, selectedClasses } = useSelector(
        (state) => state.trainingSetObjectDetection.general.labels
    )
    const [goPage, setGoPage] = useState()

    const filters = [{ name: 'unlabelled', type: '', clause: showUnlabelled ? 'true' : 'false' }]
    if (selectedClasses) {
        filters.push({ name: 'classes', type: '', clause: JSON.stringify(selectedClasses) })
    }

    // FIXME: Change it by a count, not request all the entities
    const { data: { getTrainingSetEntriesByTrainingSet: { count = 0 } = {} } = {} } = useQuery(
        GET_ENTRIES_BY_TRAINING_SET,
        {
            variables: {
                params: {
                    trainingSet,
                    limit: 1,
                    offset: 0,
                    filters,
                },
            },
            fetchPolicy: 'network-only',
        }
    )

    const bboxVisibility = useSelector(
        (state) => state.trainingSetObjectDetection.images.showBBoxes
    )
    const selectedEntry = useSelector(
        (state) => state.trainingSetObjectDetection.images.selectedEntry
    )

    const onGoPageChange = (event) => setGoPage(event.target.value)

    const handleChangePage = (event, newPage) => {
        history.push({
            pathname: `${page ? '.' : 'object-detection'}/${
                newPage ? `${newPage}` : '../object-detection'
            }`,
        })
    }

    const pages = () => {
        const maxPages = calculatePagination(count || 0, ROWS_PER_PAGE)
        const pagesArray = [...Array(maxPages).keys()]

        return pagesArray
    }

    const onVisibilityIconClicked = () => {
        dispatch(setBboxVisibility(!bboxVisibility))
    }

    const onSettingsClicked = () => {
        dispatch(openSettingsDialog())
    }

    useEffect(() => {
        if (goPage !== undefined) {
            handleChangePage(null, goPage)
        }
    }, [goPage])

    return (
        <Grid container item xs={12}>
            <Grid item xs>
                <Typography variant="h6" style={{ marginTop: 10, marginLeft: 20 }}>
                    Herramientas
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Box>
                    <Tooltip
                        placement="top"
                        title={
                            <Typography fontSize={11}>
                                {bboxVisibility ? 'Esconder anotaciones' : 'Mostrar anotaciones'}
                            </Typography>
                        }
                    >
                        <div style={{ display: 'inline' }}>
                            <IconButton
                                onClick={onVisibilityIconClicked}
                                disabled={selectedEntry === null}
                            >
                                {bboxVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </div>
                    </Tooltip>
                    <Tooltip placement="top" title={<Typography fontSize={11}>Ajustes</Typography>}>
                        <IconButton onClick={onSettingsClicked}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                    <PredictAnnotations />
                </Box>
            </Grid>
            <Grid item xs={1}>
                <FormControl style={{ width: 80 }}>
                    <InputLabel>Page</InputLabel>
                    <Select defaultValue={page} value={goPage} onChange={onGoPageChange} fullWidth>
                        {pages().map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2} alignIt>
                <TablePagination
                    rowsPerPageOptions={[ROWS_PER_PAGE]}
                    component="div"
                    rowsPerPage={ROWS_PER_PAGE}
                    page={parseInt(page, 10)}
                    onChangePage={handleChangePage}
                    count={count}
                />
            </Grid>
        </Grid>
    )
}

export default Pagination
