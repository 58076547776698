import React from 'react'

import Logo from 'static/logo.png'

class Error404 extends React.Component {
    render() {
        return (
            <div style={{ display: 'table' }}>
                <div
                    style={{
                        height: '70vh',
                        width: '100vw',
                        display: 'table-cell',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                    }}
                >
                    <img src={Logo} width={300} height="auto" />
                    <br />
                    Error 404
                </div>
            </div>
        )
    }
}

export default Error404
