import React, { useState, useRef } from 'react'
import { PropTypes } from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@mui/material/TextField'
import Dialog from '@material-ui/core/Dialog'
import { Zoom } from '@vx/zoom'

export const DRAWBOXES_ID = 'drawBoxes'

const ROW_HEIGHT = 120
const FORMATS_XYXY_KEY = 'xyxy'
const FORMATS_XYWH_KEY = 'xywh'

/**
 * Zoom of draw boxes type
 */
export const DrawBoxesZoom = ({ url, bboxes, open, close }) => {
    const hasMoved = useRef(false)
    return (
        <Dialog
            open={open}
            onClose={close}
            maxWidth={false}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
            <Zoom
                width="auto"
                height={window.innerHeight}
                scaleXMin={0.1}
                scaleXMax={10}
                scaleYMin={0.1}
                scaleYMax={10}
                style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            >
                {(zoom) => {
                    return (
                        <svg width={window.innerWidth} height={window.innerHeight}>
                            <g transform={zoom.toString()}>
                                <image xlinkHref={url} />
                                {bboxes.map((bbox, i) => {
                                    return (
                                        <rect
                                            key={i}
                                            x={bbox[0]}
                                            y={bbox[1]}
                                            width={bbox[2] - bbox[0]}
                                            height={bbox[3] - bbox[1]}
                                            fill="#eb3a34"
                                            opacity={0.6}
                                        />
                                    )
                                })}
                            </g>
                            <rect
                                width={window.innerWidth}
                                height={window.innerHeight}
                                rx={14}
                                opacity={0}
                                onTouchStart={zoom.dragStart}
                                onTouchMove={(event) => {
                                    zoom.dragMove(event)
                                }}
                                onTouchEnd={zoom.dragEnd}
                                onMouseDown={zoom.dragStart}
                                onMouseMove={(event) => {
                                    hasMoved.current = true
                                    zoom.dragMove(event)
                                }}
                                onMouseUp={(event) => {
                                    hasMoved.current = false
                                    zoom.dragEnd(event)
                                }}
                                onMouseLeave={() => {
                                    if (zoom.isDragging) zoom.dragEnd()
                                }}
                            />
                        </svg>
                    )
                }}
            </Zoom>
        </Dialog>
    )
}

DrawBoxesZoom.propTypes = {
    /** Url of image */
    url: PropTypes.string.isRequired,
    /** Annotations */
    bboxes: PropTypes.array.isRequired,
    /** Boolean to pen zoom dialog */
    open: PropTypes.bool.isRequired,
    /** Function to close dialog */
    close: PropTypes.func.isRequired,
}

/**
 * Component to render the draw boxes column in the table.
 */
export const DrawBoxesComponent = ({ value, node, api }) => {
    const [aspectRatio, setAspectRatio] = useState(0)

    const { thumbnail, url, bboxes, height } = value.reduce(
        (acc, item) => ({ ...acc, [item.name]: item.value }),
        {}
    )

    const [openClicked, setOpenClicked] = useState(false)

    setTimeout(() => {
        node.setRowHeight(ROW_HEIGHT)
        api.onRowHeightChanged()
    }, 1000)

    const onLoad = (event) => {
        const naturalHeight = height === undefined ? event.target.naturalHeight : height
        if (naturalHeight === 0) {
            setAspectRatio(0)
        } else {
            setAspectRatio(ROW_HEIGHT / naturalHeight)
        }
    }

    const onClick = () => {
        setOpenClicked(true)
    }
    const onExit = () => {
        setOpenClicked(false)
    }

    return (
        <React.Fragment>
            <div style={{ height: ROW_HEIGHT, display: 'inline-block' }}>
                <img
                    src={height === undefined ? url : thumbnail}
                    height="100%"
                    width="auto"
                    style={{ position: 'absolute', display: 'block', top: 0, left: 0 }}
                    onLoad={onLoad}
                    alt="Not found"
                />
                <svg
                    onClick={onClick}
                    height="100%"
                    style={{ position: 'absolute', top: 0, left: 0 }}
                >
                    {bboxes.map((bbox, i) => {
                        return (
                            <rect
                                key={i}
                                x={bbox[0] * aspectRatio}
                                y={bbox[1] * aspectRatio}
                                width={(bbox[2] - bbox[0]) * aspectRatio}
                                height={(bbox[3] - bbox[1]) * aspectRatio}
                                fill="#eb3a34"
                                opacity={0.6}
                            />
                        )
                    })}
                </svg>
            </div>
            <DrawBoxesZoom
                url={height === undefined ? url : thumbnail}
                bboxes={bboxes}
                open={openClicked}
                close={onExit}
            />
        </React.Fragment>
    )
}

DrawBoxesComponent.propTypes = {
    /** Value of the entry in the column */
    value: PropTypes.object.isRequired,
    /** Node value of table api */
    node: PropTypes.any.isRequired,
    /** Api */
    api: PropTypes.any.isRequired,
}

/**
 * Component to configure the draw boxes operation
 */
export const DrawBoxesInputComponent = ({ columnsList, dataContainer }) => {
    const [imageColumn, setImageColumn] = useState('')
    const [bBoxesColumn, setBBoxesColumn] = useState('')
    const [format, setFormat] = useState(FORMATS_XYXY_KEY)
    const [formatData, setFormatData] = useState([0, 1, 2, 3])
    dataContainer.current.format = format // eslint-disable-line no-param-reassign
    dataContainer.current.formatData = formatData // eslint-disable-line no-param-reassign

    const onImageColumnChange = (event) => {
        setImageColumn(event.target.value)
        dataContainer.current.imageColumn = event.target.value // eslint-disable-line no-param-reassign
    }

    const onBBoxesColumnChange = (event) => {
        setBBoxesColumn(event.target.value)
        dataContainer.current.boxesColumn = event.target.value // eslint-disable-line no-param-reassign
    }

    const onFormatChange = (event) => {
        setFormat(event.target.value)
        dataContainer.current.format = event.target.value // eslint-disable-line no-param-reassign
    }

    const onFormatConfigChange = (prop) => (event) => {
        const index = parseInt(prop, 10)
        const value = parseInt(event.target.value, 10)
        setFormatData([...formatData.slice(0, index), value, ...formatData.slice(index + 1)])
        dataContainer.current.formatData = formatData // eslint-disable-line no-param-reassign
    }
    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel>Columna imagen</InputLabel>
                <Select value={imageColumn} onChange={onImageColumnChange}>
                    {Object.entries(columnsList)
                        .filter((obj) => obj[1] === 'rgb')
                        .map((obj) => {
                            return (
                                <MenuItem key={obj[0]} value={obj[0]}>
                                    {obj[0]}
                                </MenuItem>
                            )
                        })}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Columna anotaciones</InputLabel>
                <Select value={bBoxesColumn} onChange={onBBoxesColumnChange}>
                    {Object.entries(columnsList)
                        .filter((obj) => ['string', 'constant'].includes(obj[1]))
                        .map((obj) => {
                            return (
                                <MenuItem key={obj[0]} value={obj[0]}>
                                    {obj[0]}
                                </MenuItem>
                            )
                        })}
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel>Formato</InputLabel>
                <Select value={format} onChange={onFormatChange}>
                    <MenuItem key={FORMATS_XYXY_KEY} value={FORMATS_XYXY_KEY}>
                        {FORMATS_XYXY_KEY}
                    </MenuItem>
                    <MenuItem key={FORMATS_XYWH_KEY} value={FORMATS_XYWH_KEY}>
                        {FORMATS_XYWH_KEY}
                    </MenuItem>
                </Select>
            </FormControl>
            {format === FORMATS_XYXY_KEY && (
                <React.Fragment>
                    <TextField
                        label="x up"
                        type="number"
                        variant="standard"
                        value={formatData[0]}
                        onChange={onFormatConfigChange(0)}
                    />
                    <TextField
                        label="y up"
                        type="number"
                        variant="standard"
                        value={formatData[1]}
                        onChange={onFormatConfigChange(1)}
                    />
                    <TextField
                        label="x down"
                        type="number"
                        variant="standard"
                        value={formatData[2]}
                        onChange={onFormatConfigChange(2)}
                    />
                    <TextField
                        label="y down"
                        type="number"
                        variant="standard"
                        value={formatData[3]}
                        onChange={onFormatConfigChange(3)}
                    />
                </React.Fragment>
            )}
            {format === FORMATS_XYWH_KEY && (
                <React.Fragment>
                    <TextField
                        label="x"
                        type="number"
                        variant="standard"
                        value={formatData[0]}
                        onChange={onFormatConfigChange(0)}
                    />
                    <TextField
                        label="y"
                        type="number"
                        variant="standard"
                        value={formatData[1]}
                        onChange={onFormatConfigChange(1)}
                    />
                    <TextField
                        label="w"
                        type="number"
                        variant="standard"
                        value={formatData[2]}
                        onChange={onFormatConfigChange(2)}
                    />
                    <TextField
                        label="h"
                        type="number"
                        variant="standard"
                        value={formatData[3]}
                        onChange={onFormatConfigChange(3)}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

DrawBoxesInputComponent.propTypes = {
    /** List of all columns */
    columnsList: PropTypes.array.isRequired,
    /** Static container to save the configuration */
    dataContainer: PropTypes.object.isRequired,
}

/**
 * Draw boxes operation. This operation prints the bounding box
 * above image.
 */
export class DrawBoxes {
    constructor(name) {
        this.name = name
    }

    /**
     * Get the field configuration
     * @returns object
     */
    getField() {
        return { name: this.name, type: DRAWBOXES_ID }
    }

    /**
     * Function to check the configuration data integrity
     * @param {object} data
     * @returns bool
     */
    static checkData(data) {
        if (!Object.hasOwn(data, 'imageColumn')) {
            return false
        }
        if (!Object.hasOwn(data, 'boxesColumn')) {
            return false
        }
        return true
    }

    /**
     *
     * @param {object} _ - Each entry of the dataset, but in constant is not used
     * @param {object} data - Operation configuration
     * @returns New data for the entry
     */
    /* eslint-disable class-methods-use-this */
    call(entry, data) {
        // Check integrity of bboxes
        if (!Object.hasOwn(entry, data.boxesColumn)) {
            return entry
        }
        const bboxPreprocessed = []
        const entryBboxes = JSON.parse(entry[data.boxesColumn].value) || []
        entryBboxes.forEach((bbox) => {
            if (bbox.length < Math.max(data.formatData)) {
                return
            }
            if (data.format === FORMATS_XYXY_KEY) {
                bboxPreprocessed.push([
                    bbox[data.formatData[0]],
                    bbox[data.formatData[1]],
                    bbox[data.formatData[2]],
                    bbox[data.formatData[3]],
                ])
            } else {
                bboxPreprocessed.push([
                    bbox[data.formatData[0]],
                    bbox[data.formatData[1]],
                    bbox[data.formatData[0]] + bbox[data.formatData[2]],
                    bbox[data.formatData[1]] + bbox[data.formatData[3]],
                ])
            }
        })
        return { ...entry[data.imageColumn], bboxes: bboxPreprocessed }
    }
}
