import React, { useState } from "react"

import * as EmailValidator from 'email-validator';

import { withSnackbar } from "notistack"
import { makeStyles } from "@material-ui/core/styles"
import { Link, useLocation, withRouter } from "react-router-dom"

import Grid from "@material-ui/core/Grid"
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import DialogContentText from '@material-ui/core/DialogContentText';

import EmailIcon from '@material-ui/icons/Email'
import NameIcon from '@material-ui/icons/AccountCircle'
import OrganizationIcon from "@material-ui/icons/AccountBalance"

import Container from "../components/Container"
import LeftSide from "../components/LeftSide"
import RightSide from "../components/RightSide"

function SignUp(props) {

    const classes = useStyles()
    const location = useLocation()
    
    const [ loading, setLoading ] = useState(false)
    const [ open, setOpen ] = useState(false)

    const [ organization, setOrganization ] = useState("")
    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")

    const [ organizationError, setOrganizationError ] = useState("")
    const [ nameError, setNameError ] = useState("")
    const [ emailError, setEmailError ] = useState("")

    const onNameChanged = e => setName(e.target.value)
    const onOrganizationChanged = e => setOrganization(e.target.value)
    const onEmailChanged = e => setEmail(e.target.value)

    const signup = async () => {
        if(name.length === 0) {
            setNameError("Introduzca un nombre válido")
            return
        } else {
            setNameError("")
        }

        if(organization.length === 0) {
            setOrganizationError("Introduzca un nombre de organization válido")
            return
        } else {
            setOrganizationError("")
        }

        if(!EmailValidator.validate(email)) {
            setEmailError("Introduzca un email válido")
            return
        } else {
            setEmailError("")
        }

        setLoading(true)
        const { data: { signup: { status = "FAILED" }} = {}} = await props.signup({
            organizationName: organization,
            userName: name,
            email
        })
        setLoading(false)
        if(status === "FAILED") {
            props.enqueueSnackbar("Se ha producido un error", { variant: "error" })
        } else {
            setOpen(true)
        }
    }

    return(
        <Container>
            <LeftSide/>
            <RightSide>
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2"><Link to={"login" + location.hash}>Log In</Link> or Sign Up</Typography>
                    </Grid>
                    <Grid item xs={12}>                                
                        <TextField 
                            label="Name"
                            value={name}
                            placeholder="Enter your name" 
                            variant="outlined"
                            margin="normal" 
                            fullWidth 
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><NameIcon/></InputAdornment>
                            }}
                            disabled={loading}
                            onChange={onNameChanged}
                            error={nameError.length}
                            helperText={nameError}
                        />
                    </Grid>
                    <Grid item xs={12}>                                
                        <TextField 
                            label="Organization"
                            value={organization}
                            placeholder="Enter your organization name" 
                            variant="outlined"
                            margin="normal" 
                            fullWidth 
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><OrganizationIcon/></InputAdornment>
                            }}
                            disabled={loading}
                            onChange={onOrganizationChanged}
                            error={organizationError.length}
                            helperText={organizationError}
                        />
                    </Grid>
                    <Grid item xs={12}>                                
                        <TextField 
                            label="Email" 
                            value={email}
                            placeholder="Enter your email" 
                            variant="outlined"
                            margin="normal" 
                            fullWidth 
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><EmailIcon/></InputAdornment>
                            }}
                            disabled={loading}
                            onChange={onEmailChanged}
                            error={emailError.length}
                            helperText={emailError}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttonContainer}>
                            <Fab 
                                className={classes.button}
                                variant="extended" 
                                color="primary" 
                                onClick={signup} 
                                disabled={loading}
                            >
                                Sign Up
                            </Fab>
                            {loading && <CircularProgress size={24} className={classes.loading}/>}
                        </div>
                    </Grid>
            </RightSide>
            <Dialog open={open}>
                <DialogTitle>El registro se ha realizado con éxito</DialogTitle>
                <DialogContent>
                    <DialogContentText>En breve recibirá un email con la contraseña temporal.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Link to={"login" + location.hash}><Button>Continuar</Button></Link>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: 10
    },
    button: { 
        fontWeight: 900, 
        fontSize: 13, 
        background: "linear-gradient(60deg,#FF4E50,#F9D423)", 
        margin: "25px 0", 
        width: "100%", 
        boxShadow: "0 0 45px -12px rgba(0,0,0,0.6)" 
    },
    buttonContainer: {
        position: "relative"
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}))

import { SIGNUP } from "../queries/signup"

import { graphql, compose } from "react-apollo"

export default compose(
    withRouter,
    withSnackbar,
    graphql(SIGNUP, {
        props: ({ mutate }) => {
            return {
                signup: (params) => mutate({ variables: { params } })
            }
        }
    })
)(SignUp)
