import React, { useState, useEffect } from "react"

import { useMutation } from "@apollo/react-hooks"

import Grid from "@material-ui/core/Grid"
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import PlanCard from "../../../plan-card"

import { SELECT_PLAN } from "../../../../../../queries/local"

function Addon(props) {
    
    const { plans = [], subscriptions = [] } = props

    console.log(props)
    const [ selectPlan ] = useMutation(SELECT_PLAN)
    
    const onSelected = i => () => {
        const item = subscriptions.find(it => plans.findIndex(p => p._id === it.plan._id) >= 0)
        selectPlan({
            variables: {
                params: {
                    ...item ? { item: item._id } : {},
                    plan: i,
                    action: item ? "remove" : "add"
                }
            }
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                {
                    props.plans.map((plan, i) => (
                        <Grid item xs={4}>
                            <PlanCard onClick={onSelected(plan._id)} selected={subscriptions.filter(item => item.plan._id === plan._id).length > 0} title={plan.title} price={plan.price} features={plan.features} />
                        </Grid>
                    ))
                }
            </Grid>
        </React.Fragment>
    )
}

export default Addon