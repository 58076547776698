import gql from "graphql-tag"

export const GET_ALL_DATASETS = gql`
    query GetDatasetsByProject($params: GetDatasetsByProjectInput!) {
        getDatasetsByProject(params: $params) {
            state
            datasets {
                _id
                createdAt
            }
        }
    }
`

export const GET_DATASET = gql`
    query dataset($id: String!) {
        dataset(id: $id) {
            name
            description
        }
    }
`

export const CREATE_DATASET = gql`
    mutation CreateDataset($params: CreateDatasetInput!) {
        createDataset(params: $params) {
            status
            dataset {
                _id
            }
        }
    }
`

export const REMOVE_DATASET = gql`
    mutation RemoveDataset($params: RemoveDatasetRequest!) {
        removeDataset(params: $params) {
            status
        }
    }
`

export const UPDATE_DATASET_NAME = gql`
    mutation updateDatasetName($id: String!, $name: String!) {
        updateDatasetName(id: $id, name: $name)
    }
`

export const UPDATE_DATASET_DESCRIPTION = gql`
    mutation updateDatasetDescription($id: String!, $description: String!) {
        updateDatasetDescription(id: $id, description: $description)
    }
`